import styled from '@emotion/styled';

export const Header = styled.div({
  padding: '24px',
  display: 'flex',
});

export const HeaderText = styled.div({
  flexGrow: 1,
});

export const HeaderTitle = styled.div({
  fontSize: '18px',
  marginBottom: '8px',
});

export const HeaderDescription = styled.div({
  fontSize: '14px',
  color: 'var(--bu-gray-700)',
});

export const HeaderButton = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const MainContainer = styled.div({
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
});

export const TableCont = styled.div({
  display: 'flex',
  flexGrow: 1,
  overflow: 'hidden',
  width: '100%',
  '>div:first-child': {
    width: '100%',
  },
});

export const DeleteInfoContainer = styled.div({
  textAlign: 'left',
  gap: '15px',
  display: 'flex',
  flexDirection: 'column',
  '>ul': {
    paddingLeft: '14px',
  },
});
