import moment from 'moment';
import * as R from 'ramda';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { IColumn } from 'components/UI/common/TypedTable/TypedTable';

const toLowerCaseIsString = <T>(obj: T): T | string =>
  typeof obj === 'string' ? R.toLower(obj) : obj;

const toParsedDateIfDateString =
  <T>(path: R.Path, columnConfiguration?: any[]) =>
  (obj: any): T | string | number | undefined => {
    const fullPath = path.join('.');
    const columnConfig = columnConfiguration?.find(
      (el) => el.field === fullPath
    );

    const value = R.hasPath([...(path as string[]), 'value'], obj)
      ? R.path<T>([...path, 'value'], obj)
      : R.pathOr<T | string>('', path, obj);

    if (
      !!columnConfiguration &&
      !!columnConfig &&
      columnConfig.type === 'date'
    ) {
      // Dates sometimes comes with day name (Mon, Tue..) in first place and messes up the sort
      return moment
        .parseZone(value as string | number | null)
        .utc(false)
        .format('YYYY-MM-DD-h-mm-ss');
    }

    return value;
  };

const useTableSort = <T>(
  columnConfiguration?: IColumn[]
): [
  string | undefined,
  Dispatch<SetStateAction<string | undefined>>,
  (list: readonly T[]) => T[]
] => {
  const [sortByField, setSortByField] = useState<string | undefined>();

  const sortBy = useMemo(() => {
    const isDescending = sortByField?.[0] === '-';

    const fieldPath = (sortByField ?? '')
      .slice(isDescending ? 1 : 0)
      .split('.');

    return R.sort<T>(
      (isDescending ? R.descend : R.ascend)(
        R.compose(
          toLowerCaseIsString,
          toParsedDateIfDateString(fieldPath, columnConfiguration)
        )
      )
    );
  }, [sortByField]);

  return [sortByField, setSortByField, sortBy];
};

export default useTableSort;
