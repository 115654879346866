import React from 'react';

import BuButton from 'components/UI/BuButton';
import FunnelConditions from 'components/dashboard/Metrics/Create/FunnelConditions/FunnelConditions';
import { ContinueButtonWrapper } from 'components/dashboard/Metrics/Create/FunnelDefinition/styles';
import FunnelObjectSelector from 'components/dashboard/Metrics/Create/FunnelObjectSelector/FunnelObjectSelector';
import StagesSelector from 'components/dashboard/Metrics/Create/StagesSelector/StagesSelector';
import { OptionSections } from 'components/dashboard/Metrics/Create/constants';
import { BIWidget } from 'components/dashboard/Metrics/metrics.types';

interface Props {
  widget: BIWidget;
  setWidget: (widget: BIWidget) => void;
  onContinueButtonClick: (nextSection: OptionSections) => void;
}

export const FunnelDefinition: React.FC<Props> = ({
  widget,
  setWidget,
  onContinueButtonClick,
}) => {
  return (
    <>
      <FunnelObjectSelector widget={widget} setWidget={setWidget} />
      <FunnelConditions widget={widget} setWidget={setWidget} />
      <StagesSelector widget={widget} setWidget={setWidget} />
      <ContinueButtonWrapper>
        <BuButton
          secondary
          onClick={() => {
            onContinueButtonClick(OptionSections.METRIC);
          }}
        >
          Continue
        </BuButton>
      </ContinueButtonWrapper>
    </>
  );
};

export default FunnelDefinition;
