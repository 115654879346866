import { css } from 'emotion';

import { fontSubtitle } from 'assets/css/common';

export const tabs = css`
  display: flex;
  align-items: center;
  min-height: 30px;
  border-bottom: 1px solid var(--bu-gray-300);
  padding-top: 0;
  padding-bottom: 7px;
  margin: 10px -30px 0px -20px;
  padding-left: 10px;
  min-height: 42px;
`;

export const tab_icon = css`
  width: 16px;
  margin-left: 5px;
`;

export const tab_content_wrapper = css`
  display: flex;
  align-items: center;
`;

export const tabs_item_wpar = css`
  display: -ms-inline-grid;
  display: inline-grid;
  position: relative;
  top: 8px;

  /* &:hover {
    p {
      visibility: visible;
    }
  } */

  p {
    visibility: hidden;
    position: absolute;
    left: 50%;
    transform: translateX(-56%);
    bottom: -35px;
    padding: 5px 12px;
    width: fit-content;
    border-radius: 2px;
    font-size: 10px;
    font-family: var(--bu-font-regular);
  }
`;

export const tabs_item = css`
  border: none;
  background: transparent;
  text-align: center;
  padding: 0px 20px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${fontSubtitle};
  text-transform: uppercase;
  color: var(--bu-gray-700);
  position: relative;
  min-height: 30px;
  padding-bottom: 15px;
  transition: 0.4s;
  font-size: 14px;

  &:hover {
    color: var(--bu-color-buying_team_email);
  }

  &.active {
    display: block;
    min-height: 32px;
    border-bottom: 3px solid var(--bu-color-buying_team_email);
    transition: width 0.3s;
    color: var(--bu-color-buying_team_email);
    ${fontSubtitle};
    text-transform: uppercase;
    -webkit-letter-spacing: 0;
    -moz-letter-spacing: 0;
    -ms-letter-spacing: 0;
    letter-spacing: 0;
    line-height: 0;
    text-align: center;
  }

  &:focus {
    outline: none;
  }
`;
