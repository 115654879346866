import React, { useMemo } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { sortAlphabetically } from 'common/helpers';
import BuButton from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import BuSelect from 'components/UI/BuSelect';
import { ISelectOption } from 'components/UI/BuSelect/types';
import {
  AddFilterButtonContainer,
  ColumnDropdownContainer,
  FiltersContainer,
  MetricsFilteDescriptionLong,
  MetricsFilterDescription,
} from 'components/dashboard/Metrics/Create/WidgetFilters/styles';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { getDropdownFriendlyName } from 'components/dashboard/Metrics/metrics.helpers';
import { RemoveIconContainer } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIMetricColumn,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  readonly widget: BIWidget;
  readonly columns: BIMetricColumn[];
  updateWidget: (widget: BIWidget) => void;
}

export const WidgetFilters: React.FC<Props> = ({
  widget,
  columns,
  updateWidget,
}) => {
  const filterColumns: BIMetricColumn[] = useMemo(
    () =>
      columns
        .map((column) => ({
          ...column,
          label: getDropdownFriendlyName(column),
        }))
        .filter(
          (element) =>
            element.type !== 'user' &&
            element.type !== 'note' &&
            element.type !== 'custom' &&
            element.type !== 'text' &&
            element.type !== 'multipicklist' &&
            element.type !== 'formula' &&
            element.type !== 'CRM Lookup'
        )
        // To remove duplicates which are causing issues
        .filter((v, i, a) => a.findIndex((v2) => v2.name === v.name) === i),
    [columns]
  );

  const getOptions = (index: number): ISelectOption[] =>
    sortAlphabetically(
      filterColumns
        .filter(
          (column) =>
            widget.widget_filters?.findIndex(
              (filter) => filter.name === column.name
            ) === -1 ||
            widget.widget_filters?.findIndex(
              (filter) => filter.name === column.name
            ) === index
        )
        .map((column) => ({
          text: column.label,
          value: column.name,
        }))
    );

  const handleRemove = (index: number): void => {
    const newFilters = [
      ...(widget?.widget_filters ?? []).slice(0, index),
      ...(widget?.widget_filters ?? []).slice(index + 1),
    ];
    updateWidget({
      ...widget,
      widget_filters: newFilters,
      template_filters: widget.template_filters
        ? [
            ...(widget.template_filters ?? []).slice(0, index),
            ...(widget.template_filters ?? []).slice(index + 1),
          ]
        : [],
    });
  };

  const handleChange = (values: string[], index: number): void => {
    const selectedColumn = filterColumns.find(
      (oppColumn) => values[0] === oppColumn.name
    );
    const newFilters = widget.widget_filters ?? [];
    newFilters[index] = selectedColumn as BIMetricColumn;
    const newTemplateFilters = widget.template_filters
      ? widget.template_filters.filter((filter) => {
          const index = newFilters.findIndex(
            (newFilter) => filter.column.name === newFilter.name
          );
          return index != -1;
        })
      : [];
    updateWidget({
      ...widget,
      widget_filters: newFilters,
      template_filters: newTemplateFilters,
    });
  };

  const handleAdd = (): void => {
    const output = widget?.widget_filters
      ? filterColumns.filter((obj) => {
          return (
            widget?.widget_filters?.findIndex(
              (filter) => filter.name === obj.name
            ) === -1
          );
        })
      : null;
    updateWidget({
      ...widget,
      widget_filters:
        widget?.widget_filters && output
          ? [...widget?.widget_filters, output[0]]
          : [filterColumns[0]],
    });
  };

  const canAddNewFilter: boolean = useMemo(
    () => (widget?.widget_filters?.length ?? 0) < filterColumns?.length,
    [widget?.widget_filters?.length, filterColumns?.length]
  );

  return (
    <>
      <MetricsFilterDescription>
        Add a field as a selectable filter to be able to set its value on
        runtime when analysing the widget in a dashboard.
        {widget.analysis_type === AnalysisType.FUNNEL && (
          <MetricsFilteDescriptionLong>
            Filters will be applied on <strong>live</strong> data of the funnel
            objects.
          </MetricsFilteDescriptionLong>
        )}
      </MetricsFilterDescription>
      <FiltersContainer>
        {widget?.widget_filters &&
          widget?.widget_filters?.map((widgetFilter, index) => {
            if (widgetFilter && widgetFilter.name) {
              return (
                <ColumnDropdownContainer
                  key={widgetFilter.name}
                  data-testing={`filter-Container-${index}`}
                >
                  <BuSelect
                    options={getOptions(index)}
                    onChange={(values: string[]) => {
                      handleChange(values, index);
                    }}
                    secondary
                    placeholder={'Select a Filter'}
                    defaults={[widgetFilter ? widgetFilter.name : '']}
                    searchable
                    className={'select'}
                    fullWidth
                    isLargePlaceholder
                    testingLabel={`Filter-${index}`}
                  />
                  <RemoveIconContainer
                    onClick={() => {
                      handleRemove(index);
                    }}
                    key={index}
                    data-testing={`Delete-${index}`}
                  >
                    <BuIcon name={BoostUpIcons.Trash} />
                  </RemoveIconContainer>
                </ColumnDropdownContainer>
              );
            }
          })}
        <AddFilterButtonContainer>
          <BuButton secondary disabled={!canAddNewFilter} onClick={handleAdd}>
            + Add Filter
          </BuButton>
        </AddFilterButtonContainer>
      </FiltersContainer>
    </>
  );
};
