import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'actions';
import {
  getSubmissionForecastHistory,
  getSubmissionForecastOverrides,
  SubmissionForecastHistory,
} from 'actions/croOverrideActions';
import SubmissionHistory from 'components/dashboard/CROOverride/SubmissionHistory/SubmissionHistory';
import { IProps } from 'components/dashboard/CROOverride/SubmissionHistory/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const SubmissionHistoryWrapper: React.FC = () => {
  const [isHistoryInitialized, setHistoryInitialized] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState<
    SubmissionForecastHistory
  >();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubmissionForecastHistory());
  }, []);

  const submissionForecasts = useSelector(
    selectors.submissionForecastOverrides
  );
  const history = useSelector(selectors.submissionForecastHistory);
  const status = useSelector(selectors.submissionForecastOverridesStatus);
  const historyStatus = useSelector(selectors.submissionForecastHistoryStatus);

  const allBusinessTypes = useSelector<IReduxState, string[]>((state) =>
    selectors.getBusinessTypes(state, true)
  );
  const activeBusinessType = useSelector<IReduxState, string>(
    (state) =>
      selectors.getForecastActiveBusinessType(state) || allBusinessTypes[0]
  );

  const companyCurrency = useSelector(selectors.getUserLocalCurrency);

  useEffect(() => {
    if (historyStatus === 'loading') {
      setHistoryInitialized(true);
    }
  }, [historyStatus]);

  useEffect(() => {
    if (
      isHistoryInitialized &&
      historyStatus === 'success' &&
      history.length > 0
    ) {
      handleSelectHistory(history[0]);
    }
  }, [history, historyStatus, isHistoryInitialized]);

  const setActiveBusinessType: IProps['onSelectBusinessType'] = (value) =>
    dispatch(actions.ui.manageForecast.setActiveBusinessType(value));

  const handleSelectHistory: IProps['onSelectHistory'] = (history) => {
    setSelectedHistory(history);
    dispatch(getSubmissionForecastOverrides(history.id));
  };

  return (
    <SubmissionHistory
      submissionForecasts={submissionForecasts}
      allBusinessTypes={allBusinessTypes}
      activeBusinessType={activeBusinessType}
      companyCurrency={companyCurrency}
      status={status}
      history={history}
      historyStatus={historyStatus}
      selectedHistory={selectedHistory}
      onSelectHistory={handleSelectHistory}
      onSelectBusinessType={setActiveBusinessType}
    />
  );
};

export default SubmissionHistoryWrapper;
