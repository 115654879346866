import * as hooks from './hooks';
import * as s from './styles';
import { PersistParams } from './types';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { shortNumber } from 'common/helpers';
import SyntheticFormulaPreview from 'components/UI/BuFormulaTextField/SyntheticFormulaPreview';
import BuMetricFiltersDescription from 'components/UI/BuMetricFiltersDescription';
import TypedTable from 'components/UI/common/TypedTable/TypedTable';
import { getUnits } from 'components/dashboard/Metrics/Widget/helper';
import { UnitType } from 'components/dashboard/Metrics/constants';
import { IReduxState } from 'reducers/types';
import { getModalProps } from 'selectors';

export const SyntheticMetricModal: React.FC = () => {
  const dispatch = useDispatch();

  const params: PersistParams = useSelector((state: IReduxState) =>
    getModalProps(state, '/syntheticfmrl-drilldown')
  );

  const { simpleMetricsUsed } = hooks.useFetchMetrics(params.valuesByMetrics);
  const { handleMetricClick } = hooks.useOpenModalByMetricCallback(dispatch);
  const { columns: tableColumns } = hooks.useTableColumnBuilder(
    handleMetricClick,
    params.drilldownParams
  );
  const { tableData } = hooks.useTableData(
    params.valuesByMetrics,
    simpleMetricsUsed,
    params.drilldownParams
  );

  return (
    <s.ModalWrapper>
      <s.Header>{params.title}</s.Header>
      <s.clickedValue>
        {params.selectedValue?.pivot1Id}{' '}
        {params.selectedValue?.pivot2Id &&
          `& ${params.selectedValue?.pivot2Id}`}
      </s.clickedValue>
      <s.value>
        {`${getUnits(UnitType.Prefix, params.metric)}${shortNumber(
          params.selectedValue?.y
        )}${getUnits(UnitType.Suffix, params.metric)}`}
      </s.value>
      <s.formulaBlock>
        <s.formulaTitle>Formula:</s.formulaTitle>
        <SyntheticFormulaPreview formula={params.formula} />
      </s.formulaBlock>
      {params.conditions && (
        <s.conditionsBlock>
          <BuMetricFiltersDescription filters={params.conditions} />
        </s.conditionsBlock>
      )}
      <TypedTable.Border>
        <TypedTable
          columns={tableColumns}
          data={tableData}
          width="100%"
          isModal
          fullscreen
        />
      </TypedTable.Border>
    </s.ModalWrapper>
  );
};
