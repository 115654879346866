import React, { useContext } from 'react';

import BuInput from 'components/UI/BuInput';
import BuSelect from 'components/UI/BuSelect';
import {
  NULL_OPERATOR_VALUES,
  NUMBER_OPERATION_DROPDOWN_OPTIONS,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/constants';
import {
  FixedWidthContainer,
  FlexGrowContainer,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/styles';
import { UserSettingsContext } from 'components/dashboard/Metrics/contexts/UserSettingsContext';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import { BIMetricsFilter } from 'components/dashboard/Metrics/metrics.types';

type Props = {
  columnType: string;
  filter: Partial<BIMetricsFilter>;
  onOperatorSelect: (operator: string) => void;
  onTextValueChange: (text: string) => void;
};

export const NumberTypeCondition: React.FC<Props> = ({
  columnType,
  filter,
  onOperatorSelect,
  onTextValueChange,
}) => {
  const { currency } = useContext(UserSettingsContext);

  return (
    <FlexRow cssProps={{ gap: '12px' }}>
      <FixedWidthContainer width={100}>
        <BuSelect
          fullWidth
          secondary
          options={NUMBER_OPERATION_DROPDOWN_OPTIONS}
          onChange={(values: string[]) => {
            onOperatorSelect(values[0]);
          }}
          placeholder=""
          testingLabel="operator"
          defaults={[
            filter.operator
              ? filter.operator
              : NUMBER_OPERATION_DROPDOWN_OPTIONS[0].value,
          ]}
        />
      </FixedWidthContainer>

      {!NULL_OPERATOR_VALUES.includes(filter.operator ?? '') && (
        <FlexGrowContainer>
          <BuInput
            testingLabel="operator-value"
            type={columnType === 'money' ? 'currency' : columnType}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              onTextValueChange(e.currentTarget.value);
            }}
            value={
              typeof filter.value === 'boolean' || Array.isArray(filter.value)
                ? undefined
                : filter.value
            }
            code={currency}
          />
        </FlexGrowContainer>
      )}
    </FlexRow>
  );
};
