import classNames from 'classnames';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { Dropdown, Grid, Loader, Segment } from 'semantic-ui-react';
import { ActionCreator } from 'typescript-fsa';

import { actions } from 'actions';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { buildTooltip } from 'components/dashboard/Charts/helpers';
import * as layStyle from 'components/dashboard/styles';
import UnstackedDealsModal from 'components/modals/UnstackedDealsModal';
import {
  REVENUE_DROPDOWN_OPTIONS,
  RevenueTypes,
} from 'components/modals/types';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import * as selectors from 'selectors';

export type UnstackedItem = {
  [key: string]: string | number;
  total_amount: number;
  total_count: number;
};

type KeyOfUnstackedItem = keyof UnstackedItem;

type IProps = {
  status: string;
  yAxisTitle?: string;
  valueName: KeyOfUnstackedItem;
  revenueValueName: KeyOfUnstackedItem;
  title: string;
  color: string;
  childFilterValue: string;
  childValueFormatter?: (value: string) => [unknown];
  category: KeyOfUnstackedItem;
  data?: UnstackedItem[];
  persistModalParams: ActionCreator<PersistQueryParams>;
  filters: any;
  isModal?: boolean;
  extraFilter?: JSX.Element;
  tab: string;
  localStorageKeyPrefix?: string;
};

const emptyData = {
  amount_at_risk: 0,
  amount_high_risk: 0,
  amount_lost: 0,
  amount_low_risk: 0,
  amount_medium_risk: 0,
  amount_won: 0,
  category: '',
  category_name: '',
  count_at_risk: 0,
  count_high_risk: 0,
  count_lost: 0,
  count_low_risk: 0,
  count_medium_risk: 0,
  count_won: 0,
  total_amount: 0,
  total_count: 0,
};

const Unstacked: React.FC<IProps> = ({
  status,
  yAxisTitle,
  valueName,
  revenueValueName,
  title,
  color,
  childFilterValue,
  category,
  data = [],
  persistModalParams,
  filters,
  extraFilter,
  isModal,
  tab,
  localStorageKeyPrefix = '',
  childValueFormatter,
}: IProps) => {
  const [fullscreen, setFullscreen] = useState(false);
  const [type, setType] = useState<RevenueTypes>(RevenueTypes.COUNT);
  const [config, setConfig] = useState<Highcharts.Options>({});
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);

  useEffect(() => {
    const value: KeyOfUnstackedItem =
      type === RevenueTypes.COUNT ? valueName : revenueValueName;
    const sortedData = data.sort(
      (a, b) =>
        // @ts-ignorexz
        b[value] - a[value]
    );

    if (data.length) {
      setConfig({
        title: {
          text: '',
        },
        xAxis: {
          categories: sortedData.map((el) => String(el[category])),
        },
        tooltip: {
          enabled: true,
          formatter: buildTooltip(companyCurrencyCode),
        },
        yAxis: {
          min: 0,
          allowDecimals: false,
          title: {
            text: yAxisTitle,
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            cursor: 'pointer',
            events: {
              click: (e) => {
                handleChartClick(e.point.category as string);
                AnalyticsTracker.event(
                  { tab },
                  {
                    category: 'Pipeline Analytics',
                    action: 'Tab: Risk Analytics',
                    label: `Opened ${title}`,
                  }
                );
              },
            },
          },
        },
        series: [
          {
            type: 'bar',
            name: type === RevenueTypes.COUNT ? '# of Deals' : 'Revenue',
            data: sortedData.map((stage) => ({
              type,
              y:
                type === RevenueTypes.REVENUE
                  ? stage.total_amount
                  : stage.total_count,
              y2:
                type === RevenueTypes.REVENUE
                  ? stage.total_count
                  : stage.total_amount,
            })),
            color,
            boostThreshold: 500,
          },
        ],
      });
    }
  }, [
    JSON.stringify(data),
    color,
    category,
    valueName,
    revenueValueName,
    type,
  ]);

  const handleChartClick = (value: string) => {
    if (childFilterValue) {
      openModal({
        scheme: '/deals/:tab',
        params: {
          tab: isModal ? 'accounts-deals' : 'default',
          localStorageKeyPrefix,
        },
        persistParams: {
          ...filters,
          title,
          [childFilterValue]: childValueFormatter
            ? childValueFormatter(value)
            : value,
          filter_activity_sync_info: true,
          is_pipeline_analytics: true,
        },
        persistor: persistModalParams,
      });
    }
  };

  if (!data.length) {
    data.push(emptyData);
  }

  if (status === 'loading') {
    return (
      <div className="block-loader">
        <Loader active inline="centered" />
      </div>
    );
  }

  return (
    <Segment className={classNames(isModal && layStyle.removePadding_box)}>
      <Grid>
        <Grid.Row>
          <div className={layStyle.chartHeader}>
            <div>
              <span className={layStyle.chartTitle}>{title}</span>
            </div>
            <div>
              {extraFilter}
              {revenueValueName && (
                <Dropdown
                  className={layStyle.dropdown}
                  options={REVENUE_DROPDOWN_OPTIONS}
                  onChange={(e, { value }) => setType(value as RevenueTypes)}
                  value={type}
                />
              )}
              <button
                onClick={() => setFullscreen(true)}
                className={layStyle.fullScreen}
              >
                <img
                  src={require('assets/images/new_icon/full_screen_icon.svg')}
                  alt="full screen"
                />
              </button>
            </div>
          </div>
        </Grid.Row>
        <Grid.Row columns={1} stretched>
          <Grid.Column stretched>
            <HighchartsReact
              highcharts={Highcharts}
              options={config}
              immutable={true}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <UnstackedDealsModal
        open={fullscreen}
        title={title}
        type={type}
        config={config}
        onClose={() => setFullscreen(false)}
        onChange={(e, { value }) => setType(value as RevenueTypes)}
      />
    </Segment>
  );
};

const dispatchToProps = {
  persistModalParams: actions.ui.modal.persist,
};

export default connect(null, dispatchToProps)(Unstacked);
