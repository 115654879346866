import classNames from 'classnames';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Popup } from 'semantic-ui-react';

import Search from 'assets/images/search.svg';
import BuCheckbox from 'components/UI/BuCheckbox';
import BuDropdown, {
  DropdownItemContainer,
  BuDropdownItem,
} from 'components/UI/BuDropdown';
import {
  Field,
  Input,
  SearchIcon,
  SearchError,
  DropdownLabel,
} from 'components/UI/BuSelect/styles';
import { IBuSelect, ISelectOption } from 'components/UI/BuSelect/types';

const ALL_TEXT = 'All';

const ALL_ITEM: ISelectOption = {
  value: ALL_TEXT,
  text: ALL_TEXT,
  order: 0,
};

const BuSelect: React.FC<IBuSelect> = ({
  borderless,
  options,
  secondary = false,
  defaults = [],
  multiselect = false,
  inlineLabel,
  addAllOption = false,
  disabled = false,
  readOnly = false,
  className,
  onChange,
  placeholder = '',
  returnFullList = false,
  searchable = false,
  testingLabel = '',
  fullWidth = false,
  isLargePlaceholder = false,
}) => {
  const [localSelection, setLocalSelection] = useState<string[]>([]);
  const [seachValue, setSeachValue] = useState('');

  const onClose = useCallback(() => {
    if (multiselect && localSelection) {
      onChange(localSelection);
    }
  }, [localSelection]);

  useEffect(() => {
    if (Array.isArray(defaults)) {
      setLocalSelection(defaults);
    }
  }, [JSON.stringify(defaults)]);

  /*
   * when one period option is clicked should be add it on the list to
   */
  const onChangeMultiple = (val: ISelectOption, checked: boolean) => {
    const isAll = val.value === ALL_TEXT;

    const newSelection: string[] = checked
      ? [...localSelection, val.value]
      : [...localSelection.filter((item) => item !== val.value)];

    const areAllIncluded = newSelection.length >= options.length;
    const currentSelection =
      isAll || (areAllIncluded && !returnFullList) ? [] : newSelection;
    setLocalSelection(currentSelection);
  };

  const onChangeItem = (val: ISelectOption) => {
    const isAll = val.value === ALL_TEXT;
    const currentSelection = isAll ? [] : [val.value];
    onChange(currentSelection);
  };

  const optionMaker = (option: any, checked: boolean, hide: Function) => {
    const { value, text } = option;
    const dropdownItempProps = {
      key: value,
      value,
      active: checked,
      className: classNames({ multiselect: multiselect }),
    };

    const itemInner = multiselect ? (
      <BuCheckbox
        label={text}
        checked={checked}
        onChange={(checked) => onChangeMultiple(option, checked)}
      />
    ) : (
      text
    );

    /*
     * if the Select Component is a simple list, when the item is selected,
     * it will close the list and notify
     */
    const dropdownItemClick = !multiselect && {
      onClick: () => {
        hide();
        onChangeItem(option);
        setSeachValue('');
      },
    };

    const ariaActiveAttribute = multiselect ? 'aria-checked' : 'aria-selected';

    const selectedOptionProps = {
      role: multiselect ? 'menuitemcheckbox' : 'option',
      [ariaActiveAttribute]: dropdownItempProps.active ? true : false,
    };

    return (
      <BuDropdownItem
        {...selectedOptionProps}
        {...dropdownItempProps}
        {...dropdownItemClick}
      >
        {itemInner}
      </BuDropdownItem>
    );
  };

  const availableOptions = (hide: Function) => {
    const sortByOrder =
      options.length && options.find((option: ISelectOption) => !!option.order);
    const optionsItems = !searchable
      ? options
      : options.filter((option) => {
          const valueIncludes = option.value
            ?.toLowerCase()
            .includes(seachValue.toLowerCase());
          const textIncludes = option.text
            ?.toLowerCase()
            .includes(seachValue.toLowerCase());
          return valueIncludes || textIncludes;
        });

    const newOptionsItems: ReactNode[] = optionsItems
      .sort((pItem: ISelectOption, nItem: ISelectOption) =>
        sortByOrder
          ? pItem.order! > nItem.order!
            ? 1
            : pItem.order! < nItem.order!
            ? -1
            : 0
          : 0
      )
      .map((item: ISelectOption) =>
        optionMaker(item, localSelection.includes(item.value), hide)
      );

    if (addAllOption) {
      newOptionsItems.unshift(
        optionMaker(ALL_ITEM, !localSelection.length, hide)
      );
    }

    return newOptionsItems;
  };

  const optionsMaker = (hide: Function) => (
    <>
      {searchable && (
        <>
          <SearchIcon src={Search} alt={'Search Icon'} />
          <Input
            value={seachValue}
            type="text"
            placeholder="Search..."
            onChange={(e) => setSeachValue(e.target.value)}
            data-testing="txt_field"
          />
        </>
      )}
      <DropdownItemContainer>
        {availableOptions(hide) || (
          <SearchError>No items match the search</SearchError>
        )}
      </DropdownItemContainer>
    </>
  );

  const selectedElement = options.find(
    (item: ISelectOption) => item.value === localSelection[0]
  );
  const hasMoreThanOne = localSelection.length > 1;
  const selectedText =
    (localSelection.length && selectedElement?.text) || placeholder;
  const selectLabel = localSelection.length
    ? hasMoreThanOne
      ? `${selectedText} (+${localSelection.length - 1})`
      : `${selectedText}`
    : placeholder;

  return (
    <Field
      className={classNames(className, {
        'secondary-background': secondary,
        'borderless-background': borderless,
        'read-only-background': readOnly,
      })}
    >
      <BuDropdown
        borderless={borderless}
        key="select-ma"
        secondary={secondary}
        inlineLabel={inlineLabel}
        label={
          <Popup
            hoverable
            content={selectLabel}
            trigger={
              <DropdownLabel
                isPlaceholder={!selectedElement}
                isLarge={isLargePlaceholder}
              >
                {selectLabel}
              </DropdownLabel>
            }
          />
        }
        fullWidth={fullWidth}
        disabled={disabled}
        readOnly={readOnly}
        onClose={onClose}
        testingLabel={testingLabel}
      >
        {optionsMaker}
      </BuDropdown>
    </Field>
  );
};

export default BuSelect;
