import * as Highcharts from 'highcharts';
import React from 'react';

import {
  NewPipelineCreatedSpanData,
  NewPipelineCreatedSpanUser,
  NewPipelineCreatedCurrentUserData,
} from 'actions/pipelineActions';
import { formatAmount, formatMoney } from 'common/numbers';
import { IColumn, SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { SimpleCellConfig } from 'components/UI/common/TypedTable/renderers/SimpleCell';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import {
  backgroundLevel,
  clickableCell,
  nonClickableCell,
} from 'components/dashboard/Pipeline/PipelineDashboard/PipelineWidget/styles';
import { RowType } from 'components/dashboard/Pipeline/PipelineDashboard/PipelineWidget/types';

export const SELLER_COLUMN_WIDTH = 200;
export const TOTAL_COLUMN_WIDTH = 100;
export const INTERVAL_COLUMN_WIDTH = 120;
const CHART_COLUMN_WIDTH = 30;

const cellColor = backgroundLevel('var(--bu-white)', '#B1DCFD');

const tooltip = (
  currencyCode: string,
  value: number,
  deals?: NewPipelineCreatedSpanData['deals']
) =>
  deals
    ? `${formatMoney(currencyCode, value, 0)} (${deals.length} deals)`
    : `Target: ${formatMoney(currencyCode, value, 0)}`;

const pointToBreakLine = (
  currentUser: NewPipelineCreatedCurrentUserData,
  x: number,
  value: number
): (Highcharts.PointOptionsObject | null)[] => [
  { x: x - value, y: currentUser.target, custom: { hideLabel: true } },
  { x: x, y: currentUser.target },
  { x: x + value, y: currentUser.target, custom: { hideLabel: true } },
];

export const getBarPlotOptions = (
  data: NewPipelineCreatedSpanData[],
  currentUserData: NewPipelineCreatedCurrentUserData[],
  currencyCode: string,
  availableWidth: number,
  displayTarget: boolean,
  onBarClick: (
    name: string,
    deals: NewPipelineCreatedSpanData['deals']
  ) => void,
  isModal: boolean
): Highcharts.Options => {
  const columnsWidth =
    availableWidth >=
    SELLER_COLUMN_WIDTH +
      TOTAL_COLUMN_WIDTH +
      INTERVAL_COLUMN_WIDTH * data.length
      ? Math.trunc(
          (availableWidth - SELLER_COLUMN_WIDTH - TOTAL_COLUMN_WIDTH) /
            data.length
        )
      : INTERVAL_COLUMN_WIDTH;
  const valueForHalfChartColumn =
    Math.floor((CHART_COLUMN_WIDTH / columnsWidth / 2) * 10000) / 10000;
  const xAxis: Highcharts.XAxisOptions[] = [
    {
      type: 'category',
      categories: data.map((item) => item.name),
      minPadding: 0,
      maxPadding: 0,
      width: columnsWidth * data.length,
    },
  ];
  const series: Highcharts.SeriesOptionsType[] = [
    {
      type: 'column',
      color: '#5EBCD8',
      name: 'Pipeline',
      data: data.map((item) => ({
        y: item.total_amount,
        custom: item,
      })),
      pointWidth: CHART_COLUMN_WIDTH,
      point: {
        events: {
          click() {
            onBarClick(
              this.category as string,
              (this.options.custom as NewPipelineCreatedSpanData).deals
            );
          },
        },
      },
      cursor: 'pointer',
      xAxis: 0,
    } as Highcharts.SeriesColumnOptions,
  ];

  if (displayTarget) {
    xAxis.push({
      type: 'linear',
      categories: currentUserData.map((item) => item.name),
      minPadding: 0,
      maxPadding: 0,
      min: 0,
      max: currentUserData.length - 1,
      width: columnsWidth * data.length,
      visible: false,
    });

    series.push(
      ...currentUserData.map(
        (item, index) =>
          ({
            type: 'line',
            color: '#E09A72',
            name: 'Target',
            data: pointToBreakLine(item, index, valueForHalfChartColumn),
            marker: {
              enabled: false,
              states: {
                hover: { enabled: false },
              },
            },
            lineWidth: 2,
            xAxis: 1,
          } as Highcharts.SeriesLineOptions)
      )
    );
  }

  return {
    chart: {
      type: 'column',
      height: 300,
      marginBottom: 0,
      marginRight: 0,
      marginTop: 0,
      width:
        SELLER_COLUMN_WIDTH + TOTAL_COLUMN_WIDTH + columnsWidth * data.length,
      marginLeft: !isModal ? SELLER_COLUMN_WIDTH + TOTAL_COLUMN_WIDTH : 50,
      plotBorderWidth: 0,
      plotBorderColor: '#D6D7DE',
    },
    title: {
      text: '',
    },
    xAxis,
    yAxis: {
      allowDecimals: false,
      title: {
        text: 'Amount',
      },
      labels: {
        enabled: true,
        formatter() {
          return this.isFirst || this.isLast
            ? ''
            : formatAmount(this.value as number, currencyCode);
        },
      },
      gridLineDashStyle: 'Dash',
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      formatter() {
        return tooltip(
          currencyCode,
          this.y || 0,
          (this.point.options.custom as NewPipelineCreatedSpanData)?.deals
        );
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          color: '#666',
          crop: false,
          enabled: true,
          inside: false,
          overflow: 'justify',
          formatter() {
            return this.point.options.custom?.hideLabel
              ? ''
              : formatAmount(this.y || 0, currencyCode);
          },
          style: {
            fontWeight: 'normal',
          },
        },
      },
      column: {
        stacking: 'normal',
      },
    },
    series,
  };
};

export const getDealsColumnName = (period: string) => `${period}|deals`;

export const getTargetColumnName = (period: string) => `${period}|target`;

export const getColumns = (
  spans: string[],
  companyCurrency: string,
  maxValue: number,
  availableWidth: number,
  onSellerClick: (user: NewPipelineCreatedSpanUser) => void,
  onAmountClick: (
    label: string,
    ids: NewPipelineCreatedSpanData['deals']
  ) => void,
  tab: string
) => {
  const columnsWidth =
    availableWidth >=
    SELLER_COLUMN_WIDTH +
      TOTAL_COLUMN_WIDTH +
      INTERVAL_COLUMN_WIDTH * spans.length
      ? Math.trunc(
          (availableWidth - SELLER_COLUMN_WIDTH - TOTAL_COLUMN_WIDTH) /
            spans.length
        )
      : INTERVAL_COLUMN_WIDTH;

  return [
    {
      id: 'user',
      label: 'Sales Org',
      field: 'user',
      type: ColumnTypes.TEXT,
      sort_order: SortOrder.ASCENDING,
      width: SELLER_COLUMN_WIDTH,
      maxWidth: SELLER_COLUMN_WIDTH,
      minWidth: SELLER_COLUMN_WIDTH,
      config: {
        formatter: (value: NewPipelineCreatedSpanUser) => (
          <div
            className={
              value.role !== 'Account Executive'
                ? clickableCell
                : nonClickableCell
            }
          >
            {value!.name}
          </div>
        ),
        ts: Date.now(),
        click(column, row: RowType | undefined) {
          AnalyticsTracker.event(
            {
              tab,
            },
            {
              category: 'Widget Pipeline',
              action: 'Column: Sales Org',
              label: 'Clicked on the Sales Org',
            }
          );
          if (row!.user.role !== 'Account Executive') {
            onSellerClick(row!.user);
          }
        },
      } as SimpleCellConfig,
    },
    {
      id: 'total',
      label: 'Total',
      field: 'total',
      type: ColumnTypes.TEXT,
      sort_order: SortOrder.ASCENDING,
      width: TOTAL_COLUMN_WIDTH,
      maxWidth: TOTAL_COLUMN_WIDTH,
      minWidth: TOTAL_COLUMN_WIDTH,
      align: 'center',
      config: {
        formatter(value: number) {
          return `${formatAmount(value, companyCurrency)}`;
        },
        click(column: IColumn, row: RowType | undefined) {
          onAmountClick(
            `Total - ${row!.user.name}'s Org`,
            (row!.allDeals as NewPipelineCreatedSpanData['deals']) || []
          );
          AnalyticsTracker.event(
            {
              tab,
            },
            {
              category: 'Widget Pipeline',
              action: 'Column: Total',
              label: 'Clicked on the Total',
            }
          );
        },
        tooltip: {
          position: 'top right',
          relativeFields: ['total', 'allDeals'],
          getTooltip(
            value: number,
            relativeFields: {
              [key: string]: NewPipelineCreatedSpanData['deals'];
            }
          ) {
            return tooltip(
              companyCurrency,
              value,
              relativeFields?.['allDeals'] || []
            );
          },
        },
        progressBar: {
          relativeField: 'target',
        },
      },
    } as IColumn,
    ...spans.map<IColumn>((name) => ({
      id: name,
      label: name,
      field: name,
      type: ColumnTypes.TEXT,
      sort_order: SortOrder.ASCENDING,
      width: columnsWidth,
      maxWidth: columnsWidth,
      minWidth: columnsWidth,
      align: 'center',
      config: {
        formatter(value: number) {
          return `${formatAmount(value, companyCurrency)}`;
        },
        className(row) {
          return cellColor(
            maxValue ? ((row?.[name] as number) || 0) / maxValue : 0
          );
        },
        click(column, row: RowType | undefined) {
          onAmountClick(
            `${column!.label} - ${row!.user.name}'s Org`,
            (row![
              getDealsColumnName(name)
            ] as NewPipelineCreatedSpanData['deals']) || []
          );
        },
        tooltip: {
          position: 'top right',
          relativeFields: [getDealsColumnName(name)],
          getTooltip(
            value: number,
            relativeFields: {
              [key: string]: NewPipelineCreatedSpanData['deals'];
            }
          ) {
            return tooltip(
              companyCurrency,
              value,
              relativeFields?.[getDealsColumnName(name)] || []
            );
          },
        },
        progressBar: {
          relativeField: getTargetColumnName(name),
        },
      } as SimpleCellConfig,
    })),
  ];
};
