import { ISelectOption } from 'components/UI/BuSelect/types';

const NULL_OPERATOR_OPTIONS: ISelectOption[] = [
  { text: 'Is null', value: 'is_null' },
  { text: 'Not null', value: 'is_not_null' },
];

export const NULL_OPERATOR_VALUES = ['is_null', 'is_not_null'];

export const NUMBER_OPERATION_DROPDOWN_OPTIONS: ISelectOption[] = [
  { text: '<', value: 'lt' },
  { text: '≤', value: 'lte' },
  { text: '=', value: 'eq' },
  { text: '≥', value: 'gte' },
  { text: '>', value: 'gt' },
  ...NULL_OPERATOR_OPTIONS,
];

const IN_OPERATOR_VALUES: ISelectOption[] = [
  { text: 'In', value: 'in' },
  { text: 'Not In', value: 'not_in' },
];

export const SET_OPERATION_DROPDOWN_OPTIONS: ISelectOption[] = [
  ...IN_OPERATOR_VALUES,
  ...NULL_OPERATOR_OPTIONS,
];

export const BOOLEAN_OPERATION_DROPDOWN_OPTIONS: ISelectOption[] = [
  { text: 'equal', value: 'eq' },
  ...NULL_OPERATOR_OPTIONS,
];

export const BOOLEAN_VALUE_DROPDOWN_OPTIONS: ISelectOption[] = [
  { text: 'Yes', value: 'yes' },
  { text: 'No', value: 'no' },
];
