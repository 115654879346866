import { css } from 'emotion';
import update from 'immutability-helper';
import moment from 'moment';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Layout } from 'react-grid-layout';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import BuDropdown, { BuDropdownItemContainer } from 'components/UI/BuDropdown';
import BuGroupButton from 'components/UI/BuGroupButton';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import BuPopup from 'components/UI/BuPopup';
import { DashboardWidget } from 'components/dashboard/Metrics/Dashboard/DashboardWidget';
import { MetricsDashboardPlaceholder } from 'components/dashboard/Metrics/Dashboard/MetricsDashboardPlaceholder';
import {
  DashboardHeaderRow,
  DashboardHeaderRowControls,
  DashboardHeaderRowTitle,
  DashboardMetricContainer,
  LoaderContainer,
  MainContainer,
  PopupInfoContainer,
  TimestampSpan,
  dashboardSettingContainer,
  WidgetModalComponent,
} from 'components/dashboard/Metrics/Dashboard/metrics.dashboard.styles';
import { RevBiQuickView } from 'components/dashboard/Metrics/QuickView/RevBiQuickView';
import InteractiveGrid from 'components/dashboard/Metrics/common/InteractiveGrid/InteractiveGrid';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { SidebarType } from 'components/dashboard/Metrics/constants';
import { LastSyncContext } from 'components/dashboard/Metrics/contexts/LastSyncContext';
import { LastSyncProvider } from 'components/dashboard/Metrics/contexts/LastSyncContext';
import { UserSettingsProvider } from 'components/dashboard/Metrics/contexts/UserSettingsContext';
import {
  formatDashboardForSave,
  geSizeRestrictionsForWidgetConfiguration,
  getLayoutForWidget,
  parseMetricList,
  parseWidget,
  parseWidgetList,
} from 'components/dashboard/Metrics/metrics.helpers';
import { TitleNotEditing } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIDashboard,
  BIDashboardSettings,
  BIMetrics,
  BIWidget,
  InteractiveBIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import Tabs from 'components/dashboard/Tabs';
import { Partition } from 'navigation/tabs';
import { fetchApi, QueryStatus } from 'utils/network';

const DASHBOARD_ROW_HEIGHT = 200;
const DASHBOARD_NUMBER_COLUMNS = 4;

interface Props {
  loadDashboardStatus: QueryStatus;
  selectedDashboard?: BIDashboard | undefined;
  selectedDashboardIdRef: { current: string };
  dashboardList: BIDashboard[];
  setSelectedDashboard: React.Dispatch<
    React.SetStateAction<BIDashboard | undefined>
  >;
  setDashboardList: React.Dispatch<React.SetStateAction<BIDashboard[]>>;
  readOnly?: boolean;
}

export const RevBiDashboardContainer = () => {
  const match = useRouteMatch<{ id: string; tab_name: Partition }>();
  const selectedDashboardIdRef = useRef(match.params.id);

  const [loadDashboardStatus, setLoadDashboardStatus] =
    useState<QueryStatus>('notAsked');
  const [selectedDashboard, setSelectedDashboard] = useState<BIDashboard>();

  useEffect(() => {
    if (match.params.id && selectedDashboardIdRef.current !== match.params.id) {
      const abortController = new AbortController();
      fetchApi<void, BIDashboard>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards/${match.params.id}/complete`,
        queryMethod: 'get',
        setData: (result) => {
          setSelectedDashboard(result);
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load dashboard: ${error}`);
        },
        setStatus: setLoadDashboardStatus,
        signal: abortController.signal,
      });
      return () => {
        abortController.abort();
      };
    }
  }, [match.params.id]);

  const partition = match.params['tab_name'];

  return (
    <div className="container-dashboard">
      <div className="container">
        <Tabs partition={partition} wrapped />
      </div>
      <LastSyncProvider>
        <UserSettingsProvider>
          <MetricsDashboard
            key={selectedDashboard?.id}
            loadDashboardStatus={loadDashboardStatus}
            selectedDashboard={selectedDashboard}
            setSelectedDashboard={setSelectedDashboard}
            selectedDashboardIdRef={selectedDashboardIdRef}
            readOnly={true}
            dashboardList={[]}
            setDashboardList={() => {}}
          />
        </UserSettingsProvider>
      </LastSyncProvider>
    </div>
  );
};

export const MetricsDashboard: React.FC<Props> = ({
  loadDashboardStatus,
  selectedDashboard,
  selectedDashboardIdRef,
  dashboardList,
  setSelectedDashboard,
  setDashboardList,
  readOnly = false,
}) => {
  const match = useRouteMatch<{ dashboardId: string }>();
  const history = useHistory();

  const lastSyncDate = useContext(LastSyncContext);
  const modalRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const debounceTime = useRef<ReturnType<typeof setTimeout>>();

  const [metricList, setMetricList] = useState<BIMetrics[]>([]);
  const [widgetList, setWidgetList] = useState<BIWidget[]>([]);
  const [widgetToShow, setWidgetToShow] = useState<any>();

  const [isQuickViewOpen, setIsQuickViewOpen] = useState<boolean>(false);
  const [isEditingTitle, setEditingTitle] = useState<boolean>(false);

  const [loadMetricListStatus, setLoadMetricListStatus] =
    useState<QueryStatus>('notAsked');
  const [loadWidgetListStatus, setLoadWidgetListStatus] =
    useState<QueryStatus>('notAsked');
  const [deleteDashboardStatus, setDeleteDashboardStatus] =
    useState<QueryStatus>('notAsked');
  const [saveStatus, setSaveStatus] = useState<QueryStatus>('notAsked');

  const [isDelConfirmationOpen, setIsDelConfirmationOpen] =
    useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const widgetAddedRef = useRef<boolean>(false);

  useEffect(() => {
    // switching dashboard cause the this effect to run. Checking the
    // selectedDashboardIdRef to prevent save being call when user is
    // just switching dashboard
    if (
      selectedDashboardIdRef &&
      selectedDashboardIdRef.current !== selectedDashboard?.id
    ) {
      selectedDashboardIdRef.current = selectedDashboard?.id ?? '';
    } else {
      if (selectedDashboard) {
        const abortController = new AbortController();
        fetchApi<BIDashboard, any>({
          url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards/${selectedDashboard?.id}`,
          queryMethod: 'put',
          queryParams: formatDashboardForSave(selectedDashboard),
          setError: (error: string | null) => {
            toast.error(`Failed to save dashboard: ${error}`);
          },
          setStatus: setSaveStatus,
          signal: abortController.signal,
        });

        return () => {
          abortController.abort();
        };
      }
    }
  }, [selectedDashboard, selectedDashboard?.widget_list]);

  useEffect(() => {
    fetchApi<void, BIMetrics[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics_time_series`,
      queryMethod: 'get',
      setData: (result) => {
        setMetricList((prev) => [...prev, ...parseMetricList(result ?? [])]);
      },
      setError: (error: string | null) => {
        toast.error(`Fetching data failed: ${error}`);
      },
      setStatus: setLoadMetricListStatus,
    });

    fetchApi<void, BIMetrics[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics`,
      queryMethod: 'get',
      setData: (result) => {
        setMetricList((prev) => [...prev, ...parseMetricList(result ?? [])]);
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load metrics: ${error}`);
      },
      setStatus: setLoadMetricListStatus,
    });

    fetchApi<void, BIWidget[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets`,
      queryMethod: 'get',
      setData: (result) => {
        setWidgetList(parseWidgetList(result ?? []));
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load widgets: ${error}`);
      },
      setStatus: setLoadWidgetListStatus,
    });

    return () => {
      setMetricList([]);
      setWidgetList([]);
    };
  }, []);

  useEffect(() => {
    if (!selectedDashboard?.name) {
      inputRef.current?.focus();
    }
  }, [selectedDashboard?.name]);

  useEffect(() => {
    if (widgetAddedRef.current && selectedDashboard?.widget_list?.length) {
      const [firstWidget, secondWidget] = selectedDashboard.widget_list;
      if (
        selectedDashboard.widget_list.length > 2 ||
        (selectedDashboard.widget_list.length > 1 &&
          ((firstWidget as BIWidget)?.analysis_type === AnalysisType.REPORT ||
            (secondWidget as BIWidget)?.analysis_type === AnalysisType.REPORT))
      ) {
        // scroll to bottom when widget is added
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
        widgetAddedRef.current = false;
      }
    }
  }, [selectedDashboard?.widget_list, widgetAddedRef.current]);

  const updateSelectedDashboardWithNewWidget = (newWidget: BIWidget): void => {
    const newWidgets = selectedDashboard?.widget_list
      ? [...selectedDashboard?.widget_list, newWidget]
      : [newWidget];

    setSelectedDashboard((selectedDashboard) => ({
      ...selectedDashboard,
      name: selectedDashboard?.name ?? '',
      widget_list: newWidgets,
      properties: {
        ...(selectedDashboard?.properties ?? {}),
      },
    }));
    widgetAddedRef.current = true;
  };

  const handleAddWidget = (widget: BIWidget) => {
    // On add we need to load report view widget since it is missing metric info
    if (widget.analysis_type === AnalysisType.REPORT) {
      fetchApi<string, BIWidget>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets/${widget._id}?expand=true`,
        queryMethod: 'get',
        setData: (result) => {
          updateSelectedDashboardWithNewWidget(result);
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load widget ${widget._id}: ${error}`);
        },
      });
    } else {
      const newMetricList =
        widget.metric_list?.map((metricId: string) =>
          metricList.find((metricFromList) => metricId === metricFromList.id)
        ) ?? [];
      const newWidget = {
        ...widget,
        metric_list: newMetricList,
      };
      updateSelectedDashboardWithNewWidget(newWidget);
    }
  };

  const handleRemoveWidget = (widgetId: BIWidget['id']) => {
    const newWidgets = (selectedDashboard?.widget_list ?? []).filter(
      (widget: any) => widget._id !== widgetId
    );
    setSelectedDashboard((selectedDashboard) => ({
      ...selectedDashboard,
      name: selectedDashboard?.name ?? '',
      widget_list: newWidgets,
      properties: {
        ...(selectedDashboard?.properties ?? {}),
      },
    }));
  };

  const handleDeleteDashboard = () => {
    const dashboardId = match.params.dashboardId;
    if (dashboardId) {
      fetchApi<void, any>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards/${dashboardId}`,
        queryMethod: 'delete',
        setData: () => {
          const deleteIndex = (dashboardList ?? []).findIndex(
            (dashboard) => dashboard?.id === dashboardId
          );
          const newDashboardList = dashboardList.filter(
            (_, idx) => idx !== deleteIndex
          );
          setDashboardList(newDashboardList);
          setSelectedDashboard(undefined);
          if (newDashboardList.length)
            history.push(`/revbi/dashboard/${newDashboardList[0].id}`);
        },
        setError: (error: string | null) => {
          toast.error(`Failed to save dashboard: ${error}`);
        },
        setStatus: setDeleteDashboardStatus,
      });
    }
  };

  const handleCloneWidget = (widgetId: string): void => {
    fetchApi<void, BIWidget>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets/${widgetId}/clone`,
      queryMethod: 'get',
      setData: (result) => {
        const widget = parseWidget(result);
        toast.success(`Metric "${widget?.name}" has been cloned`);
        widgetList.unshift(widget);
        handleAddWidget(widget);
      },
      setError: (error: string | null) => {
        const widget = widgetList.find((widget) => widget?.id === widgetId);
        toast.error(`Failed to clone metric "${widget?.name}": ${error}`);
      },
    });
  };

  const handleUpdateWidget = useCallback(
    (widgetId: string, updatedWidget: Partial<BIWidget>): void => {
      const newWidgetList = [...widgetList];
      const index = widgetList.findIndex(
        (widgetItem) => widgetItem.id === widgetId
      );
      newWidgetList[index] = updatedWidget as BIWidget;
      setWidgetList(newWidgetList);
      setWidgetToShow(newWidgetList[index]);
    },
    [widgetToShow, widgetList]
  );

  const handleCreateNewWidget = () => {
    const url = new URL(document.location.href);
    history.push(
      `/revbi/widgets/create?saveRedirect=${url.pathname}&addWidgetToDashboard=${selectedDashboard?.id}`
    );
  };

  const handleSettingChanges = (settings: BIDashboardSettings) => {
    if (selectedDashboard) {
      selectedDashboard.properties.settings = settings;
    }

    setSelectedDashboard((selectedDashboard) => ({
      ...selectedDashboard,
      name: selectedDashboard?.name ?? '',
      widget_list: selectedDashboard?.widget_list ?? [],
      properties: {
        ...(selectedDashboard?.properties ?? {}),
        settings,
      },
    }));
  };
  // TODO - why widget_list in this case can be a list of strings?
  // Refactor to use BIWidget type as is assumed as BIWidget on this component
  const widgetsForRender = (selectedDashboard?.widget_list || []) as BIWidget[];

  const addedWidgetIds = useMemo(
    () =>
      Array.from(
        new Set(
          (selectedDashboard?.widget_list as BIWidget[])?.reduce(
            (results: string[], item) => {
              if (item?._id) results.push(item._id);
              return results;
            },
            []
          )
        )
      ),
    [selectedDashboard?.widget_list]
  );

  const dashboardSettings = useMemo(
    () => selectedDashboard?.properties?.settings || undefined,
    [selectedDashboard?.properties?.settings]
  );

  const showWidgetModal = (widget: Partial<BIWidget>) => {
    if (debounceTime.current) {
      return;
    }
    containerRef.current?.classList.add('no-scroll');

    const widgetElement = widgetsForRender?.find(
      (widgetElement: BIWidget) => widget._id === widgetElement._id
    );
    const newWidgetToShow = {
      ...widget,
      metricsFromList: widgetElement?.metric_list ?? [],
    };
    applyModalAnimation('opened');
    setWidgetToShow(newWidgetToShow);
  };

  const closeWidgetModal = () => {
    modalRef.current?.classList.replace('opened', 'closing');
    applyModalAnimation('closing', 1450);
  };

  const applyModalAnimation = (actionClass: string, timer = 450) => {
    if (debounceTime.current) {
      clearTimeout(debounceTime.current);
    }

    debounceTime.current = setTimeout(() => {
      modalRef.current?.classList.toggle(actionClass);
      if (actionClass === 'closing') {
        setWidgetToShow(null);
        debounceTime.current = undefined;
        containerRef.current?.classList.remove('no-scroll');
      }
    }, timer);
  };

  const getlegacyWidgetsForRender = () => {
    if (selectedDashboard?.properties?.widgetsOrder?.length) {
      return (selectedDashboard.properties.widgetsOrder as string[]).reduce<
        BIWidget[]
      >((results, widgetId) => {
        const w = selectedDashboard?.widget_list?.find(
          (e) => (e as BIWidget)._id === widgetId
        );
        if (w) results.push(w as BIWidget);

        return results;
      }, []);
    } else {
      return selectedDashboard?.widget_list as BIWidget[];
    }
  };

  /**
   * This function is a temporary measure to ensure backward compatibility with old dashboards.
   * It converts the layout structure of old dashboards to be compatible with the new layout system.
   *
   * Note: This function should be removed after the Backend migration that converts all existing
   * dashboards to the new layout structure has been successfully run in production.
   * https://vocalo.atlassian.net/browse/REV-1020
   */
  const convertLegacyDashboardLayout = () => {
    const legacyWidgetsForRender = getlegacyWidgetsForRender();

    if (legacyWidgetsForRender === undefined) {
      return [];
    }

    const convertedWidgets: InteractiveBIWidget[] = [];

    let nextColumn = 0;
    let nextRow = 0;
    for (const widget of legacyWidgetsForRender) {
      const minSizes = geSizeRestrictionsForWidgetConfiguration(widget);
      const isExpanded =
        widget?.analysis_type === AnalysisType.REPORT ||
        !!selectedDashboard?.properties?.widgetsExpanded?.includes(widget._id!);
      const width = isExpanded ? 4 : 2;
      const height = 2;

      const columnForWidget = nextColumn;
      const rowForWidget = nextRow;

      nextColumn = (columnForWidget + width) % DASHBOARD_NUMBER_COLUMNS;
      nextRow = nextColumn === 0 ? rowForWidget + height : rowForWidget;

      convertedWidgets.push({
        ...widget,
        i: widget._id!,
        x: columnForWidget,
        y: rowForWidget,
        w: width,
        h: height,
        ...minSizes,
      });
    }
    return convertedWidgets;
  };

  const isLegacyDashboard =
    selectedDashboard &&
    selectedDashboard?.properties.widgetLayout === undefined;

  const widgetsFetched = loadWidgetListStatus === 'success';

  const widgetsList = useMemo(() => {
    if (isLegacyDashboard === undefined || !widgetsFetched) {
      return [];
    }
    if (isLegacyDashboard) {
      return convertLegacyDashboardLayout();
    }

    return widgetsForRender
      ?.map((widgetElement: BIWidget) => {
        const widget =
          widgetElement?.analysis_type === AnalysisType.REPORT
            ? widgetElement
            : widgetList.find(
                (widgetItem) => widgetItem._id === widgetElement._id
              );

        if (widget) {
          const layoutConfiguration = getLayoutForWidget(
            widget,
            selectedDashboard?.properties.widgetLayout || []
          );

          return {
            ...widget,
            metric_list: widgetElement.metric_list,
            isBounded: true,
            ...layoutConfiguration,
          };
        }
        return null;
      })
      .filter((widget) => !!widget) as InteractiveBIWidget[];
  }, [
    widgetList,
    widgetsForRender,
    selectedDashboard?.properties.widgetLayout,
    widgetsFetched,
    isLegacyDashboard,
  ]);

  const onDashboardLayoutChange = (layout: Layout[]) => {
    setSelectedDashboard((selectedDashboard) => {
      if (selectedDashboard) {
        return {
          ...selectedDashboard,
          name: selectedDashboard?.name ?? '',
          widget_list: selectedDashboard?.widget_list ?? [],
          properties: {
            ...(selectedDashboard?.properties ?? {}),
            widgetLayout: layout,
          },
        };
      }
    });
  };

  return (
    <MainContainer ref={containerRef} className={'main-dashboard'}>
      <DashboardHeaderRow>
        <DashboardHeaderRowTitle onClick={() => setEditingTitle(true)}>
          {(isEditingTitle || !selectedDashboard?.name) && !readOnly ? (
            <BuInput
              innerRef={inputRef}
              autoFocus
              disabled={!selectedDashboard}
              placeholder="Dashboard Name"
              type="text"
              value={selectedDashboard?.name}
              onChange={(e) => {
                const updatedDashboard = {
                  ...selectedDashboard,
                  name: e.target.value,
                  widget_list: selectedDashboard?.widget_list ?? [],
                  properties: selectedDashboard?.properties ?? {},
                };
                setSelectedDashboard(updatedDashboard);
                const selectedIndex = dashboardList.findIndex(
                  (dashboard) => dashboard.id === match.params.dashboardId
                );
                const newDashboardList = update(dashboardList, {
                  [selectedIndex]: { $set: updatedDashboard },
                });
                setDashboardList(newDashboardList);
              }}
              onBlur={() => setEditingTitle(false)}
              onFocus={() => setEditingTitle(true)}
            />
          ) : (
            <>
              <TitleNotEditing>
                <p>{selectedDashboard?.name}</p>
                {!readOnly && (
                  <div className="icon-button">
                    <BuIcon name={BoostUpIcons.Pencil} />
                  </div>
                )}
              </TitleNotEditing>
            </>
          )}
        </DashboardHeaderRowTitle>
        <DashboardHeaderRowControls>
          <BuPopup
            trigger={(ref) => (
              <div ref={ref}>
                {
                  <BuIcon
                    name={BoostUpIcons.BadgeInfoSolid}
                    color={'var(--bu-gray-700)'}
                    className={css`
                      font-size: 1.7em;
                    `}
                  />
                }
              </div>
            )}
          >
            <PopupInfoContainer>
              This is the time of the last data synchronization to RevBI
            </PopupInfoContainer>
          </BuPopup>
          <TimestampSpan>
            Last sync:{' '}
            {moment(lastSyncDate, 'MMM Do, YYYY hh:mma').isValid() &&
              moment(lastSyncDate, 'MMM Do, YYYY hh:mma').format(
                'MMM Do, YYYY [-] hh:mm a'
              )}
          </TimestampSpan>

          {!readOnly && (
            <>
              <BuButton
                icon
                secondary
                borderless
                tooltip="Delete dashboard"
                disabled={
                  !selectedDashboard?.id || deleteDashboardStatus === 'loading'
                }
                onClick={() => {
                  setIsDelConfirmationOpen(true);
                }}
              >
                <BuIcon name={BoostUpIcons.Trash} />
              </BuButton>

              <BuDropdown
                label={<BuIcon name={BoostUpIcons.Settings} />}
                secondary
                borderless
                icon={true}
                noDropdownIcon
              >
                {(hide) => (
                  <BuDropdownItemContainer>
                    <div className={dashboardSettingContainer}>
                      <h5 className={'bu-font-sub-heading'}>Filter Users</h5>
                      <BuGroupButton
                        options={[
                          { id: 'active', text: 'Active users only' },
                          { id: 'all', text: 'All users' },
                        ]}
                        selectedOption={
                          dashboardSettings?.userFilter || 'active'
                        }
                        onSelect={(value) => {
                          handleSettingChanges({ userFilter: value });
                        }}
                      />
                    </div>
                  </BuDropdownItemContainer>
                )}
              </BuDropdown>

              <BuButton
                disabled={!selectedDashboard}
                onClick={() => {
                  setIsQuickViewOpen(true);
                }}
              >
                + Add Widget
              </BuButton>
            </>
          )}
        </DashboardHeaderRowControls>
      </DashboardHeaderRow>
      <DashboardMetricContainer
        widgetsLength={selectedDashboard?.widget_list?.length ?? 0}
      >
        {(loadDashboardStatus === 'loading' ||
          loadWidgetListStatus === 'loading') && (
          <LoaderContainer>
            <Loader active />
          </LoaderContainer>
        )}

        {loadDashboardStatus !== 'loading' &&
          loadWidgetListStatus !== 'loading' &&
          widgetsList?.length > 0 && (
            <InteractiveGrid
              rowHeight={DASHBOARD_ROW_HEIGHT}
              columns={DASHBOARD_NUMBER_COLUMNS}
              items={widgetsList}
              compactType="vertical"
              onLayoutChange={onDashboardLayoutChange}
              isInteractive={!readOnly}
              renderItem={(widget) => {
                return (
                  <DashboardWidget
                    readOnly={readOnly}
                    key={widget._id}
                    widget={widget}
                    dashboardId={selectedDashboard?.id}
                    dashboardName={selectedDashboard?.name}
                    dashboardSettings={dashboardSettings}
                    metricsFromList={widget.metric_list}
                    onCloneWidget={handleCloneWidget}
                    onRemoveWidget={handleRemoveWidget}
                    onUpdateWidget={(value) =>
                      handleUpdateWidget(widget._id!, value)
                    }
                    onNameClicked={showWidgetModal}
                  />
                );
              }}
            />
          )}

        {loadDashboardStatus !== 'loading' &&
          (!selectedDashboard || selectedDashboard.widget_list.length === 0) &&
          !readOnly && (
            <MetricsDashboardPlaceholder
              buttonDisabled={!selectedDashboard}
              handleAddMetricClick={() => {
                setIsQuickViewOpen(true);
              }}
            />
          )}

        <div ref={bottomRef} />
      </DashboardMetricContainer>

      {isQuickViewOpen && (
        <RevBiQuickView
          isOpen={isQuickViewOpen}
          list={widgetList}
          selectedIds={addedWidgetIds}
          sidebarType={SidebarType.WIDGETS}
          onClose={() => setIsQuickViewOpen(false)}
          onAdd={handleAddWidget}
          onCreateNew={handleCreateNewWidget}
        />
      )}

      <BuConfirmationPopup
        cancelText="No"
        confirmText="Yes"
        headerText="Confirmation Required!"
        isOpen={isDelConfirmationOpen}
        onClose={() => {
          setIsDelConfirmationOpen(false);
        }}
        onConfirm={() => {
          handleDeleteDashboard();
          setIsDelConfirmationOpen(false);
        }}
      >
        {`Are you sure you want to delete "${selectedDashboard?.name}"? It will be permanently removed.`}
      </BuConfirmationPopup>

      {widgetToShow && (
        <WidgetModalComponent
          scrollDistance={containerRef.current?.scrollTop}
          heightView={containerRef.current?.clientHeight}
          ref={modalRef}
        >
          <DashboardWidget
            key={`modalId_${widgetToShow._id}`}
            isDashboardModal
            widget={widgetToShow}
            dashboardId={selectedDashboard?.id}
            dashboardName={selectedDashboard?.name}
            dashboardSettings={dashboardSettings}
            onCloneWidget={handleCloneWidget}
            onRemoveWidget={handleRemoveWidget}
            onUpdateWidget={(value) =>
              handleUpdateWidget(widgetToShow._id ?? '', value)
            }
            metricsFromList={widgetToShow.metricsFromList}
            readOnly={readOnly}
            closeWidgetModal={closeWidgetModal}
          />
        </WidgetModalComponent>
      )}
    </MainContainer>
  );
};
