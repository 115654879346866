import styled from '@emotion/styled';

export const Field = styled.div`
  position: relative;
  &.secondary-background {
    background-color: var(--bu-white);
  }
  &.borderless-background {
    margin-left: -8px;
    max-width: 100%;
    > .bu-dropdown-open {
      background-color: var(--bu-white);
    }
    > .bu-dropdown {
      padding-left: 5px;
      max-width: 100%;
    }
    &:hover {
      background-color: var(--bu-white) !important;
    }
  }
  &.read-only-background {
    background-color: var(--bu-gray-100);
  }
`;

export const Input = styled.input`
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-right: none;
  border-left: none;
  outline: none;
  height: 32px;
  font-size: 14px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding: 0px 7px 0px 30px;
`;

export const SearchIcon = styled.img`
  height: 14px;
  position: absolute;
  top: 0;
  left: 0;
  margin: 8px 5px 0px 10px;
`;

export const SearchError = styled.p`
  padding: 5px;
`;

interface DropdownLabelProps {
  isPlaceholder: boolean;
  isLarge: boolean;
}

export const DropdownLabel = styled.div<DropdownLabelProps>`
  max-width: ${(props) => (props.isLarge ? 'inherit' : '100px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 28px;
  color: ${(props) => props.isPlaceholder && 'var(--bu-gray-400)'};
`;
