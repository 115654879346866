import { conditionElement, conditionsList, labelParagraph } from './styles';
import React from 'react';

import { getPeriodPrettyPrint } from 'components/dashboard/Metrics/metrics.helpers';

interface Props {
  readonly isHierarchyRollupSum?: boolean;
  readonly isCumulativeSum?: boolean;
  readonly cumulativeSumPeriod?: string;
}

export const MetricAdvancedOptionsList: React.FC<Props> = ({
  isHierarchyRollupSum = false,
  isCumulativeSum = false,
  cumulativeSumPeriod,
}) => (
  <>
    <p className={labelParagraph}>Show:</p>
    <ul className={conditionsList}>
      {isCumulativeSum && cumulativeSumPeriod && (
        <li className={conditionElement}>
          Cumulative Sum {getPeriodPrettyPrint(cumulativeSumPeriod)}
        </li>
      )}
      {isHierarchyRollupSum && (
        <li className={conditionElement}>Manager level aggregation</li>
      )}
    </ul>
  </>
);
