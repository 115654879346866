import { connect } from 'react-redux';

import { actions } from 'actions';
import * as forecastActions from 'actions/forecastActions';
import ForecastRollUps from 'components/dashboard/ForecastRollUps/ForecastRollUps';
import {
  StateProps,
  DispatchProps,
} from 'components/dashboard/ForecastRollUps/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const mapStateToProps = (state: IReduxState): StateProps => {
  const { rollup_overall_view_enabled, cache_settings } =
    selectors.getCompanySettings(state);
  return {
    isOverallEnabled: rollup_overall_view_enabled,
    selectedBusinessType: selectors.getSelectedBusinessType(
      state,
      'forecast_roll_ups',
      rollup_overall_view_enabled
    ),
    businessTypesList: selectors.getBusinessTypesList(state),
    companyCurrency: selectors.getUserLocalCurrency(state),
    filters: selectors.getFiltersForAPI(state, 'forecast_roll_ups'),
    isSubmissionAvailable: selectors.isForecastSubmissionAvailable(state),
    submissionSettings: selectors.getForecastSubmissionSettings(state),
    submissionDeadline: selectors.forecastSubmissionDeadline(state),
    user: selectors.getUser(state),
    isSubmitForecast: selectors.getSubmitForecast(state),
    isCROEnabled: selectors.isSubmissionOverrideEnabled(state),
    cacheSettings: cache_settings,
    isSubmissionLoaded: selectors.isForecastSubmissionLoaded(state),
    companySettingsLoaded: selectors.getCompanySettingsLoaded(state),
    isRequiredDataReady: selectors.isBusinessTypesConfigReady(state),
  };
};

const mapDispatchToProps: DispatchProps = {
  clearOnExit: actions.ui.manageForecast.clearOnRouteChange,
  fetchForecastSubmission:
    forecastActions.fetchForecastSubmissionsSettingsAvailable,
  persistModalParams: actions.ui.modal.persist,
  setForecastViewFilter: forecastActions.setForecastViewFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForecastRollUps);
