import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { fontSubtitle } from 'assets/css/common';
import { IColumn } from 'components/UI/common/TypedTable/TypedTable';
import styles from 'components/dashboard/ForecastRollUps/FormulaColumnTooltips/styles';
import { DefaultPivotsProps } from 'components/dashboard/ForecastRollUps/types';
import { IReduxState } from 'reducers/types';
import { getFiltersState, getCompanySettings } from 'selectors';

type Props = {
  column: IColumn;
  activePivot: DefaultPivotsProps;
};

const ColumnHeaderTooltip: React.FC<Props> = ({ column, activePivot }) => {
  const {
    label: title,
    config: { formulaExpressionDisplayName, subValue },
  } = column;

  const expression = formulaExpressionDisplayName || '';

  const hasDelta = !!subValue?.badge;

  const filterState = useSelector((state: IReduxState) =>
    getFiltersState(state, 'forecast_roll_ups')
  );

  const companySettings = useSelector(getCompanySettings);

  const isForecastRollUpsDeltasEnabled = Boolean(
    companySettings?.rollups_deltas_enabled
  );

  const isManagerPivotActive = activePivot.index === 'user._id';

  const changesSinceFilter = useMemo(
    () =>
      filterState?.change_interval?.values?.find((value) => value.checked)
        ?.label || null,
    [filterState]
  );

  return (
    <div className={classNames('bu-font-default', styles.container)}>
      <div className={classNames(styles.row, styles.header)}>
        <div className="title">{title}</div>
      </div>
      <div className={classNames(styles.column, styles.body)}>
        <span>The formula for this column is:</span>
        <span className={classNames(fontSubtitle)}>{expression}</span>

        {isForecastRollUpsDeltasEnabled &&
          isManagerPivotActive &&
          (hasDelta ? (
            <p style={{ marginTop: '14px' }}>
              The deltas are calculated by comparing the current submission{' '}
              {changesSinceFilter ? (
                <span>
                  to what it was{' '}
                  <span className={classNames(fontSubtitle)}>
                    {changesSinceFilter}
                  </span>{' '}
                  (based on the Changes Since filter).
                </span>
              ) : (
                <span>based on the Changes Since filter.</span>
              )}
            </p>
          ) : (
            <p style={{ marginTop: '14px' }}>
              Delta can't be calculated as it uses variables without a trackable
              delta.
            </p>
          ))}
      </div>
    </div>
  );
};

export default ColumnHeaderTooltip;
