import { TimeSeriesDrillDownParams } from 'components/dashboard/Metrics/metrics.types';

export enum RevenueTypes {
  REVENUE = 'revenue',
  COUNT = 'count',
}

export const REVENUE_DROPDOWN_OPTIONS = [
  {
    text: 'Revenue',
    value: RevenueTypes.REVENUE,
  },
  {
    text: '# of Deals',
    value: RevenueTypes.COUNT,
  },
];

export const formatRevenueTitle = (type: RevenueTypes, title: string) => {
  return `${
    type === RevenueTypes.REVENUE ? 'Revenue' : '# of Deals'
  } by ${title}`;
};

/**
 * RevBI - Historical & Opp Splits modal
 *
 * */

export type CsvColumns = {
  display_name: string;
  object_field: string;
};

export interface DrilldownQueryParams extends TimeSeriesDrillDownParams {
  columns?: string[];
  limit: number;
  offset: number;
  order_by_expression: string;
}

export type DrilldownData = {
  rows: Deals.Deal[];
  deals: Deals.Deal[];
  count: number;
  total_amount: number;
};

export type DrilldownDataResponse = {
  data: DrilldownData;
};
