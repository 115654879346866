import {
  CROUserSubmission,
  SubmissionSettingsId,
  UserRow,
  UserSubmission,
  UserSubmissionBookedAndTarget,
} from 'actions/croOverrideActions';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';

export type RowTypeFieldName = '__userRowType__';
export const RowTypeFieldName: RowTypeFieldName = '__userRowType__';

export type SubmissionTouchedFieldName = '__touched__';
export const SubmissionTouchedFieldName: SubmissionTouchedFieldName =
  '__touched__';

export type SubmissionTouched<T> = T &
  Partial<Record<SubmissionTouchedFieldName, boolean>>;

interface Row extends Omit<UserRow, 'submissions'> {
  [RowTypeFieldName]: string;
  submissions: Record<SubmissionSettingsId, unknown>;
}

interface CRORowRaw extends Row, IRow {
  [RowTypeFieldName]: 'CRO';
  bookedAndTarget?: UserSubmissionBookedAndTarget;
  managerCanOverride: boolean;
  submissions: Record<
    SubmissionSettingsId,
    SubmissionTouched<CROUserSubmission>
  >;
}

interface UserRowRaw extends Row, IRow {
  [RowTypeFieldName]: 'User';
  bookedAndTarget?: UserSubmissionBookedAndTarget;
  managerCanOverride: boolean;
  submissions: Record<
    SubmissionSettingsId,
    SubmissionTouched<UserSubmission> | undefined
  >;
}

export type RowSubmission = CRORowRaw | UserRowRaw;

type SubmissionType<T extends Row> = T extends {
  submissions: Record<SubmissionSettingsId, infer R>;
}
  ? R
  : never;

export type RowWithExistingSubmissions<T extends RowSubmission> = T & {
  submissions: Record<
    SubmissionSettingsId,
    Exclude<SubmissionType<T>, undefined>
  >;
};

export const isCRORow = (row: RowSubmission): row is CRORowRaw =>
  row[RowTypeFieldName] === 'CRO';
export const isUserRow = (row: RowSubmission): row is UserRowRaw =>
  row[RowTypeFieldName] === 'User';
