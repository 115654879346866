import * as selectors from '../selectors';
import { getLocation, push } from 'connected-react-router';
import queryString from 'query-string';
import { select, put, take, fork, cancel, takeEvery } from 'redux-saga/effects';

import * as t from 'actions/actionTypes';
import * as initActions from 'actions/initActions';
import { createUserAction } from 'actions/userActions';
import { persistor } from 'store/configureStore';

let isNeedRefresh = false;

function* waitForLogin() {
  // wait for a google login success action
  yield take(t.LOGIN + t.OAUTH + t.SUCCESS);
  yield put(initActions.initApp());
  persistor.persist();
  // check if there's a `next` paremeter on the url
  const { search } = yield select(getLocation);
  const query = queryString.parse(search);
  const role = yield select(selectors.getUserRole);
  // by default redirect to the dashboard
  let url = '';
  if (query.next) {
    url = query.next;
  } else {
    url = '/';
  }

  // do the redirect
  yield put(push(url));
}

function* stopPendo() {
  const isImpersonate = yield select(selectors.isImpersonate);

  if (!!window.pendo && isImpersonate) {
    isNeedRefresh = true;
  }
}

function* refreshApp() {
  if (isNeedRefresh) {
    isNeedRefresh = false;
    document.location.reload(true);
  }
}

export function* loginSaga() {
  while (true) {
    // when entering the login section
    yield take(t.LOGIN + t.ENTER);

    // clear the user state
    yield put(createUserAction.resetState());

    // wait for a login
    const wait = yield fork(waitForLogin);

    // when exiting, stop listening
    yield take(t.LOGIN + t.EXIT);
    cancel(wait);
  }
}

export function* impersonateSaga() {
  yield takeEvery(t.ADMIN + t.IMPERSONATE + t.SUCCESS + t.INIT, stopPendo);
  yield takeEvery('@@router/LOCATION_CHANGE', refreshApp);
}
