import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuCheckbox from 'components/UI/BuCheckbox';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import * as s from 'components/UI/OpenFiltersPanel/CreateNewViewModal/styles';

type IProps = {
  isOpen: boolean;
  filterProfilesNames: string[];
  onClose: () => void;
  onSubmit: (name: string, isDefaultView: boolean) => void;
};

const CreateNewViewModal: React.FC<IProps> = ({
  isOpen,
  filterProfilesNames,
  onClose,
  onSubmit,
}) => {
  const [name, setName] = useState<string>('');
  const [isNameValid, setNameValid] = useState<boolean>(true);
  const [isDefaultView, setDefaultView] = useState<boolean>(false);
  const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(true);

  const resetState = () => {
    setName('');
    setNameValid(true);
    setDefaultView(false);
    setSubmitDisabled(true);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const trimmedName = e.target.value?.trimStart();
    const isValid = !!trimmedName;
    setName(trimmedName);
    setNameValid(isValid);
    setSubmitDisabled(!isValid);
  };

  const handleSubmit = (): void => {
    const trimmedName = name.trim();
    if (filterProfilesNames.includes(trimmedName)) {
      setName(trimmedName);
      setNameValid(false);
      setSubmitDisabled(true);
      toast.error(
        'View name cannot be updated as this name is taken. Try again with different name.',
        {
          position: 'bottom-left',
        }
      );

      return;
    }

    onSubmit(trimmedName, isDefaultView);
    resetState();
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isOpen) {
      ref?.current?.focus();
    }
  }, [isOpen]);

  return (
    <Modal size="tiny" open={isOpen} onClose={handleClose}>
      <div className={s.header}>
        <div className={s.title_container}>
          <p className={s.title_paragraph}>Create New View</p>
        </div>
        <div className={s.close_icon_button} onClick={handleClose}>
          <BuIcon name={BoostUpIcons.ClosePopup} />
        </div>
      </div>
      <Modal.Content>
        <span className={s.name_span}>
          Name
          <span>*</span>
        </span>
        <BuInput
          className={classNames(
            s.modal_text_input,
            !isNameValid && s.input_error
          )}
          type="text"
          placeholder="Type here..."
          value={name}
          innerRef={ref}
          onChange={handleNameChange}
        />
        <BuCheckbox
          label="Pin view to make this a default view"
          checked={isDefaultView}
          onChange={() => {
            setDefaultView((isDefaultView) => !isDefaultView);
          }}
        />
        <div className={s.buttons_container}>
          <BuButton
            size={BuControlSize.REGULAR}
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
          >
            Create
          </BuButton>
          <BuButton
            secondary
            size={BuControlSize.REGULAR}
            onClick={handleClose}
            className={s.cancel_button}
          >
            Cancel
          </BuButton>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default CreateNewViewModal;
