import Highcharts from 'highcharts';

export interface TooltipWithClickableAnchors extends Highcharts.TooltipOptions {
  onAnchorClick(
    this: Highcharts.Point,
    e: MouseEvent,
    anchor: HTMLAnchorElement
  ): void;
}

const isExtraTooltip = (
  tooltipOptions: Highcharts.TooltipOptions
): tooltipOptions is TooltipWithClickableAnchors =>
  typeof (tooltipOptions as TooltipWithClickableAnchors).onAnchorClick ===
  'function';

const handledClickAttributeName = 'data-click-handling-href';

/**
 * Because Highcharts in tooltip formatter is getting html in string value, it is loosing runtime context.
 * Also for security reasons Highcharts is not allowing passing some Html DOM attributes like "onclick".
 * It is possible to force allowing "onclick" attribute, but Highcharts Tooltip formatter function is accepting only html as string in return,
 * you can add event by document.addEvent because button is not existing til tooltip is not rendered.
 *
 * This mixin is adding to `Highcharts.TooltipOptions` extra method `onAnchorClick(MouseEvent, HTMLAnchorElement)`,
 * when it is exists all anchors from that tooltips will get onclick event mapped to that function
 */
export default function (H: any) {
  H.wrap(H.Tooltip.prototype, 'refresh', function (
    this: Highcharts.Tooltip,
    proceeds: any,
    point: Highcharts.Point
  ) {
    proceeds.apply(this, Array.prototype.slice.call(arguments, 1));

    const options = this.options;

    if (isExtraTooltip(options)) {
      ((this.getLabel().attr('div') as unknown) as HTMLDivElement)
        .querySelectorAll<HTMLAnchorElement>('a')
        .forEach((anchor) => {
          if (anchor.href && !anchor.getAttribute(handledClickAttributeName)) {
            anchor.setAttribute(handledClickAttributeName, 'true');
            anchor.addEventListener('click', (e) => {
              options.onAnchorClick.call(point, e, anchor);
            });
          }
        });
    }
  });
}
