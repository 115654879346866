import React, { Dispatch, SetStateAction } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import {
  MetricTitle,
  MetricsButtonsContainer,
  SavedMetricBlock,
  MetricInfoContainer,
  ActionButtonsContainer,
} from 'components/dashboard/Metrics/Create/DefinitionsMetricsList/styles';
import { MetricInfo } from 'components/dashboard/Metrics/common/MetricInfo/MetricInfo';
import { getMetricDescription } from 'components/dashboard/Metrics/metrics.helpers';
import { BIMetricCreated } from 'components/dashboard/Metrics/metrics.types';

interface Props {
  metricsFromList: BIMetricCreated[];
  setIsSideBarOpen: Dispatch<SetStateAction<boolean>>;
  onRemoveMetric: (metric: BIMetricCreated) => void;
}

export const FunnelMetricsList: React.FC<Props> = ({
  metricsFromList,
  setIsSideBarOpen,
  onRemoveMetric,
}) => {
  return (
    <>
      {metricsFromList?.map((metric: BIMetricCreated) => (
        <SavedMetricBlock key={metric._id}>
          <MetricInfoContainer>
            <MetricTitle>{metric?.name ?? metric.name}</MetricTitle>
            <MetricInfo
              metric={metric}
              metricDescription={getMetricDescription(metric, true)}
            />
          </MetricInfoContainer>

          <ActionButtonsContainer>
            <BuButton
              borderless
              icon
              size={BuControlSize.SMALL}
              onClick={() => onRemoveMetric(metric)}
            >
              <BuIcon name={BoostUpIcons.Trash} />
            </BuButton>
          </ActionButtonsContainer>
        </SavedMetricBlock>
      ))}

      <div>
        <span>Add an existing metric</span>
      </div>

      <MetricsButtonsContainer>
        <BuButton secondary onClick={() => setIsSideBarOpen(true)}>
          + Existing Metric
        </BuButton>
      </MetricsButtonsContainer>
    </>
  );
};
