import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { DateFilterOptionsResponse } from 'components/dashboard/Metrics/metrics.types';
import { fetchApi } from 'utils/network';

export const useTimeOptions = () => {
  const [options, setOptions] = useState<DateFilterOptionsResponse>({
    predefined: [],
    fiscal_year: [],
  });

  const getOptionsFromAPI = async () => {
    fetchApi<string, DateFilterOptionsResponse>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_date_filter_settings`,
      queryMethod: 'get',
      setData: (result) => {
        setOptions(result);
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load date filter options: ${error}`);
      },
    });
  };

  useEffect(() => {
    getOptionsFromAPI();
  }, []);

  return { options };
};
