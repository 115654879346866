import {
  IBusinessType,
  IBusinessTypesSettings,
} from 'components/settings/DefineBusinessType/types';

export const getNewBusinessType = (
  businessTypesSettings: IBusinessTypesSettings
): IBusinessType => {
  return {
    name: '',
    values: [],
    amount_field:
      businessTypesSettings.availableValues?.amount_field?.[0] || '',
    close_date_field:
      businessTypesSettings.availableValues?.close_date_field?.[0] || '',
    forecast_category_field:
      businessTypesSettings.availableValues?.forecast_category_field?.[0] || '',
    owner_field: businessTypesSettings.availableValues?.owner_field?.[0] || '',
    user_roles: businessTypesSettings.availableValues?.user_roles
      ? [businessTypesSettings.availableValues?.user_roles?.[0]]
      : [],
    order:
      (businessTypesSettings?.businessTypes &&
        Object.keys(businessTypesSettings.businessTypes).length + 1) ||
      1,
    enabled: true,
  };
};
