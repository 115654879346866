import { createTranscriptAction as actions } from '../../../actions/transcriptActions';
import store from '../../../store/configureStore';

export function play() {
  const audio = document.getElementById('music');
  audio.play();
  store.dispatch(actions.updateState('isPlaying', true));
}
export function pause() {
  const audio = document.getElementById('music');
  store.dispatch(actions.updateState('isPlaying', false));
  audio.pause();
}

export function resetPlayer() {
  const audio = document.getElementById('music');
  audio.currentTime = 0;
  audio.pause();
  store.dispatch(actions.resetActiveTopic());
  store.dispatch(actions.resetSearch());
}

export function forward() {
  const audio = document.getElementById('music');
  audio.currentTime = audio.currentTime + 10;
}

export function backward() {
  const audio = document.getElementById('music');
  audio.currentTime = audio.currentTime - 10;
}

export function setCurrentTime(time) {
  const audio = document.getElementById('music');
  audio.currentTime = time; // seek to new offset
  audio.play();
  store.dispatch(actions.updateState('isPlaying', true));
}

export function getPlayingProgressWidth(currentTimeOffset, duration) {
  let playLineWidth = 0;
  if (currentTimeOffset === null) {
    playLineWidth = 0;
  } else if (currentTimeOffset > duration - 4) {
    playLineWidth = getPercentageOffset(currentTimeOffset, duration) + 40;
  } else {
    playLineWidth = getPercentageOffset(currentTimeOffset, duration) + 20;
  }
  return playLineWidth;
}

export function getPercentageOffset(time, duration) {
  const timeline = document.getElementById('timeline');
  if (!timeline) return 0;
  const timelineWidth = timeline.offsetWidth;
  return timelineWidth * (time / duration);
}

export function changePlayback(type, playbackRate) {
  const audio = document.getElementById('music');
  let newRate = 0;
  switch (playbackRate) {
    case 1: {
      newRate = type === 'faster' ? 1.25 : 1;
      store.dispatch(actions.updateState('playbackRate', newRate));
      audio.playbackRate = newRate;
      break;
    }
    case 1.25: {
      newRate = type === 'faster' ? 1.5 : 1;
      store.dispatch(actions.updateState('playbackRate', newRate));
      audio.playbackRate = newRate;
      break;
    }
    case 1.5: {
      newRate = type === 'faster' ? 1.5 : 1.25;
      store.dispatch(
        actions.updateState('playbackRate', type === 'faster' ? 1.5 : 1.25)
      );
      audio.playbackRate = newRate;
      break;
    }
    default:
      return null;
  }
}
