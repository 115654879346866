import classNames from 'classnames';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';

import { BUYER_ENGAGEMENT_PAGE_SIZE } from 'common/constants';
import BuGroupButton from 'components/UI/BuGroupButton';
import EngagementTable from 'components/UI/EngagementTable/TeamEngagement';
import s from 'components/UI/EngagementTable/styles';
import {
  DataState,
  EngagementTableProps,
  RequestState,
} from 'components/UI/EngagementTable/types';
import { useBoundary } from 'components/hooks/useBoundary';

const dataInitialState = {
  total: 0,
  list: [],
  initiated: false,
};

const requestInitialState = {
  page_size: BUYER_ENGAGEMENT_PAGE_SIZE,
  page_number: 0,
  sort: '-last_engaged_at',
};

export default (props: EngagementTableProps) => {
  const isFirstLoad = useBoundary(props.position);

  const [buyerEngagementState, setBuyerEngagementState] = useState<DataState>(
    dataInitialState
  );
  const [teamEngagementState, setTeamEngagementState] = useState<DataState>(
    dataInitialState
  );
  const [partnerEngagementState, setPartnerEngagementState] = useState<
    DataState
  >(dataInitialState);
  const [
    buyerEngagementRequestState,
    setBuyerEngagementRequestState,
  ] = useState<RequestState>(requestInitialState);
  const [teamEngagementRequestState, setTeamEngagementRequestState] = useState<
    RequestState
  >(requestInitialState);
  const [
    partnerEngagementRequestState,
    setPartnerEngagementRequestState,
  ] = useState<RequestState>(requestInitialState);

  const [loadMoreStatus, setLoadMoreStatus] = useState('none');
  const [sortingStatus, setSortingStatus] = useState('none');
  const [tabsState, setTabsState] = useState('buyer');

  useEffect(() => {
    let controller: AbortController | null = null;

    if (!isFirstLoad) {
      controller = new AbortController();

      fetchData(
        buyerEngagementRequestState,
        controller,
        buyerEngagementState,
        setBuyerEngagementState,
        'prospect_engagement',
        setLoadMoreStatus,
        false
      );
    }

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [buyerEngagementRequestState.page_number, isFirstLoad]);

  useEffect(() => {
    let controller: AbortController | null = null;

    if (!isFirstLoad) {
      controller = new AbortController();

      fetchData(
        teamEngagementRequestState,
        controller,
        teamEngagementState,
        setTeamEngagementState,
        'sales_team_engagement',
        setLoadMoreStatus,
        false
      );
    }

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [teamEngagementRequestState.page_number, isFirstLoad]);

  useEffect(() => {
    const controller = new AbortController();
    fetchData(
      partnerEngagementRequestState,
      controller,
      partnerEngagementState,
      setPartnerEngagementState,
      'partner_engagement',
      setLoadMoreStatus,
      false
    );
    return () => {
      controller.abort();
    };
  }, [partnerEngagementRequestState.page_number]);

  useEffect(() => {
    const controller = new AbortController();
    if (buyerEngagementState.total > 0 && buyerEngagementState.initiated) {
      fetchData(
        buyerEngagementRequestState,
        controller,
        buyerEngagementState,
        setBuyerEngagementState,
        'prospect_engagement',
        setSortingStatus,
        true
      );
    }
    return () => {
      controller.abort();
    };
  }, [buyerEngagementRequestState.sort]);

  useEffect(() => {
    const controller = new AbortController();
    if (teamEngagementState.total > 0 && teamEngagementState.initiated) {
      fetchData(
        teamEngagementRequestState,
        controller,
        teamEngagementState,
        setTeamEngagementState,
        'sales_team_engagement',
        setSortingStatus,
        true
      );
    }
    return () => {
      controller.abort();
    };
  }, [teamEngagementRequestState.sort]);

  useEffect(() => {
    const controller = new AbortController();
    if (partnerEngagementState.total > 0 && partnerEngagementState.initiated) {
      fetchData(
        partnerEngagementRequestState,
        controller,
        partnerEngagementState,
        setPartnerEngagementState,
        'partner_engagement',
        setSortingStatus,
        true
      );
    }
    return () => {
      controller.abort();
    };
  }, [partnerEngagementRequestState.sort]);

  async function fetchData(
    request: RequestState,
    controller: AbortController,
    state: DataState,
    setState: Function,
    engagementType: string,
    loading: Function,
    isSorting: boolean
  ) {
    try {
      loading('loading');
      const objId = props.dealId || props.accountId;
      const objType = props.dealId ? 'deal' : 'account';
      const url = new URL(
        `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/${objId}/${engagementType}/${objType}`,
        `${window.location.origin}` // Add fallback base, since BACKEND_URL may be empty
      );
      Object.keys(request).forEach((key) =>
        // @ts-ignore
        url.searchParams.append(key, request[key])
      );
      // @ts-ignore
      const response = await fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        method: 'GET',
        signal: controller.signal,
      });

      const result = await response.json();
      setState({
        total: get(result, 'data.total_count', 0),
        list: isSorting
          ? get(result, 'data.contacts', [])
          : state.list.concat(get(result, 'data.contacts', [])),
        initiated: true,
      });
      loading('none');
    } catch (err) {
      if (err.name === 'AbortError') {
      }
    }
  }

  const isBuyerShown = tabsState === 'buyer';
  const isTeamShown = tabsState === 'team';
  const isPartnerShown = tabsState === 'partner';

  const buyerClasses = classNames({
    [s.btn_tab]: true,
    active: isBuyerShown,
  });

  const teamClasses = classNames({
    [s.btn_tab]: true,
    active: isTeamShown,
  });

  const partnerClasses = classNames({
    [s.btn_tab]: true,
    active: isPartnerShown,
  });

  function onLoadMore(state: RequestState, setState: Function) {
    setState({ ...state, page_number: state.page_number + 1 });
  }

  function onSortingChange(
    request: RequestState,
    setState: Function,
    sortField: string
  ) {
    const isCurrentDesc = request.sort.includes('-');

    // if the same field but asc
    if (isCurrentDesc && request.sort.includes(sortField)) {
      setState({ ...request, sort: sortField, page_number: 0 });
    }

    // if the same field but desc
    if (!isCurrentDesc && request.sort === sortField) {
      setState({ ...request, sort: `-${sortField}`, page_number: 0 });
    }

    // another field
    if (!request.sort.includes(sortField)) {
      setState({ ...request, sort: `-${sortField}`, page_number: 0 });
    }
  }

  const buildOptionGroupData = () => {
    return [
      {
        id: 'buyer',
        text: `Buying Team Engagement (${get(
          buyerEngagementState,
          'total',
          0
        )})`,
      },
      {
        id: 'team',
        text: `Selling Team Engagement (${get(
          teamEngagementState,
          'total',
          0
        )})`,
      },
      {
        id: 'partner',
        text: `Partner Engagement (${get(partnerEngagementState, 'total', 0)})`,
      },
    ];
  };

  return (
    <>
      <div className={s.filters_container}>
        <div className={classNames(s.section_header, 'bu-font-heading')}>
          Engagement
        </div>
        <div className={s.buttons_box_content}>
          <BuGroupButton
            options={buildOptionGroupData()}
            selectedOption={tabsState}
            onSelect={(value: string) => {
              setTabsState(value);
            }}
          />
        </div>
      </div>
      {isBuyerShown && (
        <div id="engagement-tabs-table-buying-team">
          <EngagementTable
            contacts={buyerEngagementState.list}
            total={buyerEngagementState.total}
            onLoadMore={() =>
              onLoadMore(
                buyerEngagementRequestState,
                setBuyerEngagementRequestState
              )
            }
            status={loadMoreStatus}
            sortField={buyerEngagementRequestState.sort}
            onSortingChange={(value: string) =>
              onSortingChange(
                buyerEngagementRequestState,
                setBuyerEngagementRequestState,
                value
              )
            }
            sortingStatus={sortingStatus}
            paginationStatus=""
            pageNumber={0}
          />
        </div>
      )}
      {isTeamShown && (
        <div id="engagement-tabs-table-selling-team">
          <EngagementTable
            contacts={teamEngagementState.list}
            total={teamEngagementState.total}
            onLoadMore={() =>
              onLoadMore(
                teamEngagementRequestState,
                setTeamEngagementRequestState
              )
            }
            status={loadMoreStatus}
            sortField={teamEngagementRequestState.sort}
            onSortingChange={(value: string) =>
              onSortingChange(
                teamEngagementRequestState,
                setTeamEngagementRequestState,
                value
              )
            }
            sortingStatus={sortingStatus}
            paginationStatus=""
            pageNumber={0}
          />
        </div>
      )}
      {isPartnerShown && (
        <div id="engagement-tabs-table-partner">
          <EngagementTable
            contacts={partnerEngagementState.list}
            total={partnerEngagementState.total}
            onLoadMore={() =>
              onLoadMore(
                partnerEngagementRequestState,
                setPartnerEngagementRequestState
              )
            }
            status={loadMoreStatus}
            sortField={partnerEngagementRequestState.sort}
            onSortingChange={(value: string) =>
              onSortingChange(
                partnerEngagementRequestState,
                setPartnerEngagementRequestState,
                value
              )
            }
            sortingStatus={sortingStatus}
            paginationStatus=""
            pageNumber={0}
          />
        </div>
      )}
    </>
  );
};
