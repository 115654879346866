import { connect } from 'react-redux';

import { FILTER_NAME } from 'common/constants';
import ManageForecastModal from 'components/modals/ManageForecastModal/ManageForecastModal';
import { PersistedProps } from 'components/modals/ManageForecastModal/types';
import { IReduxState } from 'reducers/types';
import { getModalProps, getModalOptions, getFiltersForAPI } from 'selectors';

const stateToProps = (state: IReduxState) => ({
  modalProps: getModalProps<PersistedProps>(state, '/deals-status/:tab'),
  modalOptions: getModalOptions<PersistedProps>(state, '/deals-status/:tab'),
  filters: getFiltersForAPI(state, FILTER_NAME.ForecastOpportunitiesModal),
});

export default connect(stateToProps)(ManageForecastModal);
