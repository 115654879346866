const defaultFilters = {
  opportunity_stages: [],
  roles: [],
  users: [],
  risk_categories: [],
  win_probability: [],
  risk_score: [],
  opportunity_name: '',
  forecast_category_names: [],
  report_spec_id: '',
  close_date_interval: null,
  flat: true,
  sort: '',
  page_number: 0,
  page_size: 25,
  opportunity_types: [],
  competitors: [],
  custom_filters: {},
  change_interval: 'TQU',
  is_forecast: false,
};

export const defaultTab = {
  filters: defaultFilters,
  deals: {
    data: { count: 0 },
    list: [],
    status: 'notAsked',
    paginationStatus: 'notAsked',
  },
  stats: {},
  isFiltersOpened: false,
  scroll: 0,
};

export default {
  dealStatus: {},
  pipeline: {
    filters: {
      opportunity_stages: [],
      users: [],
      roles: [],
      risk_categories: [],
      forecast_category_names: [],
      win_probability: [],
      risk_score: [],
      opportunity_name: '',
      competitors: [],
      report_spec_id: '',
      page_size: 10,
      close_date_interval: ['ALT'],
      flat: true,
      custom_filters: {},
    },
    deals: {
      data: {
        count: 0,
      },
      list: [],
      status: 'notAsked',
      paginationStatus: 'notAsked',
    },
    stats: {
      totals: {},
      stages: [],
      by_date: [],
      sellers: [],
      status: 'notAsked',
    },
    isFiltersOpened: false,
    dealsForWidget: {
      list: [],
      status: 'notAsked',
      count: 0,
    },
  },
  topOpportunities: {
    filters: {
      opportunity_stages: ['__active__'],
      users: [],
      risk_categories: [],
      win_probability: [],
      risk_score: [],
      opportunity_name: '',
      forecast_category_names: [],
      report_spec_id: '',
      close_date_interval: ['NQU'],
      flat: true,
      competitors: [],
      sort: '',
      page_number: 0,
      page_size: 50,
      opportunity_types: [],
      custom_filters: {},
    },
    deals: {
      data: {
        count: 0,
      },
      list: [],
      status: 'notAsked',
      paginationStatus: 'notAsked',
      changes: null,
    },
    stats: {},
    isFiltersOpened: false,
    scroll: 0,
  },
  forecast: {
    filters: {
      opportunity_stages: ['__active__'],
      users: [],
      risk_categories: [],
      win_probability: [],
      risk_score: ['__all__'],
      opportunity_name: '',
      forecast_category_names: [],
      report_spec_id: '',
      close_date_interval: ['TQU'],
      flat: true,
      is_forecast: true,
      sort: '',
      competitors: [],
      page_number: 0,
      page_size: 50,
      opportunity_types: [],
      custom_filters: {},
      change_interval: 'L7D',
    },
    deals: {
      data: {
        count: 0,
      },
      list: [],
      status: 'notAsked',
      paginationStatus: 'notAsked',
    },
    stats: {},
    isFiltersOpened: false,
    scroll: 0,
  },
  activity: {
    filters: {
      opportunity_stages: [],
      users: [],
      risk_categories: [],
      win_probability: [],
      risk_score: [],
      opportunity_name: '',
      forecast_category_names: [],
      report_spec_id: '',
      close_date_interval: null,
      flat: true,
      sort: '',
      page_number: 0,
      page_size: 10,
      opportunity_types: [],
      custom_filters: {},
      change_interval: '1W',
    },
    deals: {
      data: {
        count: 0,
      },
      list: [],
      status: 'notAsked',
      paginationStatus: 'notAsked',
    },
    stats: {},
    isFiltersOpened: false,
    scroll: 0,
  },
  allDeals: {
    filters: {
      opportunity_stages: ['__active__'],
      users: [],
      risk_categories: [],
      win_probability: [],
      risk_score: [],
      forecast_category_names: [],
      opportunity_name: '',
      report_spec_id: '',
      close_date_interval: ['ALT'],
      flat: true,
      top_opportunities: true,
      competitors: [],
      custom_filters: {},
      sort: '',
    },
    deals: {
      data: {
        count: 0,
        totalAmount: 0,
        amount_at_risk: 0,
        amount_on_track: 0,
      },
      list: [],
      status: 'notAsked',
      paginationStatus: 'notAsked',
    },
    stats: {
      totals: {},
      stages: [],
      by_date: [],
      sellers: [],
      status: 'notAsked',
    },
    customReports: {},
    isFiltersOpened: false,
  },
  winAndLoss: {
    filters: {
      opportunity_stages: [],
      users: [],
      risk_categories: [],
      win_probability: [],
      risk_score: [],
      forecast_category_names: [],
      opportunity_name: '',
      report_spec_id: '',
      close_date_interval: ['ALT'],
      deal_status: 'Closed',
      flat: true,
      custom_filters: {},
    },
    deals: {
      data: {
        count: 0,
      },
      list: [],
      status: 'notAsked',
      paginationStatus: 'notAsked',
    },
    stats: {
      totals: {},
      stages: [],
      by_date: [],
      sellers: [],
      status: 'notAsked',
    },
    isFiltersOpened: false,
  },
  analytics: {
    filters: {
      opportunity_stages: [],
      users: [],
      risk_categories: [],
      forecast_category_names: [],
      win_probability: [],
      risk_score: [],
      opportunity_name: '',
      report_spec_id: '',
      page_size: 10,
      page_number: 0,
      opportunity_types: [],
      close_date_interval: ['NQU'],
      flat: true,
      custom_filters: {},
    },
    deals: {
      data: {
        count: 0,
      },
      list: [],
      status: 'notAsked',
      paginationStatus: 'notAsked',
    },
    stats: {
      totals: {},
      stages: [],
      by_date: [],
      sellers: [],
      status: 'notAsked',
    },
    isFiltersOpened: false,
  },
  recommendations: {
    filters: {},
    deals: {},
    stats: {},
    isFiltersOpened: false,
    scroll: 0,
  },
  pipelineRecommendations: {
    filters: {},
    deals: {},
    stats: {},
    isFiltersOpened: false,
    scroll: 0,
  },
  action: {
    filters: {},
    deals: {},
    stats: {},
    isFiltersOpened: false,
    scroll: 0,
  },
  actionFilters: {
    waitingResponse: {
      prospect_waiting_response: true,
    },
    notEngagedSince: {
      not_engaged_since: 7,
    },
  },
  recommendationFilters: {
    notEngaged7: {
      is_recommendations: true,
      is_forecast: true,
      not_engaged: 7,
    },
    notEngaged30: {
      is_recommendations: true,
      not_engaged: 30,
    },
    oneContact: {
      is_recommendations: true,
      one_contact_engaged: true,
    },
    noActivity7: {
      is_recommendations: true,
      is_forecast: true,
      no_activity: 7,
    },
    noActivity30: {
      is_recommendations: true,
      no_activity: 30,
    },
    highRisk: {
      is_recommendations: true,
      risk_score: ['high'],
    },
    lowRisk: {
      is_recommendations: true,
      risk_score: ['low'],
    },
    highRiskForecast: {
      is_recommendations: true,
      risk_score: ['high'],
      is_forecast: true,
    },
    competitorMentions: {
      competitor_mentions: 1,
      is_recommendations: true,
    },
  },
  prospect_account_dashboard: {
    filters: {
      ...defaultFilters,
      roles: ['Account Executive'],
    },
    deals: {
      status: 'notAsked',
    },
    stats: {
      status: 'notAsked',
    },
    customReports: {},
    isFiltersOpened: false,
  },
  dealDetailed: {
    dumb: {
      id: undefined,
      status: 'notAsked',
      data: {},
      calculated_data: {},
      emailModal: {
        data: {},
        isOpenModal: false,
      },
    },
  },
  currentTab: null,
  count: {},
  stages: [],
  forceRefresh: false,
  fieldsDescriptions: {},
  selectedModalLabel: undefined,
  extremeItemsTrends: {
    first: undefined,
    last: undefined,
  },
};
