import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import classNames from 'classnames';
import { css } from 'emotion';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Accordion, Container, Loader } from 'semantic-ui-react';

import { actions } from 'actions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { FILTER_NAME } from 'common/constants';
import BuIcon from 'components/UI/BuIcon';
import { AccordionHeader } from 'components/UI/common/TableCollection/TableCollection';
import FiltersPanel from 'components/settings/ManageFilters/ManagePageDefaults/FiltersPanel';
import { SettingsView } from 'components/settings/Settings/styles';
import { apiUserProfile } from 'components/settings/UserProfiles/UserProfiles';
import {
  backgroundOnHover,
  contentWithoutPadding,
  fixHeadersInAccordion,
} from 'components/settings/styles';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import {
  getFiltersForProfile,
  getFiltersInitialState,
  getIsFiltersOnModalsEnabled,
  getSettingsFiltersTabsState,
} from 'selectors';
import { fetchApi } from 'utils/network';

type OwnProps = {
  profileId?: string;
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type Props = OwnProps & StateProps & DispatchProps;

const colorOnOpen = css`
  &.ui.header > {
    .icon,
    .content {
      color: var(--bu-primary-500);
    }
  }
`;

const colorOnHoverParent = css`
  *:hover > & {
    ${colorOnOpen}
  }
`;

const upperCaseFirst = css`
  &::first-letter {
    text-transform: capitalize;
  }
`;

const panelTitleStyle = css`
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
`;

type Sections = {
  [key: string]: { section: string; tab: string; title: string }[];
};

const ManagePageDefaults: React.FC<Props> = ({
  filtersForProfile,
  initialState,
  profileFiltersLoaded,
  profileId,
  tabsState,
  initFilters,
  user,
}) => {
  const defaults = profileId ? filtersForProfile || {} : initialState;

  const sections = Object.keys(defaults).reduce<Sections>((acc, tab) => {
    const { section, title } = tabsState[tab] || {};

    if (section && title) {
      if (!acc[section]) {
        acc[section] = [];
      }

      acc[section].push({ section, tab, title });
    }

    return acc;
  }, {});

  const accordions = Object.keys(sections).sort((a, b) => (a < b ? -1 : 0));
  const isInitialStateReady = !!Object.keys(initialState).length;
  const isLoading = accordions.length === 0;

  let abortController: AbortController | null = null;

  const [activeIndex, setActiveIndex] = useState('');
  const [isProfileLoading, setIsProfileLoading] = useState(!!profileId);

  const isFiltersOnModalsEnabled = useSelector(getIsFiltersOnModalsEnabled);

  const fetchFilters = () => {
    if (isInitialStateReady && profileId) {
      abortController = new AbortController();

      fetchApi<undefined, any>({
        queryMethod: 'get',
        setData: (data) => {
          profileFiltersLoaded({ filtersState: data, profileId });
          setIsProfileLoading(false);
        },
        signal: abortController.signal,
        url: `${apiUserProfile}/${profileId}/filters`,
      });
    }
  };

  useEffect(() => {
    fetchFilters();

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [isInitialStateReady, profileId]);

  const handleLock = useCallback(
    (tab: string, name: string) => {
      if (filtersForProfile) {
        abortController = new AbortController();
        const filters: string[] = [];

        Object.keys(filtersForProfile[tab]).forEach((filterName) => {
          const currentLockedStatus =
            filtersForProfile[tab][filterName].isLocked;

          const condition =
            filterName === name ? !currentLockedStatus : currentLockedStatus;

          if (condition) {
            filters.push(filterName);
          }
        });

        fetchApi<string, any>({
          queryMethod: 'put',
          queryParams: JSON.stringify({ filters }),
          setData: () => {
            fetchFilters();
            initFilters({ role: user.role, email: user.email });
          },
          signal: abortController.signal,
          url: `${apiUserProfile}/${profileId}/filters/${tab}/locked_filters`,
        });
      }
    },
    [JSON.stringify(filtersForProfile)]
  );

  const handleRemove = () => {
    initFilters({ role: user.role, email: user.email });
  };

  return (
    <>
      {!profileId && (
        <SettingsView.HeaderContainer>
          <SettingsView.Header>
            Manage Page Default Values
            <SettingsView.SubHeader>
              Define selected values for pages.
            </SettingsView.SubHeader>
          </SettingsView.Header>
        </SettingsView.HeaderContainer>
      )}

      {isLoading || isProfileLoading ? (
        <Loader active inline="centered" />
      ) : (
        <Accordion
          exclusive
          fluid
          styled
          className={classNames(
            backgroundOnHover,
            contentWithoutPadding,
            fixHeadersInAccordion
          )}
        >
          {accordions.map((sectionName) => {
            const isOpen = activeIndex === sectionName;

            return (
              <div key={sectionName}>
                <Accordion.Title
                  active={isOpen}
                  index={sectionName}
                  onClick={() => setActiveIndex(isOpen ? '' : sectionName)}
                >
                  <AccordionHeader
                    className={classNames(
                      'bu-font-section-title',
                      upperCaseFirst,
                      {
                        active: isOpen,
                      }
                    )}
                  >
                    <BuIcon
                      name={
                        isOpen
                          ? BoostUpIcons.SquareMinus
                          : BoostUpIcons.SquarePlus
                      }
                    />

                    {sectionName}
                  </AccordionHeader>
                </Accordion.Title>

                <Accordion.Content active={activeIndex === sectionName}>
                  <Container fluid>
                    {sections[sectionName].map(({ tab, title }) => {
                      if (tab === 'forecast_roll_ups') {
                        /* Forecast Rollups Configurations should be configured in `Rollups Column Config` settings [VPD-10014] [myk.hub] */
                        return null;
                      }

                      if (
                        tab === FILTER_NAME.ForecastOpportunitiesModal &&
                        !isFiltersOnModalsEnabled
                      ) {
                        return null;
                      }

                      return (
                        <section key={tab}>
                          <div className={panelTitleStyle}>{title}</div>
                          <FiltersPanel
                            handleLock={handleLock}
                            handleRemove={handleRemove}
                            profileId={profileId}
                            tab={tab}
                          />
                        </section>
                      );
                    })}
                  </Container>
                </Accordion.Content>
              </div>
            );
          })}
        </Accordion>
      )}
    </>
  );
};

const mapStateToProps = (state: IReduxState, { profileId }: OwnProps) => ({
  filtersForProfile: profileId
    ? getFiltersForProfile(state, profileId)
    : undefined,
  initialState: getFiltersInitialState(state),
  tabsState: getSettingsFiltersTabsState(state),
  user: selectors.getUser(state),
});

const mapDispatchToProps = {
  profileFiltersLoaded: actions.ui.filtersSettings.profileFiltersLoaded,
  initFilters: actions.ui.filters.init,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagePageDefaults);
