import { css } from 'emotion';

import { fontDisplay } from 'assets/css/common';

export const toggle_btn = css`
  z-index: 989;
  display: block;
  padding: 3px 20px;
  font-size: 10px;
  border-radius: 0;
  cursor: pointer;
  position: absolute;
  left: 50%;
  bottom: -16px;
  transform: translateX(-50%);
  width: 99%;
  overflow: hidden;
  border: none;
  background: none;

  span {
    display: inline-block;
    border: 1px solid #ddd;
    height: 100%;
    border-radius: 15px;
    padding: 3px 15px 3px 24px;
    background: #fff;
    transition: 0.4s;
    ${fontDisplay};
    font-size: 12px;
    box-shadow: rgb(0 0 0 / 30%) 0px 0px 2px 0px;
    color: var(--bu-gray-700);
    position: relative;

    i {
      transform: rotate(90deg);
      display: inline-block;
      font-size: 16px;
      position: absolute;
      left: 10px;
      bottom: 6px;
      color: var(--bu-primary-500);

      &.down {
        transform: rotate(270deg);
        left: 6px;
        bottom: 0;
        top: 8px;
      }
    }
  }

  &:before {
    content: '';
    left: 0;
    right: 0;
    background: #ddd;
    width: 100%;
    z-index: -1;
    position: absolute;
    top: 13px;
    transition: 0.4s;
  }

  &.hidden_panel {
    bottom: -17px;

    &:before {
      height: 1px;
      top: 9px;
    }
  }

  &:hover {
    &:before {
      background: var(--bu-primary-500);
    }

    span {
      border-color: var(--bu-primary-500);
    }
  }

  &:focus {
    outline: none;
  }

  &.scrolled {
    z-index: 901;
  }
`;

export const metrics = css`
  display: block;
`;

export const metrics_main = css`
  margin-top: 8px;
  background: #fff;
  z-index: 1;
  position: relative;
  margin-bottom: 20px;
`;

export const totalPanelWrapper = css`
  display: flex;
  flex-direction: column;
  -webkit-transition: 0.35s;
  -o-transition: 0.35s;
  transition: 0.35s;
  visibility: visible;
  overflow: hidden;
  max-height: 100vh;

  &.hidden_panel {
    visibility: hidden;
    max-height: 0;
  }
`;

export const totalPanelRow = css`
  display: flex;
  height: 90px;
  align-items: end;
  margin-top: 10px;
  z-index: 0;
  overflow-x: visible;
  scroll-behavior: smooth;

  &:first-child {
    margin-top: 0;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
`;

export const totalPanel = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 67px;
  transition: all 0.2s ease-out;
  font-family: var(--bu-font-regular);
  background-color: var(--bu-gray-200);
  width: 100%;
  border-style: solid;
  border-color: var(--bu-gray-400);
  border-width: 1px;
  text-align: left;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  box-shadow: 0 0 0 0 #00000033;

  &:not(&:first-child) {
    margin-left: -1px;
  }

  &.is_selected {
    z-index: 2;
    box-shadow: var(--bu-shadow-deep);
    background-color: white;
    height: 86px;
    transition: all 0.2s;
    border-bottom: 2px var(--bu-primary-500) solid;
  }

  &.initial_view {
    background-color: white;
    height: 86px;
    transition: all 0.2s;
  }

  &:focus {
    outline: none;
  }

  &:hover:not(&.is_selected) {
    background-color: var(--bu-gray-100);
  }

  &:hover:not(&.is_selected):not(&.initial_view) {
    z-index: 2;
    box-shadow: 1px 1px 4px 1px #00000033;
    height: 70px;
  }
`;

export const totalBox = css`
  padding: 16px 21px;
`;

export const details = css`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: var(--bu-gray-700);
`;

export const highRisk = css`
  color: red;
`;

export const totalFooter = css`
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
`;

export const totalTitle = css`
  color: var(--bu-gray-900);
  font-family: var(--bu-font-regular);
  font-weight: normal;
  font-size: 14px;
  white-space: nowrap;
`;

export const totalCurrency = css`
  color: var(--bu-gray-900);
  ${fontDisplay};
  letter-spacing: 0;
  line-height: 24px;
`;

export const changedValue = (type: string | any) => css`
  color: ${type === 'up' ? 'var(--bu-green-500)' : 'var(--bu-red-500)'};
  background-color: ${type === 'up' ? '#EAFAF0' : '#FFEBEB'};
  font-family: var(--bu-font-regular);
  font-size: 12px;
  letter-spacing: 0;
  margin-left: 2px;
  padding: 2px 5px 2px 0px;
  border-radius: 2px;

  &:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: middle;
    margin: 0 3px;
  }
`;

export const selectedBg = css`
  background: #efefef;
`;

export const hideMetricsButtonIcon = css`
  width: 8px;
  margin-right: 5px;
  transform: rotate(90deg);
  display: inline-block;
`;

export const scrollBtn = css`
  position: absolute;
  top: calc(50% - 13px);
  background: white;
  border: 2px solid #ddd;
  border-radius: 13px;
  width: 26px;
  height: 26px;
  cursor: pointer;

  &.left {
    left: -13px;
  }

  &.right {
    right: -13px;
  }
`;
