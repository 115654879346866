import styled from '@emotion/styled';
import CSS from 'csstype';

export const FlexRow = styled.div((props: { cssProps?: CSS.Properties }) => ({
  display: 'flex',
  ...props.cssProps,
}));

export const RemoveIconContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  flexShrink: 0,
  cursor: 'pointer',
});

export const MetricCreateSubTitle = styled.div({
  fontSize: '14px',
  fontWeight: 600,
  color: 'var(--bu-gray-900)',
  marginBottom: '4px',
  span: {
    marginRight: '5px',
  },
});

export const TitleNotEditing = styled.div({
  display: 'flex',
  alignItems: 'center',
  p: {
    margin: '0px 8px 0px 0px',
    fontWeight: 315,
    fontSize: '18px',
    lineHeight: '21.6px',
  },
  '.icon-button': {
    visibility: 'hidden',
    cursor: 'pointer',
    i: {
      fontSize: '20px',
    },
  },
  '&:hover': {
    '>.icon-button': {
      visibility: 'unset',
    },
  },
});

export const ExclamationIcon = styled.div({
  display: 'inline-block',
});
