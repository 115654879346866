import { css } from 'emotion';
import moment from 'moment';
import * as R from 'ramda';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Icon, Loader } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { notNullAndEmpty } from 'common/utils';
import BuButton from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import { ColumnTypesCallback } from 'components/UI/TableConfig/column-helper';
import { TableConfigurationColumn } from 'components/UI/TableConfig/types';
import { IDataCellProps } from 'components/UI/common/TypedTable/TypedTable';
import NotAvailableCell from 'components/UI/common/TypedTable/renderers/NotAvailableCell';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import {
  CustomComponentFunction,
  getFieldValue,
} from 'components/UI/common/TypedTable/renderers/custom/common';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { openModal } from 'navigation/utils';
import * as selectors from 'selectors';

const syncButton = css`
  &.bu-button-icon {
    border-radius: 100%;
    font-size: 9px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin: 0 10px 0 0;
    padding: 0;

    .bu-icon {
      color: var(--bu-green-500);
    }
  }
`;

const container = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  & .deal {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;

    color: var(--bu-clickable-cell);

    &:hover,
    tr:hover & {
      color: var(--bu-clickable-cell__hover);
    }
  }
`;

export const CUSTOM_EXTRA_TYPE = 'SYNC_SF';

const customDealNameCell: CustomComponentFunction<{
  callback: ColumnTypesCallback;
  showSyncDealButton: boolean;
  columnConfig: TableConfigurationColumn;
}> = ({ callback, columnConfig, showSyncDealButton }) => ({
  column,
  row,
}: IDataCellProps) => {
  const { id, opportunity_name, activity_sync_info } = row;

  const isIcon = notNullAndEmpty(row.activity_sync_info);
  const { allow_single_record_sf_sync: isSyncSFEnabled = false } = useSelector(
    selectors.getFeatureFlags
  );
  const lastSyncDate = useSelector((state) => {
    return R.pathOr(
      getFieldValue('last_checked', row),
      ['deals', 'dealDetailed', id, 'data', 'last_checked'],
      state
    );
  });

  const dealsStatuses = useSelector(selectors.getDealStatuses);

  const dealStatus = dealsStatuses?.[id]?.status ?? '';

  const tooltipContent = isIcon ? (
    <>
      {/* @ts-ignore */}
      {activity_sync_info.map((e) => (
        <li key={e.created_at}>{e.text}</li>
      ))}
    </>
  ) : null;

  const syncTooltip = lastSyncDate ? (
    <>
      Last synced on{' '}
      <b>{moment(lastSyncDate as string).format('MMM DD [at] HH:mm a')}</b>
    </>
  ) : (
    'Sync with SF'
  );

  const handleClick = useCallback(() => {
    if (id) {
      openModal({
        scheme: '/deal/:id',
        params: { id: `${id}` },
      });

      AnalyticsTracker.event(
        {
          row,
        },
        {
          action: `Open details`,
          category: 'Deal details',
          label: 'Deal details modal opened',
        }
      );
    }
  }, [id, row.id]);

  return id || row._id ? (
    <div className={container}>
      {isSyncSFEnabled && showSyncDealButton && (
        <TooltipWrapper tooltip={syncTooltip} hoverable>
          <BuButton
            icon
            className={syncButton}
            onClick={() =>
              callback({
                columnConfig,
                column,
                row,
                objectId: null,
                extraData: {
                  type: CUSTOM_EXTRA_TYPE,
                },
              })
            }
          >
            {(dealStatus === '' || dealStatus === 'error') && (
              <BuIcon name={BoostUpIcons.Sync} />
            )}
            {dealStatus === 'loading' && <Loader size="tiny" active inline />}
            {dealStatus === 'success' && <BuIcon name={BoostUpIcons.Checked} />}
          </BuButton>
        </TooltipWrapper>
      )}

      {isIcon && (
        <TooltipWrapper tooltip={tooltipContent}>
          <Icon size="small" name="warning" color="red" />
        </TooltipWrapper>
      )}
      <TooltipWrapper tooltip={column.showTooltip && opportunity_name}>
        <div className="deal" onClick={handleClick}>
          {opportunity_name}
        </div>
      </TooltipWrapper>
    </div>
  ) : (
    <NotAvailableCell />
  );
};

export default customDealNameCell;
