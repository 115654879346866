import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import * as t from 'actions/revbi/actionTypes';
import * as metricsActions from 'actions/revbi/metrics';
import * as widgetsActions from 'actions/revbi/widgets';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import {
  formatWidgetForSaving,
  formatWidgetForUpdating,
} from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIMetricFormulaNewborn,
  BIMetricSimpleNewborn,
  BIMetricSimple,
  BIMetricFormula,
  BIWidgetPreview,
  BIWidget,
  BIDashboard,
  CloneMetricActionPayload,
  DeleteMetricActionPayload,
  CreateMetricExtendedActionPayload,
  UpdateMetricExtendedActionPayload,
  ComponentMode,
} from 'components/dashboard/Metrics/metrics.types';
import { history } from 'store/configureStore';
import { fetchApiWithoutCb } from 'utils/network';

export const mappingTakeEveryRevBISaga = [
  t.METRIC + t.GET,
  t.METRIC + t.UPDATE,
  t.COLUMN_FIELDS_FILTER + t.GET,
  t.COLUMN_FIELDS_FILTER + t.TIME_SERIES + t.GET,
  t.COLUMN_FIELDS + t.GET,
  t.METRIC + t.CREATE,
  t.OBJECT_LIST + t.GET,
  t.OBJECT_LIST + t.TIME_SERIES + t.GET,
  t.METRIC + t.GET + t.ALL,
  t.WIDGET + t.GET,
  t.METRIC + t.CREATE + t.FOR_WIDGET,
  t.METRIC + t.UPDATE + t.FOR_WIDGET,
  t.WIDGET + t.CREATE,
  t.WIDGET + t.UPDATE,
  t.WIDGET + t.CREATE + t.EXTENDED,
  t.WIDGET + t.UPDATE + t.EXTENDED,
  t.METRIC + t.CLONE,
  t.METRIC + t.DELETE,
  t.METRIC + t.CREATE + t.EXTENDED,
  t.METRIC + t.UPDATE + t.EXTENDED,
  t.METRIC_TIME_SERIES + t.GET + t.ALL,
  t.WIDGET + t.GET + t.COLUMN_FIELDS_FILTER,
  t.WIDGET + t.GET + t.EXTENDED,
];

export const mappingTakeLatestBISaga = [
  t.OBJECT_LIST + t.GET,
  t.METRIC_FORMULA + t.VALIDATE,
  t.METRIC + t.GET + t.ALL,
  t.WIDGET + t.GET + t.ALL,
];

export function* createNewMetricInWidgetCreationProccess(
  action: Action<{
    metric: BIMetricFormulaNewborn | BIMetricSimpleNewborn;
    widgetId?: string;
  }>
) {
  // creation new metric
  try {
    const {
      error,
      result,
    }: { error: string; result: BIMetricSimple | BIMetricFormula } = yield call(
      fetchApiWithoutCb,
      {
        queryMethod: 'post',
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics`,
        queryParams: action.payload.metric,
      }
    );

    if (error) {
      console.error(error);
      toast.error('Error! Failed to save metric.');
    } else {
      yield put(
        widgetsActions.addMetricToMetricsFromList({
          metric: result,
          widgetId: action.payload.widgetId,
        })
      );
    }
  } catch (e) {
    console.error(e);
    toast.error('Error! Failed to save metric.');
  }
}

export function* updateNewMetricInWidgetCreationProccess(
  action: Action<{
    metric: BIMetricFormula | BIMetricSimple;
    widgetId?: string;
  }>
) {
  // updating new metric
  try {
    const {
      error,
      result,
    }: { error: string; result: BIMetricSimple | BIMetricFormula } = yield call(
      fetchApiWithoutCb,
      {
        queryMethod: 'put',
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${action.payload.metric._id}`,
        queryParams: action.payload.metric,
      }
    );

    if (error) {
      console.error(error);
      toast.error('Error! Failed to update metric.');
    } else {
      yield put(
        metricsActions.updateMetricByIdSuccess({ id: result._id, data: result })
      );
      yield put(
        widgetsActions.updateMetricFromMetricsFromList({
          metric: result,
          widgetId: action.payload.widgetId,
          metricId: result._id,
        })
      );
    }
  } catch (e) {
    console.error(e);
    toast.error(e);
  }
}

export function* createWidgetExtended(
  action: Action<{
    widget: BIWidgetPreview;
    dashboardId?: string;
    redirect?: string;
  }>
) {
  try {
    const { payload } = action;

    yield put(widgetsActions.createWidgetLoading());
    const { error, result: newWidget }: { error: string; result: BIWidget } =
      yield call(fetchApiWithoutCb, {
        queryMethod: 'post',
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets`,
        queryParams: formatWidgetForSaving(payload.widget),
      });

    if (error) {
      console.error(error);
      toast.error('Error! Failed to save widget.');
    } else {
      yield put(widgetsActions.createWidgetSuccess(newWidget));
      if (payload.dashboardId) {
        const { result: dashboardToChange }: { result: BIDashboard } =
          yield call(fetchApiWithoutCb, {
            queryMethod: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards/${payload.dashboardId}`,
          });
        const changedDashboard: BIDashboard = {
          ...dashboardToChange,
          widget_list: dashboardToChange.widget_list.concat(
            newWidget._id || ''
          ),
        };
        yield call(fetchApiWithoutCb, {
          queryMethod: 'put',
          url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards/${payload.dashboardId}`,
          queryParams: changedDashboard,
        });
      }
      if (payload.redirect) {
        history.push(payload.redirect);
      }
    }
  } catch (e) {
    console.error(e);
    toast.error(e);
  }
}

export function* updateWidgetExtended(
  action: Action<{
    widget: BIWidgetPreview;
    dashboardId?: string;
    redirect?: string;
  }>
) {
  try {
    const { payload } = action;
    const { error, result: newWidget }: { error: string; result: BIWidget } =
      yield call(fetchApiWithoutCb, {
        queryMethod: 'put',
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets/${payload.widget._id}`,
        queryParams: formatWidgetForUpdating(payload.widget),
      });

    if (error) {
      console.error(error);
      toast.error('Error! Failed to update widget.');
    } else {
      yield put(widgetsActions.updateWidgetSuccess(newWidget));
      if (payload.dashboardId) {
        const { result: dashboardToChange }: { result: BIDashboard } =
          yield call(fetchApiWithoutCb, {
            queryMethod: 'get',
            url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards/${payload.dashboardId}`,
          });
        const changedDashboard: BIDashboard = {
          ...dashboardToChange,
          widget_list: dashboardToChange.widget_list.concat(newWidget),
        };
        yield call(fetchApiWithoutCb, {
          queryMethod: 'put',
          url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards/${payload.dashboardId}`,
          queryParams: changedDashboard,
        });
      }
      if (payload.redirect) {
        history.push(payload.redirect);
      }
    }
  } catch (e) {
    console.error(e);
    toast.error(e);
  }
}

export function* cloneMetric(action: Action<CloneMetricActionPayload>) {
  const { payload } = action;
  const { result: newMetric }: { result: BIMetricSimple | BIMetricFormula } =
    yield call(fetchApiWithoutCb, {
      queryMethod: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${payload.id}/clone`,
    });
  yield put(metricsActions.fetchAllMetrics());
  // yield put(metricsActions.fetchAllMetrics());
  if (payload.addToWidget) {
    yield put(
      widgetsActions.addMetricToMetricsFromList({
        metric: newMetric,
        widgetId: payload.addToWidget === 'create' ? undefined : 'someWidgetId',
      })
    );
  }
  if (payload.redirectToTheNewMetric) {
    yield put(push(`/revbi/metrics/edit/${newMetric._id}`));
  }
  if (payload.toast) {
    toast.success(`Metric "${newMetric.name}" has been cloned`);
  }
}

export function* deleteMetric(action: Action<DeleteMetricActionPayload>) {
  const { payload } = action;

  yield call(fetchApiWithoutCb, {
    queryMethod: 'delete',
    url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${payload.metric._id}`,
  });

  if (payload.redirect) {
    yield put(push(payload.redirect));
  }
  if (payload.toast) {
    toast.success(`Metric "${payload.metric.name}" has been removed`);
  }
}

export function* createMetricExtended(
  action: Action<CreateMetricExtendedActionPayload>
) {
  const { payload } = action;
  try {
    const {
      error,
      result: newMetric,
    }: { error: string; result: BIMetricSimple | BIMetricFormula } = yield call(
      fetchApiWithoutCb,
      {
        queryMethod: 'post',
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics`,
        queryParams: payload.metric,
      }
    );

    if (error) {
      toast.error('Error! Metric is not created.');
      console.error(error);
    } else {
      if (payload.redirect) {
        yield put(push(payload.redirect));
      }
      yield put(
        metricsActions.createMetricSuccess({
          metric: newMetric,
          addToWidgetCreationMetricsList:
            payload.addToWidgetCreationMetricsList,
        })
      );
      if (payload.toast) {
        toast.success(`Metric saved.`);
      }
    }
  } catch (e) {
    toast.error('Error! Metric is not created.');
    console.error(e);
  }
}

export function* updateMetricExtended(
  action: Action<UpdateMetricExtendedActionPayload>
) {
  const { payload } = action;
  try {
    const {
      error,
      result: newMetric,
    }: { error: string; result: BIMetricSimple | BIMetricFormula } = yield call(
      fetchApiWithoutCb,
      {
        queryMethod: 'put',
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${payload.metric._id}`,
        queryParams: payload.metric,
      }
    );

    if (error) {
      toast.error(`Error! Metric isn't saved.`);
      console.error(error);
    } else {
      if (payload.redirect) {
        yield put(push(payload.redirect));
      }
      yield put(
        metricsActions.updateMetricByIdSuccess({
          data: newMetric,
          id: newMetric._id,
        })
      );
      if (payload.toast) {
        toast.success(`Metric saved.`);
      }
    }
  } catch (e) {
    toast.error(`Error! Metric isn't saved.`);
    console.error(e);
  }
}

export function* getWidgetByIdExtended(action: Action<{ id: string }>) {
  const { payload } = action;
  try {
    const { result: widget }: { result: BIWidget } = yield call(
      fetchApiWithoutCb,
      {
        queryMethod: 'get',
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets/${payload.id}?expand=true`,
      }
    );
    yield put(
      widgetsActions.fetchWidgetByIdSuccess({
        id: payload.id,
        data: widget,
        isEditing: true,
      })
    );
    if (widget.analysis_type === AnalysisType.REPORT) {
      yield put(
        metricsActions.changeCreateMetricComponentMode(ComponentMode.EDIT)
      );
      yield put(
        metricsActions.fetchMetricById(widget.metric_list[0]._id, true)
      );
    }
  } catch (e) {
    toast.error('Error! Could not get widget from the server.');
    console.error(e);
  }
}
