import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { DropdownItemProps } from 'semantic-ui-react/dist/commonjs/modules/Dropdown/DropdownItem';

import * as userProfileActions from 'actions/userProfilesActions';
import { USER_ROLES } from 'common/constants';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuToggle from 'components/UI/BuToggle';
import * as s from 'components/settings/ManageUsers/UsersBulkUpdate/styles';
import { IProps } from 'components/settings/ManageUsers/UsersBulkUpdate/types';
import { UserProfile } from 'components/settings/UserProfiles/types';
import { USER_STATUS } from 'reducers/companyUsersReducer';
import * as selectors from 'selectors';

const switchLabel = (value?: boolean) => (value ? 'ON' : 'OFF');

const statusOptions: DropdownItemProps[] = [
  { text: 'None', value: '', key: 'not set' },
  { text: 'Active', value: 'active', key: 'active' },
  { text: 'Disabled', value: 'disabled', key: 'disabled' },
];

const UsersBulkUpdate: React.FC<IProps> = ({
  onCancel,
  onSave,
  managers,
  defaultValues,
  isAdminEnabled,
  disabledFields,
}) => {
  const dispatch = useDispatch();
  const profiles: UserProfile[] = useSelector(selectors.getUserProfilesList());

  const [role, setRole] = useState<string>('');
  const [manager, setManager] = useState<string>();
  const [status, setStatus] = useState<USER_STATUS>();
  const [profileId, setProfileId] = useState<string>('notAssigned');
  const [emailSync, setEmailSync] = useState<boolean>(false);
  const [calendarSync, setCalendarSync] = useState<boolean>(false);
  const [callsSync, setCallsSync] = useState<boolean>(false);
  const [isAlowImpersonation, setAllowImpersonation] = useState<boolean>(false);

  const [isRoleEnable, setRoleEnable] = useState(false);
  const [isManagerEnable, setManagerEnable] = useState(false);
  const [isStatusEnable, setStatusEnable] = useState(false);
  const [isProfileEnabled, setProfileEnabled] = useState(false);
  const [isEmailSyncEnable, setEmailSyncEnable] = useState(false);
  const [isCalendarSyncEnable, setCalendarSyncEnable] = useState(false);
  const [isCallsSyncEnable, setCallsSyncEnable] = useState(false);
  const [isAllowImpersonationEnable, setAllowImpersonationEnable] = useState(
    false
  );

  const [isTouched, setTouched] = useState(false);

  const [managersOptions, setManagersOptions] = useState<DropdownItemProps[]>(
    []
  );

  const profileOptions = [
    { key: 'notAssigned', text: 'not assigned', value: 'notAssigned' },
    ...profiles.map(({ id, name }) => ({
      key: id,
      text: name,
      value: id,
    })),
  ];

  useEffect(() => {
    if (defaultValues?.role !== undefined) {
      setRole(defaultValues.role || '');
      setRoleEnable(Boolean(defaultValues.role));
    }

    if (defaultValues?.manager !== undefined) {
      setManager(defaultValues.manager);
      setManagerEnable(Boolean(defaultValues.manager));
    }

    if (defaultValues?.status !== undefined) {
      setStatus(defaultValues.status);
      setStatusEnable(Boolean(defaultValues.status));
    }

    if (defaultValues?.profile !== undefined) {
      setProfileId(defaultValues.profile.id);
      setProfileEnabled(Boolean(defaultValues.profile));
    }

    if (defaultValues?.emailSync !== undefined) {
      setEmailSync(defaultValues.emailSync);
      setEmailSyncEnable(Boolean(defaultValues.emailSync));
    }

    if (defaultValues?.calendarSync !== undefined) {
      setCalendarSync(defaultValues.calendarSync);
      setCalendarSyncEnable(Boolean(defaultValues.calendarSync));
    }

    if (defaultValues?.callsSync !== undefined) {
      setCallsSync(defaultValues.callsSync);
      setCallsSyncEnable(Boolean(defaultValues.callsSync));
    }

    if (defaultValues?.impersonation !== undefined) {
      setAllowImpersonation(defaultValues.impersonation);
      setAllowImpersonationEnable(Boolean(defaultValues.impersonation));
    }
  }, [defaultValues]);

  useEffect(() => {
    setManagersOptions([
      {
        key: 'none',
        text: 'None',
        value: '',
      },
      ...managers
        .filter((item) => item.status === 'active')
        .map((item) => ({
          key: item.email,
          text: item.name || item.email,
          value: item.email,
          disabled: item.status === 'disabled',
        })),
    ]);
  }, [managers]);

  useEffect(() => {
    dispatch(userProfileActions.getUserProfilesList());
  }, []);

  const handleSaveClick = () => {
    onSave({
      is_user_impersonation_allowed: isAllowImpersonationEnable
        ? isAlowImpersonation
        : undefined,
      role: isRoleEnable ? role : undefined,
      manager: isManagerEnable ? manager : undefined,
      status: isStatusEnable ? status : undefined,
      profileId: isProfileEnabled
        ? profileId === 'notAssigned'
          ? null
          : profileId
        : undefined,
      user_settings: {
        processing: {
          emails: isEmailSyncEnable ? emailSync : undefined,
          calendar: isCalendarSyncEnable ? calendarSync : undefined,
          calls: isCallsSyncEnable ? callsSync : undefined,
        },
      },
    });
  };

  const handleProfileChange = (
    e: SyntheticEvent<HTMLElement>,
    {
      value,
    }: { value?: string | number | boolean | (number | string | boolean)[] }
  ) => {
    if (value) {
      setProfileId(value as string);
      setTouched(true);
    }
  };

  return (
    <div className={s.container}>
      <div className={s.header}>Bulk Update Fields</div>
      <div className={s.mainContent}>
        <div className={s.userHeaderContainer}>
          <div className="header">
            Note: The changes you will make will update all records.
          </div>
        </div>

        <div className={s.userDetailsContainer}>
          <div className="header">Select fields which you want to update</div>

          <div className="item">
            <div className="lab">
              <Checkbox
                label="Role:"
                checked={isRoleEnable}
                onChange={(e, { checked }) => {
                  setRoleEnable(checked || false);
                  setTouched(true);
                }}
                disabled={disabledFields.includes('role')}
              />
            </div>
            <div className="control">
              <Dropdown
                options={[
                  {
                    key: 'none',
                    text: 'None',
                    value: '',
                  },
                  ...Object.values(USER_ROLES).map((role) => ({
                    key: role,
                    text: role,
                    value: role,
                  })),
                ]}
                disabled={!isRoleEnable}
                onChange={(e, { value }) => {
                  setRole(value as string);
                  setTouched(true);
                }}
                value={role}
                placeholder="Select Role"
                selection
              />
            </div>
          </div>
          <div className="item">
            <div className="lab">
              <Checkbox
                label="Manager:"
                checked={isManagerEnable}
                onChange={(e, { checked }) => {
                  setManagerEnable(checked || false);
                  setTouched(true);
                }}
                disabled={disabledFields.includes('manager')}
              />
            </div>
            <div className="control">
              <Dropdown
                options={managersOptions}
                disabled={!isManagerEnable}
                onChange={(e, { value }) => {
                  setManager(value as string);
                  setTouched(true);
                }}
                value={manager}
                placeholder="Select Manager"
                selection
              />
            </div>
          </div>
          <div className="item">
            <div className="lab">
              <Checkbox
                label="Status:"
                checked={isStatusEnable}
                onChange={(e, { checked }) => {
                  setStatusEnable(checked || false);
                  setTouched(true);
                }}
                disabled={disabledFields.includes('status')}
              />
            </div>
            <div className="control">
              <Dropdown
                options={statusOptions}
                disabled={!isStatusEnable}
                onChange={(e, { value }) => {
                  setStatus(value as USER_STATUS);
                  setTouched(true);
                }}
                value={status}
                placeholder="Select Status"
                selection
              />
            </div>
          </div>
          {profileOptions.length > 1 && (
            <div className="item">
              <div className="lab">
                <Checkbox
                  label="Profile:"
                  checked={isProfileEnabled}
                  onChange={(e, { checked }) => {
                    setProfileEnabled(checked || false);
                    setTouched(true);
                  }}
                />
              </div>
              <div className="control">
                <Dropdown
                  options={profileOptions}
                  disabled={!isProfileEnabled}
                  onChange={handleProfileChange}
                  value={profileId}
                  placeholder="Select Profile"
                  selection
                />
              </div>
            </div>
          )}
          <div className="item">
            <div className="lab">
              <Checkbox
                label="Enable Email Sync:"
                checked={isEmailSyncEnable}
                onChange={(e, { checked }) => {
                  setEmailSyncEnable(checked || false);
                  setTouched(true);
                }}
              />
            </div>
            <div className="control">
              <BuToggle
                checked={emailSync}
                disabled={!isEmailSyncEnable}
                onChange={(event) => {
                  setEmailSync(event.target.checked || false);
                  setTouched(true);
                }}
              >
                {switchLabel(emailSync)}
              </BuToggle>
            </div>
          </div>
          <div className="item">
            <div className="lab">
              <Checkbox
                label="Enable Calendar Sync:"
                checked={isCalendarSyncEnable}
                onChange={(e, { checked }) => {
                  setCalendarSyncEnable(checked || false);
                  setTouched(true);
                }}
              />
            </div>
            <div className="control">
              <BuToggle
                checked={calendarSync}
                disabled={!isCalendarSyncEnable}
                onChange={(event) => {
                  setCalendarSync(event.target.checked || false);
                  setTouched(true);
                }}
              >
                {switchLabel(calendarSync)}
              </BuToggle>
            </div>
          </div>
          <div className="item">
            <div className="lab">
              <Checkbox
                label="Enable Calls Sync:"
                checked={isCallsSyncEnable}
                onChange={(e, { checked }) => {
                  setCallsSyncEnable(checked || false);
                  setTouched(true);
                }}
              />
            </div>
            <div className="control">
              <BuToggle
                checked={callsSync}
                disabled={!isCallsSyncEnable}
                onChange={(event) => {
                  setCallsSync(event.target.checked || false);
                  setTouched(true);
                }}
              >
                {switchLabel(callsSync)}
              </BuToggle>
            </div>
          </div>
          {isAdminEnabled && (
            <div className="item">
              <div className="lab">
                <Checkbox
                  label="Allow Impersonation:"
                  checked={isAllowImpersonationEnable}
                  onChange={(e, { checked }) => {
                    setAllowImpersonationEnable(checked || false);
                    setTouched(true);
                  }}
                />
              </div>
              <div className="control">
                <BuToggle
                  checked={isAlowImpersonation}
                  disabled={!isAllowImpersonationEnable}
                  onChange={(event) => {
                    setAllowImpersonation(event.target.checked || false);
                    setTouched(true);
                  }}
                >
                  {switchLabel(isAlowImpersonation)}
                </BuToggle>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className={s.actionButtons}>
        <BuButton secondary size={BuControlSize.REGULAR} onClick={onCancel}>
          Cancel
        </BuButton>
        <BuButton
          disabled={!isTouched}
          size={BuControlSize.REGULAR}
          onClick={handleSaveClick}
        >
          Save
        </BuButton>
      </div>
    </div>
  );
};

export default UsersBulkUpdate;
