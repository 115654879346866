import { css } from 'emotion';

import { fontDefault, fontSubtitle } from 'assets/css/common';

export const BASE_PANEL_DROPDOWN_WIDTH = 241;

export const panel_wrapper = css`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const panel = css`
  background-color: var(--bu-gray-200);
  position: relative;
  margin: 0 -20px 0px -20px;
  position: relative;
  border-bottom: 1px solid var(--bu-gray-300);
  padding: 8px 20px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;

  &.modal {
    margin-right: 0;
    margin-left: 0;
    border-top: 1px solid var(--bu-gray-300);
    border-bottom: 0;
  }
`;

export const panel_filters = css`
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;
  gap: 7px;
`;

export const panel_button = css`
  border-radius: 4px;
  cursor: pointer;
  height: 30px;
  ${fontSubtitle};
  font-size: 14px;
  text-transform: inherit;
  color: var(--bu-primary-500);
  background: transparent;
  border: none;
  margin-bottom: 3px;
  transition: 0.4s;

  &:hover,
  &:focus {
    background: var(--bu-gray-200);
    outline: none;
  }
`;

export const panel_dropdown = css`
  position: absolute;
  margin-top: 35px;
  flex-direction: column;
  border: 1px solid var(--bu-gray-400);
  overflow: hidden;
  min-width: 100%;
  width: max-content;
  border-radius: 4px;
  display: flex;
  z-index: 991;
  background: #fff;
  box-shadow: 0px 22px 24px #00000033;

  &.isCalendar {
    width: 512px !important;
  }
`;

export const tree_dropdown = css`
  position: absolute;
  margin-top: 35px;
  flex-direction: row;
  border: 1px solid var(--bu-gray-300);
  min-width: ${BASE_PANEL_DROPDOWN_WIDTH}px;
  border-radius: 2px;
  display: flex;
  z-index: 990;
  background: #fff;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  padding: 13px 10px 5px;

  & > div {
    flex-direction: row;
    &:not(:last-child):after {
      display: block;
      content: '>';
      line-height: 30px;
      margin-right: 7px;
      color: #777777;
    }
  }
`;

export const panel_dropdown_button = css`
  display: flex;
  position: relative;
  box-sizing: border-box;
  height: 32px;
  padding: 5px 8px;
  border: 1px solid var(--bu-gray-500);
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--bu-gray-200);
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  transition: 0.4s all;
  color: var(--bu-gray-900);

  &.isChanged {
    border: 1px solid #41b8d9;
    background-color: #f5fdfd;
  }

  &.isLocked {
    cursor: default;
    opacity: 0.6;
  }

  &.isOpen {
    border: 1px solid #41b8d9 !important;
  }

  &:hover {
    background: rgba(49, 92, 121, 0.12);
  }

  .white & {
    background-color: #fff;
  }
`;

export const panel_span = css`
  color: #444444;
  font-weight: 100;
`;

export const panel_dropdown_checkboxes = css`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow: overlay;

  div.ctt:first-child {
    margin-top: 15px !important;
  }

  div.ctt:last-child {
    margin-bottom: 5px !important;
  }
`;

export const getHierarchyDropdownStyle = (level: number) => css`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow: overlay;
  width: ${BASE_PANEL_DROPDOWN_WIDTH + 30 * level}px;

  div.ctt:first-child {
    margin-top: 15px !important;
  }

  div.ctt:last-child {
    margin-bottom: 5px !important;
  }
`;

export const panel_dropdown_input = css`
  display: flex;
  height: 32px;
  width: 100%;

  input {
    ${fontDefault};
    border: 0px !important;
    border-radius: 2px;
    background-color: var(--bu-white);
    transition: 0.4s all;

    &:focus {
      outline: 1px solid var(--bu-gray-700);
    }
  }
`;

export const panel_dropdown_name = css`
  ${fontSubtitle};
  margin-right: 5px;
`;

export const panel_dropdown_value = css`
  ${fontDefault};
`;

export const panel_dropdown_calendar = css`
  margin-right: 9px;
`;

export const panel_dropdown_divider = css`
  margin: 0px !important;
  border-bottom: 0px !important;
`;

const optionsInColumn = (itemWidth: number, maxItemsInRow: number) => css`
  & > .collapse {
    border-right: 1px solid var(--bu-gray-300);
    overflow-y: auto;
    width: ${itemWidth}px;
    min-width: ${itemWidth}px;

    &:nth-child(1n + ${maxItemsInRow}):last-child {
      border: none;
    }

    ${`.${panel_dropdown_checkboxes}`} {
      margin: 0;
      max-height: initial;

      .ctt {
        padding: 0 8px;
        display: flex;

        &:first-child {
          margin-top: 5px !important;
        }

        &:hover,
        &.checked {
          background-color: #cfeafd;

          &.hasMore:after {
            font-family: Icons;
            content: '\f054';
            line-height: 28px;
            font-size: 12px;
            color: #0762ec;
          }
        }

        .ui.radio.checkbox {
          margin: 5px 0 !important;
          width: 100%;

          label {
            padding-left: 3px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:before,
            &:after {
              display: none;
            }
          }

          &.checked label {
            color: #1e306e;
            font-family: var(--bu-font-medium);
          }
        }
      }
    }
  }
`;

export const tree_column = css`
  position: absolute;
  margin-top: 35px;
  flex-direction: row;
  border: 1px solid var(--bu-gray-300);
  height: 320px;
  overflow-x: auto;
  border-radius: 2px;
  display: flex;
  z-index: 990;
  background: #fff;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);

  width: ${216 * 4}px;
  ${optionsInColumn(216, 4)}
`;

export const no_result_box = css`
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    ${fontDefault};
    margin-bottom: 10px;
  }

  span {
    padding: 5px 11px;
    color: var(--bu-gray-900);
    ${fontDefault};
    background: var(--bu-white);
    border: 1px solid var(--bu-gray-500);
    border-radius: 3px;
    cursor: pointer;
    transition: 0.4s;

    &:hover {
      border-color: var(--bu-primary-500);
      background: rgba(49, 92, 121, 0.12);
    }
  }
`;

export const textField = css`
  font-size: 1rem;
  line-height: 17px;
  font-family: var(--bu-font-regular);
  color: var(--bu-gray-900);
  cursor: pointer;
  position: relative;

  i {
    position: absolute;
    right: 12px;
    top: 51%;
    transform: translateY(-51%);
    font-size: 17px;
  }
`;

export const icon = css`
  font-size: 7px;
  margin-left: 5px;
`;

export const warning_box = css`
  background: var(--bu-orange-300);
  border: 1px solid var(--bu-gray-400);
  color: var(--bu-gray-800);
  border-radius: 2px;
  padding: 5px;
  margin-top: 10px;
  font-size: 12px;
  padding: 5px;
`;

export const saveBtnContainer = css`
  margin-left: auto;
`;

export const popup = css`
  color: var(--bu-gray-900);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 4px 16px;
`;
