import styled from '@emotion/styled';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';

const NoDataContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  height: '100%',
  '>.bar-graph': {
    fontSize: '12em',
    color: 'var(--bu-gray-400)',
  },
  '>div': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '>.noDataTitle': {
      fontWeight: 315,
      fontSize: '18px',
    },
    '>.noDataContent': {
      fontWeight: 305,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
});

export const NoDataMessage: React.FC = () => (
  <NoDataContainer>
    <div>
      <p className="noDataTitle">No data found for this metric</p>
      <p>Try changing the metric property or conditions</p>
    </div>
    <BuIcon name={BoostUpIcons.ColumnGraph} className="bar-graph" />
  </NoDataContainer>
);
