import { PointClickEventObject } from 'highcharts';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import { BIChartTypes } from 'common/enums/metrics';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { getMetricType } from 'components/dashboard/Metrics/Create/MetricCreate/utils';
import { WidgetChart } from 'components/dashboard/Metrics/Widget/Chart/WidgetChart';
import { MetricsWidgetPreviewControls } from 'components/dashboard/Metrics/Widget/Controls/MetricsWidgetPreviewControls';
import { WidgetHeader } from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { DataFetchErrorMessage } from 'components/dashboard/Metrics/Widget/Messages/DataFetchErrorMessage';
import { NoDataMessage } from 'components/dashboard/Metrics/Widget/Messages/NoDataMessage';
import { WidgetTable } from 'components/dashboard/Metrics/Widget/Table/WidgetTable';
import { TemplateFiltersPreview } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFiltersPreview';
import { TotalsBlock } from 'components/dashboard/Metrics/Widget/TotalsBlock/TotalsBlock';
import { NOT_SAVED_METRIC } from 'components/dashboard/Metrics/Widget/constants';
import {
  getMetricNamesWithValues,
  getSyntheticMetricData,
  openSyntheticMetricDetailsModal,
  parseFormulaToMetricsIdArray,
} from 'components/dashboard/Metrics/Widget/helper';
import { useMetricsList } from 'components/dashboard/Metrics/Widget/hooks/useMetricsList';
import {
  createDrillDownParams,
  fetchRecordsAndOpenDrillDownModal,
  fetchWidgetData,
  getDrillDownFilters,
  openDrillDownModal,
} from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import * as s from 'components/dashboard/Metrics/Widget/metrics.widget.styles';
import {
  LoaderContainer,
  WidgetContainer,
} from 'components/dashboard/Metrics/Widget/widgets.styles';
import { BUSINESS_TYPES_FIELD_NAMES } from 'components/dashboard/Metrics/constants';
import { getDropdownFriendlyName } from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIColumnListItem,
  BIMetricColumn,
  BIMetricFormula,
  BIMetricToChartType,
  BIMetricUnion,
  BIMetrics,
  BIWidget,
  BIWidgetDataV2,
  DrillDownParams,
  MetricType,
  SelectedChartValue,
  SelectedValue,
  SyntheticMetricDataWidgetState,
} from 'components/dashboard/Metrics/metrics.types';
import { IReduxState } from 'reducers/types';
import * as metricSelectors from 'selectors/revbi/metrics';
import * as widgetSelectors from 'selectors/revbi/widgets';
import { fetchApi, QueryStatus } from 'utils/network';

interface Props {
  isCreateEditMetric: boolean;
  hasValidFilters: boolean;
  metricsFromList: any[]; // TODO Fix type
  updateWidget: (widget: Partial<BIWidget>) => void;
}

export const MetricsWidgetPreview: React.FC<Props> = ({
  isCreateEditMetric = false,
  hasValidFilters = false,
  metricsFromList,
  updateWidget,
}) => {
  const dispatch = useDispatch();

  const match = useRouteMatch<{ widgetId: string }>();

  const lastFetchedWidget = useRef<string>();

  const metric = useSelector(
    metricSelectors.getActiveMetric
  ) as Partial<BIMetrics>;

  const widget = useSelector((state: IReduxState) =>
    widgetSelectors.getActiveWidget(state, match.params.widgetId)
  );

  const metricIdList = useMemo(
    () => (isCreateEditMetric ? [metric?._id] : widget.metric_list),
    [isCreateEditMetric, metric?._id, widget.metric_list]
  );

  const { data: metricsDict } = useMetricsList(widget.metric_list);

  const metricsInUse = useMemo(() => {
    if (isCreateEditMetric && !metric?._id) {
      return {
        ...metricsDict,
        not_saved_metric: metric as BIMetrics,
      };
    } else if (isCreateEditMetric && !!metric?._id) {
      return {
        ...metricsDict,
        [metric._id]: metric as BIMetrics,
      };
    } else {
      return metricsDict;
    }
  }, [isCreateEditMetric, JSON.stringify(metric), metricsDict]);

  const widgetFiltersBusinessType: string = useMemo(() => {
    const templateFilterBusinessType = widget.template_filters?.find((filter) =>
      BUSINESS_TYPES_FIELD_NAMES.has(filter.column.name)
    );

    return (templateFilterBusinessType?.value as string[])?.[0];
  }, [JSON.stringify(widget.template_filters)]);

  const [metricToChartType, setMetricToChartType] = useState<
    BIMetricToChartType[]
  >(
    widget?.properties?.metricToChartType ||
      (metricIdList as string[]).map((el) => ({
        chartType:
          widget.chart_type === 'table'
            ? BIChartTypes.Table
            : BIChartTypes.Column,
        metricId: el,
      }))
  );

  const [metricData, setMetricData] = useState<BIWidgetDataV2>();
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');

  const [firstPivotColumns, setFirstPivotColumns] = useState<BIMetricColumn[]>(
    []
  );
  const [secondPivotColumns, setSecondPivotColumns] = useState<
    BIMetricColumn[]
  >([]);

  const [pivot1, setPivot1] = useState<BIMetricColumn | undefined>(
    widget.group_by && widget?.group_by[0]
  );
  const [pivot2, setPivot2] = useState<BIMetricColumn | undefined>(
    widget.group_by && widget?.group_by[1]
  );

  useEffect(() => {
    if (pivot2?.name.toLowerCase() === 'none') {
      metricToChartType.forEach((el) => {
        el.chartType =
          el.chartType === BIChartTypes.ColumnStacked
            ? BIChartTypes.Column
            : el.chartType;
      });
      setMetricToChartType([...metricToChartType]);
    }
  }, [pivot2?.name]);

  useEffect(() => {
    if (!!widget?.properties?.metricToChartType) {
      setMetricToChartType(widget?.properties?.metricToChartType);
    }
  }, [JSON.stringify(widget)]);

  //hack to make metricToChartType work with edit
  useEffect(() => {
    setMetricToChartType((prev) => {
      const res = [
        ...prev,
        ...Object.keys(metricsInUse)
          .filter((key) => !prev.some((el) => el.metricId === key))
          .map((key) => ({
            chartType: prev.some((el) => el.chartType === BIChartTypes.Table)
              ? BIChartTypes.Table
              : BIChartTypes.Column,
            metricId: key,
          })),
      ];
      updateWidget({
        ...widget,
        properties: {
          ...widget?.properties,
          metricToChartType: res,
        },
      });
      return res;
    });
  }, [JSON.stringify(metricsInUse), JSON.stringify(widget)]);

  // TODO Investigate is this necessary any more?
  useEffect(() => {
    if (widget.chart_type === 'pie') {
      updateWidget({
        ...widget,
        group_by: widget && widget.group_by ? [widget.group_by[0]] : [],
      });
    }
  }, [widget.chart_type]);

  const usedFormulaMetricIds = useMemo(() => {
    const metricsIdsFromWidgetMetrics = metricsFromList
      .filter((m) => getMetricType(m) === MetricType.Formula)
      .flatMap((m) => parseFormulaToMetricsIdArray(m.synthetic_metric));

    const metricsIdsFromCreatedEditedMetric = parseFormulaToMetricsIdArray(
      (metric as BIMetricFormula).synthetic_metric
    );

    return [
      ...metricsIdsFromWidgetMetrics,
      ...metricsIdsFromCreatedEditedMetric,
    ];
  }, [metricsFromList, metric.synthetic_metric]);

  const { data: usedFormulaMetricsMap } = useMetricsList(usedFormulaMetricIds);

  useEffect(() => {
    const objectsList: string[] = [];

    const simpleMetrics = metricsFromList.filter(
      (m) => getMetricType(m) === MetricType.Simple
    );

    if (simpleMetrics.length) {
      simpleMetrics.forEach((m) => {
        const metricObject = m.object;

        if (metricObject) {
          objectsList.push(metricObject);
        }
      });
    }

    Object.keys(usedFormulaMetricsMap).forEach((element) => {
      const metricObject = usedFormulaMetricsMap[element].object;

      if (metricObject) {
        objectsList.push(metricObject);
      }
    });

    if (isCreateEditMetric) {
      if (getMetricType(metric as BIMetricUnion) === MetricType.Simple) {
        if (metric.object) {
          objectsList.push(metric.object);
        }
      }
    }

    if (objectsList?.length) {
      const queryParams = {
        tables: [...new Set(objectsList)],
      };

      fetchApi<any, BIColumnListItem[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_first_pivot`,
        queryMethod: 'post',
        queryParams: queryParams,
        setData: (result) => {
          setFirstPivotColumns(
            result.map((column) => ({
              ...column,
              label: getDropdownFriendlyName(column),
            }))
          );
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load first pivot columns: ${error}`);
        },
      });

      fetchApi<any, BIColumnListItem[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_second_pivot`,
        queryMethod: 'post',
        queryParams: queryParams,
        setData: (result) => {
          setSecondPivotColumns(
            result.map((column) => ({
              ...column,
              label: getDropdownFriendlyName(column),
            }))
          );
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load second pivot columns: ${error}`);
        },
      });
    }
  }, [
    JSON.stringify(metricsFromList),
    metric?.object,
    JSON.stringify(usedFormulaMetricsMap),
  ]);

  useEffect(() => {
    if (
      metric &&
      isCreateEditMetric &&
      metric.metadata?.type === 'simple' &&
      metric.object !== 'target' &&
      ((metric.aggregation_function !== 'count' && !metric.column) ||
        !metric.aggregation_function)
    ) {
      return;
    }

    if (
      metric &&
      metric.metadata?.type === 'formula' &&
      !metric.synthetic_metric
    ) {
      return;
    }

    let fullMetrics;
    if (isCreateEditMetric && metricsFromList) {
      fullMetrics = [...metricsFromList];

      let metricIdx = metricsFromList.findIndex(
        (item) => item._id === metric?._id
      );
      if (metricIdx === -1) {
        fullMetrics.push(metric);
      } else {
        fullMetrics[metricIdx] = metric;
      }
    }

    let queryWidget = isCreateEditMetric
      ? { ...widget, metric_list: fullMetrics }
      : widget;

    const previewJson = JSON.stringify(queryWidget);

    let abortController: AbortController = new AbortController();

    const metricValid = Boolean(
      !isCreateEditMetric ||
        (metric?.aggregation_function && metric?.column) ||
        metric?.aggregation_function === 'count' ||
        metric?.synthetic_metric ||
        metric?.object === 'target'
    );
    if (hasValidFilters && metricValid) {
      lastFetchedWidget.current = previewJson;
      abortController = new AbortController();

      fetchWidgetData(
        previewJson,
        '',
        (widget._id ?? widget.name) || '',
        widget.time_field,
        abortController,
        setMetricData,
        setDataStatus,
        setPivot1,
        setPivot2
      );
    }

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [
    metric?.aggregation_function,
    metric?.column?.name,
    metric?.metadata?.type,
    metric?.object,
    metric?.select_expression,
    metric?.synthetic_metric,
    metric?.cumulative_sum_period,
    metric?.target_period,
    metric?.target_type,
    metric?.is_hierarchy_rollup_sum,
    JSON.stringify(metric?.filters),
    widget.metric_list?.length,
    widget.time_field?.name,
    widget.time_field?.label,
    JSON.stringify(widget.template_filters),
    JSON.stringify(widget.group_by),
  ]);

  const isTargetMetricOnlyWidget = useMemo(
    () =>
      Object.keys(metricsInUse).length === 1 &&
      metricsInUse[Object.keys(metricsInUse)[0]].object === 'target',
    [metricsInUse]
  );

  const pivot1Columns = useMemo(() => {
    // If target metric is only metric on widget we show only pivot by User Name
    if (isTargetMetricOnlyWidget) {
      return firstPivotColumns.filter((c) => c.name === 'user.name');
    }

    return firstPivotColumns;
  }, [isTargetMetricOnlyWidget, firstPivotColumns]);

  const firstTargetMetric = useMemo(() => {
    const keys = Object.keys(metricsInUse);
    for (const key of keys) {
      const current = metricsInUse[key];
      if (current.object === 'target') {
        return current;
      }
    }

    return null;
  }, [metricsInUse]);

  const handleTableDataClick = (column: IColumn, row: IRow): void => {
    const metricId = isCreateEditMetric ? NOT_SAVED_METRIC : column.metricId;

    if (metricId in metricsInUse) {
      const clickedMetric: BIMetrics = metricsInUse[metricId];

      const drillDownFilters = getDrillDownFilters(
        widget,
        metricData,
        column.extraHeader,
        row
      );

      const drilldownParams: DrillDownParams = createDrillDownParams(
        clickedMetric,
        // If it is a subtotal row then we send only the first pivot value
        row.isSubtotal ? [drillDownFilters[0]] : drillDownFilters,
        widgetFiltersBusinessType,
        widget
      );

      if (clickedMetric.synthetic_metric) {
        if (typeof clickedMetric.synthetic_metric !== 'string') {
          fetchRecordsAndOpenDrillDownModal(
            undefined,
            drilldownParams,
            clickedMetric.object,
            clickedMetric.name,
            dispatch
          );
          return;
        }
        const parsedClickedMetricFormulaAsArray: string[] =
          parseFormulaToMetricsIdArray(clickedMetric.synthetic_metric);

        const syntheticMetricData: SyntheticMetricDataWidgetState =
          getSyntheticMetricData(
            parsedClickedMetricFormulaAsArray,
            metricData,
            clickedMetric
          );

        const selectedValue: SelectedValue = {
          y: !Number.isNaN(row[column.field] as number)
            ? (row[column.field] as number)
            : 0,
          metricsValues: getMetricNamesWithValues(
            parsedClickedMetricFormulaAsArray,
            row,
            syntheticMetricData
          ),
        };

        const pivot1FieldName = pivot1?.field_name;
        if (pivot1FieldName) {
          selectedValue.pivot1Id = row[pivot1FieldName] as string;
        }

        openSyntheticMetricDetailsModal(
          widget.title || widget.name || 'Drill Down',
          drilldownParams,
          syntheticMetricData,
          selectedValue,
          dispatch
        );
      } else {
        fetchRecordsAndOpenDrillDownModal(
          undefined,
          drilldownParams,
          clickedMetric.object,
          clickedMetric.name,
          dispatch
        );
      }
    }
  };

  const handleChartDataClick = (pointEvent?: PointClickEventObject): void => {
    const selectedValue: SelectedChartValue | undefined =
      pointEvent?.point?.options;

    if (selectedValue) {
      const metricId = isCreateEditMetric
        ? NOT_SAVED_METRIC
        : selectedValue?.metricId || '';

      if (metricId in metricsInUse) {
        const clickedMetric: BIMetrics = metricsInUse[metricId];

        openDrillDownModal(
          widget,
          selectedValue,
          clickedMetric,
          widgetFiltersBusinessType,
          false,
          false,
          undefined,
          metricData,
          availablePivots,
          pivot1,
          pivot2,
          dispatch
        );
      }
    }
  };

  const handleTotalsClick = (metricId: string): void => {
    if (metricId === undefined || metricId === '') {
      // handling case when metric is not created yet
      metricId = NOT_SAVED_METRIC;
    }

    if (metricId in metricsInUse) {
      const clickedMetric = metricsInUse[metricId];

      const drilldownParams: DrillDownParams = createDrillDownParams(
        clickedMetric,
        [],
        widgetFiltersBusinessType,
        widget
      );

      if (clickedMetric.synthetic_metric) {
        if (typeof clickedMetric.synthetic_metric !== 'string') {
          fetchRecordsAndOpenDrillDownModal(
            undefined,
            drilldownParams,
            clickedMetric.object,
            clickedMetric.name,
            dispatch
          );
          return;
        }

        const parsedClickedMetricFormulaAsArray: string[] =
          parseFormulaToMetricsIdArray(clickedMetric.synthetic_metric);

        const syntheticMetricData: SyntheticMetricDataWidgetState =
          getSyntheticMetricData(
            parsedClickedMetricFormulaAsArray,
            metricData,
            clickedMetric
          );

        let clickedValue = 0;

        const totals = (metricData?.pivot_0?.columns || []).map((el) => ({
          ...el,
          total: metricData?.pivot_0?.data[0][el.field_name],
        }));

        totals?.forEach((total) => {
          if (total.metric_id === metricId) {
            clickedValue = total.total;
          }
        });

        openSyntheticMetricDetailsModal(
          widget.title || widget.name || 'Drill Down',
          drilldownParams,
          syntheticMetricData,
          {
            y: clickedValue,
            metricsValues: getMetricNamesWithValues(
              parsedClickedMetricFormulaAsArray,
              metricData?.pivot_0?.data[0] || {},
              syntheticMetricData
            ),
          },
          dispatch
        );
      } else {
        fetchRecordsAndOpenDrillDownModal(
          undefined,
          drilldownParams,
          clickedMetric.object,
          clickedMetric.name,
          dispatch
        );
      }
    }
  };

  const handleTableSortChange = (columnName: string) => {
    updateWidget({
      ...widget,
      properties: {
        ...widget?.properties,
        table_view_order_by_column: columnName,
      },
    });
  };

  const hasData = !!metricData?.pivot_1?.data.length;

  const availablePivots = metricData?.hasOwnProperty('pivot_2')
    ? 2
    : metricData?.hasOwnProperty('pivot_1')
    ? 1
    : 0;

  const isDateFirstPivot =
    pivot1?.type === 'date' || pivot1?.type === 'timePeriod';

  const isTableVisualization = metricToChartType.some(
    (el) => el.chartType === 'table'
  );

  return (
    <>
      <WidgetContainer
        key={widget._id}
        isDashboard={false}
        isMetricsPreview={isCreateEditMetric}
        isModal={false}
      >
        <WidgetHeader
          id={widget._id}
          name={widget.name}
          isCreateEditMetric={isCreateEditMetric}
          data-testing="widget-header"
        />

        <MetricsWidgetPreviewControls
          isTargetMetricOnlyWidget={isTargetMetricOnlyWidget}
          hasTargetMetric={Boolean(firstTargetMetric)}
          showControls={true}
          firstPivotColumns={pivot1Columns}
          secondPivotColumns={secondPivotColumns}
          pivot1={pivot1}
          pivot2={pivot2}
          metricToChartType={metricToChartType}
          setMetricToChartType={setMetricToChartType}
          setPivot1={setPivot1}
          setPivot2={setPivot2}
          setWidget={updateWidget}
          data-testing="metrics-widget-controls"
        />

        <TemplateFiltersPreview
          isDateFirstPivot={isDateFirstPivot}
          templateFilters={widget.template_filters}
          widgetFilters={widget.widget_filters}
          widget={widget}
          targetPeriod={firstTargetMetric?.target_period}
          updateWidget={updateWidget}
        />

        {dataStatus === 'loading' && (
          <LoaderContainer>
            <Loader active content="Loading" />
          </LoaderContainer>
        )}

        {dataStatus === 'success' && (
          <>
            {(!!metricData?.pivot_0?.columns?.length ||
              !!metricData?.pivot_0?.columns_error?.length) && (
              <div
                className={s.totalsContainer}
                data-testing="totals-container"
              >
                <div className={s.totalsBarElement}>
                  <TotalsBlock
                    showControls
                    isPivoted={pivot1?.name !== 'none'}
                    pivot2={pivot2}
                    metricData={metricData}
                    metricsInUse={metricsInUse}
                    type={
                      metricsFromList &&
                      metricsFromList.length === 1 &&
                      !metricData?.hasOwnProperty('pivot_1')
                        ? 'singleValue'
                        : (metricsFromList &&
                            metricsFromList.length > 4 &&
                            !metricData?.hasOwnProperty('pivot_1')) ||
                          !metricData?.hasOwnProperty('pivot_1')
                        ? 'totalsOnly'
                        : 'totalsAndChart'
                    }
                    widget={widget}
                    metricToChartType={metricToChartType}
                    onDataClick={handleTotalsClick}
                    onChartTypeChange={(metricId, chartType) => {
                      let result: {
                        chartType: BIChartTypes;
                        metricId: string;
                      }[] = [...metricToChartType];
                      if (
                        chartType === BIChartTypes.Table &&
                        (
                          (widget.properties
                            ?.metricToChartType as BIMetricToChartType[]) || []
                        ).every((el) => el.chartType !== BIChartTypes.Table)
                      ) {
                        result = [
                          ...result.map((el) => ({
                            ...el,
                            chartType: chartType,
                          })),
                        ];
                      }
                      if (
                        (
                          (widget.properties
                            ?.metricToChartType as BIMetricToChartType[]) || []
                        ).every((el) => el.chartType === BIChartTypes.Table) &&
                        chartType !== BIChartTypes.Table
                      ) {
                        result = [
                          ...result.map((el) => ({
                            ...el,
                            chartType: chartType,
                          })),
                        ];
                      }

                      const type = result.find(
                        (el) => el.metricId === metricId
                      );

                      if (!!type) {
                        type.chartType = chartType;
                        result = [...result];
                      } else {
                        result = [...result, { metricId, chartType }];
                      }

                      setMetricToChartType(result);
                      updateWidget({
                        ...widget,
                        properties: {
                          metricToChartType: result,
                        },
                        chart_type: chartType,
                      });
                    }}
                  />
                </div>
              </div>
            )}

            {hasData && (
              <>
                {isTableVisualization &&
                  (widget.metric_list?.length > 0 || isCreateEditMetric) &&
                  !!metricData && (
                    <WidgetTable
                      sortByColumn={
                        widget.properties?.table_view_order_by_column
                      }
                      widgetData={metricData}
                      metricsCount={widget.metric_list?.length || 0}
                      availablePivots={availablePivots}
                      isDateFirstPivot={isDateFirstPivot}
                      metricsInUse={metricsInUse}
                      onTableDataClick={handleTableDataClick}
                      onTableSortChange={handleTableSortChange}
                    />
                  )}

                {!isTableVisualization && !!metricData && (
                  <WidgetChart
                    metricToChartType={metricToChartType}
                    metricData={metricData}
                    metricsInUse={metricsInUse}
                    widget={widget}
                    onDataClick={handleChartDataClick}
                    data-testing="widget-chart-section"
                  />
                )}
              </>
            )}

            {availablePivots > 0 && !hasData && (
              <NoDataMessage data-testing="no-data-message" />
            )}
          </>
        )}

        {dataStatus === 'error' && <DataFetchErrorMessage />}
      </WidgetContainer>
    </>
  );
};
