import moment from 'moment';
import * as R from 'ramda';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Dimmer, Loader } from 'semantic-ui-react';

import {
  CopyCROSubmissionRequest,
  CopyCROSubmissionResponse,
  CopyUserSubmissionRequest,
  CopyUserSubmissionResponse,
  CROUserSubmission,
  UserRow,
  UserSubmission,
} from 'actions/croOverrideActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuCheckbox from 'components/UI/BuCheckbox';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import { useDataModal } from 'components/UI/BuDataPopup';
import BuDropdown, {
  BuDropdownItem,
  BuDropdownItemContainer,
} from 'components/UI/BuDropdown';
import BuGroupButton from 'components/UI/BuGroupButton';
import BuIcon from 'components/UI/BuIcon';
import { ScheduleSubmissionAlert } from 'components/UI/ScheduleSubmissionAlert';
import OnLeave from 'components/UI/common/OnLeave';
import Scrolling, {
  useTableShadows,
} from 'components/UI/common/Scrolling/Scrolling';
import TypedTable, {
  BorderType,
  IColumn,
  IRow,
  onChangeCallback,
} from 'components/UI/common/TypedTable/TypedTable';
import {
  getCellValue,
  getFieldValue,
} from 'components/UI/common/TypedTable/renderers/custom/common';
import ForecastDealsModal from 'components/dashboard/CROOverride/SubmissionForecast/ForecastDealsTable/ForecastDealsModal';
import { ForecastDealsModalProps } from 'components/dashboard/CROOverride/SubmissionForecast/ForecastDealsTable/types';
import SubmitSubmissionModal from 'components/dashboard/CROOverride/SubmissionForecast/SubmitSubmissionModal';
import {
  getColumns,
  GetColumnsProps,
  getOverrideUser,
  getRowsWithUpdatedCRO,
  getSubmissionFingerprint,
  mergeFlatSubmissionsWithBookedAndTarget,
  aggregateBookedFromChildren,
} from 'components/dashboard/CROOverride/SubmissionForecast/helper';
import { IProps } from 'components/dashboard/CROOverride/SubmissionForecast/types';
import { MyCallCellConfig } from 'components/dashboard/CROOverride/cell-renderers/myCall';
import { MyCallCROCellConfig } from 'components/dashboard/CROOverride/cell-renderers/myCallCRO';
import {
  ALL_TEXT,
  cf,
  createTableRows,
  createTreeRows,
  getAvailablePeriods,
  getBusinessTypeButtonList,
  getTeamRowStyle,
  getVisibleSubmissions,
  showUserWithAccessToSubmission,
} from 'components/dashboard/CROOverride/helper';
import { withSubmissionWrapper } from 'components/dashboard/CROOverride/helper';
import {
  ComponentBox,
  Fluid,
  removeMargins,
  RowContainer,
  SpaceBetweenContainer,
  tableCellBorders,
  UpdateLabel,
  ViewContainer,
  TabsContainer,
  CloseButton,
} from 'components/dashboard/CROOverride/styles';
import {
  isCRORow,
  RowSubmission,
  SubmissionTouched,
  SubmissionTouchedFieldName,
} from 'components/dashboard/CROOverride/types';
import Tabs from 'components/dashboard/Tabs';
import { fetchApi, fetchApiPromise } from 'utils/network';

type ForecastDealsModal = React.FC<
  ForecastDealsModalProps<Record<'user_id' | 'submission_settings_id', string>>
>;

const SubmissionForecast: React.FC<IProps> = ({
  submissionForecasts,
  allBusinessTypes,
  activeBusinessType,
  companyCurrency,
  submissionStatus,
  onSave,
  onSubmit,
  onSubmitSelected,
  onSelectBusinessType,
  submissionBookedAndTarget,
  submissionBookedAndTargetStatus,
  bookedAndTargetEnabled,
  canNavigateToInitiatorRoute,
  goToInitiatorRoute,
}) => {
  const history = useHistory();
  const scrollShadows = useTableShadows();
  const ref = useRef<HTMLDivElement>(null);
  const [dataFlat, setDataFlat] = useState<RowSubmission[]>([]);
  const [isTouched, setTouched] = useState(false);
  const [periodSelected, setPeriodSelected] = useState<string[]>([]);
  const [isBusy, setBusy] = useState<boolean>(false);

  const submitSubmissionModal = useDataModal<typeof SubmitSubmissionModal>({
    currencyFormatter: cf(companyCurrency),
    notes: '',
    title: '',
    amount: 0,
    dealsTotal: 0,
    dealsAmount: 0,
    dealsIncluded: 0,
  });
  const dealsModal = useDataModal<ForecastDealsModal>({
    url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submissions/override/deals`,
    queryParams: {
      user_id: '',
      submission_settings_id: '',
    },
    submissionSettingsId: '',
    submissionName: '',
    dealsExcluded: [],
    dealsIncluded: [],
    callOptionType: 'OVERRIDE_ME_AND_TEAM_CALLS',
  });

  useEffect(() => {
    if (submissionStatus === 'submitted') {
      toast.success('Submitted successful');
    }
  }, [submissionStatus]);

  useEffect(() => {
    setDataFlat(createTableRows(submissionForecasts));
    setTouched(false);
  }, [submissionForecasts]);

  const visibleSubmissions = useMemo(
    () =>
      getVisibleSubmissions(
        submissionForecasts.columns,
        periodSelected,
        activeBusinessType
      ),
    [submissionForecasts.columns, periodSelected, activeBusinessType]
  );

  const filterUserWithAccessToSubmission = useMemo(
    () => showUserWithAccessToSubmission(visibleSubmissions),
    [visibleSubmissions]
  );

  const filteredFlatSubmissions = useMemo(
    () => dataFlat.filter(filterUserWithAccessToSubmission),
    [dataFlat, filterUserWithAccessToSubmission]
  );

  const rawTree = useMemo(
    () =>
      createTreeRows(
        mergeFlatSubmissionsWithBookedAndTarget(
          filteredFlatSubmissions,
          submissionBookedAndTargetStatus,
          submissionBookedAndTarget
        )
      ),
    [
      filteredFlatSubmissions,
      submissionBookedAndTargetStatus,
      submissionBookedAndTarget,
    ]
  );

  const rawTreeHasItems = !!rawTree?.length;

  const aggregatedTree = useMemo(
    () =>
      rawTreeHasItems ? [aggregateBookedFromChildren(rawTree[0])] : rawTree,
    [rawTree]
  );

  const handleOpenNoteEditor = useCallback<
    MyCallCROCellConfig['onNoteEditorOpen']
  >(
    (column, row) => {
      const submission = getCellValue({ column, row }) as CROUserSubmission;
      const {
        submission_settings_id,
        notes,
        override_amount,
        override_included_deals_amount,
        override_included_deals_ids,
        override_excluded_deals_ids,
      } = submission;
      const rowId = row.id;

      submitSubmissionModal.open(
        {
          title: column.label,
          notes: notes,
          amount: override_amount,
          dealsTotal:
            override_included_deals_ids.length +
            override_excluded_deals_ids.length,
          dealsAmount: override_included_deals_amount,
          dealsIncluded: override_included_deals_ids.length,
        },
        ({ notes }) => {
          setDataFlat((currentDataFlat) => {
            const newDataFlat = currentDataFlat.map<RowSubmission>((item) =>
              item.id === rowId
                ? R.assocPath<
                    SubmissionTouched<CROUserSubmission>,
                    RowSubmission
                  >(
                    column.field.split('.'),
                    {
                      ...submission,
                      notes,
                      [SubmissionTouchedFieldName]: true,
                    },
                    item
                  )
                : item
            );

            onSubmitSelected(newDataFlat, submission_settings_id);

            return newDataFlat;
          });
        }
      );
    },
    [submitSubmissionModal, setDataFlat, onSubmitSelected]
  );

  const handleSelectDeals = useCallback<MyCallCellConfig['onSelectDealsClick']>(
    (column, row) => {
      const userId = getFieldValue<UserRow['user_id']>('user_id', row);
      const submission = getCellValue({ column, row }) as
        | CROUserSubmission
        | UserSubmission
        | undefined;
      const {
        override_included_deals_ids,
        override_excluded_deals_ids,
        submission_settings_id,
      } = submission ?? {};

      if (submission) {
        dealsModal.open(
          {
            submissionName: column.label,
            dealsIncluded: override_included_deals_ids,
            dealsExcluded: override_excluded_deals_ids,
            submissionSettingsId: submission_settings_id,
            callOptionType: isCRORow(row as RowSubmission)
              ? 'ME_CALLS_ONLY'
              : 'OVERRIDE_ME_AND_TEAM_CALLS',
            queryParams: {
              submission_settings_id: submission_settings_id ?? '',
              user_id: userId,
            },
          },
          ({
            selectedDealsIds: included_deal_ids,
            allDealsIds,
            amount: included_deals_amount,
          }) => {
            const excluded_deal_ids = allDealsIds.filter(
              (id) => !included_deal_ids.includes(id)
            );
            setTouched(true);

            setDataFlat((prevDataFlat) => {
              const newData = prevDataFlat.map((item) => {
                if (item.id === row.id) {
                  return R.assocPath<
                    SubmissionTouched<CROUserSubmission | UserSubmission>,
                    RowSubmission
                  >(
                    column.field.split('.'),
                    {
                      ...submission,
                      override_amount: included_deals_amount,
                      override_included_deals_amount: included_deals_amount,
                      override_included_deals_ids: included_deal_ids,
                      override_excluded_deals_ids: excluded_deal_ids,
                      [SubmissionTouchedFieldName]: true,
                    },
                    item
                  );
                }

                return item;
              });

              if (!isCRORow(row as RowSubmission) && submission_settings_id) {
                return getRowsWithUpdatedCRO(newData, submission_settings_id);
              }

              return newData;
            });
          }
        );
      }
    },
    [dealsModal, setDataFlat]
  );

  const copyUserSubmission = useCallback(
    (
      submission: CopyUserSubmissionResponse,
      rowId: IRow['id'],
      submission_settings_id: UserSubmission['submission_settings_id'],
      submissionPath: IColumn['field']
    ) => {
      const {
        included_deals_amount: override_included_deals_amount,
        included_deal_ids: override_included_deals_ids,
        excluded_deal_ids: override_excluded_deals_ids,
        amount: override_amount,
      } = submission ?? {};

      const overrideSubmission: Partial<UserSubmission> = {
        override_amount,
        override_included_deals_amount,
        override_included_deals_ids,
        override_excluded_deals_ids,
      };

      setDataFlat((prevDataFlat) => {
        const newData = prevDataFlat.map((item) => {
          if (item.id === rowId) {
            const currentSubmission = item.submissions[
              submission_settings_id
            ] as UserSubmission;

            const newData = getSubmissionFingerprint(overrideSubmission);

            const oldData = getSubmissionFingerprint(currentSubmission);

            if (newData !== oldData) {
              toast.warn(
                'Available deals changed since the user made the submission'
              );
            }

            return R.assocPath<
              SubmissionTouched<UserSubmission>,
              RowSubmission
            >(
              submissionPath.split('.'),
              {
                ...currentSubmission,
                ...overrideSubmission,
                [SubmissionTouchedFieldName]: true,
              },
              item
            );
          }

          return item;
        });

        return getRowsWithUpdatedCRO(newData, submission_settings_id);
      });
    },
    [setDataFlat]
  );

  const handleCopyUserSubmission = useCallback<MyCallCellConfig['onCopyClick']>(
    (column, row) => {
      const submission = getCellValue({ column, row }) as UserSubmission;
      const { user_submission_id, submission_settings_id } = submission;

      return fetchApiPromise<
        CopyUserSubmissionRequest,
        CopyUserSubmissionResponse
      >({
        url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submissions/override/copy`,
        queryParams: {
          submission_id: user_submission_id!,
          submission_settings_id,
        },
      }).then(({ result, error }) => {
        if (error) {
          toast.error(`Copying submission failed ${error}`);
        } else {
          setTouched(true);
          copyUserSubmission(
            result!,
            row.id,
            submission_settings_id,
            column.field
          );
        }
      });
    },
    [copyUserSubmission]
  );

  const handleCopySubmission = useCallback<
    GetColumnsProps['onCopySubmissionSettings']
  >(
    (submission_settings_id_from, submission_settings_id_to) => {
      const SUBMISSION_PATH: MapPaths.Paths<
        Pick<RowSubmission, 'submissions'>
      > = ['submissions', submission_settings_id_to];

      setBusy(true);

      fetchApi<CopyCROSubmissionRequest, CopyCROSubmissionResponse>({
        url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submissions/override/copy-from`,
        queryParams: {
          submission_settings_id_from,
          submission_settings_id_to,
        },
        setData(response) {
          setBusy(false);
          setTouched(true);
          setDataFlat((prev) =>
            prev.map((item) => {
              const prevSubmission =
                item.submissions[submission_settings_id_to];
              const overrideSubmission = getOverrideUser(
                response!,
                item.user_id
              );

              if (!prevSubmission || !overrideSubmission) {
                return item;
              }

              return R.assocPath<
                SubmissionTouched<CROUserSubmission | UserSubmission>,
                RowSubmission
              >(
                SUBMISSION_PATH,
                {
                  ...prevSubmission,
                  ...overrideSubmission,
                  [SubmissionTouchedFieldName]: true,
                },
                item
              );
            })
          );
        },
        setError(error) {
          setBusy(false);
          toast.error(`Copying submission failed. ${error}`);
        },
      });
    },
    [setDataFlat]
  );

  const tableColumnConfig = useMemo(
    () =>
      getColumns({
        companyCurrency,
        submissions: submissionForecasts.columns,
        visibleSubmissions: visibleSubmissions,
        onNoteEditorOpen: handleOpenNoteEditor,
        onCopyClick: handleCopyUserSubmission,
        onSelectDealsClick: handleSelectDeals,
        onCopySubmissionSettings: handleCopySubmission,
        bookedAndTargetEnabled,
      }),
    [
      companyCurrency,
      visibleSubmissions,
      submissionForecasts.columns,
      handleOpenNoteEditor,
      handleCopyUserSubmission,
      handleSelectDeals,
      onSelectBusinessType,
      bookedAndTargetEnabled,
    ]
  );

  const availablePeriods = useMemo(
    () => getAvailablePeriods(submissionForecasts),
    [submissionForecasts]
  );

  const handleChange: onChangeCallback = (column, row, newValue) => {
    const submission = getCellValue({ column, row }) as
      | CROUserSubmission
      | UserSubmission;

    const value = Number.isFinite(newValue) ? (newValue as number) : null;

    setDataFlat((currentDataFlat) =>
      currentDataFlat.map((item) =>
        item.id === row.id
          ? R.assocPath<
              SubmissionTouched<CROUserSubmission | UserSubmission>,
              RowSubmission
            >(
              column.field.split('.'),
              {
                ...submission,
                override_amount: value,
                [SubmissionTouchedFieldName]: true,
              },
              item
            )
          : item
      )
    );
    setTouched(true);
  };

  const businessTypeButtons = useMemo(
    () => getBusinessTypeButtonList(allBusinessTypes),
    [allBusinessTypes]
  );

  /*
   * when one period option is clicked should be add it on the list to
   */
  const onChange = (val: string, checked: boolean) => {
    const isAll = val === ALL_TEXT;
    const periods: string[] = checked
      ? [...periodSelected, val]
      : periodSelected.filter((period) => period !== val);
    const areAllIncluded = periods.length >= availablePeriods.length;
    const currentSelection = isAll || areAllIncluded ? [] : periods;

    setPeriodSelected(currentSelection);
  };

  const optionMaker = (period: string, checked: boolean, hide: Function) => (
    <BuDropdownItem key={period} value={period} active={false}>
      <BuCheckbox
        label={period}
        checked={checked}
        onChange={(checked) => {
          hide();
          onChange(period, checked);
        }}
      />
    </BuDropdownItem>
  );

  const availablePeriodOption = (hide: Function) => {
    const options = availablePeriods.map((period: string) =>
      optionMaker(period, periodSelected.includes(period), hide)
    );
    options.unshift(optionMaker(ALL_TEXT, !periodSelected.length, hide));
    return options;
  };

  const periodMaker = (hide: Function) => (
    <BuDropdownItemContainer>
      {availablePeriodOption(hide)}
    </BuDropdownItemContainer>
  );

  const hasMoreThanOne = periodSelected.length > 1;
  const periodDropdownLabel = periodSelected.length
    ? hasMoreThanOne
      ? `${periodSelected[0]} (+${periodSelected.length - 1})`
      : `${periodSelected[0]}`
    : ALL_TEXT;

  return (
    <ViewContainer ref={ref}>
      <RowContainer>
        <Tabs partition="cro_override" wrapped>
          <div className={TabsContainer}>
            <ScheduleSubmissionAlert />
            {canNavigateToInitiatorRoute && (
              <a
                className={CloseButton}
                onClick={goToInitiatorRoute}
                href="javascript:;"
              >
                <BuIcon name={BoostUpIcons.ClosePopup} />
              </a>
            )}
          </div>
        </Tabs>
      </RowContainer>

      <RowContainer>
        <SpaceBetweenContainer>
          <ComponentBox>
            <BuGroupButton
              className={removeMargins}
              options={businessTypeButtons}
              selectedOption={activeBusinessType}
              onSelect={onSelectBusinessType}
            />
            <BuDropdown
              inlineLabel="Period"
              label={periodDropdownLabel}
              secondary
            >
              {periodMaker}
            </BuDropdown>
          </ComponentBox>
          <ComponentBox>
            {submissionForecasts?.submitted_at && (
              <UpdateLabel>
                Last submitted:{' '}
                {moment(submissionForecasts.submitted_at).format(
                  'DD MMM YYYY, h:mm A'
                )}
              </UpdateLabel>
            )}
            <BuButton
              size={BuControlSize.SMALL}
              onClick={() => onSubmit(dataFlat)}
            >
              Submit All
            </BuButton>
            <BuButton
              secondary
              size={BuControlSize.SMALL}
              onClick={() => onSave(dataFlat)}
            >
              Save
            </BuButton>
          </ComponentBox>
        </SpaceBetweenContainer>
      </RowContainer>

      <Fluid>
        <TypedTable.Border borders={BorderType.TOP} height="100%">
          <Dimmer.Dimmable
            dimmed={submissionStatus === 'loading' || isBusy}
            style={{ height: '100%', overflowY: 'auto' }}
          >
            <Dimmer active={submissionStatus === 'loading' || isBusy} inverted>
              <Loader id="loading_spinner" />
            </Dimmer>

            <Scrolling
              alwaysShow
              height="100%"
              shadows
              width
              {...scrollShadows.shadows}
            >
              <TypedTable
                columns={tableColumnConfig}
                data={aggregatedTree}
                onRender={scrollShadows.onRender}
                fullscreen
                stickyColumn
                stickyHeader
                fixColumns
                onChange={handleChange}
                rowClassName={getTeamRowStyle}
                className={tableCellBorders}
              />
            </Scrolling>
          </Dimmer.Dimmable>
        </TypedTable.Border>
      </Fluid>

      <SubmitSubmissionModal {...submitSubmissionModal.props} />

      <ForecastDealsModal {...dealsModal.props} mountNode={ref?.current} />

      <OnLeave block={!!isTouched} ignoredPath="/submission/forecast">
        {(showPrompt, proceedLeaving, hidePrompt) => (
          <BuConfirmationPopup
            isOpen={showPrompt}
            onClose={hidePrompt}
            onConfirm={proceedLeaving}
            headerText="Unsaved changes"
            confirmText="Proceed Anyway"
            cancelText="Cancel"
          >
            <p>
              You have some unsaved changes on this screen which you will lose
              if you proceed. Save your changes before proceeding
            </p>
          </BuConfirmationPopup>
        )}
      </OnLeave>
    </ViewContainer>
  );
};

export default withSubmissionWrapper(SubmissionForecast);
