import _ from 'lodash';
import { pathOr } from 'ramda';

const limiter = (entities) =>
  entities.length > 999 ? entities.slice(0, 999) : entities;

export const getEngagementStatus = (state) => state.engagement.status;

export const getEngagementCalls = (state, objId) => {
  const calls = _.get(state.engagement, `byId.${objId}.calls`, []);
  return limiter(calls);
};

export const getEngagementEvents = (state, objId) => {
  const events = _.get(state.engagement, `byId.${objId}.events`, []);
  return limiter(events);
};

export const getRepEngagementEmails = (state, objId) => {
  const emails = _.get(state.engagement, `byId.${objId}.company_emails`, []);

  return Object.keys(emails).reduce(
    (res, key) => ({
      ...res,
      [key]: limiter(emails[key]),
    }),
    {}
  );
};

export const getCustomerEngagementEmails = (state, objId) => {
  const emails = _.get(
    state.engagement,
    `byId.${objId}.customer_outreach_emails`,
    []
  );
  return limiter(emails);
};

export const getCustomerNextSteps = (state, objId) => {
  const steps = _.get(state.engagement, `byId.${objId}.next_step_history`, []);
  return limiter(steps).map((record) => ({
    ...record,
    id: `${objId}_${record.created_at}`,
  }));
};

export const getSFDCActivityGroups = (state, objId) => {
  return _.get(state.engagement, `byId.${objId}.activities`, []);
};

export const isEngagementCached = (state, objId) =>
  _.get(state.engagement, `byId.${objId}`, false);

export const getEngagementStageHistory = (state, objId) =>
  pathOr([], ['byId', objId, 'stage_history'], state.engagement);
