import React from 'react';

import { DateOperatorOption } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/types';
import { DropdownCalendar } from 'components/dashboard/Metrics/common/Calendar/Calendar';
import { useTargetPeriodOptions } from 'components/dashboard/Metrics/common/TargetTimePeriodCalendar/hooks';

interface Props {
  isDateFirstPivot?: boolean;
  selectedValue?: string;
  targetPeriod?: string;
  title: string;
  onValueChange: (change: DateOperatorOption) => void;
}

export const TargetTimePeriodCalendar: React.FC<Props> = ({
  isDateFirstPivot = false,
  selectedValue,
  targetPeriod,
  title,
  onValueChange,
}) => {
  const { options: targetPeriodOptions } = useTargetPeriodOptions(
    targetPeriod || ''
  );

  return (
    <DropdownCalendar
      config={{
        allowFuture: true,
        checkedAll: false,
        checkedTotal: 0,
        title: title,
        withShowAll: true,
        isLocked: isDateFirstPivot,
        customEnd: false,
        customStart: false,
        values: targetPeriodOptions.map((option) => ({
          ...option,
          checked: option.value === selectedValue,
        })),
        showInNotInFilter: false,
      }}
      key="Target Time Period Filter"
      name="Target Time Period Filter"
      onChange={onValueChange}
    />
  );
};
