import styled from '@emotion/styled';

export const FlexColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
});

export const MetricOptionsColumn = styled.div({
  width: '520px',
  minWidth: '520px',
  display: 'flex',
  flexDirection: 'column',
  borderRight: 'solid var(--bu-gray-400) 1px',
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 115px)',
});

export const MetricOptionSection = styled.div(
  (props: { expanded: boolean; first: boolean }) =>
    props.expanded
      ? {
          flexGrow: 1,
          overflowX: 'visible',
          borderTop: props.first ? 0 : 'solid var(--bu-gray-400) 1px',
          padding: '16px',
        }
      : {
          flexGrow: 0,
          height: '60px',
          overflow: 'hidden',
          borderTop: props.first ? 0 : 'solid var(--bu-gray-400) 1px',
          padding: '16px',
        }
);

export const MetricPreviewColumn = styled.div({
  flexGrow: 1,
  background: 'var(--bu-gray-200)',
  alignItems: 'center',
  padding: '24px 48px',
  overflow: 'auto',
});

export const MetricPreviewPlaceholder = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  background: 'white',
  alignItems: 'center',
  padding: '48px 20px',
  gap: '16px',
  maxHeight: '512px',
  borderRadius: '8px',
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.15)',
});

export const MetricPreview = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
});

export const SectionTitle = styled.div({
  fontWeight: 'bolder',
  fontSize: '18px',
  cursor: 'pointer',
  display: 'flex',
  gap: '8px',
});

export const PreviewNoteParagraph = styled.p({
  color: 'black',
  fontWeight: 400,
  fontSize: '12px',
  textAlign: 'center',
  marginTop: '16px',
  marginBottom: '48px !important',
});
