/* eslint-disable import/prefer-default-export */

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import clx from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deleteCustomView, updateCustomView } from 'actions/customViews';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import BuIcon from 'components/UI/BuIcon';
import {
  additional_features,
  additional_features_btn,
  additional_features_box,
  additional_features_box_item,
} from 'components/UI/OpenFiltersPanel/ViewDropdownFilter/styles';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { IReduxState } from 'reducers/types';
import { getActiveFiltersProfile, getFiltersForCustomViews } from 'selectors';

type Props = {
  tab: string;
  profileId: string;
  profileName: string;
  profileShareable: boolean;
  profileDeleteable?: boolean;
};

export const AdditionalDropdown: React.FC<Props> = ({
  tab,
  profileId,
  profileName,
  profileShareable,
  profileDeleteable,
}) => {
  const dispatch = useDispatch();
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const [isOpenShareConfirm, setIsOpenShareConfirm] = useState<boolean>(false);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] =
    useState<boolean>(false);

  const { properties, selectedProfile } = useSelector((state: IReduxState) => ({
    properties: getFiltersForCustomViews(state, tab),
    selectedProfile: getActiveFiltersProfile(state, tab),
  }));

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div ref={refElement} className={additional_features}>
      <button
        type="button"
        onClick={toggle}
        className={clx(additional_features_btn, { open: isOpen })}
      >
        <BuIcon name={BoostUpIcons.MenuVert} />
      </button>

      <div className={clx(additional_features_box, { open: isOpen })}>
        {profileShareable && (
          <button
            className={additional_features_box_item}
            onClick={() => setIsOpenShareConfirm(true)}
          >
            Share
          </button>
        )}

        {profileDeleteable && (
          <button
            className={additional_features_box_item}
            onClick={() => setIsOpenDeleteConfirm(true)}
          >
            Delete
          </button>
        )}
      </div>

      <BuConfirmationPopup
        isOpen={isOpenDeleteConfirm}
        onClose={() => setIsOpenDeleteConfirm(false)}
        onConfirm={() => {
          dispatch(deleteCustomView(profileId, tab, profileName));

          setIsOpenDeleteConfirm(false);
          setIsOpen(false);
        }}
        confirmText="Yes"
        cancelText="No"
      >
        <p>
          Are you sure you want to delete ‘{profileName}’ view? It will be
          permanently removed.
        </p>
      </BuConfirmationPopup>

      <BuConfirmationPopup
        isOpen={isOpenShareConfirm}
        onClose={() => setIsOpenShareConfirm(false)}
        onConfirm={() => {
          dispatch(
            updateCustomView(profileId, {
              view_type: 'GLOBAL',
              view_section: tab,
              pinned: Boolean(selectedProfile?.profile.pinned),
              properties: {
                ...properties,
                tablePersistName: selectedProfile?.profile.tablePersistName,
                columnToggleState: selectedProfile?.profile.columnToggleState,
                selectedBusinessType:
                  selectedProfile?.profile.selectedBusinessType,
              } as any,
            })
          );

          setIsOpenShareConfirm(false);
          setIsOpen(false);
        }}
        confirmText="Yes"
        cancelText="No"
      >
        <p>
          Saved dashboard will be available as an option under the ‘View’
          dropdown located on the top left side of the filters.
        </p>
      </BuConfirmationPopup>
    </div>
  );
};
