import { css } from 'emotion';

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const optionGroup = css`
  margin-bottom: 24px;
`;

export const conditionSubTitle = css`
  font-size: 17px;
  font-weight: 600;
  color: var(--bu-gray-900);
  margin-bottom: 12px;
`;

export const conditionDescription = css`
  font-size: 12px;
  color: var(--bu-gray-700);
`;

export const addButtonContainer = css`
  max-width: 150px;
`;

export const addConditionButton = css`
  color: var(--bu-gray-900) !important;
  background-color: var(--bu-white) !important;
  &:hover {
    background-color: var(--bu-gray-400) !important;
  }
`;

export const conditionsContainer = css`
  margin-bottom: 16px;
`;
