import * as t from 'actions/revbi/actionTypes';
import {
  BIMetricFormula,
  BIMetricFormulaNewborn,
  BIMetricSimple,
  BIMetricSimpleNewborn,
  GetMetricByIdSuccessPayload,
  MetricType,
  UpdateMetricByIdSuccessPayload,
  BIColumnListItem,
  BIWidgetPreview,
  CloneMetricActionPayload,
  DeleteMetricActionPayload,
  CreateMetricExtendedActionPayload,
  ComponentMode,
  UpdateMetricExtendedActionPayload,
} from 'components/dashboard/Metrics/metrics.types';
import * as genericSagas from 'sagas/generic';
import * as revbiSagas from 'sagas/revbi';
import { actionCreator } from 'utils/factories';

/**
 * Action gets metric by ID and keep it in revbi store.
 * @param id Id of the requesting metric
 * @param isEditing It's optional boolean param. If it is true fetched metric is saving as editing metric.
 */
export const fetchMetricById = (id: string, isEditing?: boolean) => ({
  type: t.METRIC + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${id}`,
  saga: genericSagas.get,
  success: (payload: BIMetricSimple | BIMetricFormula) =>
    fetchMetricByIdSuccess({
      id,
      data: payload,
      isEditing,
    }),
  loading: () => fetchMetricByIdLoading({ id, isEditing }),
});
export const fetchMetricByIdSuccess =
  actionCreator<GetMetricByIdSuccessPayload>(t.METRIC + t.GET + t.SUCCESS);
export const fetchMetricByIdLoading = actionCreator<{
  id: string;
  isEditing?: boolean;
}>(t.METRIC + t.GET + t.LOADING);

/**
 * Action updates metric by ID on the server and keep it in revbi store.
 * @param id Id of the requesting metric
 * @param metric Pass metric object for updating
 */
export const updateMetricById = (
  id: string,
  metric: BIMetricSimple | BIMetricFormula
) => ({
  type: t.METRIC + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${id}`,
  data: metric,
  saga: genericSagas.update,
  success: (payload: BIMetricSimple | BIMetricFormula) =>
    updateMetricByIdSuccess({
      id,
      data: payload,
    }),
  loading: () => updateMetricByIdLoading({ id }),
});
export const updateMetricByIdSuccess =
  actionCreator<UpdateMetricByIdSuccessPayload>(
    t.METRIC + t.UPDATE + t.SUCCESS
  );
export const updateMetricByIdLoading = actionCreator<{ id: string }>(
  t.METRIC + t.UPDATE + t.LOADING
);

/**
 * Action fetches all metrics from the server
 * @see /rev_bi/metrics API GET
 */
export const fetchAllMetrics = () => ({
  type: t.METRIC + t.GET + t.ALL,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics`,
  saga: genericSagas.get,
  success: (payload: (BIMetricSimple | BIMetricFormula)[]) =>
    fetchAllMetricsSuccess(payload),
  loading: fetchAllMetricsLoading,
});
export const fetchAllMetricsSuccess = actionCreator<
  (BIMetricSimple | BIMetricFormula)[]
>(t.METRIC + t.GET + t.ALL + t.SUCCESS);
export const fetchAllMetricsLoading = actionCreator(
  t.METRIC + t.GET + t.ALL + t.LOADING
);

/**
 * Action fetches all metrics from the server
 * @see /rev_bi/metrics/metrics_time_series API GET
 */
export const fetchAllTSMetrics = () => ({
  type: t.METRIC_TIME_SERIES + t.GET + t.ALL,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics_time_series`,
  saga: genericSagas.get,
  success: (payload: (BIMetricSimple | BIMetricFormula)[]) =>
    fetchAllTSMetricsSuccess(payload),
  loading: fetchAllTSMetricsLoading,
});

export const fetchAllTSMetricsSuccess = actionCreator<
  (BIMetricSimple | BIMetricFormula)[]
>(t.METRIC_TIME_SERIES + t.GET + t.ALL + t.SUCCESS);
export const fetchAllTSMetricsLoading = actionCreator(
  t.METRIC_TIME_SERIES + t.GET + t.ALL + t.LOADING
);

/**
 * Action removes deleted metric from the metrics list in store.
 */
export const removeMetric = actionCreator<{ ids: string[] }>(
  t.METRIC + t.REMOVE
);

/**
 * Action creates metric and keep it in revbi store.
 * @param metric Pass metric object for creation
 * @param addToWidgetCreationMetricsList put true in order to add it to list of metrics on widget creation component
 */
export const createMetric = (
  metric: BIMetricSimpleNewborn | BIMetricFormulaNewborn,
  addToWidgetCreationMetricsList?: boolean
) => ({
  type: t.METRIC + t.CREATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics`,
  data: metric,
  saga: genericSagas.create,
  success: (payload: BIMetricSimple | BIMetricFormula) =>
    createMetricSuccess({ metric: payload, addToWidgetCreationMetricsList }),
  loading: createMetricLoading,
});
export const createMetricSuccess = actionCreator<{
  metric: BIMetricFormula | BIMetricSimple;
  addToWidgetCreationMetricsList?: boolean;
}>(t.METRIC + t.CREATE + t.SUCCESS);
export const createMetricLoading = actionCreator(
  t.METRIC + t.CREATE + t.LOADING
);

/**
 * Action creates metric and keep it in revbi store. Addtional functionality: showing toasts, redirects
 * @param metric Pass metric object for creation
 * @param addToWidgetCreationMetricsList put true in order to add it to list of metrics on widget creation component
 */
export const createMetricExtended = (
  payload: CreateMetricExtendedActionPayload
) => ({
  type: t.METRIC + t.CREATE + t.EXTENDED,
  saga: revbiSagas.createMetricExtended,
  payload,
});

/**
 * Action updates metric and keep it in revbi store. Addtional functionality: showing toasts, redirects
 * @param payload Pass metric object for creation
 */
export const updateMetricExtended = (
  payload: UpdateMetricExtendedActionPayload
) => ({
  type: t.METRIC + t.UPDATE + t.EXTENDED,
  saga: revbiSagas.updateMetricExtended,
  payload,
});

/**
 * Action changes active metric in revbi store.
 * @param metric Pass metric object
 */
export const changeActiveMetric = actionCreator<
  | BIMetricFormula
  | BIMetricSimple
  | BIMetricSimpleNewborn
  | BIMetricFormulaNewborn
>(t.ACTIVE_METRIC + t.UPDATE);

/**
 * Action gets objects list from API
 * @see /rev_bi/external/get_object_list
 */
export const fetchObjectList = () => ({
  type: t.OBJECT_LIST + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_object_list`,
  saga: genericSagas.get,
  success: (payload: string[]) => fetchObjectListSuccess(payload),
  loading: fetchObjectLoading,
});
export const fetchObjectListSuccess = actionCreator<string[]>(
  t.OBJECT_LIST + t.GET + t.SUCCESS
);
export const fetchObjectLoading = actionCreator(
  t.OBJECT_LIST + t.GET + t.LOADING
);

/**
 * Action gets objects list from API
 * @see /rev_bi/external/get_object_history
 */
export const fetchTimeSeriesObjectList = () => ({
  type: t.OBJECT_LIST + t.TIME_SERIES + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_object_list_history`,
  saga: genericSagas.get,
  success: (payload: string[]) => fetchTimeSeriesObjectListSuccess(payload),
  loading: fetchTimeSeriesObjectLoading,
});
export const fetchTimeSeriesObjectListSuccess = actionCreator<string[]>(
  t.OBJECT_LIST + t.TIME_SERIES + t.GET + t.SUCCESS
);
export const fetchTimeSeriesObjectLoading = actionCreator(
  t.OBJECT_LIST + t.TIME_SERIES + t.GET + t.LOADING
);

/**
 * Action changes formula type. If it is editing. Formula will be copied in the storage but with fields fit to switched typed. If it new metric the metric type field will be changed only.
 * @param MetricType Put on of the metric type from enum
 * @see src/reducers/revbi/metrics.ts
 */
export const switchTab = actionCreator<MetricType>(
  t.CREATE_METRIC_TAB + t.CHANGE
);

/**
 * Action fires getting column fields filter from API by object type.
 * @see /rev_bi/external/get_column_fields_filter
 * @param tables array of table names willing to fetch
 */
export const fetchColumnFieldsFilter = (tables: string[]) => ({
  type: t.COLUMN_FIELDS_FILTER + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_filter`,
  saga: genericSagas.create,
  data: {
    tables,
  },
  success: (payload: BIColumnListItem[]) =>
    fetchColumnFieldsFilterSuccess(payload),
  loading: fetchColumnFieldsFilterLoading,
});
export const fetchColumnFieldsFilterSuccess = actionCreator<BIColumnListItem[]>(
  t.COLUMN_FIELDS_FILTER + t.GET + t.SUCCESS
);
export const fetchColumnFieldsFilterLoading = actionCreator(
  t.COLUMN_FIELDS_FILTER + t.GET + t.LOADING
);

/**
 * Action fires getting column fields filter from API
 * @see /rev_bi/external/get_column_fields_filter_history
 */
export const fetchColumnFieldsFilterTimeSeries = () => ({
  type: t.COLUMN_FIELDS_FILTER + t.TIME_SERIES + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_filter_history`,
  saga: genericSagas.get,
  success: (payload: BIColumnListItem[]) =>
    fetchColumnFieldsFilterTimeSeriesSuccess(payload),
  loading: fetchColumnFieldsFilterTimeSeriesLoading,
});
export const fetchColumnFieldsFilterTimeSeriesSuccess = actionCreator<
  BIColumnListItem[]
>(t.COLUMN_FIELDS_FILTER + t.TIME_SERIES + t.GET + t.SUCCESS);
export const fetchColumnFieldsFilterTimeSeriesLoading = actionCreator(
  t.COLUMN_FIELDS_FILTER + t.TIME_SERIES + t.GET + t.LOADING
);

/**
 * Action fires getting column fields from API by object type.
 * @see /rev_bi/external/get_column_fields/
 * @param object object type for willing column fields
 */
export const fetchColumnFields = (object: string) => ({
  type: t.COLUMN_FIELDS + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields/${object}`,
  saga: genericSagas.get,
  success: (payload: BIColumnListItem[]) =>
    fetchColumnFieldsSuccess({ columns: payload, object }),
  loading: fetchColumnFieldsLoading,
});

export const fetchColumnFieldsSuccess = actionCreator<{
  columns: BIColumnListItem[];
  object: string;
}>(t.COLUMN_FIELDS + t.GET + t.SUCCESS);
export const fetchColumnFieldsLoading = actionCreator(
  t.COLUMN_FIELDS + t.GET + t.LOADING
);

/**
 * Action sets formula valid field.
 * @param valid provide validating state
 */
export const setFormulaValid = actionCreator<boolean>(
  t.FORMULA_VALID + t.CHANGE
);

/**
 * Action cleans up creation metric state. Call it on unmount component.
 */
export const cleanUpMetricCreationState = actionCreator(
  t.CLEAN_UP_CREATION_STATE
);

/**
 * Action adds a new existed metric to widget creation metrics list
 * @param metric - metric to be added
 */
export const addMetricToWidgetCreationMetricsList = actionCreator<
  BIMetricFormula | BIMetricSimple
>(t.WIDGET_CREATION_METRICS_LIST + t.ADD);

/**
 * Action removes existed metric from widget creation metrics list
 * @param metricId - metric id to be removed
 */
export const removeMetricFromWidgetCreationMetricsList = actionCreator<string>(
  t.WIDGET_CREATION_METRICS_LIST + t.REMOVE
);

/**
 * Action changes creation metric component mode
 * @param componentMode pass new component mode
 */
export const changeCreateMetricComponentMode = actionCreator<ComponentMode>(
  t.CREATE_METRIC_COMPONENT_MODE + t.CHANGE
);
/**
 * Action updates preview widget
 * @param widget pass widget object
 */
export const changePreviewWidget = actionCreator<BIWidgetPreview>(
  t.PREVIEW_WIDGET + t.CHANGE
);

/**
 * Action clones metric and optionally put it to widget editing / creating object.
 * @param Payload describes various params like redirects, toast etc
 * @see CloneMetricActionPayload
 */
export const cloneMetric = (payload: CloneMetricActionPayload) => ({
  type: t.METRIC + t.CLONE,
  saga: revbiSagas.cloneMetric,
  payload,
});

/**
 * Action removes metric by ID
 * @param Payload describes various params like id, redirects, toast etc
 * @see CloneMetricActionPayload
 */
export const deleteMetric = (payload: DeleteMetricActionPayload) => ({
  type: t.METRIC + t.DELETE,
  saga: revbiSagas.deleteMetric,
  payload,
});
