import { css } from 'emotion';

export const container = css`
  position: relative;
`;

export const button = css`
  padding: 5px 10px;
  white-space: nowrap;
  background: var(--bu-primary-500);
  border: 1px solid var(--bu-primary-500);
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--bu-font-regular);
  cursor: pointer;
  color: var(--bu-white);
  transition: 0.4s;
`;

export const options = css`
  z-index: 150;
  position: absolute;
  top: 32px;
  width: max-content;
  max-width: 520px;
  background: var(--bu-white);
  visibility: hidden;
  opacity: 0;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.19);
  border: 1px solid #ccc;
  overflow: auto;

  &.open {
    visibility: visible;
    opacity: 1;
  }

  &.right {
    right: 0;
  }

  &.left {
    left: 0;
  }
`;

export const action_btns = css`
  display: flex;
`;

export const action_btn = css`
  padding: 5px 30px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  font-family: var(--bu-font-regular);

  &.save {
    background: var(--bu-primary-500);
    border: 1px solid transparent;
    color: var(--bu-white);
    margin-right: 20px;
    opacity: 1;
    transition: opacity 0.4s;

    &:disabled {
      cursor: default;
      opacity: 0.5;
    }
  }

  &.as_new {
    background: var(--bu-white);
    border: 1px solid var(--bu-primary-500);
    color: var(--bu-primary-500);
    margin-right: 20px;
    opacity: 1;
    transition: opacity 0.4s;

    &:disabled {
      cursor: default;
      opacity: 0.5;
    }
  }

  &.cancel {
    background: var(--bu-white);
    border: 1px solid var(--bu-gray-400);
    color: var(--bu-gray-700);
  }
`;

export const input_label = css`
  margin-bottom: 8px;
  font-size: 14px;
  position: relative;
  color: var(--bu-gray-700);
  font-family: var(--bu-font-regular);
  display: inline-block;

  &:after {
    content: '*';
    position: absolute;
    right: -10px;
    top: 0;
    color: var(--bu-red-500);
  }
`;

export const input_text = css`
  padding: 6px 15px;
  width: 100%;
  font-size: 14px;
  border: 1px solid var(--bu-primary-500);
  border-radius: 4px;
  font-family: var(--bu-font-regular);

  &.error {
    border: 1px solid var(--bu-red-500);
  }
`;

export const error_message = css`
  color: var(--bu-red-500);
  height: 18px;
`;

export const checkbox_text = css`
  color: var(--bu-gray-900);
  font-size: 14px;
  font-family: var(--bu-font-regular);
  line-height: 20px;
  margin-top: 7px;
`;

export const option_header = css`
  background: var(--bu-white);
  padding: 10px 20px;
  font-size: 14px;
  color: var(--bu-gray-900);
  font-family: var(--bu-font-medium);
  border-bottom: 1px solid var(--bu-gray-500);
`;

export const option_body = css`
  padding: 20px;
`;

export const option_box = css`
  margin-bottom: 20px;

  &.flex {
    display: flex;
  }
`;

export const option_box_checkbox = css`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
`;

export const note_text = css`
  font-size: 12px;
  line-height: 18px;
  color: var(--bu-gray-700);
`;
