import get from 'lodash/get';
import moment from 'moment';
import React from 'react';

import ObjectionText from 'components/UI/MomentsTable/MomentsRow/ObjectionText';
import { IProps } from 'components/UI/MomentsTable/MomentsRow/types';
import * as styles from 'components/UI/MomentsTable/styles';
import { ModalLink } from 'navigation/Link';

const MomentsRow: React.FC<IProps> = ({ data, dataType }) => {
  const phrase = get(data, [dataType, 'matches', 0, 'phrase'], '');
  const match = get(data, [dataType, 'matches', 0, 'match'], '');
  const name = get(data, ['mentioned_by', 'name'], '');
  const title = get(data, ['mentioned_by', 'title'], '');

  return (
    <tr className={styles.body_table_row}>
      <td className={styles.body_table_column} style={{ width: '50%' }}>
        <ObjectionText phrase={phrase} match={match} />

        {data.source === 'email' && (
          <ModalLink
            url={{
              scheme: '/email/:emailId',
              params: { emailId: data.object_id },
              persistParams: { topic: get(data, [dataType, 'topic_name']) },
            }}
            className={styles.show_text}
          >
            More
          </ModalLink>
        )}
      </td>
      <td className={styles.body_table_column} style={{ width: '25%' }}>
        {get(data, [dataType, 'topic_name'])}
      </td>
      <td className={styles.body_table_column} style={{ width: '25%' }}>
        <div className={styles.prospect_position}>{name}</div>
        <div>{title}</div>
        <div className={styles.date}>{moment(data.mentioned_at).fromNow()}</div>
      </td>
    </tr>
  );
};

export default MomentsRow;
