import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { Loader } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import {
  ForecastSubmissionSettingRecord,
  ForecastSubmissionSettingsDealsItemReduxState,
} from 'common/types/forecastSubmission';
import BuIcon from 'components/UI/BuIcon';
import { IdType } from 'components/UI/common/TypedTable/TypedTable';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import Form from 'components/dashboard/Forecast/Dashboard/SubmitForecast/Form';
import History from 'components/dashboard/Forecast/Dashboard/SubmitForecast/History';
import * as styles from 'components/dashboard/Forecast/Dashboard/SubmitForecast/styles';
import { Formatter } from 'components/dashboard/Forecast/Dashboard/SubmitForecast/types';
import usePopulateDealsAndHistoryForForecastSubmissionSetting from 'components/hooks/usePopulateDealsAndHistoryForForecastSubmissionSetting';
import { getForecastActiveBusinessType } from 'selectors';
import { OVERALL_BUSINESS_TYPE } from 'selectors/settings';

type Props = {
  businessType: string;
  formatter: Formatter;
  included: string[];
  shouldPreload: boolean;
  isActive: boolean;
  onIncludedDealsChange: (
    includedDealsIds: IdType[],
    excludedDealsIds: IdType[],
    activeSettingsId: string,
    includeWithReplace?: boolean
  ) => void;
  resetIncludedAndExcludedDeals: (
    activeSettingsId: string,
    deals: ForecastSubmissionSettingsDealsItemReduxState[]
  ) => void;
  onSubmit: (amount: number, notes: string) => void;
  openDealsModal: () => void;
  setActiveBusinessType: (settingsId: string) => void;
  setting: ForecastSubmissionSettingRecord;
  toggle: () => void;
};

const Record: React.FC<Props> = ({
  formatter,
  included,
  shouldPreload = false,
  isActive,
  onIncludedDealsChange,
  resetIncludedAndExcludedDeals,
  onSubmit,
  openDealsModal,
  setActiveBusinessType,
  setting,
  toggle,
}) => {
  const activeBusinessType = useSelector(getForecastActiveBusinessType);
  const isOverall = activeBusinessType === OVERALL_BUSINESS_TYPE;
  const forecastName = setting.forecast_display_name || setting.forecast_name;
  const forecastDisplayName = `${forecastName} (${setting.period_info})`;
  const { deals, submissionsToCopy, isLoading } =
    usePopulateDealsAndHistoryForForecastSubmissionSetting(
      setting.id,
      isActive || shouldPreload
    );

  useEffect(() => {
    if (deals?.deals) {
      resetIncludedAndExcludedDeals(setting.id, deals.deals);
    }
  }, [deals]);

  const submissionLockingType = useMemo(() => {
    /**
     * submission_locking.locking_type bring the strategy set in settings
     * submission_locked_types bring if the user is locked by some of the strategies
     */
    const { submission_locked_types, submission_locking } = setting;

    if (submission_locked_types && !!submission_locked_types.length) {
      return (
        submission_locking.locking_type.find(
          (locking_type) => locking_type === submission_locked_types[0]
        ) || ''
      );
    }

    return '';
  }, [setting]);

  const hasSubmissionLockingConfigured = useMemo(
    () => !!setting.submission_locking.locking_type.length,
    [setting]
  );

  const submissionLockingIconConfig = useMemo(() => {
    if (!!submissionLockingType) {
      return {
        text: 'Submission is locked because a manager above you has already submitted this week',
        color: 'var(--bu-red-500)',
        icon: BoostUpIcons.Lock,
      };
    } else if (hasSubmissionLockingConfigured && !submissionLockingType) {
      return {
        text: 'Submission locking is enabled for this forecast type',
        color: 'var(--bu-gray-400)',
        icon: BoostUpIcons.Unlock,
      };
    }

    return null;
  }, [submissionLockingType, hasSubmissionLockingConfigured]);

  return (
    <div
      className={classNames(styles.formRecord, {
        active: isActive,
      })}
    >
      <div
        className={styles.recordHeader}
        onClick={(e) => {
          isActive && e.stopPropagation();
          toggle();
        }}
      >
        <div
          className={classNames(styles.recordTHeader, 'bu-font-default', {
            active: isActive,
          })}
        >
          {isOverall && (
            <span>
              [{setting.business_type_name}]
              <br />
            </span>
          )}
          <span>{forecastDisplayName}</span>
        </div>

        <div className={classNames(styles.recordIconsWrapper)}>
          {!!submissionLockingIconConfig && (
            <TooltipWrapper
              tooltip={
                <div style={{ width: '200px', textAlign: 'center' }}>
                  {submissionLockingIconConfig.text}
                </div>
              }
              position="top right"
              leftOffset={11}
            >
              <div className={classNames(styles.recordLockIcon)}>
                <BuIcon
                  name={submissionLockingIconConfig.icon}
                  color={submissionLockingIconConfig.color}
                />
              </div>
            </TooltipWrapper>
          )}

          <BuIcon
            className={classNames(styles.recordSignIcon, { active: isActive })}
            name={
              isActive ? BoostUpIcons.ChevronDown : BoostUpIcons.ChevronRight
            }
          />
        </div>
      </div>

      {isActive && (
        <>
          {isLoading ? (
            <div className={styles.loaderArea}>
              <Loader active inline="centered" size="mini" />
            </div>
          ) : (
            <div>
              <Form
                formatter={formatter}
                included={included}
                onIncludedDealsChange={onIncludedDealsChange}
                resetIncludedAndExcludedDeals={resetIncludedAndExcludedDeals}
                onSubmit={onSubmit}
                openDealsModal={openDealsModal}
                deals={deals}
                submissionsToCopy={submissionsToCopy}
                submissionSettingId={setting.id}
                includeExcludeSupported={setting.include_exclude_supported}
                persist={setting.persist}
                submissionLockingType={submissionLockingType}
              />
              <History
                formatter={formatter}
                settingId={setting.id}
                setActiveBusinessType={setActiveBusinessType}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Record;
