import {
  getDeltaValueForField,
  getRawValue,
  mergeFieldItemsWithDeltas,
} from './rowValueExtractors';
import { sanitize } from 'dompurify';
import * as R from 'ramda';
import React from 'react';

import InfoIcon from 'assets/fonts/boostup-icons/badge_info_outline.svg';
import { CallbackProps } from 'components/UI/TableConfig/column-helper';
import { TableConfigurationColumn } from 'components/UI/TableConfig/types';
import {
  IColumn,
  IRow,
  TooltipConfig,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import MonthCustomCell from 'components/dashboard/ForecastRollUps/MonthCustomCell';
import { calculatePipelinesTree } from 'components/dashboard/ForecastRollUps/helpers/calculatePipelinesTree';
import { applyFormulaColumn } from 'components/dashboard/ForecastRollUps/helpers/formulas';
import { sortAndAggTree } from 'components/dashboard/ForecastRollUps/helpers/sortAndAggTree';
import { treeify } from 'components/dashboard/ForecastRollUps/helpers/treeify';
import * as styles from 'components/dashboard/ForecastRollUps/styles';
import {
  ApiResponse,
  IUserRow,
  RollupsItem,
} from 'components/dashboard/ForecastRollUps/types';

const getMyOrgTooltip: TooltipConfig['getTooltip'] = (_value, args) => (
  <>
    {args.Role && <div className={styles.tooltipMyOrg_role}>{args.Role}</div>}
    {args.email && (
      <div className={styles.tooltipMyOrg_email}>{args.email}</div>
    )}
  </>
);

const getPipelineCoverageClassName = (value: ValueProp, row: IRow) => {
  const desired = row['User Role Multiplier'] || 0;
  return !isEmptyValue(value)
    ? value >= desired
      ? styles.percentGreen
      : styles.percentRed
    : '';
};

export const isEmptyValue = (value: ValueProp): value is null | undefined =>
  R.isNil(value) || value === '-' || value === 'N/A';

export const getPipelineGapClassName = (value: ValueProp) =>
  !isEmptyValue(value)
    ? value >= 100
      ? styles.percentGreen
      : styles.percentRed
    : '';

export const processTableData = (
  { columns, data }: ApiResponse,
  primaryField: string,
  getFormulaColumnTooltip: (
    column: TableConfigurationColumn,
    columns: TableConfigurationColumn[]
  ) => TooltipConfig['getTooltip'],
  getSubmittedForecastTooltip: (
    column: TableConfigurationColumn
  ) => TooltipConfig['getTooltip'],
  getFormulaHeaderTooltip: (column: IColumn) => React.ReactNode,
  getPipelineGapTooltip: TooltipConfig['getTooltip'],
  getPipelineCoverageTooltip: TooltipConfig['getTooltip'],
  getSubmissionPrefix: (
    column: TableConfigurationColumn
  ) => TooltipConfig['getTooltip'],
  onClick?: (props: CallbackProps) => void,
  key?: string,
  pivotValue?: string
) => {
  const tooltipColumsWithHtmlHover = columns.filter(
    (column) => column.meta.tooltip && column.meta.tooltip.is_html
  );

  const fixedColumns = columns.map((column) => {
    // Add a custom class to the primary field and its subValue.
    if (column.field_name === primaryField) {
      column.meta.class_name = 'primaryField';
      if (column.meta.sub_value) {
        column.meta.sub_value.class_name = styles.primaryFieldSubValue;
      }
    }

    /* FIXME temporary disabled, because VPD-8840
    // Force 'My Org' column to be custom
    if (column.field_name === 'My Org') {
      column.type = 'custom';
      column.max_width = 160;
      column.meta = {
        ...column.meta,
        renderer: FirstColumnCustomCell,
        type: 'with_render',
        onClick,
      };
    }
    */

    // Add custom class name getters.
    switch (column.meta.class_name) {
      case 'pipeline_gap':
        column.meta.get_class_name = getPipelineGapClassName;
        break;
      case 'pipeline_coverage':
        column.meta.get_class_name = getPipelineCoverageClassName;
        break;
      default:
    }

    // Add custom tooltip getters.
    switch (column.meta.tooltip?.name) {
      case 'my_org':
        column.meta.tooltip = {
          ...column.meta.tooltip,
          getTooltip: getMyOrgTooltip,
          relative_fields: ['Role', 'email'],
        };
        break;
      case 'submitted_forecast':
        column.meta.tooltip = {
          ...column.meta.tooltip,
          key,
          getTooltip: getSubmittedForecastTooltip(column),
          relative_fields: [
            'user_id',
            'My Org',
            'pivotEnabled',
            'Currency',
            `Prev ${column.object_field} Submission Date`,
          ],
        };
        break;
      case 'pipeline_gap':
        column.meta.tooltip = {
          ...column.meta.tooltip,
          getTooltip: getPipelineGapTooltip,
          relative_fields: [
            'User Role Multiplier',
            'Target',
            'Booked',
            'Total Pipe',
          ],
        };
        break;
      case 'pipeline_coverage':
        column.meta.tooltip = {
          ...column.meta.tooltip,
          getTooltip: getPipelineCoverageTooltip,
          relative_fields: [
            'User Role Multiplier',
            'Target',
            'Booked',
            'Total Pipe',
          ],
        };
        break;
      case 'next_qtr_pipeline_coverage':
        column.meta.tooltip = {
          ...column.meta.tooltip,
          getTooltip: getPipelineCoverageTooltip,
          relative_fields: [
            'User Role Multiplier',
            'Next Qtr Target',
            'Next Qtr Booked',
            'Next Qtr Total Pipe',
          ],
        };
        break;
      case 'formula_column':
        column.meta.tooltip = {
          ...column.meta.tooltip,
          getTooltip: getFormulaColumnTooltip(column, columns),
          relative_fields: [
            'My Org',
            ...(column.meta.tooltip.relative_fields || []),
          ],
        };
        break;
      default:
    }

    if (column.meta.type === 'formula') {
      column.meta.header = {
        tooltip: {
          hoverable: true,
          renderer: getFormulaHeaderTooltip,
        },
        icon: <img src={InfoIcon} alt="info" />,
      };
    }

    // Add custom tooltip prefix
    if (column.show_forecast_reminder) {
      column.meta = {
        ...column.meta,
        getPrefix: getSubmissionPrefix(column),
      };
    }

    if (column.meta.type === 'clickable') {
      column.meta.get_class_name = (value, row) =>
        row.isMonthRow ? styles.removeClickStyles : '';
    }

    if (column.field_name === 'Months') {
      column.type = 'custom';
      column.max_width = 160;
      column.meta = {
        ...column.meta,
        type: 'with_render',
        renderer: (cellProps) => {
          return <MonthCustomCell cellProps={cellProps} />;
        },
        onClick,
      };
    }

    return column;
  });
  /**
   * Fix user_id, manager_id and the primary field of data.
   * Also append a div with `dangerouslySetInnerHTML` for html tooltips.
   */

  const newData =
    String(pivotValue) === 'Managers'
      ? data.filter((row) => !!row.user_id)
      : data;

  const isDeltaField = (key: String) =>
    key?.startsWith('Prev ') && !key?.endsWith(' id');

  const getDeltaValueForDeltaField = (deltaFieldName: string, row: IRow) =>
    getDeltaValueForField(deltaFieldName?.replace('Prev ', ''), row);

  const findValueForRowField = (field: string, row: IRow) => {
    if (field?.startsWith('Prev ') && field?.endsWith(' id')) {
      let dealsIds = getRawValue(field.replace('Prev ', ''), row);
      let deltaDealIds = getRawValue(field, row);
      if (Array.isArray(dealsIds) && Array.isArray(deltaDealIds)) {
        return mergeFieldItemsWithDeltas(dealsIds, deltaDealIds);
      }
      return Array.isArray(deltaDealIds)
        ? deltaDealIds.filter((deal: RollupsItem) => !deal.filtered_out)
        : deltaDealIds;
    }
    return isDeltaField(field)
      ? getDeltaValueForDeltaField(field, row)
      : getRawValue(field, row);
  };

  const getAvailableDeltaFields = () =>
    columns
      .filter((column) => !!column?.meta?.sub_value?.badge)
      .map((column) =>
        column.meta.sub_value?.badge.relative_fields.find((field) =>
          field.startsWith('Prev ')
        )
      )
      .filter((field) => !!field) as string[];

  const fixedData = newData.map((row) => {
    const rowFields = Object.keys(row);
    // When user is disabled they don't appear in deltas data, but fields still need to be present to adecuately compute deltas,
    // that's why we add them based on columns configs.
    const fullFieldsSet =
      getRawValue('status', row) == 'disabled'
        ? new Set<string>([...rowFields, ...getAvailableDeltaFields()])
        : new Set<string>(rowFields);
    row = [...fullFieldsSet].reduce(
      (object, key) => ({ ...object, [key]: findValueForRowField(key, row) }),
      {}
    ) as IUserRow;
    return {
      ...row,
      ...tooltipColumsWithHtmlHover.reduce((agg, column) => {
        const relativeField = column.meta.tooltip?.relative_field || '';
        const value = row[relativeField];

        return value
          ? {
              ...agg,
              [relativeField]: (
                <div
                  className={styles.htmlTooltip}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(value as string),
                  }}
                ></div>
              ),
            }
          : agg;
      }, {}),
      user_id: row.user_id?.$oid,
      group_by_id: row.index ?? row.user_id?.$oid,
      manager_id: row.manager_id?.$oid,
      pivotEnabled: row.index !== row.user_id?.$oid,
      [primaryField]: row[primaryField] || row.email,
    };
  });

  let tree = treeify(fixedData, 'group_by_id', 'manager_id');

  // Sort and agg tree.
  const columnsToAgg = columns.filter(
    (column) =>
      column.meta.aggregation && column.meta.aggregation === 'hierarchy'
  );

  const columnsToChildAgg = columns.filter(
    (column) =>
      column.meta.sub_value &&
      column.meta.sub_value.aggregation === 'hierarchy_child'
  );
  tree = sortAndAggTree(tree, columnsToAgg, columnsToChildAgg);

  // Calculate Pipelines of tree.
  const columnsWithPipelineGap = columns.filter(
    (column) => column.meta.pipeline_gap
  );
  const columnsWithPipelineCoverage = columns.filter(
    (column) => column.meta.pipeline_coverage
  );
  tree = calculatePipelinesTree(
    tree,
    columnsWithPipelineGap,
    columnsWithPipelineCoverage
  );

  // Analyze the columns and apply formulas if has any on all rows.
  applyFormulaColumn(tree, fixedColumns);

  return {
    columns: fixedColumns,
    rows: tree,
  };
};
