import classNames from 'classnames';
import { css } from 'emotion';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
  MutableRefObject,
} from 'react';

import { TableDetails } from 'components/UI/common/TypedTable/TypedTable';

const horizontalScroll = css`
  width: 100%;
  padding-bottom: 10px; /* scrollbar height*/

  overflow-x: hidden;

  &.alwaysShow {
    padding-bottom: 0; /* scrollbar height*/
    overflow-x: auto;
  }

  &:hover {
    padding-bottom: 0; /* scrollbar height*/
    overflow-x: auto;
  }
`;

const verticalScroll = css`
  height: 100%;
  overflow-y: hidden;

  &.alwaysShow {
    overflow-y: auto;
  }

  &:hover {
    overflow-y: auto;
  }
`;

const scrollShadow = css`
  position: relative;

  .shadow-top,
  .shadow-right,
  .shadow-bottom,
  .shadow-left {
    position: absolute;
    border-radius: 6em;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
    z-index: 130;
  }

  .shadow-top,
  .shadow-bottom {
    right: 0;
    left: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    background-image: linear-gradient(
      rgba(85, 85, 85, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .shadow-top {
    top: 0;
    height: 0.4em;
  }

  .shadow-bottom {
    bottom: 0;
    height: 0.2em;
    transform: rotate(180deg);
  }

  .shadow-right,
  .shadow-left {
    top: 0;
    bottom: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-image: linear-gradient(
      90deg,
      rgba(85, 85, 85, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .shadow-right {
    right: 0;
    width: 0.2em;
    transform: rotate(180deg);
  }

  .shadow-left {
    left: 0;
    width: 0.4em;
  }

  .active {
    opacity: 1;
  }
`;

export const useTableShadows = (extraTopOffset?: number) => {
  const [shadowTopOffset, setShadowTopOffset] = useState<number>(0);
  const [shadowLeftOffset, setShadowLeftOffset] = useState<number>(0);

  const onRender = useCallback(
    (details: TableDetails) => {
      if (details.columns && details.columns[0]) {
        setShadowLeftOffset(details.columns[0].width);
        setShadowTopOffset(details.columns[0].height + (extraTopOffset ?? 0));
      }
    },
    [setShadowLeftOffset, setShadowTopOffset]
  );

  return {
    shadows: {
      shadowLeftOffset,
      shadowTopOffset,
    },
    onRender,
  };
};

type WidthHeightType = number | string;

interface IProps {
  width?: boolean | WidthHeightType /* true - fit to parent */;
  height?: boolean | WidthHeightType /* true - fit to parent */;
  shadows?: boolean;
  shadowTopOffset?: number;
  shadowLeftOffset?: number;
  alwaysShow?: boolean;
  maxHeight?: WidthHeightType;
  innerScrollRef?: MutableRefObject<HTMLDivElement | null>;
}

/**
 * TypeGuard
 */
function isStyleProp(prop: any): prop is WidthHeightType {
  return typeof prop !== 'boolean';
}

const Scrolling: React.FC<IProps> = ({
  children,
  width,
  height,
  shadows = false,
  shadowTopOffset = 0,
  shadowLeftOffset = 0,
  alwaysShow = false,
  maxHeight,
  innerScrollRef,
}: PropsWithChildren<IProps>) => {
  const scrollRef = useRef<HTMLDivElement | null>(null);

  const [shadowLeft, setShadowLeft] = useState(false);
  const [shadowTop, setShadowTop] = useState(false);
  const [shadowRight, setShadowRight] = useState(false);
  const [shadowBottom, setShadowBottom] = useState(false);

  const [shadowRightOffset, setShadowRightOffset] = useState(0);
  const [shadowBottomOffset, setShadowBottomOffset] = useState(0);

  const recalculateShadowsPositions = useCallback(() => {
    if (scrollRef.current) {
      const hasHorizontalScrollbar =
        scrollRef.current.clientWidth < scrollRef.current.scrollWidth;
      const hasVerticalScrollbar =
        scrollRef.current.clientHeight < scrollRef.current.scrollHeight;

      const scrolledFromLeft =
        scrollRef.current.offsetWidth + scrollRef.current.scrollLeft;
      const scrolledFromTop =
        scrollRef.current.offsetHeight + scrollRef.current.scrollTop;

      const scrolledToTop = scrollRef.current.scrollTop === 0;
      const scrolledToRight = scrolledFromLeft >= scrollRef.current.scrollWidth;
      const scrolledToBottom =
        scrolledFromTop >= scrollRef.current.scrollHeight;
      const scrolledToLeft = scrollRef.current.scrollLeft === 0;

      setShadowTop(hasVerticalScrollbar && !scrolledToTop);
      setShadowRight(hasHorizontalScrollbar && !scrolledToRight);
      setShadowBottom(hasVerticalScrollbar && !scrolledToBottom);
      setShadowLeft(hasHorizontalScrollbar && !scrolledToLeft);

      setShadowRightOffset(
        scrollRef.current.offsetWidth - scrollRef.current.clientWidth
      );
      setShadowBottomOffset(
        scrollRef.current.offsetHeight - scrollRef.current.clientHeight
      );
    }
  }, [scrollRef.current]);

  useEffect(() => {
    if (shadows) {
      if (scrollRef.current) {
        scrollRef.current.addEventListener(
          'scroll',
          recalculateShadowsPositions
        );
      }

      recalculateShadowsPositions();

      return () => {
        if (scrollRef.current) {
          scrollRef.current.removeEventListener(
            'scroll',
            recalculateShadowsPositions
          );
        }
      };
    }
  }, [scrollRef.current, shadows]);

  return (
    <div
      style={{
        width: isStyleProp(width) ? (width as WidthHeightType) : undefined,
        height: isStyleProp(height) ? (height as WidthHeightType) : undefined,
      }}
      className={classNames('scroll-wrapper', { [scrollShadow]: shadows })}
    >
      <div
        ref={(element) => {
          scrollRef.current = element;
          if (innerScrollRef) {
            innerScrollRef.current = element;
          }
        }}
        className={classNames({
          [horizontalScroll]: width,
          [verticalScroll]: height,
          alwaysShow,
        })}
        onMouseEnter={recalculateShadowsPositions}
        onMouseLeave={recalculateShadowsPositions}
        style={{
          paddingBottom: !(shadowLeft || shadowRight) ? 0 : undefined,
          maxHeight,
        }}
      >
        {children}

        {shadows && (
          <>
            <span
              className={classNames('shadow-top', { active: shadowTop })}
              style={{ top: shadowTopOffset, right: shadowRightOffset }}
            />
            <span
              className={classNames('shadow-right', { active: shadowRight })}
              style={{ right: shadowRightOffset, bottom: shadowBottomOffset }}
            />
            <span
              className={classNames('shadow-bottom', { active: shadowBottom })}
              style={{ bottom: shadowBottomOffset, right: shadowRightOffset }}
            />
            <span
              className={classNames('shadow-left', { active: shadowLeft })}
              style={{ left: shadowLeftOffset, bottom: shadowBottomOffset }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Scrolling;
