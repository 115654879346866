import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import { WidgetActions } from 'components/dashboard/Metrics/Widget/Header/Actions/WidgetActions';
import {
  DEFAULT_TITLE,
  PREVIEW_TITLE,
} from 'components/dashboard/Metrics/Widget/Header/constants';
import { WidgetTitleRow } from 'components/dashboard/Metrics/Widget/Header/styles';

interface Props {
  id?: string;
  name?: string;
  dashboardName?: string;
  tooltip?: JSX.Element;
  isCreateEditMetric?: boolean;
  isDashboardWidget?: boolean;
  isDashboardModal?: boolean;
  isReportViewWidget?: boolean;
  onEditWidget?: (metricId: string) => void;
  onCloneWidget?: (metricId: string) => void;
  onRemoveWidget?: (metricId: string) => void;
  onTitleClicked?: () => void;
  closeWidgetModal?: () => void;
}

export const WidgetHeader: React.FC<Props> = ({
  id,
  name,
  dashboardName = '',
  tooltip,
  isCreateEditMetric = false,
  isDashboardWidget = false,
  isDashboardModal = false,
  isReportViewWidget = false,
  onEditWidget,
  onCloneWidget,
  onRemoveWidget,
  onTitleClicked,
  closeWidgetModal,
}) => {
  const nameBuilder = () => {
    let currentName;

    if (isCreateEditMetric) {
      currentName = PREVIEW_TITLE;
    } else if (isDashboardModal) {
      currentName = (
        <>
          <span>{`${dashboardName} / `}</span> {name}
        </>
      );
    } else if (name) {
      currentName = name;
    } else {
      currentName = DEFAULT_TITLE;
    }

    return currentName;
  };

  return (
    <WidgetTitleRow isDashboardModal={isDashboardModal}>
      {isDashboardModal && (
        <BuButton
          borderless
          secondary
          onClick={closeWidgetModal}
          testingLabel="close-modal"
        >
          <BuIcon name={BoostUpIcons.ClosePopup} />
        </BuButton>
      )}
      <p onClick={onTitleClicked} data-testing="widget-title">
        {nameBuilder()}
      </p>

      {isDashboardWidget && !isDashboardModal && (
        <WidgetActions
          isReportViewWidget={isReportViewWidget}
          widgetId={id}
          tooltip={tooltip}
          onEditWidget={onEditWidget}
          onCloneWidget={onCloneWidget}
          onRemoveWidget={onRemoveWidget}
          data-testing="widget-actions"
        />
      )}
    </WidgetTitleRow>
  );
};
