import styled from '@emotion/styled';
import React from 'react';

interface MetricDescriptionProps {
  readonly color: string;
  readonly margin?: boolean;
}

const MetricDescription = styled.div(
  ({ color, margin }: MetricDescriptionProps) => ({
    padding: '1.5px 8px',
    width: 'fit-content',
    backgroundColor: `var(--bu-${color}-300)`,
    borderRadius: '12px',
    span: {
      fontSize: '12px',
      color: `var(--bu-${color}-700)`,
      whiteSpace: 'nowrap',
    },
    margin: margin ? '14px 0px' : 'revert',
  })
);

const AGGREGATION_FUNCTION_COLORS = [
  {
    aggregation: 'sum',
    color: 'indigo',
  },
  {
    aggregation: 'average',
    color: 'yellow',
  },
  {
    aggregation: 'min',
    color: 'lime',
  },
  {
    aggregation: 'max',
    color: 'purple',
  },
  {
    aggregation: 'count',
    color: 'primary',
  },
];

interface Props {
  readonly metricDescription: string | JSX.Element;
  readonly aggregationFunction: string;
}

export const MetricTag: React.FC<Props> = ({
  metricDescription,
  aggregationFunction,
}) => (
  <MetricDescription
    color={
      AGGREGATION_FUNCTION_COLORS.find(
        (element) => element.aggregation === aggregationFunction
      )?.color ?? 'green'
    }
  >
    <span>{metricDescription}</span>
  </MetricDescription>
);
