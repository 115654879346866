import CheckboxCell from './CheckboxCell';
import CustomCell from './CustomCell';
import React from 'react';

import { IDataCellProps } from 'components/UI/common/TypedTable/TypedTable';
import ActionCell from 'components/UI/common/TypedTable/renderers/ActionCell';
import ChangeCell from 'components/UI/common/TypedTable/renderers/ChangeCell';
import ClickableCell from 'components/UI/common/TypedTable/renderers/ClickableCell';
import CrmLookupCell from 'components/UI/common/TypedTable/renderers/CrmLookupCell';
import DateCell from 'components/UI/common/TypedTable/renderers/DateCell';
import DropDownCell from 'components/UI/common/TypedTable/renderers/DropDownCell';
import LinkCell from 'components/UI/common/TypedTable/renderers/LinkCell';
import MultiDropDownCell from 'components/UI/common/TypedTable/renderers/MultiDropDownCell';
import NotAvailableCell from 'components/UI/common/TypedTable/renderers/NotAvailableCell';
import NoteCell from 'components/UI/common/TypedTable/renderers/NoteCell';
import PopupTableCell from 'components/UI/common/TypedTable/renderers/PopupTableCell';
import RadioCell from 'components/UI/common/TypedTable/renderers/RadioCell';
import RiskScoreCell from 'components/UI/common/TypedTable/renderers/RiskScoreCell';
import SalesProcessCell from 'components/UI/common/TypedTable/renderers/SalesProcessCell';
import SelectCell from 'components/UI/common/TypedTable/renderers/SelectCell';
import ShowMoreCell from 'components/UI/common/TypedTable/renderers/ShowMoreCell';
import SimpleCell from 'components/UI/common/TypedTable/renderers/SimpleCell';
import SubmissionSummaryCell from 'components/UI/common/TypedTable/renderers/SubmissionSummaryCell';
import UserCell from 'components/UI/common/TypedTable/renderers/UserCell';

export enum ColumnTypes {
  ACTION = 'action',
  CHECKBOX = 'checkbox',
  CLICKABLE = 'clickable',
  CRM_LOOKUP = 'CRM Lookup',
  CUSTOM = 'custom',
  DATE = 'date',
  DROPDOWN = 'dropdown',
  MULTI_DROPDOWN = 'multidropdown',
  LINK = 'link',
  MONEY = 'money',
  CORPORATE_CURRENCY = 'corporate_currency',
  NOT_AVAILABLE = 'n/a',
  NUMBER = 'number',
  PERCENT = 'percent',
  RADIO = 'radio',
  SCORE = 'score',
  SELECT = 'select',
  STRING = 'string',
  TEXT = 'text',
  NOTE = 'note',
  SHOW_MORE = 'show_more',
  POPUP_TABLE = 'popup_table',
  CHANGE = 'change',
  USER = 'user',
  SALES_PROCESS = 'sales_process',
  SUBMISSION_SUMMARY = 'include_summary',
}

const Cells = {
  [ColumnTypes.ACTION]: ActionCell,
  [ColumnTypes.CHECKBOX]: CheckboxCell,
  [ColumnTypes.CLICKABLE]: ClickableCell,
  [ColumnTypes.CRM_LOOKUP]: CrmLookupCell,
  [ColumnTypes.CUSTOM]: CustomCell,
  [ColumnTypes.DATE]: DateCell,
  [ColumnTypes.DROPDOWN]: DropDownCell,
  [ColumnTypes.MULTI_DROPDOWN]: MultiDropDownCell, // this is multi select with [LABELS x]
  [ColumnTypes.SELECT]: SelectCell, // this is multi select with [LABELS x]
  [ColumnTypes.LINK]: LinkCell,
  [ColumnTypes.MONEY]: SimpleCell,
  [ColumnTypes.CORPORATE_CURRENCY]: SimpleCell,
  [ColumnTypes.NOT_AVAILABLE]: NotAvailableCell,
  [ColumnTypes.NUMBER]: SimpleCell,
  [ColumnTypes.PERCENT]: SimpleCell,
  [ColumnTypes.RADIO]: RadioCell,
  [ColumnTypes.SCORE]: RiskScoreCell,
  [ColumnTypes.STRING]: SimpleCell,
  [ColumnTypes.TEXT]: SimpleCell,
  [ColumnTypes.NOTE]: NoteCell,
  [ColumnTypes.SHOW_MORE]: ShowMoreCell,
  [ColumnTypes.POPUP_TABLE]: PopupTableCell,
  [ColumnTypes.CHANGE]: ChangeCell,
  [ColumnTypes.USER]: UserCell,
  [ColumnTypes.SALES_PROCESS]: SalesProcessCell,
  [ColumnTypes.SUBMISSION_SUMMARY]: SubmissionSummaryCell,
};

const TypedCell: React.FC<IDataCellProps> = (props) => {
  const { column } = props;
  const Cell = Cells[column.type] || Cells[ColumnTypes.TEXT];

  return <Cell {...props} />;
};

export default TypedCell;
