import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { StringParam, useQueryParam } from 'use-query-params';

import * as metricActions from 'actions/revbi/metrics';
import developerPlaceholder from 'assets/images/developer_placeholder.png';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import { MetricAnalysisTypes } from 'components/dashboard/Metrics/Create/AnalysisTypes/MetricAnalysisTypes/MetricAnalysisTypes';
import { Header } from 'components/dashboard/Metrics/Create/Header';
import { getMetricType } from 'components/dashboard/Metrics/Create/MetricCreate/utils';
import { MetricDefinitionInputs } from 'components/dashboard/Metrics/Create/MetricDefinition/MetricDefinitionInputs';
import { CreationType } from 'components/dashboard/Metrics/Create/constants';
import {
  FlexColumn,
  MetricOptionSection,
  MetricOptionsColumn,
  MetricPreview,
  MetricPreviewColumn,
  MetricPreviewPlaceholder,
  PreviewNoteParagraph,
  SectionTitle,
} from 'components/dashboard/Metrics/Create/metrics.create.styles';
import { DefinitionsTitle } from 'components/dashboard/Metrics/Create/widget.create.styles';
import { HistoricalWidget } from 'components/dashboard/Metrics/Widget/HistoricalWidget';
import { MetricsWidget } from 'components/dashboard/Metrics/Widget/MetricsWidget';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { UserSettingsProvider } from 'components/dashboard/Metrics/contexts/UserSettingsContext';
import { UsersByActivityProvider } from 'components/dashboard/Metrics/contexts/UsersByActivityContext';
import { parseSaveRedirect } from 'components/dashboard/Metrics/metrics.helpers';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIWidget,
  ComponentMode,
  BIMetricSimple,
  BIMetricFormula,
  BIMetricSimpleNewborn,
  BIMetricFormulaNewborn,
  MetricType,
} from 'components/dashboard/Metrics/metrics.types';
import * as metricSelectors from 'selectors/revbi/metrics';

export const MetricsCreate: React.FC = () => {
  const dispatch = useDispatch();

  // selectors
  const activeMetric = useSelector(metricSelectors.getActiveMetric);
  const isReadyForSave = useSelector(
    metricSelectors.isActiveMetricReadyForSave
  );
  const componentMode = useSelector(
    metricSelectors.getCreateMetricComponentMode
  );
  const fetchStatus = useSelector(metricSelectors.getMetricFetchStatus);
  const isActiveMetricTouched = useSelector(
    metricSelectors.isActiveMetricTouched
  );
  const isMetricReadyForPreview = useSelector(
    metricSelectors.isActiveMetricReadyForPreview
  );
  const isMetricHasValidFilters = useSelector(
    metricSelectors.isActiveMetricHasValidFilters
  );

  const match = useRouteMatch<{ metricId: string }>();
  const [saveRedirect] = useQueryParam('saveRedirect', StringParam);

  const [previewWidget, setPreviewWidget] = useState<Partial<BIWidget>>({
    name: '',
    chart_type: 'column',
    metric_list: [],
    template_filters: [],
    widget_filters: [],
    order_by: [],
    group_by: [],
  });

  const [isSaveAndCloneDialogOpen, setIsSaveAndCloneDialogOpen] =
    useState<boolean>(false);
  const [isDelConfirmationOpen, setIsDelConfirmationOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (match.params.metricId) {
      dispatch(metricActions.fetchMetricById(match.params.metricId, true));
    }
  }, [match.params.metricId]);

  useEffect(() => {
    setPreviewWidget({
      ...previewWidget,
      analysis_type: activeMetric.analysis_type,
    });
  }, [activeMetric.analysis_type]);

  useEffect(() => {
    setPreviewWidget((prev) => ({
      ...prev,
      metric_list: [activeMetric],
    }));
  }, [activeMetric]);

  useEffect(() => {
    return () => {
      dispatch(metricActions.cleanUpMetricCreationState());
    };
  }, []);

  const isWidgetPreviewAvailable = useMemo((): boolean => {
    if (activeMetric !== null) {
      const metricType: MetricType = getMetricType(activeMetric);
      if (metricType === MetricType.Simple) {
        return isMetricReadyForPreview;
      }
      if (metricType === MetricType.Formula) {
        return isMetricReadyForPreview;
      }
      return false;
    }
    return false;
  }, [isMetricReadyForPreview, JSON.stringify(activeMetric)]);

  const isAnalysisTypeDisabled = useMemo(() => {
    if (componentMode === ComponentMode.CREATE) {
      if (activeMetric.analysis_type === AnalysisType.HISTORICAL) {
        return true;
      }
      return false;
    }
    return Boolean(activeMetric.analysis_type);
  }, [activeMetric.analysis_type, componentMode]);

  const handleChangeAnalysisType = (type: AnalysisType): void => {
    if (activeMetric !== null) {
      dispatch(
        metricActions.changeActiveMetric({
          ...activeMetric,
          analysis_type: type,
          object:
            type === AnalysisType.HISTORICAL
              ? 'opportunity'
              : activeMetric.object,
        })
      );
      setPreviewWidget({ ...previewWidget, analysis_type: type });
    }
  };

  const handleNameChange = (name: string): void => {
    if (activeMetric !== null) {
      dispatch(metricActions.changeActiveMetric({ ...activeMetric, name }));
    }
  };

  const handleDelete = (): void => {
    setIsDelConfirmationOpen(true);
  };

  const handleClone = (): void => {
    if (isActiveMetricTouched) {
      setIsSaveAndCloneDialogOpen(true);
    } else {
      dispatch(
        metricActions.cloneMetric({
          id: (activeMetric as BIMetricFormula | BIMetricSimple)._id,
          redirectToTheNewMetric: true,
          toast: true,
        })
      );
    }
  };

  const handleSave = (): void => {
    let redirect = '/revbi/metrics/list';
    if (saveRedirect) {
      redirect = parseSaveRedirect(saveRedirect);
    }
    if (componentMode === ComponentMode.CREATE && isReadyForSave) {
      dispatch(
        metricActions.createMetricExtended({
          metric: activeMetric as
            | BIMetricSimpleNewborn
            | BIMetricFormulaNewborn,
          redirect,
          toast: true,
        })
      );
    }
    if (componentMode === ComponentMode.EDIT && isReadyForSave) {
      dispatch(
        metricActions.updateMetricExtended({
          metric: activeMetric as BIMetricSimple | BIMetricFormula,
          redirect,
          toast: true,
        })
      );
    }
  };

  const handleSaveAndCloneConfirm = (): void => {
    if (componentMode === ComponentMode.EDIT && isReadyForSave) {
      const metricForUpdating = activeMetric as
        | BIMetricFormula
        | BIMetricSimple;
      dispatch(
        metricActions.updateMetricById(metricForUpdating._id, metricForUpdating)
      );
      dispatch(
        metricActions.cloneMetric({
          id: (activeMetric as BIMetricFormula | BIMetricSimple)._id,
          redirectToTheNewMetric: true,
          toast: true,
        })
      );
    }
  };

  const handleDeleteConfirm = (): void => {
    dispatch(
      metricActions.deleteMetric({
        metric: activeMetric as BIMetricFormula | BIMetricSimple,
        toast: true,
        redirect: '/revbi/metrics/list',
      })
    );
  };

  if (activeMetric === null || fetchStatus === 'loading') {
    return <Loader active content="Loading" />;
  }

  return (
    <UsersByActivityProvider includeDisabledUsers={false}>
      <UserSettingsProvider>
        <FlexColumn>
          <Header
            id={
              componentMode === ComponentMode.EDIT
                ? (activeMetric as BIMetricSimple)._id
                : undefined
            }
            name={activeMetric.name}
            type={CreationType.METRIC}
            isSaveDisabled={!isReadyForSave}
            onNameChange={handleNameChange}
            onClone={handleClone}
            onDelete={handleDelete}
            onSave={handleSave}
          />

          <FlexRow cssProps={{ height: '100%' }} data-testing="metric-section">
            <MetricOptionsColumn>
              <MetricOptionSection expanded first>
                <MetricAnalysisTypes
                  selectedAnalysisType={
                    activeMetric.analysis_type || AnalysisType.LIVE
                  }
                  onChangeAnalysisType={handleChangeAnalysisType}
                  disabled={isAnalysisTypeDisabled}
                />
                <DefinitionsTitle>Metric Definition</DefinitionsTitle>
                <MetricDefinitionInputs />
              </MetricOptionSection>
            </MetricOptionsColumn>

            <MetricPreviewColumn>
              {!isWidgetPreviewAvailable && (
                <>
                  <MetricPreviewPlaceholder data-testing="widget-preview-section">
                    <img src={developerPlaceholder} width="250px" />
                    <SectionTitle>
                      What metric do you want to create?
                    </SectionTitle>
                    <div>
                      Build your metric and check it out in the widget preview
                    </div>
                  </MetricPreviewPlaceholder>
                </>
              )}

              {isWidgetPreviewAvailable && (
                <MetricPreview data-testing="widget-preview-section">
                  {activeMetric.analysis_type === AnalysisType.LIVE && (
                    <MetricsWidget
                      hasValidFilters={isMetricHasValidFilters}
                      metric={activeMetric}
                      widget={previewWidget}
                      setWidget={setPreviewWidget}
                    />
                  )}

                  {activeMetric.analysis_type === AnalysisType.HISTORICAL && (
                    <HistoricalWidget
                      hasValidFilters={isMetricHasValidFilters}
                      metric={activeMetric}
                      widget={previewWidget}
                      setWidget={setPreviewWidget}
                    />
                  )}
                </MetricPreview>
              )}

              <PreviewNoteParagraph>
                The widget and all it includes (pivot, chart type) is only used
                for previewing your metric and it will not be saved with the
                metric.
              </PreviewNoteParagraph>
            </MetricPreviewColumn>
          </FlexRow>
        </FlexColumn>

        <BuConfirmationPopup
          headerText={'Save changes and clone metric'}
          isOpen={isSaveAndCloneDialogOpen}
          onClose={() => {
            setIsSaveAndCloneDialogOpen(false);
          }}
          onConfirm={() => {
            setIsSaveAndCloneDialogOpen(false);
            handleSaveAndCloneConfirm();
          }}
        >
          There are unsaved changes in the metric. Do you want to save and clone
          the metric?
        </BuConfirmationPopup>

        <BuConfirmationPopup
          cancelText="No"
          confirmText="Yes"
          headerText="Confirmation Required!"
          isOpen={isDelConfirmationOpen}
          onClose={() => {
            setIsDelConfirmationOpen(false);
          }}
          onConfirm={() => {
            setIsDelConfirmationOpen(false);
            handleDeleteConfirm();
          }}
        >
          Are you sure you want to delete this? It will be permanently removed.
        </BuConfirmationPopup>
      </UserSettingsProvider>
    </UsersByActivityProvider>
  );
};
