import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';
import { getLandingPageScheme } from 'common/helpers';
import { apiKeyToScheme } from 'navigation/routes';

const previous = (state = null, action) => {
  switch (action.type) {
    case t.PREVIOUS_ROUTE + t.CHANGE:
      return action.payload.previous;
    default:
      return state;
  }
};

const wentBack = (state = null, action) => {
  switch (action.type) {
    case t.PREVIOUS_ROUTE + t.CHANGE:
      return action.payload.wentBack;
    default:
      return state;
  }
};

const DEFAULT_LANDING = '/opportunities';

const configInitialState = {
  isInitialized: false,
  defaultLanding: DEFAULT_LANDING,
  allSchemes: [],
  hiddenSchemes: [],
};

const checkSchemes = (acc, item) => {
  const scheme = apiKeyToScheme[item.name]
    ? apiKeyToScheme[item.name]
    : apiKeyToScheme['revbi_dashboard'];

  return scheme ? [...acc, scheme] : acc;
};

const checkHiddenSchemes = (shouldHideItem) => (acc, item) => {
  const scheme = apiKeyToScheme[item.name];

  if (scheme && shouldHideItem(item)) {
    return [...acc, scheme];
  }

  return acc;
};

const config = (state = configInitialState, action) => {
  switch (action.type) {
    case t.ROUTER_CONFIG + t.GET + t.SUCCESS: {
      const { data, read_only } = action.payload;
      const { items, default_landing } = data;
      const shouldHideItem = (item) => {
        if (item.enabled === false) {
          return true;
        }

        return (
          read_only &&
          (item.name === 'settings' ||
            item.name === 'targets' ||
            item.name.indexOf('targets__') > -1)
        );
      };
      const hiddenSchemeReducer = checkHiddenSchemes(shouldHideItem);

      return {
        ...state,
        isInitialized: true,
        defaultLanding:
          getLandingPageScheme(default_landing) || DEFAULT_LANDING,
        navigation: items,
        allSchemes: items.reduce(
          (acc, item) =>
            item.sub_nav
              ? item.sub_nav.reduce(checkSchemes, acc)
              : checkSchemes(acc, item),
          []
        ),
        hiddenSchemes: items.reduce((acc, item) => {
          return item.sub_nav
            ? item.sub_nav.reduce(hiddenSchemeReducer, acc)
            : hiddenSchemeReducer(acc, item);
        }, []),
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  config,
  previous,
  wentBack,
});
