import * as R from 'ramda';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

import filterData from 'common/filters';
import { getUsersForFilter } from 'selectors/openFilters';

const getAllFilters = (state) => ({});

// utils
export const filtersForFilterPillsFactory = (filterValuesSelector) =>
  createSelector([getAllFilters, filterValuesSelector], (definition, filters) =>
    R.compose(
      R.values(),
      R.mapObjIndexed((values, key) => ({
        key,
        values: R.compose(
          R.map((value) => ({
            value,
            label: R.pathOr(value, [key, value, 'name'])(definition),
          })),
          R.without(['__all__'])
        )(values),
        name: R.compose(
          R.propOr(
            R.compose(
              R.replace(/\b./g, R.toUpper),
              R.replace(/_/g, R.always(' '))
            )(key),
            'name'
          ),
          R.find(R.propEq('key', key)),
          R.values
        )(filterData),
      }))
    )(filters)
  );

export const getOptionsForFilter = createCachedSelector(
  [R.nthArg(1), getAllFilters],
  R.compose(
    R.values,
    R.mapObjIndexed((user, email) => ({ label: user.name, value: email })),
    R.omit('__all__'),
    R.prop
  )
)(R.nthArg(1));

export const getFlatFilterUsers = (state) => {
  let result = [];
  let toExplore = R.clone(getUsersForFilter(state));

  while (toExplore.length > 0) {
    const user = toExplore.pop();
    result = result.concat(user);
    if (user.team) {
      toExplore = toExplore.concat(user.team);
    }
  }
  return result;
};

export const prepareFilterData = (filter, options) =>
  R.map((option) => ({
    ...option,
    checked: !R.isNil(filter) && R.contains(option.value, filter),
  }))(options);
