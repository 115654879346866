import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dimmer, Dropdown, Loader } from 'semantic-ui-react';

import {
  clearBusinessTypesConfig,
  setBusinessTypesSettings,
} from 'actions/settingsActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import BuRadio from 'components/UI/BuRadio';
import BuSelect from 'components/UI/BuSelect';
import { getNewBusinessType } from 'components/modals/BusinessTypeModal/BusinessTypeDetails/helpers';
import * as s from 'components/modals/BusinessTypeModal/BusinessTypeDetails/styles';
import { getOptions } from 'components/settings/DefineBusinessType/helpers';
import {
  IBusinessType,
  IBusinessTypesSettings,
} from 'components/settings/DefineBusinessType/types';
import * as selectors from 'selectors';

const BusinessTypeDetails = ({
  isEditMode = false,
  businessTypeForEditName,
  onClose = () => {},
}: {
  isEditMode?: boolean;
  businessTypeForEditName?: string;
  onClose?(): void;
}) => {
  const dispatch = useDispatch();

  const ALL = 'ALL';

  const businessTypesSettings = useSelector(
    selectors.getBusinessTypesSettings
  ) as IBusinessTypesSettings;
  const isLoading = useSelector(selectors.getBusinessTypesLoading) as boolean;

  const businessTypesNames = useMemo(
    () =>
      businessTypesSettings?.businessTypes
        ? Object.values(businessTypesSettings.businessTypes).map(
            (bt) => bt.name
          )
        : [],
    [JSON.stringify(businessTypesSettings)]
  );
  const initialBusinessType: IBusinessType = useMemo(
    () =>
      isEditMode && businessTypeForEditName
        ? businessTypesSettings.businessTypes?.[businessTypeForEditName]
        : getNewBusinessType(businessTypesSettings),
    [isEditMode, businessTypeForEditName, businessTypesSettings]
  );

  const [businessType, setBusinessType] =
    useState<IBusinessType>(initialBusinessType);
  const [isBtEnabled, setBtEnabled] = useState<boolean>(
    initialBusinessType.enabled
  );
  const [isValid, setValid] = useState<boolean>(isEditMode);
  const [isTouched, setTouched] = useState<boolean>(false);
  const [isValidName, setValidName] = useState<boolean>(true);

  useEffect(() => {
    setBusinessType(
      businessTypeForEditName
        ? businessTypesSettings.businessTypes?.[businessTypeForEditName]
        : initialBusinessType
    );
  }, [JSON.stringify(businessTypesSettings)]);

  const validate = (businessType: IBusinessType): void => {
    const hasEmptyField = Object.values(businessType).some(
      (x) => x === null || x === '' || (x instanceof Array && !x.length)
    );
    setValid(!hasEmptyField);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newName = e.target.value;
    const newBusinessType = {
      ...businessType,
      name: newName,
    };
    setBusinessType(newBusinessType);
    setTouched(true);

    const isDuplicate = businessTypesNames.includes(newName);
    setValidName(!isDuplicate);
    isDuplicate ? setValid(false) : validate(newBusinessType);
  };

  const handleMultiDropdownValueChange = (
    values: string[],
    field: keyof IBusinessType
  ): void => {
    const currentValues = (businessType[field] as string[]) || [ALL];
    const difference = values.filter((x) => !currentValues.includes(x));
    const hasAll = difference.includes(ALL);

    const newBusinessType = {
      ...businessType,
      [field]: hasAll ? difference : values.filter((x) => x !== ALL),
    };

    setBusinessType(newBusinessType);
    setTouched(true);
    validate(newBusinessType);
  };

  const handleChangeDropdownValue = (values: string[], field: string): void => {
    const newBusinessType = {
      ...businessType,
      [field]: values[0],
    };
    setBusinessType(newBusinessType);
    setTouched(true);
    validate(newBusinessType);
  };

  const handleCancel = (): void => {
    onClose();
  };

  const handleSave = (): void => {
    const newBusinessType = { ...businessType, enabled: isBtEnabled };
    dispatch(setBusinessTypesSettings(newBusinessType));
    dispatch(clearBusinessTypesConfig());
    onClose();
  };

  const handleEnableBusinessType = (): void => {
    setBtEnabled(!isBtEnabled);
    setTouched(true);
  };

  return (
    <div className={s.container}>
      <div className={s.header}>
        {isEditMode ? 'Edit' : 'Add'} Business Type
      </div>
      <div className={s.mainContent}>
        <Dimmer.Dimmable dimmed={isLoading}>
          <div className={s.detailsContainer}>
            <div className="item">
              <div className="lab">Primary CRM Field:</div>
              <div className="control">
                {businessTypesSettings.primaryField}
              </div>
            </div>
            <div className="item">
              <div className="lab">Name:</div>
              <div className="control">
                <BuInput
                  className={classNames(
                    s.textInput,
                    !isValidName && s.inputError
                  )}
                  placeholder="Set Name"
                  type="text"
                  value={(businessType?.name as string) || ''}
                  onChange={handleNameChange}
                />
                {!isValidName && (
                  <span className={s.errorInfo}>
                    <BuIcon name={BoostUpIcons.DangerCircle} /> Name already
                    exists!
                  </span>
                )}
              </div>
            </div>
            <div className="item">
              <div className="lab lab-multipicker">Values:</div>
              <div className="control">
                <Dropdown
                  fluid
                  multiple
                  placeholder="Set Values"
                  selection
                  options={getOptions(
                    businessTypesSettings?.availableValues?.values
                  )}
                  value={businessType?.values || []}
                  onChange={(_, { value }) => {
                    handleMultiDropdownValueChange(value as string[], 'values');
                  }}
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">Amount Field:</div>
              <div className="control">
                <BuSelect
                  placeholder="Set Amount"
                  className={s.fullWidth}
                  defaults={[businessType?.amount_field || '']}
                  options={getOptions(
                    businessTypesSettings?.availableValues?.amount_field
                  )}
                  onChange={(values: string[]) => {
                    handleChangeDropdownValue(values, 'amount_field');
                  }}
                  secondary
                  fullWidth
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">Close Date Field:</div>
              <div className="control">
                <BuSelect
                  placeholder="Set Close Date"
                  className={s.fullWidth}
                  defaults={[businessType?.close_date_field || '']}
                  options={getOptions(
                    businessTypesSettings?.availableValues?.close_date_field
                  )}
                  onChange={(values: string[]) => {
                    handleChangeDropdownValue(values, 'close_date_field');
                  }}
                  secondary
                  fullWidth
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">Deal Owner Field:</div>
              <div className="control">
                <BuSelect
                  placeholder="Set Deal Owner"
                  className={s.fullWidth}
                  defaults={[
                    businessType?.owner_field ||
                      businessTypesSettings?.availableValues
                        ?.owner_field?.[0] ||
                      '',
                  ]}
                  options={getOptions(
                    businessTypesSettings?.availableValues?.owner_field
                  )}
                  onChange={(values: string[]) => {
                    handleChangeDropdownValue(values, 'owner_field');
                  }}
                  secondary
                  fullWidth
                />
              </div>
            </div>
            <div className="item">
              <div className="lab-long lab-multipicker">
                Show these BoostUp Roles in Hierarchy:
              </div>
              <div className="control">
                <Dropdown
                  fluid
                  multiple
                  placeholder="Set Roles"
                  selection
                  options={getOptions(
                    businessTypesSettings?.availableValues?.user_roles
                  )}
                  value={
                    businessType?.user_roles || [
                      businessTypesSettings?.availableValues?.user_roles?.[0],
                    ] ||
                    []
                  }
                  onChange={(_, { value }) => {
                    handleMultiDropdownValueChange(
                      value as string[],
                      'user_roles'
                    );
                  }}
                />
              </div>
            </div>
            <div className="item">
              <div className="lab">Forecast Category Field:</div>
              <div className="control">
                <BuSelect
                  placeholder="Set Forecast Category"
                  className={s.fullWidth}
                  defaults={[
                    businessType?.forecast_category_field ||
                      businessTypesSettings?.availableValues
                        ?.forecast_category_field?.[0] ||
                      '',
                  ]}
                  options={getOptions(
                    businessTypesSettings?.availableValues
                      ?.forecast_category_field
                  )}
                  onChange={(values: string[]) => {
                    handleChangeDropdownValue(
                      values,
                      'forecast_category_field'
                    );
                  }}
                  secondary
                  fullWidth
                />
              </div>
            </div>
            {!isEditMode && (
              <div className="item">
                <div className="lab">User profile visibility:</div>
                <div className="control">
                  <div className="info">
                    Note: This will be added on all User profiles automatically.
                    You can only change the visibility of it. If you want to
                    keep it enabled for few profiles and disabled it for others
                    then chose Disabled and change it by visiting User Profile
                    settings.
                  </div>
                  <div className="sub-header">
                    How do you want to keep it by default for all User profiles?
                  </div>
                  <div className="radio-container">
                    <BuRadio
                      value="enabled"
                      label="Enable"
                      checked={isBtEnabled}
                      onChange={handleEnableBusinessType}
                    />
                    <div className="label-title">
                      (Users will have it available immediately)
                    </div>
                    <BuRadio
                      value="disabled"
                      label="Disable"
                      checked={!isBtEnabled}
                      onChange={handleEnableBusinessType}
                    />
                    <div className="label-title">
                      (Users will not see it until you enable it)
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <Dimmer active={isLoading} inverted>
            <Loader />
          </Dimmer>
        </Dimmer.Dimmable>
      </div>

      <div className={s.actionButtons}>
        <BuButton
          className={s.wideButtons}
          secondary
          size={BuControlSize.BIG}
          onClick={handleCancel}
        >
          Cancel
        </BuButton>
        <BuButton
          className={s.wideButtons}
          size={BuControlSize.BIG}
          disabled={!isTouched || !isValid || !isValidName}
          onClick={handleSave}
        >
          Save
        </BuButton>
      </div>
    </div>
  );
};

export default BusinessTypeDetails;
