import styled from '@emotion/styled/macro';
import { css } from 'emotion';
import React, { useEffect, useMemo, useState } from 'react';

import BuButton from 'components/UI/BuButton';
import BuDropdown, {
  BuDropdownItemContainer,
  BuDropdownItemGroup,
  BuDropdownNonClickableItem,
  BuDropdownSearchItem,
} from 'components/UI/BuDropdown';
import BuRadio from 'components/UI/BuRadio';
import Scrolling from 'components/UI/common/Scrolling/Scrolling';
import { AvailableFieldEntry } from 'components/settings/ManageTableColumns/ManageTableColumns';

enum Direction {
  Descending = -1,
  Ascending = 1,
}

const Label = styled.span`
  margin-right: 5px;
`;

const DropdownItem = styled.div`
  padding: 10px 15px 5px;
  background-color: transparent;
`;

const DropdownLabelItem = styled.div`
  padding: 5px 15px;
  background-color: transparent;
  color: var(--bu-gray-700);

  text-transform: uppercase;
`;

const minWidth = css`
  min-width: 300px;
`;

type Props = {
  columns: AvailableFieldEntry[];
  selected: string;
  onChange(newSortOrder: string): void;
};

const ColumnOrderDropdown: React.FC<Props> = ({
  columns,
  onChange,
  selected,
}) => {
  const [selectedColumn, setSelectedColumn] = useState<string>('');
  const [direction, setDirection] = useState<Direction>(Direction.Ascending);
  const [userDropdownFilter, setUserDropdownFilter] = useState('');

  const resetChanges = () => {
    setSelectedColumn(selected[0] === '-' ? selected.slice(1) : selected);
    setDirection(
      selected[0] === '-' ? Direction.Descending : Direction.Ascending
    );
  };

  useEffect(() => {
    resetChanges();
  }, [selected]);

  const dropdownLabel = useMemo(() => {
    const columnField = selected[0] === '-' ? selected.slice(1) : selected;
    const direction =
      selected[0] === '-' ? Direction.Descending : Direction.Ascending;

    const column = columns.find((item) => item.object_field === columnField);

    return column
      ? `${column?.display_name} (${
          direction === Direction.Descending ? 'Descending' : 'Ascending'
        })`
      : 'Select Column';
  }, [selected, direction, columns]);

  const handleApply = () =>
    onChange(
      `${direction === Direction.Descending ? '-' : ''}${selectedColumn}`
    );

  return (
    <>
      <Label>Sort this table by</Label>
      <BuDropdown
        label={dropdownLabel}
        secondary
        placement="bottom-end"
        className={minWidth}
        onClose={resetChanges}
      >
        {(hide) => (
          <BuDropdownItemContainer>
            <DropdownLabelItem>Select Sorting</DropdownLabelItem>

            <BuDropdownItemGroup>
              <DropdownItem>
                <BuRadio
                  onChange={(e, { value }) => setDirection(value as Direction)}
                  value={Direction.Ascending}
                  checked={direction !== Direction.Descending}
                  label="Ascending"
                />
              </DropdownItem>
              <DropdownItem>
                <BuRadio
                  onChange={(e, { value }) => setDirection(value as Direction)}
                  value={Direction.Descending}
                  checked={direction === Direction.Descending}
                  label="Descending"
                />
              </DropdownItem>
            </BuDropdownItemGroup>

            <DropdownLabelItem>Select Column Name</DropdownLabelItem>

            <BuDropdownItemGroup>
              <Scrolling
                alwaysShow
                shadows
                maxHeight={400}
                height
                shadowTopOffset={32}
              >
                <BuDropdownSearchItem
                  value={userDropdownFilter}
                  onChange={(e) => setUserDropdownFilter(e.target.value)}
                  data-testing="txt_field"
                />
                {columns
                  .filter((item) =>
                    item.display_name
                      .toLocaleLowerCase()
                      .includes(userDropdownFilter.toLocaleLowerCase())
                  )
                  .map((item) => (
                    <DropdownItem key={item.object_field}>
                      <BuRadio
                        onChange={(e, { value }) =>
                          setSelectedColumn(value as string)
                        }
                        value={item.object_field}
                        checked={item.object_field === selectedColumn}
                        label={item.display_name}
                      />
                    </DropdownItem>
                  ))}
              </Scrolling>
            </BuDropdownItemGroup>

            <BuDropdownNonClickableItem className="no-hover">
              <BuButton
                onClick={() => {
                  hide();
                  handleApply();
                }}
              >
                Apply
              </BuButton>
              <BuButton secondary onClick={hide}>
                Cancel
              </BuButton>
            </BuDropdownNonClickableItem>
          </BuDropdownItemContainer>
        )}
      </BuDropdown>
    </>
  );
};

export default ColumnOrderDropdown;
