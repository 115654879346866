import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import * as dealsActions from 'actions/dealsActions';
import ForecastAnalytics from 'components/dashboard/ForecastAnalytics/ForecastAnalytics';
import { StateProps } from 'components/dashboard/ForecastAnalytics/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const stateToProps = (
  state: IReduxState,
  ownProps: RouteComponentProps
): StateProps => ({
  selectedBusinessType: selectors.getSelectedBusinessType(
    state,
    'forecast_analytics_change'
  ),
  filters: selectors.getFiltersForAPI(state, 'forecast_analytics_change'),
  isBusinessTypeEnabled:
    selectors.getCompanySettings(state).trends_by_business_type_enabled,
  tabPartition:
    ownProps.location.pathname === '/dashboard/trends'
      ? 'dashboards'
      : 'forecast',
  savedPrevValuesDate: selectors.getForecastAllChangesDate(
    'forecast_analytics_change'
  )(state),
  isRequiredDataReady: selectors.isBusinessTypesConfigReady(state),
});

const dispatchToProps = {
  toggleAllFilters: dealsActions.toggleAllFilters,
};

export default connect(stateToProps, dispatchToProps)(ForecastAnalytics);
