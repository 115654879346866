import classNames from 'classnames';
import { css } from 'emotion';
import React, { useEffect, useState } from 'react';
import CheckboxTree, { CheckboxProps } from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { Grid, Icon } from 'semantic-ui-react';

import { no_result_box } from 'components/UI/OpenFiltersPanel/styles';
import SearchInput from 'components/UI/SearchInput';
import RadioGroup from 'components/UI/common/Filter/RadioGroup';
import {
  addonsForOpenFilters,
  checkBoxStyles,
  panelCheckBoxTree,
  panelDividerColor,
  panelItemDropdown,
} from 'components/UI/common/Filter/styles';
import {
  CheckboxTreeNode,
  ICheckboxItemNode,
  IFilterPanelItemProps,
  IFilterPanelProps,
  IPanelFilterItem,
  IPanelItem,
  ItemValueType,
} from 'components/UI/common/Filter/types';

const colorArrow = css`
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px !important;
  padding-top: 3px;
`;

const MAX_PANELS_IN_ROW = 5;
const MAX_ITEMS_IN_PANEL_TO_SHOW = 10;

const CheckBox = ({ className }: React.HTMLAttributes<HTMLSpanElement>) => (
  <span className={classNames(checkBoxStyles, className)} />
);

export const FilterPanelItem = ({
  panel,
  checked,
  onChange,
}: IFilterPanelItemProps) => {
  const [expandedList, setExpandedList] = useState<string[]>([]);
  const [nodes, setNodes] = useState<CheckboxTreeNode[]>([]);
  const [search, setSearch] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value);

  const childrenLength = panel.children.filter((item) => !item.special).length;
  const specialLength = panel.children.length - childrenLength;

  const inString = (src: string, searchString: string) =>
    src && src.toLowerCase().indexOf(searchString) !== -1;

  function findNodes<T extends IPanelFilterItem>(
    nodes: T[],
    searchString: string
  ): T[] {
    return nodes.reduce((acc, node) => {
      const isMatched =
        inString(`${node.label}`, searchString) ||
        inString(node.value, searchString);

      if (Array.isArray(node.children)) {
        return acc
          .concat(isMatched ? node : [])
          .concat(findNodes(node.children as T[], searchString));
      } else if (isMatched) {
        return acc.concat(node);
      }
      return acc;
    }, [] as T[]);
  }

  useEffect(() => {
    if (search) {
      setNodes([
        ...panel.children.filter((item) => item.special),
        ...findNodes(
          panel.children.filter((item) => !item.special),
          search.toLowerCase()
        ),
      ]);
    } else {
      setNodes(panel.children.slice(0, childrenLength + specialLength));
    }
  }, [panel, search]);

  const handleRestedSearch = () => setSearch('');

  const handleCheck = (
    newCheckedList: string[],
    targetNode: IPanelFilterItem
  ) => {
    onChange(newCheckedList, targetNode);
  };

  const isRadioGroup = ['created_at'].includes(panel.value);

  return (
    <div
      className={classNames(
        'filter-item-wrapper',
        panelItemDropdown,
        addonsForOpenFilters
      )}
    >
      {/* <Header className={panelItemHeader}>{panel.label}</Header> */}

      {(panel.search === true ||
        (panel.search === 'auto' &&
          childrenLength > MAX_ITEMS_IN_PANEL_TO_SHOW)) && (
        <SearchInput
          name="search"
          value={search}
          onChange={handleSearchChange}
        />
      )}

      {isRadioGroup ? (
        <RadioGroup checked={checked} nodes={nodes} onCheck={handleCheck} />
      ) : (
        <div className={panelCheckBoxTree}>
          <CheckboxTree
            icons={{
              check: <CheckBox className="check" />,
              uncheck: <CheckBox className="uncheck" />,
              halfCheck: <CheckBox className="half-check" />,
              expandClose: <Icon className={colorArrow} name="angle down" />,
              expandOpen: <Icon className={colorArrow} name="angle up" />,
            }}
            nodes={nodes}
            showNodeIcon={false}
            expanded={expandedList}
            onExpand={(items: string[]) => setExpandedList(items)}
            checked={checked}
            onCheck={handleCheck as CheckboxProps['onCheck']}
          />
        </div>
      )}

      {search &&
        nodes.filter((item) => !(item as ICheckboxItemNode).special).length ===
          0 && (
          <div className={no_result_box}>
            <p>No results found.</p>
            <span onClick={handleRestedSearch}>reset search</span>
          </div>
        )}
    </div>
  );
};

const FilterPanel = ({
  panels,
  panelsChecked,
  onChange = () => {},
}: IFilterPanelProps) => {
  const handlePanelItemChange = (panel: IPanelItem) => (
    newCheckedItems: ItemValueType[],
    targetNode: IPanelFilterItem
  ) => {
    onChange(
      {
        ...panelsChecked,
        [panel.value]: newCheckedItems,
      },
      panel,
      targetNode
    );
  };

  return (
    <Grid divided columns={MAX_PANELS_IN_ROW} className={panelDividerColor}>
      {panels.map((panel) => {
        if (panel.hidden) {
          return null;
        }

        return (
          <Grid.Column key={`filter-panel-${panel.value}`}>
            <FilterPanelItem
              panel={panel}
              checked={panelsChecked[panel.value]}
              onChange={handlePanelItemChange(panel)}
            />
          </Grid.Column>
        );
      })}
      {panels.length < MAX_PANELS_IN_ROW && <Grid.Column />}
    </Grid>
  );
};

export default FilterPanel;
