import axios from 'axios';
import { toast } from 'react-toastify';

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const EMAIL_AVAILABILITY_API = '/api/settings/company/users/email/available';
const EMAIL_AVAILABILITY_ERROR_MSG = 'Email availability check failed!';

export const regexValidateEmail = (email: string): boolean =>
  EMAIL_REGEX.test(String(email).toLowerCase());

type AvailabilityResponse = {
  data: {
    data: {
      available: boolean | null;
    };
  };
};

export const checkEmailAvailability = async (
  email: string
): Promise<boolean | null> => {
  if (!email) return false;

  return await axios
    .get(EMAIL_AVAILABILITY_API, {
      params: { email },
    })
    .then((response: AvailabilityResponse) => {
      const {
        data: {
          data: { available },
        },
      } = response;

      return available;
    })
    .catch(() => {
      toast.error(EMAIL_AVAILABILITY_ERROR_MSG, {
        position: 'bottom-left',
      });

      return null;
    });
};

export const splitEmail = (email: string): string[] => {
  if (!email) return [];

  const splitArr = email.split('@');

  return splitArr.length === 2
    ? splitArr
    : [splitArr.slice(0, -1).join('@'), splitArr.slice(-1)[0]];
};

export const getDomainByEmail = (email: string): string => {
  return email.substring(email.indexOf('@') + 1, email.length);
};
