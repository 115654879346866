import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import Table from 'components/UI/TableConfig/Table';
import { AdditionalMetrics } from 'components/dashboard/Metrics/Widget/FunnelWidget/AdditionalMetrics/AdditionalMetrics';
import { FunnelChart } from 'components/dashboard/Metrics/Widget/FunnelWidget/FunnelChart';
import { FunnelControls } from 'components/dashboard/Metrics/Widget/FunnelWidget/FunnelControls';
import { getTableOptions } from 'components/dashboard/Metrics/Widget/FunnelWidget/helpers.table';
import {
  MetricsBlock,
  WidgetTopBar,
  tableHeaderCellBorders,
} from 'components/dashboard/Metrics/Widget/FunnelWidget/styles';
import { WidgetHeader } from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { TemplateFilters } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilters';
import {
  LoaderContainer,
  WidgetContainer,
} from 'components/dashboard/Metrics/Widget/widgets.styles';
import { UserSettingsContext } from 'components/dashboard/Metrics/contexts/UserSettingsContext';
import {
  BIDashboardSettings,
  BIWidget,
  BIWidgetDataV2,
} from 'components/dashboard/Metrics/metrics.types';
import { fetchApi, QueryStatus } from 'utils/network';

interface Props {
  widget: Partial<BIWidget>;
  dashboardName?: string;
  isDashboardWidget?: boolean;
  isDashboardModal?: boolean;
  dashboardSettings?: BIDashboardSettings;
  setWidget: Dispatch<SetStateAction<Partial<BIWidget>>>;
  updateWidget?: (widget: Partial<BIWidget>) => void;
  onEditWidget?: (metricId: string) => void;
  onCloneWidget?: (metricId: string) => void;
  onRemoveWidget?: (metricId: string) => void;
  updateWidgetInList?: (value: BIWidget) => void;
  onNameClicked?: (widget: Partial<BIWidget>) => void;
  closeWidgetModal?: () => void;
}

export const FunnelWidget: React.FC<Props> = ({
  widget,
  dashboardName = '',
  isDashboardWidget = false,
  isDashboardModal = false,
  setWidget,
  updateWidget,
  onEditWidget,
  onCloneWidget,
  onRemoveWidget,
  onNameClicked,
  closeWidgetModal,
}) => {
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');
  const [funnelData, setFunnelData] = useState<BIWidgetDataV2>();

  const { currency: companyCurrencyCode } = useContext(UserSettingsContext);

  const onTitleClicked = () => {
    onNameClicked && onNameClicked(widget);
  };
  const fetchWidgetData = (
    previewJson: string,
    abortController: AbortController,
    querySetting: string
  ) => {
    fetchApi<string, BIWidgetDataV2>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/v2/widgets/editor/funnel${querySetting}`,
      queryMethod: 'post',
      queryParams: previewJson,
      setData: (result) => {
        setFunnelData(result);
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load metric data ${widget.id}: ${error}`);
      },
      setStatus: setDataStatus,
      signal: abortController.signal,
    });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const queryWidget = { ...widget, metric_list: [] };
    const previewJson = JSON.stringify(queryWidget);
    fetchWidgetData(previewJson, abortController, '');

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [
    JSON.stringify(widget.template_filters),
    JSON.stringify(widget.time_period),
  ]);

  const tableOptions = getTableOptions(
    funnelData?.pivot_1?.columns || [],
    funnelData?.pivot_1?.data || [],
    companyCurrencyCode
  );

  return (
    <>
      <WidgetContainer
        key={widget._id}
        isDashboard={isDashboardWidget}
        isMetricsPreview={false}
        isModal={isDashboardModal}
      >
        <WidgetHeader
          id={widget._id}
          name={widget.name}
          dashboardName={dashboardName}
          isDashboardWidget={isDashboardWidget}
          isDashboardModal={isDashboardModal}
          onEditWidget={onEditWidget}
          onCloneWidget={onCloneWidget}
          onRemoveWidget={onRemoveWidget}
          onTitleClicked={onTitleClicked}
          closeWidgetModal={closeWidgetModal}
        />
        <FunnelControls
          widget={widget}
          showControls={isDashboardModal}
          updateWidget={setWidget}
        />

        <TemplateFilters
          templateFilters={widget.template_filters ?? []}
          widgetFilters={widget.widget_filters ?? []}
          setWidget={setWidget}
          showControls={isDashboardModal || !isDashboardWidget}
        />

        {dataStatus === 'loading' && (
          <LoaderContainer>
            <Loader active content="Loading" />
          </LoaderContainer>
        )}

        {dataStatus === 'success' && (
          <>
            <WidgetTopBar>
              <MetricsBlock>
                {!!funnelData && !!funnelData.additional_metrics && (
                  <AdditionalMetrics
                    additionalMetrics={funnelData.additional_metrics}
                  />
                )}
              </MetricsBlock>
            </WidgetTopBar>

            {widget.chart_type === 'funnel' && funnelData && (
              <FunnelChart data={funnelData} />
            )}
            {widget.chart_type === 'table' && funnelData && (
              <Table
                fullscreen
                hidePaginationEnd
                hidePaginationStart
                hideSearch
                stickyColumn={true}
                columns={tableOptions.columns}
                data={tableOptions.rows}
                rowsPerPage={10}
                currentPage={0}
                className={tableHeaderCellBorders}
              />
            )}
          </>
        )}
      </WidgetContainer>
    </>
  );
};
