import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import $ from 'jquery';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Grid, Loader, Segment } from 'semantic-ui-react';

import { actions } from 'actions';
import * as interfaces from 'common/props-interfaces';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { formatRevenue } from 'components/dashboard/Charts/helpers';
import * as layStyle from 'components/dashboard/styles';
import styles from 'components/deal/styles';
import FunnelModal from 'components/modals/FunnelModal';
import { openModal } from 'navigation/utils';
import * as selectors from 'selectors';

class Funnel extends PureComponent {
  constructor(props) {
    super(props);

    this.getChartOptions = this.getChartOptions.bind(this);
    this.handleChartClick = this.handleChartClick.bind(this);
    this.onClickLabel = this.onClickLabel.bind(this);

    const { data, funnel, statsStatus, filters, tab } = props;

    this.state = {
      chartOptions:
        statsStatus === 'success'
          ? this.getChartOptions({
              data,
              funnel,
              statsStatus,
              filter: filters?.risk_score,
            })
          : null,
      fullScreen: false,
      type: 'count',
    };
  }

  getChartOptions({ data, funnel, filter = [] }) {
    const stagesData = R.map(
      R.pick([
        'opportunity_stage',
        'count_high_risk',
        'count_medium_risk',
        'count_low_risk',
        'count_high_risk',
        'count_medium_risk',
        'count_low_risk',
        'amount_high_risk',
        'amount_medium_risk',
        'amount_low_risk',
      ])
    )(data);

    const funnelData = R.compose(
      R.reject(R.isNil), // reject steps that have no data
      R.map(
        R.compose(
          R.ifElse(
            // we may have a single stage or various stages per sequence
            R.compose(R.gte(1), R.length),
            R.head, // if only one stage present, use it
            R.converge(
              // otherwise, we have to summarize it's values
              R.merge,
              [
                R.compose(
                  // reduce the on track and at risk values
                  R.reduce(R.mergeWith(R.add), {
                    count_high_risk: 0,
                    count_medium_risk: 0,
                    count_low_risk: 0,
                  }),
                  R.map(R.dissoc('opportunity_stage'))
                ),
                R.compose(
                  // concat the names for the opportunity stages
                  R.objOf('opportunity_stage'),
                  R.pluck('opportunity_stage')
                ),
              ]
            )
          ),
          R.reject(R.isNil), // remove undefined stages
          R.map((stage) =>
            R.find(
              // map each stage name to it's data
              R.propEq('opportunity_stage', stage),
              stagesData
            )
          )
        )
      )
    )(funnel);

    if (!funnelData.length) {
      return null;
    }

    const getLabelByStage = (stage) => {
      const stageData = R.find(
        R.propEq('opportunity_stage', stage),
        funnelData
      );
      const addBreakChars = (str) =>
        str
          .split(/([\w (]*?[\W])/)
          .filter((str) => Boolean(str))
          .join('&ZeroWidthSpace;');
      const stageString = stage instanceof Array ? R.join(',')(stage) : stage;
      const stageTitle =
        stage instanceof Array
          ? R.join('<br/>')(stage.map(addBreakChars))
          : addBreakChars(stage);

      return `
        <div class="labelContainer" data-stages="${stageString}">
          <b class="${layStyle.centerBlockTitle}">${stageTitle}</b>
          <div class="${layStyle.centerBlock} red">
            <div class="bu-label-tag">
              <i class="bu-icon bu-icon-risk_dot"></i>
            </div>
            <span>High Risk:</span>
            <span class="count">${R.pathOr(
              0,
              ['count_high_risk'],
              stageData
            )}</span>
          </div>
          <div class="${layStyle.centerBlock} yellow">
            <div class="bu-label-tag">
              <i class="bu-icon bu-icon-risk_dot"></i>
            </div>
            <span>Medium Risk:</span>
            <span class="count">${R.pathOr(
              0,
              ['count_medium_risk'],
              stageData
            )}</span>
          </div>
          <div class="${layStyle.centerBlock} green">
            <div class="bu-label-tag">
              <i class="bu-icon bu-icon-risk_dot"></i>
            </div>
            <span>Low Risk:</span>
            <span class="count">${R.pathOr(
              0,
              ['count_low_risk'],
              stageData
            )}</span>
          </div>
        </div>
        `;
    };

    const getSeriesData = (value, count, filterName) => {
      const seriesData = funnelData.map((i) => ({ y: i[count], y1: i[value] }));
      const isIncluded = !!(
        filter.includes(filterName) ||
        seriesData.filter((i) => i.y || i.y1).length
      );

      return isIncluded ? seriesData : [];
    };

    const { handleChartClick } = this;
    const { companyCurrency } = this.props;

    return {
      chart: {
        type: 'streamgraph',
        marginBottom: 30,
        events: {
          render: this.onClickLabel,
        },
        zoomType: 'x',
      },
      legend: { enabled: false },
      plotOptions: {
        series: {
          label: {
            minFontSize: 5,
            maxFontSize: 15,
            style: {
              color: 'rgba(255,255,255,0.75)',
            },
          },
          cursor: 'pointer',
          events: {
            click(e) {
              handleChartClick(e.point.category, this.name);
            },
          },
        },
        streamgraph: {
          trackByArea: true,
        },
      },
      series: [
        {
          key: 'count_high_risk',
          name: 'count_high_risk',
          data: getSeriesData('amount_high_risk', 'count_high_risk', 'high'),
          color: 'var(--bu-red-400)',
        },
        {
          key: 'count_medium_risk',
          name: 'count_medium_risk',
          data: getSeriesData(
            'amount_medium_risk',
            'count_medium_risk',
            'medium'
          ),
          color: 'var(--bu-orange-400)',
        },
        {
          key: 'count_low_risk',
          name: 'count_low_risk',
          data: getSeriesData('amount_low_risk', 'count_low_risk', 'low'),
          color: 'var(--bu-green-400)',
        },
      ],
      title: { text: '' },
      tooltip: {
        formatter() {
          const {
            point: { y, y1 },
            color,
          } = this;

          return `
                <b>${this.x}</b><br/>
                <p><span style="color: ${color}">●</span> ${formatRevenue(
            y1,
            companyCurrency
          )} (${y})</p>
              `;
        },
      },
      xAxis: {
        type: 'category',
        categories: R.map(R.prop('opportunity_stage'), funnelData),
        crosshair: { snap: true },
        gridLineWidth: 1,
        labels: {
          align: 'center',
          formatter() {
            return getLabelByStage(this.value);
          },
          useHTML: true,
          style: { paddingBottom: '40px' },
        },
        lineWidth: 0,
        margin: 20,
        opposite: true,
        tickWidth: 0,
      },
      yAxis: {
        startOnTick: false,
        endOnTick: false,
        visible: false,
      },
    };
  }

  componentDidUpdate(prevProps) {
    const { data, funnel, filters } = this.props;

    if (
      this.state.chartOptions === null ||
      JSON.stringify(data) !== JSON.stringify(prevProps.data) ||
      JSON.stringify(funnel) !== JSON.stringify(prevProps.funnel)
    ) {
      const chartOptions = this.getChartOptions({
        data,
        funnel,
        filter: filters?.risk_score,
      });
      this.setState({ chartOptions });
    }
  }

  handleChartClick(stage, type) {
    const { title, filters, persistModalParams, isModal, tab } = this.props;
    const riskScoreCategory = type.split('_')[1];

    AnalyticsTracker.event(
      { tab },
      {
        category: 'Pipeline Analytics',
        action: 'Tab: Risk Analytics',
        label: 'Opened Deals by Stage',
      }
    );

    openModal({
      scheme: '/deals/:tab',
      params: {
        tab: isModal ? 'accounts-deals' : 'default',
        localStorageKeyPrefix: this.props.localStorageKeyPrefix,
      },
      persistParams: {
        ...filters,
        title,
        opportunity_stages: stage instanceof Array ? stage : [stage],
        risk_score: [riskScoreCategory],
      },
      persistor: persistModalParams,
    });
  }

  onClickLabel() {
    $('.labelContainer').click((e) => {
      const label = e.currentTarget.dataset.stages.split(',');
      const value = e.target.innerText;

      if (value === 'High Risk:') {
        const string = 'count_high_risk';
        this.handleChartClick(label, string);
      } else if (value === 'Medium Risk:') {
        const string = 'count_medium_risk';
        this.handleChartClick(label, string);
      } else if (value === 'Low Risk:') {
        const string = 'count_low_risk';
        this.handleChartClick(label, string);
      }
    });
  }

  render() {
    const { status, title, isModal, extraFilter } = this.props;
    const { chartOptions, type, fullScreen } = this.state;

    if (isModal) {
      return (
        <div className={styles.modal_container}>
          <div className={styles.modal_header}>
            <div className={styles.modal_title}>Pipeline Risk Analytics</div>
            <div>{extraFilter}</div>
          </div>
          <div>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
              immutable
            />
          </div>
        </div>
      );
    }

    if (['loading', 'notAsked'].includes(status)) {
      return (
        <div className="block-loader">
          <Loader active inline="centered" />
        </div>
      );
    }

    return (
      <Segment>
        <Grid>
          <Grid.Row
            centered
            verticalAlign="middle"
            className={layStyle.chartHeader}
          >
            <Grid.Column width={16} textAlign="left">
              <span className={layStyle.chartTitle}># of Deals by Stage</span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1} stretched>
            <Grid.Column stretched>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                immutable
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <FunnelModal
          open={fullScreen}
          type={type}
          title={title}
          onClose={() => this.setState({ fullScreen: false })}
          onChange={(e, { value }) => this.setState({ type: value })}
        />
      </Segment>
    );
  }
}

Funnel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  funnel: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  persistModalParams: PropTypes.func.isRequired,
  status: interfaces.loadingStatus.isRequired,
  title: PropTypes.string.isRequired,
  companyCurrency: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  companyCurrency: selectors.getUserLocalCurrency(state),
});

const dispatchToProps = {
  persistModalParams: actions.ui.modal.persist,
};

export default connect(mapStateToProps, dispatchToProps)(Funnel);
