import { css } from 'emotion';

import { fontCaption, fontSubtitle } from 'assets/css/common';

export const header = css`
  ${fontSubtitle};
  color: var(--bu-gray-900);
  text-transform: uppercase;

  .sub {
    color: var(--bu-gray-700);
    margin-left: 5px;
  }
`;

export const lastUpdate = css`
  ${fontCaption};
  color: var(--bu-gray-700);

  display: flex;
  align-items: center;
  margin-left: 20px;

  img {
    margin-right: 7px;
    width: 16px;
    height: 16px;
  }
`;

export const downloadButton = css`
  padding-top: 5px;
`;

export const totalAmountStyle = css`
  font-family: var(--bu-font-medium);
  color: var(--bu-gray-900);
  margin-left: 10px;
`;
