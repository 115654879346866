import styled from '@emotion/styled';
import { css } from 'emotion';
import moment from 'moment';
import React from 'react';
import { Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import {
  IColumn,
  IDataCellProps,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { MetricInfoContainer } from 'components/dashboard/Metrics/Create/DefinitionsMetricsList/styles';
import { MetricInfo } from 'components/dashboard/Metrics/common/MetricInfo/MetricInfo';
import {
  formatMetricObject,
  getMetricDescription,
} from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIMetrics,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { Link } from 'navigation/Link';

enum MetricStatus {
  published = 'published',
  draft = 'draft',
}

const MetricName = styled.span({
  marginRight: '4px',
});

const GreenStatus = styled.span({
  display: 'inline',
  padding: '2px 4px',
  fontSize: '10px',
  backgroundColor: 'var(--bu-green-500)',
  color: 'var(--bu-primary-500-light)',
  borderRadius: '3px',
});

const WarnStatus = styled.div({
  display: 'inline',
  padding: '2px 4px',
  fontSize: '10px',
  backgroundColor: 'var(--bu-orange-500)',
  color: 'var(--bu-primary-500-light)',
  borderRadius: '3px',
});

const PrimaryCell = css`
  .typed-table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TooltipMetricObject = styled.div({
  color: 'var(--bu-gray-700)',
  paddingTop: '4px',
  fontSize: '12px',
});

const popup = css`
  &.ui.popup {
    max-width: 480px;
  }
`;

const NameCell: React.FC<IDataCellProps> = ({ row }: IDataCellProps) => (
  <>
    <Link
      style={{ color: 'var(--bu-clickable-cell)' }}
      url={{
        scheme: '/revbi/widgets/edit/:widgetId',
        params: { widgetId: (row as any)?._id },
      }}
    >
      <MetricName>{row.name}</MetricName>
    </Link>
    {row?.status === MetricStatus.published && (
      <GreenStatus>Published</GreenStatus>
    )}
    {row?.status === MetricStatus.draft && <WarnStatus>Draft</WarnStatus>}
  </>
);

const MetricNameCell: React.FC<IDataCellProps> = ({
  row,
  column,
}: IDataCellProps) => (
  <>
    <Popup
      offset={[0, 10]}
      content={
        <MetricInfoContainer>
          <TooltipMetricObject>
            {formatMetricObject(row.object as string)}
          </TooltipMetricObject>
          <MetricInfo
            metric={row as BIMetrics}
            metricDescription={getMetricDescription(row as BIMetrics)}
          />
        </MetricInfoContainer>
      }
      key="Details"
      header={row.name}
      trigger={
        <Link
          style={{ color: 'var(--bu-clickable-cell)' }}
          url={{
            scheme:
              column.label === 'widget name'
                ? '/revbi/widgets/edit/:widgetId'
                : '/revbi/metrics/edit/:metricId',
            params:
              column.label === 'widget name'
                ? { widgetId: (row as any)?._id }
                : { metricId: (row as any)?._id },
          }}
        >
          <MetricName>{row.name}</MetricName>
        </Link>
      }
      className={popup}
    />
  </>
);

const MetricDateCell: React.FC<IDataCellProps> = ({ row }: IDataCellProps) => {
  const timestamp = (row as any)?.updated_at;
  return (
    <>{timestamp ? moment(timestamp).format("Do MMM 'YY") : 'Date not found'}</>
  );
};

export const getMetricsListTableColumns = (
  handleEdit: (id: string, name: string) => void,
  handleDelete: (id: string, name: string) => void,
  handleClone: (id: string, name: string) => void
): IColumn[] => {
  const MetricActionCell: React.FC<IDataCellProps> = ({
    row,
  }: IDataCellProps) => {
    const id = (row as BIMetrics)?._id ?? '';
    const name = (row as BIMetrics)?.name;

    return (
      <>
        <BuButton
          icon
          borderless
          secondary
          onClick={() => handleEdit(id, name)}
          size={BuControlSize.SMALL}
          tooltip={'Edit metric'}
          testingLabel="edit-metric-button"
        >
          <BuIcon name={BoostUpIcons.Pencil} />
        </BuButton>
        <BuButton
          icon
          borderless
          secondary
          onClick={() => handleClone(id, name)}
          size={BuControlSize.SMALL}
          tooltip={'Clone metric'}
          testingLabel="clone-metric-button"
        >
          <BuIcon name={BoostUpIcons.Copy} />
        </BuButton>
        <BuButton
          icon
          borderless
          secondary
          onClick={() => handleDelete(id, name)}
          size={BuControlSize.SMALL}
          tooltip={'Delete metric'}
          testingLabel="delete-metric-button"
        >
          <BuIcon name={BoostUpIcons.Trash} />
        </BuButton>
      </>
    );
  };

  return [
    {
      id: 'name',
      label: 'metric name',
      field: 'name',
      minWidth: 400,
      maxWidth: 400,
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 400,
      config: {
        renderer: MetricNameCell,
        className: PrimaryCell,
      } as any,
    },
    {
      id: 'lastUpdated',
      label: 'last updated',
      field: 'updated_at',
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 500,
      config: {
        renderer: MetricDateCell,
      } as any,
    },
    {
      id: 'metricType',
      label: 'Metric Type',
      field: 'synthetic_metric',
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 500,
      config: {
        renderer: ({ row }: any) => {
          return <>{row.synthetic_metric ? 'Formula' : 'Simple'}</>;
        },
      } as any,
    },
    {
      id: 'data',
      label: 'Data',
      field: 'analysis_type',
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 500,
      config: {
        renderer: ({ row }: any) => {
          return (
            <>
              {row.analysis_type.charAt(0).toUpperCase() +
                row.analysis_type.slice(1)}
            </>
          );
        },
      } as any,
    },
    {
      id: 'actions',
      label: 'actions',
      field: '',
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 200,
      config: {
        renderer: MetricActionCell,
      } as any,
    },
  ];
};

export const getWidgetsListTableColumns = (
  handleEdit: (id: string, name: string) => void,
  handleDelete: (id: string, name: string) => void,
  handleClone: (id: string, name: string) => void
): IColumn[] => {
  const WidgetActionCell: React.FC<IDataCellProps> = ({
    row,
  }: IDataCellProps) => {
    const id = (row as BIWidget)?.id ?? '';
    const name = (row as BIWidget)?.name;

    return (
      <>
        <BuButton
          icon
          borderless
          secondary
          onClick={() => handleEdit(id, name)}
          size={BuControlSize.SMALL}
          tooltip={'Edit widget'}
          testingLabel="edit-widget-button"
        >
          <BuIcon name={BoostUpIcons.Pencil} />
        </BuButton>
        <BuButton
          icon
          borderless
          secondary
          onClick={() => handleClone(id, name)}
          size={BuControlSize.SMALL}
          tooltip={'Clone widget'}
          testingLabel="clone-widget-button"
        >
          <BuIcon name={BoostUpIcons.Copy} />
        </BuButton>
        <BuButton
          icon
          borderless
          secondary
          onClick={() => handleDelete(id, name)}
          size={BuControlSize.SMALL}
          tooltip={'Delete widget'}
          testingLabel="delete-widget-button"
        >
          <BuIcon name={BoostUpIcons.Trash} />
        </BuButton>
      </>
    );
  };

  return [
    {
      id: 'name',
      label: 'widget name',
      field: 'name',
      minWidth: 640,
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 1000,
      config: {
        renderer: NameCell,
      } as any,
    },
    {
      id: 'createdBy',
      label: 'created by',
      field: 'metadata.user_name',
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.TEXT,
      width: 500,
      config: {},
    },
    {
      id: 'lastUpdated',
      label: 'last updated',
      field: 'updated_at',
      sortable: true,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 500,
      config: {
        renderer: MetricDateCell,
      } as any,
    },
    {
      id: 'actions',
      label: 'actions',
      field: '',
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      type: ColumnTypes.CUSTOM,
      width: 200,
      config: {
        renderer: WidgetActionCell,
      } as any,
    },
  ];
};
