import * as styles from './styles';
import { ColoredMetric } from './types';
import classNames from 'classnames';
import { MutableRefObject, RefObject } from 'react';

import * as CONSTANTS from 'components/UI/BuFormulaTextField/constants';
import { metricsColorRef } from 'components/UI/BuFormulaTextField/types';

export function insertNodeAtCaret(node: Node) {
  const sel = window.getSelection();
  if (sel?.rangeCount) {
    try {
      let range = sel.getRangeAt(0);
      range.collapse(false);
      range.insertNode(node);
      range = range.cloneRange();
      range.selectNodeContents(node);
      range.collapse(false);
      sel.removeAllRanges();
      sel.addRange(range);
    } catch (e) {
      console.error('insertNodeAtCaret :: ', e);
    }
  }
}
export function getColorMetricElementStyles(metricName: string) {
  const colors = [
    styles.metricColorBlue,
    styles.metricColorIndigo,
    styles.metricColorLime,
    styles.metricColorPurple,
    styles.metricColorYellow,
    styles.metricColorOrange,
  ];

  const stringToColour = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const idx = Math.abs(hash % colors.length);
    return colors[idx];
  };
  return classNames(styles.metricElement, stringToColour(metricName));
}

export function getHighlightedTerm(
  metric: ColoredMetric,
  metricsColors?: MutableRefObject<metricsColorRef>
) {
  const newTermElement = document.createElement('span');
  if (
    metricsColors &&
    metricsColors.current &&
    metric.id in metricsColors.current
  ) {
    newTermElement.className = metricsColors.current[metric.id];
  } else if (metricsColors && metricsColors.current) {
    const classes = getColorMetricElementStyles(metric.name);
    metricsColors.current[metric.id] = classes;
    newTermElement.className = classes;
  } else {
    newTermElement.className = getColorMetricElementStyles(metric.name);
  }
  newTermElement.dataset.elementId = metric.id;
  newTermElement.dataset.ftype = 'metric';
  newTermElement.innerText = metric.name;
  newTermElement.contentEditable = 'false';
  return newTermElement;
}

export function getHighlightedNumber(numbers: string) {
  const newNumberElement = document.createElement('span');
  newNumberElement.className = styles.metricElement;
  newNumberElement.dataset.elementId = numbers;
  newNumberElement.dataset.ftype = 'number';
  newNumberElement.innerText = numbers;
  newNumberElement.contentEditable = 'false';
  newNumberElement.tabIndex = -1;
  return newNumberElement;
}

export function getSignNode(sign: string) {
  const signWithoutWhitespaces = sign.replaceAll(/[\s]/gm, '');
  const element = document.createElement('span');
  element.className = styles.specialSybmol;
  element.dataset.elementId = signWithoutWhitespaces;
  element.dataset.ftype = 'sign';
  element.innerText = signWithoutWhitespaces;
  element.contentEditable = 'false';
  element.tabIndex = -1;
  return element;
}

export function getEditableSpan(text?: string | null) {
  const element = document.createElement('span');
  element.id = 'editable_span';
  element.contentEditable = 'true';
  if (text) {
    element.innerText = text;
  }
  return element;
}

export function getCaretPosition() {
  var caretPos = 0,
    sel,
    range;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel && sel.rangeCount) {
      range = sel.getRangeAt(0);
      caretPos = range.endOffset;
    }
  }
  return caretPos;
}

export function setCaretToEndOfNode(
  focusNode: HTMLDivElement,
  node: Node
): void {
  let range = new Range();
  const selection = window.getSelection();
  try {
    selection?.removeAllRanges();
    range.setStartAfter(node);
    range.collapse(true);
    selection?.addRange(range);
    focusNode.focus();
  } catch (e) {
    console.error('setCaretToEndOfNode ::', e);
  }
}

export function replaceTextNodeWithCustomNode(
  node: Node,
  formulaFieldNode: HTMLDivElement,
  moveCaret: boolean = true
): void {
  const { childNodes } = formulaFieldNode;
  const nodeType = (node as HTMLSpanElement).dataset.ftype;
  let insertedInsteadText = false;
  const caretPosition = getCaretPosition();

  if (childNodes.length && caretPosition >= 0) {
    insertedInsteadText = true;
    if (nodeType === 'number') {
      const prevPos = caretPosition - 1;
      const nextPos = caretPosition;
      if (
        prevPos >= 0 &&
        (childNodes[prevPos] as HTMLSpanElement).dataset.ftype === 'number'
      ) {
        const newContent = `${childNodes[prevPos].textContent}${node.textContent}`;
        childNodes[prevPos].textContent = newContent;
        (childNodes[prevPos] as HTMLSpanElement).dataset.elementId = newContent;
      } else if (
        nextPos &&
        nextPos < childNodes.length &&
        (childNodes[nextPos] as HTMLSpanElement).dataset.ftype === 'number'
      ) {
        const newContent = `${node.textContent}${childNodes[nextPos].textContent}`;
        childNodes[nextPos].textContent = newContent;
        childNodes[nextPos].textContent = newContent;
        (childNodes[nextPos] as HTMLSpanElement).dataset.elementId = newContent;
      } else {
        formulaFieldNode.insertBefore(node, childNodes[caretPosition]);
        moveCaret && setCaretToEndOfNode(formulaFieldNode, node);
      }
    } else if (nodeType === 'sign' && childNodes[caretPosition]) {
      formulaFieldNode.insertBefore(node, childNodes[caretPosition]);
      moveCaret && setCaretToEndOfNode(formulaFieldNode, node);
    } else if (nodeType === 'metric') {
      let replacePosition = -1;
      childNodes.forEach((node, idx) => {
        if (node.nodeName === '#text') {
          replacePosition = idx;
        }
      });
      if (replacePosition >= 0) {
        childNodes[replacePosition].replaceWith(node);
        moveCaret && setCaretToEndOfNode(formulaFieldNode, node);
      } else {
        insertedInsteadText = false;
      }
    } else {
      insertedInsteadText = false;
    }
  }

  if (!insertedInsteadText) {
    formulaFieldNode.appendChild(node);
    moveCaret && setCaretToEndOfNode(formulaFieldNode, node);
  }
}

export function persistFormulaFieldSelection(
  ref: MutableRefObject<Range | undefined>
) {
  const selection = window.getSelection();
  const range = selection?.getRangeAt(0);
  // check if ref isn't null and node type is text (3)
  if (ref !== null && range?.commonAncestorContainer.nodeType === 3) {
    ref.current = range?.cloneRange();
  }
}

export function compileFormulaToNodes(
  formula: string,
  metrics: ColoredMetric[],
  metricsColors: MutableRefObject<metricsColorRef>
): Node[] {
  let match;
  let nodes = [];
  while ((match = CONSTANTS.FORMULA_PARSER_REGEXP.exec(formula)) !== null) {
    let isMetric =
      match[0].length > 1 && !CONSTANTS.NUMBERS_REGEXP.test(match[0]);
    let isSign =
      match[0].length === 1 && CONSTANTS.SIGNS_ARRAY.includes(match[0]);
    let isNumber =
      match[0].length >= 1 && CONSTANTS.NUMBERS_REGEXP.test(match[0]);
    if (isMetric) {
      const metricId = match[0].replace(new RegExp(/[{}]/gm), '');
      const coloredMetric = metrics.find((m) => m.id === metricId);
      if (coloredMetric) {
        nodes.push(getHighlightedTerm(coloredMetric, metricsColors));
      }
    }
    if (isSign) {
      nodes.push(getSignNode(match[0]));
    }
    if (isNumber) {
      nodes.push(getHighlightedNumber(match[0]));
    }
  }
  return nodes;
}

export function cleanUpFormulaField(
  fieldRef: RefObject<HTMLDivElement> | undefined,
  selectionRef: MutableRefObject<Range | undefined>
) {
  if (fieldRef && fieldRef.current !== null) {
    fieldRef.current.innerHTML = '';
  }
  selectionRef.current = undefined;
}

export function getCurrentUserInsertion(
  fieldRef: RefObject<HTMLDivElement>
): string | null {
  let userInsertion: string = '';
  if (fieldRef.current !== null) {
    const { childNodes: newChildNodes } = fieldRef.current;
    // If the user inputs something new put to state pure text
    newChildNodes.forEach((node) => {
      if (node.nodeName === '#text') {
        userInsertion = node.textContent as string;
      }
    });
  }
  return userInsertion.trim();
}
