import Badge from '../../Badge';
import { isSubmissionUsedToCalculateDelta } from './helper';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { actions } from 'actions';
import { fetchForecastUserSubmissionHistory } from 'actions/forecastActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { formatMoney } from 'common/numbers';
import BuIcon from 'components/UI/BuIcon';
import * as styles from 'components/dashboard/Forecast/Dashboard/SubmitForecast/Tooltip/styles';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import { IReduxState } from 'reducers/types';
import {
  isForecastSubmissionHistoryByUserLoading,
  getForecastSubmissionHistoryByUser,
  getSelectedBusinessType,
  getCompanySettings,
} from 'selectors';

type HeaderProps = {
  title: string;
  isCurrencyDiffThenUser?: boolean;
};

type Props = {
  userId: string;
  companyCurrency: string;
  closeDateInterval: string | undefined;
  forecastName: string | undefined;
  businessTypeName: string | undefined;
  periodDelta?: number;
  periodType?: string;
  isCurrencyDiffThenUser?: boolean;
  deltaOnFirstHistoryItem?: number;
  submissionDateUsedToCalculateDelta?: string;
  deltaStartDateToCheck?: string;
};

const Header: React.FC<HeaderProps> = ({ title, isCurrencyDiffThenUser }) => (
  <div className={classNames(styles.row, styles.header)}>
    <div className="title">{title}</div>
    {isCurrencyDiffThenUser && (
      <div className="diff-currency">
        <BuIcon name={BoostUpIcons.MultiCurrency} />
        <div className="bu-font-caption">
          Forecast submitted in different currency
        </div>
      </div>
    )}
  </div>
);

const Tooltip: React.FC<Props & HeaderProps> = React.memo(
  ({
    title,
    userId,
    companyCurrency,
    closeDateInterval,
    forecastName,
    businessTypeName,
    periodDelta,
    periodType,
    isCurrencyDiffThenUser,
    deltaOnFirstHistoryItem,
    submissionDateUsedToCalculateDelta,
    deltaStartDateToCheck,
  }) => {
    const [isRequested, setIsRequested] = useState(false);
    const dispatch = useDispatch();

    const isLoading = useSelector(isForecastSubmissionHistoryByUserLoading);
    const { rollup_overall_view_enabled } = useSelector(getCompanySettings);
    const selectedBusinessType = useSelector((state: IReduxState) =>
      getSelectedBusinessType(
        state,
        'forecast_roll_ups',
        rollup_overall_view_enabled
      )
    );
    const history = useSelector((state: IReduxState) =>
      getForecastSubmissionHistoryByUser(
        state,
        userId,
        selectedBusinessType,
        forecastName,
        closeDateInterval,
        periodDelta,
        periodType
      )
    );

    const formatter = (value: number, userCurrency?: string) =>
      formatMoney(userCurrency ?? companyCurrency, value, 0);

    useEffect(() => {
      if (!isRequested && userId && !history.length && closeDateInterval) {
        setIsRequested(true);
        dispatch(
          fetchForecastUserSubmissionHistory({
            forecastName,
            userId,
            closeDateInterval,
            businessTypeName: selectedBusinessType,
            periodDelta,
            periodType,
          })
        );
      }
    }, [
      userId,
      forecastName,
      isRequested,
      history,
      closeDateInterval,
      businessTypeName,
      selectedBusinessType,
    ]);

    if (isLoading) {
      return (
        <div className={styles.container}>
          <Header
            title={title}
            isCurrencyDiffThenUser={isCurrencyDiffThenUser}
          />

          <div className={styles.content}>
            <Loader active inline="centered" />
          </div>
        </div>
      );
    }

    const openPanel = (id: string) => {
      dispatch(actions.ui.manageForecast.setActiveSettingsId(id));

      const submissionSetting =
        forecastName !== undefined && closeDateInterval !== undefined
          ? {
              forecastName,
              closeDateInterval,
              businessTypeName,
            }
          : undefined;

      openModal({
        scheme: '/deals/included/:submissionId',
        params: { submissionId: id, localStorageKeyPrefix: 'DealsIncluded' },
        persistParams: {
          apiUrl: `/api/data/forecast/submission/deals_data_history/${id}`,
          title,
          isHistoricalSubmissionsModal: true,
          filterIncludedTogglerInitialState: 'on',
          submissionSetting,
        },
        persistor: (params: PersistQueryParams) =>
          dispatch(actions.ui.modal.persist(params)),
      });
    };

    return (
      <div className={classNames('bu-font-default', styles.container)}>
        <Header title={title} isCurrencyDiffThenUser={isCurrencyDiffThenUser} />

        <div className={classNames(styles.column, styles.body)}>
          {history.length ? (
            history.map((item, idx) => (
              <div
                key={item.id}
                className={classNames(styles.row, styles.item)}
                data-testing={`forecast_submission-${idx}`}
              >
                <div
                  className={classNames(styles.date, {
                    [styles.highlight]:
                      submissionDateUsedToCalculateDelta &&
                      isSubmissionUsedToCalculateDelta(
                        item,
                        submissionDateUsedToCalculateDelta
                      ),
                  })}
                  data-testing={`forecast_submission_date`}
                >
                  {moment(item.created_at).format(`MMM D 'YY, h:mma`)}
                </div>
                <div className={classNames(styles.column, styles.info)}>
                  <div>
                    <div className={classNames(styles.row, styles.between)}>
                      {isCurrencyDiffThenUser && (
                        <div className={styles.historyValue}>
                          {formatter(item.locale_amount ?? 0)}

                          <span className="amount-in-user-currency">
                            ({formatter(item.amount, item.user_currency)})
                          </span>
                        </div>
                      )}

                      {!isCurrencyDiffThenUser && (
                        <div>
                          <div className={styles.badgeAndHistoryWrapper}>
                            <div className={styles.historyValue}>
                              {formatter(item.amount, item.user_currency)}
                            </div>
                            {idx === 0 && (
                              <div className={styles.badgeWrapper}>
                                <Badge value={deltaOnFirstHistoryItem} />
                              </div>
                            )}
                          </div>
                          {idx === 0 &&
                            submissionDateUsedToCalculateDelta &&
                            deltaStartDateToCheck && (
                              <span className={styles.changesSinceLabel}>
                                Delta based on latest submission by{' '}
                                {deltaStartDateToCheck}
                              </span>
                            )}
                        </div>
                      )}
                      {!!item.total_deals && (
                        <div
                          className={styles.historyDealsButton}
                          onClick={() => openPanel(item.id)}
                          data-testing={`forecast_submission_deals_button`}
                        >
                          Deals Included:{' '}
                          <span>
                            {item.len_included_deals || 0} of {item.total_deals}{' '}
                            ({formatter(item.included_deals_amount || 0)})
                          </span>
                        </div>
                      )}
                    </div>
                    <div>
                      {item.notes ? (
                        <div className={styles.notes}>Note: {item.notes}</div>
                      ) : (
                        <div className={styles.notes_none}>Note: N/A</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.content}>Submissions not available</div>
          )}
        </div>
      </div>
    );
  }
);

export default Tooltip;
