import { BIWidgetColumnV2 } from '../../metrics.types';
import { COLUMN_DEFAULTS } from '../Table/constants';
import React from 'react';

import { formatMoney, formatNumber } from 'common/numbers';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';

export const getTableOptions = (
  cols: BIWidgetColumnV2[],
  data: { [key: string]: any },
  companyCurrencyCode: string
) => {
  const columns: IColumn[] = cols.map((el) => {
    const id = `${el.field_name}`;
    return {
      ...COLUMN_DEFAULTS,
      align: 'right',
      field: id,
      label: el.display_name,
      sortable: false,
      type: el.type,
      id: id,
      metricId: el.metric_id,
      config: {
        formatter: (value: any) => (
          <span>
            {el.type === 'currency' ? (
              formatMoney(companyCurrencyCode, value)
            ) : el.type === 'number' ? (
              formatNumber(value)
            ) : el.type === 'percent' ? (
              `${value}%`
            ) : el.field_name === 'stage' ? (
              <strong> {value} </strong>
            ) : (
              value
            )}
          </span>
        ),
      },
    } as IColumn;
  });

  const rows: IRow[] = data as IRow[];

  return { columns, rows };
};
