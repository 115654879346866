import styled from '@emotion/styled';
import { css } from 'emotion';

export const ViewContainer = styled.div`
  height: 100%;
  gap: 10px;

  display: flex;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  padding: 0 20px;
`;

export const SpaceBetweenContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const Fluid = styled.div`
  flex: 1;
  min-height: 0;
`;

export const ComponentBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const UpdateLabel = styled.div`
  color: var(--bu-gray-700);
  font-size: 0.9em;
`;

export const removeMargins = css`
  margin: 0;
`;

export const teamRowBGColor = css`
  & > td {
    background-color: var(--bu-white) !important;
  }
`;

export const tableCellBorders = css`
  & th,
  & td {
    border-right: 1px solid var(--bu-gray-300) !important;
  }
`;

export const cellBackground = css`
  background-color: var(--bu-gray-200);
`;

export const cellTouched = css`
  td& {
    background-color: var(--bu-orange-300) !important;
  }
`;

export const TabsContainer = css`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  padding-right: 25px;
  align-items: center;
`;

export const CloseButton = css`
  color: var(--bu-gray-700);
  font-size: 20px;
  margin-left: 10px;
`;
