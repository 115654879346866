import Highcharts, { PointClickEventObject } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsBoost from 'highcharts/modules/boost';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { BIChartTypes } from 'common/enums/metrics';
import { getHighChartOptions } from 'components/dashboard/Metrics/Widget/Chart/helpers';
import { UserSettingsContext } from 'components/dashboard/Metrics/contexts/UserSettingsContext';
import {
  BIMetricsMap,
  BIWidget,
  BIWidgetDataV2,
} from 'components/dashboard/Metrics/metrics.types';

HighchartsBoost(Highcharts);

interface Props {
  metricToChartType: { metricId: string; chartType: BIChartTypes }[];
  metricData: BIWidgetDataV2;
  metricsInUse: BIMetricsMap;
  widget: Partial<BIWidget>;
  onDataClick: (pointEvent?: PointClickEventObject) => void;
}

export const WidgetChart: React.FC<Props> = ({
  metricToChartType,
  metricData,
  metricsInUse,
  widget,
  onDataClick,
}) => {
  const { currency: companyCurrencyCode } = useContext(UserSettingsContext);

  const chartRef =
    useRef<{
      chart: Highcharts.Chart;
      container: React.RefObject<HTMLDivElement>;
    }>(null);

  const hiChartOptions = useMemo(() => {
    try {
      if (metricToChartType.some((el) => el.chartType === BIChartTypes.Table)) {
        return {};
      }

      return getHighChartOptions(
        onDataClick,
        metricData,
        companyCurrencyCode,
        metricsInUse,
        metricToChartType
      );
    } catch (e) {
      console.error(`Error formatting highchart option`);
      console.error(e);
      return {};
    }
  }, [
    widget.chart_type,
    companyCurrencyCode,
    JSON.stringify(metricData),
    JSON.stringify(metricsInUse),
    JSON.stringify(metricToChartType),
  ]);

  useEffect(() => {
    const element = chartRef?.current?.container.current;

    if (!element) return;

    const observer = new ResizeObserver(() => {
      chartRef.current?.chart.reflow();
    });

    observer.observe(element);
    return () => {
      // Cleanup the observer by unobserving all elements
      observer.disconnect();
    };
  }, []);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={hiChartOptions}
      containerProps={{
        'data-testing': 'widget-chart-container',
        style: { height: '100%' },
      }}
      ref={chartRef}
    />
  );
};
