import { css } from 'emotion';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'actions';
import BuButton from 'components/UI/BuButton';
import Modal from 'components/UI/Modal/Modal';

const container = css`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const btn_wrap = css`
  padding: 0 10px;
`;

export const HotjarNotification: FC = () => {
  const dispatch = useDispatch();

  const handleClickAccept = () => {
    dispatch(actions.ui.hotjarService.toggleHotjarService(false));
    dispatch(actions.ui.hotjarService.setShowNotification());
  };

  return (
    <Modal onClose={handleClickAccept} title="Note" size="tiny">
      <div className={container}>
        To finish disabling this feature, please refresh your browser.
        <div className={btn_wrap}>
          <BuButton onClick={handleClickAccept}>Ok</BuButton>
        </div>
      </div>
    </Modal>
  );
};
