import classNames from 'classnames';
import React from 'react';

import * as styles from 'components/UI/TwoColumnsDashboard/styles';

type Props = {
  panel: React.ReactNode;
  isVisiblePanel: boolean;
  isModal?: boolean;
};

const TwoColumnsDashboard: React.FC<Props> = ({
  panel,
  isVisiblePanel,
  children,
  isModal = false,
}) => (
  <div
    className={classNames('container', {
      [styles.container]: isVisiblePanel,
    })}
  >
    <div
      className={classNames('container-dashboard', {
        [styles.children]: isVisiblePanel,
        [styles.modalHeight]: isModal,
      })}
    >
      {children}
    </div>

    {isVisiblePanel && (
      <div
        className={classNames(styles.panel, {
          [styles.isModal]: isModal,
        })}
      >
        {panel}
      </div>
    )}
  </div>
);

export default TwoColumnsDashboard;
