import { fetchWidgetData } from './historical.widget.helpers';
import { PointClickEventObject } from 'highcharts';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import { BIChartTypes } from 'common/enums/metrics';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { WidgetChart } from 'components/dashboard/Metrics/Widget/Chart/WidgetChart';
import { HistoricalPreviewWidgetControls } from 'components/dashboard/Metrics/Widget/Controls/HistoricalWidgetPreviewControls';
import { WidgetHeader } from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { DataFetchErrorMessage } from 'components/dashboard/Metrics/Widget/Messages/DataFetchErrorMessage';
import { NoDataMessage } from 'components/dashboard/Metrics/Widget/Messages/NoDataMessage';
import { WidgetTable } from 'components/dashboard/Metrics/Widget/Table/WidgetTable';
import { TotalsBlock } from 'components/dashboard/Metrics/Widget/TotalsBlock/TotalsBlock';
import { NOT_SAVED_METRIC } from 'components/dashboard/Metrics/Widget/constants';
import {
  getMetricNamesWithValues,
  getSyntheticMetricData,
  openSyntheticMetricDetailsModal,
  parseFormulaToMetricsIdArray,
} from 'components/dashboard/Metrics/Widget/helper';
import { openDrillDownModal } from 'components/dashboard/Metrics/Widget/historical.widget.helpers';
import { useMetricsList } from 'components/dashboard/Metrics/Widget/hooks/useMetricsList';
import * as s from 'components/dashboard/Metrics/Widget/metrics.widget.styles';
import {
  LoaderContainer,
  WidgetContainer,
} from 'components/dashboard/Metrics/Widget/widgets.styles';
import { BUSINESS_TYPES_FIELD_NAMES } from 'components/dashboard/Metrics/constants';
import {
  BIMetricColumn,
  BIMetricToChartType,
  BIMetrics,
  BIWidget,
  BIWidgetDataV2,
  DrillDownParams,
  SelectedValue,
  SyntheticMetricDataWidgetState,
  TimeSeriesDrillDownParams,
} from 'components/dashboard/Metrics/metrics.types';
import { IReduxState } from 'reducers/types';
import * as metricSelectors from 'selectors/revbi/metrics';
import * as widgetSelectors from 'selectors/revbi/widgets';
import { QueryStatus } from 'utils/network';

interface Props {
  isCreateEditMetric: boolean;
  hasValidFilters: boolean;
  metricsFromList: any[]; // TODO Fix type
  updateWidget: (widget: Partial<BIWidget>) => void;
}

export const HistoricalWidgetPreview: React.FC<Props> = ({
  isCreateEditMetric = false,
  hasValidFilters = false,
  metricsFromList,
  updateWidget,
}) => {
  const dispatch = useDispatch();

  const match = useRouteMatch<{ widgetId: string }>();

  const lastFetchedWidget = useRef<string>();

  const metric = useSelector(
    metricSelectors.getActiveMetric
  ) as Partial<BIMetrics>;

  const widget = useSelector((state: IReduxState) =>
    widgetSelectors.getActiveWidget(state, match.params.widgetId)
  );

  const metricIdList = useMemo(
    () => (isCreateEditMetric ? [metric?._id] : widget.metric_list),
    [isCreateEditMetric, metric?._id, widget.metric_list]
  );

  const { data: metricsDict } = useMetricsList(widget.metric_list);

  const metricsInUse = useMemo(() => {
    if (isCreateEditMetric && !metric?._id) {
      return {
        ...metricsDict,
        not_saved_metric: metric as BIMetrics,
      };
    } else if (isCreateEditMetric && !!metric?._id) {
      return {
        ...metricsDict,
        [metric._id]: metric as BIMetrics,
      };
    } else {
      return metricsDict;
    }
  }, [isCreateEditMetric, JSON.stringify(metric), metricsDict]);

  const [metricToChartType, setMetricToChartType] = useState<
    BIMetricToChartType[]
  >(
    widget?.properties?.metricToChartType ||
      (metricIdList as string[]).map((el) => ({
        chartType: widget.chart_type as BIChartTypes,
        metricId: el,
      }))
  );

  const [metricData, setMetricData] = useState<BIWidgetDataV2>();
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');

  const [pivot, setPivot] = useState<BIMetricColumn | undefined>(
    widget.group_by && widget?.group_by[0]
  );

  useEffect(() => {
    if (pivot?.name.toLocaleLowerCase() === 'none') {
      metricToChartType.forEach((el) => {
        el.chartType =
          el.chartType === BIChartTypes.ColumnStacked
            ? BIChartTypes.Column
            : el.chartType;
      });
      setMetricToChartType([...metricToChartType]);
    }
  }, [pivot?.name]);

  useEffect(() => {
    updateWidget({
      ...widget,
      properties: {
        metricToChartType: metricToChartType,
      },
    });
  }, [JSON.stringify(metricToChartType)]);

  //hack to make metricToChartType work with edit
  useEffect(() => {
    setMetricToChartType((prev) => {
      const res = [
        ...prev,
        ...Object.keys(metricsInUse)
          .filter((key) => !prev.some((el) => el.metricId === key))
          .map((key) => ({
            chartType: prev.some((el) => el.chartType === BIChartTypes.Table)
              ? BIChartTypes.Table
              : BIChartTypes.Column,
            metricId: key,
          })),
      ];
      updateWidget({
        ...widget,
        properties: {
          ...widget?.properties,
          metricToChartType: res,
        },
      });
      return res;
    });
  }, [JSON.stringify(metricsInUse), JSON.stringify(widget)]);

  useEffect(() => {
    if (
      metric &&
      isCreateEditMetric &&
      metric.metadata?.type === 'simple' &&
      ((metric.aggregation_function != 'count' && !metric.column) ||
        !metric.aggregation_function)
    ) {
      return;
    }

    if (
      metric &&
      metric.metadata?.type === 'formula' &&
      !metric.synthetic_metric
    ) {
      return;
    }

    let fullMetrics;
    if (isCreateEditMetric && metricsFromList) {
      fullMetrics = [...metricsFromList];
      let metricIdx;
      metricIdx = metricsFromList.findIndex((item) => item._id === metric?._id);
      if (fullMetrics) {
        fullMetrics[metricIdx] = metric;
      }
    }

    let queryWidget = !isMetricListIds
      ? { ...widget, metric_list: [metric] }
      : isCreateEditMetric
      ? { ...widget, metric_list: fullMetrics }
      : widget;

    const previewJson = JSON.stringify(queryWidget);

    const areHistoryFieldsValid = Boolean(
      widget.time_period && widget.time_interval && widget.point_in_time
    );

    let abortController: AbortController | null = null;

    const metricValid =
      !isCreateEditMetric ||
      metric?.synthetic_metric ||
      (metric?.aggregation_function && metric?.column) ||
      metric?.aggregation_function === 'count';

    if (
      previewJson !== lastFetchedWidget.current &&
      queryWidget?.metric_list?.length &&
      hasValidFilters &&
      metricValid &&
      areHistoryFieldsValid
    ) {
      lastFetchedWidget.current = previewJson;
      abortController = new AbortController();

      fetchWidgetData(
        previewJson,
        '',
        (widget._id ?? widget.name) || '',
        abortController,
        setMetricData,
        setDataStatus,
        setPivot
      );
    }

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [
    isCreateEditMetric,
    metric?.aggregation_function,
    metric?.column?.name,
    JSON.stringify(metric?.filters),
    metric?.metadata?.type,
    metric?.object,
    metric?.select_expression,
    metric?.synthetic_metric,
    widget.metric_list?.length,
    widget.time_field?.name,
    widget.time_field?.label,
    JSON.stringify(widget.template_filters),
    JSON.stringify(widget.group_by),
    widget.time_interval,
    widget.time_period,
    widget.point_in_time,
  ]);

  const handleTableDataClick = (column: IColumn, row: IRow): void => {
    const metricId = isCreateEditMetric ? NOT_SAVED_METRIC : column.metricId;

    if (metricId in metricsInUse) {
      const clickedMetric: BIMetrics = metricsInUse[metricId];

      let pivotValue = '';
      const escapedPivotName = pivot?.name?.replaceAll('.', '$');

      if (escapedPivotName) {
        pivotValue = row[escapedPivotName] as string;
      }

      // If it is a subtotal row then we do not send second pivot
      const drillDownFilters = row.isSubtotal
        ? []
        : [
            ...(widget.group_by ?? []).map((column) => ({
              column:
                column.type === 'date' && widget.time_field
                  ? widget.time_field
                  : column,
              operator: 'eq',
              value: pivotValue,
            })),
          ];

      const drilldownParams: TimeSeriesDrillDownParams = {
        filters: clickedMetric?.filters ?? [],
        template_filters: widget.template_filters ?? [],
        drill_down_filters: drillDownFilters,
        time_interval: widget.time_interval,
        time_period: widget.time_period,
        point_in_time: widget.point_in_time,
        target_time_period: column.extraHeader ?? '',
        business_type_name: presentBusinessType,
      };

      if (clickedMetric.synthetic_metric) {
        const parsedClickedMetricFormulaAsArray: string[] =
          parseFormulaToMetricsIdArray(clickedMetric.synthetic_metric);

        const syntheticMetricData: SyntheticMetricDataWidgetState =
          getSyntheticMetricData(
            parsedClickedMetricFormulaAsArray,
            metricData,
            clickedMetric
          );

        const selectedValue: SelectedValue = {
          y: !Number.isNaN(row[column.field] as number)
            ? (row[column.field] as number)
            : 0,
          metricsValues: getMetricNamesWithValues(
            parsedClickedMetricFormulaAsArray,
            row,
            syntheticMetricData
          ),
        };

        const pivot1FieldName = pivot?.field_name;
        if (pivot1FieldName) {
          selectedValue.pivot1Id = row[pivot1FieldName] as string;
        }

        const syntheticDrilldownParams: DrillDownParams = {
          drill_down_filters: drillDownFilters,
          filters: clickedMetric?.filters ?? [],
          template_filters: widget.template_filters ?? [],
          offset: 0,
          order_by: [],
          limit: 1000000,
          duration:
            widget.group_by?.[0]?.type === 'date'
              ? widget.group_by[0].name
              : '',
          skip_business_validation: true,
        };

        openSyntheticMetricDetailsModal(
          widget.title || widget.name || 'Drill Down',
          syntheticDrilldownParams,
          syntheticMetricData,
          selectedValue,
          dispatch
        );
      } else {
        openDrillDownModal(
          drilldownParams,
          clickedMetric,
          pivotValue,
          widget.title || widget.name || 'Drill Down',
          dispatch
        );
      }
    }
  };

  const handleChartDataClick = (pointEvent?: PointClickEventObject): void => {
    const selectedValue = pointEvent?.point?.options as any;
    const metricId = isCreateEditMetric
      ? NOT_SAVED_METRIC
      : selectedValue.metricId;

    if (metricId in metricsInUse) {
      const clickedMetric: BIMetrics = metricsInUse[metricId];

      const drillDownFilters =
        pointEvent && !!selectedValue?.pivot2Id
          ? [
              ...(widget.group_by ?? []).map((column) => ({
                column:
                  column.type === 'date' && widget.time_field
                    ? widget.time_field
                    : column,
                operator: 'eq',
                value: selectedValue?.pivot2Id,
              })),
            ]
          : [];

      if (clickedMetric.synthetic_metric) {
        const parsedClickedMetricFormulaAsArray: string[] =
          parseFormulaToMetricsIdArray(clickedMetric.synthetic_metric);

        const syntheticMetricData: SyntheticMetricDataWidgetState =
          getSyntheticMetricData(
            parsedClickedMetricFormulaAsArray,
            metricData,
            clickedMetric
          );

        const drilldownParams: DrillDownParams = {
          drill_down_filters: drillDownFilters,
          filters: clickedMetric?.filters ?? [],
          template_filters: widget.template_filters ?? [],
          order_by: [],
          offset: 0,
          limit: 1000000,
          duration:
            widget.group_by?.[0]?.type === 'date'
              ? widget.group_by[0].name
              : '',
          skip_business_validation: true,
        };

        let dataRow = {};
        if (availablePivots === 1) {
          const pivot1Id = selectedValue.pivot1Id;
          const pivot1Column = metricData?.pivot_1?.columns.find(
            (el) => el.is_pivot
          );
          const key = pivot1Column?.field_name?.replaceAll('.', '$') || '';
          dataRow =
            metricData?.pivot_1?.data.find((d) => d[key] === pivot1Id) || {};
        } else if (availablePivots === 2) {
          const pivot1Id = selectedValue.pivot1Id;
          const pivot1Column = metricData?.pivot_1?.columns.find(
            (el) => el.is_pivot
          );
          const pivot1Key =
            pivot1Column?.field_name?.replaceAll('.', '$') || '';
          const pivot2Id = selectedValue.pivot2Id;
          const pivot2Key = pivot?.name?.replaceAll('.', '$') || '';
          dataRow =
            metricData?.pivot_2?.data.find(
              (d) => d[pivot1Key] === pivot1Id && d[pivot2Key] === pivot2Id
            ) || {};
        }

        openSyntheticMetricDetailsModal(
          widget.title || widget.name || 'Drill Down',
          drilldownParams,
          syntheticMetricData,
          {
            ...selectedValue,
            metricsValues: getMetricNamesWithValues(
              parsedClickedMetricFormulaAsArray,
              dataRow,
              syntheticMetricData
            ),
          },
          dispatch
        );
      } else {
        const drilldownParams: TimeSeriesDrillDownParams = {
          filters: clickedMetric?.filters ?? [],
          template_filters: widget.template_filters ?? [],
          drill_down_filters: drillDownFilters,
          time_interval: widget.time_interval,
          time_period: widget.time_period,
          point_in_time: widget.point_in_time,
          target_time_period: selectedValue?.pivot1Id,
          business_type_name: presentBusinessType,
        };

        openDrillDownModal(
          drilldownParams,
          clickedMetric,
          selectedValue?.pivot2Id,
          widget.title || widget.name || 'Drill Down',
          dispatch
        );
      }
    }
  };

  const handleTableSortChange = (columnName: string) => {
    updateWidget({
      ...widget,
      properties: {
        ...widget?.properties,
        table_view_order_by_column: columnName,
      },
    });
  };

  const isMetricListIds: boolean = useMemo(
    () => widget?.metric_list && typeof widget?.metric_list[0] === 'string',
    [JSON.stringify(widget?.metric_list)]
  );

  const widgetFiltersBusinessType: string = useMemo(() => {
    const templateFilterBusinessType = widget.template_filters?.find((filter) =>
      BUSINESS_TYPES_FIELD_NAMES.has(filter.column.name)
    );

    return (templateFilterBusinessType?.value as string[])?.[0];
  }, [JSON.stringify(widget.template_filters)]);

  const metricsBusinessType: string = useMemo(() => {
    let businessType = '';
    (metricsFromList as BIMetrics[])?.forEach((m) => {
      m.filters?.forEach((f) => {
        const condition = f.and_condition?.[0].or_condition?.[0];
        if (BUSINESS_TYPES_FIELD_NAMES.has(condition?.column?.name)) {
          businessType = (condition.value as string[])?.[0];
        }
      });
    });

    if (metric) {
      metric?.filters?.forEach((f) => {
        const condition = f.and_condition?.[0].or_condition?.[0];
        if (BUSINESS_TYPES_FIELD_NAMES.has(condition?.column?.name)) {
          businessType = (condition.value as string[])?.[0];
        }
      });
    }

    return businessType;
  }, [JSON.stringify(metricsFromList), metric]);

  const presentBusinessType = widgetFiltersBusinessType || metricsBusinessType;

  const hasData = !!metricData?.pivot_1?.data.length;
  const availablePivots = metricData?.hasOwnProperty('pivot_2')
    ? 2
    : metricData?.hasOwnProperty('pivot_1')
    ? 1
    : 0;

  return (
    <>
      <WidgetContainer
        key={widget.id}
        isDashboard={false}
        isMetricsPreview={isCreateEditMetric}
        isModal={false}
      >
        <WidgetHeader
          id={widget.id}
          name={widget.name}
          isCreateEditMetric={isCreateEditMetric}
        />

        <HistoricalPreviewWidgetControls
          pivot={pivot}
          metricToChartType={metricToChartType}
          metricData={metricData}
          setPivot={setPivot}
          updateWidget={updateWidget}
          setMetricToChartType={setMetricToChartType}
        />

        {dataStatus === 'loading' && (
          <LoaderContainer>
            <Loader active content="Loading" />
          </LoaderContainer>
        )}

        {dataStatus === 'success' && (
          <>
            {(!!metricData?.pivot_0?.columns?.length ||
              !!metricData?.pivot_0?.columns_error?.length) && (
              <div
                className={s.totalsContainer}
                data-testing="totals-container"
              >
                <div className={s.totalsBarElement}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TotalsBlock
                      showControls
                      isPivoted
                      pivot2={pivot}
                      metricData={metricData}
                      metricsInUse={metricsInUse}
                      type={
                        metricsFromList &&
                        metricsFromList.length === 1 &&
                        !metricData?.hasOwnProperty('pivot_1')
                          ? 'singleValue'
                          : (metricsFromList &&
                              metricsFromList.length > 4 &&
                              !metricData?.hasOwnProperty('pivot_1')) ||
                            !metricData?.hasOwnProperty('pivot_1')
                          ? 'totalsOnly'
                          : 'totalsAndChart'
                      }
                      widget={widget}
                      metricToChartType={metricToChartType}
                      onDataClick={() => {}}
                      onChartTypeChange={(metricId, chartType) => {
                        let result: {
                          chartType: BIChartTypes;
                          metricId: string;
                        }[] = [...metricToChartType];
                        if (
                          chartType === BIChartTypes.Table &&
                          (
                            (widget.properties
                              ?.metricToChartType as BIMetricToChartType[]) ||
                            []
                          ).every((el) => el.chartType !== BIChartTypes.Table)
                        ) {
                          result = [
                            ...result.map((el) => ({
                              ...el,
                              chartType: chartType,
                            })),
                          ];
                        }
                        if (
                          (
                            (widget.properties
                              ?.metricToChartType as BIMetricToChartType[]) ||
                            []
                          ).every(
                            (el) => el.chartType === BIChartTypes.Table
                          ) &&
                          chartType !== BIChartTypes.Table
                        ) {
                          result = [
                            ...result.map((el) => ({
                              ...el,
                              chartType: chartType,
                            })),
                          ];
                        }

                        const type = result.find(
                          (el) => el.metricId === metricId
                        );

                        if (!!type) {
                          type.chartType = chartType;
                          result = [...result];
                        } else {
                          result = [...result, { metricId, chartType }];
                        }

                        setMetricToChartType(result);
                        updateWidget({
                          ...widget,
                          properties: {
                            ...widget?.properties,
                            metricToChartType: result,
                          },
                          chart_type: chartType,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {hasData && availablePivots > 0 && (
              <>
                {metricToChartType.some((el) => el.chartType === 'table') &&
                  widget.metric_list?.length > 0 &&
                  !!metricData && (
                    <WidgetTable
                      isDateFirstPivot
                      sortByColumn={
                        widget.properties?.table_view_order_by_column
                      }
                      widgetData={metricData}
                      metricsCount={widget.metric_list?.length || 0}
                      availablePivots={availablePivots}
                      metricsInUse={metricsInUse}
                      onTableDataClick={handleTableDataClick}
                      onTableSortChange={handleTableSortChange}
                    />
                  )}

                {metricToChartType.every((el) => el.chartType !== 'table') &&
                  !!metricData && (
                    <WidgetChart
                      metricToChartType={metricToChartType}
                      metricData={metricData}
                      metricsInUse={metricsInUse}
                      widget={widget}
                      onDataClick={handleChartDataClick}
                    />
                  )}
              </>
            )}

            {!hasData && availablePivots > 0 && (
              <NoDataMessage data-testing="no-data-message" />
            )}
          </>
        )}

        {dataStatus === 'error' && <DataFetchErrorMessage />}
      </WidgetContainer>
    </>
  );
};
