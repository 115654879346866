import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';
import { Radio } from 'semantic-ui-react';
import { CheckboxProps } from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';

export const cleanMargin = css`
  &.ui.checkbox {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 20px;
  }
`;

export const color = css`
  &.ui.checkbox {
    input:checked ~ label:before,
    label:before {
      border-color: var(--bu-primary-500);
    }

    input:checked ~ label:after {
      background-color: var(--bu-primary-500) !important;
    }
  }
`;

export type IRadioProps = {
  label?: React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  onChange?(e: React.FormEvent<HTMLInputElement>, data: CheckboxProps): void;
  value?: number | string;
  name?: string;
} & Common.DataTestingType;
const COMPONENT_TESTING_NAME = 'Radio';
const BuRadio: React.FC<IRadioProps> = ({
  checked = false,
  disabled,
  label,
  name = '',
  onChange = () => {},
  value,
  testingLabel = '',
}) => {
  const componentDataTestingKey = testingLabel
    ? `${testingLabel}-${COMPONENT_TESTING_NAME}`
    : COMPONENT_TESTING_NAME;
  return (
    <Radio
      value={value}
      label={label}
      disabled={disabled}
      checked={checked}
      name={name}
      className={classNames(cleanMargin, color)}
      onChange={(e, data) => onChange(e, data)}
      data-testing={`${componentDataTestingKey}-${`${value}`?.replaceAll(
        ' ',
        '_'
      )}`}
    />
  );
};

export default BuRadio;
