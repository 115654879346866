import React from 'react';
import { Icon } from 'semantic-ui-react';

import * as styles from 'components/chart-dashboards/styles';
import { IProps } from 'components/chart-dashboards/types';
import { Link } from 'navigation/Link';
import { Scheme, indexToDashboard } from 'navigation/routes';

const DashboardsList: React.FC<IProps> = ({ dashboardsList }) => (
  <div className={styles.wrapper}>
    <h1>Dashboards</h1>

    <div className={styles.linksWrapper}>
      {dashboardsList.map((dashboardItem, id) => {
        const key = String(id);
        const scheme: Scheme | undefined = indexToDashboard[key]?.scheme;

        if (!scheme) {
          return null;
        }

        return (
          <Link key={String(id)} url={{ scheme }} className={styles.link}>
            <Icon name="area chart" />
            {dashboardItem}
          </Link>
        );
      })}
      <Link
        key="prospect-account"
        url={{ scheme: '/prospect-account-dashboard' }}
        className={styles.link}
      >
        <Icon name="area chart" />
        Prospect Account
      </Link>
    </div>
  </div>
);

export default DashboardsList;
