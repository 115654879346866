import { MANAGER_ROLES } from 'common/constants';
import { IColumn, SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { ClickableCellConfig } from 'components/UI/common/TypedTable/renderers/ClickableCell';
import {
  DropDownCellConfig,
  IDropDownOptions,
} from 'components/UI/common/TypedTable/renderers/DropDownCell';

type GetColumnsParams = {
  currentUserRole?: string;
  managersOptions: IDropDownOptions[];
  highlightText?: string;
};

type getColumnsFunction = (
  params: GetColumnsParams
) => { [id: string]: IColumn };

export const ALL_TEXT = 'All';

export const getColumns: getColumnsFunction = ({
  currentUserRole,
  managersOptions,
  highlightText,
}) => ({
  name: {
    id: 'name',
    label: 'Name',
    field: 'name',
    type: ColumnTypes.USER,
    sortable: true,
    sort_order: SortOrder.ASCENDING,
    width: 250,
    config: {
      className: 'primary-cell',
      highlightText,
    } as ClickableCellConfig,
  },
  email: {
    id: 'email',
    label: 'Email',
    field: 'email',
    type: ColumnTypes.TEXT,
    sortable: true,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
  title: {
    id: 'title',
    label: 'Title',
    field: 'title',
    type: ColumnTypes.TEXT,
    sortable: false,
    width: 200,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
  numOpenOps: {
    id: 'numOpenOps',
    label: 'Open Opportunities',
    field: 'num_open_opportunities',
    type: ColumnTypes.NUMBER,
    align: 'center',
    sortable: true,
    width: 200,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
  manager: {
    id: 'manager',
    label: 'Manager',
    field: 'manager',
    type: ColumnTypes.DROPDOWN,
    editable: false,
    hidden: !Object.values(MANAGER_ROLES).includes(currentUserRole || ''),
    sortable: true,
    sort_order: SortOrder.ASCENDING,
    width: 250,
    config: {
      options: managersOptions,
    } as DropDownCellConfig,
  },
  sfdc_perfil: {
    id: 'profile_name',
    label: 'SFDC profile',
    field: 'profile_name',
    type: ColumnTypes.TEXT,
    sortable: true,
    width: 200,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
  sfdc_role: {
    id: 'sfdc_role',
    label: 'SFDC Role',
    field: 'sfdc_role',
    type: ColumnTypes.TEXT,
    sortable: true,
    width: 200,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
  id: {
    id: 'id',
    label: 'SFDC ID',
    field: 'sfdc_user_id',
    type: ColumnTypes.TEXT,
    sortable: false,
    width: 200,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
});
