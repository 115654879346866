import styled from '@emotion/styled';

/**
 * These are common styles for widgets, be careful when editing them as it will change all widget types
 */

interface WidgetContainerProps {
  readonly isDashboard: boolean;
  readonly isMetricsPreview: boolean;
  readonly isModal?: boolean;
}

export const WidgetContainer = styled.div(
  ({ isDashboard, isMetricsPreview }: WidgetContainerProps) => ({
    background: 'var(--bu-white)',
    boxShadow: isDashboard
      ? 'none'
      : '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.12)',
    borderColor: 'var(--bu-gray-200)',
    borderRadius: '8px',
    width: '100%',
    height: '100%',
    minHeight: isDashboard ? '100%' : '300px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: isDashboard || isMetricsPreview ? '0' : '48px',
  })
);

export const LoaderContainer = styled.div({
  position: 'relative',
  flexGrow: 1,
  width: '100%',
  height: '100%',
  zIndex: 99,
});
