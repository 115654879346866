import styled from '@emotion/styled';

export const MetricTitle = styled.span({
  fontSize: '16px',
  fontWeight: 315,
  lineHeight: '22px',
  color: 'var(--bu-gray-900)',
  marginBottom: '4px',
});

export const MetricsButtonsContainer = styled.div({
  padding: '16px 0 24px 0',
  display: 'flex',
  gap: '16px',
});

export const SavedMetricBlock = styled.div({
  border: '1px solid var(--bu-gray-400)',
  borderRadius: '4px',
  padding: '12px',
  display: 'flex',
  marginBottom: '24px',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginRight: '4px',
});

export const MetricInfoContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  minWidth: '232px',
});

export const ActionButtonsContainer = styled.div({
  display: 'flex',
});
