import clx from 'classnames';
import React from 'react';

import BuPopup from 'components/UI/BuPopup';
import {
  options,
  option_body,
  option,
  title,
  subheading,
} from 'components/UI/OpenFiltersPanel/SaveViewDropdown/SaveViewActions/styles';
import { popup } from 'components/UI/OpenFiltersPanel/styles';

type IProps = {
  isDefaultView: boolean;
  isOpen: boolean;
  toggle: () => void;
  openCreateNewViewModal: () => void;
  onUpdateCustomView: () => void;
};

const SaveViewActions: React.FC<IProps> = ({
  isDefaultView,
  isOpen: open,
  toggle,
  openCreateNewViewModal,
  onUpdateCustomView,
}) => {
  const handleUpdateCustomView = () => {
    onUpdateCustomView();
    toggle();
  };

  return (
    <div
      className={clx(options, {
        open,
      })}
    >
      <div className={option_body}>
        {isDefaultView ? (
          <BuPopup
            trigger={(ref) => (
              <div ref={ref}>
                {
                  <div className={option}>
                    <p className={clx(title, { disabled: true })}>
                      Update View
                    </p>
                    <span className={clx(subheading, { disabled: true })}>
                      Overwrite settings for the current view.
                    </span>
                  </div>
                }
              </div>
            )}
          >
            <div className={popup}>
              BoostUp default view
              <br /> can not be updated
            </div>
          </BuPopup>
        ) : (
          <div className={option} onClick={handleUpdateCustomView}>
            <p className={title}>Update View</p>
            <span className={subheading}>
              Overwrite settings for the current view.
            </span>
          </div>
        )}
        <div className={option} onClick={openCreateNewViewModal}>
          <p className={title}>Create New View</p>
          <span className={subheading}>
            Keep current view and create a new one.
          </span>
        </div>
      </div>
    </div>
  );
};

export default SaveViewActions;
