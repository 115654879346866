import { css } from 'emotion';

export const filtersContainer = css`
  display: flex;
  width: fit-content;
  justify-content: flex-end;

  & > div {
    margin-top: 0;
  }
`;

export const header_prospect = css`
  flex-shrink: 0;
  border-radius: 4px 4px 0 0;
  min-height: 55px;
  padding: 0 10px 0 25px;
  display: flex;
  align-items: center;
  color: var(--bu-gray-700);
  justify-content: space-between;
`;

export const rowItemLabel = css`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  font-size: 15px;
  color: #4a4a4a;
  font-family: var(--bu-font-regular);

  img {
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }
`;

export const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const btnTime = css`
  background: #f4f7f8;
  color: #4a4a4a;
  font-size: 13px;
  border: none;
  margin: 0 5px;
  padding: 6px 10px;
  font-family: var(--bu-font-regular);
  cursor: pointer;
  transition: 0.4s;

  &:hover {
    background: #4a4a4a;
    color: #f4f7f8;
  }

  &:focus {
    outline: none;
  }
`;

export const btnTimeActive = css`
  background: #4a4a4a;
  color: #f4f7f8;
`;

export const graphics = css`
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: var(--bu-white);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: relative;
  width: 100%;
  box-shadow: 0 0 1px 0 var(--bu-gray-500);
`;

export const loader_centered = css`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const loader = css`
  position: absolute !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
`;

export const dropdown = css`
  padding: 0px 10px 0px 10px !important;
  border: 1px solid var(--bu-gray-500);
  height: 32px !important;
  border-radius: var(--bu-control-border-radius);
  background-color: white;
  color: var(--bu-gray-900);
  margin: 0px 0px 0px 10px;
  display: flex !important;
  align-items: center;

  &:hover {
    background-color: var(--bu-gray-400);
  }

  div {
    flex: 1;
  }
  i {
    display: flex;
    align-items: center;
  }
`;

export const dropdownContainer = css`
  display: flex;
  flex-direction: row;
  font-size: 15px;
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  padding-left: 40px;
  margin-top: -12px;
  z-index: 20;
  position: relative;
`;

export const emailDropdown = css`
  width: 170px;

  div {
    width: 170px;
  }
`;

export const resetDefaultStyles = css`
  &.dropdown,
  & .dropdown {
    margin-left: 5px;
    display: flex !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .text {
      white-space: nowrap;
    }

    & i {
      margin-top: 5px !important;
    }
  }
`;

export const overflow = css`
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 15px !important;
`;

export const row = css`
  height: 70px;
`;

export const row_stage = css`
  padding-bottom: 6px;
`;
