import fileDownload from 'js-file-download';
import { select, put, call, all, takeLatest } from 'redux-saga/effects';

import * as t from 'actions/actionTypes';
import {
  paginateAITrainingVersions,
  toggleAITrainingShowFilters,
  fetchAITrainingContent,
} from 'actions/aiTrainingActions';
import {
  getAITrainingVersions,
  isAITrainingFilterVisible,
  getAITrainingFilters,
  getAITrainingCurrentVersion,
} from 'selectors';

function* onFiltersChange() {
  const version = yield select(getAITrainingCurrentVersion);
  const filters = yield select(getAITrainingFilters);
  yield put(fetchAITrainingContent(version.id, filters));
}

function* onFiltersApply() {
  // close filters if open
  const isFiltersOpen = yield select(isAITrainingFilterVisible);

  if (isFiltersOpen) {
    yield put(toggleAITrainingShowFilters());
  }

  // apply the filters
  yield call(onFiltersChange);
}

function* onExport({ payload }) {
  const version = yield select(getAITrainingCurrentVersion);

  fileDownload(payload, `${version.name}.csv`);
}

function* onVersionDelete() {
  // when deleting a version, retrieve ONE more version to cover for the missing one and
  // keep the pagination working as expected
  const versions = yield select(getAITrainingVersions);

  yield put(paginateAITrainingVersions(versions.length, 1));
}

function* aiTrainingSaga() {
  yield all([
    takeLatest(
      [
        t.AI_TRAINING + t.FILTER + t.CHANGE,
        t.AI_TRAINING + t.FILTER + t.REMOVE,
      ],
      onFiltersChange
    ),
    takeLatest(
      [t.AI_TRAINING + t.FILTER + t.CLEAR, t.AI_TRAINING + t.FILTER + t.APPLY],
      onFiltersApply
    ),
    takeLatest(
      [t.AI_TRAINING + t.LABELED_CONTENT + t.EXPORT + t.SUCCESS],
      onExport
    ),
    takeLatest(
      [t.AI_TRAINING + t.VERSION + t.REMOVE + t.SUCCESS],
      onVersionDelete
    ),
  ]);
}

export default aiTrainingSaga;
