import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';

import Table from 'components/UI/TableConfig';
import { CallbackProps } from 'components/UI/TableConfig/column-helper';
import {
  isColumnConfigClickable,
  isColumnConfigQuery,
} from 'components/UI/TableConfig/types';
import { IColumn } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { AccountData } from 'components/dashboard/Accounts/AccountsList';
import viewConfig from 'components/dashboard/Accounts/getConfig';
import {
  usePageSize,
  usePageNumber,
  usePageSort,
  usePageSearch,
} from 'components/dashboard/Accounts/utils';
import * as styles from 'components/modals/AccountsModal/styles';
import { IProps } from 'components/modals/AccountsModal/types';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';

const { AbortController } = window;

const handleClick = (
  { column, columnConfig, objectId }: CallbackProps,
  persistModalParams: (params: PersistQueryParams) => void
) => {
  if (column.type === ColumnTypes.TEXT) {
    if (isColumnConfigClickable(columnConfig)) {
      switch (columnConfig.meta.object_id) {
        case 'account_id':
          openModal({
            scheme: '/account/:id',
            params: {
              id: objectId,
            },
          });
          break;
        case '_id':
          openModal({
            scheme: '/account/:id',
            params: {
              id: objectId,
            },
          });
          break;
        default:
          console.warn('Action not supported');
      }
    } else if (isColumnConfigQuery(columnConfig)) {
      if (
        columnConfig.meta.object_id === '_id' &&
        (columnConfig.meta as any).collection === 'deals'
      ) {
        openModal({
          scheme: '/deals/:tab',
          params: { tab: 'accounts-deals' },
          persistParams: { ...columnConfig.meta.params, account_id: objectId },
          persistor: persistModalParams,
        });
      } else {
        console.warn('Action not supported', columnConfig);
      }
    } else {
      console.warn('Action not supported', columnConfig);
    }
  } else if (column.type === ColumnTypes.SCORE) {
    openModal({
      scheme: '/account/:id',
      params: {
        id: objectId,
      },
    });
  }
};

const AccountsModal: React.FC<IProps> = ({
  modalProps,
  persistModalParams,
}) => {
  const [status, setStatus] = useState<
    'notAsked' | 'loading' | 'success' | 'error'
  >('notAsked');
  const [data, setData] = useState<AccountData[]>([]);
  const [count, setCount] = useState<number>(0);
  const [tableReady, setTableReady] = useState(false);

  const [rowsPerPage = 50, setPageSize] = usePageSize();
  const [currentPage = 1, setPageNumber] = usePageNumber();
  const [sortOrder = viewConfig.defaultSortOrder, setSortOrder] = usePageSort(
    Object.values<IColumn>(viewConfig.columns)
      .filter((column) => column.sortable)
      .map((column) => column.field)
  );
  const [searchText = '', setSearchText] = usePageSearch();

  const filters = {
    ...JSON.parse(modalProps.filters || '{}'),
    page_size: 50,
    page_number: 0,
  };

  const fetchData = useCallback(
    async (request: any, controller: AbortController) => {
      setStatus('loading');

      try {
        const response = await fetch(
          modalProps.apiUrl || '/api/data/accounts/',
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ ...filters, ...request }),
            signal: controller.signal,
          }
        );

        const result = await response.json();

        setData((result.data ? result.data.accounts : result.accounts) || []);
        setCount(
          (result.data ? result.data.total_count : result.total_count) || 0
        );

        setStatus('success');
      } catch (err) {
        setStatus('error');
        if (err.name === 'AbortError') {
          console.warn('ABORTED');
          return;
        }
      }
    },
    [setData, setCount, setStatus, modalProps.apiUrl, filters]
  );

  useEffect(() => {
    const controller = new AbortController();

    const query = {
      account_name: searchText || undefined,
      page_size: rowsPerPage,
      page_number: currentPage - 1,
      sort: sortOrder || undefined,
    };

    if (tableReady) {
      fetchData(query, controller);
    }

    return () => {
      controller.abort();
    };
  }, [searchText, rowsPerPage, currentPage, sortOrder, tableReady]);

  const handleSort = useCallback(
    (sort?: string) => {
      setPageNumber(1);
      setSortOrder(sort);
    },
    [setPageNumber, setSortOrder]
  );

  const handleSearchChange = useCallback(
    (text: string) => {
      setSearchText(text);
      setPageNumber(1);
    },
    [setPageNumber, setSearchText]
  );

  const handlePaginationChange = useCallback(
    (page: number, pageSize: number) => {
      setPageNumber(page);
      setPageSize(pageSize);
    },
    [setPageSize, setPageNumber]
  );

  return (
    <div className={styles.tableWrapper}>
      <Table
        tableConfigCollection="account"
        tableConfigName="Accounts"
        title={modalProps.title || ''}
        onReady={() => setTableReady(true)}
        data={data.map((item) => ({ ...item, id: item._id }))}
        loading={status === 'loading'}
        onSpecialClick={(props) => handleClick(props, persistModalParams)}
        specialTableConfig={{
          riskMoreButtonLabel: 'View Account Insights',
        }}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        totalCount={count}
        onPaginationChange={handlePaginationChange}
        onSearchChange={handleSearchChange}
        hideSearch
        sortOrder={sortOrder}
        onSort={handleSort}
        isModal
        showColumnsVisibilityToggle
        fullscreen
        hidePaginationEnd
      />
    </div>
  );
};

const AccountsModalWrapper: React.FC<IProps> = ({
  modalProps,
  persistModalParams,
  accountHiddenColumns,
}) => {
  if (isEmpty(modalProps)) {
    return <Loader active />;
  }

  return (
    <AccountsModal
      accountHiddenColumns={accountHiddenColumns}
      modalProps={modalProps}
      persistModalParams={persistModalParams}
    />
  );
};

export default AccountsModalWrapper;
