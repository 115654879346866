import * as t from 'actions/actionTypes';

type TInput = string | undefined;

export const setExtremeItemsLabelsFromForecastTrends = (
  first: TInput,
  last: TInput
) => ({
  type: t.SET_OPPORTUNITY_EXTREME_ITEMS_LABELS,
  payload: { first, last },
});

export const setSelectedLabel = (title: TInput) => ({
  type: t.SET_OPPORTUNITY_SELECTED_LABEL,
  payload: { title },
});
