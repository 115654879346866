import styled from '@emotion/styled';
import classNames from 'classnames';
import * as R from 'ramda';
import React, { FC, useEffect, MouseEvent, useCallback } from 'react';
import {
  ConnectDragSource,
  ConnectDropTarget,
  ConnectDragPreview,
} from 'react-dnd';
import { Table, Ref } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { useStateWhile } from 'common/helpers';
import BuCheckbox from 'components/UI/BuCheckbox';
import BuIcon from 'components/UI/BuIcon';
import { ITypedHierarchicalRow } from 'components/UI/common/TypedTable/TypedHierarchicalRow';
import TypedHierarchyToggle from 'components/UI/common/TypedTable/TypedHierarchyToggle';
import {
  getHierarchicalStyle,
  isDisable,
  isFunction,
  onChangeCallback,
  OnSelect,
} from 'components/UI/common/TypedTable/TypedTable';
import {
  CellStatus,
  IColumn,
  IRow,
  IdType,
  Status,
  StatusData,
} from 'components/UI/common/TypedTable/TypedTable';
import TypedCell, {
  ColumnTypes,
} from 'components/UI/common/TypedTable/renderers';
import { ShowMoreCellConfig } from 'components/UI/common/TypedTable/renderers/ShowMoreCell';
import { getFieldValue } from 'components/UI/common/TypedTable/renderers/custom/common';
import {
  cellContainer,
  hierarchyWrapper,
  marginHierarchyField,
  showMoreBox,
  wrapText,
} from 'components/UI/common/TypedTable/styles';

export interface ITypedRowProps extends ITypedHierarchicalRow {
  isHierarchyOpen?: boolean;
  toggleHierarchy?: (event: MouseEvent<HTMLButtonElement>) => void;

  selectable?: false | string;
  onSelect?: OnSelect;
  showOrHideColumns?: { label: string; id: string; show: boolean }[];
  opacity?: number;
  drag?: ConnectDragSource;
  drop?: ConnectDropTarget;
  preview?: ConnectDragPreview;
}

const DragDropHandle = styled.div`
  color: var(--bu-gray-500);
  width: 1rem;
  font-size: 1.4em;
  display: flex;
  cursor: move;
  justify-content: center;
  align-items: center;
  width: 100%;

  &.disabled {
    color: var(--bu-gray-200);
    cursor: default;
  }
`;

const TypedRow: FC<ITypedRowProps> = ({
  isHierarchyOpen,
  level,
  maxLevel,
  onChange,
  onDraftChange,
  row,
  draftRow,
  rows,
  rowClassName,
  rowStatus,
  setShowMoreDetailsVisible,
  showMoreDetailsVisible,
  toggleHierarchy,
  visibleColumns,
  selectable = false,
  onSelect = () => {},
  showOrHideColumns,
  enableReorderingRows,
  canDragRow,
  drag,
  drop,
  opacity,
  preview,
}) => {
  const [showStatus, setShowStatus] = useStateWhile(false);
  const isRowDraggable = enableReorderingRows && canDragRow(row, rows);

  useEffect(() => {
    if ((rowStatus?.[row.id]?.date as number) + 5000 > Date.now()) {
      setShowStatus(true, 5000);
    }
  }, [rowStatus?.[row.id]?.date]);

  const isHierarchy = level !== undefined;

  const hasRowStatus = (id: IdType, status: Status): boolean => {
    return (
      !!rowStatus &&
      rowStatus[id] &&
      rowStatus[id].status === status &&
      (rowStatus[id].date
        ? (rowStatus[id].date as number) + 5000 > Date.now()
        : true)
    );
  };

  const getCellStatus = (
    id: IdType,
    fieldName: string
  ): StatusData | undefined => {
    return (
      (rowStatus &&
        rowStatus[id] &&
        (rowStatus[id] as CellStatus)[fieldName] &&
        ((rowStatus[id] as CellStatus)[fieldName] as StatusData)) ||
      undefined
    );
  };

  const getShowMoreColumnConfig = (column: IColumn, row: IRow): IColumn => {
    const config: ShowMoreCellConfig = {
      ...column.config,
      show: showMoreDetailsVisible[`${column.id}-${row.id}`],
    };

    return {
      ...column,
      config: config,
    };
  };

  const handleCellChange = useCallback<onChangeCallback>(
    (column, row, value) => {
      if (column.type === ColumnTypes.SHOW_MORE) {
        setShowMoreDetailsVisible({
          ...showMoreDetailsVisible,
          [`${column.id}-${row.id}`]: value as boolean,
        });
      } else {
        onChange && onChange(column, row, value);
      }
    },
    [
      onChange,
      setShowMoreDetailsVisible,
      JSON.stringify(showMoreDetailsVisible),
    ]
  );

  const handleDraftCellChange = useCallback<onChangeCallback>(
    (column, row, value) => {
      onDraftChange && onDraftChange(column, row, value);
    },
    [
      onDraftChange,
      setShowMoreDetailsVisible,
      JSON.stringify(showMoreDetailsVisible),
    ]
  );

  const renderCell = (column: IColumn, index: number) => {
    const isHierarchyClass = isHierarchy && index === 0;
    const isShowMore = column.type === ColumnTypes.SHOW_MORE;

    const showOrHideColumn =
      showOrHideColumns &&
      showOrHideColumns.find((i) => i.label === column.label)?.show;

    const showOrHide = showOrHideColumn !== undefined ? showOrHideColumn : true;
    return (
      <Table.Cell
        className={classNames(
          cellContainer(column.align),
          column.config.className && isFunction(column.config.className)
            ? column.config.className(row, rows, column)
            : column.config.className,
          { hide: !showOrHide }
        )}
        key={`cell-${column.id}-${row.id}`}
        style={getHierarchicalStyle(column, index, maxLevel)}
        negative={showStatus && hasRowStatus(row.id, 'error')}
        positive={showStatus && hasRowStatus(row.id, 'success')}
        warning={showStatus && hasRowStatus(row.id, 'loading')}
      >
        <div>
          <div
            className={classNames({
              [hierarchyWrapper]: true,
              [marginHierarchyField]: isHierarchyClass,
            })}
          >
            {isHierarchyClass ? (
              <TypedHierarchyToggle
                level={level}
                isHierarchyOpen={isHierarchyOpen}
                toggleHierarchy={toggleHierarchy}
              />
            ) : null}

            <div
              className={classNames(
                'typed-table-cell',
                `typed-table-cell-type-${column.type}`,
                `typed-table-cell-${column.align}`
              )}
            >
              <TypedCell
                column={
                  isShowMore ? getShowMoreColumnConfig(column, row) : column
                }
                onChange={handleCellChange}
                onDraftChange={handleDraftCellChange}
                row={row}
                draftRow={draftRow}
                rows={rows}
                status={getCellStatus(row.id, column.field)}
              />
            </div>
          </div>
        </div>
      </Table.Cell>
    );
  };

  const isDisabled = useCallback(
    () =>
      visibleColumns.length ? isDisable(visibleColumns[0], row, rows) : false,
    [visibleColumns]
  );

  return (
    <React.Fragment key={`row-${row.id}`}>
      <Ref innerRef={(node) => preview && drop && preview(drop(node))}>
        <Table.Row
          className={rowClassName && rowClassName(row)}
          style={{ opacity }}
        >
          {visibleColumns.length > 0 && selectable && (
            <Table.Cell
              key={`cell-selectable-${row.id}`}
              style={{
                width: '40px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0px',
              }}
              negative={showStatus && hasRowStatus(row.id, 'error')}
              positive={showStatus && hasRowStatus(row.id, 'success')}
              warning={showStatus && hasRowStatus(row.id, 'loading')}
            >
              <BuCheckbox
                checked={getFieldValue<boolean>(selectable as string, row)}
                onChange={(checked) => onSelect([row], checked as boolean)}
                disabled={isDisabled()}
              />
            </Table.Cell>
          )}

          {enableReorderingRows && (
            <Table.Cell
              key={`cell-dragDrop-${row.id}`}
              style={{ width: '40px', padding: '0px' }}
              negative={showStatus && hasRowStatus(row.id, 'error')}
              positive={showStatus && hasRowStatus(row.id, 'success')}
              warning={showStatus && hasRowStatus(row.id, 'loading')}
            >
              <DragDropHandle
                ref={(node) => drag && drop && drag(drop(node))}
                className={classNames({ disabled: !isRowDraggable })}
              >
                <BuIcon name={BoostUpIcons.Drag} />
              </DragDropHandle>
            </Table.Cell>
          )}
          {visibleColumns.map(renderCell)}
        </Table.Row>
      </Ref>

      {visibleColumns
        .filter(
          (column) =>
            column.type === ColumnTypes.SHOW_MORE &&
            showMoreDetailsVisible[`${column.id}-${row.id}`]
        )
        .map((column, index) => (
          <Table.Row key={`show-more-row-${column.id}-${row.id}`}>
            <Table.Cell
              className={wrapText}
              colSpan={visibleColumns.length}
              style={getHierarchicalStyle(column, index, maxLevel)}
            >
              <div className={showMoreBox}>
                {R.pathOr('', column.field.split('.'), row)}
              </div>
            </Table.Cell>
          </Table.Row>
        ))}
    </React.Fragment>
  );
};

export default TypedRow;
