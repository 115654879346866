import React, { useMemo } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { sortAlphabetically } from 'common/helpers';
import BuIcon from 'components/UI/BuIcon';
import BuSelect from 'components/UI/BuSelect';
import { ISelectOption } from 'components/UI/BuSelect/types';
import { DropdownCalendar } from 'components/UI/OpenFiltersPanel/Dropdowns/Calendar';
import {
  ControlsContainer,
  FilterBarElement,
  PivotFiltersContainer,
} from 'components/dashboard/Metrics/Widget/Controls/styles';
import {
  BIMetricColumn,
  DateOption,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  showControls?: boolean;
  pivotName?: string;
  pointInTime?: string;
  timeInterval?: string;
  timePeriod?: string;
  widgetChartType?: string;
  groupByColumns: BIMetricColumn[];
  pointInTimeOptions: ISelectOption[];
  timeIntervalOptions: ISelectOption[];
  timeValueOptions: DateOption[];
  onPivotChange: (values: string[]) => void;
  onPointInTimeChange: (values: string[]) => void;
  onTimeIntervalChange: (values: string[]) => void;
  onTimePeriodChange: (selected: Filters.PersistValue[]) => void;
}

export const BaseHistoricalWidgetControls: React.FC<Props> = ({
  showControls = false,
  pivotName,
  pointInTime,
  timeInterval,
  timePeriod,
  widgetChartType,
  groupByColumns,
  pointInTimeOptions,
  timeIntervalOptions,
  timeValueOptions,
  onPivotChange,
  onPointInTimeChange,
  onTimeIntervalChange,
  onTimePeriodChange,
}) => {
  const pivotOptions: ISelectOption[] = useMemo(
    () =>
      sortAlphabetically(
        groupByColumns.map((column) => ({
          text: column.label,
          value: column.name,
        })),
        'none'
      ),
    [groupByColumns]
  );

  const dropDownCalendarOptions = useMemo(() => {
    return (
      timeValueOptions.map((option) => ({
        ...option,
        checked: option?.value === timePeriod,
      })) ?? []
    );
  }, [timeValueOptions, timePeriod]);

  return (
    <>
      <ControlsContainer isSpaceBetween={false} showControls={showControls}>
        <FilterBarElement>
          <BuIcon name={BoostUpIcons.Calendar} color="var(--bu-gray-700)" />
        </FilterBarElement>

        <div style={{ marginRight: '8px' }}>
          <BuSelect
            secondary
            fullWidth
            defaults={[
              timeInterval
                ? timeInterval
                : timeIntervalOptions.length
                ? timeIntervalOptions[0].value
                : '',
            ]}
            options={timeIntervalOptions}
            onChange={onTimeIntervalChange}
            inlineLabel="Time Interval"
            testingLabel="time-interval"
          />
        </div>

        <DropdownCalendar
          align="left"
          className="white"
          config={{
            allowFuture: true,
            checkedAll: false,
            checkedTotal: 0,
            title: 'Time Period',
            type: 'date',
            withShowAll: true,
            isLocked: false,
            customEnd: true,
            customStart: true,
            values: dropDownCalendarOptions,
          }}
          isProfileSettings={false}
          key="Filter"
          name="Filter"
          onChange={onTimePeriodChange}
          tab="rollupColumnConfig"
        />
      </ControlsContainer>

      <ControlsContainer isSpaceBetween={false} showControls={showControls}>
        <FilterBarElement>
          <BuIcon name={BoostUpIcons.Location} color="var(--bu-gray-700)" />
        </FilterBarElement>
        <BuSelect
          fullWidth
          secondary
          defaults={[
            pointInTime
              ? pointInTime
              : pointInTimeOptions.length
              ? pointInTimeOptions[0].value
              : '',
          ]}
          options={pointInTimeOptions}
          onChange={onPointInTimeChange}
          inlineLabel="Point-in-time"
          testingLabel="point-in-time"
        />
      </ControlsContainer>

      <ControlsContainer isSpaceBetween showControls={showControls}>
        <PivotFiltersContainer className={'notExpanded'}>
          <FilterBarElement>
            <BuIcon name={BoostUpIcons.Layers} color="var(--bu-gray-700)" />
          </FilterBarElement>

          {widgetChartType !== 'pie' && (
            <BuSelect
              defaults={pivotName ? [pivotName] : ['none']}
              secondary
              searchable
              options={pivotOptions}
              onChange={onPivotChange}
              testingLabel="pivot-name"
            />
          )}
        </PivotFiltersContainer>
      </ControlsContainer>
    </>
  );
};
