import axios from 'axios';
import { put, call, all, takeLatest } from 'redux-saga/effects';

import * as t from 'actions/actionTypes';
import {
  getTargetsSuccess,
  getTargetsFailure,
  setTargetSuccess,
  setTargetFailure,
  getTargetsByUserSuccess,
  getTargetsByUserFailure,
  getTargetsByManagerSuccess,
  getTargetsByManagerFailure,
  ITargetsPayload,
} from 'actions/targetsActions';

const getIntervalUrl = (interval: string): string =>
  interval ? `/${interval}` : '/quarterly';
const getAttributeUrl = (attribute?: string): string =>
  attribute ? `/${attribute}` : '';

function* getTargetsOverall({ interval, year, attribute }: ITargetsPayload) {
  try {
    const itervalUrl = getIntervalUrl(interval),
      attributeUrl = getAttributeUrl(attribute);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/data/target${itervalUrl}/overall${attributeUrl}`;
    const { data, status } = yield call(axios.get, url, {
      params: {
        fiscal_year: year,
      },
    });

    if (status === 200) {
      yield put(getTargetsSuccess(data.targets));
    }
  } catch (e) {
    let error = e.response.data.error.message.join(',\n');
    yield put(getTargetsFailure(error));
  }
}

function* getTargetsByUser({ interval, year, attribute }: ITargetsPayload) {
  try {
    const itervalUrl = getIntervalUrl(interval),
      attributeUrl = getAttributeUrl(attribute);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/data/target${itervalUrl}/by_user${attributeUrl}`;
    const { data, status } = yield call(axios.get, url, {
      params: {
        fiscal_year: year,
      },
    });

    if (status === 200) {
      yield put(getTargetsByUserSuccess(data.targets));
    }
  } catch (e) {
    let error = e.response.data.error.message.join(',\n');
    yield put(getTargetsByUserFailure(error));
  }
}

function* getTargetsByManager({ interval, year, attribute }: ITargetsPayload) {
  try {
    const itervalUrl = getIntervalUrl(interval),
      attributeUrl = getAttributeUrl(attribute);
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/data/target${itervalUrl}/by_manager${attributeUrl}`;

    const { data, status } = yield call(axios.get, url, {
      params: {
        fiscal_year: year,
      },
    });

    if (status === 200) {
      yield put(getTargetsByManagerSuccess(data.targets));
    }
  } catch (e) {
    let error = e.response.data.error.message.join(',\n');
    yield put(getTargetsByManagerFailure(error));
  }
}

function* setSellerTarget({ interval, targetsBy, body }: ITargetsPayload) {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/data/target/${interval}/${targetsBy}`;

    const { status } = yield call(axios.post, url, body);

    if (status === 200) {
      yield put(setTargetSuccess());
    }
  } catch (e) {
    let error = e.response.data.error.message.join(',\n');
    yield put(setTargetFailure(error));
  }
}

function* sellerTargetsWatch() {
  yield all([
    // @ts-ignore
    takeLatest(t.TARGETS + t.GET + t.REQUEST, getTargetsOverall),
    // @ts-ignore
    takeLatest(t.TARGETS + t.BY_USER + t.GET + t.REQUEST, getTargetsByUser),
    takeLatest(
      // @ts-ignore
      t.TARGETS + t.BY_MANAGER + t.GET + t.REQUEST,
      getTargetsByManager
    ),
    // @ts-ignore
    takeLatest([t.TARGETS + t.SET + t.REQUEST], setSellerTarget),
  ]);
}

export default sellerTargetsWatch;
