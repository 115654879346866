import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import {
  CELL_EMPTY_VALUE,
  SUBTOTAL,
} from 'components/dashboard/Metrics/Widget/Table/constants';
import { BIWidgetDataV2 } from 'components/dashboard/Metrics/metrics.types';

/*
 * General helpers
 */

const getValueForSorting = (row: IRow, fieldName: string): string | number => {
  const value = row[fieldName];

  if (value === '-') {
    return 0;
  }

  if (typeof value === 'string') {
    return (value as string).toLowerCase();
  }

  if (typeof value === 'number') {
    return value;
  }

  return '';
};

const addSubTotalRowsToDateFirstPivotTable = (
  pivot1FieldName: string,
  pivot2FieldName: string,
  rows: IRow[],
  widgetData: BIWidgetDataV2
) => {
  const subtotalRow: IRow = {
    id: SUBTOTAL,
    isSubtotal: true,
    [pivot2FieldName]: SUBTOTAL,
  };

  const subtotalData = widgetData?.pivot_1?.data || [];
  const metricColumns = widgetData.pivot_2?.columns?.filter((c) => !c.is_pivot);

  subtotalData.forEach((subtotal) => {
    const pivotDateValue = subtotal[pivot1FieldName];
    const objectKeys = Object.keys(subtotal);
    metricColumns?.forEach((_) => {
      objectKeys.forEach((fieldName) => {
        if (fieldName !== pivot1FieldName || fieldName !== pivot2FieldName) {
          subtotalRow[fieldName + '|' + pivotDateValue] = subtotal[fieldName];
        }
      });
    });
  });

  rows.push(subtotalRow);

  return rows;
};

const addSubTotalRowsToNonDateFirstPivotTable = (
  isPivot1Descending: boolean,
  pivot1FieldName: string,
  pivot2FieldName: string,
  sortByField: string | undefined,
  rows: IRow[],
  widgetData: BIWidgetDataV2
) => {
  const subtotalData = (widgetData?.pivot_1?.data || []).map((e) => ({
    ...e,
    isSubtotal: true,
    [pivot2FieldName]: CELL_EMPTY_VALUE, // Set ' ' so '-' will not show in the cell
  }));

  const allRows = [...(rows || []), ...subtotalData] as IRow[];

  const isDescending = sortByField?.[0] ? sortByField?.[0] === '-' : true;

  const fieldPath = (sortByField ?? '').slice(isDescending ? 1 : 0).split('.');

  // Sorting by pivot columns
  if (fieldPath[0] === pivot1FieldName || fieldPath[0] === pivot2FieldName) {
    allRows.sort((a, b) => {
      const colA1 = getValueForSorting(a, pivot1FieldName);
      const colA2 = getValueForSorting(a, pivot2FieldName);
      const colB1 = getValueForSorting(b, pivot1FieldName);
      const colB2 = getValueForSorting(b, pivot2FieldName);

      if (isDescending) {
        if (colA1 === colB1) {
          // If ' ' we put them to bottom, case for subtotal line
          if (colA2 === CELL_EMPTY_VALUE) {
            return 1;
          }

          // If ' ' we put them to bottom, case for subtotal line
          if (colB2 === CELL_EMPTY_VALUE) {
            return -1;
          }

          return colA2 < colB2 ? 1 : -1;
        } else {
          return colA1 < colB1 ? 1 : -1;
        }
      } else {
        if (colA1 === colB1) {
          // If ' ' we put them to bottom, case for subtotal line
          if (colA2 === CELL_EMPTY_VALUE) {
            return 1;
          }

          // If ' ' we put them to bottom, case for subtotal line
          if (colB2 === CELL_EMPTY_VALUE) {
            return -1;
          }

          return colA2 < colB2 ? -1 : 1;
        } else {
          return colA1 < colB1 ? -1 : 1;
        }
      }
    });

    // If it is a pivot 2 we need to reorder rows per pivot 1
    if (fieldPath[0] === pivot2FieldName) {
      allRows.sort((a, b) => {
        const colA1 = getValueForSorting(a, pivot1FieldName);
        const colB1 = getValueForSorting(b, pivot1FieldName);

        if (isPivot1Descending) {
          if (colA1 > colB1) {
            return -1;
          }
          if (colA1 < colB1) {
            return 1;
          }
          return 0;
        } else {
          if (colA1 > colB1) {
            return 1;
          }
          if (colA1 < colB1) {
            return -1;
          }
          return 0;
        }
      });
    }
  } else {
    // Sorting for metrics values
    allRows.sort((a, b) => {
      const colA1 = getValueForSorting(a, pivot1FieldName);
      const colA2 = getValueForSorting(a, fieldPath[0]);
      const colB1 = getValueForSorting(b, pivot1FieldName);
      const colB2 = getValueForSorting(b, fieldPath[0]);

      if (isDescending) {
        if (colA1 === colB1) {
          // If it is subtotal row we put them to bottom
          if (a.isSubtotal) {
            return 1;
          }

          // If it is subtotal row we put them to bottom
          if (b.isSubtotal) {
            return -1;
          }

          return colA2 < colB2 ? 1 : -1;
        } else {
          return colA1 < colB1 ? 1 : -1;
        }
      } else {
        if (colA1 === colB1) {
          // If it is subtotal row we put them to bottom
          if (a.isSubtotal) {
            return 1;
          }

          // If it is subtotal row we put them to bottom
          if (b.isSubtotal) {
            return -1;
          }

          return colA2 < colB2 ? -1 : 1;
        } else {
          return colA1 < colB1 ? -1 : 1;
        }
      }
    });

    allRows.sort((a, b) => {
      const colA1 = getValueForSorting(a, pivot1FieldName);
      const colB1 = getValueForSorting(b, pivot1FieldName);

      if (isPivot1Descending) {
        if (colA1 > colB1) {
          return -1;
        }
        if (colA1 < colB1) {
          return 1;
        }
        return 0;
      } else {
        if (colA1 > colB1) {
          return 1;
        }
        if (colA1 < colB1) {
          return -1;
        }
        return 0;
      }
    });
  }

  let currentValue = '';
  const remapedRows = allRows.map((row) => {
    if (row.isSubtotal) {
      // We put the `Subtotal` in cell value but put real value in separate field to be used later
      return {
        ...row,
        pivot1Value: row[pivot1FieldName],
        [pivot1FieldName]: SUBTOTAL,
      };
    } else {
      const fieldName = row[pivot1FieldName];

      if (currentValue === fieldName) {
        // We hide the cell value but put real value in separate field to be used later
        return {
          ...row,
          pivot1Value: row[pivot1FieldName],
          [pivot1FieldName]: CELL_EMPTY_VALUE,
        };
      } else {
        currentValue = fieldName as string;
        return row;
      }
    }
  });

  return remapedRows;
};

export const addSubTotalRows = (
  rows: IRow[],
  availablePivots: number,
  isDateFirstPivot: boolean,
  isPivot1Descending: boolean,
  sortByField: string | undefined,
  widgetData: BIWidgetDataV2
): IRow[] => {
  if (availablePivots === 1) {
    return rows;
  }

  if (availablePivots === 2) {
    const pivot1FieldName =
      widgetData?.pivot_1?.columns[widgetData?.pivot_1?.columns.length - 1]
        ?.field_name || '';
    const pivot2FieldName =
      widgetData?.pivot_2?.columns[widgetData?.pivot_2?.columns.length - 1]
        ?.field_name || '';

    if (isDateFirstPivot) {
      return addSubTotalRowsToDateFirstPivotTable(
        pivot1FieldName,
        pivot2FieldName,
        rows,
        widgetData
      );
    } else if (!isDateFirstPivot) {
      return addSubTotalRowsToNonDateFirstPivotTable(
        isPivot1Descending,
        pivot1FieldName,
        pivot2FieldName,
        sortByField,
        rows,
        widgetData
      );
    }
  }

  return rows;
};

/*
 * Date type field as first pivot section
 */

const remapSingleDatePivotRows = (
  pivot1FieldName: string,
  widgetData: BIWidgetDataV2
): IRow[] => {
  const rows: IRow[] = [];

  const metricColumns = widgetData.pivot_1?.columns?.filter((c) => !c.is_pivot);

  const row: IRow = {
    id: 'row_id',
  };

  (widgetData.pivot_1?.data || []).forEach((d) => {
    const pivotDateValue = d[pivot1FieldName];

    const objectKeys = Object.keys(d);

    metricColumns?.forEach((_) => {
      objectKeys.forEach((fieldName) => {
        if (fieldName !== pivot1FieldName) {
          row[fieldName + '|' + pivotDateValue] = d[fieldName];
        }
      });
    });
  });

  rows.push(row);

  return rows;
};

const remapDoubleDataPivotRows = (
  pivot1FieldName: string,
  widgetData: BIWidgetDataV2
): IRow[] => {
  const pivot2FieldName =
    widgetData?.pivot_2?.columns[widgetData?.pivot_2?.columns.length - 1]
      ?.field_name || '';

  const pivot2ValuesSet: Set<string> = new Set();
  (widgetData.pivot_2?.data || []).forEach((d) => {
    pivot2ValuesSet.add(d[pivot2FieldName]);
  });
  const metricColumns = widgetData.pivot_2?.columns?.filter((c) => !c.is_pivot);

  const rows: IRow[] = [];

  pivot2ValuesSet.forEach((pivotValue) => {
    const row: IRow = {
      id: pivotValue,
    };

    row[pivot2FieldName] = pivotValue;

    const currentData = (widgetData.pivot_2?.data || []).filter(
      (d) => d[pivot2FieldName] === pivotValue
    );

    (currentData || []).forEach((d) => {
      const pivotDateValue = d[pivot1FieldName];

      const objectKeys = Object.keys(d);
      metricColumns?.forEach((_) => {
        objectKeys.forEach((fieldName) => {
          if (fieldName !== pivot1FieldName || fieldName !== pivot2FieldName) {
            row[fieldName + '|' + pivotDateValue] = d[fieldName];
          }
        });
      });
    });

    rows.push(row);
  });

  return rows;
};

export const remapDatePivotRows = (
  availablePivots: number,
  widgetData: BIWidgetDataV2
): IRow[] => {
  const pivot1FieldName =
    widgetData?.pivot_1?.columns[widgetData?.pivot_1?.columns.length - 1]
      ?.field_name || '';

  if (availablePivots === 1) {
    return remapSingleDatePivotRows(pivot1FieldName, widgetData);
  }

  if (availablePivots === 2) {
    return remapDoubleDataPivotRows(pivot1FieldName, widgetData);
  }

  return [];
};

/*
 * Non Date type field as first pivot section
 */

export const getNonDatePivotRows = (
  availablePivots: number,
  widgetData: BIWidgetDataV2
): IRow[] => {
  if (availablePivots === 1) {
    return [...(widgetData?.pivot_1?.data || [])] as IRow[];
  }

  if (availablePivots === 2) {
    return [...(widgetData?.pivot_2?.data || [])] as IRow[];
  }

  return [];
};
