import React, { Dispatch } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Action } from 'typescript-fsa';

import { actions } from 'actions';
import { formatMoney, formatNumber } from 'common/numbers';
import {
  IColumn,
  IRow,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { ClickableCellConfig } from 'components/UI/common/TypedTable/renderers/ClickableCell';
import {
  BIMetrics,
  DrillDownParams,
  SyntheticMetricsValuesAsChartValue,
} from 'components/dashboard/Metrics/metrics.types';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import * as selectors from 'selectors';
import { fetchApi } from 'utils/network';

export const useTableColumnBuilder = (
  clickHandler: (id: IRow) => void,
  drilldownParams: DrillDownParams
) => {
  const columns = React.useMemo(
    () => [
      {
        id: 'variable',
        label: 'Variable',
        field: 'metricName',
        type: ColumnTypes.TEXT,
        sort_order: SortOrder.ASCENDING,
        config: {},
      },
      {
        id: 'value',
        label: 'Values',
        field: 'value',
        type: ColumnTypes.CLICKABLE,
        sort_order: SortOrder.ASCENDING,
        config: {
          click(column: IColumn, row: IRow) {
            clickHandler(row);
          },
        } as ClickableCellConfig,
        align: 'right' as 'right',
        format: 'number',
      },
    ],
    [drilldownParams, clickHandler]
  );

  return { columns };
};

export const useTableData = (
  metricValues: SyntheticMetricsValuesAsChartValue[],
  metrics: BIMetrics[],
  drilldownParams: DrillDownParams
) => {
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);

  const tableData = React.useMemo(() => {
    if (typeof metricValues !== 'undefined' && metrics.length > 0) {
      return metricValues.map((metric) => {
        const metricData = metrics.find(
          (m) => m.id === metric.id || m._id === metric.id
        );

        const newValue =
          metricData?.column?.type === 'money'
            ? formatMoney(companyCurrencyCode, metric.value as number)
            : metricData?.column?.type === 'number'
            ? formatNumber(metric.value as number)
            : metric.value;

        return {
          id: metric.id,
          value: newValue,
          metricName: metric.metricName,
          object: metricData?.object,
          drilldownParams: { ...drilldownParams, ...metricData }, // we need to pass the filters of the metric.
        };
      });
    } else {
      return [];
    }
  }, [metricValues, metrics, drilldownParams, companyCurrencyCode]);
  return { tableData };
};

export const useFetchMetrics = (
  metricValues: SyntheticMetricsValuesAsChartValue[]
) => {
  const [simpleMetricsUsed, setSimpleMetricsUsed] = React.useState<BIMetrics[]>(
    []
  );

  React.useEffect(() => {
    const simpleMetrics: BIMetrics[] = [];
    if (typeof metricValues === 'undefined') {
      return;
    }
    const metricsDataAsArray = metricValues.map(async (metric) => {
      if (typeof metric.id === 'string') {
        return await fetchApi<string, BIMetrics>({
          url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${metric.id}`,
          queryMethod: 'get',
          setData: (metricData: BIMetrics) => {
            simpleMetrics.push(metricData);
          },
          setError: (error: string | null) => {
            toast.error(
              `Failed to load metric data ${metric.metricName}: ${error}`
            );
          },
        });
      }
    });
    Promise.all(metricsDataAsArray).then(() =>
      setSimpleMetricsUsed(simpleMetrics)
    );
  }, [metricValues]);
  return { simpleMetricsUsed };
};

export const useOpenModalByMetricCallback = (
  dispatch: Dispatch<Action<PersistQueryParams>>
) => {
  const handleMetricClick = React.useCallback((row: IRow) => {
    fetchApi<any, string[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/${row.object}`,
      queryMethod: 'post',
      queryParams: row.drilldownParams,
      setData: (result) => {
        const data = result ?? [];
        if (data.length) {
          const popupTableParams = {
            apiUrl: `/api/data/${
              row.object === 'opportunity' ? 'deals' : 'accounts'
            }/`,
            context: '',
            deltaOverPeriod: 0,
            filters: JSON.stringify({
              ids: data,
              skip_business_validation: true,
            }),
            title: '',
          };
          openModal({
            scheme: row.object === 'opportunity' ? '/deals' : '/accounts',
            persistParams: popupTableParams,
            persistor: (params: PersistQueryParams) => {
              dispatch(actions.ui.modal.persist(params));
            },
          });
        }
      },
      setError: (error: string | null) => {
        toast.error('Failed to load table data');
      },
    });
  }, []);
  return { handleMetricClick };
};
