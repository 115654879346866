import { css } from 'emotion';

import { fontDefault } from 'assets/css/common';

export const optionsContainer = css`
  display: flex;

  .option-group:first-child {
    border-radius: 4px 0px 0px 4px;
    border-width: 1px 0 1px 1px;
    border-color: var(--bu-gray-400);
  }

  .option-group:last-child {
    border-radius: 0px 4px 4px 0px;
    border-width: 1px 1px 1px 0;
    border-color: var(--bu-gray-400);
  }
`;

export const itemContainerWhiteBg = css`
  .option-group {
    background: var(--bu-white);
  }
`;

export const itemContainer = css`
  ${fontDefault}
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--bu-control-height-regular);
  border-color: var(--bu-gray-400);
  border-width: 1px 0px;
  border-style: solid;
  text-align: center;
  letter-spacing: 0px;
  color: var(--bu-gray-800);
  cursor: pointer;

  &.not-icon-button {
    min-width: 80px;
    & > span {
      padding: 0 16px;
    }
  }

  & > span {
    width: 100%;
    height: 100%;
    padding: 0 8px;
    display: flex;
    align-items: center;
    border-width: 0px 1px !important;
    border: solid transparent;
  }

  & .option-group-item {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.disabled {
    background-color: var(--bu-gray-200);
    cursor: default;
    pointer-events: none;
    span {
      opacity: 0.4;
    }
  }
  &:hover {
    background-color: var(--bu-gray-200);
  }
`;

export const selectedOption = css`
  & {
    background-color: var(--bu-primary-200) !important;
    color: var(--bu-primary-600) !important;
    border-color: var(--bu-primary-500) !important;
    cursor: default;
    > span {
      border-color: var(--bu-primary-500) !important;
      border-style: solid; 
    }
    &:hover {
      background-color: var(--bu-primary-200);
    }
  }
  &.option-group:first-child {
    > span {
      border-left: transparent solid 1px !important;
    }
  }
  &.option-group:last-child {
    > span {
      border-right: transparent solid 1px !important;
    }
`;
