import { connect } from 'react-redux';

import { FILTER_NAME } from 'common/constants';
import DealsModal from 'components/modals/DealsModal/DealsModal';
import { PersistedProps, StateProps } from 'components/modals/DealsModal/types';
import { IReduxState } from 'reducers/types';
import { getFiltersForAPI, getModalProps, getModalOptions } from 'selectors';

const mapStateToProps = (state: IReduxState) => ({
  modalProps: getModalProps<PersistedProps>(state, '/deals'),
  modalOptions: getModalOptions<PersistedProps>(state, '/deals'),
  modalFilters: getFiltersForAPI(state, FILTER_NAME.ForecastOpportunitiesModal),
});

export default connect<StateProps, {}, {}, IReduxState>(mapStateToProps)(
  DealsModal
);
