import axios from 'axios';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import {
  all,
  call,
  delay,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
  race,
} from 'redux-saga/effects';

import { actions } from 'actions';
import * as t from 'actions/actionTypes';
import * as routeActions from 'actions/routeActions';
import { deletePartitionFromFieldsConfiguration } from 'actions/settingsActions';
import {
  getSettingsFiltersByPageFailure,
  getSettingsFiltersByPageSuccess,
  updateCompanySettings,
  updateSettingsFiltersFailure,
  updateSettingsFiltersSuccess,
} from 'actions/settingsActions';
import * as userProfileActions from 'actions/userProfilesActions';
import { apiUserProfile } from 'components/settings/UserProfiles/UserProfiles';
import { profileBase } from 'components/settings/UserProfiles/UserProfiles';
import { makeURL, makeModalPath } from 'navigation/utils';
import { getCompanySettingsToPersist } from 'selectors';
import { history } from 'store/configureStore';
import { fetchApiWithoutCb } from 'utils/network';

const toastOptions = { position: 'bottom-left' };

const eventToText = {
  [t.USER + t.PROFILES + t.ALL + t.GET + t.ERROR]: {
    text: (message) => `Fetching data failed: ${message}`,
    mode: 'error',
  },
  [t.USER + t.PROFILE + t.CREATE + t.ERROR]: {
    text: (message) => `Creating user profile failed: ${message}`,
    mode: 'error',
  },
  [t.USER + t.PROFILE + t.UPDATE + t.SUCCESS]: {
    text: () => 'User profile updated successfully.',
    mode: 'success',
  },
  [t.USER + t.PROFILE + t.UPDATE + t.ERROR]: {
    text: (message) => `Editing user profile failed: ${message}`,
    mode: 'error',
  },
};

function* debounceUpdateCompanySettings() {
  // debouncing for 500 milliseconds before starting the search
  yield delay(500);

  const settingsToPersist = yield select(getCompanySettingsToPersist);

  if (R.compose(R.not, R.isNil)(settingsToPersist)) {
    // only do the search if payload has any text at all
    yield put(updateCompanySettings(settingsToPersist));
  }
}

function* getAvailableFiltersByPage({ pages, tab }) {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/settings/filter_page`;
    const { data, status } = yield call(axios.post, url, { pages });

    if (status === 200) {
      yield put(getSettingsFiltersByPageSuccess(tab, data));
    }
  } catch (e) {
    let error = e.response.data.error.message.join(',\n');
    yield put(getSettingsFiltersByPageFailure(error));
  }
}

function* updateEnabledFiltersByPage({ page, tab, filters }) {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}/api/settings/enabled_filters/${page}`;
    const { data, status } = yield call(axios.post, url, { filters });

    if (status === 200) {
      yield put(updateSettingsFiltersSuccess(page, tab, data));
    }
  } catch (e) {
    let error = e.response.data.error.message.join(',\n');
    yield put(updateSettingsFiltersFailure(error));
  }
}

function* showToastGenerator(action) {
  const { text, mode } = eventToText[action.type];

  const beError = action.error?.response?.data?.error?.message;

  toast[mode](
    text(
      beError && Array.isArray(beError) && beError[0]
        ? beError[0]
        : action.error?.message
    ),
    toastOptions
  );

  if (mode === 'success') {
    history.goBack();
    yield put(actions.ui.modal.remove({ scheme: '/edit-profile/:profileId' }));
  }
}

function* openEditUserProfileModalGenerator(action) {
  const { id } = action.payload.data;
  const { location } = window;

  const to = makeURL({
    scheme: '/edit-profile/:profileId',
    params: { profileId: id || '' },
  });

  if (to) {
    yield put(
      actions.ui.modal.persist({
        scheme: '/edit-profile/:profileId',
        params: { profileId: id || '' },
        persistParams: {
          mode: 'new',
          row: action.payload.data,
        },
      })
    );

    history.push({
      ...location,
      pathname: makeModalPath(location.pathname, `~${to}`),
    });
  }
}

function* clearOnCancelGenerator(action) {
  const {
    params,
    persistParams: { mode },
  } = action.payload;

  if (mode === 'new') {
    const { profileId } = params;

    const { cancel } = yield race({
      cancel: take(actions.ui.modal.remove),
      save: take(t.USER + t.PROFILE + t.UPDATE + t.SUCCESS),
    });

    if (cancel) {
      yield put(
        deletePartitionFromFieldsConfiguration(`${profileBase}/${profileId}`)
      );

      yield call(fetchApiWithoutCb, {
        queryMethod: 'delete',
        url: `${apiUserProfile}/${profileId}`,
      });
    }
  }
}

function* updateRouterConfig(payload) {
  // TODO: check if FF changed are in payload
  yield put(routeActions.getRouterConfig());
}

function* reloadProfilesListGenerator() {
  yield put(routeActions.getRouterConfig());
  yield put(userProfileActions.getUserProfilesList());
}

function* settingsSaga() {
  yield all([
    takeLatest(t.COMPANY_SETTINGS + t.CHANGE, debounceUpdateCompanySettings),
    takeLatest(
      t.SETTINGS + t.GET + t.FILTER + t.REQUEST,
      getAvailableFiltersByPage
    ),
    takeLatest(
      t.SETTINGS + t.UPDATE + t.FILTER + t.REQUEST,
      updateEnabledFiltersByPage
    ),
    takeEvery(Object.keys(eventToText), showToastGenerator),
    takeEvery(
      t.USER + t.PROFILE + t.CREATE + t.SUCCESS,
      openEditUserProfileModalGenerator
    ),
    takeEvery(actions.ui.modal.persist, clearOnCancelGenerator),
    takeLatest(
      t.USER + t.PROFILE + t.UPDATE + t.SUCCESS,
      reloadProfilesListGenerator
    ),
    takeLatest(
      t.COMPANY_SETTINGS + t.UPDATE + t.SUCCESS,
      updateRouterConfig,
    )
  ]);
}

export default settingsSaga;
