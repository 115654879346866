import { getColumns, getRows } from './helpers';
import classNames from 'classnames';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Icon, Loader } from 'semantic-ui-react';

import {
  getTargetsByManagerRequest,
  getTargetsByUserRequest,
  getTargetsRequest,
} from 'actions/targetsActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import UploadIcon from 'assets/images/new_icon/upload_file_icon.svg';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import BuIcon from 'components/UI/BuIcon';
import Table from 'components/UI/TableConfig/Table';
import * as styles from 'components/modals/ImportTargetsModal/styles';
import { IProps } from 'components/modals/ImportTargetsModal/types';
import { Target } from 'reducers/sellerTargetsReducer/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import {
  getCompanyCurrency,
  getCurrentFiscalQuarter,
  getCurrentFiscalYear,
  getFiscalYearStartDate,
} from 'selectors';
import {
  getSellerTargetsHiddenColumns,
  getSellerTargetsOverall,
} from 'selectors/sellerTargets';
import { useDownloadFile } from 'utils/network';
import { uploadFile } from 'utils/network/uploadFile';

enum TargetsImportStages {
  Upload,
  ReviewAndUpload,
}

const metricToTextMap: { [key: string]: string } = {
  targets_forecast: 'Forecast Targets',
  new_pipeline_created: 'Pipeline Creation Targets',
  pipeline_coverage: 'Pipeline Coverate Targets',
};

const ImportTargetsModal: React.FC<IProps> = ({ onClose, params }) => {
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);
  const [isUpdateConfirmationVisible, setUpdateConfirmationVisible] =
    useState(false);
  const [isUploadConfirmationVisible, setUploadConfirmationVisible] =
    useState(false);
  const [file, setFile] = useState<File>();
  const inputFile = useRef<HTMLInputElement>(null);
  const [stage, setStage] = useState(TargetsImportStages.Upload);
  const [isValidationPending, setValidationPending] = useState(false);
  const [isUploadPending, setUploadPending] = useState(false);
  const [validationError, setValidationError] =
    useState<{
      message: string[][];
    }>();

  const [targetsByUser, setTargetsByUser] = useState<Target[]>([]);
  const [targetsByManager, setTargetsByManager] = useState<Target[]>([]);
  const businessType = decodeURIComponent(params.businessType ?? '');
  const dispatch = useDispatch();

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        if (item.files.length !== 0) {
          setFile(item.files[0]);
        }
      },
      canDrop(item: any) {
        if (item.files.length !== 0) {
          return validateExtension(item.files[0].name);
        }
        return false;
      },
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    []
  );

  const onBrowseButtonClick = () => {
    inputFile.current?.click();
  };

  const onCloseButtonClick = () => {
    if (stage === TargetsImportStages.ReviewAndUpload) {
      setConfirmationVisible(true);
    } else {
      if (onClose) {
        onClose();
      }
    }
  };

  const onConfirm = () => {
    if (onClose) {
      onClose();
    }
  };

  const { isPending, error, triggerDownload } = useDownloadFile({
    queryMethod: 'post',
    queryParams: {
      business_type: businessType,
      fiscal_year: params.year,
      period: params.interval,
      attribute:
        params.metric !== 'targets_forecast' ? params.metric : undefined,
    },
    url: `${process.env.REACT_APP_BACKEND_URL}/api/data/target/download_template`,
  });

  useEffect(() => {
    if (error === 'forbidden') {
      toast.error('User is not allowed to access resource');
    }
  }, [error]);

  const onDownloadTemplateButtonClick = useCallback(() => {
    triggerDownload();
  }, [params]);

  const validateExtension = (file: string) => {
    if (!Boolean(file)) {
      return false;
    }
    let ext = file.match(/\.([^\.]+)$/);
    return (
      Boolean(ext) && ext !== null && ext.length === 2 && ext[1] === 'xlsx'
    );
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target.files?.length !== 0) {
      setFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    const processUploadFile = async () => {
      setValidationPending(true);
      const { interval, metric, year } = params;
      if (!!file) {
        let response = await uploadFile({
          file,
          queryMethod: 'post',
          url: `${process.env.REACT_APP_BACKEND_URL}/api/data/target/template/validate`,
          params: {
            fiscal_year: year,
            period: interval,
            business_type: businessType,
            attribute: metric === 'targets_forecast' ? 'ARR' : metric,
          },
        });
        switch (response.status) {
          case 400:
            if (
              response.headers.get('content-type')?.includes('application/json')
            ) {
              const data = await response.json();
              setValidationError(data['error']);
            } else if (
              response.headers.get('content-type')?.includes('text/html')
            ) {
              response.blob().then((blob) => {
                const a = document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = 'ImportTarget.xlsx';
                a.click();

                setTimeout(() => {
                  window.URL.revokeObjectURL(a.href);
                }, 250);
              });
            }
            toast.error(
              'There are errors in multiple cells. Please check the template to correct the format.'
            );
            setValidationPending(false);
            break;
          case 200:
            setStage(TargetsImportStages.ReviewAndUpload);
            let json = await response.json();
            setTargetsByManager(json['managers']);
            setTargetsByUser(json['sellers']);
            setValidationPending(false);
            break;
        }
      }
    };
    if (!!file) {
      processUploadFile();
    }
  }, [file]);

  let targetsChanged = useMemo(() => {
    let sum = 0;
    if (Boolean(targetsByManager)) {
      let flattenedTargets = targetsByManager.map((el) => el.targets).flat();
      sum += flattenedTargets.reduce(
        (prevValue, value) =>
          !!value && value.new_target !== value.target
            ? prevValue + 1
            : prevValue,
        0
      );
    }
    if (Boolean(targetsByUser)) {
      let flattenedTargets = targetsByUser.map((el) => el.targets).flat();
      sum += flattenedTargets.reduce(
        (prevValue, value) =>
          !!value && value.new_target !== value.target
            ? prevValue + 1
            : prevValue,
        0
      );
    }
    return sum;
  }, [targetsByManager, targetsByUser]);

  const onUpdateConfirm = useCallback(async () => {
    setUpdateConfirmationVisible(false);
    setUploadPending(true);
    const { interval, metric, year } = params;
    if (!!file) {
      let response = await uploadFile({
        file,
        queryMethod: 'post',
        url: `${process.env.REACT_APP_BACKEND_URL}/api/data/target/template/upload`,
        params: {
          fiscal_year: year,
          period: interval,
          business_type: businessType,
          attribute: metric === 'targets_forecast' ? 'ARR' : metric,
        },
      });
      switch (response.status) {
        case 400:
          console.log(response);
        case 200:
        case 204:
          setUploadConfirmationVisible(true);
        default:
          setUploadPending(false);
      }
    }
  }, [file]);

  const isQuarterly = params.interval === 'quarterly';

  const [
    hiddenColumns,
    businessTypes,
    targets,
    currentFiscalQuarter,
    currentFiscalYear,
    companyCurrency,
    fiscalYearStartMonth,
  ] = useSelector(
    (state: IReduxState) => [
      getSellerTargetsHiddenColumns(state),
      selectors.getBusinessTypes(state, deals_overall_view_enabled),
      getSellerTargetsOverall(state),
      getCurrentFiscalQuarter(state),
      getCurrentFiscalYear(state),
      getCompanyCurrency(state),
      getFiscalYearStartDate(state).month,
    ],
    shallowEqual
  );

  const { deals_overall_view_enabled } = useSelector((state: IReduxState) =>
    selectors.getFeatureFlags(state)
  );

  const enabledColumns = businessTypes.filter(
    (type: string) =>
      type !== 'Overall' && hiddenColumns && !hiddenColumns.includes(type)
  );

  const { columns, extraHeader } = useMemo(
    () =>
      getColumns(
        isQuarterly,
        fiscalYearStartMonth,
        targets,
        params.year,
        enabledColumns,
        currentFiscalYear,
        currentFiscalQuarter,
        companyCurrency,
        params.metric === 'pipeline_coverage'
      ),
    [
      JSON.stringify(targets),
      JSON.stringify(enabledColumns),
      fiscalYearStartMonth,
      isQuarterly,
      params,
    ]
  );

  const rows = useMemo(
    () =>
      getRows(
        isQuarterly,
        fiscalYearStartMonth,
        targets,
        targetsByManager,
        targetsByUser,
        params.year,
        businessType
      ),
    [
      JSON.stringify(targets),
      JSON.stringify(targetsByManager),
      JSON.stringify(targetsByUser),
      params,
      isQuarterly,
      fiscalYearStartMonth,
    ]
  );

  const onUploadConfirmationClick = useCallback(() => {
    setUploadConfirmationVisible(false);
    dispatch(
      getTargetsRequest(
        params.interval,
        params.year,
        params.metric === 'targets_forecast' ? '' : params.metric
      )
    );
    dispatch(
      getTargetsByUserRequest(
        params.interval,
        params.year,
        params.metric === 'targets_forecast' ? '' : params.metric
      )
    );
    dispatch(
      getTargetsByManagerRequest(
        params.interval,
        params.year,
        params.metric === 'targets_forecast' ? '' : params.metric
      )
    );
    if (onClose) {
      onClose();
    }
  }, [params]);
  return (
    <div className={styles.fullscreen}>
      <div className={styles.header}>
        <div className={classNames(styles.headerTitle, 'bu-font-heading')}>
          Import Targets
        </div>
        <div className={styles.headerControlsContainer}>
          <div
            className={styles.stepHeader(stage === TargetsImportStages.Upload)}
          >
            <div
              className={classNames(styles.stepHeaderMark, {
                selected: stage === TargetsImportStages.Upload,
                completed: stage === TargetsImportStages.ReviewAndUpload,
              })}
            >
              {stage === TargetsImportStages.ReviewAndUpload ? (
                <Icon
                  className={classNames('check', 'icon', styles.checkIcon)}
                />
              ) : (
                1
              )}
            </div>
            Upload file
          </div>
          <div
            className={styles.stepHeader(
              stage === TargetsImportStages.ReviewAndUpload
            )}
          >
            <div
              className={classNames(styles.stepHeaderMark, {
                selected: stage === TargetsImportStages.ReviewAndUpload,
              })}
            >
              2
            </div>
            Review &amp; Import
          </div>
        </div>
        <div className={styles.closeButton_box}>
          <span className={styles.closeButton} onClick={onCloseButtonClick}>
            <BuIcon name={BoostUpIcons.ClosePopup} />
          </span>
        </div>
      </div>
      {stage === TargetsImportStages.Upload && (
        <>
          {!!validationError && (
            <styles.ErrorMessage>
              <Icon className={classNames('exclamation', 'circle')} />
              <div>
                {validationError.message[0].map((msg) => (
                  <span key={msg}>
                    {msg}
                    <br />
                  </span>
                ))}
              </div>
            </styles.ErrorMessage>
          )}
          <div className={styles.noteContainer}>
            <span>
              Note: Download the template file to fill your targets. Don`t make
              any changes to the columns. Upload the file once done.
            </span>
            <button
              className={classNames(styles.button, { disabled: isPending })}
              onClick={onDownloadTemplateButtonClick}
            >
              {isPending && (
                <Loader
                  size="tiny"
                  style={{ marginRight: '5px' }}
                  active
                  inline
                />
              )}
              {isPending ? 'Downloading template' : 'Download template'}
            </button>
          </div>
          <div ref={drop} className={styles.dropdownArea(isOver)}>
            {!isValidationPending ? (
              <>
                <img src={UploadIcon} />
                <span className={styles.dropdownLabel}>
                  Drag and drop a .xlsx file here
                </span>
                OR
                <button
                  className={styles.browseFilesButton}
                  onClick={onBrowseButtonClick}
                >
                  Browse
                </button>
                <input
                  ref={inputFile}
                  onChange={onFileChange}
                  accept=".xlsx"
                  type="file"
                  className={styles.fileInput}
                />
              </>
            ) : (
              <>
                <span className={styles.dropdownLabel}>
                  Please wait while we are uploading your file
                </span>
                <span className={styles.dropdownLabel}>
                  <b>{file?.name}</b>
                </span>
                <Loader
                  style={{ marginTop: '10px' }}
                  size="small"
                  active
                  inline
                />
              </>
            )}
          </div>
        </>
      )}
      {stage === TargetsImportStages.ReviewAndUpload && (
        <div className={styles.container}>
          <div className={styles.chipContainer}>
            <div className={styles.chipListHeading}>
              {metricToTextMap[params.metric]}
            </div>
            <div className={styles.chip}>
              <span>Business Type:</span>
              {businessType}
            </div>
            <div className={styles.chip}>
              <span>Fiscal Year:</span>
              {params.year}
            </div>
            <div className={styles.chip}>
              <span>Period:</span>
              {params.interval}
            </div>
          </div>
          <div className={styles.tableContainer}>
            <Table
              title=""
              columns={columns}
              data={rows}
              extraHeader={extraHeader.length ? extraHeader : undefined}
              rowsPerPage={0}
              currentPage={0}
              hideSearch
              hidePaginationEnd
              hidePaginationStart
              onSort={() => {}}
              fullscreen
              onRender={() => {}}
              innerScrollRef={undefined}
              columnsDnD
              rowClassName={(row) =>
                classNames({
                  topRow: row.isTopRow,
                  empty: row.isEmpty,
                  noEdit: row.disableEdit,
                })
              }
            />
          </div>
          <div className={styles.importTargetsContainer}>
            <button
              className={styles.panelButton}
              onClick={() => setStage(TargetsImportStages.Upload)}
            >
              Back to Upload Files
            </button>
            <button
              className={classNames(styles.button, { disabled: isPending })}
              onClick={() => setUpdateConfirmationVisible(true)}
            >
              {isUploadPending && (
                <Loader
                  size="tiny"
                  style={{ marginRight: '5px' }}
                  active
                  inline
                />
              )}
              Import targets
            </button>
          </div>
        </div>
      )}

      <BuConfirmationPopup
        confirmText="OK"
        headerText="Successfully Imported"
        isOpen={isUploadConfirmationVisible}
        onClose={onUploadConfirmationClick}
        onConfirm={onUploadConfirmationClick}
        onlyConfirm={true}
        icon={
          <Icon
            name="check circle"
            color="green"
            className={styles.checkModalIcon}
          />
        }
      >
        <p>
          The forecast targets have successfully imported for New Business CFY
          2022 Quarterly.
        </p>
      </BuConfirmationPopup>

      <BuConfirmationPopup
        cancelText="No"
        confirmText="Yes"
        headerText="Confirmation Required!"
        isOpen={isUpdateConfirmationVisible}
        onClose={() => setUpdateConfirmationVisible(false)}
        onConfirm={onUpdateConfirm}
      >
        <p>
          Do you want to proceed with importing the forecast targets for{' '}
          {businessType} {params.year} {params.interval}?
        </p>
        <p>
          This will override {targetsChanged} existing target
          {targetsChanged !== 1 ? 's' : ''}?
        </p>
      </BuConfirmationPopup>

      <BuConfirmationPopup
        cancelText="No"
        confirmText="Yes"
        headerText="Confirmation Required!"
        isOpen={isConfirmationVisible}
        onClose={() => setConfirmationVisible(false)}
        onConfirm={onConfirm}
      >
        <p>Unsaved data will be lost.</p>
      </BuConfirmationPopup>
    </div>
  );
};

export default ImportTargetsModal;
function getTargetsOverall(): any {
  throw new Error('Function not implemented.');
}
