import { Dispatch } from 'redux';

import { actions } from 'actions';
import {
  FORMULA_PARSER_REGEXP,
  NUMBERS_REGEXP,
} from 'components/UI/BuFormulaTextField/constants';
import { UnitType } from 'components/dashboard/Metrics/constants';
import {
  BIMetrics,
  BIWidgetDataV2,
  DrillDownParams,
  SelectedValue,
  SyntheticMetricDataWidgetState,
  SyntheticMetricsValuesAsChartValue,
} from 'components/dashboard/Metrics/metrics.types';
import { PersistParams } from 'components/modals/SyntheticMetricModal/types';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';

export const getUnits = (type: UnitType, metric?: Partial<BIMetrics>) => {
  const isSynthetic = metric && Boolean(metric.synthetic_metric);
  const isUnitsExist = metric?.properties && metric?.properties.metricUnit;
  const isTypesMatches = type === metric?.properties?.metricUnit?.type;
  if (isSynthetic && isUnitsExist && isTypesMatches) {
    return metric?.properties?.metricUnit?.unit;
  }
  return '';
};

export const getMetricNamesWithValues = (
  usedMetricsIds: string[],
  row: {
    [key: string]: any;
  },
  syntheticMetricData: SyntheticMetricDataWidgetState
): SyntheticMetricsValuesAsChartValue[] => {
  const result: SyntheticMetricsValuesAsChartValue[] = [];

  usedMetricsIds.map((metricId) => {
    for (const key in row) {
      if (key.includes(metricId)) {
        if (!~result.findIndex((el) => el.id === metricId)) {
          result.push({
            metricName:
              (syntheticMetricData.metadata &&
                syntheticMetricData.metadata[metricId]) ||
              '',
            id: metricId,
            value: row[key] as string | number,
          });
        }
      }
    }
  });

  return result;
};

export const getSyntheticMetricData = (
  parsedClickedMetricFormulaAsArray: string[],
  metricData: BIWidgetDataV2 | undefined,
  clickedMetric: BIMetrics
): SyntheticMetricDataWidgetState => {
  return {
    parsedFormula: parsedClickedMetricFormulaAsArray
      .map((formulaElement) => {
        if (
          metricData &&
          metricData.synthetic_metric_metadata &&
          formulaElement in metricData.synthetic_metric_metadata
        ) {
          return metricData.synthetic_metric_metadata[formulaElement];
        }
        return formulaElement;
      })
      .join(''),
    metadata: metricData?.synthetic_metric_metadata,
    syntheticMetric: clickedMetric,
  };
};

export const openSyntheticMetricDetailsModal = (
  title: string,
  drilldownParams: DrillDownParams,
  syntheticMetricData: SyntheticMetricDataWidgetState,
  selectedValue: SelectedValue,
  dispatch: Dispatch<any>
): void => {
  // TODO: proper check of data consistency
  if (
    syntheticMetricData.parsedFormula &&
    selectedValue.metricsValues &&
    syntheticMetricData.syntheticMetric
  ) {
    const syntheticMetricModalParams: PersistParams = {
      title: title,
      formula: syntheticMetricData.syntheticMetric?.synthetic_metric || '',
      conditions: syntheticMetricData.syntheticMetric?.filters,
      valuesByMetrics: selectedValue.metricsValues,
      drilldownParams: drilldownParams,
      metric: syntheticMetricData.syntheticMetric,
      selectedValue,
    };

    openModal({
      scheme: '/syntheticfmrl-drilldown',
      persistParams: { ...syntheticMetricModalParams },
      params: {
        title: title,
      },
      persistor: (params: PersistQueryParams) => {
        dispatch(actions.ui.modal.persist(params));
      },
    });
  }
};

export function parseFormulaToMetricsIdArray(formula: string): string[] {
  const result = [];
  let match;
  while ((match = FORMULA_PARSER_REGEXP.exec(formula)) !== null) {
    let isMetric = match[0].length > 1 && !NUMBERS_REGEXP.test(match[0]);

    if (isMetric) {
      const metricId = match[0].replace(new RegExp(/[{}]/gm), '');
      result.push(metricId);
    }
  }

  return result;
}

export const getFontSizeForMetricAmount = (
  height: number,
  width: number,
  metricsCount: number
) => {
  const baseFontSize = 6;

  const heightFactor = 0.07;
  const widthFactor = 0.01;
  const metricsFactor = 0.4;

  return (
    baseFontSize +
    Math.ceil(height * heightFactor) +
    Math.ceil(width * widthFactor) -
    Math.ceil(metricsCount * metricsFactor)
  );
};
