import {
  ACCOUNT,
  OPPORTUNITY,
  OPPORTUNITY_SPLIT,
} from 'components/dashboard/Metrics/constants';

export const SUPPORTED_DRILLDOWN_OBJECTS = [
  OPPORTUNITY,
  ACCOUNT,
  OPPORTUNITY_SPLIT,
];
export const TIME_SERIES_SUPPORTED_DRILLDOWN_OBJECTS = [OPPORTUNITY];

export const NOT_SAVED_METRIC = 'not_saved_metric';
