import { produce } from 'immer';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';
import { soqlQueryReducer as soqlQuery } from 'reducers/soqlQueryReducer';

const extractError = (error) =>
  typeof error === 'string'
    ? error
    : (error.message || [])
        .reduce((acc, item) => {
          acc.push(
            ...(typeof item === 'object'
              ? Object.keys(item).map((field) => `${field}: ${item[field]}`)
              : [item])
          );
          return acc;
        }, [])
        .join('\n');

const defaultUserSettings = {
  dial_info: {
    dial_phone_number: '',
    dial_access_code: '',
    dial_meeting_url: '',
  },
  processing: {
    calls: true,
    emails: true,
    calendar: true,
  },
  email_call_summary: true,
  sidebarOpen: false,
};

const userSettings = (state = defaultUserSettings, action) => {
  switch (action.type) {
    case t.SETTINGS + t.GET + t.SUCCESS:
      return R.mergeDeepRight(state, action.payload.data);
    case t.SETTINGS + t.CHANGE:
      return R.mergeDeepRight(state, action.payload);
    default:
      return state;
  }
};

const defaultCompanySettings = {
  isLoading: false,
  loaded: false,
  risk_categories: {},
  stages_configuration: [],
  forecast_configuration: [],
  crm_enrichment_configuration: {},
  submission_filters: {},
  timezone: 'GMT',
  forecast_roll_ups_closing_in_filters_config: {},
};

const companySettings = (state = defaultCompanySettings, action) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.GET + t.SUCCESS:
    case t.COMPANY_SETTINGS + t.UPDATE + t.SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        shared_views: {
          enabled: Boolean(
            action.payload.data.custom_views?.shared ||
              state.shared_views?.enabled
          ),
        },
        isLoading: false,
        loaded:
          action.type === t.COMPANY_SETTINGS + t.GET + t.SUCCESS
            ? true
            : state.loaded,
      };
    case t.COMPANY_SETTINGS + t.UPDATE + t.LOADING:
    case t.COMPANY_SETTINGS + t.GET + t.LOADING:
    case t.COMPANY_SETTINGS + t.FLAGS + t.UPDATE + t.LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case t.COMPANY_SETTINGS + t.UPDATE + t.ERROR:
    case t.COMPANY_SETTINGS + t.GET + t.ERROR:
    case t.COMPANY_SETTINGS + t.FLAGS + t.UPDATE + t.ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case t.COMPANY_SETTINGS + t.FLAGS + t.UPDATE + t.SUCCESS: {
      const newState = {
        ...state,
        isLoading: false,
      };

      const { flag_name, enabled } = action.payload.data;

      switch (flag_name) {
        case 'custom_views_enabled':
          return {
            ...state,
            custom_views: {
              ...state.custom_views,
              enabled,
            },
          };
        case 'shared_views_enabled':
          return {
            ...state,
            custom_views: {
              ...state.custom_views,
              shared: enabled,
            },
          };
        case 'multi_currency_enabled':
          return {
            ...newState,
            multi_currency_enabled: enabled,
          };
        case 'enable_opportunity_splits':
          return {
            ...newState,
            enable_opportunity_splits: enabled,
          };
        case 'legacy_views_enabled':
          return {
            ...state,
            custom_views: {
              ...state.custom_views,
              legacy: enabled,
            },
          };
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

const companySettingsToPersist = (state = null, action) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.CHANGE:
      return R.merge(state, action.payload);
    case t.COMPANY_SETTINGS + t.UPDATE + t.SUCCESS:
      return null;
    default:
      return state;
  }
};

const companySettingsIsActive = (state = false, action) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.GET + t.ACTIVE + t.SUCCESS:
    case t.COMPANY_SETTINGS + t.UPDATE + t.ACTIVE + t.SUCCESS:
      return action.payload.data;
    case t.COMPANY_SETTINGS + t.UPDATE + t.ACTIVE + t.LOADING:
    case t.COMPANY_SETTINGS + t.GET + t.ACTIVE + t.LOADING:
      return { ...state };
    case t.COMPANY_SETTINGS + t.UPDATE + t.ACTIVE + t.ERROR:
    case t.COMPANY_SETTINGS + t.GET + t.ACTIVE + t.ERROR:
      return false;
    default:
      return state;
  }
};

const usersLoading = (state = false, action) => {
  switch (action.type) {
    case t.SETTINGS_USERS + t.GET + t.LOADING:
      return true;
    case t.SETTINGS_USERS + t.GET + t.SUCCESS:
    case t.SETTINGS_USERS + t.GET + t.ERROR:
      return false;
    default:
      return state;
  }
};

const tableConfigurationLoading = (state = false, action) => {
  switch (action.type) {
    case t.TABLE_CONFIGURATION + t.TABLE + t.GET + t.LOADING:
      return true;
    case t.TABLE_CONFIGURATION + t.TABLE + t.GET + t.SUCCESS:
    case t.TABLE_CONFIGURATION + t.TABLE + t.GET + t.ERROR:
      return false;
    default:
      return state;
  }
};

const defaultUsersById = {};
const usersById = produce((draft, action) => {
  switch (action.type) {
    case t.SETTINGS_USERS + t.GET + t.SUCCESS:
      return R.indexBy(R.prop('id'), action.payload.data);
    case t.SETTINGS_USERS + t.REMOVE + t.SUCCESS:
      delete draft[action.id];
      break;
    case t.SETTINGS_USERS + t.UPDATE + t.SUCCESS:
      draft[action.payload.data.id] = action.payload.data;
      break;
    default:
  }
}, defaultUsersById);

const allUsersById = produce((draft, action) => {
  switch (action.type) {
    case t.SETTINGS_USERS + t.ALL + t.GET + t.SUCCESS:
      return R.indexBy(R.prop('id'), action.payload.data);
    default:
  }
}, {});

const defaultFilters = {
  name: '',
  roles: [],
  status: [],
  manager: [],
};

const usersFilters = produce((draft, action) => {
  switch (action.type) {
    case t.SETTINGS_USERS + t.FILTER:
      Object.assign(draft, action.changes);
      break;
    default:
  }
}, defaultFilters);

const deletingUser = (state = null, action) => {
  switch (action.type) {
    case t.SETTINGS_USERS + t.REMOVE + t.CANCEL:
    case t.SETTINGS_USERS + t.REMOVE + t.SUCCESS:
    case t.SETTINGS_USERS + t.REMOVE + t.ERROR:
      return null;
    default:
      return state;
  }
};

const defaultToasts = {
  message: '',
  status: '',
};
const editingUser = (state = defaultToasts, action) => {
  switch (action.type) {
    case t.SETTINGS_USERS + t.UPDATE + t.START:
      return action.id;
    case t.SETTINGS_USERS + t.UPDATE + t.CANCEL:
      return {
        message: '',
        status: '',
      };
    case t.SETTINGS_USERS + t.UPDATE + t.SUCCESS:
      return {
        message: 'User was updated successfully',
        status: 'success',
      };
    case t.SETTINGS_USERS + t.UPDATE + t.ERROR:
      return {
        message: action.message,
        status: 'error',
      };
    default:
      return state;
  }
};

const defaultTeamMembers = [];
const teamMembers = (state = defaultTeamMembers, action) => {
  switch (action.type) {
    case t.TEAM_MEMBERS + t.GET + t.SUCCESS:
    case t.TEAM_MEMBERS + t.UPDATE + t.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
};

const defaultSuggestedTeamMembers = [];
const suggestedTeamMembers = (state = defaultSuggestedTeamMembers, action) => {
  switch (action.type) {
    case t.SUGGESTED_TEAM_MEMBERS + t.GET + t.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
};

const defaultSalesforceSchema = [];
const salesforceSchema = (state = defaultSalesforceSchema, action) => {
  switch (action.type) {
    case t.SALESFORCE_SCHEMA + t.GET + t.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
};

const defaultFieldConfiguration = {
  configurations: {},
  configurationsList: [],
  configurationsStatuses: {},
  error: '',
  fieldStatuses: {},
  status: '',
};

const fieldConfiguration = (state = {}, action) => {
  const { apiBase = '/api/settings', type } = action;

  const newState =
    !apiBase || state[apiBase]
      ? { ...state }
      : { ...state, [apiBase]: defaultFieldConfiguration };

  switch (type) {
    case t.FIELD_CONFIGURATION + t.GET + t.LOADING:
      return {
        ...state,
        [apiBase]: {
          ...newState[apiBase],
          status: 'loading',
        },
      };
    case t.FIELD_CONFIGURATION + t.GET + t.SUCCESS:
      return {
        ...newState,
        [apiBase]: {
          ...newState[apiBase],
          configurationsList: action.payload,
          status: 'success',
        },
      };
    case t.FIELD_CONFIGURATION + t.GET + t.ERROR:
      return {
        ...newState,
        [apiBase]: {
          ...newState[apiBase],
          configurationsList: action.payload,
          error: action.error,
          status: 'error',
        },
      };
    case t.FIELD_CONFIGURATION + t.DETAIL + t.GET + t.LOADING:
      return {
        ...newState,
        [apiBase]: {
          ...newState[apiBase],
          configurationsStatuses: {
            ...newState[apiBase].configurationsStatuses,
            [action.configurationName]: 'loading',
          },
          fieldStatuses: {
            ...newState[apiBase].fieldStatuses,
            [action.configurationName]: {},
          },
        },
      };
    case t.FIELD_CONFIGURATION + t.DETAIL + t.GET + t.SUCCESS:
      return {
        ...newState,
        [apiBase]: {
          ...newState[apiBase],
          configurations: {
            ...newState[apiBase].configurations,
            [action.configurationName]: action.payload.data,
          },
          configurationsStatuses: {
            ...newState[apiBase].configurationsStatuses,
            [action.configurationName]: 'success',
          },
        },
      };
    case t.FIELD_CONFIGURATION_TABLES + t.DETAIL + t.GET + t.SUCCESS:
      return {
        ...newState,
        [apiBase]: {
          ...newState[apiBase],
          configurations: {
            ...newState[apiBase].configurations,
            [action.configurationName]: {
              ...newState[apiBase].configurations[action.configurationName],
              ...action.payload.data,
            },
          },
          configurationsStatuses: {
            ...newState[apiBase].configurationsStatuses,
            [action.configurationName]: 'success',
          },
        },
      };
    case t.FIELD_CONFIGURATION + t.DETAIL + t.GET + t.ERROR:
      return {
        ...newState,
        [apiBase]: {
          ...newState[apiBase],
          configurationsStatuses: {
            ...newState[apiBase].configurationsStatuses,
            [action.configurationName]: 'error',
          },
        },
      };
    case t.FIELD_CONFIGURATION + t.DETAIL + t.ADD + t.LOADING:
    case t.FIELD_CONFIGURATION + t.DETAIL + t.UPDATE + t.LOADING:
    case t.FIELD_CONFIGURATION + t.DETAIL + t.REMOVE + t.LOADING:
      return {
        ...newState,
        [apiBase]: {
          ...newState[apiBase],
          fieldStatuses: {
            ...newState[apiBase].fieldStatuses,
            [action.configurationName]: {
              ...newState[apiBase].fieldStatuses[action.configurationName],
              ...(action.field && {
                [action.field.field_name]: {
                  status: 'loading',
                  date: Date.now(),
                  value: null,
                },
              }),
            },
          },
        },
      };
    case t.FIELD_CONFIGURATION + t.DETAIL + t.ADD + t.SUCCESS:
    case t.FIELD_CONFIGURATION + t.DETAIL + t.UPDATE + t.SUCCESS:
    case t.FIELD_CONFIGURATION + t.DETAIL + t.REMOVE + t.SUCCESS:
      return {
        ...newState,
        [apiBase]: {
          ...newState[apiBase],
          configurations: {
            ...newState[apiBase].configurations,
            [action.configurationName]: action.payload.data,
          },
          fieldStatuses: {
            ...newState[apiBase].fieldStatuses,
            [action.configurationName]: {
              ...newState[apiBase].fieldStatuses[action.configurationName],
              ...(action.field && {
                [action.field.field_name]: {
                  status: 'success',
                  date: Date.now(),
                  value: null,
                },
              }),
            },
          },
        },
      };
    case t.FIELD_CONFIGURATION + t.DETAIL + t.ADD + t.ERROR:
    case t.FIELD_CONFIGURATION + t.DETAIL + t.UPDATE + t.ERROR:
    case t.FIELD_CONFIGURATION + t.DETAIL + t.REMOVE + t.ERROR:
      return {
        ...newState,
        [apiBase]: {
          ...newState[apiBase],
          fieldStatuses: {
            ...newState[apiBase].fieldStatuses,
            [action.configurationName]: {
              ...newState[apiBase].fieldStatuses[action.configurationName],
              ...(action.field && {
                [action.field.field_name]: {
                  status: 'error',
                  date: Date.now(),
                  value: null,
                },
              }),
            },
          },
        },
      };
    case t.TABLE_CONFIGURATION + '_DROP_PARTITION':
      return action.apiBase ? { ...state, [action.apiBase]: undefined } : state;
    default:
      return newState;
  }
};

const fieldConfigurationsToasts = (state = null, action) => {
  switch (action.type) {
    case t.FIELD_CONFIGURATION + t.DETAIL + t.ADD + t.SUCCESS:
      return toast.success('Field Configuration add successful.', {
        position: 'bottom-left',
      });
    case t.FIELD_CONFIGURATION + t.DETAIL + t.UPDATE + t.SUCCESS:
      return toast.success('Field Configuration update successful.', {
        position: 'bottom-left',
      });
    case t.FIELD_CONFIGURATION + t.DETAIL + t.REMOVE + t.SUCCESS:
      return toast.success('Field Configuration remove successful.', {
        position: 'bottom-left',
      });
    case t.FIELD_CONFIGURATION + t.DETAIL + t.GET + t.ERROR:
    case t.FIELD_CONFIGURATION + t.DETAIL + t.ADD + t.ERROR:
    case t.FIELD_CONFIGURATION + t.DETAIL + t.UPDATE + t.ERROR:
    case t.FIELD_CONFIGURATION + t.DETAIL + t.REMOVE + t.ERROR:
      return toast.error(extractError(action.payload.error), {
        position: 'bottom-left',
      });
    default:
      return state;
  }
};

const defaultTableConfiguration = {
  collections: {},
  collectionsStatuses: {},
  error: '',
  status: '',
};
const tableConfiguration = (state = {}, action) => {
  const { apiBase, type } = action;

  const newState =
    !apiBase || state[apiBase]
      ? { ...state }
      : { ...state, [apiBase]: defaultFieldConfiguration };

  switch (type) {
    case t.TABLE_CONFIGURATION + t.DETAIL + t.GET + t.LOADING:
      return {
        ...newState,
        [apiBase]: {
          ...newState[apiBase],
          collectionsStatuses: {
            ...newState[apiBase].collectionsStatuses,
            [action.collectionName]: 'loading',
          },
        },
      };
    case t.TABLE_CONFIGURATION + t.DETAIL + t.GET + t.SUCCESS:
      return {
        ...newState,
        [apiBase]: {
          ...newState[apiBase],
          collections: {
            ...newState[apiBase].collections,
            [action.collectionName]: action.payload.data,
          },
          collectionsStatuses: {
            ...newState[apiBase].collectionsStatuses,
            [action.collectionName]: 'success',
          },
        },
      };
    case t.TABLE_CONFIGURATION + t.DETAIL + t.GET + t.ERROR:
      return {
        ...newState,
        [apiBase]: {
          ...newState[apiBase],
          collectionsStatuses: {
            ...newState[apiBase].collectionsStatuses,
            [action.collectionName]: 'error',
          },
        },
      };
    case t.TABLE_CONFIGURATION + t.DETAIL + t.UPDATE + t.LOADING:
      return {
        ...newState,
        [apiBase]: {
          ...newState[apiBase],
          collectionsStatuses: {
            ...newState[apiBase].collectionsStatuses,
            [action.collectionName]: 'loading',
          },
        },
      };
    case t.TABLE_CONFIGURATION + t.DETAIL + t.UPDATE + t.SUCCESS:
      return {
        ...newState,
        [apiBase]: {
          ...newState[apiBase],
          collections: {
            ...newState[apiBase].collections,
            [action.collectionName]: action.payload.data,
          },
          collectionsStatuses: {
            ...newState[apiBase].collectionsStatuses,
            [action.collectionName]: 'success',
          },
        },
      };
    case t.TABLE_CONFIGURATION + t.DETAIL + t.UPDATE + t.ERROR:
      return {
        ...newState,
        [apiBase]: {
          ...newState[apiBase],
          collectionsStatuses: {
            ...newState[apiBase].collectionsStatuses,
            [action.collectionName]: 'error',
          },
        },
      };
    case t.TABLE_CONFIGURATION + '_DROP_PARTITION':
      return action.apiBase ? { ...state, [action.apiBase]: undefined } : state;
    default:
      return newState;
  }
};

const tableConfigurationsToasts = (state = null, action) => {
  switch (action.type) {
    case t.TABLE_CONFIGURATION + t.DETAIL + t.UPDATE + t.SUCCESS:
      return toast.success('Table Configuration update successful.', {
        position: 'bottom-left',
      });
    case t.TABLE_CONFIGURATION + t.DETAIL + t.GET + t.ERROR:
    case t.TABLE_CONFIGURATION + t.DETAIL + t.UPDATE + t.ERROR:
      return toast.error(extractError(action.payload.error), {
        position: 'bottom-left',
      });
    default:
      return state;
  }
};

const CRMSyncStatus = (state = { isSubmitting: false }, action) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET + t.SUCCESS:
      return R.merge(state, {
        ...action.payload,
        isSubmitting: action.payload?.status === 'running',
      });
    case t.COMPANY_SETTINGS +
      t.CRM_SYNC +
      t.FREQUENCY_TIME +
      t.SET +
      t.SUCCESS: {
      return {
        ...state,
        sync_frequency_minutes_overrides: {
          ...(state.sync_frequency_minutes_overrides || {}),
          [action.payload.table_name]:
            action.payload.new_frequency_time_minutes,
        },
      };
    }
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET + t.LOADING:
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET + t.ERROR:
      return R.merge(state, action.payload);
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.TABLE + t.SET + t.SUCCESS:
      return {
        ...state,
        stages: state.stages?.map((item) =>
          item.name === action.payload.table_name
            ? { ...item, sync_enabled: action.payload.sync_enabled }
            : item
        ),
        completed_stages: state.completed_stages?.map((item) =>
          item.name === action.payload.table_name
            ? { ...item, sync_enabled: action.payload.sync_enabled }
            : item
        ),
        default_tables: state.default_tables?.map((item) =>
          item.name === action.payload.table_name
            ? { ...item, sync_enabled: action.payload.sync_enabled }
            : item
        ),
        custom_tables: state.custom_tables?.map((item) =>
          item.name === action.payload.table_name
            ? { ...item, sync_enabled: action.payload.sync_enabled }
            : item
        ),
      };
    case t.COMPANY_SETTINGS + t.RT + t.CRM_SYNC + t.SET + t.SUCCESS: {
      return {
        ...state,
        stages: state.stages?.map((item) =>
          item.name === action.payload.table_name
            ? { ...item, rt_sync_enabled: action.payload.rt_sync_enabled }
            : item
        ),
        completed_stages: state.completed_stages?.map((item) =>
          item.name === action.payload.table_name
            ? { ...item, rt_sync_enabled: action.payload.rt_sync_enabled }
            : item
        ),
        default_tables: state.default_tables?.map((item) =>
          item.name === action.payload.table_name
            ? { ...item, rt_sync_enabled: action.payload.rt_sync_enabled }
            : item
        ),
      };
    }
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.USER + t.SET + t.SUCCESS:
      return {
        ...state,
        credential_user: action.payload.data,
      };
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.SET + t.SUCCESS:
      toast.success('Success. Please refresh page to get recent status.', {
        position: 'bottom-left',
      });
      return R.merge(state, action.payload);
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.SET + t.LOADING:
      toast.warn('Submitting CRM Sync Request.', {
        position: 'bottom-left',
      });
      return state;
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.SET + t.ERROR:
      return toast.error(
        `Error submitting sync request: ${extractError(action.payload.error)}`,
        {
          position: 'bottom-left',
        }
      );
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.SUBMIT + t.TOGGLE:
      return {
        ...state,
        isSubmitting: !state.isSubmitting,
      };
    default:
      return state;
  }
};

const CRMTestUser = (state = {}, action) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.USER + t.TEST + t.SUCCESS:
      toast.success('Testing User Successful.', {
        position: 'bottom-left',
      });
      return {
        [action.payload]: 'success',
      };
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.USER + t.TEST + t.LOADING:
      toast.warn('Started Testing User', {
        position: 'bottom-left',
      });
      return {
        [action.payload]: 'loading',
      };
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.USER + t.TEST + t.ERROR:
      toast.error('Testing User Failed', {
        position: 'bottom-left',
      });
      return {
        [action.payload]: 'error',
      };
    default:
      return state;
  }
};

const CRMSyncUsers = (state = [], action) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.USERS + t.GET + t.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
};

const crmSyncTableConditionsInitialState = {
  conditions: [],
  possibleConditions: [],
  loading: false,
};
const crmSyncTableConditions = (
  state = crmSyncTableConditionsInitialState,
  action
) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET + t.CONDITIONS + t.LOADING:
      return {
        ...state,
        loading: true,
      };
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET + t.CONDITIONS + t.SUCCESS:
      return {
        ...state,
        conditions: [...action.payload.conditions],
        possibleConditions: [...action.payload.possibleConditions],
        loading: false,
      };
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET + t.CONDITIONS + t.ERROR:
      return {
        ...state,
        loading: false,
      };
    case t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET + t.CONDITIONS + t.CLEAR:
      return {
        ...crmSyncTableConditionsInitialState,
      };
    default:
      return state;
  }
};

const defaultTableColumnConfiguration = {};
const tableColumnConfiguration = (
  state = defaultTableColumnConfiguration,
  action
) => {
  switch (action.type) {
    case t.TABLE_CONFIGURATION + t.TABLE + t.GET + t.SUCCESS:
      return {
        ...state,
        [action.collectionName]: {
          ...state[action.collectionName],
          [action.tableName]: action.payload.data,
        },
      };
    case t.TABLE_CONFIGURATION + t.TABLE + t.GET + t.LOADING:
    case t.TABLE_CONFIGURATION + t.TABLE + t.GET + t.ERROR:
    default:
      return state;
  }
};

const defaultFiltersConfiguration = {
  filters: {},
  filtersStatus: {},
  error: null,
};
const filtersConfiguration = (state = defaultFiltersConfiguration, action) => {
  switch (action.type) {
    case t.SETTINGS + t.GET + t.FILTER + t.SUCCESS:
      return {
        ...state,
        filters: action.data,
        filtersStatus: {
          [action.tab]: 'success',
        },
      };
    case t.SETTINGS + t.UPDATE + t.FILTER + t.SUCCESS:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.page]: {
            ...state.filters[action.page],
            enabled_filters: action.data,
          },
        },
        filtersStatus: {
          [action.tab]: 'success',
        },
      };
    case t.SETTINGS + t.GET + t.FILTER + t.REQUEST:
    case t.SETTINGS + t.UPDATE + t.FILTER + t.REQUEST:
      return {
        ...state,
        filtersStatus: {
          ...state.filtersStatus,
          [action.tab]: 'loading',
        },
      };
    case t.SETTINGS + t.GET + t.FILTER + t.ERROR:
    case t.SETTINGS + t.UPDATE + t.FILTER + t.ERROR:
      return {
        ...state,
        filtersStatus: {
          ...state.filtersStatus,
          [action.tab]: 'error',
        },
        error: action.error,
      };
    default:
      return state;
  }
};

const filtersConfigurationToasts = (state = null, action) => {
  switch (action.type) {
    case t.SETTINGS + t.UPDATE + t.FILTER + t.SUCCESS:
      return toast.success('Filter Configuration update successful.', {
        position: 'bottom-left',
      });
    case t.SETTINGS + t.UPDATE + t.FILTER + t.ERROR:
    case t.SETTINGS + t.GET + t.FILTER + t.ERROR:
      return toast.error(action.error, {
        position: 'bottom-left',
      });
    default:
      return state;
  }
};

const enabledFilters = (state = {}, action) => {
  switch (action.type) {
    case t.SETTINGS + t.ENABLED + t.FILTER + t.SUCCESS:
      return {
        ...state,
        filters: action.payload,
      };
    case t.SETTINGS + t.ENABLED + t.FILTER + t.ERROR:
    case t.SETTINGS + t.ENABLED + t.FILTER + t.LOADING:
    default:
      return state;
  }
};

const settingsToasts = (state = null, action) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.UPDATE + t.SUCCESS:
    case t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.SET + t.SUCCESS:
    case t.COMPANY_SETTINGS + t.FLAGS + t.UPDATE + t.SUCCESS:
      toast.success('Settings updated', {
        position: 'bottom-left',
      });

      return 'success';
    case t.COMPANY_SETTINGS + t.UPDATE + t.ERROR:
    case t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.SET + t.ERROR:
    case t.COMPANY_SETTINGS + t.FLAGS + t.UPDATE + t.ERROR:
      toast.error(
        action?.error?.response?.data?.error?.message[0] ||
          action.error.message,
        {
          position: 'bottom-left',
        }
      );

      return 'error';
    default:
      return state;
  }
};

const defaultBusinessTypesSettings = {
  enabled: false,
};
const businessTypesSettings = (
  state = defaultBusinessTypesSettings,
  action
) => {
  switch (action.type) {
    case t.BUSINESS_TYPES + t.STATUS + t.GET + t.SUCCESS:
      return {
        ...state,
        enabled: action.payload.status === 'enabled',
      };
    case t.BUSINESS_TYPES + t.SET + t.PRIMARY_FIELD + t.SUCCESS:
      return {
        ...state,
        primaryField: action.payload.business_type_field,
        availableValues: {
          ...state.availableValues,
          values: action.payload.values,
        },
      };
    case t.BUSINESS_TYPES + t.GET + t.SUCCESS:
    case t.BUSINESS_TYPES + t.RENAME + t.SUCCESS:
      return {
        ...state,
        businessTypes: action.payload.business_types,
        primaryField: action.payload.business_type_field,
        enabled: action.payload.enabled,
        availableValues: action.payload.available_values,
      };
    case t.BUSINESS_TYPES + t.SET + t.SUCCESS:
      const businessTypes = state.businessTypes || {};

      for (const prop in businessTypes) {
        if (businessTypes[prop].id === action.payload.id) {
          delete businessTypes[prop];
          break;
        }
      }
      businessTypes[action.payload.name] = {
        ...action.payload,
      };

      return {
        ...state,
        businessTypes: {
          ...businessTypes,
        },
      };
    case t.BUSINESS_TYPES + t.UPDATE + t.SORTING + t.SUCCESS:
      const currentBusinessTypes = state.businessTypes || {};
      for (const newOrderElement of action?.data) {
        if (
          currentBusinessTypes[newOrderElement?.name] &&
          newOrderElement?.order
        ) {
          currentBusinessTypes[newOrderElement.name].order =
            newOrderElement.order;
        }
      }

      return {
        ...state,
        businessTypes: {
          ...currentBusinessTypes,
        },
      };
    case t.BUSINESS_TYPES + t.REMOVE + t.SUCCESS:
      return {
        ...state,
        businessTypes: action.payload.business_types,
      };
    case t.BUSINESS_TYPES + t.GET + t.LOADING:
    case t.BUSINESS_TYPES + t.GET + t.ERROR:
    default:
      return state;
  }
};

const businessTypesAPIstatus = (state = {}, action) => {
  switch (action.type) {
    case t.BUSINESS_TYPES + t.GET + t.SUCCESS:
      return {};

    // updating business type
    case t.BUSINESS_TYPES + t.SET + t.SUCCESS:
    case t.BUSINESS_TYPES + t.UPDATE + t.SORTING + t.SUCCESS:
      toast.success('Business types update successful.', {
        position: 'bottom-left',
      });

      return {
        ...state,
        [action.data.name]: {
          status: 'success',
        },
      };
    case t.BUSINESS_TYPES + t.SET + t.ERROR:
      toast.error(
        action.payload.error.message[0].values?.join('\n') ||
          'Unexpected failure.',
        {
          position: 'bottom-left',
        }
      );

      return {
        ...state,
        [action.data.name]: {
          status: 'error',
        },
      };
    case t.BUSINESS_TYPES + t.SET + t.LOADING:
    case t.BUSINESS_TYPES + t.UPDATE + t.SORTING + t.LOADING:
      toast.warn('Business types update in progress.', {
        position: 'bottom-left',
      });

      return {
        ...state,
        [action.data.name]: {
          status: 'loading',
        },
      };

    // updating primary field
    case t.BUSINESS_TYPES + t.SET + t.PRIMARY_FIELD + t.SUCCESS:
      return toast.success('Primary CRM field updated successfully.', {
        position: 'bottom-left',
      });

    case t.BUSINESS_TYPES + t.SET + t.PRIMARY_FIELD + t.ERROR:
      return toast.error('Unexpected failure.', {
        position: 'bottom-left',
      });

    // removing business type
    case t.BUSINESS_TYPES + t.REMOVE + t.ERROR: {
      return {
        ...state,
        [action.data.name]: {
          status: 'error',
          data: {
            ...action.payload,
          },
        },
      };
    }
    case t.BUSINESS_TYPES + t.REMOVE + t.CLEAR: {
      delete state[action.data.name];
      return state;
    }
    default:
      return state;
  }
};

const businessTypesLoading = (state = false, action) => {
  switch (action.type) {
    case t.BUSINESS_TYPES + t.GET + t.LOADING:
      return true;
    case t.BUSINESS_TYPES + t.GET + t.SUCCESS:
    case t.BUSINESS_TYPES + t.GET + t.ERROR:
      return false;
    default:
      return state;
  }
};

const CRMForecastCategories = (state = [], action) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.GET + t.SUCCESS:
    case t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.SET + t.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
};

const forecastCategories = (state = [], action) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.FORECAST_CATEGORY + t.GET + t.SUCCESS:
    case t.COMPANY_SETTINGS + t.FORECAST_CATEGORY + t.SET + t.SUCCESS:
      return action.payload.data;
    default:
      return state;
  }
};

const salesProcesses = (state = [], action) => {
  switch (action.type) {
    case t.SETTINGS + t.SALES_PROCESS + t.GET + t.SUCCESS:
    case t.SETTINGS + t.SALES_PROCESS + t.UPDATE + t.SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const salesProcessConfig = (
  state = {
    custom_tables: [],
    opportunity: [],
  },
  action
) => {
  switch (action.type) {
    case t.SETTINGS + t.SALES_CONFIG + t.GET + t.SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const salesProcessLoading = (state = false, action) => {
  switch (action.type) {
    case t.SETTINGS + t.SALES_PROCESS + t.GET + t.LOADING:
    case t.SETTINGS + t.SALES_PROCESS + t.UPDATE + t.LOADING:
      return true;
    case t.SETTINGS + t.SALES_PROCESS + t.GET + t.SUCCESS:
    case t.SETTINGS + t.SALES_PROCESS + t.UPDATE + t.SUCCESS:
    case t.SETTINGS + t.SALES_PROCESS + t.GET + t.ERROR:
      return false;
    default:
      return state;
  }
};

const initSalesProcessStatuses = {
  getStatus: '',
  addStatus: '',
  updateStatus: '',
  removeStatus: '',
};

const salesProcessApiStatuses = (state = initSalesProcessStatuses, action) => {
  switch (action.type) {
    case t.SETTINGS + t.SALES_PROCESS + t.ADD + t.SUCCESS:
      toast.success('Sales Process added successfully.', {
        position: 'bottom-left',
      });

      return {
        ...initSalesProcessStatuses,
        addStatus: 'success',
      };
    case t.SETTINGS + t.SALES_PROCESS + t.UPDATE + t.SUCCESS:
      toast.success('Sales Process updated successfully.', {
        position: 'bottom-left',
      });

      return {
        ...initSalesProcessStatuses,
        updateStatus: 'success',
      };
    case t.SETTINGS + t.SALES_PROCESS + t.REMOVE + t.SUCCESS:
      toast.success('Sales Process removed successfully.', {
        position: 'bottom-left',
      });

      return {
        ...initSalesProcessStatuses,
        removeStatus: 'success',
      };
    case t.SETTINGS + t.SALES_PROCESS + t.GET + t.LOADING:
      return {
        ...initSalesProcessStatuses,
        getStatus: 'loading',
      };
    case t.SETTINGS + t.SALES_PROCESS + t.ADD + t.LOADING:
      return {
        ...initSalesProcessStatuses,
        addStatus: 'loading',
      };
    case t.SETTINGS + t.SALES_PROCESS + t.UPDATE + t.LOADING:
      return {
        ...initSalesProcessStatuses,
        updateStatus: 'loading',
      };
    case t.SETTINGS + t.SALES_PROCESS + t.REMOVE + t.LOADING:
      return {
        ...initSalesProcessStatuses,
        removeStatus: 'loading',
      };
    case t.SETTINGS + t.SALES_PROCESS + t.ADD + t.ERROR:
    case t.SETTINGS + t.SALES_PROCESS + t.UPDATE + t.ERROR:
    case t.SETTINGS + t.SALES_PROCESS + t.REMOVE + t.ERROR:
      toast.error('Unexpected failure.', {
        position: 'bottom-left',
      });

      return initSalesProcessStatuses;
    default:
      return initSalesProcessStatuses;
  }
};

const CRMForecastCategoriesLoading = (state = false, action) => {
  switch (action.type) {
    case t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.GET + t.LOADING:
    case t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.SET + t.LOADING:
      return true;
    case t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.GET + t.SUCCESS:
    case t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.SET + t.SUCCESS:
    case t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.GET + t.ERROR:
    case t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.SET + t.ERROR:
      return false;
    default:
      return state;
  }
};

const userProfilesInit = {
  list: [],
  count: 0,
  loading: false,
};

const userProfiles = (state = userProfilesInit, action) => {
  switch (action.type) {
    case t.USER + t.PROFILES + t.ALL + t.GET: {
      return {
        ...state,
        loading: true,
      };
    }
    case t.USER + t.PROFILES + t.ALL + t.GET + t.SUCCESS: {
      return {
        ...state,
        list: action.payload.data,
        count: action.payload.count,
        loading: false,
      };
    }
    case t.USER + t.PROFILE + t.CREATE:
    case t.USER + t.PROFILE + t.UPDATE: {
      return {
        ...state,
        loading: true,
      };
    }
    case t.USER + t.PROFILE + t.CREATE + t.SUCCESS:
    case t.USER + t.PROFILE + t.CREATE + t.ERROR:
    case t.USER + t.PROFILE + t.UPDATE + t.ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case t.USER + t.PROFILE + t.UPDATE + t.SUCCESS: {
      return {
        ...state,
        list: state.list.map((item) =>
          item.id === action.payload.data.id ? action.payload.data : item
        ),
        loading: false,
      };
    }
    default:
      return state;
  }
};

const submissionFilters = (state = userProfilesInit, action) => {
  switch (action.type) {
    case 'FILTERS_LOADED': {
      return {
        ...state,
        ...action.payload.filters,
      };
    }
    default:
      return state;
  }
};

const initialBusinessTypesConfigState = {
  isLoading: false,
  error: null,
  businessTypes: [],
  hasLoaded: false,
};

const userProfileDisplayConfiguration = (state = [], action) => {
  switch (action.type) {
    case t.SETTINGS +
      t.USER +
      t.PROFILE +
      t.DISPLAY +
      t.CONFIGURATION +
      t.SUCCESS:
      return action.payload;
    case t.SETTINGS +
      t.USER +
      t.PROFILE +
      t.DISPLAY +
      t.CONFIGURATION +
      t.ERROR:
      return toast.error(
        action?.error?.response?.data?.error || 'Unexpected failure.',
        {
          position: 'bottom-left',
        }
      );
    default:
      return state;
  }
};

const activityMatchingInitialState = {
  category: 'account',
  sortingKeys: {
    isLoading: false,
    error: null,
  },
  fieldsInfo: {
    isLoading: false,
    error: null,
  },
  filterPolicy: {
    isLoading: false,
    error: null,
  },
};

const activityMatching = (state = activityMatchingInitialState, action) => {
  switch (action.type) {
    case t.SETTINGS + t.ACTIVITY_MATCHING + t.SORTING_KEYS + t.GET + t.SUCCESS:
    case t.SETTINGS +
      t.ACTIVITY_MATCHING +
      t.SORTING_KEYS +
      t.SUBMIT +
      t.SUCCESS: {
      return {
        ...state,
        sortingKeys: {
          ...state.sortingKeys,
          account_sorting_keys: action.payload.data.account_sorting_keys,
          opportunity_sorting_keys:
            action.payload.data.opportunity_sorting_keys,
          isLoading: false,
          error: null,
        },
        filterPolicy: {
          ...state.filterPolicy,
          account_filter_policy: action.payload.data.account_filter_policy,
          opportunity_filter_policy:
            action.payload.data.opportunity_filter_policy,
          isLoading: false,
          error: null,
        },
      };
    }
    case t.SETTINGS + t.ACTIVITY_MATCHING + t.FIELDS_INFO + t.GET + t.SUCCESS:
    case t.SETTINGS +
      t.ACTIVITY_MATCHING +
      t.FIELDS_INFO +
      t.SUBMIT +
      t.SUCCESS: {
      return {
        ...state,
        fieldsInfo: {
          ...state.fieldsInfo,
          ...action.payload,
          isLoading: false,
          error: null,
        },
      };
    }
    case t.SETTINGS + t.ACTIVITY_MATCHING + t.SORTING_KEYS + t.GET + t.ERROR:
    case t.SETTINGS +
      t.ACTIVITY_MATCHING +
      t.SORTING_KEYS +
      t.SUBMIT +
      t.ERROR: {
      return {
        ...state,
        sortingKeys: {
          ...state.sortingKeys,
          isLoading: false,
          error: action.error,
        },
      };
    }
    case t.SETTINGS + t.ACTIVITY_MATCHING + t.FIELDS_INFO + t.GET + t.ERROR: {
      return {
        ...state,
        fieldsInfo: {
          ...state.fieldsInfo,
          isLoading: false,
          error: action.error,
        },
      };
    }
    case t.SETTINGS + t.ACTIVITY_MATCHING + t.FILTER_POLICY + t.SUBMIT: {
      return {
        ...state,
        filterPolicy: {
          ...state.filterPolicy,
          isLoading: false,
          error: action.error,
        },
      };
    }
    case t.SETTINGS + t.ACTIVITY_MATCHING + t.SORTING_KEYS + t.GET + t.LOADING:
    case t.SETTINGS +
      t.ACTIVITY_MATCHING +
      t.SORTING_KEYS +
      t.SUBMIT +
      t.LOADING: {
      return {
        ...state,
        sortingKeys: {
          ...state.sortingKeys,
          isLoading: true,
        },
        filterPolicy: {
          ...state.filterPolicy,
          isLoading: true,
        },
      };
    }
    case t.SETTINGS + t.ACTIVITY_MATCHING + t.FIELDS_INFO + t.GET + t.LOADING: {
      return {
        ...state,
        fieldsInfo: {
          ...activityMatchingInitialState.fieldsInfo,
          isLoading: true,
        },
      };
    }
    case t.SETTINGS + t.ACTIVITY_MATCHING + t.SET + t.CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case t.SETTINGS + t.ACTIVITY_MATCHING + t.SET + t.CLEAR:
      return activityMatchingInitialState;
    default:
      return state;
  }
};

// eslint-disable-next-line import/prefer-default-export
const businessTypesConfig = (
  state = initialBusinessTypesConfigState,
  action
) => {
  switch (action.type) {
    case t.BUSINESS_TYPES_CONFIG + t.GET + t.LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case t.BUSINESS_TYPES_CONFIG + t.GET + t.SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        businessTypes: action.payload,
      };
    case t.BUSINESS_TYPES_CONFIG + t.GET + t.ERROR:
      return {
        ...state,
        isLoading: false,
        hasLoaded: true,
        error: action.error,
      };
    case t.BUSINESS_TYPES_CONFIG + t.CLEAR:
      return {
        ...initialBusinessTypesConfigState,
      };
    default:
      return state;
  }
};

export const settingsReducer = combineReducers({
  CRMForecastCategories,
  CRMForecastCategoriesLoading,
  CRMSyncStatus,
  CRMSyncUsers,
  CRMTestUser,
  allUsersById,
  businessTypesAPIstatus,
  businessTypesLoading,
  businessTypesSettings,
  businessTypesConfig,
  companySettings,
  companySettingsToPersist,
  companySettingsIsActive,
  deletingUser,
  editingUser,
  enabledFilters,
  fieldConfiguration,
  fieldConfigurationsToasts,
  filtersConfiguration,
  filtersConfigurationToasts,
  forecastCategories,
  salesProcesses,
  salesProcessConfig,
  salesProcessLoading,
  salesProcessApiStatuses,
  salesforceSchema,
  settingsToasts,
  suggestedTeamMembers,
  tableColumnConfiguration,
  tableConfiguration,
  tableConfigurationsToasts,
  teamMembers,
  userProfiles,
  userSettings,
  usersById,
  usersFilters,
  usersLoading,
  submissionFilters,
  soqlQuery,
  tableConfigurationLoading,
  crmSyncTableConditions,
  userProfileDisplayConfiguration,
  activityMatching,
});
