import { css } from 'emotion';
import { isEmpty } from 'ramda';
import React, { useEffect } from 'react';

import BusinessTypesPanel from 'components/UI/BusinessTypesPanel';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import SellerQuotaAttainmentWidget from 'components/dashboard/ForecastDashboard/SellerQuotaAttainmentWidget';
import TrackingDashboard from 'components/dashboard/ForecastDashboard/TrackingDashboard';
import Widget3 from 'components/dashboard/ForecastDashboard/Widget3';
import * as styles from 'components/dashboard/ForecastDashboard/styles';
import { IProps } from 'components/dashboard/ForecastDashboard/types';
import Tabs from 'components/dashboard/Tabs';
import { VirtualizedItem } from 'components/virtualized/VirtualizedItem';

const stickyTop = css`
  position: sticky;
  top: 52px;
  background: var(--bu-white);
  z-index: 989;
`;

const ForecastDashboard: React.FC<IProps> = ({
  selectedBusinessType,
  filters,
  tabPartition = 'forecast',
  isQuotaAttainmentChartEnabled,
  isRequiredDataReady,
}) => {
  useEffect(() => {
    AnalyticsTracker.event(
      {},
      {
        action: 'Open',
        category: 'Forecast',
        label: 'Dashboard page',
      }
    );
  }, []);

  return (
    <div className="container">
      <div className="container-dashboard">
        <Tabs partition={tabPartition} wrapped />
        <div className={stickyTop}>
          <OpenFiltersPanel tab="forecast_analytics" />
        </div>

        <BusinessTypesPanel tab="forecast_analytics" />

        <div className={styles.wrapper}>
          {isRequiredDataReady &&
            !isEmpty(filters) &&
            [
              <TrackingDashboard selectedBusinessType={selectedBusinessType} />,
              <Widget3 selectedBusinessType={selectedBusinessType} />,
              isQuotaAttainmentChartEnabled && (
                <SellerQuotaAttainmentWidget
                  selectedBusinessType={selectedBusinessType}
                />
              ),
            ].map((El, index) => (
              <VirtualizedItem key={index} withClone>
                {El}
              </VirtualizedItem>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ForecastDashboard;
