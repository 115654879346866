import classNames from 'classnames';
import React, {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { Popup, PopupProps } from 'semantic-ui-react';

import * as s from 'components/UI/common/TypedTable/styles';

interface IProps {
  tooltip?: ReactNode;
  wrap?: boolean;
  disable?: boolean;
  position?: PopupProps['position'];
  hoverable?: boolean;
  showOnTruncatedOnly?: boolean;
  noPadding?: boolean;
  tooltipClassName?: string;
  popupClassName?: string;
  leftOffset?: number;
  bottomOffset?: number;
}

const TooltipWrapper = ({
  children,
  tooltip,
  wrap = false,
  disable = false,
  hoverable,
  position = 'bottom left',
  showOnTruncatedOnly = false,
  noPadding = false,
  tooltipClassName,
  popupClassName,
  leftOffset = 0,
  bottomOffset = 0,
}: PropsWithChildren<IProps>) => {
  const [isVisible, setVisible] = useState(false);

  const handleOver = useCallback(
    ({ target, type }: React.SyntheticEvent<EventTarget>) => {
      if (target instanceof HTMLDivElement) {
        const { clientWidth, scrollWidth } = target;

        if (clientWidth < scrollWidth) {
          setVisible(type === 'mouseenter');
        }
      }
    },
    [setVisible]
  );

  if (!tooltip) {
    return <>{children}</>;
  }

  return (
    <Popup
      className={classNames(
        s.zIndexIfModal,
        { [s.resetPadding]: noPadding },
        popupClassName
      )}
      trigger={
        wrap || showOnTruncatedOnly ? (
          <div
            className={classNames(s.fluid, tooltipClassName, {
              [s.textOverflow]: showOnTruncatedOnly,
            })}
            onMouseEnter={handleOver}
            onMouseLeave={handleOver}
          >
            {children}
          </div>
        ) : (
          children
        )
      }
      open={showOnTruncatedOnly ? isVisible : undefined}
      hoverable={hoverable}
      flowing={hoverable}
      disabled={disable}
      position={position}
      mouseEnterDelay={500}
      offset={[leftOffset, bottomOffset]}
      content={tooltip}
    />
  );
};

export default TooltipWrapper;
