import classNames from 'classnames';
import Highcharts from 'highcharts';
import moment from 'moment';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import {
  PipelineStagesColumns,
  PipelineStagesResponse,
  StageTransfer,
} from 'actions/pipelineStagesAction';
import { CHANGE_INTERVAL_OPTIONS } from 'common/constants';
import { SeriesSankeyOrderedNodesOptionsObject } from 'common/highcharts-mixins/highcharts-sankey-node-order';
import { SankeyStackLabelsOptions } from 'common/highcharts-mixins/highcharts-sankey-support-stacking-totals';
import { TooltipWithClickableAnchors } from 'common/highcharts-mixins/highcharts-tooltip-clickable-anchors';
import { formatAmount } from 'common/numbers';
import {
  IColumn,
  IRow,
  SortOrder,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import ForecastPipelineStageCell from 'components/dashboard/ForecastPipelineStage/ForecastPipelineStageCell';
import {
  TooltipAmountLink,
  TooltipContainer,
  TooltipHeader,
} from 'components/dashboard/ForecastPipelineStage/styles';
import {
  CustomDictionary,
  ForecastCategory,
  isSankeyLink,
  isSankeyNode,
  SankeyLink,
  SankeyNode,
  SequenceConfig,
  ForecastPipelineStageColumn,
  ForecastPipelineStageCellConfig,
  ForecastPipelineRow,
  StageTransferSentiment,
  GroupedTransfer,
} from 'components/dashboard/ForecastPipelineStage/types';
import { StageConfig } from 'components/settings/StageConfiguration/types';
import { PipelineStagesState } from 'reducers/pipelineStagesReducer';

export const STARTING_VALUE_FIELD = 'starting_value';

type ChartNodePoint = {
  id: string;
  name: string;
  color: string;
};

type NodeKeyNameType = PipelineStagesColumns;

export const StagesColors = [
  '#FFD07E',
  '#F7B917',
  '#DAC913',
  '#96D5E8',
  '#58BCDA',
  '#B3C828',
  '#CDBA96',
];

const StageWonColor = '#41EC8E';
const StageLostColor = '#FC8077';
const MinValueForWeight = 0.001; // We use this value instead of 0 to display a thin line.

function trimText(text: string, threshold: number) {
  if (text.length <= threshold) return text;
  return text.substr(0, threshold).concat('\u2026');
}

export const sortStageSequence = (stages: StageConfig[]): SequenceConfig[] => {
  const activeAndInactiveStages: StageConfig[] = [];
  const wonStages: StageConfig[] = [];
  const lostStages: StageConfig[] = [];

  stages
    .sort((a, b) => a.sequence - b.sequence)
    .forEach((stage) => {
      (stage.kind === 'active' || stage.kind === 'inactive') &&
        activeAndInactiveStages.push(stage);
      stage.kind === 'won' && wonStages.push(stage);
      stage.kind === 'lost' && lostStages.push(stage);
    });

  return [
    ...activeAndInactiveStages.map((stage) => ({
      id: stage.stage,
      name: stage.display_name,
    })),
    ...wonStages.map((stage) => ({
      id: stage.stage,
      name: stage.display_name,
      sentiment: 'positive',
    })),
    ...lostStages.map((stage) => ({
      id: stage.stage,
      name: stage.display_name,
      sentiment: 'negative',
    })),
  ];
};

export const sortForecastCategorySequence = (
  categories: ForecastCategory[]
): SequenceConfig[] =>
  categories
    .sort((a, b) => a.sequence - b.sequence)
    .map((category) => ({
      id: category.display_name,
      name: category.display_name,
    }));

export const getSequenceColor = (sequence: SequenceConfig, index: number) => {
  switch (sequence.sentiment) {
    case 'positive':
      return StageWonColor;
    case 'negative':
      return StageLostColor;
    default:
      return StagesColors[index % StagesColors.length];
  }
};

export const mapSequenceToPoints = (
  sequence: SequenceConfig[]
): ChartNodePoint[] =>
  sequence.map((s, index) => {
    return {
      id: s.id,
      name: s.name,
      color: getSequenceColor(s, index),
    };
  });

/**
 * Builds main Highcharts Sankey Chart series data, it return links (connections) between sankey nodes
 */
export const buildLinkData = (
  nodePoints: ChartNodePoint[],
  fromNodeKeyName: NodeKeyNameType,
  toNodeKeyName: NodeKeyNameType,
  response: PipelineStagesState
) => {
  const pointIds = nodePoints.map((point) => point.id);

  const orderLinkToNotOverLap = (a: StageTransfer, b: StageTransfer) =>
    pointIds.indexOf(a.to) - pointIds.indexOf(b.to);

  const showLinksOnlyForRegisteredNodes = (item: StageTransfer) =>
    pointIds.includes(item.from) && pointIds.includes(item.to);

  return response.data
    .filter(showLinksOnlyForRegisteredNodes)
    .sort(orderLinkToNotOverLap)
    .map<Highcharts.SeriesSankeyPointOptionsObject>((item, index, list) => {
      const sumOfAmount = list.reduce(
        (acc, elem) => acc + (elem.from === item.from ? elem.amount : 0),
        0
      );

      return {
        id: `${item.from}-${item.to}`,
        from: `${item.from}-${fromNodeKeyName}`,
        to: `${item.to}-${toNodeKeyName}`,
        // To support negative values we need to use absolute value for weight
        // And save original value in custom property
        weight: Math.abs(item.amount) || MinValueForWeight,
        custom: {
          deals: item.weight,
          percentage: sumOfAmount
            ? Math.round((item.amount / sumOfAmount) * 100)
            : 100,
          amount: item.amount,
          filters: {
            ids: item.ids,
          },
        } as CustomDictionary,
      };
    });
};

/**
 * Builds Highcharts Sankey Chart Node Points map, useful for keeping correct point configuration like point order,
 * colors, extra data for opening popup after clicking link in tooltip
 */
export const buildNodesMap = (
  nodeStages: ChartNodePoint[],
  columnIndex: Exclude<
    Highcharts.SeriesSankeyNodesOptionsObject['column'],
    void
  >,
  nodeKeyName: NodeKeyNameType,
  dataLabelsPosition: Highcharts.AlignValue,
  data: PipelineStagesState
) =>
  nodeStages.map<SeriesSankeyOrderedNodesOptionsObject>((item, index) => {
    const transfers = data.data.filter((elem) => elem[nodeKeyName] === item.id);

    return {
      id: `${item.id}-${nodeKeyName}`,
      color: item.color,
      name: item.name,
      column: columnIndex,
      dataLabels: {
        allowOverlap: false,
        backgroundColor: undefined,
        color: '#000',
        position: dataLabelsPosition,
      },
      custom: {
        deals: transfers.reduce((acc, elem) => acc + elem.weight, 0),
        percentage: 100,
        filters: {
          ids: transfers.map((item) => item.ids).flat(),
        },
        total: data.totals?.[nodeKeyName]?.[item.id] ?? 0,
      } as CustomDictionary,
      order: index + 1,
    };
  });

type ChartConfigProps = {
  data: PipelineStagesState;
  points: ChartNodePoint[];
  companyCurrency: string;
  onClickLink(
    point: SankeyNode<CustomDictionary> | SankeyLink<CustomDictionary>
  ): void;
};

/**
 *  builds Highcharts Sankey Chart config
 */
export const getChartConfig = ({
  data,
  points,
  companyCurrency,
  onClickLink,
}: ChartConfigProps): Highcharts.Options => {
  const cf = currencyFormatter(companyCurrency, 0);
  const cfShort = (value: number) => formatAmount(value, companyCurrency);

  return {
    chart: {
      marginTop: 10,
      marginBottom: 50,
      marginLeft: 180,
      marginRight: 180,
      height: 500,
    },
    title: {
      text: '',
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      onAnchorClick(e) {
        e.preventDefault();
        onClickLink(
          this as SankeyLink<CustomDictionary> | SankeyNode<CustomDictionary>
        );
      },
      style: {
        pointerEvents: 'auto',
      },
      backgroundColor: '#fff',
      formatter() {
        if (isSankeyNode<CustomDictionary>(this.point)) {
          const point = this.point,
            name = point.name,
            dealsCount = point.custom.deals,
            amount = this.point.custom.total;

          return renderToStaticMarkup(
            <TooltipContainer>
              <TooltipHeader>{name}</TooltipHeader>
              <TooltipAmountLink href="/opportunities">
                {cf(amount)} {`(${dealsCount} Deals)`}
              </TooltipAmountLink>
            </TooltipContainer>
          );
        }

        if (
          isSankeyLink<CustomDictionary>(this.point) &&
          (this.point as any).graphic?.opacity > 0
        ) {
          const name = this.point.toNode.name,
            dealsCount = this.point.custom.deals,
            amount = this.point.custom.amount ?? 0;

          return renderToStaticMarkup(
            <TooltipContainer>
              <TooltipHeader>Conversion to {name}</TooltipHeader>
              <TooltipAmountLink href="/opportunities">
                {cf(amount)} {`(${dealsCount} Deals)`}
              </TooltipAmountLink>
            </TooltipContainer>
          );
        }

        return false;
      },
    } as TooltipWithClickableAnchors,
    plotOptions: {
      sankey: {
        stackLabels: {
          enabled: true,
          style: {
            textOutline: '',
            fontFamily: 'var(--bu-font-regular)',
            fontSize: '12px',
            fontWeight: '',
          },
          formatter() {
            const column = this;
            const nodes = column as unknown as SankeyNode<CustomDictionary>[];

            const [total, deals] = nodes.reduce(
              (acc, node) => [
                acc[0] + node.custom?.total,
                acc[1] + node.custom?.deals,
              ],
              [0, 0]
            );

            return renderToStaticMarkup(
              <>
                <tspan style={{ fontWeight: 'bold', fontSize: '14px' }}>
                  {column.name}
                </tspan>
                <tspan x="0" y="30" fill="#737373">
                  {cfShort(total)} {`(${deals} Deals)`}
                </tspan>
              </>
            );
          },
          position: 'bottom',
        } as SankeyStackLabelsOptions,
        cursor: 'pointer',
        nodeWidth: 50,
        allowPointSelect: true,
        selected: false,
        nodePadding: 10,
        linkOpacity: 0.5,
        dataLabels: {
          allowOverlap: true,
          formatter() {
            const point = this.point as SankeyLink<CustomDictionary>;
            return `&nbsp;${point.custom.percentage}%&emsp;${cfShort(
              point.custom.amount ?? 0
            )} (${point.custom.deals})&nbsp;&nbsp;&nbsp;`;
          },
          nodeFormatter() {
            const point = this.point as SankeyNode<CustomDictionary>;
            const sumDeals = point.custom?.deals;

            return renderToStaticMarkup(
              <>
                <b>{trimText(point.name, 20)}</b>
                <br />
                {cfShort(point.custom?.total)} ({sumDeals})
              </>
            );
          },
          backgroundColor: '#000',
          borderRadius: 4,
          color: '#fff',
          padding: 1,
          style: {
            textOutline: '',
            fontFamily: 'var(--bu-font-regular)',
            fontSize: '12px',
            fontWeight: '',
          },
        },
        point: {
          events: {
            click() {
              const point = this;
              if (isSankeyLink<CustomDictionary>(point)) {
                onClickLink(point);
              }
            },
            select(e) {
              const point = this;
              if (isSankeyLink<CustomDictionary>(point)) {
                e.preventDefault();
              }

              if (isSankeyNode<CustomDictionary>(point)) {
                const nodes = (
                  this.series as unknown as {
                    nodes: SankeyNode<CustomDictionary>[];
                  }
                ).nodes;

                nodes?.forEach((item) => {
                  if (point.id !== item.id) {
                    item.selected = false;
                  }
                });

                nodes?.forEach((item) => {
                  item.setState(item.state, true);
                });
              }
            },
          },
        },
        tooltip: {
          followPointer: false,
          distance: 10,
        },
        states: {
          hover: {
            enabled: true,
            linkOpacity: 1,
          },
          normal: {
            color: 'var(--bu-gray-300)',
          },
          inactive: {
            enabled: true,
            linkOpacity: 0.5,
            color: 'var(--bu-gray-300)',
          },
          select: {
            enabled: true,
            linkOpacity: 1,
            color: undefined,
          },
        },
      },
    },
    series: [
      {
        minLinkWidth: 1,
        data: buildLinkData(points, 'from', 'to', data),
        columns: [data.from, data.to],
        nodes: [
          ...buildNodesMap(points, 0, 'from', 'left', data),
          ...buildNodesMap(points, 1, 'to', 'right', data),
        ],
        type: 'sankey',
      } as Highcharts.SeriesSankeyOptions,
    ],
  } as Highcharts.Options;
};

export const generateDealsProgressionTable = (
  pipelineStages: PipelineStagesState,
  sortedSequence: Array<SequenceConfig>,
  timeSpanKey: string,
  dataTypeLabel: string,
  onCellClick: (title: string, ids: string[]) => void
): {
  rows: IRow[];
  columns: IColumn[];
  topHeaders: IColumn[];
} => {
  const groupedSequencesByFrom = groupSequencesByFrom(
    pipelineStages,
    sortedSequence
  );

  const sequencesWithTransfersSorted = getSequencesWithTransfersSorted(
    sortedSequence,
    pipelineStages
  );

  const { from: fromDate, to: toDate, totals } = pipelineStages;

  const transfersToColumns = createTransferToColumns(
    sequencesWithTransfersSorted
  );
  const fromColumn: IColumn = createFromColumn(dataTypeLabel, fromDate);
  const startingAmountColumn = createStartingValuesColumn();
  const endingAmountColumns = createEndingValuesColumn();

  const transferRows = createRows(
    groupedSequencesByFrom,
    sequencesWithTransfersSorted,
    totals
  );

  const clickableColumns: ForecastPipelineStageColumn[] = [
    startingAmountColumn,
    ...transfersToColumns,
    endingAmountColumns,
  ].map((col) => ({
    ...col,
    onAmountClick: onCellClick,
  }));

  const totalsRow = createTotalsRow(clickableColumns, transferRows);

  const columns = [fromColumn, ...clickableColumns];

  const topHeaders = [
    getFromTopHeader(timeSpanKey),
    getToTopHeader(dataTypeLabel, toDate, columns.length - 2),
  ];

  const rows = [...transferRows, ...totalsRow];
  return {
    rows,
    columns,
    topHeaders,
  };
};

const groupSequencesByFrom = (
  pipelineStages: PipelineStagesState,
  sortedSequence: SequenceConfig[]
): GroupedTransfer[] =>
  sortedSequence
    .map((sequence) => {
      const sequenceTransfers = pipelineStages.data.filter(
        (sequenceTransfer) => sequenceTransfer.from === sequence.name
      );
      if (!sequenceTransfers.length) {
        return null;
      }
      return {
        from: sequence.name,
        transfers: sequenceTransfers,
      };
    })
    .filter((sequence) => sequence !== null) as GroupedTransfer[];

function getSequencesWithTransfersSorted(
  sortedSequence: SequenceConfig[],
  pipelineStages: PipelineStagesResponse
) {
  return sortedSequence.filter((sequence) =>
    pipelineStages.data.some(
      (sequenceTransfer) => sequenceTransfer.to === sequence.name
    )
  );
}

const createTransferToColumns = (
  sequence: SequenceConfig[]
): ForecastPipelineStageColumn[] => sequence.map(createTransferToColumn);

const createTransferToColumn = (
  sequence: SequenceConfig,
  index: number
): ForecastPipelineStageColumn => ({
  id: sequence.name,
  field: sequence.name,
  label: sequence.name,
  type: ColumnTypes.CUSTOM,
  config: {
    renderer: ForecastPipelineStageCell,

    className: (row: IRow) =>
      classNames(
        row.rowType === 'totalRow' && 'totalRow',
        isSentimentRow(row) && 'totalSentimentRow',
        index === 0 && 'section-delimiter'
      ),
  } as ForecastPipelineStageCellConfig,
  sort_order: SortOrder.ASCENDING,
  columnType: 'transfer',
  align: 'right',
});

const createStartingValuesColumn = (): ForecastPipelineStageColumn => ({
  id: STARTING_VALUE_FIELD,
  field: STARTING_VALUE_FIELD,
  label: 'Starting Values',
  type: ColumnTypes.CUSTOM,
  config: {
    renderer: ForecastPipelineStageCell,
    className: (row: IRow) =>
      classNames(
        row.rowType === 'totalRow' && 'totalRow',
        isSentimentRow(row) && 'totalSentimentRow'
      ),
  } as ForecastPipelineStageCellConfig,
  sort_order: SortOrder.ASCENDING,
  columnType: 'startingValue',
  align: 'right',
});

const createEndingValuesColumn = (): ForecastPipelineStageColumn => ({
  id: 'ending_value',
  field: 'ending_value',
  label: 'Ending Values',
  type: ColumnTypes.CUSTOM,
  config: {
    renderer: ForecastPipelineStageCell,
    className: (row: IRow) =>
      classNames(
        row.rowType === 'totalRow' && 'totalRow',
        isSentimentRow(row) && 'totalSentimentRow',
        'section-delimiter'
      ),
  } as ForecastPipelineStageCellConfig,
  sort_order: SortOrder.ASCENDING,
  columnType: 'endingValue',
  align: 'right',
});

function createFromColumn(dataTypeLabel: string, fromDate: string): IColumn {
  return {
    id: 'from',
    field: 'from',
    label: `${dataTypeLabel} (${fromDate})`,
    type: ColumnTypes.TEXT,
    config: {
      className: (row: IRow) =>
        classNames(
          row.rowType === 'totalRow' && 'totalRow',
          isSentimentRow(row) && 'totalSentimentRow'
        ),
    },

    sort_order: SortOrder.ASCENDING,
    align: 'left',
  };
}

const isSentimentRow = (row: IRow): boolean =>
  row.rowType === 'progressionRow' || row.rowType === 'regressionRow';

const createRows = (
  sequencesGroupedByFrom: GroupedTransfer[],
  sequenceWithTransfersSorted: SequenceConfig[],
  totals: PipelineStagesState['totals']
): IRow[] =>
  sequencesGroupedByFrom.map(({ from, transfers }) =>
    createRow(from, transfers, sequenceWithTransfersSorted, totals)
  );

const createRow = (
  fromSequenceName: string,
  sequenceTransferData: StageTransfer[] | undefined,
  sequenceWithTransfersSorted: SequenceConfig[],
  pipelineTotals: PipelineStagesState['totals']
): ForecastPipelineRow =>
  // Create an object to store the data for each stage transfer in this row
  sequenceWithTransfersSorted.reduce<ForecastPipelineRow>(
    (acc, toSequence) => {
      const toSequenceName = toSequence.name;
      // Find the stage transfer object for this stage transfer
      const sequenceTransfer = sequenceTransferData?.find(
        ({ to }) => to === toSequenceName
      );

      if (sequenceTransfer) {
        return {
          ...acc,
          [toSequenceName]: sequenceTransfer.amount,
          [`${toSequenceName}_ids`]: sequenceTransfer.ids,
          [`${toSequenceName}_sentiment`]: getChangeSentiment(
            fromSequenceName,
            toSequence,
            sequenceWithTransfersSorted
          ),
          [`${toSequenceName}_percentage`]:
            getTransferPercentageFromInitialAmount(sequenceTransfer, acc),

          // Backend doesn't send the IDs for the starting value, it only send the transfers, so we need to add the IDs from the stage transfer to the starting value
          starting_value_ids: [
            ...(acc.starting_value_ids as string[]),
            ...sequenceTransfer.ids,
          ],
        };
      }

      // If there is no stage transfer, set the amount and IDs for this stage to 0 and an empty array, respectively
      return {
        ...acc,
        [toSequenceName]: 0,
        [`${toSequenceName}_ids`]: [],
      };
    },
    {
      id: fromSequenceName,
      from: fromSequenceName,
      starting_value: pipelineTotals.from[fromSequenceName],
      starting_value_ids: [] as string[],
      rowType: 'sequenceRow',
      ending_value: 0,
      ending_value_ids: [],
      ending_value_sentiment: 'neutral',
    }
  );

const getChangeSentiment = (
  sequenceFrom: string,
  sequenceTo: SequenceConfig,
  sortedSequence: SequenceConfig[]
): StageTransferSentiment => {
  const fromIndex = sortedSequence.findIndex((s) => sequenceFrom === s.name);
  const toIndex = sortedSequence.findIndex((s) => sequenceTo.name === s.name);

  const isSequenceAlwaysPositive = sequenceTo.sentiment === 'positive';
  const isSequenceAlwaysNegative = sequenceTo.sentiment === 'negative';

  // The order of cases in this switch statement is important because a sequence can be
  // marked as always positive or always negative, which should
  // be prioritized over the order-based logic (fromIndex and toIndex comparisons).
  // If a sequence is always positive or always negative, the sentiment should be set
  // based on that property, regardless of the index positions of the sequences.
  switch (true) {
    case isSequenceAlwaysPositive:
      return 'positive';
    case isSequenceAlwaysNegative:
      return 'negative';
    case fromIndex < toIndex:
      return 'positive';
    case fromIndex > toIndex:
      return 'negative';
    default:
      return 'neutral';
  }
};

const getTransferPercentageFromInitialAmount = (
  sequenceTransfer: StageTransfer,
  acc: ForecastPipelineRow
): number => (sequenceTransfer.amount / acc.starting_value) * 100;

const createTotalsRow = (
  sequenceWithTransfers: IColumn[],
  rows: IRow[]
): [ForecastPipelineRow, ForecastPipelineRow, ForecastPipelineRow] => {
  const totalRowBase: ForecastPipelineRow = {
    id: 'totals',
    from: 'Total',
    rowType: 'totalRow',
    isTotalRow: true,
    starting_value: 0,
    starting_value_ids: [],
    ending_value: 0,
    ending_value_ids: [],
    ending_value_sentiment: 'neutral',
  };

  const positiveRowBase: ForecastPipelineRow = {
    id: 'positive_totals',
    from: 'Total Progression',
    rowType: 'progressionRow',
    isSentimentTotalRow: true,
    starting_value: 0,
    starting_value_ids: [],
    ending_value: 0,
    ending_value_ids: [],
    ending_value_sentiment: 'positive',
  };

  const negativeRowBase: ForecastPipelineRow = {
    id: 'negative_totals',
    from: 'Total Regression',
    rowType: 'regressionRow',
    starting_value: 0,
    starting_value_ids: [],
    ending_value: 0,
    ending_value_ids: [],
    ending_value_sentiment: 'negative',
  };

  const [totalRow, positiveRow, negativeRow] = sequenceWithTransfers.reduce(
    ([totalRow, positiveRow, negativeRow], sequence) => {
      const isEndingColumn = sequence.name === 'ending_value';
      // If we are in the ending column, we don't need to aggregate anything, is not a sequence column
      if (!isEndingColumn) {
        rows.forEach((row) => {
          // Extract amount and ids from current sequence and aggregate un accumulator
          const fieldValue = (row[sequence.field] as number) ?? 0;
          const fieldIds = (row[`${sequence.field}_ids`] as string[]) ?? [];

          // Aggregate amount and ids in total row
          aggregateSequenceInfoToRow(
            totalRow,
            sequence.field,
            fieldValue,
            fieldIds
          );

          // Extract sentiment to know if we need to aggregate in positive or negative row
          const fieldSentiment = row[
            `${sequence.field}_sentiment`
          ] as StageTransferSentiment;

          if (fieldSentiment === 'positive') {
            // If positive aggregate amount and ids in positive row
            aggregateSequenceInfoToRow(
              positiveRow,
              sequence.field,
              fieldValue,
              fieldIds
            );

            // and aggregate to positive ending value
            aggregateSequenceInfoToRow(
              positiveRow,
              'ending_value',
              fieldValue,
              fieldIds
            );
          } else if (fieldSentiment === 'negative') {
            // If negative
            // aggregate amount and ids in negative row

            aggregateSequenceInfoToRow(
              negativeRow,
              sequence.field,
              fieldValue,
              fieldIds
            );

            // and aggregate to negative ending value
            aggregateSequenceInfoToRow(
              negativeRow,
              'ending_value',
              fieldValue,
              fieldIds
            );
          }
        });
      }

      return [totalRow, positiveRow, negativeRow];
    },
    [totalRowBase, positiveRowBase, negativeRowBase]
  );

  // Calculate sentiment rows precentages in relation to total row for each sequence
  // SIDE EFFECT: This function will mutate the sentiment rows
  sequenceWithTransfers.forEach((sequence) => {
    const isEndingColumn = sequence.name === 'ending_value';
    const totalSequenceAmount = totalRow[sequence.field] as number;
    if (!isEndingColumn && totalSequenceAmount) {
      const positiveSequenceAmount = positiveRow[sequence.field] as number;
      const negativeSequenceAmount = negativeRow[sequence.field] as number;

      if (positiveSequenceAmount) {
        const positiveSequencePercentage =
          (positiveSequenceAmount / totalSequenceAmount) * 100;
        positiveRow[`${sequence.field}_percentage`] =
          positiveSequencePercentage;
      }

      if (negativeSequenceAmount) {
        const negativeSequencePercentage =
          (negativeSequenceAmount / totalSequenceAmount) * 100;
        negativeRow[`${sequence.field}_percentage`] =
          negativeSequencePercentage;
      }
    }
  });

  return [totalRow, positiveRow, negativeRow];
};

// SIDE EFFECT: this function will mutate row param to aggregate amount and ids in a row
// Will aggregate on the first parameter
const aggregateSequenceInfoToRow = (
  row: ForecastPipelineRow,
  sequenceName: string,
  sequenceAmount: number,
  sequenceIds: string[]
): void => {
  row[sequenceName] = safelyAddNumberValueType(
    row[sequenceName],
    sequenceAmount
  );

  row[`${sequenceName}_ids`] = safelyMergeArrayValueType(
    row[`${sequenceName}_ids`],
    sequenceIds
  );
};

const safelyAddNumberValueType = (
  valueType: ValueProp | undefined,
  amount: number
): number => {
  const safeNumber = typeof valueType === 'number' ? valueType : 0;
  return safeNumber + amount;
};

const safelyMergeArrayValueType = (
  valueType: ValueProp | undefined,
  array: string[]
): string[] => {
  const safeArray = Array.isArray(valueType) ? (valueType as string[]) : [];
  return [...safeArray, ...array];
};

const getFromTopHeader = (timeSpanKey: string) => ({
  id: 'fromTopHeader',
  label: `Deals Progression ${
    CHANGE_INTERVAL_OPTIONS[timeSpanKey]
      ? `in the ${CHANGE_INTERVAL_OPTIONS[timeSpanKey]}`
      : `since ${moment(timeSpanKey.split(',')[0]).format('MM/DD/YYYY')}`
  }`,
  field: '',
  type: ColumnTypes.TEXT,
  config: {},
  colSpan: 2,
  sort_order: SortOrder.ASCENDING,
});

const getToTopHeader = (
  dataTypeLabel: string,
  toDate: string,
  columnsToSpanTo: number
) => ({
  id: 'toTopHeader',
  field: '',
  label: `${dataTypeLabel} (${toDate})`,
  type: ColumnTypes.TEXT,
  config: {
    className: 'section-delimiter',
  },
  colSpan: columnsToSpanTo,
  sort_order: SortOrder.ASCENDING,
});
