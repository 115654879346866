import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import { AnalysisTypeSelector } from 'components/dashboard/Metrics/Create/AnalysisTypes/AnalysisTypeSelector/AnalysisTypeSelector';
import {
  AnalysisTypeContent,
  AnalysisTypeTitle,
} from 'components/dashboard/Metrics/Create/AnalysisTypes/WidgetAnalysisTypes/styles';
import {
  AnalysisTypeSelectContainer,
  AnalysisTypeSelectDescription,
} from 'components/dashboard/Metrics/Create/AnalysisTypes/common.styles';
import { OptionSections } from 'components/dashboard/Metrics/Create/constants';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';

interface Props {
  selectedAnalysisType: AnalysisType;
  disabled: boolean;
  onChangeAnalysisType: (analysisType: AnalysisType) => void;
  onContinueButtonClick: (nextSection: OptionSections) => void;
}

export const WidgetAnalysisTypes: React.FC<Props> = ({
  selectedAnalysisType,
  disabled,
  onChangeAnalysisType,
  onContinueButtonClick,
}) => (
  <>
    <FlexRow
      key={'analysis_types'}
      cssProps={{ flexDirection: 'column', gap: '24px' }}
    >
      <div>
        <AnalysisTypeTitle>
          <BuIcon name={BoostUpIcons.ColumnGraphBox} />
          <span>Metrics</span>
        </AnalysisTypeTitle>
        <AnalysisTypeContent data-testing="Metric-Analysis-Type-Content-section">
          <AnalysisTypeSelectDescription>
            Analyse one or more metrics over pivots of any field using different
            chart options or in a table format. Choose between Live and
            Historical data:
          </AnalysisTypeSelectDescription>
          <AnalysisTypeSelectContainer>
            <AnalysisTypeSelector
              analysisType={AnalysisType.LIVE}
              selectedAnalysisType={selectedAnalysisType}
              description="Current data available"
              disabled={disabled}
              onChangeAnalysisType={onChangeAnalysisType}
            />
            <AnalysisTypeSelector
              analysisType={AnalysisType.HISTORICAL}
              selectedAnalysisType={selectedAnalysisType}
              description=" Snapshots of data recorded"
              disabled={disabled}
              onChangeAnalysisType={onChangeAnalysisType}
            />
          </AnalysisTypeSelectContainer>
          <div>
            <BuButton
              secondary
              onClick={() => {
                onContinueButtonClick(OptionSections.METRIC);
              }}
              disabled={disabled}
              testingLabel="Metric"
            >
              Continue
            </BuButton>
          </div>
        </AnalysisTypeContent>
      </div>
      <div>
        <AnalysisTypeTitle>
          <BuIcon name={BoostUpIcons.NavPipeline} />
          <span>Funnel</span>
        </AnalysisTypeTitle>
        <AnalysisTypeContent>
          <AnalysisTypeSelectDescription>
            Analyze how your data is converting through a sequence of stages
            using a funnel visualization
          </AnalysisTypeSelectDescription>
          <div>
            <BuButton
              secondary
              onClick={() => {
                !disabled && onChangeAnalysisType(AnalysisType.FUNNEL);
                onContinueButtonClick &&
                  onContinueButtonClick(OptionSections.FUNNEL);
              }}
              disabled={disabled}
              testingLabel="Funnel"
            >
              Continue
            </BuButton>
          </div>
        </AnalysisTypeContent>
      </div>
      <div>
        <AnalysisTypeTitle>
          <BuIcon name={BoostUpIcons.FileDocument} />
          <span>Report</span>
        </AnalysisTypeTitle>
        <AnalysisTypeContent data-testing="Report-Analysis-Type-Content-section">
          <AnalysisTypeSelectDescription>
            Analyse a list of records that fit specific criteria by using
            filters, ordering and number of items shown in a table format
          </AnalysisTypeSelectDescription>
          <div>
            <BuButton
              secondary
              onClick={() => {
                !disabled && onChangeAnalysisType(AnalysisType.REPORT);
                onContinueButtonClick &&
                  onContinueButtonClick(OptionSections.METRIC);
              }}
              disabled={disabled}
              testingLabel="Report"
            >
              Continue
            </BuButton>
          </div>
        </AnalysisTypeContent>
      </div>
    </FlexRow>
  </>
);
