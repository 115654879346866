import React, { Dispatch, SetStateAction } from 'react';

import { BaseTemplateFilter } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/BaseTemplateFilter';
import {
  BIMetricColumn,
  BIMetricsFilter,
  BIWidget,
  DateOption,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  isDateFirstPivot?: boolean;
  widgetFilter: BIMetricColumn;
  templateFilters: BIMetricsFilter[];
  relativeDateFilterOptions: DateOption[];
  timeInterval?: string;
  timePeriod?: string;
  targetPeriod?: string;
  setWidget: Dispatch<SetStateAction<Partial<BIWidget>>>;
}

export const TemplateFilter: React.FC<Props> = ({
  isDateFirstPivot,
  widgetFilter,
  templateFilters,
  relativeDateFilterOptions,
  timeInterval,
  timePeriod,
  targetPeriod,
  setWidget,
}) => {
  const handleUpdateFilters = (filters: BIMetricsFilter[]): void => {
    setWidget((prev) => ({
      ...prev,
      template_filters: filters,
    }));
  };

  return (
    <BaseTemplateFilter
      isDateFirstPivot={isDateFirstPivot}
      widgetFilter={widgetFilter}
      timeInterval={timeInterval}
      timePeriod={timePeriod}
      targetPeriod={targetPeriod}
      templateFilters={templateFilters}
      relativeDateFilterOptions={relativeDateFilterOptions}
      onUpdateFilters={handleUpdateFilters}
    />
  );
};
