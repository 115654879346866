import { sortBy } from 'lodash';

import { IReduxState } from 'reducers/types';

export const getForecastSubmissionSettingsById = (
  state: IReduxState,
  submissionSettingId: string
) =>
  state.forecastSubmission.settings.find(
    (item) => item.id === submissionSettingId
  );

export const getForecastSubmissionSettings = (state: IReduxState) =>
  sortBy(state.forecastSubmission.settings, 'order');

export const getForecastSubmissionProfilesDeadlines = (state: IReduxState) =>
  state.forecastSubmission.profilesDeadlines;

export const isForecastSubmissionHistoryByUserLoading = (state: IReduxState) =>
  state.forecastSubmission.isHistoryByUserLoading;

export const getForecastSubmissionHistoryByUser = (
  state: IReduxState,
  userId: string,
  businessTypeName: string | undefined,
  forecastName: string | undefined,
  closeDateInterval: string | undefined,
  periodDelta: number | undefined,
  periodType: string | undefined
) => {
  const key = `${userId}.${businessTypeName}.${forecastName}.${closeDateInterval}.${periodType}.${periodDelta}`;
  return state.forecastSubmission.historyByUser[key] || [];
};
