import { startsWith } from 'lodash';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'actions';
import * as t from 'actions/actionTypes';
import {
  forecastSubmissionsSettingsAvailableSuccessAction,
  forecastSubmissionsSettingsAvailableLoadingAction,
  forecastSettingsDealsGetSuccessAction,
  forecastSettingsDealsGetLoadingAction,
  forecastSettingHistoryGetSuccessAction,
  forecastSettingHistoryGetLoadingAction,
  forecastSubmissionByPeriodToCopySuccessAction,
  forecastSubmissionByPeriodToCopyLoadingAction,
  forecastSubmissionsCreateErrorAction,
  forecastSubmissionsCreateLoadingsAction,
  forecastSubmissionsCreateSuccessAction,
  forecastUserSubmissionHistoryErrorAction,
  forecastUserSubmissionHistoryLoadingAction,
  forecastUserSubmissionHistorySuccessAction,
} from 'actions/forecastActions';
import {
  ForecastSubmissionSettingReduxState,
  ForecastSubmissionSettingResponse,
  ForecastSubmissionSettingHistoryByUserReduxState,
} from 'common/types/forecastSubmission';

const initialState = {
  activeSettingsId: null,
  dealsPanel: null,
  historyByUser: {},
  historyPanel: null,
  isHistoryByUserLoading: false,
  openedSettings: [],
  persisted: {},
  deadline: null,
  lastSubmissionDate: null,
  profilesDeadlines: [],
  settings: [],
  populated: false,
};

const clearOnRouteChangeState = {
  activeSettingsId: null,
  dealsPanel: null,
  historyPanel: null,
  openedSettings: [],
};

export interface ForecastSubmissionReduxState {
  settings: ForecastSubmissionSettingReduxState;
  isLoading?: boolean;
  profilesDeadlines: ForecastSubmissionSettingResponse['profiles_deadlines'];
  isHistoryByUserLoading: boolean;
  openedSettings: string[];
  populated: boolean;
  activeSettingsId: string | null;
  historyByUser: ForecastSubmissionSettingHistoryByUserReduxState;
  lastSubmissionDate: string | null;
  deadline: string | null;
  // Next type only to handle old not typed state.
  // Please add types and do not use this one
  [key: string]: any;
}

const forecastSubmission =
  reducerWithInitialState<ForecastSubmissionReduxState>(initialState)
    .case(
      forecastSubmissionsSettingsAvailableSuccessAction,
      (
        state,
        {
          deadline,
          data,
          last_submission_date: lastSubmissionDate,
          profiles_deadlines: profilesDeadlines,
        }
      ) => ({
        ...state,
        isLoading: false,
        populated: true,
        deadline,
        lastSubmissionDate,
        profilesDeadlines,
        settings: data.map((item) => ({
          ...item,
          history: [],
          historyStatus: 'NOT_ASKED',
          dealsStatus: 'NOT_ASKED',
          persist: null,
          submissionsToCopy: [],
          submissionsToCopyStatus: 'NOT_ASKED',
        })),
      })
    )
    .case(forecastSubmissionsSettingsAvailableLoadingAction, (state) => ({
      ...state,
      isLoading: true,
    }))
    .case(forecastSettingsDealsGetLoadingAction, (state, payload) => ({
      ...state,
      settings: state.settings.map((item) => ({
        ...item,
        dealsStatus:
          payload.submissionSettingId === item.id
            ? 'LOADING'
            : item.dealsStatus,
      })),
    }))
    .case(forecastSettingsDealsGetSuccessAction, (state, payload) => ({
      ...state,
      settings: state.settings.map((item) => {
        const isSameDealId = item.id === payload.submissionSettingId;
        const deals = isSameDealId ? payload.deals : item.deals;
        const dealsStatus = isSameDealId ? 'SUCCESS' : item.dealsStatus;
        return {
          ...item,
          deals,
          dealsStatus,
        };
      }),
    }))
    .case(forecastSettingHistoryGetLoadingAction, (state, payload) => ({
      ...state,
      settings: state.settings.map((item) => ({
        ...item,
        historyStatus:
          payload.submissionSettingId === item.id
            ? 'LOADING'
            : item.historyStatus,
      })),
    }))
    .case(forecastSettingHistoryGetSuccessAction, (state, payload) => ({
      ...state,
      settings: state.settings.map((item) => {
        const isSameDealId = item.id === payload.submissionSettingId;
        const history = isSameDealId ? payload.historyItems : item.history;
        const historyStatus = isSameDealId ? 'SUCCESS' : item.historyStatus;
        return {
          ...item,
          history,
          historyStatus,
        };
      }),
    }))
    .case(forecastSubmissionByPeriodToCopyLoadingAction, (state, payload) => ({
      ...state,
      settings: state.settings.map((item) => ({
        ...item,
        submissionsToCopyStatus:
          payload.submissionSettingId === item.id
            ? 'LOADING'
            : item.submissionsToCopyStatus,
      })),
    }))
    .case(forecastSubmissionByPeriodToCopySuccessAction, (state, payload) => ({
      ...state,
      settings: state.settings.map((item) => {
        const isSameDealId = item.id === payload.submissionSettingId;
        const submissionsToCopy = isSameDealId
          ? payload.submissionsToCopy
          : item.submissionsToCopy;

        const submissionsToCopyStatus = isSameDealId
          ? 'SUCCESS'
          : item.submissionsToCopyStatus;
        return {
          ...item,
          submissionsToCopy,
          submissionsToCopyStatus,
        };
      }),
    }))
    .case(forecastSubmissionsCreateLoadingsAction, (state, payload) => ({
      ...state,
      persisted: {
        ...state.persisted,
        [payload.submissionSettingId]: {
          ...(state.persisted[payload.submissionSettingId] || {}),
          status: 'loading',
          message: '',
        },
      },
    }))
    .case(forecastSubmissionsCreateErrorAction, (state, payload) => ({
      ...state,
      persisted: {
        ...state.persisted,
        [payload.submissionSettingId]: {
          ...(state.persisted[payload.submissionSettingId] || {}),
          status: 'error',
          message: payload.error,
        },
      },
    }))
    .case(forecastSubmissionsCreateSuccessAction, (state, payload) => ({
      ...state,
      persisted: {
        ...state.persisted,
        [payload.submissionSettingId]: {
          amount: '',
          notes: '',
          status: 'success',
          message: '',
        },
      },
      settings: state.settings.map((item) => ({
        ...item,
        history:
          item.id === payload.submissionSettingId
            ? [payload.submissionCreated, ...item.history]
            : item.history,
      })),
    }))
    .case(forecastUserSubmissionHistorySuccessAction, (state, payload) => {
      const {
        userId,
        closeDateInterval,
        forecastName,
        businessTypeName,
        periodDelta,
        periodType,
        data: submissionHistory,
      } = payload;
      const key = `${userId}.${businessTypeName}.${forecastName}.${closeDateInterval}.${periodType}.${periodDelta}`;
      return {
        ...state,
        historyByUser: {
          ...state.historyByUser,
          [key]: submissionHistory,
        },
        isHistoryByUserLoading: false,
      };
    })
    .case(forecastUserSubmissionHistoryErrorAction, (state) => ({
      ...state,
      isHistoryByUserLoading: false,
    }))
    .case(forecastUserSubmissionHistoryLoadingAction, (state) => ({
      ...state,
      isHistoryByUserLoading: true,
    }))
    .case(actions.ui.manageForecast.clear, (state, payload) => ({
      ...state,
      persisted: {
        ...state.persisted,
        [payload.settingsId]: {
          ...(state.persisted[payload.settingsId] || {}),
          status: '',
          message: '',
        },
      },
    }))
    .case(actions.ui.manageForecast.persist, (state, payload) => ({
      ...state,
      persisted: {
        ...state.persisted,
        [payload.settingsId]: {
          ...(state.persisted[payload.settingsId] || {
            status: '',
            message: '',
          }),
          amount: payload.amount,
          notes: payload.notes,
        },
      },
    }))
    .case(actions.ui.manageForecast.setHistoryPanel, (state, payload) => ({
      ...state,
      historyPanel: payload,
    }))
    .case(actions.ui.manageForecast.setOpenedSettings, (state, payload) => ({
      ...state,
      openedSettings: payload,
    }))
    .case(actions.ui.manageForecast.setActiveSettingsId, (state, payload) => ({
      ...state,
      activeSettingsId: payload,
    }))
    .case(
      actions.ui.manageForecast.setActiveBusinessType,
      (state, payload) => ({
        ...state,
        activeBusinessType: payload,
      })
    )
    .case(actions.ui.manageForecast.clearOnRouteChange, (state) => ({
      ...state,
      ...clearOnRouteChangeState,
    }))
    .case(actions.ui.manageForecast.resetSubmissionSettings, (state) => ({
      ...state,
      settings: [],
      populated: false,
    }))
    // Non typed actions, do not add actions here
    .default((state, action: any) => {
      switch (action.type) {
        case t.FORECAST + t.MANAGE + t.USER + t.INCLUDED_DEALS + t.CLEAR:
          return {
            ...state,
            historyByUser: {},
          };
        case t.FORECAST +
          t.MANAGE +
          t.USER +
          t.INCLUDED_DEALS +
          t.USER +
          t.CLEAR: {
          const { user_id, business_type_name, forecast_name } = action.payload;

          const key = `${user_id}.${business_type_name}.${forecast_name}.`;
          const userKeys = Object.keys(state.historyByUser);

          userKeys.forEach((k) => {
            if (startsWith(k, key)) {
              state.historyByUser[k] = [];
            }
          });

          return {
            ...state,
          };
        }
        case t.DEALS + t.UPDATE + t.SUCCESS: {
          if (action.payload?.changes?.amount === undefined) {
            return state;
          }

          return {
            ...state,
            settings: state.settings.map((item: any) => ({
              ...item,
              deals: {
                ...(item.deals || {}),
                deals: (item.deals?.deals || []).map((deal: any) => {
                  if (deal._id !== action.payload.data.id) {
                    return deal;
                  }

                  return {
                    ...deal,
                    amount: action.payload?.changes?.amount,
                  };
                }),
              },
            })),
          };
        }
        case t.FORECAST + t.REFRESH: {
          return { ...state, historyByUser: {} };
        }
        default:
          return state;
      }
    });

export default forecastSubmission;
