import * as R from 'ramda';
import { createSelector } from 'reselect';

export const getCallDetail = (state) => state.transcript.callDetail;
export const getPlayingState = (state) => state.transcript.isPlaying;
export const getPlaybackRate = (state) => state.transcript.playbackRate;
export const getActiveTranscriptTab = (state) =>
  state.transcript.isTranscriptionOpen;
export const getFilteredSnippets = (state) => state.transcript.filteredSnippets;
export const getFormattedFilteredSnippets = (state) =>
  state.transcript.filteredSnippets.map((s) => s.start_time);
export const getSelectedSnippet = (state) => state.transcript.selectedSnippet;
export const getSelectedTopics = (state) => state.transcript.selectedTopics;
export const getCurrentTimeOffset = (state) =>
  state.transcript.currentTimeOffset;
export const getCallSnippets = (state) =>
  state.transcript.callDetail && state.transcript.callDetail.segments;
export const getSelectedGroupType = (state) =>
  state.transcript.selectedGroupType;
export const getSelectedGroup = (state) => state.transcript.selectedGroup;
export const getSearchText = (state) => state.transcript.searchText;
export const getSearchIndex = (state) => state.transcript.searchIndex;
export const getActiveTopic = (state) => state.transcript.activeTopic;
export const getTranscriptStatus = (state) => state.transcript.status;
export const getTranscriptSearchTerms = createSelector(
  [getSearchText],
  (searchText) => {
    return !R.isEmpty(searchText) ? [searchText] : [];
  }
);
