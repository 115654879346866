import { css } from 'emotion';

import { fontDefault, fontSubtitle } from 'assets/css/common';

export const panel_wrapper = css`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const panel_dropdown = css`
  position: absolute;
  margin-top: 35px;
  flex-direction: column;
  border: 1px solid var(--bu-gray-400);
  overflow: hidden;
  min-width: 100%;
  width: max-content;
  border-radius: 4px;
  display: flex;
  z-index: 991;
  background: #fff;
  box-shadow: 0px 22px 24px #00000033;
  width: 440px !important;
`;

export const panel_dropdown_button = css`
  display: flex;
  position: relative;
  box-sizing: border-box;
  height: 32px;
  padding: 5px 8px;
  border: 1px solid var(--bu-gray-500);
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--bu-gray-200);
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  transition: 0.4s all;
  color: var(--bu-gray-900);
  background-color: var(--bu-white);

  &.isChanged {
    border: 1px solid #41b8d9;
    background-color: #f5fdfd;
  }

  &.isLocked {
    cursor: default;
    opacity: 0.6;
  }

  &.isOpen {
    border: 1px solid #41b8d9 !important;
  }

  &:hover {
    background: rgba(49, 92, 121, 0.12);
  }
`;

export const panel_dropdown_name = css`
  ${fontSubtitle};
  margin-right: 5px;
`;

export const panel_dropdown_value = css`
  ${fontDefault};
`;

export const operator_value = css`
  ${fontDefault};
  text-transform: capitalize;
  margin-right: 4px;
`;

export const icon = css`
  font-size: 7px;
  margin-left: 5px;
`;

export const operator_section = css`
  padding: 10px 24px;
  border-bottom: 1px solid var(--bu-gray-300);
`;
