import styled from '@emotion/styled';
import React from 'react';

import {
  CustomComponentFunction,
  getFieldValue,
} from 'components/UI/common/TypedTable/renderers/custom/common';
import { RowSubmission } from 'components/dashboard/CROOverride/types';

const Cell = styled.div`
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;

type SummarizeChildrenProps = {
  sumChildField: MapPaths.Paths<Pick<RowSubmission, 'submissions'>>;
  currencyFormatter(value: number): string;
};
const summarizeChildrenCell: CustomComponentFunction<SummarizeChildrenProps> =
  ({ currencyFormatter, sumChildField }) =>
  ({ row }) => {
    const fieldName = sumChildField.join('.');
    const childrenRows = row.children || [];

    const sum = childrenRows
      .map<number>((row) => getFieldValue(fieldName, row))
      .reduce((acc, item) => acc + item, 0);

    return <Cell>{currencyFormatter(sum ?? 0)}</Cell>;
  };

export default summarizeChildrenCell;
