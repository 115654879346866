import styled from '@emotion/styled';
import { css } from 'emotion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { formatMoney } from 'common/numbers';
import BuIcon from 'components/UI/BuIcon';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import {
  getBusinessTypes,
  getUserLocalCurrency,
  getFeatureFlags,
  getForecastActiveBusinessType,
  isOppSplitEnabled,
  OVERALL_BUSINESS_TYPE,
} from 'selectors';
import { fetchApi, QueryStatus } from 'utils/network';

interface SplitFieldResp {
  original_amount: number;
  splits: {
    split_amount: number;
    split_percentage: number;
    split_type_name?: string;
    user: { name: string };
  }[];
}
interface SplitDataResp extends Record<string, SplitFieldResp> {}

interface Props {
  readonly dealId: string;
  readonly fieldName: string;
}

const IconContainer = styled.div({
  width: '19px',
  height: '19px',
  borderRadius: '10px',
  backgroundColor: '#fff',
  position: 'relative',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:before': {
    width: '19px',
    height: '19px',
    position: 'absolute',
    display: 'block',
    backgroundColor: `var(--bu-primary-500)`,
    content: `' '`,
    opacity: 0.3,
    borderRadius: '10px',
  },
  '> i': {
    fontSize: '9px',
  },
});

const TooltipContainer = styled.div({
  minWidth: '470px',
  minHeight: '100px',
  position: 'relative',
});

const TooltipBodyContainer = styled.div({
  padding: '4px 0',
});

const HeaderDiv = styled.div({
  color: `var(--bu-gray-700)`,
  borderBottom: `1px solid var(--bu-gray-400)`,
  padding: '10px 18px',
});

const RowGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridColumnGap: '12px',
  padding: '4px 18px',
  '&.total': {
    fontWeight: 'bold',
    margin: '4px 0px',
    gridTemplateColumns: '1fr 1fr',
  },
});

const SplitTypeDiv = styled.div({
  textAlign: 'right',
  alignSelf: 'center',
});

const AmountDiv = styled.div({
  textAlign: 'right',
  alignSelf: 'center',
});

const PercentageDiv = styled.div({
  color: `var(--bu-gray-700)`,
  display: 'inline',
  fontSize: '12px',
});

const iconClassName = css``;

type ApiResponse = { data: SplitDataResp };

const TooltipContent: React.FC<Props> = ({ dealId, fieldName }) => {
  const [businessTypes, activeBusinessType, currency] = useSelector((state) => {
    const { deals_overall_view_enabled } = getFeatureFlags(state);
    return [
      getBusinessTypes(state, deals_overall_view_enabled),
      getForecastActiveBusinessType(state),
      getUserLocalCurrency(state),
    ];
  });
  const selectedBusinessType = activeBusinessType || businessTypes[0]; // selectedBusinessType can be undefined if no business type is being setup
  const businessTypesForFilters =
    selectedBusinessType === OVERALL_BUSINESS_TYPE
      ? ''
      : selectedBusinessType ?? '';
  const [status, setStatus] = useState<QueryStatus>('notAsked');
  const [splitData, setSplitData] = useState<SplitFieldResp>();
  const [errorMessage, setErrorMessage] = useState<string | null>('');
  const apiPoint = `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/${dealId}/splits?business_type=${businessTypesForFilters}`;
  const setData = ({ data }: ApiResponse) => {
    setSplitData(data[fieldName]);
  };
  const splitDetail = (
    <TooltipContainer>
      <HeaderDiv>Opportunity Split Amount Detail</HeaderDiv>
      <TooltipBodyContainer>
        <RowGrid className="total">
          <div>Amount</div>
          <AmountDiv>
            {formatMoney(currency, splitData?.original_amount ?? 0, 0)}
          </AmountDiv>
        </RowGrid>
        {splitData?.splits.map((split, idx) => {
          const name = split?.user?.name;
          return (
            <RowGrid key={`${name}_${idx}`}>
              <div>{name}</div>
              <SplitTypeDiv>{split?.split_type_name ?? ''}</SplitTypeDiv>
              <AmountDiv>
                {formatMoney(currency, split.split_amount ?? 0, 0)}
                <PercentageDiv>{` (${split.split_percentage}%)`}</PercentageDiv>
              </AmountDiv>
            </RowGrid>
          );
        })}
      </TooltipBodyContainer>
    </TooltipContainer>
  );

  useEffect(() => {
    fetchApi<string, ApiResponse>({
      queryMethod: 'get',
      setData,
      setError: setErrorMessage,
      setStatus,
      url: apiPoint,
    });
  }, []);

  return (
    <TooltipContainer>
      {status === 'loading' && <Loader active />}
      {status === 'error' && <div>{errorMessage}</div>}
      {status === 'success' && splitDetail}
    </TooltipContainer>
  );
};

export const OppSplitTooltip: React.FC<Props> = ({
  dealId,
  fieldName,
  children,
}) => {
  const isOppSplitFlagEnabled = useSelector(isOppSplitEnabled);
  return isOppSplitFlagEnabled ? (
    <TooltipWrapper
      tooltip={<TooltipContent dealId={dealId} fieldName={fieldName} />}
      noPadding={true}
    >
      {children ?? (
        <IconContainer
          className={'opp-split-icon'}
          data-bu-test="opp-split-icon"
        >
          <BuIcon
            name={BoostUpIcons.OppSplitIcon}
            color="var(--bu-primary-500)"
            className={iconClassName}
          />
        </IconContainer>
      )}
    </TooltipWrapper>
  ) : null;
};
