import { css } from 'emotion';

export const header = css`
  box-sizing: border-box;
  height: 50px;
  border-bottom: 1px solid #dddddd;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--bu-white);
  display: flex;
  align-items: center;
  padding: 0 8px 0 8px;
`;

export const logo_box = css`
  width: 135px;
  height: 35px;
  margin-left: 10px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const header_center = css`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
`;

export const header_right = css`
  justify-self: flex-end;
  margin-left: 13px;
  padding-top: 12px;
  position: relative;
  height: inherit;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -13px;
    height: 100%;
    width: 1px;
    display: block;
    background: var(--bu-gray-300);
  }
`;

export const search_settings = css`
  display: flex;
  align-items: center;

  > div:not(:last-child) {
    margin-right: 12px;
  }
`;

export const impersonatedView = css`
  background-color: rgb(240, 90, 48, 0.15);
  color: #e02020;
  font-size: 13px;
  padding: 5px 10px;
`;

export const settings_account = css`
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const settings_user = css`
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  margin-left: 15px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const profile = css`
  margin-left: 5px;
  color: #0762ec;
  font-size: 14px;
  font-family: var(--bu-font-regular);
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
`;

export const userAvatar = css`
  color: white;
  cursor: pointer;
  font-size: 11px;
  text-transform: uppercase;

  .UserAvatar--inner {
    border: 1px solid #fff;
    box-shadow: 0 0 0 1px #28bedf;
  }

  &.active {
    .UserAvatar--inner {
      box-shadow: 0 0 0 1px #5e86a0;
    }
  }
`;

export const popupNoPadding = css`
  &.ui.popup {
    padding: 0;
  }
`;

export const userCard = css`
  min-width: 250px;
  color: #4a4a4a;
  font-size: 14px;

  .detail {
    padding: 15px;

    text-align: center;

    .icon {
      width: 63px;
      font-size: 16px;
      color: #fff;
      margin: 0 auto 5px;
    }

    .header {
      font-weight: 600;
      color: #000;
    }

    .sub {
      font-size: 13px;
    }
  }

  .menu {
    border-top: 1px solid #ddd;

    padding: 10px 0;

    .item {
      padding: 5px 15px;
      line-height: 20px;
      cursor: pointer;
      color: inherit;
      display: block;

      img {
        margin: 0 7px;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }
`;

export const toggleContainer = css`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const checkboxContainer = css`
  margin-left: 5px;
`;
