import { css, keyframes } from 'emotion';

import { fontDefault } from 'assets/css/common';

export const container = css`
  width: 100%;
  height: 100%;
  background-color: var(--bu-primary-700);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 980;

  min-width: 50px;
  max-width: 50px;
  overflow: hidden;

  transition: all 0.2s;

  p {
    display: none;
  }

  #green-dot-beta-tag {
    display: inline-block;
  }

  &:hover {
    z-index: 9000;
    min-width: 200px;
    max-width: 200px;

    p {
      display: block;
      white-space: nowrap;
    }

    .nav-buttons {
      overflow-y: auto;
    }

    #green-dot-beta-tag {
      display: none;
    }
  }
`;

export const list_buttons = css`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  overflow: hidden;
`;

export const list_header_buttons = css`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden !important;
`;

export const list_buttons_item = css`
  width: 100%;
  height: 44px;

  &.border {
    border-top: 1px solid var(--bu-primary-800);
  }
`;

export const list_buttons_item_logo = css`
  height: 50px;
  border-bottom: 1px solid var(--bu-primary-700);
`;

export const list_buttons_spacer = css`
  flex: 1;
`;

const moveLogoArrowAnimation = keyframes`
  0% {
    transform: rotate(0deg);
    left: 0;
    top: 0;
    font-size: 24px;
  }
  30% {
    transform: rotate(45deg);
    font-size: 24px;
    top: 15px;
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    left: 110px;
    top: -7px;
    font-size: 16px;
    line-height: 16px;
    transform: rotate(0deg);
  }
`;

const showLogoArrowAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
    filter: blur(10px);
  }
  100% {
    transform: scale(1);
    opacity: 1;
    filter: blur(1px);
  }
`;

export const logoAnimation = css`
  animation: ${showLogoArrowAnimation} 0.5s;
  z-index: 1;
  text-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff;

  div:hover > ul > li.${list_buttons_item_logo} & {
    animation: ${moveLogoArrowAnimation} 0.7s;
    left: 110px;
    top: -7px;
    font-size: 16px;
    line-height: 16px;
  }
`;

export const button_nav = css`
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  display: flex;
  padding: 11px 11px 11px 13px;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  outline: none;
  color: #91b7d0;

  .bu-icon,
  .bu-icons-group {
    font-size: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;

    & .bu-icon {
      font-size: inherit;
    }
  }

  p {
    margin-left: 10px;
    transition: 0.2s;
    ${fontDefault};
  }

  &.active,
  &:hover {
    background-color: var(--bu-primary-800);
    color: var(--bu-white);
  }

  &.white {
    background-color: var(--bu-white);
  }

  #green-dot-beta-tag {
    height: 8px;
    width: 8px;
    background-color: var(--bu-green-500);
    border-radius: 50%;
    margin-right: -6px;
  }
`;
