import classNames from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import * as R from 'ramda';
import React, { Fragment } from 'react';

import { SENTIMENT_GRADE_DISPLAY } from 'common/constants';
import { IProps, IState } from 'components/UI/EngagementTable/Row/types';
import styles from 'components/UI/EngagementTable/styles';
import utils from 'components/UI/EngagementTable/utils';
import TruncateText from 'components/UI/TruncatedText';
import { ModalLink } from 'navigation/Link';

class Row extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      widthFirstColumn: null,
    };
    this.renderLastEngaged = this.renderLastEngaged.bind(this);
  }

  componentDidMount() {
    const widthBlock = get(this, 'firstColumn.current.offsetWidth', 0);
    this.setState({
      widthFirstColumn: widthBlock,
    });
  }

  widthGraph(type: string) {
    const { row, maxEvents, maxEmails } = this.props;
    const eventsEngaged = R.pathOr(
      0,
      ['touchpoint_stats', 'event', 'num_touchpoints_engaged'],
      row
    );
    const emailsEngaged = R.pathOr(
      0,
      ['touchpoint_stats', 'email', 'num_touchpoints_engaged'],
      row
    );

    if (type === 'event') {
      return maxEvents > 0 ? (eventsEngaged * 100) / maxEvents : 0;
    }
    if (type === 'email') {
      return maxEmails > 0 ? (emailsEngaged * 100) / maxEmails : 0;
    }
  }

  renderLastEngaged() {
    const { row } = this.props;
    const engagementStatus = utils.getLastEngagedType(row);
    const engagementStatusClasses = classNames({
      [styles.last_engaged_green]: engagementStatus === 'Engaged Recently',
      [styles.last_engaged_orange]: engagementStatus === 'Disengaged Recently',
      [styles.last_engaged_red]: engagementStatus === 'Disengaged',
      [styles.not_engaged]: engagementStatus === 'Not Engaged Yet',
    });
    return (
      <div>
        <p className={engagementStatusClasses}>{engagementStatus}</p>
        {row.is_engaged && engagementStatus !== 'Not Engaged Yet' && (
          <span>
            {`Last ${utils.getContactType(row)} ${utils.getTimeSinceLastEngaged(
              row
            )}`}
          </span>
        )}
      </div>
    );
  }

  renderSentiment() {
    const { row } = this.props;

    var sentiment: string | null = null;
    var sentiment_phrase: string | null = null;

    if (row.sentiment && row.sentiment.grade) {
      sentiment = row.sentiment.grade;
      sentiment_phrase = row.sentiment.phrases
        ? row.sentiment.phrases[0]
        : null;
    } else if (row.last_email && row.last_email.sentiment) {
      sentiment = row.last_email.sentiment;
      sentiment_phrase = row.last_email.summary;
    }
    const sentimentClasses = classNames({
      [styles.not_engaged]: sentiment === null,
      [styles.sentiment_color_positive]: sentiment === 'positive',
      [styles.sentiment_color_neutral]: sentiment === 'neutral',
      [styles.sentiment_color_negative]: sentiment === 'negative',
    });
    return (
      <div>
        <span className={styles.sentiment_title}>Sentiment:&nbsp;</span>
        <span className={sentimentClasses}>
          {isNil(sentiment) ? 'N/A' : SENTIMENT_GRADE_DISPLAY[sentiment]}
        </span>
        {sentiment_phrase ? (
          <Fragment>
            <div className={styles.sentiment_text}>{sentiment_phrase}</div>
            {row.last_email ? (
              <ModalLink
                url={{
                  scheme: '/email/:emailId',
                  params: { emailId: row.last_email!.id },
                }}
                className={styles.blue_link_more}
              >
                More
              </ModalLink>
            ) : null}
          </Fragment>
        ) : null}
      </div>
    );
  }

  getTruncationCount() {
    const { widthFirstColumn } = this.state;
    if (widthFirstColumn && widthFirstColumn > 178) {
      return 29;
    }
    if (widthFirstColumn && widthFirstColumn > 134 && widthFirstColumn <= 178) {
      return 24;
    }
    if (widthFirstColumn && widthFirstColumn <= 134) {
      return 17;
    }
  }

  render() {
    const { row } = this.props;

    const engagementStatus = utils.getLastEngagedType(row);
    const positionColors = classNames({
      [styles.position]: true,
      green: engagementStatus === 'Engaged Recently',
      red: engagementStatus === 'Disengaged Recently',
    });

    const eventsEngaged = R.pathOr(
      0,
      ['touchpoint_stats', 'event', 'num_touchpoints_engaged'],
      row
    );
    const emailsEngaged = R.pathOr(
      0,
      ['touchpoint_stats', 'email', 'num_touchpoints_engaged'],
      row
    );

    return (
      <tr className={styles.body_table} key={row.text}>
        <td className={styles.table_body_column}>
          <div className="callTranscript__table-body-item-name">
            <TruncateText
              text={row.name ? row.name : row.email}
              length={this.getTruncationCount()}
            />
            {row.salesforce_url && row.salesforce_url.length > 0 && (
              <a target="_blank" href={row.salesforce_url}>
                <img
                  className={styles.icon}
                  src={require('../../../../assets/images/icons/salesforce.svg')}
                  alt="time"
                />
              </a>
            )}
            {row.linkedin_profile && row.linkedin_profile.length && (
              <a target="_blank" href={row.linkedin_profile}>
                <img
                  className={styles.icon}
                  src={require('../../../../assets/images/icons/linkedin.svg')}
                  alt="time"
                />
              </a>
            )}
          </div>
          <div className={positionColors}>{row.title}</div>
        </td>
        <td className={styles.table_body_column}>{this.renderLastEngaged()}</td>
        <td className={styles.table_body_column}>{this.renderSentiment()}</td>
        <td className={styles.table_body_column}>
          <div className={styles.graphContainer}>
            <div>
              {emailsEngaged !== 0 && (
                <div className={styles.engagements}>
                  <img
                    src={require('../../../../assets/images/icons/email_icon_grey.svg')}
                    alt="time"
                  />
                  {emailsEngaged}
                </div>
              )}
              {eventsEngaged !== 0 && (
                <div className={styles.engagements}>
                  <img
                    src={require('../../../../assets/images/icons/call_icon_grey.svg')}
                    alt="questions"
                  />
                  {eventsEngaged}
                </div>
              )}
            </div>
          </div>
        </td>
      </tr>
    );
  }
}

export default Row;
