import classNames from 'classnames';
import React, { useContext, useEffect, useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { PanelUsers } from 'components/dashboard/Metrics/common/HierarchicalUserFilter/PanelUsers/PanelUsers';
import * as s from 'components/dashboard/Metrics/common/HierarchicalUserFilter/styles';
import { UsersByActivityContext } from 'components/dashboard/Metrics/contexts/UsersByActivityContext';
import { useClickOutside } from 'components/hooks/useClickOutside';
import {
  calcTotal,
  parseRawUsers,
  updateValues,
} from 'reducers/filters/helpers';
import { getUser } from 'selectors';

interface Props {
  currentValues: string[];
  onChangeUserFilter: (filterValues: string[], checkedAll: boolean) => void;
}

export const HierarchicalUserFilter: React.FC<Props> = ({
  currentValues = [],
  onChangeUserFilter,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const panelRef = useRef<HTMLDivElement>(null);

  const [configState, setConfigState] = useState<Filters.Config>({
    checkedAll: false,
    checkedTotal: 0,
    searchable: true,
    title: 'Users: ',
    type: 'checkbox',
    values: [],
    withShowAll: true,
  });

  const usersData: Filters.UserFilterElement[] = useContext(
    UsersByActivityContext
  );
  const user = useSelector(getUser);

  const users: Filters.Checkbox[] = useMemo(
    () => parseRawUsers(usersData, user),
    [usersData, user]
  );

  useEffect(() => {
    const valuesSet = new Set(currentValues);

    const currentFilteredUsers = users.map((e) => ({
      ...e,
      checked: valuesSet.has(e.label),
    }));

    const { checkedAll, checkedTotal } = calcTotal(currentFilteredUsers);

    setConfigState((prev) => ({
      ...prev,
      values: currentFilteredUsers,
      checkedAll: checkedAll,
      checkedTotal: checkedTotal,
    }));
  }, [JSON.stringify(users)]);

  const handleChangeUserFilter = (
    values: Filters.PersistValue[],
    withReset: boolean = false
  ): void => {
    const newValues: Filters.Checkbox[] = updateValues(
      configState.values || [],
      values,
      withReset
    );

    const { checkedAll, checkedTotal } = calcTotal(newValues);

    const filterValues = newValues.reduce(
      (accumulator: string[], currentValue) => {
        if (currentValue.checked) {
          return [...accumulator, currentValue.label];
        }
        return accumulator;
      },
      []
    );

    setConfigState((prev) => ({
      ...prev,
      values: newValues,
      checkedAll: checkedAll,
      checkedTotal: checkedTotal,
    }));

    onChangeUserFilter(filterValues, checkedAll);
  };

  const { checkedAll, title, values } = configState;

  const checkedItems: Filters.Checkbox[] = useMemo(
    () => values.filter((item) => item.checked),
    [values]
  );

  const checkedTotal: number = checkedItems.length;
  const currentValueText: string = checkedAll
    ? 'All'
    : `${checkedItems[0]?.label || ''} ${
        checkedTotal > 1 ? `(+${checkedTotal - 1})` : ''
      }`;

  return (
    <div
      ref={refElement}
      className={s.panel_wrapper}
      data-testing="hierarchical-user-filter"
    >
      <div
        className={classNames(s.panel_dropdown_button, {
          isOpen,
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={s.panel_dropdown_name}>{title}</span>
        <span className={s.panel_span}>{currentValueText}</span>

        <BuIcon
          name={isOpen ? BoostUpIcons.TriangleUp : BoostUpIcons.TriangleDown}
          color="var(--bu-gray-900)"
          className={s.icon}
        />
      </div>

      {isOpen && (
        <div className={classNames(s.panel_dropdown)} ref={panelRef}>
          <PanelUsers
            users={usersData}
            config={configState}
            onChange={handleChangeUserFilter}
          />
        </div>
      )}
    </div>
  );
};
