import moment from 'moment';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import {
  ActionsContainer,
  ItemContainer,
  ItemInfo,
  ItemName,
  WidgetSubInfo,
  WidgetSubInfoItem,
} from 'components/dashboard/Metrics/QuickView/QuickViewItem/styles';
import { MetricInfo } from 'components/dashboard/Metrics/common/MetricInfo/MetricInfo';
import { SidebarType } from 'components/dashboard/Metrics/constants';
import {
  BIMetrics,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  readonly updatedAt: number;
  readonly userName: string;
  readonly isAdded: boolean;
  readonly sidebarType: SidebarType;
  readonly item: BIMetrics | BIWidget;
  readonly metricDescription: string | JSX.Element;
  readonly onAdd: (item: BIMetrics | BIWidget) => void;
  readonly canClone: boolean;
  readonly onCloneAddMetric?: (id: string) => void;
  readonly idx?: number;
}

export const QuickViewItem: React.FC<Props> = ({
  updatedAt,
  userName,
  isAdded,
  sidebarType,
  metricDescription,
  item,
  onAdd,
  canClone,
  onCloneAddMetric,
  idx = 0,
}) => (
  <ItemContainer data-testing={`item_container_${idx}`}>
    <ItemInfo>
      <ItemName data-testing="item_name">{item.name}</ItemName>
      {sidebarType === SidebarType.METRICS && (
        <MetricInfo
          metric={item as BIMetrics}
          metricDescription={metricDescription}
        />
      )}

      {sidebarType === SidebarType.WIDGETS && (
        <WidgetSubInfo>
          <WidgetSubInfoItem>
            <BuIcon name={BoostUpIcons.ContactsLinked} />
            {userName}
          </WidgetSubInfoItem>
          <WidgetSubInfoItem>
            <BuIcon name={BoostUpIcons.Pencil} />
            {updatedAt ? moment(updatedAt).format('Do MMM YYYY') : ''}
          </WidgetSubInfoItem>
        </WidgetSubInfo>
      )}
    </ItemInfo>

    <ActionsContainer>
      {isAdded ? (
        <BuButton disabled size={BuControlSize.REGULAR}>
          Added
        </BuButton>
      ) : (
        <BuButton
          size={BuControlSize.REGULAR}
          onClick={() => onAdd(item)}
          secondary
        >
          {sidebarType === SidebarType.METRICS ? 'Add to widget' : 'Add widget'}
        </BuButton>
      )}

      {sidebarType === SidebarType.METRICS && canClone && (
        <BuButton
          size={BuControlSize.REGULAR}
          onClick={() => onCloneAddMetric && onCloneAddMetric(item._id ?? '')}
          secondary
        >
          Clone and add
        </BuButton>
      )}
    </ActionsContainer>
  </ItemContainer>
);
