import React, { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { fetchApi } from 'utils/network';

const DEFAULT_LAST_SYNC = '';
const NA_LAST_SYNC = 'N/A';

const LastSyncContext = createContext(DEFAULT_LAST_SYNC);

const LastSyncProvider = ({ children }: { children: any }) => {
  const [lastSync, setLastSync] = useState<string>(DEFAULT_LAST_SYNC);

  useEffect(() => {
    const abortController = new AbortController();

    fetchApi<string, string>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_last_sync_date`,
      queryMethod: 'get',
      setData: (result) => {
        setLastSync(result);
      },
      setError: (error: string | null) => {
        setLastSync(NA_LAST_SYNC);
        console.error(error);
        toast.error('Failed fetching last sync date');
      },
      signal: abortController.signal,
    });

    return () => {
      abortController.abort();
      setLastSync(NA_LAST_SYNC);
    };
  }, []);

  return (
    <LastSyncContext.Provider value={lastSync}>
      {children}
    </LastSyncContext.Provider>
  );
};

export { LastSyncContext, LastSyncProvider };
