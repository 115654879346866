import { css } from 'emotion';

export const ExtraHeaderCell = css`
  border-right: 2px solid var(--bu-gray-400) !important;
  min-width: 180px !important;
`;

export const ExtraHeaderBodyCell = css`
  border-right: 1px solid var(--bu-gray-300);
  border-bottom: 1px solid var(--bu-gray-300) !important;
  min-width: 100px !important;
`;

export const ExtraSubTotalCell = css`
  background-color: var(--bu-gray-100) !important;
  border-top: 2px solid var(--bu-gray-400);
  border-right: 1px solid var(--bu-gray-300);
  border-bottom: 2px solid var(--bu-gray-400);
`;

export const RegularCell = css`
  border-right: 1px solid var(--bu-gray-300);
  min-width: 180px !important;
`;

export const LastRowRegularCell = css`
  border-right: 1px solid var(--bu-gray-300);
  border-bottom: 1px solid var(--bu-gray-300);
`;

export const SubTotalCell = css`
  background-color: var(--bu-gray-100) !important;
  border-right: 1px solid var(--bu-gray-300);
  border-bottom: 2px solid var(--bu-gray-400);
`;

export const FirstSubTotalCell = css`
  background-color: var(--bu-gray-100) !important;
  border-right: 2px solid var(--bu-gray-300) !important;
  border-bottom: 2px solid var(--bu-gray-400);
`;

export const MergedPivotRegularCell = css`
  border: none !important;
  border-right: 2px solid var(--bu-gray-300) !important;
`;
