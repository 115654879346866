import * as utils from 'components/dashboard/Metrics/Create/MetricCreate/utils';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { formatObjectsList } from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIMetricFormula,
  BIMetricFormulaNewborn,
  BIMetricSimple,
  BIMetricSimpleNewborn,
  BIMetricSimplified,
  BIMetricUnion,
  MetricType,
  ComponentMode,
} from 'components/dashboard/Metrics/metrics.types';
import { IReduxState } from 'reducers/types';

const getMetricSimpleEditing = (state: IReduxState): BIMetricSimple | null =>
  state.revbi.metrics.create.simpleMetric;

const getMetricFormulaEditing = (state: IReduxState): BIMetricFormula | null =>
  state.revbi.metrics.create.formulaMetric;

const getMetricCreationActiveTab = (state: IReduxState): MetricType =>
  state.revbi.metrics.create.activeTab;

const getMetricSimpleNewborn = (state: IReduxState): BIMetricSimpleNewborn =>
  state.revbi.metrics.create.simpleMetricNewborn;

const getMetricFormulaNewborn = (state: IReduxState): BIMetricFormulaNewborn =>
  state.revbi.metrics.create.formulaMetricNewborn;

export const getMetricsList = (
  state: IReduxState
): (BIMetricSimple | BIMetricFormula)[] => state.revbi.metrics.metricsList.list;

export const getMetricsTSList = (
  state: IReduxState
): (BIMetricSimple | BIMetricFormula)[] =>
  state.revbi.metrics.metricsTSList.list;

export const getAllMetricsWithTS = (
  state: IReduxState
): (BIMetricSimple | BIMetricFormula)[] => {
  if (
    state.revbi.metrics.metricsList.status === 'success' &&
    state.revbi.metrics.metricsTSList.status === 'success'
  ) {
    const allMetrics = [
      ...state.revbi.metrics.metricsList.list,
      ...state.revbi.metrics.metricsTSList.list,
    ];
    return allMetrics.sort(function (a, b) {
      return (
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      );
    });
  }
  return [];
};

export const getAllMetricsWithTSStatus = (state: IReduxState): string => {
  const { metricsList, metricsTSList } = state.revbi.metrics;
  if (metricsList.status === 'success' && metricsTSList.status === 'success') {
    return 'success';
  }
  if (metricsList.status === 'loading' || metricsTSList.status === 'loading') {
    return 'loading';
  }
  if (
    metricsList.status === 'notAsked' &&
    metricsTSList.status === 'notAsked'
  ) {
    return 'notAsked';
  }
  return 'notAsked';
};

const getAllSimpleMetrics = (state: IReduxState): BIMetricSimple[] => {
  return state.revbi.metrics.metricsList.list.filter(
    (m) => utils.getMetricType(m) === MetricType.Simple
  ) as BIMetricSimple[];
};

const getAllSimpleTSSMetrics = (state: IReduxState): BIMetricSimple[] => {
  return state.revbi.metrics.metricsTSList.list.filter(
    (m) => utils.getMetricType(m) === MetricType.Simple
  ) as BIMetricSimple[];
};

export const getAllSimpleMetricsSimplified = (
  state: IReduxState
): BIMetricSimplified[] => {
  return getAllSimpleMetrics(state).map((metric: BIMetricSimple) => ({
    name: metric.name,
    id: metric._id || '',
    color: '',
  }));
};

export const getAllSimpleHistoricalMetricsSimplified = (
  state: IReduxState
): BIMetricSimplified[] => {
  const allTSMetrics = getAllSimpleTSSMetrics(state);
  return allTSMetrics.map((metric: BIMetricSimple) => ({
    name: metric.name,
    id: metric._id || '',
    color: '',
  }));
};

export const getCreateMetricComponentMode = (
  state: IReduxState
): ComponentMode => state.revbi.metrics.create.componentMode;

export const getMetricFetchStatus = (state: IReduxState): string =>
  state.revbi.metrics.create.getStatus;

/**
 * The selector returns metric for view. It checks if MetricCreate component is in edit/create view and what type of metric is selected.
 */
export const getActiveMetric = (state: IReduxState): BIMetricUnion => {
  const activeTab = getMetricCreationActiveTab(state);
  const componentMode = getCreateMetricComponentMode(state);

  if (activeTab === MetricType.Simple) {
    if (componentMode === ComponentMode.CREATE) {
      return getMetricSimpleNewborn(state);
    }
    if (componentMode === ComponentMode.EDIT) {
      const metric = getMetricSimpleEditing(state);
      // TODO: think how to better avoid nulls
      return metric === null ? getMetricSimpleNewborn(state) : metric;
    }
  }

  if (activeTab === MetricType.Formula) {
    if (componentMode === ComponentMode.CREATE) {
      return getMetricFormulaNewborn(state);
    }
    if (componentMode === ComponentMode.EDIT) {
      const metric = getMetricFormulaEditing(state);
      // TODO: think how to better avoid nulls
      return metric === null ? getMetricFormulaNewborn(state) : metric;
    }
  }

  return getMetricSimpleNewborn(state);
};

/**
 * The selector validates active metric for saving and returns boolean value;
 */
export const isActiveMetricReadyForSave = (state: IReduxState): boolean => {
  const activeMetric = getActiveMetric(state);
  const activeTab = getMetricCreationActiveTab(state);
  if (activeMetric === null) {
    return false;
  }
  const isFiltersValid = utils.isMetricFiltersValid(activeMetric);
  if (activeTab === MetricType.Simple) {
    return (
      isFiltersValid &&
      utils.isSimpleMetricValid(activeMetric as BIMetricSimple)
    );
  }
  if (activeTab === MetricType.Formula) {
    return (
      isFiltersValid &&
      utils.isFormulaMetricValid(activeMetric as BIMetricFormula)
    );
  }
  return false;
};

/**
 * The selector validates if active metric metric is ready for preview;
 */
export const isActiveMetricReadyForPreview = (state: IReduxState): boolean => {
  const activeMetric = getActiveMetric(state);
  const activeTab = getMetricCreationActiveTab(state);
  if (activeMetric === null) {
    return false;
  }
  const isFiltersValid = utils.isMetricFiltersValid(activeMetric);
  if (activeTab === MetricType.Simple) {
    return (
      isFiltersValid &&
      utils.isSimpleMetricCanPreview(activeMetric as BIMetricSimple)
    );
  }
  if (activeTab === MetricType.Formula) {
    return (
      isFiltersValid &&
      utils.isFormulaMetricCanPreview(activeMetric as BIMetricFormula)
    );
  }
  return false;
};

/**
 * The selector mark if active metric is changed. It is using for cloning functionality.
 * Works only for editing mode.
 */
export const isActiveMetricTouched = (state: IReduxState): boolean => {
  const componentMode = getCreateMetricComponentMode(state);
  const activeTab = getMetricCreationActiveTab(state);
  if (
    componentMode === ComponentMode.EDIT &&
    activeTab === MetricType.Formula
  ) {
    return state.revbi.metrics.create.isFormulaMetricTouched;
  }
  if (componentMode === ComponentMode.EDIT && activeTab === MetricType.Simple) {
    return state.revbi.metrics.create.isSimpleMetricTouched;
  }
  return false;
};

/**
 * The selector checks if active metric has valid filters
 */
export const isActiveMetricHasValidFilters = (state: IReduxState): boolean => {
  const activeMetric = getActiveMetric(state);
  if (activeMetric === null) {
    return false;
  }
  return utils.isMetricFiltersValid(activeMetric);
};

export const getObjectList = (state: IReduxState) =>
  state.revbi.metrics.objectList.list;

const getObjectListFormatted = (state: IReduxState) => {
  return formatObjectsList(state.revbi.metrics.objectList.list);
};

const getObjectListHistoryFormatted = (state: IReduxState) =>
  formatObjectsList(state.revbi.metrics.objectListHistory.list);

export const getObjectsListFormattedByAnalysisType = (
  state: IReduxState,
  analysisType: AnalysisType
) => {
  if (analysisType === AnalysisType.HISTORICAL) {
    return getObjectListHistoryFormatted(state);
  } else {
    return getObjectListFormatted(state);
  }
};

export const getColumnFiltersList = (state: IReduxState) =>
  state.revbi.metrics.columnFieldsFilter.list;

export const getColumnFieldsByObject = (state: IReduxState, object: string) => {
  const { columns } = state.revbi.metrics.columnFields;
  if (object in columns) {
    return columns[object];
  }
  return [];
};

export const getFetchingAllMetricsStatus = (state: IReduxState): string => {
  return state.revbi.metrics.metricsList.status;
};
