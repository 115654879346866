import { css } from 'emotion';

import { fontSubtitle } from 'assets/css/common';

export const wrapper = css`
  padding: 20px 0;

  > * {
    margin-top: 20px;

    last-child {
      margin-top: 0;
    }
  }
`;

export const headerContainer = css`
  display: flex;
  margin: 27px 0 10px 0;

  &:nth-child(n + 2) {
    margin-top: 40px;
  }

  & > .sub:first-child {
    flex: 1;
  }
`;

export const headerRow = css`
  color: var(--bu-gray-900);
  ${fontSubtitle};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  border-bottom: 1px solid #ccc;
  padding: 14px 30px;

  .subheader {
    font-family: var(--bu-font-regular);
    font-size: 14px;
    line-height: 21px;
    color: #777777;
    margin-top: 7px;
  }
`;
