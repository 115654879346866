import styled from '@emotion/styled';

export const FlexColumn = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
});

export const WidgetOptionsColumn = styled.div({
  width: '520px',
  minWidth: '520px',
  display: 'flex',
  flexDirection: 'column',
  borderRight: 'solid var(--bu-gray-400) 1px',
});

export const WidgetOptionSection = styled.div(
  (props: { expanded: boolean; first: boolean; sections: number }) =>
    props.expanded
      ? {
          flexGrow: 1,
          overflowX: 'visible',
          overflowY: 'auto',
          borderTop: props.first ? 0 : 'solid var(--bu-gray-400) 1px',
          maxHeight: `calc(100vh - ${(props.sections - 1) * 115}px)`,
        }
      : {
          flexGrow: 0,
          height: '60px',
          minHeight: '60px',
          borderTop: props.first ? 0 : 'solid var(--bu-gray-400) 1px',
        }
);

export const WidgetPreviewColumn = styled.div({
  flexGrow: 1,
  background: 'var(--bu-gray-200)',
  alignItems: 'center',
  padding: '24px 48px 0 48px',
  overflow: 'auto',
});

export const WidgetPreviewPlaceholder = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  background: 'white',
  alignItems: 'center',
  padding: '48px 20px',
  gap: '16px',
  maxHeight: '512px',
  borderRadius: '8px',
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.15)',
});

export const WidgetPreview = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  marginBottom: '48px',
});

export const SectionTitle = styled.div({
  fontWeight: 'bolder',
  fontSize: '18px',
  cursor: 'pointer',
  display: 'flex',
  gap: '8px',
  margin: '16px',
});

export const SectionWrapper = styled.div({
  padding: '0 16px 16px 16px',
});

export const SectionTitleText = styled.div({
  flexGrow: 1,
  marginBottom: '24px',
});

export const DefinitionsContainer = styled.div({
  maxHeight: 'calc(100vh - 400px)',
});

export const DefinitionsTitle = styled.p({
  fontSize: '15px',
  fontWeight: 500,
  color: 'var(--bu-gray-900)',
  paddingBottom: '16px',
  marginBottom: '0px',
});
