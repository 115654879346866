import { RollupsItem } from '../types';
import * as R from 'ramda';

import { IRow } from 'components/UI/common/TypedTable/TypedTable';

export function getRawValue(field: string, row: IRow): any {
  if (R.path(field.split('.'), row) !== undefined)
    return R.path(field.split('.'), row);

  return R.pathOr(undefined, ['extra', field, 'value'], row);
}

export const mergeFieldItemsWithDeltas = (
  fieldItems: RollupsItem[],
  deltaFieldItems: RollupsItem[]
) => {
  const mergedItems = [];
  // Builds and uses an alternative map based representation of delta deals list for performance reasons
  let deltaFieldMap = deltaFieldItems.reduce(
    (map, curr) => map.set(`${curr.$oid}-${curr.group_id}`, curr),
    new Map<String, RollupsItem>()
  );
  for (let deal of fieldItems) {
    // Goal here is to update current deal list with corresponding delta values, as well as filtering them out if they are tagged
    // for it
    let mergeMap = {
      $oid: deal.$oid,
      group_id: deal.group_id,
      value: deal.value,
      count: deal.count,
      filtered_out: deal?.filtered_out,
    } as RollupsItem;
    let dealKey = `${deal.$oid}-${deal.group_id}`;
    if (deltaFieldMap.has(dealKey)) {
      mergeMap.value = deltaFieldMap.get(dealKey)?.value;
      mergeMap.filtered_out = deltaFieldMap.get(dealKey)?.filtered_out;
    }
    if (!mergeMap.filtered_out) {
      mergedItems.push(mergeMap);
    }
  }
  // As there might be delta items that are not in the current list, this part makes sure those values are still part of the
  // final list
  let fieldItemsIds = fieldItems.map((item: RollupsItem) => item.$oid);
  let nonOverlappingDeltaDeals = deltaFieldItems.filter(
    (deltaItem: RollupsItem) =>
      !fieldItemsIds.includes(deltaItem.$oid) && !deltaItem.filtered_out
  );
  // Finally, we add both lists together (as they are garanteed to be non-overlapping) to form a consolidated list of "delta" items,
  // meaning the state of deals at the begining of the target change period.
  return [...mergedItems, ...nonOverlappingDeltaDeals];
};

export const getDeltaValueForField = (field: string, row: IRow) => {
  /**
   * This function has the purpose of computing the corresponding delta values for a field, given how their list
   * of elements look like (represented as an array of elements in a "<field> id" key). When a field is deltable, it has
   * a "Prev" prefix to signal that those values are the deltas. Finding out final delta normally requires
   * figuring out values duplicated in both lists and values that need to be filtered out first, in order to build a consolidated
   * list out of both (current and delta) list inputs.
   *
   * The reason for moving the responsibility of this computation to the FE is because, given FE always request data
   * for both current rollups and deltas, that fact can be leveraged in order to improve performance of delta computation in BE
   * (as only knowledge of updated deals, in the case of vopportunities, is needed), provided that the FE consolidates the final value from both sources
   */
  const deltaField = `Prev ${field}`;
  const deltaFieldValue = getRawValue(deltaField, row);
  const fieldValue = getRawValue(field, row);
  const fieldItems = getRawValue(`${field} id`, row);
  const deltaFieldItems = getRawValue(`${deltaField} id`, row);
  const bothCurrentAndPreviousAreArrays =
    Array.isArray(fieldItems) && Array.isArray(deltaFieldItems);
  const bothArraysAreEmpty =
    bothCurrentAndPreviousAreArrays &&
    deltaFieldItems.length === 0 &&
    fieldItems.length === 0;
  if (deltaFieldValue === undefined) {
    // When delta value is undefined it means that there is no data for deltas. This can happen because deltas hasn't been called yet,
    // rollups finished loading before deltas, or because the current user is disabled. For disabled users we don't want to generate
    // a delta, therefore the value of the original field is returned so the difference is 0, for the other cases we returned the regular delta value
    return getRawValue('status', row) === 'disabled'
      ? fieldValue
      : deltaFieldValue;
  }
  if (bothCurrentAndPreviousAreArrays && !bothArraysAreEmpty) {
    // This state implies fields keep individual elements in a list, there are elements in both lists, and some sort of conflicting behavior
    // may exist, like some of them being present in both, with different values, or some element that currently
    // exists but didn't exist before (because it was created during the target period). Therefore some sort of
    // consolidation needs to be performed in order to compute the final value
    let mergedFieldItems = mergeFieldItemsWithDeltas(
      fieldItems,
      deltaFieldItems
    );
    return mergedFieldItems.reduce(
      (acc: any, current: RollupsItem) => acc + current.value,
      0
    );
  }
  if (
    !bothCurrentAndPreviousAreArrays &&
    Array.isArray(fieldItems) &&
    fieldItems.length > 0
  ) {
    // This state implies there are no changes done to the items in the list (they remain in the same state), so there is no delta,
    // which we encode by returning the same value it has currently.
    return fieldValue;
  }
  // Reaching here implies the field doesn't keep the count of individual elements in an "id" field, therefore no special merging
  // behavior is needed
  return deltaFieldValue;
};
