import { css } from 'emotion';
import { PointClickEventObject } from 'highcharts';

import { fontBody } from 'assets/css/fontStyles';
import { BIChartTypes } from 'common/enums/metrics';
import { HighchartsColors } from 'common/highcharts-helpers';
import { formatAmount, formatMoney, formatNumber } from 'common/numbers';
import { getUnits } from 'components/dashboard/Metrics/Widget/helper';
import { UnitType } from 'components/dashboard/Metrics/constants';
import {
  BIMetricToChartType,
  BIMetricsMap,
  BIWidgetDataV2,
} from 'components/dashboard/Metrics/metrics.types';

type BIChartData = {
  data?: any;
  name?: string;
  y?: any;
  stack?: string;
  legendIndex?: number;
  color?: string;
  type?: BIChartTypes;
  yAxis?: number;
  threshold?: number;
};

const getPivotLabels = (
  metricData: BIWidgetDataV2,
  isSecondPivot: boolean = false
): string[] => {
  if (
    !metricData.hasOwnProperty('pivot_1') ||
    (isSecondPivot && !metricData.hasOwnProperty('pivot_2'))
  ) {
    return [];
  }

  const numberOfPivots = metricData.hasOwnProperty('pivot_2')
    ? 2
    : metricData.hasOwnProperty('pivot_1')
    ? 1
    : 0;
  const pivotsCondition = isSecondPivot ? 1 : 0;

  if (numberOfPivots > pivotsCondition) {
    const pivot1Column = metricData?.pivot_1?.columns.find((el) => el.is_pivot);
    const pivot2Column = metricData?.pivot_2?.columns.find(
      (el) => el.is_pivot && el.field_name !== pivot1Column?.field_name
    );

    return [
      ...new Set(
        isSecondPivot
          ? metricData.pivot_2?.data.map(
              (el) => el[pivot2Column?.field_name || '']
            )
          : metricData.pivot_1?.data.map(
              (el) => el[pivot1Column?.field_name || '']
            )
      ),
    ];
  }

  return [];
};

const handleNegativeDataForPieChart = (data: BIChartData[]): any[] =>
  data.map((d) => ({
    ...d,
    isNegative: d.y < 0,
    y: d.y < 0 ? d.y * -1 : d.y,
  }));

const formatChartDataAndLabels = (
  metricData: BIWidgetDataV2,
  metricToChartType: BIMetricToChartType[]
) => {
  const isPivotsEmpty =
    !metricData?.hasOwnProperty('pivot_1') &&
    !metricData?.hasOwnProperty('pivot_2');
  const isColumnExists = !!metricData?.pivot_1?.columns;

  if (!metricData || isPivotsEmpty || !isColumnExists) {
    return { chartData: [], labels: [], type: {} };
  }

  const pivot1Labels: string[] = getPivotLabels(metricData);

  let dataForChart: BIChartData[] = [];

  const pivot1Column = metricData?.pivot_1?.columns.find((el) => el.is_pivot);
  const pivot2Column = metricData?.pivot_2?.columns.find(
    (el) => el.is_pivot && el.field_name !== pivot1Column?.field_name
  );
  const nonPivotColumns =
    metricData?.pivot_1?.columns.filter((el) => !el.is_pivot) || [];

  if (metricData?.hasOwnProperty('pivot_2')) {
    const pivot2Labels: string[] = getPivotLabels(metricData, true);

    dataForChart = nonPivotColumns.flatMap((el, idx) =>
      metricData?.pivot_2?.data[0].hasOwnProperty(el.field_name) &&
      metricToChartType.find((ch) => ch.metricId === el.metric_id)
        ?.chartType !== BIChartTypes.Line &&
      metricToChartType.find((ch) => ch.metricId === el.metric_id)
        ?.chartType !== BIChartTypes.Column
        ? pivot2Labels.map((pivot2Label) => ({
            name:
              pivot2Label === null
                ? `No ${pivot2Column?.display_name}`
                : pivot2Label,
            data: new Array(pivot1Labels.length).fill({}),
            stack: el.field_name,
            threshold: 0,
            type:
              metricToChartType.find((ch) => ch.metricId === el.metric_id)
                ?.chartType !== BIChartTypes.ColumnStacked
                ? metricToChartType.find((ch) => ch.metricId === el.metric_id)
                    ?.chartType
                : BIChartTypes.Column,
            color: HighchartsColors.mapColorBasedOnLabel(pivot2Label, true),
            showInLegend: idx === 0,
            yAxis: metricData?.pivot_1?.columns
              .filter(
                (value, index, array) =>
                  array.findIndex((column) => column.type === value.type) ===
                  index
              )
              .findIndex((yAxis) => yAxis.type === el.type),
          }))
        : []
    );

    nonPivotColumns.forEach((el) => {
      const chartType = metricToChartType.find(
        (ch) => ch.metricId === el.metric_id
      );
      if (
        !metricData?.pivot_2?.data[0].hasOwnProperty(el.field_name) ||
        chartType?.chartType === BIChartTypes.Line ||
        chartType?.chartType === BIChartTypes.Column
      ) {
        dataForChart.push({
          data: metricData?.pivot_1?.data?.map((datum) => ({
            y:
              datum[el.field_name] === '-' || datum[el.field_name] === ''
                ? 0
                : el.field_name
                ? datum[el.field_name]
                : 0,
            pivot1Id: datum[pivot1Column?.field_name || ''],
            name: el.display_name,
            metricId: el.metric_id,
          })),
          color:
            nonPivotColumns.length > 1
              ? HighchartsColors.mapColorBasedOnLabel(el.display_name, true)
              : undefined,
          name: el.display_name,
          stack: el.field_name,
          threshold: 0,
          type:
            chartType?.chartType !== BIChartTypes.ColumnStacked
              ? chartType?.chartType
              : BIChartTypes.Column,
          yAxis: metricData?.pivot_1?.columns
            .filter(
              (value, index, array) =>
                array.findIndex((column) => column.type === value.type) ===
                index
            )
            .findIndex((yAxis) => yAxis.type === el.type),
        });
      } else {
        (metricData?.pivot_2?.data as any[]).forEach((datum) => {
          const pivot2Index = dataForChart.findIndex((e) => {
            if (pivot2Column?.display_name === null) {
              return e === `No ${pivot2Column?.display_name}`;
            }

            return (
              e.name === datum[pivot2Column?.field_name || ''] &&
              e.stack === el.field_name
            );
          });
          const pivot1Index = pivot1Labels.findIndex(
            (e) => e === datum[pivot1Column?.field_name || '']
          );

          if (dataForChart[pivot2Index]?.data?.[pivot1Index] !== undefined) {
            dataForChart[pivot2Index].data[pivot1Index] = {
              y:
                (el?.field_name && datum[el.field_name] === '-') ||
                (el?.field_name && datum[el.field_name] === '')
                  ? 0
                  : el?.field_name
                  ? datum[el.field_name]
                  : 0,
              pivot1Id: datum[pivot1Column?.field_name || ''],
              pivot2Id: datum[pivot2Column?.field_name || ''],
              name: el.display_name,
              metricId: el.metric_id,
            };
          }
        });
      }
    });
  } else {
    nonPivotColumns.forEach((el) => {
      dataForChart.push({
        data: metricData?.pivot_1?.data?.map((datum) => ({
          y:
            datum[el.field_name] === '-' || datum[el.field_name] === ''
              ? 0
              : el.field_name
              ? datum[el.field_name]
              : 0,
          pivot1Id: datum[pivot1Column?.field_name || ''],
          name: metricToChartType.some((el) => el.chartType === 'pie')
            ? datum[pivot1Column?.field_name || '']
            : el.display_name,
          color: metricToChartType.some((el) => el.chartType === 'pie')
            ? HighchartsColors.mapColorBasedOnLabel(
                datum[pivot1Column?.field_name || ''],
                true
              )
            : undefined,
          metricId: el.metric_id,
        })),
        name: el.display_name,
        stack: el.field_name,
        ...(metricToChartType.some((el) => el.chartType === 'pie')
          ? { innerSize: '50%' }
          : {}),
        color:
          nonPivotColumns.length > 1
            ? HighchartsColors.mapColorBasedOnLabel(el.display_name, true)
            : undefined,
        threshold: 0,
        type:
          metricToChartType.find((ch) => ch.metricId === el.metric_id)
            ?.chartType !== BIChartTypes.ColumnStacked
            ? metricToChartType.find((ch) => ch.metricId === el.metric_id)
                ?.chartType
            : BIChartTypes.Column,
        yAxis: metricData?.pivot_1?.columns
          .filter(
            (value, index, array) =>
              array.findIndex((column) => column.type === value.type) === index
          )
          .findIndex((yAxis) => yAxis.type === el.type),
      });
    });
  }

  const chartData = dataForChart;

  const chartDataGroupedByType = chartData.reduce((prev, curr) => {
    if (!!curr.type) {
      if (prev.hasOwnProperty(curr.type)) {
        prev[curr.type].unshift(curr);
      } else {
        prev[curr.type] = [curr];
      }
    }
    return prev;
  }, {} as { [key: string]: BIChartData[] });

  let lIndex = 1;
  if (chartDataGroupedByType.hasOwnProperty(BIChartTypes.Line)) {
    chartDataGroupedByType[BIChartTypes.Line].forEach((el) => {
      el.legendIndex = lIndex;
      lIndex++;
    });
  }

  Object.keys(chartDataGroupedByType).forEach((key) => {
    if (key === BIChartTypes.Line) {
      return;
    } else {
      chartDataGroupedByType[key].forEach((el) => {
        el.legendIndex = lIndex;
        lIndex++;
      });
    }
  });

  return {
    chartData: metricToChartType.some((el) => el.chartType === 'pie')
      ? handleNegativeDataForPieChart(chartData)
      : chartData,
    labels: pivot1Labels,
    type: nonPivotColumns.reduce((prev, value) => {
      prev[value.display_name] = value.type;
      return prev;
    }, {} as { [key: string]: string }),
  };
};

export const getHighChartOptions = (
  onDataClick: (pointEvent: PointClickEventObject) => void,
  metricData: BIWidgetDataV2,
  currencyCode: string,
  metricsInUse: BIMetricsMap,
  metricToChartType: BIMetricToChartType[]
): Highcharts.Options => {
  const nonPivotColumns =
    metricData?.pivot_1?.columns.filter((el) => !el.is_pivot) || [];

  let { chartData, labels, type } = formatChartDataAndLabels(
    metricData,
    metricToChartType
  );

  return {
    title: {
      text: '',
    },
    tooltip: {
      formatter: function (this) {
        const getFormattedNumber = () => {
          return `${(this.point.options as any).isNegative ? '- ' : ''}${
            type[this.series.name] === 'money'
              ? formatMoney(currencyCode, this.y as number)
              : formatNumber(this.y as number)
          }`;
        };
        const getSubName = () => {
          if (metricData.hasOwnProperty('pivot_2')) {
            if (
              (this.point.options as any).pivot2Id &&
              (this.point.options as any).name !==
                (this.point.options as any).pivot2Id
            ) {
              return `${(this.point.options as any).pivot2Id}<br/>`;
            } else if ((this.point.options as any).name !== this.key) {
              return `${this.key}<br/>`;
            }
            return '';
          } else if (metricData.hasOwnProperty('pivot_1')) {
            if (
              (this.point.options as any).pivot1Id &&
              (this.point.options as any).name !==
                (this.point.options as any).pivot1Id
            ) {
              return `${(this.point.options as any).pivot1Id}<br/>`;
            } else if ((this.point.options as any).name !== this.key) {
              return `${this.key}<br/>`;
            }
            return '';
          }
          return '';
        };

        const serieName = this.point.options.name ?? '';
        // Covering case when we have only one metric and it is synthetic.
        let metricId = (this.point.options as any).metricId as string;
        let _id = metricId.startsWith('not_saved_')
          ? 'not_saved_metric'
          : metricId;
        /**
         * if the _id was not found in the metadata,
         * it will find it by serie name in the metricsInUse collection
         */
        if (!_id) {
          for (const key in metricsInUse) {
            if (Object.prototype.hasOwnProperty.call(metricsInUse, key)) {
              const element = metricsInUse[key];
              if (element.name === serieName) {
                _id = element._id;
              }
            }
          }
        }

        if (_id in metricsInUse) {
          const metricSynth = metricsInUse[_id ?? 'not_saved_metric'];
          return `${serieName} <br/>${getSubName()} ${getUnits(
            UnitType.Prefix,
            metricSynth
          )}${
            typeof this.y === 'number' ? getFormattedNumber() : this.y
          }${getUnits(UnitType.Suffix, metricSynth)}`;
        }
        return `${serieName} <br/>${getSubName()} ${
          typeof this.y === 'number' ? getFormattedNumber() : this.y
        }`;
      },
    },
    chart: {
      alignThresholds: true,
      type: metricToChartType.some((el) => el.chartType === 'pie')
        ? 'pie'
        : 'column',
    },
    series: chartData.sort((el) => (el.type === 'line' ? 1 : -1)),
    boost: {
      enabled: true,
      useGPUTranslations: true,
      // Chart-level boost when there are more than 5 series in the chart
      seriesThreshold: 0,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
        },
      },
      series: {
        stacking: 'normal',
        point: {
          events: {
            click: onDataClick,
          },
        },
        dataLabels: {
          enabled: metricToChartType.some((el) => el.chartType === 'pie'),
          ...(metricToChartType.some((el) => el.chartType === 'pie') && {
            className: css`
              text {
                ${fontBody}
                color: var(--bu-black) !important;
                font-weight: unset !important;
                fill: none !important;
                tspan {
                  stroke-width: 1px !important;
                  stroke: var(--bu-gray-900) !important;
                }
              }
            `,
            format: '{point.name}',
            crop: false,
            overflow: 'allow',
          }),
        },
        cursor: 'pointer',
        boostThreshold: 0,
      },
    },
    xAxis: {
      categories: labels,
    },
    yAxis: nonPivotColumns
      .filter(
        (value, index, array) =>
          array.findIndex((column) => column.type === value.type) === index
      )
      .map((column, index) => ({
        labels: {
          enabled: true,
          formatter: function (this) {
            const getFormattedNumber = () =>
              column.type === 'money'
                ? formatAmount(this.value as number, currencyCode)
                : formatNumber(this.value as number);
            return `${
              typeof this.value === 'number' ? getFormattedNumber() : this.value
            }`;
          },
        },
        visible: metricToChartType.every((el) => el.chartType !== 'pie'),
        title: {
          text:
            nonPivotColumns.reduce(
              (prev, curr) => (curr.type === column.type ? prev + 1 : prev),
              0
            ) > 1
              ? ''
              : column.display_name,
        },
        opposite: index % 2 !== 0,
      })),
  };
};
