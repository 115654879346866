import React, { PropsWithChildren } from 'react';

import { IDataCellProps } from 'components/UI/common/TypedTable/TypedTable';
import {
  CustomComponentFunction,
  getCellValue,
  makeComponent,
} from 'components/UI/common/TypedTable/renderers/custom/common';

interface SwitchComponentsProps {
  [fieldValue: string]: React.FC<IDataCellProps>;
}

export type SwitchComponentOptions<PropertyType extends string> = {
  [key in PropertyType]: React.FC<IDataCellProps>;
};

/**
 * Show component from component map depending of the field value
 * @param components
 */
export const switchComponents: CustomComponentFunction<SwitchComponentsProps> = (
  components
) => (props) => {
  const Component =
    components[`${getCellValue(props)}`] ||
    (() => <div>Component not found</div>);

  return <Component {...props} />;
};

export const changeField = (
  Component: React.FC<IDataCellProps>,
  field: string
) => (props: PropsWithChildren<IDataCellProps>) => (
  <Component {...props} column={{ ...props.column, field }} />
);

export const SwitchComponents = makeComponent(switchComponents);
