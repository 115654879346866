import isNil from 'lodash/isNil';
import { useState, useRef, useEffect } from 'react';

export const useClickOutside = (onClose?: () => void) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const refElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: Event) => {
      const refPopup = refElement.current;

      if (!isNil(refPopup) && !refPopup.contains(e.target as Node)) {
        setIsOpen(false);
        onClose && onClose();
      }
    };

    document.addEventListener('mousedown', (e) => handleClickOutside(e));

    return () => {
      document.removeEventListener('mousedown', (e) => handleClickOutside(e));
    };
  }, [refElement]);

  return {
    isOpen,
    setIsOpen,
    refElement,
  };
};
