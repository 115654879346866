import { css } from 'emotion';

export const title = css`
  padding: 28px 30px;
  font-size: 14px;
  color: var(--bu-gray-900);
  font-family: var(--bu-font-medium);
  text-transform: uppercase;
  border-top: 1px solid var(--bu-gray-400);
`;

export const wrapperTable = css`
  width: 100%;
`;

export const table = css`
  width: inherit;
`;

export const table_header = css`
  padding: 0 30px;
  background: var(--bu-gray-200);
  border-top: 1px solid var(--bu-gray-400);
  border-bottom: 1px solid var(--bu-gray-400);
`;

export const table_header_col = css`
  width: 100%;

  p {
    position: relative;
    display: inline-block;
  }

  &.require {
    p {
      &:after {
        position: absolute;
        content: '*';
        color: var(--bu-red-500);
        right: -7px;
        top: 0;
      }
    }
  }
`;

export const table_header_row = css`
  display: flex;
  padding: 13px 0px;
  font-family: var(--bu-font-medium);
  font-size: 12px;
  color: var(--bu-gray-700);
`;

export const table_body_row = css`
  border-bottom: 1px solid var(--bu-gray-400);
  padding: 12px 30px 20px 30px;
  display: flex;
`;

export const table_body_col = css`
  width: 100%;
`;

export const input = css`
  padding: 7px 12px;
  font-size: 14px;
  color: var(--bu-gray-900);
  font-family: var(--bu-font-regular);
  border: 1px solid var(--bu-gray-500);
  border-radius: 4px;
  transition: border-color 0.4s;

  &.isValid {
    border-color: var(--bu-red-500);
  }

  &:focus {
    border-color: var(--bu-primary-500);
  }

  &::placeholder {
    color: var(--bu-gray-700);
  }
`;

export const input_notification = css`
  position: absolute;
  left: 0;
  bottom: -18px;
  color: red;
  font-size: 12px;
  white-space: nowrap;
`;

export const control = css`
  padding: 12px 30px;
`;

export const add_new = css`
  border: 1px solid var(--bu-primary-500);
  color: var(--bu-primary-500);
  font-size: 14px;
  font-family: var(--bu-font-regular);
  padding: 8px 20px;
  border-radius: 2px;
  background: transparent;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

export const btn_save = css`
  font-size: 14px;
  font-family: var(--bu-font-regular);
  padding: 8px 20px;
  background: var(--bu-primary-500);
  border: 1px solid transparent;
  border-radius: 2px;
  color: var(--bu-white);
  margin-left: 20px;
  opacity: 1;
  transition: opacity 0.4s;
  cursor: pointer;
  width: 75px;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

export const wrapper_field = css`
  max-width: 193px;
  position: relative;

  button {
    width: -webkit-fill-available;

    & .bu-btn-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
