import { css } from 'emotion';
import { isEmpty } from 'ramda';
import React from 'react';

import { RecognizedTableConfigurationTypes } from 'components/UI/TableConfig/types';
import {
  IColumn,
  IDataCellProps,
  IRow,
  TypedTableCellConfig,
} from 'components/UI/common/TypedTable/TypedTable';
import { getFieldValue } from 'components/UI/common/TypedTable/renderers/custom/common';

export type Step = {
  step_name: string;
  field_name: string;
  display_name: string;
  crm_field: string;
  type: RecognizedTableConfigurationTypes['type'];
  editable: boolean;
  sortable: boolean;
  object_field: string;
  protected: boolean;
  length?: number;
  choices?: string[];
  meta?: {
    delta: boolean;
  };
};

export interface ISalesProcessCellConfig extends TypedTableCellConfig {
  steps: Step[];
  version?: number;
  name: string;
  click(column: IColumn, row: IRow): void;
}

const pile = css`
  background-color: var(--bu-gray-300);
  border-radius: 10px;
  color: var(--bu-gray-700);
  padding: 3px 10px;
  outline: none;

  .filled {
    color: var(--bu-primary-500);
  }
`;

const SalesProcessCell: React.FC<IDataCellProps> = ({ column, row }) => {
  const {
    steps,
    click: callback,
    version,
    name,
  } = column.config as ISalesProcessCellConfig;
  const stepValues = steps.map((step) => getFieldValue(step.object_field, row));
  const salesProcess = (row?.sales_process as any)?.[name] || {};

  if (version === 2 && isEmpty(salesProcess)) {
    return <div style={{ textAlign: 'center' }}> - </div>;
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <span
        className={pile}
        onClick={() => callback(column, row)}
        role="button"
        tabIndex={0}
        aria-pressed={false}
      >
        <span className="filled">
          {stepValues.filter((v) => !!v && !isEmpty(v)).length}
        </span>
        /{steps.length}
      </span>
    </div>
  );
};

export default SalesProcessCell;
