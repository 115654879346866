import classNames from 'classnames';
import { css } from 'emotion';
import React, { useMemo } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { ITypedRowProps } from 'components/UI/common/TypedTable/TypedRow';

type TypedHierarchyToggleProps = Pick<
  ITypedRowProps,
  'isHierarchyOpen' | 'level' | 'toggleHierarchy'
>;

export const ICON_WIDTH = 14;
export const HIERARCHICAL_OFFSET = 15;

const buttonClassName = css`
  margin: 0 10px;
  cursor: pointer;
  outline-style: none;
  background-color: transparent;
  border-style: none;
  padding: 0;
  font-size: 0.6em;
`;

const icon = css`
  font-size: 2em;
  color: var(--bu-gray-700);

  &.open {
    color: var(--bu-primary-500);
  }
`;

const TypedHierarchyToggle: React.FC<TypedHierarchyToggleProps> = ({
  level = 0,
  isHierarchyOpen,
  toggleHierarchy,
}) => {
  const classNameWithOffset = useMemo(() => {
    const ifNoIconOffset = !toggleHierarchy ? ICON_WIDTH + 12 : 0;
    return css`
      margin-left: ${HIERARCHICAL_OFFSET * level + ifNoIconOffset}px;
    `;
  }, [level, !toggleHierarchy]);

  return (
    <div className={classNames('hierarchy-toggle', classNameWithOffset)}>
      {toggleHierarchy ? (
        <button className={buttonClassName} onClick={toggleHierarchy}>
          <BuIcon
            name={
              isHierarchyOpen
                ? BoostUpIcons.ChevronDown
                : BoostUpIcons.ChevronRight
            }
            className={classNames(icon, {
              open: isHierarchyOpen,
            })}
          />
        </button>
      ) : null}
    </div>
  );
};

export default TypedHierarchyToggle;
