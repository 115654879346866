import { NUMBER_OF_SETTINGS_TO_PRELOAD } from './helper';
import groupBy from 'lodash/groupBy';
import React, { Fragment, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'actions';
import { forecastSubmissionCreate } from 'actions/forecastActions';
import closeIcon from 'assets/images/close.svg';
import {
  ForecastSubmissionSettingItemReduxState,
  ForecastSubmissionSettingsDealsItemReduxState,
} from 'common/types/forecastSubmission';
import { useDataModal } from 'components/UI/BuDataPopup';
import { ScheduleSubmissionAlert } from 'components/UI/ScheduleSubmissionAlert';
import { IdType } from 'components/UI/common/TypedTable/TypedTable';
import ForecastDealsModal from 'components/dashboard/CROOverride/SubmissionForecast/ForecastDealsTable/ForecastDealsModal';
import { ForecastDealsModalProps } from 'components/dashboard/CROOverride/SubmissionForecast/ForecastDealsTable/types';
import Record from 'components/dashboard/Forecast/Dashboard/SubmitForecast/Record';
import * as styles from 'components/dashboard/Forecast/Dashboard/SubmitForecast/styles';
import {
  AllPersisted,
  Formatter,
} from 'components/dashboard/Forecast/Dashboard/SubmitForecast/types';
import {
  getBusinessTypes,
  getForecastActiveBusinessType,
  getForecastOpenedSettings,
  getForecastSubmissionSettings,
} from 'selectors';
import { OVERALL_BUSINESS_TYPE } from 'selectors/settings';

type ForecastDealsModalQueryParam = {
  included_deals: string[];
  page_size: number;
};

type ForecastDealsModal = React.FC<
  ForecastDealsModalProps<ForecastDealsModalQueryParam>
>;

type Props = {
  formatter: Formatter;
  includedDeals: Record<string, string[]>;
  excludedDeals: Record<string, string[]>;
  onClose: () => void;
  onIncludedDealsChange: (
    includedDealsIds: IdType[],
    excludedDealsIds: IdType[],
    activeSettingsId: string,
    includeWithReplace?: boolean
  ) => void;
  resetIncludedAndExcludedDeals: (
    activeSettingsId: string,
    deals: ForecastSubmissionSettingsDealsItemReduxState[]
  ) => void;
  persisted: AllPersisted;
};

const BasePanel: React.FC<Props> = ({
  formatter,
  includedDeals,
  excludedDeals,
  onClose,
  onIncludedDealsChange,
  resetIncludedAndExcludedDeals,
  persisted,
}) => {
  const dispatch = useDispatch();

  const ref = useRef<HTMLDivElement>(null);

  const dealsModal = useDataModal<ForecastDealsModal>({
    url: '',
    queryParams: {
      included_deals: [],
      page_size: 0,
    },
    submissionName: '',
    submissionSettingsId: '',
    dealsExcluded: [],
    dealsIncluded: [],
    callOptionType: 'SIDE_PANEL_ME_AND_TEAM_CALLS',
    viewMode: false,
    hideIncludeColumn: true,
  });

  const allBusinessTypes = useSelector(getBusinessTypes);
  const activeBusinessType = useSelector(getForecastActiveBusinessType);
  const openedRecords: string[] = useSelector(getForecastOpenedSettings);

  const settings = useSelector(getForecastSubmissionSettings);

  const data = groupBy(settings, 'business_type_name');
  const isOverall = activeBusinessType === OVERALL_BUSINESS_TYPE;

  const businessTypes: string[] = activeBusinessType
    ? [activeBusinessType]
    : allBusinessTypes.length
    ? allBusinessTypes
    : [''];

  if (isOverall) {
    data[OVERALL_BUSINESS_TYPE] = settings.filter(
      (s) =>
        s.business_type_name && allBusinessTypes.includes(s.business_type_name)
    );
  }

  const setOpenedSettings = (value: string[]) => {
    dispatch(actions.ui.manageForecast.setOpenedSettings(value));
  };

  const setActiveBusinessType = (settingsId: string) => {
    const selected = settings.find(({ id }) => id === settingsId) || null;
    const businessType = selected && selected.business_type_name;

    !isOverall &&
      dispatch(actions.ui.manageForecast.setActiveBusinessType(businessType));
  };

  const openDealsModal = useCallback(
    (settingsItem) => {
      dealsModal.open(
        {
          url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submission/deals_data/${settingsItem.id}`,
          queryParams: {
            included_deals: includedDeals[settingsItem.id] ?? [],
            page_size: 0,
          },
          submissionName: `${settingsItem.display_name} included deals`,
          dealsIncluded: includedDeals[settingsItem.id] ?? [],
          dealsExcluded: excludedDeals[settingsItem.id] ?? [],
          submissionSettingsId: settingsItem.id,
          viewMode: !settingsItem.include_exclude_supported,
        },
        ({ selectedDealsIds: included_deal_ids, allDealsIds }) => {
          const excluded_deal_ids = allDealsIds.filter(
            (id) => !included_deal_ids.includes(id)
          );

          onIncludedDealsChange(
            included_deal_ids,
            excluded_deal_ids,
            settingsItem.id
          );
        }
      );
    },
    [includedDeals, excludedDeals]
  );

  const setDealsPanel = (
    settingsItem: ForecastSubmissionSettingItemReduxState
  ) => {
    openDealsModal(settingsItem);
  };

  const lockingPeriodMessage = useMemo(() => {
    if (data[activeBusinessType] && data[activeBusinessType].length) {
      const lockingPeriodDisplay = data[activeBusinessType].find(
        (item) => !!item.submission_locking.locking_period_display
      );

      return (
        lockingPeriodDisplay?.submission_locking.locking_period_display || ''
      );
    }

    return '';
  }, [activeBusinessType, data]);

  return (
    <>
      <div className={styles.panelContainer} ref={ref}>
        <div className={styles.panelHeader}>
          <div className="row">
            <div className="title">
              <div className="main-text one-row">Submit Forecast</div>
              <div className="locking-period-message">
                {lockingPeriodMessage}
              </div>
            </div>
            <div className="schedule-submission-alert">
              <ScheduleSubmissionAlert />
            </div>
            <img
              alt="close"
              className={styles.closeButton}
              onClick={onClose}
              src={closeIcon}
            />
          </div>
        </div>

        <div className={styles.panelBody}>
          {businessTypes.map((item) => (
            <Fragment key={`businessType-${item}`}>
              {item && <div className={styles.recordTitle}>{item}</div>}

              {data[item] && data[item].length ? (
                data[item].map((settingsItem, idx) => {
                  const { id: settingsId } = settingsItem;

                  const included = includedDeals[settingsId] || [];
                  const isActive = openedRecords.includes(settingsId);
                  return (
                    <Record
                      businessType={item}
                      formatter={formatter}
                      included={included}
                      isActive={isActive}
                      shouldPreload={idx <= NUMBER_OF_SETTINGS_TO_PRELOAD - 1}
                      key={settingsId}
                      onIncludedDealsChange={onIncludedDealsChange}
                      resetIncludedAndExcludedDeals={
                        resetIncludedAndExcludedDeals
                      }
                      onSubmit={(amount, notes) => {
                        dispatch(
                          forecastSubmissionCreate(settingsId, {
                            amount,
                            notes,
                            included_deal_ids: included,
                          })
                        );
                      }}
                      openDealsModal={() => setDealsPanel(settingsItem)}
                      setting={{
                        ...settingsItem,
                        persist: persisted[settingsId] || null,
                      }}
                      setActiveBusinessType={setActiveBusinessType}
                      toggle={() =>
                        setOpenedSettings(
                          isActive
                            ? openedRecords.filter(
                                (record) => record !== settingsId
                              )
                            : [...openedRecords, settingsId]
                        )
                      }
                    />
                  );
                })
              ) : (
                <div className={styles.noSubmissions}>
                  No forecast submissions available for this business type
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>

      <ForecastDealsModal {...dealsModal.props} mountNode={ref?.current} />
    </>
  );
};

export default BasePanel;
