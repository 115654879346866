import { css } from 'emotion';

import { fontBody, fontCaption3 } from 'assets/css/fontStyles';

export const totalCard = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 2px;
  &.clickable {
    cursor: pointer !important;
  }
`;

export const totalCardHalfSpace = css`
  flex: 1 1 min(50%, 200px);
`;

export const totalCardAThirdOfSpace = css`
  flex: 1 1 min(33%, 200px);
`;

export const totalNumber = css`
  color: var(--bu-gray-900);
`;

export const tooltipAggregation = css`
  padding: 8px 0px;
`;

export const tooltipConditions = css``;

export const totalValueContainer = css`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 4px 8px;
`;

export const totalChartTypeDropdown = css`
  display: flex !important;
  justify-content: space-evenly;
  align-items: center;
  width: 47px !important;
  border-left: 1px solid var(--bu-gray-400);
  & > i {
    margin: unset;
  }
  &:hover {
    background-color: var(--bu-primary-200);
    & > i {
      color: var(--bu-primary-600);
    }
  }
`;

export const totalChartTypeIcon = css`
  font-size: 17px;
  color: var(--bu-primary-600);
`;

export const totalChartTypeDropdownIcon = css`
  font-size: 7px;
  margin: unset !important;
`;

export const totalChartTypeDropdownMenu = css`
  display: flex;
  flex-direction: column;
  margin-top: 4px !important;
  z-index: 988 !important;
`;

export const totalChartTypeDropdownOption = css`
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  ${fontBody}
  &>i {
    color: var(--bu-gray-700);
  }
  &:hover {
    background-color: var(--bu-primary-200);
    cursor: pointer;
  }
`;

export const metricNameOnlyContainer = css`
  display: flex;
  align-items: center;
  padding: 0 8px 0 0;
`;

export const metricNameParagraph = css`
  padding-left: 8px;
  font-size: 16px;
`;

export const pivotVisualizationLabel = css`
  ${fontCaption3}
  color: var(--bu-gray-700);
  text-transform: uppercase;
  margin: 10px;
`;

export const noDataParagraph = css`
  font-style: normal;
  font-weight: 600;
  font-size: 1.5vw !important;
  line-height: 120%;
  color: var(--bu-orange-600);
  cursor: pointer;
  padding-bottom: 0 !important;
`;

export const icon = css`
  color: var(--bu-orange-600);
`;

export const popupInfoContainer = css`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 7px;
  text-align: center;
  max-width: 194px;
`;

export const noDataContainer = css`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const popup = css`
  &.ui.popup {
    max-width: 480px;
  }
`;

export const totalTitle = css`
  color: var(--bu-gray-700);
  font-size: 12px;
  margin: 0px;
`;
