import styled from '@emotion/styled';
import React from 'react';

const BetaTagTSX = styled.span({
  padding: '1.5px 8px',
  height: 'fit-content',
  backgroundColor: 'var(--bu-green-500)',
  borderRadius: '12px',
  marginLeft: '8px',
  span: {
    color: 'var(--bu-white)',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
  },
});

export const BetaTag: React.FC = () => (
  <BetaTagTSX>
    <span>Beta</span>
  </BetaTagTSX>
);
