import classNames from 'classnames';
import { css } from 'emotion';
import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { formatMoney } from 'common/numbers';
import BuIcon from 'components/UI/BuIcon';
import {
  IRowWithChangesSince,
  ValueType,
  IColumn,
} from 'components/UI/common/TypedTable/TypedTable';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import * as selectors from 'selectors';

export interface IDeltaValueType {
  value: null | ValueType;
  prev_value: null | ValueType;
  updated_at: string;
  latest_updated_at: null | ValueType;
  latest_value: null | ValueType;
}

type Props = {
  row: IRowWithChangesSince;
  column: IColumn;
};

const multiCurrencyTooltipContainer = css`
  width: 5%;
  padding: 5px 0;
  margin-right: 10px;
  font-size: 20px;
`;

const multiCurrencyIcon = css`
  color: var(--bu-orange-600) !important;
  margin: auto 5px auto auto;
  position: relative;
`;

const MultiCurrencyIconTooltip: React.FC<Props> = ({ row, column }) => {
  const companyCurrency = useSelector(selectors.getCompanyCurrency);
  const cell = R.path(column.field.split('.'), row) as IDeltaValueType;

  return (
    <TooltipWrapper
      tooltip={
        <div>
          Original deal value:{' '}
          <strong>
            {formatMoney(
              (row.crm_metadata as any)?.currency || companyCurrency,
              Number(cell.value),
              0
            )}
          </strong>
        </div>
      }
    >
      <div
        className={classNames(
          'multi_currency-container',
          multiCurrencyTooltipContainer
        )}
      >
        <BuIcon
          name={BoostUpIcons.MultiCurrency}
          className={multiCurrencyIcon}
        />
      </div>
    </TooltipWrapper>
  );
};

export default MultiCurrencyIconTooltip;
