import React, { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { fetchApi } from 'utils/network';

type UserSettings = {
  currency: string;
};

const DEFAULT_USER_SETTINGS: UserSettings = {
  currency: 'USD',
};

const UserSettingsContext = createContext(DEFAULT_USER_SETTINGS);

const UserSettingsProvider = ({ children }: { children: any }) => {
  const [userSettings, setUserSettings] = useState<UserSettings>(
    DEFAULT_USER_SETTINGS
  );

  useEffect(() => {
    const abortController = new AbortController();

    fetchApi<string, UserSettings>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/settings`,
      queryMethod: 'get',
      setData: (result) => {
        setUserSettings(result);
      },
      setError: (error: string | null) => {
        setUserSettings(DEFAULT_USER_SETTINGS);
        console.error(error);
        toast.error('Failed fetching user settings');
      },
      signal: abortController.signal,
    });

    return () => {
      abortController.abort();
      setUserSettings(DEFAULT_USER_SETTINGS);
    };
  }, []);

  return (
    <UserSettingsContext.Provider value={userSettings}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export { UserSettingsContext, UserSettingsProvider };
