export const DEFAULT_MAX_ITEMS_PER_ROW = 5;

export const isLastRow = (
  itemIndex: number,
  itemsLength: number,
  itemsPerRow: number = DEFAULT_MAX_ITEMS_PER_ROW
): boolean => {
  const numberOfRows = Math.ceil(itemsLength / itemsPerRow);
  const rowOfItem = Math.ceil((itemIndex + 1) / itemsPerRow);
  return rowOfItem === numberOfRows;
};

export const getMetricListItemsPerRow = (
  itemsLength: number,
  metricListWidth: number
): number => {
  let maxItemsAllowed = DEFAULT_MAX_ITEMS_PER_ROW;
  if (metricListWidth < 650) {
    maxItemsAllowed = 2;
  } else if (metricListWidth < 800) {
    maxItemsAllowed = 3;
  } else if (metricListWidth < 1000) {
    maxItemsAllowed = 4;
  }

  return Math.min(maxItemsAllowed, itemsLength);
};
