import { css } from 'emotion';
import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import * as metricActions from 'actions/revbi/metrics';
import * as widgetActions from 'actions/revbi/widgets';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { WidgetAnalysisTypes } from 'components/dashboard/Metrics/Create/AnalysisTypes/WidgetAnalysisTypes/WidgetAnalysisTypes';
import { DefinitionsMetricsList } from 'components/dashboard/Metrics/Create/DefinitionsMetricsList/DefinitionsMetricsList';
import { FunnelMetricsList } from 'components/dashboard/Metrics/Create/DefinitionsMetricsList/FunnelMetricsList';
import FunnelDefaultMetricDefinition from 'components/dashboard/Metrics/Create/FunnelDefaultMetricDefinition';
import { SubTitle } from 'components/dashboard/Metrics/Create/FunnelDefaultMetricDefinition/styles';
import FunnelDefinition from 'components/dashboard/Metrics/Create/FunnelDefinition/FunnelDefinition';
import { getMetricType } from 'components/dashboard/Metrics/Create/MetricCreate/utils';
import { MetricDefinition } from 'components/dashboard/Metrics/Create/MetricDefinition';
import { ReportDefinition } from 'components/dashboard/Metrics/Create/ReportDefinition';
import { WidgetFilters } from 'components/dashboard/Metrics/Create/WidgetFilters/WidgetFilters';
import {
  DEFAULT_OPPORTUNITIES_ORDER_BY,
  FUNNEL_PREDEFINED_TOP_METRICS,
  OptionSections,
} from 'components/dashboard/Metrics/Create/constants';
import {
  DefinitionsContainer,
  WidgetOptionsColumn,
  WidgetOptionSection,
  SectionTitle,
  SectionTitleText,
  SectionWrapper,
} from 'components/dashboard/Metrics/Create/widget.create.styles';
import { parseFormulaToMetricsIdArray } from 'components/dashboard/Metrics/Widget/helper';
import { useMetricsList } from 'components/dashboard/Metrics/Widget/hooks/useMetricsList';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import {
  AggregationFunction,
  BIMetricCreated,
  BIMetricFormula,
  BIMetricSimple,
  BIMetricUnion,
  BIWidget,
  MetricType,
} from 'components/dashboard/Metrics/metrics.types';
import { IReduxState } from 'reducers/types';
import * as metricSelectors from 'selectors/revbi/metrics';
import * as widgetSelectors from 'selectors/revbi/widgets';

interface Props {
  sectionExpanded: OptionSections;
  widget: BIWidget;
  metric: BIMetricUnion;
  metricsFromList: BIMetricCreated[];
  isValidReportViewInput: boolean;
  isCreateNewMetric: boolean;
  updateWidget: (widget: Partial<BIWidget>) => void;
  setSectionExpanded: Dispatch<SetStateAction<OptionSections>>;
  setIsCreateNewMetric: Dispatch<SetStateAction<boolean>>;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  setIsTopMetricsSidebarOpen: Dispatch<SetStateAction<boolean>>;
}

export const WidgetCreateOptions: React.FC<Props> = ({
  sectionExpanded,
  widget,
  metric,
  metricsFromList,
  isValidReportViewInput,
  isCreateNewMetric,
  updateWidget,
  setSectionExpanded,
  setIsCreateNewMetric,
  setIsSidebarOpen,
  setIsTopMetricsSidebarOpen,
}) => {
  const dispatch = useDispatch();

  const match = useRouteMatch<{ widgetId: string }>();

  const [isContinueButtonClicked, setContinueButtonClicked] = useState<boolean>(
    () => (match.params.widgetId ? true : false)
  );

  const topMetricsFromList = FUNNEL_PREDEFINED_TOP_METRICS.filter((metric) =>
    widget.funnel_top_metrics?.includes(metric._id)
  );

  const columnListLive = useSelector((state: IReduxState) =>
    widgetSelectors.getColumnsList(state, widget._id)
  );
  const columnListNotLive = useSelector((state: IReduxState) =>
    metricSelectors.getColumnFieldsByObject(state, metric.object)
  );

  const usedFormulaMetricIds = useMemo(
    () =>
      metricsFromList
        .filter((m) => getMetricType(m) === MetricType.Formula)
        .flatMap((m) =>
          parseFormulaToMetricsIdArray((m as BIMetricFormula).synthetic_metric)
        ),
    [metricsFromList]
  );

  const { data: usedMetricsMap } = useMetricsList(usedFormulaMetricIds);

  useEffect(() => {
    if (widget.analysis_type === AnalysisType.LIVE) {
      const objectsSet = new Set<string>();

      metricsFromList.forEach((metric) => {
        const metricType = getMetricType(metric);

        if (metricType === MetricType.Simple) {
          objectsSet.add(metric.object);
        }

        if (metricType === MetricType.Formula) {
          Object.keys(usedMetricsMap).forEach((element) => {
            const metricObject = usedMetricsMap[element].object;

            if (metricObject) {
              objectsSet.add(metricObject);
            }
          });
        }
      });

      if (metric.object && isCreateNewMetric) {
        objectsSet.add(metric.object);
      }

      dispatch(
        widgetActions.fetchColumnsList(Array.from(objectsSet), widget._id)
      );
    }
    dispatch(metricActions.fetchColumnFields(metric.object));
  }, [widget.analysis_type, metricsFromList, metric.object, usedMetricsMap]);

  useEffect(() => {
    return () => {
      dispatch(widgetActions.resetWidgetStorage());
    };
  }, []);

  const columnList = useMemo(() => {
    if (widget.analysis_type === AnalysisType.LIVE) {
      return columnListLive;
    }
    return columnListNotLive;
  }, [widget.analysis_type, columnListNotLive, columnListLive]);

  const updateMetric = (metric: BIMetricUnion): void => {
    dispatch(metricActions.changeActiveMetric(metric));
  };

  const handleChangeAnalysisType = (analysisType: AnalysisType): void => {
    updateWidget({
      ...widget,
      analysis_type: analysisType,
      widget_filters: [],
      group_by: [],
      chart_type:
        analysisType === AnalysisType.REPORT
          ? 'table'
          : analysisType === AnalysisType.FUNNEL
          ? 'funnel'
          : 'column',
      order_by_column: DEFAULT_OPPORTUNITIES_ORDER_BY,
      time_period:
        analysisType === AnalysisType.FUNNEL ? 'current_quarter' : undefined,
    });

    updateMetric({
      ...metric,
      name: analysisType === AnalysisType.REPORT ? 'report-view-metric' : '',
      aggregation_function: AggregationFunction.Sum,
      analysis_type: analysisType,
    });
  };

  const handleContinueButtonClick = (nextSection: OptionSections): void => {
    setSectionExpanded(nextSection);
    setContinueButtonClicked(true);
  };

  const handleRemoveMetric = (metric: BIMetricCreated): void => {
    dispatch(
      widgetActions.removeMetricFromMetricsFromList({
        metricId: metric._id,
        widgetId: widget._id,
      })
    );
  };

  const handleRemoveTopMetric = (metric: BIMetricCreated): void => {
    updateWidget({
      ...widget,
      funnel_top_metrics: widget.funnel_top_metrics?.filter(
        (el) => el !== metric._id
      ),
    });
  };

  const handleSaveMetric = (): void => {
    if ((metric as BIMetricSimple)._id === undefined) {
      dispatch(
        widgetActions.createMetricForWidgetCreation(
          metric,
          match.params.widgetId
        )
      );
    }
    if (typeof (metric as BIMetricSimple)._id === 'string') {
      const metricForUpdating = metric as BIMetricSimple | BIMetricFormula;
      dispatch(
        widgetActions.updateMetricForWidgetCreation(
          metricForUpdating,
          match.params.widgetId
        )
      );
    }
    setIsCreateNewMetric(false);
  };

  const hasFunnelCompleted = (widget: BIWidget): boolean => {
    return (
      widget.funnel_stage_column !== undefined &&
      widget.funnel_stages?.length !== 0
    );
  };

  return (
    <WidgetOptionsColumn data-testing="widget-section">
      <WidgetOptionSection
        expanded={sectionExpanded === OptionSections.TYPE}
        sections={widget.analysis_type === AnalysisType.FUNNEL ? 4 : 3}
        first
        data-testing="type-of-widget-section"
      >
        <SectionTitle
          onClick={() => {
            setSectionExpanded(OptionSections.TYPE);
          }}
        >
          <BuIcon
            name={BoostUpIcons.BadgeCheckSolid}
            color={
              isContinueButtonClicked
                ? 'var(--bu-green-500)'
                : 'var(--bu-gray-500)'
            }
          />
          <SectionTitleText>Type of widget</SectionTitleText>
          <BuIcon
            name={
              sectionExpanded === OptionSections.TYPE
                ? BoostUpIcons.ChevronUp
                : BoostUpIcons.ChevronDown
            }
            className={css`
              font-size: 20px;
            `}
          />
        </SectionTitle>
        <SectionWrapper hidden={sectionExpanded !== OptionSections.TYPE}>
          <WidgetAnalysisTypes
            selectedAnalysisType={
              (widget.analysis_type as AnalysisType) || AnalysisType.LIVE
            }
            disabled={isContinueButtonClicked}
            onChangeAnalysisType={handleChangeAnalysisType}
            onContinueButtonClick={handleContinueButtonClick}
          />
        </SectionWrapper>
      </WidgetOptionSection>
      <WidgetOptionSection
        hidden={widget.analysis_type !== AnalysisType.FUNNEL}
        expanded={sectionExpanded === OptionSections.FUNNEL}
        sections={widget.analysis_type === AnalysisType.FUNNEL ? 4 : 3}
        first={false}
        data-testing="widget-definition-section"
      >
        <SectionTitle
          onClick={() => {
            if (widget.analysis_type === AnalysisType.FUNNEL) {
              setSectionExpanded(OptionSections.FUNNEL);
            }
          }}
        >
          <BuIcon
            name={BoostUpIcons.BadgeCheckSolid}
            color={
              hasFunnelCompleted(widget)
                ? 'var(--bu-green-500)'
                : 'var(--bu-gray-500)'
            }
          />
          <SectionTitleText>Funnel definition</SectionTitleText>
          <BuIcon
            name={
              sectionExpanded === OptionSections.FUNNEL
                ? BoostUpIcons.ChevronUp
                : BoostUpIcons.ChevronDown
            }
            className={css`
              font-size: 20px;
            `}
          />
        </SectionTitle>
        <SectionWrapper hidden={sectionExpanded !== OptionSections.FUNNEL}>
          <DefinitionsContainer>
            {widget.analysis_type === AnalysisType.FUNNEL && (
              <FunnelDefinition
                widget={widget}
                setWidget={updateWidget}
                onContinueButtonClick={handleContinueButtonClick}
              />
            )}
          </DefinitionsContainer>
        </SectionWrapper>
      </WidgetOptionSection>
      <WidgetOptionSection
        expanded={sectionExpanded === OptionSections.METRIC}
        sections={widget.analysis_type === AnalysisType.FUNNEL ? 4 : 3}
        first={false}
        data-testing="widget-definition-section"
      >
        <SectionTitle
          onClick={() => {
            setSectionExpanded(OptionSections.METRIC);
          }}
        >
          <BuIcon
            name={BoostUpIcons.BadgeCheckSolid}
            color={
              ((metric as BIMetricSimple).aggregation_function &&
                (metric as BIMetricSimple).column) ||
              metricsFromList?.length ||
              isValidReportViewInput
                ? 'var(--bu-green-500)'
                : 'var(--bu-gray-500)'
            }
          />
          <SectionTitleText>
            {widget.analysis_type === AnalysisType.REPORT
              ? 'Report'
              : 'Metrics'}{' '}
            definition
          </SectionTitleText>
          <BuIcon
            name={
              sectionExpanded === OptionSections.METRIC
                ? BoostUpIcons.ChevronUp
                : BoostUpIcons.ChevronDown
            }
            className={css`
              font-size: 20px;
            `}
          />
        </SectionTitle>
        <SectionWrapper hidden={sectionExpanded !== OptionSections.METRIC}>
          <DefinitionsContainer>
            {widget.analysis_type === AnalysisType.FUNNEL && (
              <>
                <FunnelDefaultMetricDefinition
                  widget={widget}
                  setWidget={updateWidget}
                />
                <SubTitle>Metrics</SubTitle> {/* Title For the next section */}
              </>
            )}
            <>
              {isCreateNewMetric ? (
                <MetricDefinition
                  setIsCreateNewMetric={setIsCreateNewMetric}
                  onSaveMetric={handleSaveMetric}
                />
              ) : widget.analysis_type === AnalysisType.FUNNEL ? (
                <FunnelMetricsList
                  metricsFromList={metricsFromList}
                  setIsSideBarOpen={setIsSidebarOpen}
                  onRemoveMetric={handleRemoveMetric}
                />
              ) : (
                <DefinitionsMetricsList
                  analysisType={widget.analysis_type as AnalysisType}
                  metricsFromList={metricsFromList}
                  setIsCreateNewMetric={setIsCreateNewMetric}
                  setIsSideBarOpen={setIsSidebarOpen}
                  onRemoveMetric={handleRemoveMetric}
                />
              )}
              {widget.analysis_type === AnalysisType.FUNNEL && (
                <>
                  <SubTitle>Top Metrics</SubTitle>
                  <FunnelMetricsList
                    metricsFromList={topMetricsFromList}
                    setIsSideBarOpen={setIsTopMetricsSidebarOpen}
                    onRemoveMetric={handleRemoveTopMetric}
                  />
                </>
              )}
            </>
            {widget.analysis_type === AnalysisType.REPORT && (
              <ReportDefinition
                widget={widget}
                metric={metric}
                columnList={columnList}
                setMetric={updateMetric}
                setWidget={updateWidget}
              />
            )}
          </DefinitionsContainer>
        </SectionWrapper>
      </WidgetOptionSection>
      <WidgetOptionSection
        expanded={sectionExpanded === OptionSections.TEMPLATE_FILTERS}
        sections={widget.analysis_type === AnalysisType.FUNNEL ? 4 : 3}
        first={false}
        data-testing="widget-filters-section"
      >
        <SectionTitle
          onClick={() => {
            setSectionExpanded(OptionSections.TEMPLATE_FILTERS);
          }}
        >
          <BuIcon
            name={BoostUpIcons.BadgeCheckSolid}
            color={
              widget.widget_filters?.length
                ? 'var(--bu-green-500)'
                : 'var(--bu-gray-500)'
            }
          />
          <SectionTitleText>Widget filters (optional)</SectionTitleText>
          <BuIcon
            name={
              sectionExpanded === OptionSections.TEMPLATE_FILTERS
                ? BoostUpIcons.ChevronUp
                : BoostUpIcons.ChevronDown
            }
            className={css`
              font-size: 20px;
            `}
          />
        </SectionTitle>

        <SectionWrapper
          hidden={sectionExpanded !== OptionSections.TEMPLATE_FILTERS}
        >
          <WidgetFilters
            widget={widget}
            columns={columnList}
            updateWidget={updateWidget}
          />
        </SectionWrapper>
      </WidgetOptionSection>
    </WidgetOptionsColumn>
  );
};
