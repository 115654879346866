import { metricObjectToPlural } from '../helpers';
import React from 'react';

import {
  descriptionParagraph,
  labelParagraph,
  tooltipContainer,
} from 'components/dashboard/Metrics/Widget/Report/Tooltip/styles';
import { ConditionsDescription } from 'components/dashboard/Metrics/common/ConditionsDescription/ConditionsDescription';
import { BIMetricsQueryFilter } from 'components/dashboard/Metrics/metrics.types';

interface Props {
  readonly filters: BIMetricsQueryFilter[];
  readonly object: string;
  readonly orderByDirection: string;
  readonly orderByColumn: string;
  readonly rowsLength: number;
}

export const TooltipContent: React.FC<Props> = ({
  filters = [],
  object,
  orderByDirection,
  orderByColumn,
  rowsLength = 0,
}) => (
  <div className={tooltipContainer}>
    <p className={labelParagraph}>Showing:</p>
    <p className={descriptionParagraph}>{`${rowsLength} ${metricObjectToPlural(
      object
    )} records ordered by ${orderByColumn} in ${orderByDirection} order`}</p>

    {Boolean(filters.length) && <ConditionsDescription filters={filters} />}
  </div>
);
