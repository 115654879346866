import Highcharts, {
  PointOptionsObject,
  SeriesAreaOptions,
  SeriesLineOptions,
  XAxisEventsOptions,
  XAxisLabelsOptions,
} from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highstock from 'highcharts/highstock';
import * as R from 'ramda';
import React, { Component } from 'react';
import { renderToString } from 'react-dom/server';
import { Checkbox } from 'semantic-ui-react';

import { formatMoney, formatAmount } from 'common/numbers';
import { MAX_ITEMS_TO_DISPLAY } from 'components/chart-dashboards/Widget/Widget';
import * as s from 'components/chart-dashboards/Widget/styles';
import {
  ChartType,
  ClickEvent,
  DataItemType,
  SeriesType,
} from 'components/chart-dashboards/Widget/types';

const RGBToColor = {
  'rgb(111,163,255)': 'var(--bu-primary-400)',
  'rgb(4,218,178)': 'var(--bu-green-400)',
  'rgb(255,114,113)': 'var(--bu-red-400)',
  'rgb(50,124,255)': '#30b0d1',
  '#9b9b9b': 'var(--bu-gray-500)',
  '#587eb0': 'var(--bu-primary-500)',
  '#6fa3ff': 'var(--bu-primary-400)',
  '#04dab2': 'var(--bu-green-400)',
  '#6dc7df': 'var(--bu-primary-400)',
};

const changeColorsCharts = (array: any[], chart_type: string) => {
  array.forEach((el) => {
    if (el.color === undefined && chart_type !== 'waterfall') {
      el.data.map((item: any) => {
        item.color = 'var(--bu-primary-400)';
      });
    }

    if (el.data) {
      el.data.map((item: any) => {
        if (item.color) {
          //@ts-ignore
          item.color = RGBToColor[item.color];
        }
      });
    }
  });

  array.forEach((el) => {
    if (el.color) {
      //@ts-ignore
      el.color = RGBToColor[el.color];
    }
  });
};

const unallowedNames = ['Totals', 'id', '_id'];

const MAX_X_AXIS_IN_VIEW = 25;

interface IProps {
  chartType: ChartType;
  config: {
    color?: string;
    legends?: boolean;
    seriesColors?: { [index: string]: string };
    seriesName?: string;
    tooltipFormatter?: string;
    yAxisFormatter?: string;
    yAxisTitle?: string;
    inside?: boolean;
  };
  data: any[];
  displayName: string;
  labelPath: string;
  onClick: Function | null;
  series?: string[];
  setVisibleSeries: Function;
  title: string;
  valuePath: string;
  visibleSeries?: string[];
  companyCurrencyCode: string;
  tooltip?: Highcharts.TooltipOptions;
  xAxisLabelFormatter?: Highcharts.AxisLabelsFormatterCallbackFunction;
}

export default class Chart extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.updateSeriesVisibility();
  }

  private seriesVisibility: { [key: string]: boolean } = {};

  private updateSeriesVisibility = () => {
    const { visibleSeries } = this.props;
    if (visibleSeries) {
      visibleSeries.forEach((sName) => (this.seriesVisibility[sName] = true));
    }
  };

  shouldComponentUpdate(nextProps: IProps) {
    const { config, data, valuePath, visibleSeries } = this.props;

    return (
      JSON.stringify(visibleSeries) !==
        JSON.stringify(nextProps.visibleSeries) ||
      JSON.stringify(data) !== JSON.stringify(nextProps.data) ||
      JSON.stringify(config) !== JSON.stringify(nextProps.config) ||
      valuePath !== nextProps.valuePath
    );
  }

  componentDidUpdate() {
    this.updateSeriesVisibility();
  }

  render() {
    const props = this.props;
    const { seriesVisibility } = this;
    const isWaterfallChart = props.chartType === 'waterfall';
    const labelPath = props.labelPath.split('.');
    const valuePath = props.valuePath.split('.');

    const labels = props.data.length
      ? props.data &&
        props.data[0] &&
        props.data[0].data &&
        props.data[0].data
          .map((item: DataItemType) => R.path(labelPath, item))
          .slice(0, MAX_ITEMS_TO_DISPLAY)
      : [];

    const selectedSeries = props.series
      ? props.data.filter(
          (series: SeriesType) =>
            props.series && props.series.includes(series.name)
        )
      : props.data;

    const seriesValues = selectedSeries.map((series) => {
      const seriesData: DataItemType[] = series.data
        ? series.data.map((item: DataItemType) =>
            props.chartType === 'columnrange'
              ? item
              : { y: R.path(valuePath, item), ...item }
          )
        : [];

      return {
        cropThreshold: seriesData.slice(0, MAX_ITEMS_TO_DISPLAY).length + 1,
        name:
          props.displayName === 't'
            ? series.name
            : props.displayName || series.name,
        visible: props.visibleSeries
          ? props.visibleSeries.includes(series.name)
          : true,
        data: seriesData.slice(0, MAX_ITEMS_TO_DISPLAY),
        color: series.color
          ? series.color
          : props.config.seriesColors
          ? props.config.seriesColors && props.config.seriesColors[series.name]
          : undefined,
        internalName: series.internal_name,
        type: props.chartType === 'line' && 'area',
      } as SeriesLineOptions | SeriesAreaOptions;
    });

    changeColorsCharts(seriesValues, props.chartType);

    const getYAxisFormatter = () => {
      if (props.config.yAxisFormatter) {
        return function () {
          return eval(props.config.yAxisFormatter!);
        };
      } else if (props.valuePath === 'total_amount') {
        return function ({ value }: any) {
          return formatAmount(value, props.companyCurrencyCode);
        };
      } else {
        return null;
      }
    };

    const renderLegendLabel = (this_: any) => {
      const {
        name,
        visible,
        userOptions: { color, internalName },
      } = this_;

      if (unallowedNames.includes(internalName)) {
        return null;
      }

      const explicitStyle = [
        'best_case',
        'booked',
        'commit',
        'pipeline',
      ].includes(internalName)
        ? { color }
        : undefined;

      const label = (
        <div className={s.legendLabelContainer}>
          <Checkbox className={s.legendLabelCheckbox} checked={visible} />
          <span className={s.circle_legend(explicitStyle?.color)} />
          <span className={s.legendLabelText}>{name}</span>
        </div>
      );

      return renderToString(label);
    };

    function getClickHandler() {
      if (props.onClick === null) {
        return null;
      }

      return (event: ClickEvent) => {
        const seriesType = props.data.find(
          (el: SeriesType) => el.name === event.point.series.name
        ) || { data: [], name: '' };

        let series =
          props.data.length <= 2
            ? props.data[0]['data'].find(
                (series: DataItemType) =>
                  series.name === event.point.series.name ||
                  R.path(labelPath, series) === event.point.category ||
                  (series.user && series.user.name === event.point.category)
              )
            : seriesType.data.find(
                (el: DataItemType) =>
                  R.path(labelPath, el) === event.point.series.name ||
                  el.label === event.point.category
              );

        const point =
          series && series.data
            ? series.data.find(
                (item: DataItemType) =>
                  R.path(labelPath, item) === event.point.category
              )
            : series;

        props.onClick &&
          props.onClick(point.filters, point[props.labelPath], seriesType.name);
      };
    }

    function dataLabelFormatter(this: any): string {
      const { y } = this;

      if (isWaterfallChart) {
        return formatAmount(y, props.companyCurrencyCode);
      }

      const seriesValueForSegment = props.data.reduce(
        (acc, cur) => {
          if (
            seriesVisibility[cur.name] &&
            cur.data[this.point.index] &&
            props.visibleSeries?.includes(cur.name)
          ) {
            const value = cur.data[this.point.index].total_amount;
            acc.values.push(value);

            if (value < 0) {
              acc.negativeSum += value;
            } else {
              acc.positiveSum += value;
            }
          }

          return acc;
        },
        { negativeSum: 0, positiveSum: 0, values: [] }
      );

      let label = '';

      if (y < 0 && y === Math.min(...seriesValueForSegment.values)) {
        label = formatAmount(
          seriesValueForSegment.negativeSum,
          props.companyCurrencyCode
        );
      } else if (
        y > 0 &&
        seriesValueForSegment.values.filter((item: number) => !!item)[0] === y
      ) {
        label = formatAmount(
          seriesValueForSegment.positiveSum,
          props.companyCurrencyCode
        );
      }

      return label;
    }

    //@ts-ignore
    const legendClickHandler = (event) => {
      const hidden = !event.target.userOptions.visible;
      const visibleSeries = event.target.chart.series
        .filter(
          //@ts-ignore
          (x) =>
            x.userOptions.name == event.target.userOptions.name
              ? hidden
              : x.userOptions.visible
        )
        .map(
          //@ts-ignore
          (x) => x.userOptions.name
        );
      setTimeout(() => {
        props.setVisibleSeries(props.title, visibleSeries);
      }, 1000);
    };

    const tooltipFormatter = () => {
      if (props.config.tooltipFormatter) {
        return function () {
          return eval(props.config.tooltipFormatter!);
        };
      } else {
        return function (this: any) {
          if (props.valuePath === 'total_amount') {
            const amount = formatMoney(props.companyCurrencyCode, this.y, 0);

            return `${this.x}<br/>${this.series.name}: ${amount}`;
          }
          return `${this.x} <br/> ${props.displayName}: ${this.y}`;
        };
      }
    };

    const clickHandler = getClickHandler();
    const isVisibleLegends = props.data.length > 1;

    const values = [
      ...seriesValues
        .map((s) => s.data!.map((i) => (i as PointOptionsObject).y || 0))
        .flat(),
      0,
    ];

    const yAxisMinMax = !['column', 'waterfall'].includes(props.chartType);

    const config = {
      credits: { enabled: false },
      title: {
        text: '',
      },
      chart: {
        type: props.chartType,
        spacing: [0, 0, 0, 0],
        marginTop: 0,
        panning: true,
        plotBorderWidth: 1,
        plotBorderColor: '#D6D7DE',
      },
      scrollbar: {
        enabled: labels.length - 1 > MAX_X_AXIS_IN_VIEW,

        height: 8,

        barBorderWidth: 1,
        buttonBorderWidth: 1,
        trackBorderWidth: 1,

        barBorderRadius: 5,
        buttonBorderRadius: 5,
        trackBorderRadius: 5,

        barBackgroundColor: '#c3c3c3',
        buttonBackgroundColor: '#c3c3c3',
        trackBackgroundColor: '#e5e5e5',

        buttonArrowColor: '#e5e5e5',
        rifleColor: '#c3c3c3',

        barBorderColor: '#c3c3c3',
        buttonBorderColor: '#c3c3c3',
        trackBorderColor: '#e5e5e5',
      },
      xAxis: {
        categories: labels,
        min: 0,
        max:
          labels.length - 1 > MAX_X_AXIS_IN_VIEW
            ? MAX_X_AXIS_IN_VIEW
            : labels.length - 1,
        labels: {
          useHTML: true,
          style: {
            lineHeight: 'normal',
            textOverflow: 'ellipsis',
            textAlign: 'center',
            width: labels.length > 10 ? 80 : null,
          },
          formatter: props.xAxisLabelFormatter
            ? props.xAxisLabelFormatter
            : function () {
                if (typeof this.value !== 'number') {
                  return this.value;
                }
                return '';
              },
        } as XAxisLabelsOptions,
        events: {
          afterSetExtremes() {
            const xAxis = this,
              numberOfPoints = xAxis.series[0].points.length - 1,
              minRangeValue = xAxis.getExtremes().min,
              maxRangeValue = xAxis.getExtremes().max;

            if (minRangeValue < 0) {
              xAxis.setExtremes(xAxis.options.min!, xAxis.options.max!);
            } else if (maxRangeValue > numberOfPoints) {
              xAxis.setExtremes(
                numberOfPoints - xAxis.options.max!,
                numberOfPoints
              );
            }
          },
        } as XAxisEventsOptions,
      },
      yAxis: {
        allowDecimals: false,
        min: yAxisMinMax ? Math.min.apply(Math, values) : undefined,
        max: yAxisMinMax ? Math.max.apply(Math, values) + 1 : undefined,
        gridLineDashStyle: 'dash',
        title: {
          text: props.config.yAxisTitle || '',
        },
        showLastLabel: false,
        labels: {
          formatter: getYAxisFormatter(),
        },
        plotLines: isWaterfallChart
          ? undefined
          : [
              {
                value: 0,
                color: '#666666',
                dashStyle: 'solid',
                width: 2,
                zIndex: 5,
              },
            ],
      },
      legend: {
        enabled: isVisibleLegends,
        useHTML: true,
        align: 'right',
        verticalAlign: 'top',
        borderWidth: 0,
        symbolHeight: 0.001,
        symbolWidth: 0.001,
        symbolRadius: 0.001,
        labelFormatter: function () {
          return renderLegendLabel(this);
        },
        y: -48,
        x: -120,
      },
      tooltip: props.tooltip ?? {
        useHTML: true,
        enabled: true,
        formatter: tooltipFormatter(),
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
        line: {
          animation: false,
        },
        series: {
          animation: false,
          cursor: clickHandler ? 'pointer' : 'normal',
          events: {
            click: clickHandler,
            legendItemClick: legendClickHandler,
          },
          dataLabels: {
            color: '#666666',
            crop: false,
            enabled: true,
            formatter: dataLabelFormatter,
            inside: false,
            overflow: 'justify',
            style: {
              fontWeight: 'normal',
            },
          },
        },
      },
      series: seriesValues,
    };

    if (props.chartType === 'line' && seriesValues && seriesValues[0]) {
      seriesValues[0].color = 'var(--bu-primary-500)';
    }

    const configLine = {
      credits: { enabled: false },
      chart: {
        type: 'line',
        spacing: [0, 0, 0, 0],
        marginTop: 0,
        panning: true,
        plotBorderWidth: 1,
        plotBorderColor: '#D6D7DE',
      },
      title: {
        text: null,
      },
      yAxis: {
        allowDecimals: false,
        showLastLabel: false,
        min:
          labels.length - 1 > MAX_X_AXIS_IN_VIEW
            ? Math.min.apply(Math, values)
            : undefined,
        max:
          labels.length - 1 > MAX_X_AXIS_IN_VIEW
            ? Math.max.apply(Math, values) + 1
            : undefined,
        gridLineDashStyle: 'dash',
        title: {
          text: props.config.yAxisTitle || '# of Accounts →',
        },
        labels: {
          formatter: getYAxisFormatter(),
        },
      },
      xAxis: {
        crosshair: {
          width: 1,
          dashStyle: 'dash',
          color: '#4AB2D1',
        },
        categories: labels,
        min: 0,
        max:
          labels.length - 1 > MAX_X_AXIS_IN_VIEW
            ? MAX_X_AXIS_IN_VIEW
            : labels.length - 1,
        scrollbar: {
          enabled: labels.length - 1 > MAX_X_AXIS_IN_VIEW,

          height: 10,

          barBorderWidth: 1,
          buttonBorderWidth: 1,
          trackBorderWidth: 1,

          barBorderRadius: 5,
          buttonBorderRadius: 5,
          trackBorderRadius: 5,

          barBackgroundColor: '#c3c3c3',
          buttonBackgroundColor: '#c3c3c3',
          trackBackgroundColor: '#e5e5e5',

          buttonArrowColor: '#e5e5e5',
          rifleColor: '#c3c3c3',

          barBorderColor: '#c3c3c3',
          buttonBorderColor: '#c3c3c3',
          trackBorderColor: '#e5e5e5',
        },
        labels: {
          style: {
            lineHeight: 'normal',
            textOverflow: 'ellipsis',
            width: labels.length > 10 ? 80 : null,
          },
          formatter() {
            if (typeof this.value !== 'number') {
              return this.value;
            }
            return '';
          },
        } as XAxisLabelsOptions,
        events: {
          afterSetExtremes() {
            const xAxis = this,
              numberOfPoints = xAxis.series[0].points.length - 1,
              minRangeValue = xAxis.getExtremes().min,
              maxRangeValue = xAxis.getExtremes().max;

            if (minRangeValue < 0) {
              xAxis.setExtremes(xAxis.options.min!, xAxis.options.max!);
            } else if (maxRangeValue > numberOfPoints) {
              xAxis.setExtremes(
                numberOfPoints - xAxis.options.max!,
                numberOfPoints
              );
            }
          },
        } as XAxisEventsOptions,
      },
      legend: {
        valueDecimals: 2,
        enabled: isVisibleLegends,
        align: 'center',
        verticalAlign: 'top',
        borderWidth: 0,
      },
      tooltip: {
        enabled: true,
        formatter: function (this: any) {
          const tooltipText = `${this.x} <br/> ${this.series.name}: ${this.y}`;
          if (this.series.name === 'Percentage') {
            return `${tooltipText}%`;
          }
          return tooltipText;
        },
      },
      plotOptions: {
        column: {
          stacking: 'normal',
        },
        series: {
          color: 'var(--bu-primary-500)',
          cursor: clickHandler ? 'pointer' : 'normal',
          dashStyle: 'Solid',
          fillOpacity: 0.3,
          events: {
            click: clickHandler,
            legendItemClick: legendClickHandler,
          },
          marker: {
            states: {
              hover: {
                radiusPlus: 3,
              },
            },
            fillColor: 'var(--bu-white)',
            lineWidth: 4,
            lineColor: 'var(--bu-primary-500)',
            radius: 4,
            enabled: true,
          },
          lineWidth: 1,
        },
        area: {
          fillColor: 'rgba(48, 176, 209, 0.1)',
        },
      },
      series: seriesValues,
    };

    return (
      <HighchartsReact
        highcharts={isWaterfallChart ? Highcharts : Highstock}
        options={props.chartType === 'line' ? configLine : config}
        containerProps={{ className: s.chartContainer }}
      />
    );
  }
}
