/* eslint-disable react/jsx-wrap-multilines */

/* eslint-disable import/prefer-default-export */
import clx from 'classnames';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { createCustomView, updateCustomView } from 'actions/customViews';
import {
  USER_ROLES,
  tabsWithAnalytics,
  allTabs,
  BusinessTypeTab,
} from 'common/constants';
import { BuControlSize } from 'components/UI/BuButton';
import BuCheckbox from 'components/UI/BuCheckbox';
import {
  action_btn,
  action_btns,
  checkbox_text,
  error_message,
  input_label,
  input_text,
  note_text,
  option_body,
  option_box,
  option_box_checkbox,
  option_header,
  options,
} from 'components/UI/OpenFiltersPanel/SaveViewFilters/styles';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { IReduxState } from 'reducers/types';
import {
  getActiveFiltersProfile,
  getFiltersForCustomViews,
  getForecastActiveBusinessType,
  getPersistName,
  getSelectedBusinessTypeForPanel,
  getShowOrHideColumns,
  getUserRole,
  getShowCustomSharedViews,
} from 'selectors';

enum ProfileType {
  DEFAULT = 'DEFAULT',
  GLOBAL = 'GLOBAL',
  PERSONAL = 'PERSONAL',
}

interface ProfileEditorProps {
  cancel: () => void;
  open: boolean;
  position: 'right' | 'left';
  tab: string;
}

const INPUT_ERROR_TIMEOUT = 3000; // msec

const emptyCurrent = {
  name: '',
  profile: {
    deleteable: false,
    id: '',
    view_type: '',
    pinned: false,
  },
};

export const ProfileEditor: React.FC<ProfileEditorProps> = ({
  cancel,
  open,
  position,
  tab,
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    userRole,
    persistName,
    showOrHideColumns,
    selectedBusinessType,
    isShowCustomSharedViews,
  } = useSelector((state: IReduxState) => {
    const isModal = false;
    return {
      userRole: getUserRole(state),
      persistName: getPersistName(state, isModal),
      showOrHideColumns: getShowOrHideColumns(state, isModal),
      selectedBusinessType: allTabs.includes(tab as BusinessTypeTab)
        ? getSelectedBusinessTypeForPanel(state, tab as BusinessTypeTab)
        : getForecastActiveBusinessType(state),
      isShowCustomSharedViews: getShowCustomSharedViews(state),
    };
  });

  const current =
    useSelector((state: IReduxState) => getActiveFiltersProfile(state, tab)) ||
    emptyCurrent;

  const isOwner = current.profile.deleteable; // if user can delete it, that means they own (or create) the view

  const properties = useSelector((state: IReduxState) =>
    getFiltersForCustomViews(state, tab)
  );

  const [inputError, setInputError] = useState<string>('');
  const [isDefault, setDefault] = useState<boolean>(false);
  const [isGlobal, setGlobal] = useState<boolean>(false);
  const [name, setName] = useState<string>(current.name);

  const isCurrentDefault = current.profile.view_type === ProfileType.DEFAULT;
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  useEffect(
    () => () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    },
    []
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    if (userRole === USER_ROLES.SALES_MANAGER) {
      setDefault(current.profile.view_type === ProfileType.DEFAULT);
      setGlobal(current.profile.view_type === ProfileType.GLOBAL);
    }

    setName(current.name);
  }, [current.name, current.profile.view_type, userRole]);

  const save = (isNew?: boolean) => {
    if (name && properties) {
      const data: Filters.CustomView = {
        view_type: isGlobal ? ProfileType.GLOBAL : ProfileType.PERSONAL,
        view_section: tab,
        pinned: isNew ? false : Boolean(current.profile.pinned),
        properties: {
          ...properties,
          tablePersistName: persistName,
          columnToggleState: showOrHideColumns,
          display_name: name,
          selectedBusinessType,
        },
      };

      if (isNew) {
        dispatch(createCustomView(data));
      } else if (current.profile.id) {
        dispatch(updateCustomView(current.profile.id, data));
      }

      if (tabsWithAnalytics.includes(tab)) {
        AnalyticsTracker.event(
          { tab, data },
          {
            category: isNew ? 'Save view' : 'Update view',
            action: isNew ? 'Create new view' : 'Update view',
            label: name,
          }
        );
      }

      cancel();
    }
  };

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (inputError) {
      setInputError('');
    }

    setName(event.target.value);
  };

  const handleChangeCheckbox = (value: boolean, id: ProfileType) => {
    if (id === ProfileType.DEFAULT) {
      if (value) {
        setGlobal(false);
      }

      setDefault(value);
    }

    if (id === ProfileType.GLOBAL) {
      if (value) {
        setDefault(false);
      }

      setGlobal(value);
    }
  };

  const handleSave = () => {
    if (name !== current.name) {
      setInputError('Name has to be the same for saving as current');

      timeoutId = setTimeout(() => {
        setName(current.name);
        setInputError('');
      }, INPUT_ERROR_TIMEOUT);

      return;
    }

    save();
  };

  const handleSaveAsNew = () => {
    if (name === current.name) {
      setInputError('Enter unique name to save it as a new view');
      timeoutId = setTimeout(() => setInputError(''), INPUT_ERROR_TIMEOUT);
      return;
    }

    save(true);
  };

  return (
    <div
      className={clx(options, {
        open,
        right: position === 'right',
        left: position === 'left',
      })}
    >
      <div className={option_header}>Save View</div>
      <div className={option_body}>
        <div className={option_box}>
          <div className={input_label}>Name</div>
          <input
            className={clx(input_text, { error: !!inputError })}
            name="name"
            onChange={handleChangeInput}
            ref={inputRef}
            type="text"
            value={name}
            data-testing="txt_field"
          />
          <div className={error_message}>{inputError}</div>
        </div>

        {isShowCustomSharedViews &&
          [USER_ROLES.SALES_MANAGER, USER_ROLES.SALES_OPERATIONS].includes(
            userRole as string
          ) && (
            <div className={option_box_checkbox}>
              <BuCheckbox
                checked={isDefault}
                onChange={(value) =>
                  handleChangeCheckbox(value, ProfileType.DEFAULT)
                }
                size={BuControlSize.BIG}
                elementLabel={
                  <div className={checkbox_text}>Make this a personal view</div>
                }
              />

              <BuCheckbox
                checked={isGlobal}
                onChange={(value) =>
                  handleChangeCheckbox(value, ProfileType.GLOBAL)
                }
                size={BuControlSize.BIG}
                elementLabel={
                  <div className={checkbox_text}>
                    Make it Available to the users in your organization as a
                    standard option to choose in the ‘View’ dropdown. When
                    unchecked this ‘View’ is only visible to you
                  </div>
                }
              />
            </div>
          )}

        <div className={clx(option_box, { flex: true })}>
          <div className={note_text}>
            Note: Saved dashboard will be available as an option under the
            ‘View’ dropdown located on the top left side of the filters.
          </div>
        </div>

        <div className={action_btns}>
          {!isCurrentDefault && isOwner && (
            <button
              onClick={handleSave}
              disabled={!!inputError}
              className={clx(action_btn, { save: true })}
              type="button"
            >
              Update View
            </button>
          )}

          <button
            onClick={handleSaveAsNew}
            disabled={!!inputError}
            className={clx(action_btn, { as_new: true })}
            type="button"
          >
            Create New View
          </button>

          <button
            onClick={cancel}
            className={clx(action_btn, { cancel: true })}
            type="button"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
