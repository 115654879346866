import { CHANGE_INTERVAL_OPTIONS } from './constants';
import {
  IncludeSummaryDealResponse,
  IncludedSummaryForDeal,
} from './types/forecastSubmission';
import moment from 'moment';

import { IRow } from 'components/UI/common/TypedTable/TypedTable';

export const mapIncludedSummaryForDeal = (
  dealId: string,
  includeSummary: IncludeSummaryDealResponse[]
): IncludedSummaryForDeal[] => {
  const includeSummaryForDeal = includeSummary.find(
    ({ _id }) => _id === dealId
  );

  if (!includeSummaryForDeal) {
    return [];
  }

  return [
    ...includeSummaryForDeal.included_by.map((summary) => ({
      userId: summary.user_id,
      userName: summary.user_name,
      userRole: summary.user_role,
      wasDealIncluded: true,
    })),
    ...includeSummaryForDeal.excluded_by.map((summary) => ({
      userId: summary.user_id,
      userName: summary.user_name,
      userRole: summary.user_role,
      wasDealIncluded: false,
    })),
  ];
};

export const mapChangesSinceDataForDeals = (
  deals: IRow[],
  changesSinceDate: string,
  // Can be a key from CHANGE_INTERVAL_OPTIONS or a date string when using custom range
  changesSinceKeyOrDateRange: string
) => {
  const possibleDateIfCustomRange = moment(
    changesSinceKeyOrDateRange.split(',')?.[0]
  );

  const changesSinceCopy = possibleDateIfCustomRange.isValid()
    ? possibleDateIfCustomRange.format('MM/DD/YYYY')
    : CHANGE_INTERVAL_OPTIONS[changesSinceKeyOrDateRange];

  return deals.map((d) => {
    return {
      ...d,
      changesSinceCopy,
      changesSinceDate,
    };
  });
};
