import { css } from 'emotion';

export const tableHeaderCellBorders = css`
  & th {
    border-right: 1px solid var(--bu-gray-400) !important;

    span {
      -webkit-line-clamp: 1 !important;
      text-align: left;
      text-transform: inherit;
    }
  }
`;

export const extraHeaderTableCellBordersSingleMetric = css`
  & thead {
    tr:first-child {
      > th:not(:last-child) {
        border-right: 2px solid var(--bu-gray-400) !important;
      }
    }

    tr:last-child {
      > th:not(:last-child) {
        border-right: 2px solid var(--bu-gray-400) !important;
      }
    }

    span {
      -webkit-line-clamp: 1 !important;
      text-align: left;
      text-transform: inherit;
    }
  }

  & tbody tr {
    > td:not(:last-child) {
      border-right: 2px solid var(--bu-gray-400) !important;
    }
  }
`;

export const extraHeaderTwoPivotsTableCellBorders = (
  metricsCount: number
) => css`
  & thead {
    tr:first-child {
      > th:not(:last-child) {
        border-right: 2px solid var(--bu-gray-400) !important;
      }
    }

    tr:last-child {
      & th {
        border-right: 1px solid var(--bu-gray-400) !important;
      }

      > th:nth-child(${metricsCount}n + 1):not(:last-child) {
        border-right: 2px solid var(--bu-gray-400) !important;
      }
    }

    span {
      -webkit-line-clamp: 1 !important;
      text-align: left;
      text-transform: inherit;
    }
  }

  & tbody tr {
    > td:nth-child(${metricsCount}n + 1):not(:last-child) {
      border-right: 2px solid var(--bu-gray-400) !important;
    }
  }
`;

export const extraHeaderSinglePivotTableCellBorders = (
  metricsCount: number
) => css`
  & thead {
    tr:first-child {
      > th:not(:last-child) {
        border-right: 2px solid var(--bu-gray-400) !important;
      }
    }

    tr:last-child {
      & th {
        border-right: 1px solid var(--bu-gray-400) !important;
      }

      > th:nth-child(${metricsCount}n):not(:last-child) {
        border-right: 2px solid var(--bu-gray-400) !important;
      }
    }

    span {
      -webkit-line-clamp: 1 !important;
      text-align: left;
      text-transform: initial;
    }
  }

  & tbody tr {
    > td:nth-child(${metricsCount}n):not(:last-child) {
      border-right: 2px solid var(--bu-gray-400) !important;
    }
  }
`;
