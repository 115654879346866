import { css } from 'emotion';

import { fontDefault } from 'assets/css/common';

export const dropdown_label = css`
  display: flex;
  align-items: center;
  height: inherit;
  padding: 0 8px;
`;

export const dropdownContainer = css`
  z-index: 200;
`;

export const menu = css`
  position: relative;
  max-height: 300px;
`;

export const menu__item = css`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  cursor: pointer;
`;

export const custom_checkbox = css`
  ${fontDefault};

  padding-left: 1.8em;
  cursor: pointer;
  color: var(--bu-gray-700);
  height: 20px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  &.isChecked {
    color: #4a4a4a;
  }
`;

export const custom_checkbox__input = css`
  position: absolute;
  appearance: none;

  &:checked + span {
    background-color: var(--bu-primary-500);
    border-color: var(--bu-primary-500);

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 9px;
      height: 9px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-position: center;
      background-size: contain;
      background-image: url(${require('assets/images/checked_white.svg')});
    }
  }
`;

export const check_box = css`
  position: absolute;
  width: 14px;
  height: 14px;
  background: var(--bu-white);
  margin-left: -24px;
  border: 1px solid var(--bu-gray-500);
  border-radius: 2px;
`;
