import styled from '@emotion/styled';
import React from 'react';

import { UserSubmission } from 'actions/croOverrideActions';
import {
  CustomComponentFunction,
  getCellValue,
} from 'components/UI/common/TypedTable/renderers/custom/common';

const CellLabel = styled.div`
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type MyCallCellConfig = {
  currencyFormatter(value: number): string;
};
const myCallCell: CustomComponentFunction<MyCallCellConfig> = ({
  currencyFormatter,
}) => (props) => {
  const submission = getCellValue(props) as UserSubmission | undefined;
  const { override_amount } = submission ?? {};

  return <CellLabel>{currencyFormatter(override_amount ?? 0)}</CellLabel>;
};

export default myCallCell;
