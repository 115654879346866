import { Delta, Body, Title } from './StyledComponents';
import { BuMetricsPanelItem } from './types';
import React from 'react';

import Tooltip from 'components/UI/common/Tooltip';

interface MetricTileProps {
  item: BuMetricsPanelItem;
}

const MetricTile = ({ item }: MetricTileProps) => {
  return (
    <>
      <Title data-testing="metric-title">{item.title}</Title>
      <Body data-testing="metric-content">
        <Tooltip tooltip={item.formattedAmount}>
          {item.formattedShortAmount}
        </Tooltip>
        {item.formattedDeltaAmount && (
          <Delta data-testing="metric-delta" isPositive={!!item.positiveDelta}>
            {item.formattedDeltaAmount}
          </Delta>
        )}
      </Body>
    </>
  );
};

export default MetricTile;
