import MetricTile from './MetricTile';
import {
  MetricPanelSection,
  CollapseAnimationContainer,
  CollapseDivider,
  CollapseButton,
  CollapseIcon,
  MetricList,
  MetricItem,
} from './StyledComponents';
import { getMetricListItemsPerRow, isLastRow } from './helper';
import { BuMetricsPanelItem } from './types';
import classNames from 'classnames';
import React, { useState, useMemo } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { useElementSize } from 'components/hooks/useElementSize';

export interface BuMetricsPanelProps {
  items: BuMetricsPanelItem[];
  onTileClick?: (item: BuMetricsPanelItem) => void | undefined;
}

const BuMetricsPanel = ({ items, onTileClick }: BuMetricsPanelProps) => {
  const [panelExpanded, setPanelExpanded] = useState(true);
  const expandButtonPrefixText = panelExpanded ? 'Hide' : 'Show';
  const expendButtonText = `${expandButtonPrefixText} Metrics`;
  const isTileClickable = !!onTileClick;

  const thereAreItems = items.length > 0;

  const [listRef, listSize] = useElementSize<HTMLUListElement>();

  const itemsPerRow = useMemo(
    () => getMetricListItemsPerRow(items.length, listSize.width),
    [items.length, listSize.width]
  );

  if (!thereAreItems) {
    return null;
  }

  return (
    <MetricPanelSection>
      <CollapseAnimationContainer
        className={classNames({
          closed: !panelExpanded,
        })}
      >
        <MetricList
          data-testing="metric-list"
          ref={listRef}
          itemsPerRow={itemsPerRow}
        >
          {items.map((item, index) => (
            <MetricItem
              key={item.title}
              isClickable={isTileClickable}
              noBorder={isLastRow(index, items.length, itemsPerRow)}
              itemsPerRow={itemsPerRow}
              onClick={() => onTileClick?.(item)}
              data-testing={`metric-item-${index}`}
            >
              <MetricTile item={item} />
            </MetricItem>
          ))}
        </MetricList>
      </CollapseAnimationContainer>

      <CollapseDivider
        hideDivider={panelExpanded}
        onClick={() => setPanelExpanded(!panelExpanded)}
      >
        <CollapseButton>
          <CollapseIcon
            name={
              panelExpanded ? BoostUpIcons.ChevronUp : BoostUpIcons.ChevronDown
            }
          />
          {expendButtonText}
        </CollapseButton>
      </CollapseDivider>
    </MetricPanelSection>
  );
};

export default BuMetricsPanel;
