import classNames from 'classnames';
import { debounce } from 'lodash';
import { evaluate } from 'mathjs';
import React from 'react';

import FormulaFieldSuggester from 'components/UI/BuFormulaTextField/FormulaFieldSuggester';
import { MetricSuggesterState } from 'components/UI/BuFormulaTextField/FormulaFieldSuggester/types';
import { METRICS_SUGGESTER_INITIAL_STATE } from 'components/UI/BuFormulaTextField/constants';
import * as CONSTANTS from 'components/UI/BuFormulaTextField/constants';
import * as styles from 'components/UI/BuFormulaTextField/styles';
import { metricsColorRef } from 'components/UI/BuFormulaTextField/types';
import {
  IBuFormulaTextFieldProps,
  ColoredMetric,
} from 'components/UI/BuFormulaTextField/types';
import {
  replaceTextNodeWithCustomNode,
  getHighlightedTerm,
  setCaretToEndOfNode,
  getSignNode,
  compileFormulaToNodes,
  getCurrentUserInsertion,
  getHighlightedNumber,
  getCaretPosition,
} from 'components/UI/BuFormulaTextField/utils';

export const BuFormulaTextField: React.FC<IBuFormulaTextFieldProps> = ({
  metrics,
  metricsStatus,
  formulaToBeEdited,
  onFormulaFinishEditing,
}) => {
  // States //
  const [metricSuggesterState, changeMetricSuggester] =
    React.useState<MetricSuggesterState>(METRICS_SUGGESTER_INITIAL_STATE);

  // Refs //
  const formulaTextFieldRef = React.useRef<HTMLDivElement>(null);
  const metricsColorRef = React.useRef<metricsColorRef>({});
  const [isFormulaValid, setIsFormulaValid] = React.useState(true);

  React.useEffect(() => {
    if (formulaToBeEdited && metrics.length > 0) {
      const formulaAsNodes = compileFormulaToNodes(
        formulaToBeEdited,
        metrics,
        metricsColorRef
      );

      if (
        formulaTextFieldRef.current !== null &&
        formulaTextFieldRef.current.innerHTML.trim() === '' &&
        formulaAsNodes.length
      ) {
        formulaTextFieldRef.current.innerHTML = '';
        formulaAsNodes.map((el) => {
          formulaTextFieldRef.current?.appendChild(el);
        });
        setCaretToEndOfNode(
          formulaTextFieldRef.current,
          formulaAsNodes[formulaAsNodes.length - 1]
        );
      }
    }
  }, [formulaToBeEdited, metrics]);

  React.useEffect(() => {
    if (metrics.length > 0) {
      const userInsertion = getCurrentUserInsertion(
        formulaTextFieldRef
      ) as string;

      const metricsfiltered = userInsertion
        ? metrics.filter((el) =>
            el.name
              ?.toLocaleLowerCase()
              .includes(userInsertion.trim().toLocaleLowerCase())
          )
        : metrics;

      changeMetricSuggester({
        ...metricSuggesterState,
        listOfSuggestions: metricsfiltered,
      });

      METRICS_SUGGESTER_INITIAL_STATE.listOfSuggestions = metrics;
    }
  }, [metrics]);

  React.useEffect(() => {
    const result = prepareFormulaText();
    const formulaToBeEditedAsNodes = formulaToBeEdited
      ? compileFormulaToNodes(formulaToBeEdited, metrics, metricsColorRef)
      : '';

    let formulaToBeEditedOuterHTML = '';
    if (formulaToBeEditedAsNodes) {
      formulaToBeEditedOuterHTML = formulaToBeEditedAsNodes.reduce(
        (acc, node) => {
          if (node.nodeName === '#text') {
            acc = acc + node.nodeValue;
          } else {
            const span = node as HTMLSpanElement;
            acc = acc + span.outerHTML;
          }
          return acc;
        },
        ''
      );
    }
  }, [isFormulaValid, formulaToBeEdited]);

  const prepareFormulaText = () => {
    let result = '';
    if (formulaTextFieldRef.current !== null) {
      const { childNodes } = formulaTextFieldRef.current;
      childNodes.forEach((node) => {
        const dataset = (node as HTMLSpanElement).dataset;
        if (dataset) {
          switch (dataset.ftype) {
            case 'metric':
              result += `{${dataset.elementId}}`;
              break;
            case 'sign':
            case 'number':
              result += `${dataset.elementId}`;
              break;
          }
        }
      });
    }
    return result;
  };

  const localFormulaValidation = () => {
    const formula = prepareFormulaText();
    const cleaned = formula.replace(/{[^}]+}/g, '7');

    // we use a try-catch block because the evaluate() function throws an error if the input is not a valid arithmetic formula.
    // If evaluate() runs successfully, formula is valid.
    // If it doesn't (i.e., it throws an error)formula is invalid
    // This approach allows us to quickly and effectively validate whether user-provided input is a valid arithmetic formula.
    try {
      evaluate(cleaned);
      onFormulaFinishEditing(formula);
      return true;
    } catch {
      return false;
    }
  };

  const handleMetricSelection = (element: ColoredMetric) => {
    if (formulaTextFieldRef.current) {
      const newNode = getHighlightedTerm(element);
      replaceTextNodeWithCustomNode(newNode, formulaTextFieldRef.current);
      changeMetricSuggester(METRICS_SUGGESTER_INITIAL_STATE);
      setIsFormulaValid(localFormulaValidation());
    }
  };

  const handleKeyUp: React.KeyboardEventHandler = (event) => {
    setIsFormulaValid(localFormulaValidation());
  };

  const handleKey: React.KeyboardEventHandler = (event) => {
    const { caretPosition, listOfSuggestions } = metricSuggesterState;
    switch (event.key) {
      case 'ArrowDown':
        if (caretPosition <= listOfSuggestions.length) {
          changeMetricSuggester({
            ...metricSuggesterState,
            caretPosition: caretPosition + 1,
          });
        } else {
          changeMetricSuggester({ ...metricSuggesterState, caretPosition: 0 });
        }
        return;
        break;
      case 'ArrowUp':
        if (caretPosition > 0) {
          changeMetricSuggester({
            ...metricSuggesterState,
            caretPosition: caretPosition - 1,
          });
        } else {
          changeMetricSuggester({
            ...metricSuggesterState,
            caretPosition: listOfSuggestions.length - caretPosition,
          });
        }
        return;
      case 'ArrowRight':
        if (formulaTextFieldRef.current) {
          const pos = getCaretPosition();
          const node = formulaTextFieldRef.current.childNodes[pos];
          setCaretToEndOfNode(formulaTextFieldRef.current, node);
          changeMetricSuggester(METRICS_SUGGESTER_INITIAL_STATE);
          event.preventDefault();
          event.stopPropagation();
        }
        return;
      case 'Enter':
        if (!metricSuggesterState.collapsed) {
          handleMetricSelection(
            metricSuggesterState.listOfSuggestions[
              metricSuggesterState.caretPosition
            ]
          );
        }
        changeMetricSuggester(METRICS_SUGGESTER_INITIAL_STATE);
        event.preventDefault();
        event.stopPropagation();
        return;
      case ' ':
        debounce(
          changeMetricSuggester,
          250
        )({
          ...metricSuggesterState,
          collapsed: false,
        });
        break;
      case 'Escape':
        changeMetricSuggester(METRICS_SUGGESTER_INITIAL_STATE);
        event.preventDefault();
        event.stopPropagation();
        return;
      case 'Backspace':
      case 'Tab':
      case 'ArrowLeft':
      case 'Shift':
        changeMetricSuggester(METRICS_SUGGESTER_INITIAL_STATE);
        return;
    }

    if (formulaTextFieldRef.current) {
      const prevTextEntered = getCurrentUserInsertion(formulaTextFieldRef);
      const userInsertion = `${prevTextEntered}${event.key}`.trim();
      const isNumber = CONSTANTS.NUMBERS_REGEXP.test(userInsertion);
      const isOperator = CONSTANTS.SIGNS_ARRAY.includes(userInsertion);

      if (isNumber) {
        const newNode = getHighlightedNumber(userInsertion);
        replaceTextNodeWithCustomNode(newNode, formulaTextFieldRef.current);
        changeMetricSuggester(METRICS_SUGGESTER_INITIAL_STATE);
        event.preventDefault();
        event.stopPropagation();
      } else if (isOperator) {
        const signNode = getSignNode(userInsertion);
        replaceTextNodeWithCustomNode(signNode, formulaTextFieldRef.current);
        changeMetricSuggester(METRICS_SUGGESTER_INITIAL_STATE);
        event.preventDefault();
        event.stopPropagation();
      } else if (userInsertion) {
        const clearedUserInput = userInsertion.replace(/[^A-Za-z]/g, '');
        const newSuggestionList = clearedUserInput
          ? metrics.filter((el) =>
              el.name
                ?.toLocaleLowerCase()
                .includes(clearedUserInput.toLocaleLowerCase())
            )
          : metrics;
        changeMetricSuggester({
          caretPosition: 0,
          listOfSuggestions: newSuggestionList,
          collapsed: false,
        });
      }
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // this is a workarround to close the suggerter when the user clicks outside.
    if (formulaTextFieldRef.current?.contains(e.target)) {
      debounce(changeMetricSuggester, 200)(METRICS_SUGGESTER_INITIAL_STATE);
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (
      metricSuggesterState.collapsed &&
      formulaTextFieldRef.current?.innerText.trim() === ''
    ) {
      debounce(
        changeMetricSuggester,
        250
      )({
        ...metricSuggesterState,
        collapsed: false,
      });
    }
  };

  const editableDivClasses = classNames({
    [styles.editableDiv]: true,
    [styles.editableDivError]: !isFormulaValid,
  });

  return (
    <>
      <div
        className={editableDivClasses}
        contentEditable={true}
        data-testing="txt_field"
        id="formulaField"
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKey}
        onKeyUp={handleKeyUp}
        ref={formulaTextFieldRef}
        suppressContentEditableWarning={true}
      ></div>
      {!isFormulaValid && (
        <span className={styles.errorMessage}>The Formula is invalid</span>
      )}
      {!metricSuggesterState.collapsed && (
        <FormulaFieldSuggester
          handleSelect={handleMetricSelection}
          fieldRef={formulaTextFieldRef}
          caretPosition={metricSuggesterState.caretPosition}
          items={metricSuggesterState.listOfSuggestions}
          status={metricsStatus}
        />
      )}
    </>
  );
};
