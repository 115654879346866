import React, { FC, MouseEvent } from 'react';
import {
  useDrag,
  useDrop,
  ConnectDragSource,
  ConnectDropTarget,
  ConnectDragPreview,
} from 'react-dnd';

import { ITypedHierarchicalRow } from 'components/UI/common/TypedTable/TypedHierarchicalRow';
import TypedRow from 'components/UI/common/TypedTable/TypedRow';
import { OnSelect } from 'components/UI/common/TypedTable/TypedTable';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';

export interface ITypedRowProps extends ITypedHierarchicalRow {
  isHierarchyOpen?: boolean;
  toggleHierarchy?: (event: MouseEvent<HTMLButtonElement>) => void;

  selectable?: false | string;
  onSelect?: OnSelect;
  showOrHideColumns?: { label: string; id: string; show: boolean }[];
  opacity?: number;
  drag?: ConnectDragSource;
  drop?: ConnectDropTarget;
  preview?: ConnectDragPreview;
}

const getDragNDropTagItem = (level: number = 0) =>
  `drag-n-drop-table-row-${level}`;

interface DragItem {
  id: IRow['id'];
  originalIndex: number;
}

const TypedRowDraggable: FC<ITypedRowProps> = (props) => {
  const {
    findRow,
    row,
    level,
    enableReorderingRows,
    canDragRow,
    rows,
    moveRow,
    onDrop,
  } = props;
  const originalIndex = findRow(row.id).index;
  const isRowDraggable = enableReorderingRows && canDragRow(row, rows);

  const [{ opacity }, drag, preview] = useDrag(
    () => ({
      type: getDragNDropTagItem(level),
      item: { id: row.id, originalIndex },
      canDrag: () => isRowDraggable,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0 : 1,
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveRow(droppedId, originalIndex);
        }
      },
    }),
    [row.id, originalIndex, moveRow, isRowDraggable, findRow]
  );

  const [, drop] = useDrop(
    () => ({
      accept: getDragNDropTagItem(level),
      canDrop: () => isRowDraggable,
      hover({ id: draggedId }: DragItem) {
        if (draggedId !== row.id && isRowDraggable) {
          const { index: overIndex } = findRow(row.id);
          moveRow(draggedId, overIndex);
        }
      },
      drop({ id: droppedId }: DragItem) {
        const { row } = findRow(droppedId);

        onDrop(rows, row);
      },
    }),
    [findRow, moveRow, isRowDraggable]
  );

  const dndProps = { opacity, drag, drop, preview };

  return <TypedRow {...dndProps} {...props} />;
};

export default TypedRowDraggable;
