const definedColorsForSeries: Record<string, string> = {
  best_case: 'var(--bu-color-constant-best-case)',
  booked: 'var(--bu-color-constant-booked)',
  boostup_projection: 'var(--bu-color-chart-boostup_projection)',
  commit: 'var(--bu-color-constant-commit)',
  company_forecast: 'var(--bu-color-chart-company_forecast)',
  expected_booked: 'var(--bu-color-chart-expected_booked)',
  lost: 'var(--bu-color-constant-lost)',
  pipeline: 'var(--bu-color-constant-pipeline)',
  pipeline_coverage: 'var(--bu-color-constant-pipeline-coverage-target)',
  target: 'var(--bu-color-constant-forecast-target)',
  omitted: 'var(--bu-color-constant-omitted)',
  closed: 'var(--bu-color-constant-closed-won)',
  in_play: 'var(--bu-color-chart-in_play)',
};

const additionalColors = [
  'var(--bu-color-dynamic-bar-light-yellow)',
  'var(--bu-color-dynamic-bar-light-aqua)',
  'var(--bu-color-dynamic-bar-light-lime)',
  'var(--bu-color-dynamic-bar-light-indigo)',
  'var(--bu-color-dynamic-bar-light-purple)',
  'var(--bu-color-dynamic-bar-light-blue)',
  'var(--bu-color-dynamic-bar-light-pink)',
];

export const getSeriesColor = (name: string, index: number) => {
  const nameLowerCase = name.toLowerCase().replace(/ /g, '_');
  return (
    definedColorsForSeries[nameLowerCase] ||
    additionalColors[additionalColors.length - index]
  );
};
