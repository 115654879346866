import { css } from 'emotion';

import {
  fontHeading,
  fontDefault,
  fontDefaultDropdown,
} from 'assets/css/common';

export const container = css`
  label: inline-search-container;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  padding-top: 3px;
  z-index: 988;
`;

export const searchBox = css`
  label: inline-search-box;
  flex-shrink: 0;
`;

export const results = css`
  label: inline-search-results;
  flex-shrink: 0;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border: 1px solid var(--bu-gray-500);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  background-color: var(--bu-white);
`;

export const resultsEmpty = css`
  label: inline-search-results-empty;
  padding: 20px;
  ${fontDefault};
`;

export const resultsLoading = css`
  label: inline-search-results-loading;
  padding: 20px;
`;

export const resultsHasMore = css`
  label: inline-search-results-has-more;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 0 0 20px 20px;
  color: var(--bu-primary-500);
  ${fontDefault};
  cursor: pointer;
`;

export const resultType = css`
  label: inline-search-result-type;
  padding: 5px 20px;
  background-color: var(--bu-white);
  color: var(--bu-gray-700);
  text-transform: uppercase;
  ${fontDefaultDropdown};
  font-size: 0.8em;
  border-top: 1px solid #ddd;

  &:first-child {
    border-top: none;
  }

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

export const resultItem = css`
  label: inline-search-result-item;
  padding: 5px 20px;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }
`;

export const notLinked = css`
  opacity: 0.3;
`;

export const plainResultItem = css`
  label: inline-search-plain-result-item;
  ${resultItem};
  padding: 10px 20px;
`;

export const resultItemIcon = css`
  label: inline-search-result-item-icon;
  flex-shrink: 0;
  margin-right: 10px;
`;

export const resultItemDescription = css`
  label: inline-search-result-item-description;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
  flex-grow: 1;
`;

export const resultItemTitle = css`
  label: inline-search-result-item-title;
  color: var(--bu-gray-900);
  ${fontHeading};
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const resultItemWarning = css`
  label: inline-search-result-item-warning;
  color: #ee724e;
  font-weight: normal;
`;

export const resultItemType = css`
  label: inline-search-result-item-type;
  color: var(--bu-gray-700);
  ${fontDefault};
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const descTitle = css`
  color: #9b9b9b;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const descField = css`
  margin-right: 5px;
`;
