import { css } from 'emotion';

export const row = css`
  padding: 0 !important;
`;

export const rowItemLabel = css`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  color: var(--bu-gray-700);

  .engagement-title {
    color: var(--bu-gray-900);
    font-weight: 600;
  }

  .engagement-sub {
    margin-left: 5px;
  }

  .bu-icon {
    margin-right: 10px;
    font-size: 2em;
  }
`;

export const ellipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const dropdownContainer = css`
  display: flex;
  flex-direction: row;
  color: var(--bu-gray-900);
  padding-left: 40px;
  margin-top: -12px;
  z-index: 20;
  position: relative;
`;

export const resetDefaultStyles = css`
  &.dropdown {
    margin-top: -4px;
    background: var(--bu-gray-100) !important;
    padding: 3px !important;
    border: 1px solid #ccc !important;
    border-radius: 2px;
    display: flex !important;
    justify-content: space-between !important;
    color: var(--bu-gray-700);

    i {
      margin-top: -2px !important;
    }
  }

  &.dropdown,
  & .dropdown {
    margin-left: 5px;
    display: flex !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;

    .text {
      white-space: nowrap;
    }
  }
`;

export const chartContainer = css`
  overflow: visible !important;

  & .highcharts-container {
    z-index: 1 !important;
  }
`;

export const line = css`
  padding: 4px 6px;
  border-top: 1px solid var(--bu-gray-300);
  display: flex;
`;

export const stage_box = css`
  border-radius: 12px;
  display: inline-block;
  font-size: 12px;
  position: relative;
  font-size: 12px;
  font-family: var(--bu-font-regular);
  max-height: 22px;
  padding: 5px 8px 3px 9px;
  cursor: pointer;
  text-align: center;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    right: -1px;
    bottom: -5px;
    height: 300px;
    width: 1px;
    z-index: 0;
    background: var(--bu-gray-300);
  }

  &:last-child {
    &:before {
      display: none;
    }
  }

  &.gray {
    border: 1px solid var(--bu-gray-500);
    background: var(--bu-gray-300);
    color: var(--bu-gray-900);
  }

  &.red {
    background: #fff0f0;
    border: 1px solid #ffb3b3;
    color: var(--bu-red-500);

    &:after {
      content: '';
      width: 100%;
      height: 300px;
      left: 0;
      right: 0;
      display: block;
      position: absolute;
      bottom: 27px;
      background: hsla(0, 0%, 100%, 1);
      background: linear-gradient(
        270deg,
        hsla(0, 0%, 100%, 0.7) 0%,
        hsla(0, 100%, 97%, 1) 100%
      );
      background: -moz-linear-gradient(
        270deg,
        hsla(0, 0%, 100%, 0.7) 0%,
        hsla(0, 100%, 97%, 1) 100%
      );
      background: -webkit-linear-gradient(
        270deg,
        hsla(0, 0%, 100%, 0.7) 0%,
        hsla(0, 100%, 97%, 1) 100%
      );
    }
  }

  &.green {
    background: #eafaf0;
    border: 1px solid #96e8b6;
    color: var(--bu-gray-900);

    &:after {
      content: '';
      width: 100%;
      height: 300px;
      left: 0;
      right: 0;
      display: block;
      position: absolute;
      bottom: 27px;
      background: hsla(0, 0%, 100%, 1);
      background: linear-gradient(
        270deg,
        hsla(0, 0%, 100%, 0.7) 0%,
        hsla(140, 60%, 97%, 1) 100%
      );
      background: -moz-linear-gradient(
        270deg,
        hsla(0, 0%, 100%, 0.7) 0%,
        hsla(140, 60%, 97%, 1) 100%
      );
      background: -webkit-linear-gradient(
        270deg,
        hsla(0, 0%, 100%, 0.7) 0%,
        hsla(140, 60%, 97%, 1) 100%
      );
    }
  }
`;

export const stage_tooltip = css`
  display: block;
  font-size: 12px;
  color: var(--bu-gray-900);
  font-family: var(--bu-font-regular);
`;

export const arrow_stage_left = css`
  position: absolute;
  display: block;
  left: -1px;
  top: -1px;
  width: 11px;
  height: 22px;
  background: var(--bu-white);
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    right: -13px;
    top: 1px;
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(45deg);
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #ffb3b3;
    background: #fff0f0;
  }
`;

export const arrow_stage_right = css`
  position: absolute;
  display: block;
  right: -1px;
  top: -1px;
  width: 11px;
  height: 22px;
  background: var(--bu-white);
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    left: -13px;
    top: 1px;
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(45deg);
    display: inline-block;
    width: 20px;
    height: 20px;
    background: #eafaf0;
    border: 1px solid #96e8b6;
  }
`;
