import { NOT_SAVED_METRIC } from './constants';
import { fetchWidgetData } from './historical.widget.helpers';
import { PointClickEventObject } from 'highcharts';
import _ from 'lodash';
import React, {
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { BIChartTypes } from 'common/enums/metrics';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { WidgetChart } from 'components/dashboard/Metrics/Widget/Chart/WidgetChart';
import { HistoricalWidgetControls } from 'components/dashboard/Metrics/Widget/Controls/HistoricalWidgetControls';
import { WidgetHeader } from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { DataFetchErrorMessage } from 'components/dashboard/Metrics/Widget/Messages/DataFetchErrorMessage';
import { NoDataMessage } from 'components/dashboard/Metrics/Widget/Messages/NoDataMessage';
import { WidgetTable } from 'components/dashboard/Metrics/Widget/Table/WidgetTable';
import { TotalsBlock } from 'components/dashboard/Metrics/Widget/TotalsBlock/TotalsBlock';
import {
  getMetricNamesWithValues,
  getSyntheticMetricData,
  openSyntheticMetricDetailsModal,
  parseFormulaToMetricsIdArray,
} from 'components/dashboard/Metrics/Widget/helper';
import { openDrillDownModal } from 'components/dashboard/Metrics/Widget/historical.widget.helpers';
import * as s from 'components/dashboard/Metrics/Widget/metrics.widget.styles';
import {
  LoaderContainer,
  WidgetContainer,
} from 'components/dashboard/Metrics/Widget/widgets.styles';
import { BUSINESS_TYPES_FIELD_NAMES } from 'components/dashboard/Metrics/constants';
import {
  BIMetricColumn,
  BIMetricToChartType,
  BIMetrics,
  BIWidget,
  BIWidgetDataV2,
  DrillDownParams,
  SelectedValue,
  SyntheticMetricDataWidgetState,
  TimeSeriesDrillDownParams,
} from 'components/dashboard/Metrics/metrics.types';
import { QueryStatus } from 'utils/network';

interface Props {
  widget: Partial<BIWidget>; // TODO Fix type
  metric: Partial<BIMetrics>; // TODO Fix type
  hasValidFilters?: boolean;
  setWidget: Dispatch<SetStateAction<Partial<BIWidget>>>;
}

export const HistoricalWidget: React.FC<Props> = ({
  widget,
  metric,
  hasValidFilters = false,
  setWidget,
}) => {
  const dispatch = useDispatch();

  const metricsInUse = useMemo(() => {
    if (!metric?._id) {
      return {
        [NOT_SAVED_METRIC]: metric as BIMetrics,
      };
    } else {
      return {
        [metric._id]: metric as BIMetrics,
      };
    }
  }, [metric?._id]);

  const [metricToChartType, setMetricToChartType] = useState<
    BIMetricToChartType[]
  >(
    [metric?._id || NOT_SAVED_METRIC].map((el) => ({
      chartType:
        widget.chart_type === 'table'
          ? BIChartTypes.Table
          : BIChartTypes.Column,
      metricId: el,
    }))
  );

  const [metricData, setMetricData] = useState<BIWidgetDataV2>();
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');

  const [pivot, setPivot] = useState<BIMetricColumn | undefined>(
    widget.group_by && widget?.group_by[0]
  );

  useEffect(() => {
    if (
      metric &&
      metric.metadata?.type === 'simple' &&
      ((metric.aggregation_function != 'count' && !metric.column) ||
        !metric.aggregation_function)
    ) {
      return;
    }

    if (
      metric &&
      metric.metadata?.type === 'formula' &&
      !metric.synthetic_metric
    ) {
      return;
    }

    const queryWidget = { ...widget, metric_list: [metric] };

    let abortController: AbortController | null = null;

    const metricValid = Boolean(
      metric?.synthetic_metric ||
        (metric?.aggregation_function && metric?.column) ||
        metric?.aggregation_function === 'count'
    );

    const areHistoryFieldsValid = Boolean(
      widget.time_period && widget.time_interval && widget.point_in_time
    );

    if (
      queryWidget?.metric_list?.length &&
      hasValidFilters &&
      metricValid &&
      areHistoryFieldsValid
    ) {
      abortController = new AbortController();

      fetchWidgetData(
        JSON.stringify(queryWidget),
        '',
        (widget._id ?? widget.name) || '',
        abortController,
        setMetricData,
        setDataStatus,
        setPivot
      );
    }

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [
    metric?.aggregation_function,
    metric?.column?.name,
    JSON.stringify(metric?.filters),
    metric?.metadata?.type,
    metric?.object,
    metric?.select_expression,
    metric?.synthetic_metric,
    widget.metric_list?.length,
    widget.time_field?.name,
    widget.time_field?.label,
    JSON.stringify(widget.group_by),
    widget.time_interval,
    widget.time_period,
    widget.point_in_time,
  ]);

  const handleTableDataClick = (column: IColumn, row: IRow): void => {
    const clickedMetric = metric as BIMetrics;

    if (clickedMetric) {
      let pivotValue = '';
      const escapedPivotName = pivot?.name?.replaceAll('.', '$');

      if (escapedPivotName) {
        pivotValue = row[escapedPivotName] as string;
      }

      // If it is a subtotal row then we do not send second pivot
      const drillDownFilters = row.isSubtotal
        ? []
        : [
            ...(widget.group_by ?? []).map((column) => ({
              column:
                column.type === 'date' && widget.time_field
                  ? widget.time_field
                  : column,
              operator: 'eq',
              value: pivotValue,
            })),
          ];

      const drilldownParams: TimeSeriesDrillDownParams = {
        filters: clickedMetric?.filters ?? [],
        template_filters: widget.template_filters ?? [],
        drill_down_filters: drillDownFilters,
        time_interval: widget.time_interval,
        time_period: widget.time_period,
        point_in_time: widget.point_in_time,
        target_time_period: column.extraHeader ?? '',
        business_type_name: metricBusinessType,
      };

      if (clickedMetric.synthetic_metric) {
        const parsedClickedMetricFormulaAsArray: string[] =
          parseFormulaToMetricsIdArray(clickedMetric.synthetic_metric);

        const syntheticMetricData: SyntheticMetricDataWidgetState =
          getSyntheticMetricData(
            parsedClickedMetricFormulaAsArray,
            metricData,
            clickedMetric
          );

        const selectedValue: SelectedValue = {
          y: !Number.isNaN(row[column.field] as number)
            ? (row[column.field] as number)
            : 0,
          metricsValues: getMetricNamesWithValues(
            parsedClickedMetricFormulaAsArray,
            row,
            syntheticMetricData
          ),
        };

        const pivot1FieldName = pivot?.field_name;
        if (pivot1FieldName) {
          selectedValue.pivot1Id = row[pivot1FieldName] as string;
        }

        const syntheticDrilldownParams: DrillDownParams = {
          drill_down_filters: drillDownFilters,
          filters: clickedMetric?.filters ?? [],
          template_filters: widget.template_filters ?? [],
          offset: 0,
          order_by: [],
          limit: 1000000,
          duration:
            widget.group_by?.[0]?.type === 'date'
              ? widget.group_by[0].name
              : '',
          skip_business_validation: true,
        };

        openSyntheticMetricDetailsModal(
          widget.title || widget.name || 'Drill Down',
          syntheticDrilldownParams,
          syntheticMetricData,
          selectedValue,
          dispatch
        );
      } else {
        openDrillDownModal(
          drilldownParams,
          clickedMetric,
          pivotValue,
          widget.title || widget.name || 'Drill Down',
          dispatch
        );
      }
    }
  };

  const handleChartDataClick = (pointEvent?: PointClickEventObject): void => {
    const selectedValue = pointEvent?.point?.options as any;

    const metricId = selectedValue.metricId.startsWith('not_saved_')
      ? NOT_SAVED_METRIC
      : selectedValue.metricId;

    if (metricId in metricsInUse) {
      const clickedMetric = metric as BIMetrics;

      const drillDownFilters =
        pointEvent && !!selectedValue?.pivot2Id
          ? [
              ...(widget.group_by ?? []).map((column) => ({
                column:
                  column.type === 'date' && widget.time_field
                    ? widget.time_field
                    : column,
                operator: 'eq',
                value: selectedValue?.pivot2Id,
              })),
            ]
          : [];

      if (clickedMetric.synthetic_metric) {
        const parsedClickedMetricFormulaAsArray: string[] =
          parseFormulaToMetricsIdArray(clickedMetric.synthetic_metric);

        const syntheticMetricData: SyntheticMetricDataWidgetState =
          getSyntheticMetricData(
            parsedClickedMetricFormulaAsArray,
            metricData,
            clickedMetric
          );

        const drilldownParams: DrillDownParams = {
          drill_down_filters: drillDownFilters,
          filters: clickedMetric?.filters ?? [],
          template_filters: widget.template_filters ?? [],
          order_by: [],
          offset: 0,
          limit: 1000000,
          duration:
            widget.group_by?.[0]?.type === 'date'
              ? widget.group_by[0].name
              : '',
          skip_business_validation: true,
        };

        let dataRow = {};
        if (availablePivots === 1) {
          const pivot1Id = selectedValue.pivot1Id;
          const pivot1Column = metricData?.pivot_1?.columns.find(
            (el) => el.is_pivot
          );
          const key = pivot1Column?.field_name?.replaceAll('.', '$') || '';
          dataRow =
            metricData?.pivot_1?.data.find((d) => d[key] === pivot1Id) || {};
        } else if (availablePivots === 2) {
          const pivot1Id = selectedValue.pivot1Id;
          const pivot1Column = metricData?.pivot_1?.columns.find(
            (el) => el.is_pivot
          );
          const pivot1Key =
            pivot1Column?.field_name?.replaceAll('.', '$') || '';
          const pivot2Id = selectedValue.pivot2Id;
          const pivot2Key = pivot?.name?.replaceAll('.', '$') || '';
          dataRow =
            metricData?.pivot_2?.data.find(
              (d) => d[pivot1Key] === pivot1Id && d[pivot2Key] === pivot2Id
            ) || {};
        }

        openSyntheticMetricDetailsModal(
          widget.title || widget.name || 'Drill Down',
          drilldownParams,
          syntheticMetricData,
          {
            ...selectedValue,
            metricsValues: getMetricNamesWithValues(
              parsedClickedMetricFormulaAsArray,
              dataRow,
              syntheticMetricData
            ),
          },
          dispatch
        );
      } else {
        const drilldownParams: TimeSeriesDrillDownParams = {
          filters: clickedMetric?.filters ?? [],
          template_filters: widget.template_filters ?? [],
          drill_down_filters: drillDownFilters,
          time_interval: widget.time_interval,
          time_period: widget.time_period,
          point_in_time: widget.point_in_time,
          target_time_period: selectedValue?.pivot1Id,
          business_type_name: metricBusinessType,
        };

        openDrillDownModal(
          drilldownParams,
          clickedMetric,
          selectedValue?.pivot2Id,
          widget.title || widget.name || 'Drill Down',
          dispatch
        );
      }
    }
  };

  const metricBusinessType: string = useMemo(() => {
    let businessType = '';

    if (metric) {
      metric?.filters?.forEach((f) => {
        const condition = f.and_condition?.[0].or_condition?.[0];
        if (BUSINESS_TYPES_FIELD_NAMES.has(condition?.column?.name)) {
          businessType = (condition.value as string[])?.[0];
        }
      });
    }

    return businessType;
  }, [metric]);

  const hasData = !!metricData?.pivot_1?.data.length;

  const availablePivots = metricData?.hasOwnProperty('pivot_2')
    ? 2
    : metricData?.hasOwnProperty('pivot_1')
    ? 1
    : 0;

  return (
    <>
      <WidgetContainer
        key={widget.id}
        isDashboard={false}
        isMetricsPreview={true}
        isModal={false}
      >
        <WidgetHeader isCreateEditMetric id={widget.id} name={widget.name} />

        <HistoricalWidgetControls
          showControls
          pivot={pivot}
          widget={widget}
          metricToChartType={metricToChartType}
          metricData={metricData}
          setPivot={setPivot}
          setWidget={setWidget}
          setMetricToChartType={setMetricToChartType}
        />

        {dataStatus === 'loading' && (
          <LoaderContainer>
            <Loader active content="Loading" />
          </LoaderContainer>
        )}

        {dataStatus === 'success' && (
          <>
            {!!metricData?.pivot_0?.columns?.length && (
              <div
                className={s.totalsContainer}
                data-testing="totals-container"
              >
                <div className={s.totalsBarElement}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TotalsBlock
                      showControls
                      isPivoted
                      type=""
                      pivot2={pivot}
                      widget={widget}
                      metricData={metricData}
                      metricsInUse={metricsInUse}
                      metricToChartType={metricToChartType}
                      onDataClick={() => {}}
                      onChartTypeChange={(metricId, chartType) => {
                        let result: {
                          chartType: BIChartTypes;
                          metricId: string;
                        }[] = [...metricToChartType];
                        if (
                          chartType === BIChartTypes.Table &&
                          (
                            (widget.properties
                              ?.metricToChartType as BIMetricToChartType[]) ||
                            []
                          ).every((el) => el.chartType !== BIChartTypes.Table)
                        ) {
                          result = [
                            ...result.map((el) => ({
                              ...el,
                              chartType: chartType,
                            })),
                          ];
                        }
                        if (
                          (
                            (widget.properties
                              ?.metricToChartType as BIMetricToChartType[]) ||
                            []
                          ).every(
                            (el) => el.chartType === BIChartTypes.Table
                          ) &&
                          chartType !== BIChartTypes.Table
                        ) {
                          result = [
                            ...result.map((el) => ({
                              ...el,
                              chartType: chartType,
                            })),
                          ];
                        }

                        const type = result.find(
                          (el) => el.metricId === metricId
                        );

                        if (!!type) {
                          type.chartType = chartType;
                          result = [...result];
                        } else {
                          result = [...result, { metricId, chartType }];
                        }

                        setMetricToChartType(result);
                        setWidget({
                          ...widget,
                          properties: {
                            metricToChartType: result,
                          },
                          chart_type: chartType,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {hasData && availablePivots > 0 && (
              <>
                {metricToChartType.some((el) => el.chartType === 'table') &&
                  widget.metric_list?.length > 0 &&
                  !!metricData && (
                    <WidgetTable
                      isDateFirstPivot
                      widgetData={metricData}
                      metricsCount={widget.metric_list?.length || 0}
                      availablePivots={availablePivots}
                      onTableDataClick={handleTableDataClick}
                      metricsInUse={metricsInUse}
                    />
                  )}

                {metricToChartType.every((el) => el.chartType !== 'table') &&
                  !!metricData && (
                    <WidgetChart
                      metricToChartType={metricToChartType}
                      metricData={metricData}
                      metricsInUse={metricsInUse}
                      widget={widget}
                      onDataClick={handleChartDataClick}
                    />
                  )}
              </>
            )}

            {!hasData && availablePivots > 0 && (
              <NoDataMessage data-testing="no-data-message" />
            )}
          </>
        )}

        {dataStatus === 'error' && <DataFetchErrorMessage />}
      </WidgetContainer>
    </>
  );
};
