/* eslint react/prop-types: 0 */
import * as styles from './styles';
import moment from 'moment';
import * as R from 'ramda';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react';

import { getEvent } from 'actions/eventActions';
import { goBackOr, goToCalls } from 'actions/routeActions';
import IconAttendees from 'assets/images/attendees.svg';
import IconMeeting from 'assets/images/icons/meeting_timeline_icon.svg';
import IconLocation from 'assets/images/location_grey.svg';
import IconUser from 'assets/images/user.svg';
import { Fullscreen } from 'components/UI/Layout';
import {
  getLoadingEvent,
  getCurrentEvent,
  getPreviousRoute,
  getPreviousSection,
} from 'selectors';

const isNilOrEmpty = R.either(R.isNil, R.isEmpty);
const getEventCreator = R.compose(
  R.defaultTo('Not Found'),
  R.unless(
    isNilOrEmpty,
    R.compose(
      R.find(R.complement(isNilOrEmpty)),
      R.values,
      R.pick(['display_name', 'email'])
    )
  ),
  R.prop('creator')
);

const getEventAttendees = R.compose(
  R.defaultTo('Not Found'),
  R.unless(isNilOrEmpty, R.compose(R.join(', '), R.pluck('email'))),
  R.prop('attendees')
);

class Event extends PureComponent {
  componentDidMount() {
    const { match, fetchEvent } = this.props;
    fetchEvent(match.params.eventId);
  }

  render() {
    const {
      loading,
      event,
      previousRoute,
      previousSection,
      goBack,
    } = this.props;

    return (
      <div className={styles.eventContainer}>
        <div className={styles.container}>
          <div className={styles.breadcrumbsBlock}>
            <Fullscreen.BackButton onClick={() => goBack(previousRoute)} />
            <div className={styles.breadcrumbs}>
              {previousSection}
              <span>{` / Meeting`}</span>
            </div>
          </div>
          {loading || event == null ? (
            <Dimmer inverted active>
              <Loader />
            </Dimmer>
          ) : (
            <div>
              <div className={styles.row}>
                <div className={styles.iconBlock}>
                  <img src={IconMeeting} alt="Icon" />
                </div>
                <div className={styles.rowContent}>
                  <div className={styles.name}>{event.summary}</div>
                  <div className={styles.date}>
                    {moment(event.start).format('MMM D, YYYY h:mm A')}
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.iconBlock}>
                  <img src={IconUser} alt="Icon" />
                </div>
                <div className={styles.rowContent}>
                  <span className={styles.label}>
                    {`Creator: `}
                    <span>{getEventCreator(event)}</span>
                  </span>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.iconBlock}>
                  <img src={IconAttendees} alt="Icon" />
                </div>
                <div className={styles.rowContent}>
                  <span className={styles.label}>
                    {`Attendees: `}
                    <span>{getEventAttendees(event)}</span>
                  </span>
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.iconBlock}>
                  <img src={IconLocation} alt="Icon" />
                </div>
                <div className={styles.rowContent}>
                  <span className={styles.label}>
                    {`Location: `}
                    <span>{R.propOr('Not Defined', 'location')(event)}</span>
                  </span>
                </div>
              </div>
              <div>
                <div className={styles.label}>Description</div>
                <div
                  className={styles.descriptionText}
                  dangerouslySetInnerHTML={{ __html: event.description }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: getLoadingEvent(state),
  event: getCurrentEvent(state),
  previousRoute: getPreviousRoute(state),
  previousSection: getPreviousSection(state, 'Calls'),
});

const mapDispatchToProps = (dispatch) => ({
  fetchEvent: (id) => dispatch(getEvent(id)),
  goBack: (previousRoute) => dispatch(goBackOr(goToCalls, previousRoute)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Event);
