import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'actions';

export type IState = {
  isActive: boolean;
  isShowNotification: boolean;
};

const initialState: IState = {
  isActive: false,
  isShowNotification: false,
};

const reducer = reducerWithInitialState<IState>(initialState)
  .case(
    actions.ui.hotjarService.toggleHotjarService,
    (state, payload): IState => ({
      ...state,
      isActive: payload,
    })
  )
  .case(
    actions.ui.hotjarService.setShowNotification,
    (state): IState => ({
      ...state,
      isShowNotification: !state.isShowNotification,
    })
  );

export const hotjarService = { initialState, reducer };
