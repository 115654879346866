import { ACCOUNT, OPPORTUNITY } from 'components/dashboard/Metrics/constants';
import { BIMetricColumn } from 'components/dashboard/Metrics/metrics.types';

export const NEW_WIDGET_MOCK = {
  name: '',
  chart_type: 'column',
  analysis_type: 'live',
  point_in_time: '',
  metric_list: [],
  template_filters: [],
  widget_filters: [],
  order_by: ['descending'],
  order_by_column: {
    name: '',
    label: '',
    type: '',
  },
  group_by: [],
  limit: 10,
};
export enum optionSectionsEnum {
  type = 'type',
  metric = 'metric',
  templateFilters = 'templateFilters',
}

export const SUPPORTED_REPORT_VIEW_OBJECTS = [OPPORTUNITY, ACCOUNT];

export const DEFAULT_OPPORTUNITIES_ORDER_BY: BIMetricColumn = {
  name: 'opportunity.last_touched',
  label: 'Last Touched',
  type: 'date',
};
export const DEFAULT_ACCOUNTS_ORDER_BY: BIMetricColumn = {
  name: 'account.open_opportunities',
  label: 'Open opportunities',
  type: 'number',
};
