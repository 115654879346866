import { getFontSizeForMetricAmount } from '../helper';
import * as s from './styles';
import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { BIChartTypes } from 'common/enums/metrics';
import BuIcon from 'components/UI/BuIcon';
import { Total } from 'components/dashboard/Metrics/Widget/TotalsBlock/Total/Total';
import { NOT_SAVED_METRIC } from 'components/dashboard/Metrics/Widget/constants';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import {
  BIMetricColumn,
  BIMetricToChartType,
  BIMetricsMap,
  BIWidget,
  BIWidgetDataV2,
} from 'components/dashboard/Metrics/metrics.types';
import { useElementSize } from 'components/hooks/useElementSize';

interface Props {
  showControls: boolean;
  isPivoted?: boolean;
  pivot2?: BIMetricColumn;
  metricData: BIWidgetDataV2;
  metricsInUse: BIMetricsMap;
  type: string;
  widget: Partial<BIWidget>;
  metricToChartType: BIMetricToChartType[];
  onDataClick: (metricId: string) => void;
  onChartTypeChange: (metricId: string, chartType: BIChartTypes) => void;
}

export const TotalsBlock: React.FC<Props> = ({
  showControls = false,
  isPivoted = false,
  pivot2,
  metricData,
  metricsInUse,
  widget,
  type,
  metricToChartType,
  onDataClick,
  onChartTypeChange,
}) => {
  const columns = [
    ...(metricData?.pivot_0?.columns_error || []),
    ...(metricData?.pivot_0?.columns || []),
  ];
  const isMetricNameOnly = widget.analysis_type === AnalysisType.HISTORICAL;

  const [containerRef, { height, width }] = useElementSize();

  const fontSize = getFontSizeForMetricAmount(
    height,
    width,
    widget?.metric_list?.length
  );

  const fontSizeClass = css`
    &.totalsOnly {
      span {
        font-size: ${fontSize}px;
      }
    }
    &.singleValue {
      span {
        font-size: ${fontSize * 3}px;
      }
    }
  `;

  return (
    <>
      {(!type || type === 'totalsAndChart') && (
        <BuIcon name={BoostUpIcons.ColumnGraphBox} color="var(--bu-gray-600)" />
      )}
      <div
        ref={containerRef}
        className={classNames(s.totalCardsContainer, type, fontSizeClass)}
        data-testing="totals-block-section"
      >
        {columns.map((column, index) => {
          const metricId =
            !column.metric_id || column.metric_id.startsWith('not_saved_')
              ? NOT_SAVED_METRIC
              : column.metric_id;
          const metric = metricsInUse[metricId];
          const chartType =
            metricToChartType.find((ct) => ct.metricId === column.metric_id)
              ?.chartType || (widget.chart_type as BIChartTypes);

          if (metric) {
            return (
              <Total
                index={index}
                key={column?.metric_id || `metric_id_${index}`}
                isPivoted={isPivoted}
                isMetricNameOnly={isMetricNameOnly}
                showControls={showControls}
                metricData={metricData}
                pivot2={pivot2}
                type={chartType}
                column={column}
                metric={metric}
                popupPosition={
                  Object.keys(metricsInUse ?? {})?.length > 1 || isPivoted
                    ? 'bottom left'
                    : 'bottom center'
                }
                metricsCount={widget?.metric_list?.length}
                onDataClick={onDataClick}
                onChartTypeChange={onChartTypeChange}
              />
            );
          }
        })}
      </div>
    </>
  );
};
