import { css } from 'emotion';

export const container = css`
  max-width: 480px;
`;

export const totalParagraph = css`
  font-size: 24px;
  font-eeight: 900;
  margin-bottom: 12px;
`;

export const metricNameParagraph = css`
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 4px;
`;

export const objectNameParagaph = css`
  font-size: 12px;
  color: var(--bu-gray-600);
  margin-bottom: 12px;
`;

export const tagContainer = css`
  margin-bottom: 12px;
`;

export const syntheticContainer = css`
  margin-top: 12px;
`;

export const tooltipConditions = css`
  fontSize: 12px;
  fontWeight: 305;
  & > ul {
    paddingLeft: 12px;
  },
`;
