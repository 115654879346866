import { conditionElement, conditionsList, labelParagraph } from './styles';
import React, { useContext, useMemo } from 'react';

import { UserSettingsContext } from 'components/dashboard/Metrics/contexts/UserSettingsContext';
import { getFilterHumanReadable } from 'components/dashboard/Metrics/metrics.helpers';
import { BIMetricsQueryFilter } from 'components/dashboard/Metrics/metrics.types';

interface Props {
  readonly filters: BIMetricsQueryFilter[];
}

export const ConditionsDescription: React.FC<Props> = ({ filters }) => {
  const { currency: companyCurrencyCode } = useContext(UserSettingsContext);

  const conditions = useMemo(
    () =>
      filters.map(
        (filter) =>
          filter?.and_condition?.[0]?.or_condition?.[0]?.column && (
            <li
              key={`${filter.and_condition[0].or_condition[0].column.name}_${filter.and_condition[0].or_condition[0].value}`}
              className={conditionElement}
            >
              {getFilterHumanReadable(
                filter.and_condition[0].or_condition[0],
                companyCurrencyCode
              )}
            </li>
          )
      ),
    [JSON.stringify(filters)]
  );

  return (
    <>
      <p className={labelParagraph}>Where:</p>
      <ul className={conditionsList}>{conditions}</ul>
    </>
  );
};
