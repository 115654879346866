import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import Table from 'components/UI/TableConfig/Table';
import { AdditionalMetrics } from 'components/dashboard/Metrics/Widget/FunnelWidget/AdditionalMetrics/AdditionalMetrics';
import { FunnelChart } from 'components/dashboard/Metrics/Widget/FunnelWidget/FunnelChart';
import { FunnelControls } from 'components/dashboard/Metrics/Widget/FunnelWidget/FunnelControls';
import { getTableOptions } from 'components/dashboard/Metrics/Widget/FunnelWidget/helpers.table';
import {
  MetricsBlock,
  WidgetTopBar,
  tableHeaderCellBorders,
} from 'components/dashboard/Metrics/Widget/FunnelWidget/styles';
import { WidgetHeader } from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { DataFetchErrorMessage } from 'components/dashboard/Metrics/Widget/Messages/DataFetchErrorMessage';
import { TemplateFiltersPreview } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFiltersPreview';
import {
  LoaderContainer,
  WidgetContainer,
} from 'components/dashboard/Metrics/Widget/widgets.styles';
import { UserSettingsContext } from 'components/dashboard/Metrics/contexts/UserSettingsContext';
import {
  BIWidget,
  BIWidgetDataV2,
} from 'components/dashboard/Metrics/metrics.types';
import { IReduxState } from 'reducers/types';
import * as widgetSelectors from 'selectors/revbi/widgets';
import { fetchApi, QueryStatus } from 'utils/network';

interface Props {
  updateWidget: (widget: Partial<BIWidget>) => void;
}

export const FunnelWidgetPreview: React.FC<Props> = ({ updateWidget }) => {
  const match = useRouteMatch<{ widgetId: string }>();
  const widget = useSelector((state: IReduxState) =>
    widgetSelectors.getActiveWidget(state, match.params.widgetId)
  );
  const { currency: companyCurrencyCode } = useContext(UserSettingsContext);

  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');
  const [funnelData, setFunnelData] = useState<BIWidgetDataV2>();

  const fetchWidgetData = (
    previewJson: string,
    abortController: AbortController,
    querySetting: string
  ) => {
    fetchApi<string, BIWidgetDataV2>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/v2/widgets/editor/funnel${querySetting}`,
      queryMethod: 'post',
      queryParams: previewJson,
      setData: (result) => {
        setFunnelData(result);
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load metric data ${widget.id}: ${error}`);
      },
      setStatus: setDataStatus,
      signal: abortController.signal,
    });
  };

  useEffect(() => {
    const abortController = new AbortController();
    const previewJson = JSON.stringify(widget);
    fetchWidgetData(previewJson, abortController, '');
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [
    widget.funnel_object,
    widget.funnel_stage_column,
    widget.funnel_column,
    widget.time_period,
    JSON.stringify(widget.funnel_stages),
    JSON.stringify(widget.funnel_metric_list),
    JSON.stringify(widget.funnel_top_metrics),
    JSON.stringify(widget.template_filters),
    JSON.stringify(widget.widget_filters),
    JSON.stringify(widget.funnel_conditions),
  ]);

  const tableOptions = getTableOptions(
    funnelData?.pivot_1?.columns || [],
    funnelData?.pivot_1?.data || [],
    companyCurrencyCode
  );

  return (
    <>
      <WidgetContainer
        key={widget._id}
        isDashboard={false}
        isMetricsPreview={false}
        isModal={false}
      >
        <WidgetHeader
          id={widget._id}
          name={widget.name}
          isCreateEditMetric={true}
        />

        <FunnelControls
          widget={widget}
          showControls={true}
          updateWidget={updateWidget}
        />

        <TemplateFiltersPreview
          isDateFirstPivot={true}
          templateFilters={widget.template_filters}
          widgetFilters={widget.widget_filters}
          widget={widget}
          targetPeriod={undefined}
          updateWidget={updateWidget}
        />

        {dataStatus === 'loading' && (
          <LoaderContainer>
            <Loader active content="Loading" />
          </LoaderContainer>
        )}

        {dataStatus === 'success' && (
          <>
            <WidgetTopBar>
              <MetricsBlock>
                {!!funnelData && !!funnelData.additional_metrics && (
                  <AdditionalMetrics
                    additionalMetrics={funnelData.additional_metrics}
                  />
                )}
              </MetricsBlock>
            </WidgetTopBar>

            {widget.chart_type === 'funnel' && funnelData && (
              <FunnelChart data={funnelData} />
            )}
            {widget.chart_type === 'table' && funnelData && (
              <Table
                fullscreen
                hidePaginationEnd
                hidePaginationStart
                hideSearch
                stickyColumn={true}
                columns={tableOptions.columns}
                data={tableOptions.rows}
                rowsPerPage={10}
                currentPage={0}
                className={tableHeaderCellBorders}
              />
            )}
          </>
        )}

        {dataStatus === 'error' && <DataFetchErrorMessage />}
      </WidgetContainer>
    </>
  );
};
