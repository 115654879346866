import sortBy from 'lodash/sortBy';
import { toast } from 'react-toastify';
import { put } from 'redux-saga/effects';

import * as t from 'actions/actionTypes';
import { notNullAndEmpty } from 'common/utils';
import * as genericSagas from 'sagas/generic';
import { fetchApi } from 'utils/network';

const settingsActionType = {
  UPDATE_STATE: 'SETTINGS_UPDATE_STATE',
  RESET_STATE: 'SETTINGS_RESET_STATE',
};

export const createSettingsAction = {
  updateState: (key, value) => {
    return {
      type: settingsActionType.UPDATE_STATE,
      key,
      value,
    };
  },
  resetState: () => {
    return {
      type: settingsActionType.RESET_STATE,
    };
  },
  processApiSuccess: (response) => {
    return createSettingsAction.updateState('apiSuccess', response);
  },
  processApiError: (response) => {
    return createSettingsAction.updateState('apiError', response);
  },
  processSettingsSuccess: (response) => {
    const settings = response.data;
    if (notNullAndEmpty(settings) && notNullAndEmpty(settings.dial_info)) {
      return createSettingsAction.updateState({
        dial_phone_number: settings.dial_info.dial_phone_number,
        dial_access_code: settings.dial_info.dial_access_code,
        dial_meeting_url: settings.dial_info.dial_meeting_url,
      });
    }
    return createSettingsAction.updateState({
      dial_phone_number: '',
      dial_access_code: '',
      dial_meeting_url: '',
    });
  },
};

export const doGetSettings = (email, api_access_token) => ({
  type: t.SETTINGS + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/get`,
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.SETTINGS + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.SETTINGS + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SETTINGS + t.GET + t.LOADING,
  }),
});

export const changeSettings = (changes) => ({
  type: t.SETTINGS + t.CHANGE,
  payload: changes,
});

export const doUpdateSettings = (email, api_access_token, settings) => ({
  type: t.SETTINGS + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/update`,
  data: { settings },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.SETTINGS + t.UPDATE + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.SETTINGS + t.UPDATE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SETTINGS + t.UPDATE + t.LOADING,
  }),
});

export const doUpdateCompanySettings = (settings, options) => ({
  type: t.SETTINGS + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company`,
  data: { ...settings },
  options,
  saga: genericSagas.update,
  success: (payload) => ({
    type: t.COMPANY_SETTINGS + t.UPDATE + t.SUCCESS,
    payload: {
      ...payload,
      settings,
    },
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.UPDATE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.UPDATE + t.LOADING,
  }),
});

export const doUpdateCompanyFlags = (flags, options) => ({
  type: t.SETTINGS + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/flags`,
  data: { ...flags },
  options,
  saga: genericSagas.update,
  success: (payload) => ({
    type: t.COMPANY_SETTINGS + t.FLAGS + t.UPDATE + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.FLAGS + t.UPDATE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.FLAGS + t.UPDATE + t.LOADING,
  }),
});

export const getSettingsUsers = (data) => {
  return {
    type: t.SETTINGS_USERS + t.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/users`,
    data,
    saga: genericSagas.create,
    success: (payload) => ({
      type: t.SETTINGS_USERS + t.GET + t.SUCCESS,
      payload: {
        data: sortBy(payload.data, ['name']),
        errors: payload.errors,
      },
    }),
    error: (e) => ({
      type: t.SETTINGS_USERS + t.GET + t.ERROR,
      error: e,
    }),
    loading: () => ({
      type: t.SETTINGS_USERS + t.GET + t.LOADING,
    }),
  };
};

export const getSettingsAllUsers = (data) => {
  return {
    type: t.SETTINGS_USERS + t.ALL + t.GET,
    url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/users`,
    data,
    saga: genericSagas.create,
    success: (payload) => ({
      type: t.SETTINGS_USERS + t.ALL + t.GET + t.SUCCESS,
      payload,
    }),
    error: (e) => ({
      type: t.SETTINGS_USERS + t.ALL + t.GET + t.ERROR,
      error: e,
    }),
    loading: () => ({
      type: t.SETTINGS_USERS + t.ALL + t.GET + t.LOADING,
    }),
  };
};

export const getTeamMembers = (team_type = 'immediate') => ({
  type: t.TEAM_MEMBERS + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/team-members`,
  data: {
    team_type,
  },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.TEAM_MEMBERS + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.TEAM_MEMBERS + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.TEAM_MEMBERS + t.GET + t.LOADING,
  }),
});

export const getSuggestedTeamMembers = () => ({
  type: t.SUGGESTED_TEAM_MEMBERS + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/team-members/suggested`,
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.SUGGESTED_TEAM_MEMBERS + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.SUGGESTED_TEAM_MEMBERS + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SUGGESTED_TEAM_MEMBERS + t.GET + t.LOADING,
  }),
});

export const updateTeamMembers = (team) => ({
  type: t.TEAM_MEMBERS + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/team-members/update`,
  data: { team },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.TEAM_MEMBERS + t.UPDATE + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.TEAM_MEMBERS + t.UPDATE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.TEAM_MEMBERS + t.UPDATE + t.LOADING,
  }),
});

export const getCompanySettings = () => ({
  type: t.COMPANY_SETTINGS + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.COMPANY_SETTINGS + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.GET + t.LOADING,
  }),
});

export const getCompanyIsActiveSettings = () => ({
  type: t.COMPANY_SETTINGS + t.GET + t.ACTIVE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/active`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.COMPANY_SETTINGS + t.GET + t.ACTIVE + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.GET + t.ACTIVE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.GET + t.ACTIVE + t.LOADING,
  }),
});

export const doUpdateCompanyIsActive = (settings) => ({
  type: t.COMPANY_SETTINGS + t.UPDATE + t.ACTIVE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/active`,
  data: { ...settings },
  saga: genericSagas.update,
  success: (payload) => ({
    type: t.COMPANY_SETTINGS + t.UPDATE + t.ACTIVE + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.UPDATE + t.ACTIVE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.UPDATE + t.ACTIVE + t.LOADING,
  }),
});

export const changeCompanySettings = (changes) => ({
  type: t.COMPANY_SETTINGS + t.CHANGE,
  payload: changes,
});

export const updateCompanySettings = (settings) => ({
  type: t.COMPANY_SETTINGS + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company`,
  data: settings,
  saga: genericSagas.update,
  success: (payload) => ({
    type: t.COMPANY_SETTINGS + t.UPDATE + t.SUCCESS,
    payload: {
      ...payload,
      settings,
    },
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.UPDATE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.UPDATE + t.LOADING,
  }),
});

export const doGetSalesforceSchema = () => ({
  type: t.SALESFORCE_SCHEMA + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/salesforce-schema`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.SALESFORCE_SCHEMA + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.SALESFORCE_SCHEMA + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SALESFORCE_SCHEMA + t.GET + t.LOADING,
  }),
});

export const getFieldConfigurationList = (apiBase) => ({
  type: t.FIELD_CONFIGURATION + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/fields/`,
  /* TODO get object types */
  saga: function* (action) {
    yield put(action.loading());

    yield put(
      action.success([
        { name: 'account', label: 'Account' },
        { name: 'opportunity', label: 'Opportunity' },
        { name: 'contact', label: 'Contact' },
        { name: 'user', label: 'Users' },
        { name: 'lead', label: 'Lead' },
      ])
    );
  },
  success: (payload) => ({
    type: t.FIELD_CONFIGURATION + t.GET + t.SUCCESS,
    apiBase,
    payload,
  }),
  error: (e) => ({
    type: t.FIELD_CONFIGURATION + t.GET + t.ERROR,
    apiBase,
    payload: e.response.data,
  }),
  loading: () => ({
    type: t.FIELD_CONFIGURATION + t.GET + t.LOADING,
    apiBase,
  }),
});

export const getFieldConfigurations = (configurationName, apiBase) => ({
  type: t.FIELD_CONFIGURATION + t.DETAIL + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/fields/${configurationName}`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.FIELD_CONFIGURATION + t.DETAIL + t.GET + t.SUCCESS,
    apiBase,
    configurationName,
    payload,
  }),
  error: (e) => ({
    type: t.FIELD_CONFIGURATION + t.DETAIL + t.GET + t.ERROR,
    apiBase,
    configurationName,
    payload: e.response.data,
  }),
  loading: () => ({
    type: t.FIELD_CONFIGURATION + t.DETAIL + t.GET + t.LOADING,
    apiBase,
    configurationName,
  }),
});

export const getFieldConfigurationsTables = (configurationName, apiBase) => ({
  type: t.FIELD_CONFIGURATION_TABLES + t.DETAIL + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/tables/${configurationName}`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.FIELD_CONFIGURATION_TABLES + t.DETAIL + t.GET + t.SUCCESS,
    apiBase,
    configurationName,
    payload,
  }),
  error: (e) => ({
    type: t.FIELD_CONFIGURATION_TABLES + t.DETAIL + t.GET + t.ERROR,
    apiBase,
    configurationName,
    payload: e.response.data,
  }),
  loading: () => ({
    type: t.FIELD_CONFIGURATION_TABLES + t.DETAIL + t.GET + t.LOADING,
    apiBase,
    configurationName,
  }),
});

export const addFieldToFieldConfiguration = (
  configurationName,
  field,
  apiBase
) => ({
  type: t.FIELD_CONFIGURATION + t.DETAIL + t.ADD,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/fields/${configurationName}/add`,
  data: field,
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.FIELD_CONFIGURATION + t.DETAIL + t.ADD + t.SUCCESS,
    apiBase,
    configurationName,
    field,
    payload,
  }),
  error: (e) => ({
    type: t.FIELD_CONFIGURATION + t.DETAIL + t.ADD + t.ERROR,
    apiBase,
    configurationName,
    field,
    payload: e.response.data,
  }),
  loading: () => ({
    type: t.FIELD_CONFIGURATION + t.DETAIL + t.ADD + t.LOADING,
    apiBase,
    field,
    configurationName,
  }),
});

export const updateFieldInFieldConfiguration = (
  configurationName,
  field,
  apiBase
) => ({
  type: t.FIELD_CONFIGURATION + t.DETAIL + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/fields/${configurationName}/update`,
  data: field,
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.FIELD_CONFIGURATION + t.DETAIL + t.UPDATE + t.SUCCESS,
    apiBase,
    configurationName,
    field,
    payload,
  }),
  error: (e) => ({
    type: t.FIELD_CONFIGURATION + t.DETAIL + t.UPDATE + t.ERROR,
    apiBase,
    configurationName,
    field,
    payload: e.response.data,
  }),
  loading: () => ({
    type: t.FIELD_CONFIGURATION + t.DETAIL + t.UPDATE + t.LOADING,
    apiBase,
    configurationName,
    field,
  }),
});

export const removeFieldFromFieldConfiguration = (
  configurationName,
  field,
  apiBase
) => ({
  type: t.FIELD_CONFIGURATION + t.DETAIL + t.REMOVE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/fields/${configurationName}/delete`,
  data: field,
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.FIELD_CONFIGURATION + t.DETAIL + t.REMOVE + t.SUCCESS,
    apiBase,
    configurationName,
    field,
    payload,
  }),
  error: (e) => ({
    type: t.FIELD_CONFIGURATION + t.DETAIL + t.REMOVE + t.ERROR,
    apiBase,
    configurationName,
    field,
    payload: e.response.data,
  }),
  loading: () => ({
    type: t.FIELD_CONFIGURATION + t.DETAIL + t.REMOVE + t.LOADING,
    apiBase,
    configurationName,
    field,
  }),
});

export const getTableCollection = (apiBase, collectionName) => ({
  type: t.TABLE_CONFIGURATION + t.DETAIL + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}${apiBase}/tables/${collectionName}`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.TABLE_CONFIGURATION + t.DETAIL + t.GET + t.SUCCESS,
    apiBase,
    collectionName,
    payload,
  }),
  error: (e) => ({
    type: t.TABLE_CONFIGURATION + t.DETAIL + t.GET + t.ERROR,
    apiBase,
    collectionName,
    payload: e.response.data,
  }),
  loading: () => ({
    type: t.TABLE_CONFIGURATION + t.DETAIL + t.GET + t.LOADING,
    apiBase,
    collectionName,
  }),
});

export const updateTableCollection = (apiBase, collectionName, data) => ({
  type: t.TABLE_CONFIGURATION + t.DETAIL + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}${apiBase}/tables/${collectionName}`,
  data,
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.TABLE_CONFIGURATION + t.DETAIL + t.UPDATE + t.SUCCESS,
    apiBase,
    collectionName,
    table: data,
    payload,
  }),
  error: (e) => ({
    type: t.TABLE_CONFIGURATION + t.DETAIL + t.UPDATE + t.ERROR,
    apiBase,
    collectionName,
    table: data,
    payload: e.response.data,
  }),
  loading: () => ({
    type: t.TABLE_CONFIGURATION + t.DETAIL + t.UPDATE + t.LOADING,
    apiBase,
    table: data,
    collectionName,
  }),
});

export const deletePartitionFromFieldsConfiguration = (apiBase) => ({
  type: t.TABLE_CONFIGURATION + '_DROP_PARTITION',
  apiBase,
});

export const getTableColumnConfiguration = (collectionName, tableName) => ({
  type: t.TABLE_CONFIGURATION + t.TABLE + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/tables/${collectionName}/${tableName}`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.TABLE_CONFIGURATION + t.TABLE + t.GET + t.SUCCESS,
    collectionName,
    tableName,
    payload,
  }),
  error: (e) => ({
    type: t.TABLE_CONFIGURATION + t.TABLE + t.GET + t.ERROR,
    collectionName,
    tableName,
    payload: e.response.data,
  }),
  loading: () => ({
    type: t.TABLE_CONFIGURATION + t.TABLE + t.GET + t.LOADING,
    collectionName,
    tableName,
  }),
});

export const getSettingsFiltersByPageSuccess = (tab, data) => ({
  type: t.SETTINGS + t.GET + t.FILTER + t.SUCCESS,
  tab,
  data,
});

export const getSettingsFiltersByPageFailure = (error) => ({
  type: t.SETTINGS + t.GET + t.FILTER + t.ERROR,
  error,
});

export const updateSettingsFiltersSuccess = (page, tab, data) => ({
  type: t.SETTINGS + t.UPDATE + t.FILTER + t.SUCCESS,
  page,
  tab,
  data,
});

export const updateSettingsFiltersFailure = (error) => ({
  type: t.SETTINGS + t.UPDATE + t.FILTER + t.ERROR,
  error,
});

export const setBusinessTypesStatus = (status) => ({
  type: t.BUSINESS_TYPES + t.SET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/business_types/status`,
  data: {
    status,
  },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.BUSINESS_TYPES + t.STATUS + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.BUSINESS_TYPES + t.STATUS + t.GET + t.ERROR,
    payload: e.response.data,
  }),
  loading: () => ({
    type: t.BUSINESS_TYPES + t.STATUS + t.GET + t.LOADING,
    status,
  }),
});

export const getBusinessTypesSettings = () => ({
  type: t.BUSINESS_TYPES + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/business_types`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.BUSINESS_TYPES + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.BUSINESS_TYPES + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.BUSINESS_TYPES + t.GET + t.LOADING,
  }),
});

export const setBusinessTypesSettings = (data) => ({
  type: t.BUSINESS_TYPES + t.SET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/business_types`,
  data,
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.BUSINESS_TYPES + t.SET + t.SUCCESS,
    payload,
    data,
  }),
  error: (e) => ({
    type: t.BUSINESS_TYPES + t.SET + t.ERROR,
    payload: e.response.data,
    data,
  }),
  loading: () => ({
    type: t.BUSINESS_TYPES + t.SET + t.LOADING,
    data,
  }),
});

export const setBusinessTypesPrimaryField = (field) => ({
  type: t.BUSINESS_TYPES + t.SET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/business_types/business_type_field`,
  data: { business_type_field: field },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.BUSINESS_TYPES + t.SET + t.PRIMARY_FIELD + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.BUSINESS_TYPES + t.SET + t.PRIMARY_FIELD + t.ERROR,
    payload: e.response.data,
  }),
  loading: () => ({
    type: t.BUSINESS_TYPES + t.SET + t.PRIMARY_FIELD + t.LOADING,
  }),
});

export const deleteBusinessType = (data) => ({
  type: t.BUSINESS_TYPES + t.SET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/business_types`,
  data,
  saga: genericSagas.remove,
  success: (payload) => {
    toast.success('Business type removed successfully.', {
      position: 'bottom-left',
    });

    return {
      type: t.BUSINESS_TYPES + t.REMOVE + t.SUCCESS,
      payload,
      data,
    };
  },
  error: (e) => ({
    type: t.BUSINESS_TYPES + t.REMOVE + t.ERROR,
    payload: e.response.data,
    data,
  }),
  loading: () => ({
    type: t.BUSINESS_TYPES + t.REMOVE + t.LOADING,
  }),
});

export const clearDeleteableBusinessType = (data) => ({
  type: t.BUSINESS_TYPES + t.REMOVE + t.CLEAR,
  data,
});

export const sortBusinessTypes = (data) => ({
  type: t.BUSINESS_TYPES + t.UPDATE + t.SORTING,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/business_types/bulk_update_order`,
  data,
  saga: genericSagas.patch,
  success: (payload) => ({
    type: t.BUSINESS_TYPES + t.UPDATE + t.SORTING + t.SUCCESS,
    payload,
    data,
  }),
  error: (e) => ({
    type: t.BUSINESS_TYPES + t.UPDATE + t.SORTING + t.ERROR,
    payload: e.response.data,
    data,
  }),
  loading: () => ({
    type: t.BUSINESS_TYPES + t.UPDATE + t.SORTING + t.LOADING,
    data,
  }),
});

export const getCRMForecastCategories = () => ({
  type: t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/get-forecast-category-mapping`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.GET + t.LOADING,
  }),
});

export const getCRMSyncStatus = () => ({
  type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/crm/sync/`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET + t.LOADING,
  }),
});

/**
 *
 * @param {string} table_name
 * @param {boolean} [sync_enabled]
 */
export const setCRMRTSync = (table_name, rt_sync_enabled) => ({
  type: t.COMPANY_SETTINGS + t.RT + t.CRM_SYNC + t.SET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/crm/sync/rt-sync`,
  data: {
    table_name,
    rt_sync_enabled,
  },
  options: {
    toast: { error: true },
  },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.COMPANY_SETTINGS + t.RT + t.CRM_SYNC + t.SET + t.SUCCESS,
    payload: {
      table_name,
      rt_sync_enabled,
    },
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.RT + t.CRM_SYNC + t.SET + t.ERROR,
    payload: {
      table_name,
      rt_sync_enabled,
      error: e,
    },
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.RT + t.CRM_SYNC + t.SET + t.LOADING,
  }),
});

export const toggleCRMSyncSubmittingState = () => {
  return {
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.SUBMIT + t.TOGGLE,
  };
};

export const getCRMSyncUserList = () => ({
  type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.USERS + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/crm/sync/users`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.USERS + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.USERS + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.USERS + t.GET + t.LOADING,
  }),
});

/**
 *
 * @param {string} email
 */
export const setCRMSyncUser = (email) => ({
  type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.USER + t.SET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/crm/sync/user`,
  data: {
    email,
  },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.USER + t.SET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.USER + t.SET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.USER + t.SET + t.LOADING,
  }),
});

/**
 *
 * @param {string} [email]
 */
export const testCRMSyncUser = (email) => ({
  type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.USER + t.TEST,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/crm/sync/test`,
  data: {
    email,
  },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.USER + t.TEST + t.SUCCESS,
    payload: email,
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.USER + t.TEST + t.ERROR,
    payload: email,
    error: e,
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.USER + t.TEST + t.LOADING,
    payload: email,
  }),
});

/**
 *
 * @param {string} table_name
 * @param {boolean} [sync_enabled]
 */
export const enableCRMTable = (table_name, sync_enabled = false) => ({
  type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.TABLE + t.SET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/crm/sync/restrict`,
  data: {
    table_name,
    sync_enabled,
  },
  options: {
    toast: { error: true },
  },
  saga: genericSagas.create,
  success: () => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.TABLE + t.SET + t.SUCCESS,
    payload: {
      table_name,
      sync_enabled,
    },
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.TABLE + t.SET + t.ERROR,
    payload: {
      table_name,
      sync_enabled,
      error: e,
    },
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.TABLE + t.SET + t.LOADING,
  }),
});

/**
 *
 * @param {string} table_name
 * @param {number} [new_frequency_time_minutes]
 */
export const enableCRMFrequencyTime = (
  table_name,
  new_frequency_time_minutes
) => ({
  type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.FREQUENCY_TIME + t.SET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/crm/sync/update-table-sync-frequency`,
  data: {
    table_name,
    new_frequency_time_minutes,
  },
  saga: genericSagas.create,
  success: () => ({
    type:
      t.COMPANY_SETTINGS + t.CRM_SYNC + t.FREQUENCY_TIME + t.SET + t.SUCCESS,
    payload: {
      table_name,
      new_frequency_time_minutes,
    },
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.FREQUENCY_TIME + t.SET + t.ERROR,
    payload: {
      table_name,
      new_frequency_time_minutes,
      error: e,
    },
  }),
  loading: () => ({
    type:
      t.COMPANY_SETTINGS + t.CRM_SYNC + t.FREQUENCY_TIME + t.SET + t.LOADING,
  }),
});

/**
 *
 * @param {'full'|'incr'} run_type
 * @param {string[]} [only_tables]
 * @param {boolean} [only_failed]
 */
export const updateCRMSyncStatus = (
  run_type,
  { only_tables, only_failed } = {}
) => ({
  type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.SET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/crm/sync/`,
  data: {
    run_type,
    force: true,
    queue: 'sf_incremental_sync',
    only_tables,
    only_failed,
  },
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.SET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.SET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.SET + t.LOADING,
  }),
});

export const getCrmSyncTableConditions = (tableName) => ({
  type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET + t.CONDITIONS,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/crm/sync/conditions/${tableName}`,
  saga: genericSagas.get,
  success: (payload) => {
    // TODO: this mapping is temporary until the next API part is ready
    const result = payload?.data?.reduce(
      (acc, item) => {
        let fieldType;

        switch (item.field_type) {
          case 'string':
            fieldType = 'text';
            break;
          default:
            fieldType = item.field_type;
        }

        return {
          ...acc,
          conditions: [
            ...acc.conditions,
            {
              field: item.field,
              operator: item.operator,
              value: item.value,
            },
          ],
          possibleConditions: [
            ...acc.possibleConditions,
            {
              display_name: item.display_name || item.field,
              type: fieldType,
              object_field: item.field,
            },
          ],
        };
      },

      {
        conditions: [],
        possibleConditions: [],
      }
    );

    return {
      type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET + t.CONDITIONS + t.SUCCESS,
      payload: result,
    };
  },
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET + t.CONDITIONS + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.CRM_SYNC + t.GET + t.CONDITIONS + t.LOADING,
  }),
});

export const clearCrmSyncTableConditions = () => ({
  type: t.SETTINGS + t.CRM_SYNC + t.CLEAR + t.CONDITIONS,
});

export const updateCRMForecastCategories = (categories) => ({
  type: t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.SET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/set-forecast-category-mapping`,
  data: categories,
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.SET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.SET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.CRM_FORECAST_CATEGORY + t.SET + t.LOADING,
  }),
});

export const getForecastCategories = () => ({
  type: t.COMPANY_SETTINGS + t.FORECAST_CATEGORY + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/company/forecast-categories`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.COMPANY_SETTINGS + t.FORECAST_CATEGORY + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.COMPANY_SETTINGS + t.FORECAST_CATEGORY + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.COMPANY_SETTINGS + t.FORECAST_CATEGORY + t.GET + t.LOADING,
  }),
});

export const getSalesProcessConfiguration = () => ({
  type: t.SETTINGS + t.SALES_CONFIG + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/sales_process/config`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.SETTINGS + t.SALES_CONFIG + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.SETTINGS + t.SALES_CONFIG + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SETTINGS + t.SALES_CONFIG + t.GET + t.LOADING,
  }),
});

export const addNewSalesProcess = (data) => ({
  type: t.SETTINGS + t.SALES_PROCESS + t.ADD,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/sales_process`,
  data,
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.SETTINGS + t.SALES_PROCESS + t.ADD + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.SETTINGS + t.SALES_PROCESS + t.ADD + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SETTINGS + t.SALES_PROCESS + t.ADD + t.LOADING,
  }),
});

export const editSalesProcess = (name, data) => ({
  type: t.SETTINGS + t.SALES_PROCESS + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/sales_process/${name}`,
  data,
  saga: genericSagas.update,
  success: (payload) => ({
    type: t.SETTINGS + t.SALES_PROCESS + t.UPDATE + t.SUCCESS,
    payload: [payload],
  }),
  error: (e) => ({
    type: t.SETTINGS + t.SALES_PROCESS + t.UPDATE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SETTINGS + t.SALES_PROCESS + t.UPDATE + t.LOADING,
  }),
});

export const removeSalesProcess = (name) => ({
  type: t.SETTINGS + t.SALES_PROCESS + t.REMOVE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/sales_process/${name}`,
  saga: genericSagas.remove,
  success: () => ({
    type: t.SETTINGS + t.SALES_PROCESS + t.REMOVE + t.SUCCESS,
  }),
  error: (e) => ({
    type: t.SETTINGS + t.SALES_PROCESS + t.REMOVE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SETTINGS + t.SALES_PROCESS + t.REMOVE + t.LOADING,
  }),
});

export const getSalesProcessSettings = () => ({
  type: t.SETTINGS + t.SALES_PROCESS + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/sales_process`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.SETTINGS + t.SALES_PROCESS + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.SETTINGS + t.SALES_PROCESS + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SETTINGS + t.SALES_PROCESS + t.GET + t.LOADING,
  }),
});

export const submitSOQLQuery = (query, abortController) => (dispatch) => {
  dispatch({ type: t.SETTINGS + t.SOQL_QUERY + t.SUBMIT + t.LOADING });

  return fetchApi({
    url: `${process.env.REACT_APP_BACKEND_URL}/api/data/search/soql_query`,
    queryMethod: 'POST',
    queryParams: { q: query },
    setData(response) {
      dispatch({
        type: t.SETTINGS + t.SOQL_QUERY + t.SUBMIT + t.SUCCESS,
        payload: response,
      });
    },
    setError(error) {
      toast.error(`Fetching data failed`, {
        position: 'bottom-left',
      });

      dispatch({
        type: t.SETTINGS + t.SOQL_QUERY + t.SUBMIT + t.ERROR,
        payload: error,
      });
    },
    signal: abortController?.signal,
  });
};

export const resetSOQLQuery = () => (dispatch) => {
  dispatch({ type: t.SETTINGS + t.SOQL_QUERY + t.RESET });
};

export const getUserProfileDisplayConfiguration = () => ({
  type: t.SETTINGS + t.USER + t.PROFILE + t.DISPLAY + t.CONFIGURATION,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/tables/opportunity/DealsInsights`,
  saga: genericSagas.get,
  success: (payload) => ({
    type:
      t.SETTINGS + t.USER + t.PROFILE + t.DISPLAY + t.CONFIGURATION + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type:
      t.SETTINGS + t.USER + t.PROFILE + t.DISPLAY + t.CONFIGURATION + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type:
      t.SETTINGS + t.USER + t.PROFILE + t.DISPLAY + t.CONFIGURATION + t.LOADING,
  }),
});

export const getBusinessTypesConfig = () => ({
  type: t.BUSINESS_TYPES_CONFIG + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/business_types/enabled_business_types`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.BUSINESS_TYPES_CONFIG + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.BUSINESS_TYPES_CONFIG + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.BUSINESS_TYPES_CONFIG + t.GET + t.LOADING,
  }),
});

export const clearBusinessTypesConfig = () => ({
  type: t.BUSINESS_TYPES_CONFIG + t.CLEAR,
});

export const getActivityMatchingSortingKeys = () => ({
  type: t.SETTINGS + t.ACTIVITY_MATCHING + t.SORTING_KEYS + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/activity_matching`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.SETTINGS + t.ACTIVITY_MATCHING + t.SORTING_KEYS + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.SETTINGS + t.ACTIVITY_MATCHING + t.SORTING_KEYS + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SETTINGS + t.ACTIVITY_MATCHING + t.SORTING_KEYS + t.GET + t.LOADING,
  }),
});

export const getActivityMatchingFieldsInfo = (category) => ({
  type: t.SETTINGS + t.ACTIVITY_MATCHING + t.FIELDS_INFO + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/activity_matching/fields_info/${category}`,
  saga: genericSagas.get,
  options: {
    toast: { error: true },
  },
  success: (payload) => ({
    type: t.SETTINGS + t.ACTIVITY_MATCHING + t.FIELDS_INFO + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.SETTINGS + t.ACTIVITY_MATCHING + t.FIELDS_INFO + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.SETTINGS + t.ACTIVITY_MATCHING + t.FIELDS_INFO + t.GET + t.LOADING,
  }),
});

export const submitActivityMatchingSortingKeys = (data) => ({
  type: t.SETTINGS + t.ACTIVITY_MATCHING + t.SORTING_KEYS + t.SUBMIT,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/activity_matching`,
  saga: genericSagas.create,
  data,
  options: {
    toast: { error: true },
  },
  success: (payload) => ({
    type:
      t.SETTINGS + t.ACTIVITY_MATCHING + t.SORTING_KEYS + t.SUBMIT + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type:
      t.SETTINGS + t.ACTIVITY_MATCHING + t.SORTING_KEYS + t.SUBMIT + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type:
      t.SETTINGS + t.ACTIVITY_MATCHING + t.SORTING_KEYS + t.SUBMIT + t.LOADING,
  }),
});

export const clearActivityMatching = () => ({
  type: t.SETTINGS + t.ACTIVITY_MATCHING + t.SET + t.CLEAR,
});

export const setActivityMatchingCategory = (category) => ({
  type: t.SETTINGS + t.ACTIVITY_MATCHING + t.SET + t.CATEGORY,
  payload: category,
});
