import { isNil, groupBy } from 'ramda';
import React from 'react';

import {
  CopyCROSubmissionResponse,
  CROUserSubmission,
  SubmissionColumn,
  SubmissionForecastChanges,
  SubmissionForecastParams,
  SubmissionSettingsId,
  SubmissionSummary,
  UserSubmission,
  UserSubmissionBookedAndTarget,
  UserSubmissionBookedAndTargetData,
} from 'actions/croOverrideActions';
import { formatMoney, getCurrencySymbol } from 'common/numbers';
import {
  IColumn,
  SortOrder,
  IDataCellProps,
  IRow,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import {
  changeField,
  SwitchComponentOptions,
  switchComponents,
} from 'components/UI/common/TypedTable/renderers/custom';
import { getCellValue } from 'components/UI/common/TypedTable/renderers/custom/common';
import {
  forecast,
  forecastCRO,
  myCall,
  myCallCRO,
} from 'components/dashboard/CROOverride/cell-renderers';
import { MyCallCellConfig } from 'components/dashboard/CROOverride/cell-renderers/myCall';
import { MyCallCROCellConfig } from 'components/dashboard/CROOverride/cell-renderers/myCallCRO';
import {
  cellBackground,
  cellTouched,
} from 'components/dashboard/CROOverride/styles';
import {
  isCRORow,
  isUserRow,
  RowSubmission,
  RowTypeFieldName,
  RowWithExistingSubmissions,
  SubmissionTouchedFieldName,
} from 'components/dashboard/CROOverride/types';
import {
  SubmissionBookedAndTargetState,
  SubmissionBookedAndTargetStatusState,
} from 'reducers/croOverrideReducer';

const FORECAST_COLUMN_WIDTH = 200;

export interface GetColumnsProps
  extends Pick<
      MyCallCROCellConfig,
      'onNoteEditorOpen' | 'onCopySubmissionSettings' | 'onSelectDealsClick'
    >,
    Pick<MyCallCellConfig, 'onCopyClick'> {
  companyCurrency: string;
  submissions: SubmissionColumn[];
  visibleSubmissions: SubmissionColumn[];
  bookedAndTargetEnabled: boolean;
}

type GetColumns = (config: GetColumnsProps) => IColumn[];

/**
 * Returns Submission TypedTable column list configuration
 */
export const getColumns: GetColumns = ({
  companyCurrency,
  submissions,
  visibleSubmissions,
  onNoteEditorOpen,
  onCopyClick,
  onSelectDealsClick,
  onCopySubmissionSettings,
  bookedAndTargetEnabled,
}): IColumn[] => {
  const currencyFormatter = formatMoney.bind(formatMoney, companyCurrency);
  const currencySymbol = getCurrencySymbol(companyCurrency);

  /**
   * Returns pairs of forecast and my call columns
   */
  const getColumnPair = (column: SubmissionColumn): [IColumn, IColumn] => {
    const {
      display_name: label,
      business_type: businessType,
      submission_settings_id: submissionSettingsId,
      forecast_name: forecastName,
      period_interval: period,
    } = column;

    const fieldPath: MapPaths.Paths<Pick<RowSubmission, 'submissions'>> = [
      'submissions',
      submissionSettingsId,
    ];
    const fieldPathString = fieldPath.join('.');

    return [
      {
        id: `${businessType}-${submissionSettingsId}`,
        label: label,
        labelForceWrap: true,
        field: RowTypeFieldName,
        type: ColumnTypes.CUSTOM,
        sort_order: SortOrder.ASCENDING,
        width: FORECAST_COLUMN_WIDTH,
        minWidth: FORECAST_COLUMN_WIDTH,
        maxWidth: FORECAST_COLUMN_WIDTH,
        config: {
          className: cellBackground,
          renderer: switchComponents({
            CRO: changeField(
              forecastCRO({
                companyCurrency: companyCurrency,
                currencyFormatter: currencyFormatter,
                period,
                businessType,
                forecastName,
              }),
              fieldPathString
            ),
            User: changeField(
              forecast({
                companyCurrency: companyCurrency,
                currencyFormatter: currencyFormatter,
                period,
                businessType,
                forecastName,
              }),
              fieldPathString
            ),
          } as SwitchComponentOptions<RowSubmission[RowTypeFieldName]>),
        } as CustomCellConfig,
      },
      {
        id: `${businessType}-${submissionSettingsId}-my_call`,
        label: `${label}\nMy Call`,
        labelForceWrap: true,
        field: RowTypeFieldName,
        type: ColumnTypes.CUSTOM,
        sort_order: SortOrder.ASCENDING,
        width: FORECAST_COLUMN_WIDTH,
        minWidth: FORECAST_COLUMN_WIDTH,
        maxWidth: FORECAST_COLUMN_WIDTH,
        editable: true,
        config: {
          className(row) {
            const rowT = row as RowSubmission;
            return rowT.submissions[submissionSettingsId]?.[
              SubmissionTouchedFieldName
            ]
              ? cellTouched
              : '';
          },
          renderer: switchComponents({
            CRO: changeField(
              myCallCRO({
                currencyFormatter: currencyFormatter,
                currencySymbol: currencySymbol,
                symbolOnLeft: true,
                onNoteEditorOpen,
                onSelectDealsClick,
                submissions,
                submissionSettingsId,
                onCopySubmissionSettings(submissionSettingsIdFrom) {
                  onCopySubmissionSettings(
                    submissionSettingsIdFrom,
                    submissionSettingsId
                  );
                },
              }),
              fieldPathString
            ),
            User: changeField(
              myCall({
                copyFieldLabel: label,
                currencySymbol: currencySymbol,
                currencyFormatter: currencyFormatter,
                symbolOnLeft: true,
                onCopyClick,
                onSelectDealsClick,
                submitEnabledForRoles: column.submit_enabled_for_roles,
              }),
              fieldPathString
            ),
          } as SwitchComponentOptions<RowSubmission[RowTypeFieldName]>),
        } as CustomCellConfig,
      },
    ];
  };

  const getBookedAndTargetForPeriodAndBusinessType = (
    groupedSubmission: SubmissionColumn[]
  ): IColumn => {
    const firstSubmission = groupedSubmission[0];
    const {
      business_type: businessType,
      period_info: periodLabel,
      submission_settings_id: submissionSettingsId,
    } = firstSubmission;

    return {
      id: `${businessType}-${submissionSettingsId}-target_and_booked`,
      label: `${businessType}\nTarget & Booked (${periodLabel})`,
      labelForceWrap: true,
      field: 'bookedAndTarget',
      type: ColumnTypes.CUSTOM,
      sort_order: SortOrder.ASCENDING,
      width: FORECAST_COLUMN_WIDTH,
      minWidth: FORECAST_COLUMN_WIDTH,
      config: {
        className: cellBackground,
        renderer: (props: IDataCellProps) => {
          const bookedAndTargetForUser = getCellValue(
            props
          ) as UserSubmissionBookedAndTarget | null;
          const bookedAndTargetForPeriod =
            bookedAndTargetForUser?.data?.[periodLabel];
          const bookedAndTarget = bookedAndTargetForPeriod?.[businessType];

          const bookedValue = bookedAndTarget?.booked ?? 0;
          const targetValue = bookedAndTarget?.target ?? 0;
          const requestStatus =
            bookedAndTargetForUser?.requestStatus ?? 'loading';

          const formattedBooked = currencyFormatter(bookedValue);
          const formattedValue = currencyFormatter(targetValue);

          return requestStatus === 'success' ? (
            <>
              <p>Booked: {formattedBooked}</p>
              <p>Target: {formattedValue} </p>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <div className="ui tiny active centered inline loader"></div>
            </div>
          );
        },
      } as CustomCellConfig,
    };
  };

  const getColumnsWithBookedAndTarget = (
    visibleSubmissions: SubmissionColumn[]
  ): IColumn[] =>
    Object.values(groupBy(samePeriodAndBussinessType, visibleSubmissions))
      .map((groupedSubmission) => [
        getBookedAndTargetForPeriodAndBusinessType(groupedSubmission),
        ...groupedSubmission.map<IColumn[]>(getColumnPair).flat(),
      ])
      .flat();

  const getColumnsWithoutBookedAndTarget = (
    visibleSubmissions: SubmissionColumn[]
  ): IColumn[] => visibleSubmissions.map<IColumn[]>(getColumnPair).flat();

  const columns = bookedAndTargetEnabled
    ? getColumnsWithBookedAndTarget(visibleSubmissions)
    : getColumnsWithoutBookedAndTarget(visibleSubmissions);

  return [
    {
      id: 'user',
      label: 'Sales Org',
      field: 'name',
      type: ColumnTypes.TEXT,
      sort_order: SortOrder.ASCENDING,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      config: {
        subValue: {
          relativeField: 'role',
          template: '',
        },
      },
    },
    ...columns,
  ];
};

const samePeriodAndBussinessType = (submission: SubmissionColumn): string =>
  `${submission.period_info}.${submission.business_type}`;

const isSubmissionOverrideAmountExists = <
  T extends UserSubmission | CROUserSubmission
>(
  item: T | undefined
): item is T => !isNil(item?.override_amount);

export const isSubmissionExists =
  (submission: SubmissionSettingsId) =>
  <T extends RowSubmission>(item: T): item is RowWithExistingSubmissions<T> =>
    !!item.submissions[submission];

/**
 * Converts table rows with `my calls` to `submission overrides
 * @param rows
 * @param submissionIds
 */
const convertMyCallToSubmissionEntry = (
  rows: RowSubmission[],
  submissionIds: string[]
): SubmissionForecastChanges[] =>
  rows
    .filter(isUserRow)
    .map((item) =>
      submissionIds
        .map((submission) => item.submissions[submission])
        .filter(isSubmissionOverrideAmountExists)
        .map((submission) => ({
          user_id: item.user_id,
          submission_settings_id: submission.submission_settings_id,
          submission_id: submission.user_submission_id ?? null,
          amount: submission.override_amount,
          excluded_deals_ids: submission.override_excluded_deals_ids,
          included_deals_ids: submission.override_included_deals_ids,
          included_deals_amount: submission.override_included_deals_amount,
        }))
    )
    .flat();

/**
 * Creates summary
 * @param rows
 * @param submissionIds
 */
const getSubmissionOverridesSummary = (
  rows: RowSubmission[],
  submissionIds: string[]
): SubmissionSummary[] => {
  const item = rows.find(isCRORow);

  return submissionIds
    .map((submission) => item?.submissions[submission])
    .filter(isSubmissionOverrideAmountExists)
    .map((submission) => ({
      submission_settings_id: submission.submission_settings_id,
      amount: submission.override_amount,
      excluded_deals_ids: submission.override_excluded_deals_ids,
      included_deals_ids: submission.override_included_deals_ids,
      included_deals_amount: submission.override_included_deals_amount,
      notes: submission.notes,
    }));
};

/**
 * Prepares saving payload
 * @param submissionsIds
 * @param rows
 * @param submit
 */
export const createSubmissionOverridePayload = (
  submissionsIds: SubmissionSettingsId[],
  rows: RowSubmission[],
  submit: boolean
): SubmissionForecastParams => {
  const overrides = convertMyCallToSubmissionEntry(rows, submissionsIds);
  const summary = getSubmissionOverridesSummary(rows, submissionsIds);

  return {
    submit: submit,
    summary: summary,
    overrides: overrides,
  };
};

export const getSubmissionFingerprint = (
  submission: Partial<
    Pick<
      UserSubmission,
      | 'override_included_deals_ids'
      | 'override_excluded_deals_ids'
      | 'override_included_deals_amount'
    >
  >
) => {
  return [
    (submission.override_included_deals_ids?.length ?? 0) +
      (submission.override_excluded_deals_ids?.length ?? 0),
    submission.override_included_deals_amount,
  ].toString();
};

export const getOverrideUser = (
  { data }: CopyCROSubmissionResponse,
  userId: string
): Partial<CROUserSubmission | UserSubmission> | undefined => {
  const user = data.find((item) => item.user_id === userId);

  if (!user) {
    return;
  }

  const {
    excluded_deal_ids: override_excluded_deals_ids,
    included_deal_ids: override_included_deals_ids,
    included_deals_amount: override_included_deals_amount,
    notes,
  } = user;

  return {
    notes,
    override_amount: override_included_deals_amount,
    override_included_deals_amount,
    override_included_deals_ids,
    override_excluded_deals_ids,
  };
};

const getOverrideSummary = (
  data: RowSubmission[],
  submissionSettingsId: SubmissionSettingsId
) =>
  data
    .filter(isUserRow)
    .filter(isSubmissionExists(submissionSettingsId))
    .reduce<
      Pick<
        CROUserSubmission,
        | 'override_excluded_deals_ids'
        | 'override_included_deals_ids'
        | 'override_included_deals_amount'
      >
    >(
      (acc, { submissions }) => {
        const submission = submissions[submissionSettingsId];
        const {
          override_included_deals_amount,
          override_included_deals_ids,
          override_excluded_deals_ids,
        } = submission;
        return {
          override_excluded_deals_ids: [
            ...acc.override_excluded_deals_ids,
            ...(override_excluded_deals_ids ?? []),
          ],
          override_included_deals_ids: [
            ...acc.override_included_deals_ids,
            ...(override_included_deals_ids ?? []),
          ],
          override_included_deals_amount:
            acc.override_included_deals_amount! +
            (override_included_deals_amount ?? 0),
        };
      },
      {
        override_excluded_deals_ids: [],
        override_included_deals_ids: [],
        override_included_deals_amount: 0,
      }
    );

export const getRowsWithUpdatedCRO = (
  data: RowSubmission[],
  submission_settings_id: SubmissionSettingsId
): RowSubmission[] => {
  const submissionsSummary = getOverrideSummary(data, submission_settings_id);

  return data.map((item) =>
    isCRORow(item)
      ? {
          ...item,
          submissions: {
            ...item.submissions,
            [submission_settings_id]: {
              ...item.submissions[submission_settings_id],
              ...submissionsSummary,
              [SubmissionTouchedFieldName]:
                item.submissions[submission_settings_id][
                  SubmissionTouchedFieldName
                ] ||
                data.some(
                  (userRows) =>
                    isUserRow(userRows) &&
                    userRows.submissions[submission_settings_id]?.[
                      SubmissionTouchedFieldName
                    ]
                ),
            },
          },
        }
      : item
  );
};

export const getBookedAndTargetForUser = (
  submissionBookedAndTargetResponse: SubmissionBookedAndTargetState,
  userId: string
) => {
  const bookedAndTarget: UserSubmissionBookedAndTargetData = {};
  Object.entries(submissionBookedAndTargetResponse).forEach(
    ([period, bookedAndTargetByBusinessType]) => {
      Object.entries(bookedAndTargetByBusinessType).forEach(
        ([businessType, bookedAndTargetByUser]) => {
          if (bookedAndTargetByUser.data) {
            const dataForUser = bookedAndTargetByUser.data.find(
              (userBookedAndTarget) =>
                userBookedAndTarget?.user_id?.$oid === userId
            );
            const booked = dataForUser?.Booked || 0;
            const target = dataForUser?.Target || 0;
            bookedAndTarget[period] = {
              ...bookedAndTarget[period],
              [businessType]: {
                booked,
                target,
              },
            };
          }
        }
      );
    }
  );
  return bookedAndTarget;
};

export const mergeFlatSubmissionsWithBookedAndTarget = (
  filteredFlatSubmissions: RowSubmission[],
  submissionBookedAndTargetStatus: SubmissionBookedAndTargetStatusState,
  submissionBookedAndTarget: SubmissionBookedAndTargetState
) => {
  if (
    submissionBookedAndTargetStatus === 'success' &&
    !!submissionBookedAndTarget
  ) {
    return filteredFlatSubmissions.map((row) => {
      const bookedAndTargetForUser = getBookedAndTargetForUser(
        submissionBookedAndTarget,
        row.user_id
      );
      return {
        ...row,
        bookedAndTarget: {
          requestStatus: submissionBookedAndTargetStatus,
          data: bookedAndTargetForUser,
        },
      };
    });
  } else {
    return filteredFlatSubmissions;
  }
};

const defaultPredicateToAggregate = () => true;

const hasBookedAndTarget = (tree: RowSubmission) =>
  !!tree.bookedAndTarget?.data;

const aggregateBooked = (
  tree: RowSubmission,
  aggregatedChildren: RowSubmission[]
): Partial<RowSubmission> => {
  const bookedAndTarget = tree.bookedAndTarget;
  const { data: bookedAndTargetData = {}, requestStatus = 'loading' } =
    bookedAndTarget || {};
  const aggregatedBookedAndTarget: UserSubmissionBookedAndTargetData = {};
  Object.entries(bookedAndTargetData).forEach(
    ([period, bookedAndTargetByBusinessType]) => {
      Object.entries(bookedAndTargetByBusinessType).forEach(
        ([businessType, bookedAndTarget]) => {
          const rootBooked = bookedAndTarget.booked;
          const bookedSum = aggregatedChildren.reduce(
            (partialAggregatedBooked, children) => {
              const bookedAndCommit = children.bookedAndTarget;
              const bookedForThisChildren =
                bookedAndCommit?.data?.[period]?.[businessType]?.booked ?? 0;
              return partialAggregatedBooked + bookedForThisChildren;
            },
            rootBooked
          );
          aggregatedBookedAndTarget[period] = {
            ...aggregatedBookedAndTarget[period],
            [businessType]: {
              booked: bookedSum,
              target: bookedAndTarget.target,
            },
          };
        }
      );
    }
  );
  return {
    bookedAndTarget: {
      requestStatus,
      data: aggregatedBookedAndTarget,
    },
  };
};

export const aggregateBookedFromChildren = (tree: RowSubmission) =>
  aggregateTree(tree, aggregateBooked, hasBookedAndTarget);

type AggreagteChildrenToParent<T> = (parent: T, children: T[]) => Partial<T>;
type PredicateToAggregate<T> = (tree: T) => boolean;

/**
 * This function will iterate over the tree and will apply aggreagteChildrenToParent function
 * to the node. Returns a whole new mapped tree.
 *
 * @param tree Tree Root Node
 * @param aggreagteChildrenToParent function that will apply the aggregation to each node, this function recieves a node and its children already aggregated
 * @param predicateToAggregate optional predicate function that should return a if aggregation should be applied to the current node and children
 */
export const aggregateTree = <T extends IRow>(
  tree: T,
  aggreagteChildrenToParent: AggreagteChildrenToParent<T>,
  predicateToAggregate: PredicateToAggregate<T> = defaultPredicateToAggregate
): T => {
  const treeHasChildren = !!tree?.children?.length;
  if (treeHasChildren && predicateToAggregate(tree)) {
    const childrenNodes = tree.children!;
    const aggregatedChildren = childrenNodes.map((child) =>
      aggregateTree(child as T, aggreagteChildrenToParent)
    );
    const aggregatedRoot = aggreagteChildrenToParent(tree, aggregatedChildren);
    return {
      ...tree,
      ...aggregatedRoot,
      children: aggregatedChildren,
    };
  } else {
    return { ...tree };
  }
};
