import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import clx from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast, ToastOptions } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import * as yup from 'yup';

import { UsersAddManuallyTableItem } from 'components/settings/ManageUsers/UsersAddManually/UsersAddManuallyTableItem';
import {
  initialState,
  configTable,
} from 'components/settings/ManageUsers/UsersAddManually/helpers';
import * as styles from 'components/settings/ManageUsers/UsersAddManually/styles';
import { getCompanyManagerList } from 'selectors/companyUsers';
import { getUser } from 'selectors/user';
import { QueryStatus, fetchApiWithoutCb } from 'utils/network';

const toastOptions: ToastOptions = { position: 'bottom-left' };
const url = `${process.env.REACT_APP_BACKEND_URL}/api/user`;

const schema = yup.string().required().email();

const isValidEmailAddress = (address: string, domain: string) =>
  schema.isValidSync(address) && address.includes(`@${domain}`);

const UsersAddManuallyTable = () => {
  const [status, setStatus] = useState<QueryStatus>('notAsked');
  const [users, setUsers] = useState([initialState]);

  const user = useSelector(getUser);
  const companyManagers = useSelector(getCompanyManagerList);

  const domain = user.email.split('@')[1];

  const managerOptions = companyManagers
    .filter((i) => i.status === 'active')
    .map((m) => ({
      label: m.name || m.email,
      value: m.id,
    }));

  const isDisabledSave = users.some(
    ({ name, email, role, status, isValid }) =>
      !name || !email || !role || !status || !isValid
  );

  const isDisabledAdd = status === 'loading' || isDisabledSave;

  let abortController: AbortController | null = null;

  useEffect(
    () => () => {
      // Abort the request (if it exists) when unmounting the component
      if (abortController) {
        abortController.abort();
      }
    },
    []
  );

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    field: string
  ) => {
    const value = e.target.value;
    const newArrUsers = users.map((el) =>
      el.id === id
        ? {
            ...el,
            [field]: value,
            isValid:
              field === 'email'
                ? isValidEmailAddress(value, domain)
                : el.isValid,
          }
        : el
    );

    setUsers(newArrUsers);
  };

  const onSelect = (id: number, value: string, field: string) => {
    const newArrUsers = users.map((user) =>
      user.id === id ? { ...user, [field]: value } : user
    );

    setUsers(newArrUsers);
  };

  const addNewUser = () => {
    const newField = { ...initialState, id: users.length + 1 };

    setUsers([...users, newField]);
  };

  const handleSave = async () => {
    if (users.length && status !== 'loading') {
      abortController = new AbortController();

      setStatus('loading');

      const results = await Promise.all(
        users.map(({ email, manager: manager_id, name, role }) =>
          fetchApiWithoutCb({
            queryMethod: 'post',
            queryParams: {
              name,
              email,
              role,
              manager_id,
            },
            signal: abortController?.signal,
            url,
          })
        )
      );

      setStatus('notAsked');

      const { error } = results.find(({ error }) => !!error) || {};

      if (error) {
        toast.error(`Save error: ${error}`, toastOptions);
      } else {
        setUsers([initialState]);
        toast.success('Added successfully', toastOptions);
      }
    }
  };

  const title = users.length > 1 ? `${users.length} USERS` : `1 USER`;

  return (
    <>
      <div className={styles.title}>{title}</div>
      <div className={styles.wrapperTable}>
        <div className={styles.table}>
          <div className={styles.table_header}>
            <div className={styles.table_header_row}>
              {configTable.map((col) => (
                <div
                  key={col.id}
                  className={clx(styles.table_header_col, {
                    require: col.require,
                  })}
                >
                  <p>{col.label}</p>
                </div>
              ))}
            </div>
          </div>
          <div>
            {users.map((item) => (
              <UsersAddManuallyTableItem
                key={item.id}
                user={item}
                domain={domain}
                onSelect={onSelect}
                onChange={onChange}
                isValidEmailAddress={isValidEmailAddress}
                managerOptions={managerOptions}
              />
            ))}
          </div>
        </div>
        <div className={styles.control}>
          <button
            type="button"
            className={styles.add_new}
            disabled={isDisabledAdd}
            onClick={addNewUser}
          >
            Add New
          </button>
          <button
            type="button"
            className={styles.btn_save}
            disabled={isDisabledSave}
            onClick={handleSave}
          >
            {status === 'loading' ? (
              <Loader size="tiny" active inline />
            ) : (
              'Save'
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default UsersAddManuallyTable;
