import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'actions';
import {
  getSubmissionForecasts,
  saveSubmissionForecast,
  getSubmissionBookedAndTarget,
} from 'actions/croOverrideActions';
import { clearHistory } from 'actions/forecastActions';
import SubmissionForecast from 'components/dashboard/CROOverride/SubmissionForecast/SubmissionForecast';
import { createSubmissionOverridePayload } from 'components/dashboard/CROOverride/SubmissionForecast/helper';
import { IProps } from 'components/dashboard/CROOverride/SubmissionForecast/types';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const SubmissionForecastWrapper: React.FC = () => {
  const dispatch = useDispatch();
  const bookedAndTargetEnabled = useSelector(
    selectors.getCompanyHasBookedAndTargetEnabledInOverride
  );

  const companyCurrency = useSelector(selectors.getUserLocalCurrency);

  const submissionForecastStatus = useSelector(
    selectors.submissionForecastStatus
  );
  const submissionBookedAndTargetStatus = useSelector(
    selectors.submissionBookedAndTargetStatus
  );
  const submissionBookedAndTarget = useSelector(
    selectors.submissionBookedAndTarget
  );

  useEffect(() => {
    dispatch(getSubmissionForecasts());
  }, []);

  useEffect(() => {
    if (bookedAndTargetEnabled) {
      dispatch(getSubmissionBookedAndTarget());
    }
  }, [bookedAndTargetEnabled]);

  const submissionForecasts = useSelector(selectors.submissionForecast);

  const allBusinessTypes = useSelector<IReduxState, string[]>((state) =>
    selectors.getBusinessTypes(state, true)
  );
  const activeBusinessType = useSelector<IReduxState, string>(
    (state) =>
      selectors.getForecastActiveBusinessType(state) || allBusinessTypes[0]
  );

  const setActiveBusinessType: IProps['onSelectBusinessType'] = (value) =>
    dispatch(actions.ui.manageForecast.setActiveBusinessType(value));

  const handleSave: IProps['onSave'] = (data) => {
    const payload = createSubmissionOverridePayload(
      submissionForecasts.columns.map((item) => item.submission_settings_id),
      data,
      false
    );

    dispatch(saveSubmissionForecast(payload));
  };

  const handleSubmitAll: IProps['onSubmit'] = (data) => {
    const payload = createSubmissionOverridePayload(
      submissionForecasts.columns.map((item) => item.submission_settings_id),
      data,
      true
    );

    dispatch(saveSubmissionForecast(payload));
    dispatch(clearHistory());
  };

  const handleSubmitSelected: IProps['onSubmitSelected'] = (
    data,
    submissionSettingsId
  ) => {
    const payload = createSubmissionOverridePayload(
      submissionForecasts.columns.map((item) => item.submission_settings_id),
      data,
      false
    );

    dispatch(
      saveSubmissionForecast({
        ...payload,
        submission_settings_id: submissionSettingsId,
      })
    );
    dispatch(clearHistory());
  };

  return (
    <SubmissionForecast
      submissionForecasts={submissionForecasts}
      allBusinessTypes={allBusinessTypes}
      activeBusinessType={activeBusinessType}
      companyCurrency={companyCurrency}
      submissionStatus={submissionForecastStatus}
      submissionBookedAndTargetStatus={submissionBookedAndTargetStatus}
      submissionBookedAndTarget={submissionBookedAndTarget}
      bookedAndTargetEnabled={bookedAndTargetEnabled}
      onSave={handleSave}
      onSubmit={handleSubmitAll}
      onSubmitSelected={handleSubmitSelected}
      onSelectBusinessType={setActiveBusinessType}
    />
  );
};

export default SubmissionForecastWrapper;
