import BuIcon from '../BuIcon';
import styled from '@emotion/styled';

export const Title = styled.h2`
  color: var(--bu-gray-700);
  font-family: var(--bu-font-regular);
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 5px;
  white-space: nowrap;
`;

export const Body = styled.p`
  align-items: center;
  color: var(--bu-gray-900);
  display: flex;
  font-family: var(--bu-font-medium);
  font-size: 24px;
  letter-spacing: 0;
  line-height: 20px;
`;

interface DeltaProps {
  isPositive?: boolean;
}

export const Delta = styled.span<DeltaProps>`
  font-family: var(--bu-font-regular);
  font-size: 12px;
  letter-spacing: 0;
  margin-left: 2px;
  padding: 0px 4px 0px 4px;
  border-radius: 2px;
  color: ${(props) =>
    props.isPositive ? 'var(--bu-green-500)' : 'var(--bu-red-500)'};
  background-color: ${(props) => (props.isPositive ? '#EAFAF0' : '#FFEBEB')};
`;

interface MetricItemProps {
  noBorder: boolean;
  isClickable: boolean;
  itemsPerRow: number;
}

export const MetricItem = styled.li<MetricItemProps>`
  box-sizing: border-box;
  border-right: 1px solid var(--bu-gray-500);
  border-bottom: ${(props) =>
    props.noBorder ? 'none' : '1px solid var(--bu-gray-500)'};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'auto')};
  min-height: 87px;
  padding: 16px 21px;

  &:nth-of-type(${(props) => `${props.itemsPerRow}n`}) {
    border-right: none;
  }
`;

export const MetricList = styled.ul<{ itemsPerRow: number }>`
  border-radius: 5px;
  border: 1px solid var(--bu-gray-500);
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.itemsPerRow}, 1fr)`};
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const MetricPanelSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 15px;
  margin-bottom: 25px;
`;

export const CollapseButton = styled.button`
  border: 1px solid #ddd;
  border-radius: 15px;
  background: #fff;
  font-size: 12px;
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 2px 0px;
  color: var(--bu-gray-700);
  align-self: center;
  cursor: pointer;
`;

interface CollapseDividerProps {
  hideDivider: boolean;
}

export const CollapseDivider = styled.div<CollapseDividerProps>`
  all: unset;
  align-self: ${(props) => (props.hideDivider ? 'center' : 'stretch')};
  background-color: #ddd;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  height: 2px;
  justify-content: center;
  margin-left: 6px;
  margin-right: 6px;
  &:hover {
    background-color: var(--bu-primary-500);
  }
  &:hover > button {
    border-color: var(--bu-primary-500);
  }
`;

// TODO we shold find a way to avoid using a harcoded max-height for the animation
export const CollapseAnimationContainer = styled.div`
  transition: max-height 0.1s;
  max-height: 2000px;
  &.closed {
    max-height: 0px;
    overflow: hidden;
  }
`;

export const CollapseIcon = styled(BuIcon)`
  width: 10px;
  height: 10px;
  margin-right: 5px;
  color: var(--bu-primary-500);
`;
