import { ACTIONS_COLUMN_DEFAULT_WIDTH } from 'components/UI/TableConfig/column-helper';
import {
  IColumn,
  SortOrder,
  IRow,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import { DropDownCellConfig } from 'components/UI/common/TypedTable/renderers/DropDownCell';
import { editRemoveButtons } from 'components/UI/common/TypedTable/renderers/custom';
import { IBusinessTypes } from 'components/settings/DefineBusinessType/types';
import { openModal } from 'navigation/utils';

type GetColumnsFunction = (params: {
  availableValues: {
    [key: string]: string[];
  };
  rows: IRow[];
  editRemoveDisabled: boolean;
  handleRemove(row: IRow): void;
}) => { [id: string]: IColumn };

export const getOptions = (
  options: string[]
): Array<{
  key: string;
  text: string;
  value: string;
}> =>
  options?.map((v: string) => ({
    key: v,
    text: v,
    value: v,
  })) || [];

const handleEditClick = (row: IRow) => {
  openModal({
    scheme: '/business-type/edit/:businessType',
    params: {
      businessType: row.id,
    },
  });
};

export const getColumns: GetColumnsFunction = ({
  availableValues = {},
  rows,
  editRemoveDisabled,
  handleRemove = () => {},
}) => ({
  action: {
    id: 'actions',
    label: 'Actions',
    field: 'Actions',
    type: ColumnTypes.CUSTOM,
    sort_order: SortOrder.ASCENDING,
    width: ACTIONS_COLUMN_DEFAULT_WIDTH,
    minWidth: ACTIONS_COLUMN_DEFAULT_WIDTH,
    maxWidth: 160,
    config: {
      rows,
      renderer: editRemoveButtons({
        onEdit: handleEditClick,
        onRemove: handleRemove,
        disabled: editRemoveDisabled,
      }),
      className: 'actionColumn',
    } as CustomCellConfig,
  },
  name: {
    id: 'name',
    label: 'Name',
    field: 'name',
    type: ColumnTypes.TEXT,
    sortable: false,
    sort_order: SortOrder.ASCENDING,
    config: {},
  },
  values: {
    id: 'values',
    label: 'Value',
    field: 'values',
    sortable: false,
    type: ColumnTypes.MULTI_DROPDOWN,
    sort_order: SortOrder.ASCENDING,
    config: {
      options: getOptions(availableValues.values),
    } as DropDownCellConfig,
  },
  amount_field: {
    id: 'amount_field',
    label: 'Amount Field',
    field: 'amount_field',
    type: ColumnTypes.DROPDOWN,
    sortable: false,
    sort_order: SortOrder.ASCENDING,
    config: {
      options: getOptions(availableValues.amount_field),
    } as DropDownCellConfig,
  },
  close_date_field: {
    id: 'close_date_field',
    label: 'close date field',
    field: 'close_date_field',
    sortable: false,
    type: ColumnTypes.DROPDOWN,
    sort_order: SortOrder.ASCENDING,
    config: {
      options: getOptions(availableValues.close_date_field),
    } as DropDownCellConfig,
  },
});

export const getRows = (data: IBusinessTypes = {}) =>
  Object.keys(data)
    .map((t: string) => ({
      id: t,
      name: t,
      values: data[t].values,
      amount_field: data[t].amount_field,
      close_date_field: data[t].close_date_field,
      order: data[t].order,
    }))
    .sort((a, b) => a.order - b.order);
