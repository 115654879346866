import { IMPORT_TARGETS_NOT_ALLOWED_ROLES } from './types';
import classNames from 'classnames';
import * as R from 'ramda';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import {
  getTargetsByManagerRequest,
  getTargetsByUserRequest,
  getTargetsRequest,
  setTargetHiddenColumns,
  setTargetRequest,
} from 'actions/targetsActions';
import { tabsWithAnalytics } from 'common/constants';
import BuGroupButton from 'components/UI/BuGroupButton';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import Table from 'components/UI/TableConfig/Table';
import {
  IColumn,
  IRow,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { getColumns, getRows } from 'components/dashboard/SellerTargets/helper';
import * as styles from 'components/dashboard/SellerTargets/styles';
import Tabs from 'components/dashboard/Tabs';
import { useCheckFrameWindow } from 'components/hooks/useCheckFrameWindow';
import { useTableOffset } from 'components/hooks/useTableOffset';
import { openModal } from 'navigation/utils';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import { getFiltersForAPI, isUserRole } from 'selectors';
import {
  getSellerTargetsByManager,
  getSellerTargetsByUser,
  getSellerTargetsHiddenColumns,
  getSellerTargetsOverall,
  isTargetChanged,
  isTargetsLoading,
} from 'selectors/sellerTargets';
import { useDownloadFile } from 'utils/network';

const SellerTargets: React.FC = () => {
  const { pathname } = useLocation();
  const isPipeline = pathname.includes('pipeline');
  const [selectedMetric, setSelectedMetric] = useState<string>('');
  const [containerRef, offset] = useTableOffset();
  const [selectedInterval, setSelectedInterval] = useState<string>('');
  const tab = !isPipeline
    ? 'targets_forecast'
    : selectedMetric === 'pipeline_coverage'
    ? 'targets_pipeline_coverage'
    : 'targets_pipeline_creation';
  const [
    hiddenColumns,
    businessTypes,
    userRole,
    targets,
    targetsByUser,
    targetsByManager,
    targetChanged,
    targetsLoading,
    currentFiscalQuarter,
    currentFiscalYear,
    companyCurrency,
    fiscalYearStartMonth,
    filters,
  ] = useSelector(
    (state: IReduxState) => [
      getSellerTargetsHiddenColumns(state),
      selectors.getBusinessTypes(state, deals_overall_view_enabled),
      selectors.getUserRole(state),
      getSellerTargetsOverall(state),
      getSellerTargetsByUser(state),
      getSellerTargetsByManager(state),
      isTargetChanged(state),
      isTargetsLoading(state),
      selectors.getCurrentFiscalQuarter(state),
      selectors.getCurrentFiscalYear(state),
      selectors.getCompanyCurrency(state),
      selectors.getFiscalYearStartDate(state).month,
      getFiltersForAPI(state, tab),
    ],
    shallowEqual
  );
  const [selectedBusinessType, setSelectedBusinessType] = useState<string>('');
  const [targetYear, setTargetYear] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const isCheckFrame = useCheckFrameWindow();

  const { deals_overall_view_enabled } = useSelector((state: IReduxState) =>
    selectors.getFeatureFlags(state)
  );
  const canImportTargets = useSelector(
    (state: IReduxState) => !isUserRole(state, IMPORT_TARGETS_NOT_ALLOWED_ROLES)
  );

  const dispatch = useDispatch();

  const isQuarterly = selectedInterval === 'quarterly';

  useEffect(() => {
    setSelectedInterval(
      filters.target_period ? filters.target_period[0] : 'quarterly'
    );
    setTargetYear(
      filters.fiscal_year
        ? filters.fiscal_year[0] === 'LYE'
          ? currentFiscalYear - 1
          : filters.fiscal_year[0] === 'NYE'
          ? currentFiscalYear + 1
          : currentFiscalYear
        : currentFiscalYear
    );
  }, [filters]);

  useEffect(() => {
    AnalyticsTracker.event(
      { userRole },
      {
        action: 'Open',
        category: isPipeline ? 'Pipeline' : 'Forecast',
        label: 'Targets page',
      }
    );

    if (isPipeline) {
      setSelectedMetric(
        pathname.includes('pipeline-creation')
          ? 'new_pipeline_created'
          : 'pipeline_coverage'
      );
    }

    return function cleanUp() {
      dispatch(setTargetHiddenColumns([]));
    };
  }, []);

  useLayoutEffect(() => {
    if (!R.isNil(currentFiscalYear)) {
      setTargetYear(String(currentFiscalYear));
    }
  }, [currentFiscalYear]);

  useEffect(() => {
    if (targetChanged || (targetYear && (!isPipeline || selectedMetric))) {
      dispatch(getTargetsRequest(selectedInterval, targetYear, selectedMetric));
      dispatch(
        getTargetsByUserRequest(selectedInterval, targetYear, selectedMetric)
      );
      dispatch(
        getTargetsByManagerRequest(selectedInterval, targetYear, selectedMetric)
      );
    }
  }, [selectedInterval, targetChanged, targetYear, selectedMetric]);

  useEffect(() => {
    if (!targetsLoading) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [targetsLoading]);

  const { isPending, error, triggerDownload } = useDownloadFile({
    queryMethod: 'post',
    queryParams: {
      business_type: selectedBusinessType,
      fiscal_year: targetYear,
      period: selectedInterval,
      attribute: isPipeline ? selectedMetric : undefined,
    },
    url: `${process.env.REACT_APP_BACKEND_URL}/api/data/target/download_template`,
  });

  const handleDownloadTemplate = useCallback(() => {
    triggerDownload();
  }, [
    targetYear,
    selectedInterval,
    selectedBusinessType,
    isPipeline,
    selectedMetric,
  ]);

  useEffect(() => {
    if (error === 'forbidden') {
      toast.error('User is not allowed to access resource');
    }
  }, [error]);

  const handleExportTargets = useCallback(() => {
    openModal({
      // We need this hack because our modal system doesn't support optional parameters
      scheme: !!selectedBusinessType
        ? '/import-targets/:interval/:year/:metric/:businessType'
        : '/import-targets/:interval/:year/:metric',
      params: {
        metric: selectedMetric.length > 0 ? selectedMetric : 'targets_forecast',
        interval: selectedInterval,
        year: targetYear,
        businessType: encodeURIComponent(selectedBusinessType),
      },
    });
  }, [targetYear, selectedInterval, selectedBusinessType, selectedMetric]);

  const handleBusinessType = (value: string): void => {
    if (tabsWithAnalytics.includes(tab)) {
      AnalyticsTracker.event(
        { tab },
        {
          category: 'Business Types Panel',
          action: 'Change Business Type',
          label: value,
        }
      );
    }

    setSelectedBusinessType(value);
    dispatch(
      setTargetHiddenColumns(
        value === 'Overall'
          ? []
          : businessTypes.filter((type: string) => type !== value)
      )
    );
  };

  const onTableChange = (column: IColumn, row: IRow, newValue: ValueProp) => {
    AnalyticsTracker.event(
      { tab },
      {
        category: 'Seller Targets Dashboard',
        action: 'Customers change',
        label: Number(newValue),
      }
    );

    const body: any = {
      fiscal_year: row.fiscal_year,
      target: Number(newValue),
      quarter: column.quarter,
      month: column.month,
    };

    if (!R.isEmpty(selectedMetric)) {
      body.attribute = selectedMetric;
    }

    if (row.email) {
      body.user = row.email;
    }

    if (column.business_type) {
      body.business_type_name = column.business_type;
    }

    setIsLoading(true);
    dispatch(
      setTargetRequest({
        interval: selectedInterval,
        targetsBy: row.targetType ? `by_${row.targetType}` : 'overall',
        body,
      })
    );
  };
  const enabledColumns = businessTypes.filter(
    (type: string) =>
      type !== 'Overall' && hiddenColumns && !hiddenColumns.includes(type)
  );

  const { columns, extraHeader } = useMemo(
    () =>
      getColumns(
        isQuarterly,
        fiscalYearStartMonth,
        targets,
        targetYear,
        enabledColumns,
        currentFiscalYear,
        currentFiscalQuarter,
        companyCurrency,
        selectedMetric === 'pipeline_coverage'
      ),
    [
      JSON.stringify(targets),
      JSON.stringify(enabledColumns),
      fiscalYearStartMonth,
      isQuarterly,
      targetYear,
      currentFiscalYear,
      currentFiscalQuarter,
    ]
  );

  const rows = useMemo(
    () =>
      getRows(
        isQuarterly,
        fiscalYearStartMonth,
        targets,
        targetsByManager,
        targetsByUser,
        targetYear
      ),
    [
      JSON.stringify(targets),
      JSON.stringify(targetsByManager),
      JSON.stringify(targetsByUser),
      targetYear,
      isQuarterly,
      fiscalYearStartMonth,
    ]
  );

  const isTargetsImportEnabled = useMemo(
    () =>
      selectedBusinessType !== 'Overall' &&
      (selectedBusinessType !== '' || !businessTypes.length) &&
      canImportTargets,
    [selectedBusinessType, businessTypes, canImportTargets]
  );

  if (
    R.isNil(targetYear) ||
    R.isNil(currentFiscalYear) ||
    R.isNil(currentFiscalQuarter)
  ) {
    return <Loader active />;
  }

  const buildOptionGroupData = (options: string[]) => {
    return options.map((option) => {
      return {
        id: option,
        text: option,
      };
    });
  };

  const heightTable = `calc(100vh - ${isCheckFrame ? offset + 50 : offset}px)`;
  return (
    <main className={styles.wrapper}>
      <section className="container" ref={containerRef}>
        <header className="container-dashboard">
          <Tabs partition="targets" wrapped />
          <OpenFiltersPanel tab={tab} />
          <section className={styles.header}>
            <div
              className={styles.targets_bt_container(isTargetsImportEnabled)}
            >
              {!!businessTypes.length && (
                <BuGroupButton
                  options={buildOptionGroupData(businessTypes)}
                  selectedOption={selectedBusinessType}
                  onSelect={(value: string) => {
                    handleBusinessType(value);
                  }}
                />
              )}
            </div>
            {isTargetsImportEnabled && (
              <div className={styles.headerButtonGroup}>
                <button
                  className={styles.panel_button(isPending)}
                  onClick={handleDownloadTemplate}
                >
                  {isPending && (
                    <Loader
                      size="tiny"
                      style={{ marginRight: '5px' }}
                      active
                      inline
                    />
                  )}
                  {isPending ? 'Downloading template' : 'Download template'}
                </button>
                <button
                  onClick={handleExportTargets}
                  className={styles.button}
                  type="button"
                >
                  Import Targets
                </button>
              </div>
            )}
          </section>
        </header>
      </section>
      <section
        className={styles.tableContainer}
        style={{
          height: heightTable,
        }}
      >
        <Table
          title=""
          columns={columns}
          extraHeader={extraHeader.length ? extraHeader : undefined}
          rowsPerPage={0}
          currentPage={0}
          data={rows}
          hideSearch
          hidePaginationEnd
          hidePaginationStart
          onSort={() => {}}
          loading={isLoading}
          fullscreen
          onRender={() => {}}
          onChange={onTableChange}
          innerScrollRef={undefined}
          columnsDnD
          rowClassName={(row) =>
            classNames({
              topRow: row.isTopRow,
              empty: row.isEmpty,
              noEdit: row.disableEdit,
            })
          }
        />
      </section>
    </main>
  );
};

export default SellerTargets;
