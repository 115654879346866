// @ts-nocheck
import React, { PureComponent } from 'react';
import provideScrollPosition from 'react-provide-scroll-position';
import classNames from 'classnames';
import moment from 'moment';
import $ from 'jquery';
import { connect } from 'react-redux';
import * as _ from 'ramda';
import PropTypes from 'prop-types';
import { highlightedMarkupList, notNullAndEmpty } from '../../common/utils';
import * as selectors from '../../selectors';
import * as service from '../UI/AudioPlayer/service';
import {
  createTranscriptAction,
  speakerType,
} from '../../actions/transcriptActions';
import store from '../../store/configureStore';

export const getTextMarkup = (snippet, searchTerms) => {
  let markup = { __html: snippet.transcript };

  if (notNullAndEmpty(searchTerms)) {
    markup = highlightedMarkupList(snippet.transcript, searchTerms);
  }

  return markup;
};

class TranscriptDetails extends PureComponent {
  static scrollToTop() {
    const container = $('body');
    if (container.offset()) {
      container.finish().animate({
        scrollTop: container.offset().top - container.scrollTop(),
      });
    }
  }

  constructor(props) {
    super(props);
    // this.state = {
    //   isAutoScrollActive: true
    // };
    this.timer = null;
  }

  componentDidMount() {
    // const { isAutoScroll } = this.props;
    this.scrollToSelection();

    // if user scrolled doc mannualy - disable autoscrolling

    // const scrollArea = document.querySelector('body');
    // fromEvent(scrollArea, 'mousewheel').subscribe(() =>
    //   this.setState({ isAutoScrollActive: false })
    // );
  }

  componentDidUpdate({ selectedSnippet: prevSelectedSnippet }) {
    const { selectedSnippet } = this.props;
    if (
      prevSelectedSnippet &&
      selectedSnippet &&
      prevSelectedSnippet.index !== selectedSnippet.index
    ) {
      this.scrollToSelection();
    }
  }

  selectSnippet = (selectedSnippet, evt) => {
    let newTime;
    if (evt) {
      const yPos = evt.pageY - $(evt.target).offset().top;
      // const xPos = evt.pageX - $(evt.target).offset().left
      // const width = evt.target.offsetWidth
      const height = evt.target.offsetHeight;
      let per = ((yPos * 100) / height + 10) / 100;
      if (selectedSnippet) {
        const { start_time, duration, transcript: text } = selectedSnippet;
        const newLength = Math.floor(per * text.length);
        const newText = text.substring(0, newLength);
        const newerText = newText.substring(0, newText.lastIndexOf('. '));
        per = newerText.length / text.length;
        newTime = Number(start_time) + per * Number(duration);

        const audio = document.getElementById('music');
        audio.currentTime = newTime;
        audio.play();
        store.dispatch(createTranscriptAction.playAudio());
      }
    }
  };

  scrollToSelection() {
    // const { isAutoScrollActive } = this.state;
    const { isAutoScroll } = this.props;
    // if (!isAutoScrollActive) return;
    if (!isAutoScroll) return;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      const scrollTo = document.querySelector('.cue.active.current');
      const container = document.querySelector('#table_transcription');

      if (scrollTo && container) {
        container.scrollTo({
          top: scrollTo.offsetTop - 630,
          behavior: 'smooth',
        });
      }
    }, 100);
  }

  render() {
    const { callDetail } = this.props;
    if (!callDetail) return <span />;
    const {
      callDetail: { start_date: transcriptStartDate, segments: snippets },
      filteredSnippets,
      selectedSnippet,
      currentTime,
      isPlaying,
      searchTerms,
      activeTopic,
      ...otherProps
    } = this.props;
    const filteredIds = filteredSnippets.map((s) => s._id);
    return (
      <div className="transcript-wrapper">
        {snippets
          .filter((s) => notNullAndEmpty(s.transcript))
          .map((s, i) => (
            <SnippetCue
              key={i}
              snippet={s}
              {...otherProps}
              selectedSnippet={selectedSnippet}
              callDetail={callDetail}
              transcriptStartDate={transcriptStartDate}
              selectSnippet={this.selectSnippet}
              searchTerms={searchTerms}
              filteredIds={filteredIds}
              currentTime={currentTime}
              isPlaying={isPlaying}
              activeTopic={activeTopic}
            />
          ))}
      </div>
    );
  }
}

const SnippetCue = ({
  snippet,
  selectedSnippet,
  searchTerms,
  selectSnippet,
  filteredIds,
  isPlaying,
  currentTime,
  activeTopic,
}) => {
  const { speaker_type, start_time, _id } = snippet;
  // If topic is not playing (active search) then use the search terms
  // Otherwise use the matching keywords of the topic.
  const topicKeywords = activeTopic
    ? _.compose(
        _.propOr([], activeTopic),
        _.propOr({}, 'topic_match_details')
      )(snippet)
    : null;
  const newSearchTerms = !_.isEmpty(searchTerms) ? searchTerms : topicKeywords;

  const cueClass = classNames({
    cue: true,
    customer: speaker_type === speakerType.CUSTOMER,
    company: speaker_type === speakerType.COMPANY,
    active:
      _.contains(_id, filteredIds) ||
      (selectedSnippet.snippet !== null && selectedSnippet.snippet._id === _id),
    current:
      selectedSnippet.snippet !== null && selectedSnippet.snippet._id === _id,
  });
  // const time = moment(transcriptStartDate).add(start_time, 'seconds');
  const time = moment().startOf('day').seconds(start_time);
  const onSelect = (evt) => selectSnippet(snippet, evt);
  const textMarkup = getTextMarkup(snippet, newSearchTerms);
  const playingProgress =
    ((currentTime - snippet.start_time) * 282.6) / snippet.duration;

  return (
    <div className={cueClass} id={_id}>
      <div
        role="presentation"
        className="player-circle-box"
        style={{ cursor: 'auto' }}
        onClick={isPlaying ? service.pause : service.play}
      >
        <div className="player-circle">
          <svg className="circles" viewBox="0 0 110 110">
            <circle
              r="45"
              cx="50%"
              cy="50%"
              fill="none"
              className="circle__first"
            />
            <circle
              r="45"
              cx="50%"
              cy="50%"
              transform="rotate(-90 55 55)"
              className="circle__second"
              style={{ strokeDasharray: `${playingProgress} 282.6` }}
            />
          </svg>
          {!isPlaying ? (
            <span className="circles--play" />
          ) : (
            <span className="circles--pause" />
          )}
        </div>
        {/* <div className="circles__icon_play">
          <span className="circles--play" />
        </div> */}
      </div>

      <div className="speakerInfo">
        <span className="speakerName">{snippet.speaker_type}</span>
        <span className="timestamp">{time.format('HH:mm:ss')}</span>
      </div>
      <span className="cue-text bubble">
        <span
          role="presentation"
          onClick={onSelect}
          dangerouslySetInnerHTML={textMarkup}
        />
      </span>
    </div>
  );
};

TranscriptDetails.propTypes = {
  callDetail: PropTypes.shape({
    attendees: PropTypes.array,
    audio_url: PropTypes.string,
    competitors_discussed: PropTypes.array,
    description: PropTypes.string,
    duration: PropTypes.number,
    id: PropTypes.string,
    my_tags: PropTypes.array,
    opportunity_name: PropTypes.string,
    other_tags: PropTypes.array,
    segments: PropTypes.array,
    start: PropTypes.string,
    title: PropTypes.string,
    topics_discussed: PropTypes.array,
  }),
  isPlaying: PropTypes.bool,
  selectSnippet: PropTypes.func,
};

TranscriptDetails.defaultProps = {
  callDetail: PropTypes.shape({
    attendees: PropTypes.array,
    audio_url: PropTypes.string,
    competitors_discussed: PropTypes.array,
    description: PropTypes.string,
    duration: PropTypes.number,
    id: PropTypes.string,
    my_tags: PropTypes.array,
    opportunity_name: PropTypes.string,
    other_tags: PropTypes.array,
    segments: PropTypes.array,
    start: PropTypes.string,
    title: PropTypes.string,
    topics_discussed: PropTypes.array,
  }),
  isPlaying: PropTypes.bool,
  selectSnippet: PropTypes.func,
};

const mapStateToProps = (state) => ({
  callDetail: selectors.getCallDetail(state),
  filteredSnippets: selectors.getFilteredSnippets(state),
  selectedSnippet: selectors.getSelectedSnippet(state),
  searchTerms: selectors.getTranscriptSearchTerms(state),
  currentTime: selectors.getCurrentTimeOffset(state),
  isPlaying: selectors.getPlayingState(state),
  activeTopic: selectors.getActiveTopic(state),
});

export default connect(mapStateToProps)(
  provideScrollPosition(TranscriptDetails)
);
