import classnames from 'classnames';
import React from 'react';

import TruncatedText from 'components/UI/TruncatedText';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import {
  tabs_item,
  tabs_item_wpar,
  tab_icon,
  tab_content_wrapper,
} from 'components/dashboard/Tabs/styles';
import { Link } from 'navigation/Link';
import { Scheme } from 'navigation/routes';
import { URLScheme } from 'navigation/types';

interface ITabTitleProps {
  isActive: boolean;
  scheme: Scheme;
  params?: URLScheme<Scheme>['params'];
  title: string;
  titleLength?: number;
  icon?: string;
  tooltipMessage?: React.ReactNode;
}

const TabTitle: React.FC<ITabTitleProps> = ({
  title,
  scheme,
  params,
  isActive,
  titleLength,
  icon,
  tooltipMessage,
}) => (
  <Link url={{ scheme, params }}>
    <div className={tabs_item_wpar}>
      <button
        className={classnames([tabs_item], {
          active: isActive,
        })}
      >
        <div className={tab_content_wrapper}>
          <TruncatedText inverted length={titleLength} text={title} />

          {icon && (
            <TooltipWrapper tooltip={tooltipMessage} position="bottom center">
              <img src={icon} className={tab_icon} />
            </TooltipWrapper>
          )}
        </div>
      </button>

      <p>{title}</p>
    </div>
  </Link>
);

export default TabTitle;
