import Highcharts, { PointClickEventObject } from 'highcharts';
import _ from 'lodash';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { BIChartTypes } from 'common/enums/metrics';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { WidgetChart } from 'components/dashboard/Metrics/Widget/Chart/WidgetChart';
import { HistoricalWidgetControls } from 'components/dashboard/Metrics/Widget/Controls/HistoricalWidgetControls';
import { WidgetHeader } from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { DataFetchErrorMessage } from 'components/dashboard/Metrics/Widget/Messages/DataFetchErrorMessage';
import { NoDataMessage } from 'components/dashboard/Metrics/Widget/Messages/NoDataMessage';
import { WidgetTable } from 'components/dashboard/Metrics/Widget/Table/WidgetTable';
import { TotalsBlock } from 'components/dashboard/Metrics/Widget/TotalsBlock/TotalsBlock';
import {
  getMetricNamesWithValues,
  getSyntheticMetricData,
  openSyntheticMetricDetailsModal,
  parseFormulaToMetricsIdArray,
} from 'components/dashboard/Metrics/Widget/helper';
import {
  fetchWidgetData,
  openDrillDownModal,
} from 'components/dashboard/Metrics/Widget/historical.widget.helpers';
import { useMetricsList } from 'components/dashboard/Metrics/Widget/hooks/useMetricsList';
import * as s from 'components/dashboard/Metrics/Widget/metrics.widget.styles';
import {
  LoaderContainer,
  WidgetContainer,
} from 'components/dashboard/Metrics/Widget/widgets.styles';
import { BUSINESS_TYPES_FIELD_NAMES } from 'components/dashboard/Metrics/constants';
import {
  BIDashboardSettings,
  BIMetricColumn,
  BIMetricToChartType,
  BIMetrics,
  BIWidget,
  BIWidgetDataV2,
  DrillDownParams,
  SelectedValue,
  SyntheticMetricDataWidgetState,
  TimeSeriesDrillDownParams,
} from 'components/dashboard/Metrics/metrics.types';
import { QueryStatus } from 'utils/network';

interface Props {
  widget: Partial<BIWidget>;
  dashboardId?: string;
  dashboardName?: string;
  isDashboardModal?: boolean;
  metricsFromList?: any[]; // TODO Fix type
  dashboardSettings?: BIDashboardSettings;
  setWidget: Dispatch<SetStateAction<Partial<BIWidget>>>;
  onEditWidget?: (metricId: string) => void;
  onCloneWidget?: (metricId: string) => void;
  onRemoveWidget?: (metricId: string) => void;
  onNameClicked?: (widget: Partial<BIWidget>) => void;
  closeWidgetModal?: () => void;
}

export const HistoricalWidgetDashboard: React.FC<Props> = ({
  widget,
  dashboardId = '',
  dashboardName = '',
  isDashboardModal = false,
  metricsFromList,
  dashboardSettings,
  setWidget,
  onEditWidget,
  onCloneWidget,
  onRemoveWidget,
  onNameClicked,
  closeWidgetModal,
}) => {
  const dispatch = useDispatch();

  const lastFetchedWidget = useRef<string>();

  const { data: metricsInUse } = useMetricsList(widget.metric_list);

  const [metricToChartType, setMetricToChartType] = useState<
    BIMetricToChartType[]
  >(
    widget?.properties?.metricToChartType ||
      (widget.metric_list as string[]).map((el) => ({
        chartType: widget.chart_type as BIChartTypes,
        metricId: el,
      }))
  );

  const [metricData, setMetricData] = useState<BIWidgetDataV2>();
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');

  const [pivot, setPivot] = useState<BIMetricColumn | undefined>(
    widget.group_by && widget?.group_by[0]
  );

  useEffect(() => {
    if (pivot?.name.toLocaleLowerCase() === 'none') {
      metricToChartType.forEach((el) => {
        el.chartType =
          el.chartType === BIChartTypes.ColumnStacked
            ? BIChartTypes.Column
            : el.chartType;
      });
      setMetricToChartType([...metricToChartType]);
    }
  }, [pivot?.name]);

  useEffect(() => {
    setWidget({
      ...widget,
      properties: {
        metricToChartType: metricToChartType,
      },
    });
  }, [metricToChartType]);

  useEffect(() => {
    const queryWidget = {
      ...widget,
      ...dashboardSettings,
      dashboard_id: dashboardId,
      dashboard_name: dashboardName,
    };
    const previewJson = JSON.stringify(queryWidget);

    const areHistoryFieldsValid = Boolean(
      widget.time_period && widget.time_interval && widget.point_in_time
    );

    let abortController: AbortController | null = null;

    if (areHistoryFieldsValid || previewJson !== lastFetchedWidget.current) {
      lastFetchedWidget.current = previewJson;
      abortController = new AbortController();

      const querySetting = dashboardSettings
        ? `?user_status=${dashboardSettings.userFilter}`
        : '';

      fetchWidgetData(
        previewJson,
        querySetting,
        (widget._id ?? widget.name) || '',
        abortController,
        setMetricData,
        setDataStatus,
        setPivot
      );
    }

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [
    widget.metric_list?.length,
    widget.time_field?.name,
    widget.time_field?.label,
    JSON.stringify(widget.template_filters),
    JSON.stringify(widget.group_by),
    widget.time_interval,
    widget.time_period,
    widget.point_in_time,
    JSON.stringify(dashboardSettings),
  ]);

  const handleTableDataClick = (column: IColumn, row: IRow): void => {
    const metricId = column.metricId;

    if (metricId in metricsInUse) {
      const clickedMetric: BIMetrics = metricsInUse[metricId];

      let pivotValue = '';
      const escapedPivotName = pivot?.name?.replaceAll('.', '$');

      if (escapedPivotName) {
        pivotValue = row[escapedPivotName] as string;
      }

      // If it is a subtotal row then we do not send second pivot
      const drillDownFilters = row.isSubtotal
        ? []
        : [
            ...(widget.group_by ?? []).map((column) => ({
              column:
                column.type === 'date' && widget.time_field
                  ? widget.time_field
                  : column,
              operator: 'eq',
              value: pivotValue,
            })),
          ];

      const drilldownParams: TimeSeriesDrillDownParams = {
        filters: clickedMetric?.filters ?? [],
        template_filters: widget.template_filters ?? [],
        drill_down_filters: drillDownFilters,
        time_interval: widget.time_interval,
        time_period: widget.time_period,
        point_in_time: widget.point_in_time,
        target_time_period: column.extraHeader ?? '',
        business_type_name: presentBusinessType,
      };

      if (clickedMetric.synthetic_metric) {
        const parsedClickedMetricFormulaAsArray: string[] =
          parseFormulaToMetricsIdArray(clickedMetric.synthetic_metric);

        const syntheticMetricData: SyntheticMetricDataWidgetState =
          getSyntheticMetricData(
            parsedClickedMetricFormulaAsArray,
            metricData,
            clickedMetric
          );

        const selectedValue: SelectedValue = {
          y: !Number.isNaN(row[column.field] as number)
            ? (row[column.field] as number)
            : 0,
          metricsValues: getMetricNamesWithValues(
            parsedClickedMetricFormulaAsArray,
            row,
            syntheticMetricData
          ),
        };

        const pivot1FieldName = pivot?.field_name;
        if (pivot1FieldName) {
          selectedValue.pivot1Id = row[pivot1FieldName] as string;
        }

        const syntheticDrilldownParams: DrillDownParams = {
          drill_down_filters: drillDownFilters,
          filters: clickedMetric?.filters ?? [],
          template_filters: widget.template_filters ?? [],
          offset: 0,
          order_by: [],
          limit: 1000000,
          duration:
            widget.group_by?.[0]?.type === 'date'
              ? widget.group_by[0].name
              : '',
          skip_business_validation: true,
        };

        openSyntheticMetricDetailsModal(
          widget.title || widget.name || 'Drill Down',
          syntheticDrilldownParams,
          syntheticMetricData,
          selectedValue,
          dispatch
        );
      } else {
        openDrillDownModal(
          drilldownParams,
          clickedMetric,
          pivotValue,
          widget.title || widget.name || 'Drill Down',
          dispatch
        );
      }
    }
  };

  const handleChartDataClick = (pointEvent?: PointClickEventObject): void => {
    const selectedValue = pointEvent?.point?.options as any;
    const metricId = selectedValue.metricId;

    if (metricId in metricsInUse) {
      const clickedMetric: BIMetrics = metricsInUse[metricId];

      const drillDownFilters =
        pointEvent && !!selectedValue?.pivot2Id
          ? [
              ...(widget.group_by ?? []).map((column) => ({
                column:
                  column.type === 'date' && widget.time_field
                    ? widget.time_field
                    : column,
                operator: 'eq',
                value: selectedValue?.pivot2Id,
              })),
            ]
          : [];

      if (clickedMetric.synthetic_metric) {
        const parsedClickedMetricFormulaAsArray: string[] =
          parseFormulaToMetricsIdArray(clickedMetric.synthetic_metric);

        const syntheticMetricData: SyntheticMetricDataWidgetState =
          getSyntheticMetricData(
            parsedClickedMetricFormulaAsArray,
            metricData,
            clickedMetric
          );

        const drilldownParams: DrillDownParams = {
          drill_down_filters: drillDownFilters,
          filters: clickedMetric?.filters ?? [],
          template_filters: widget.template_filters ?? [],
          order_by: [],
          offset: 0,
          limit: 1000000,
          duration:
            widget.group_by?.[0]?.type === 'date'
              ? widget.group_by[0].name
              : '',
          skip_business_validation: true,
        };

        let dataRow = {};
        if (availablePivots === 1) {
          const pivot1Id = selectedValue.pivot1Id;
          const pivot1Column = metricData?.pivot_1?.columns.find(
            (el) => el.is_pivot
          );
          const key = pivot1Column?.field_name?.replaceAll('.', '$') || '';
          dataRow =
            metricData?.pivot_1?.data.find((d) => d[key] === pivot1Id) || {};
        } else if (availablePivots === 2) {
          const pivot1Id = selectedValue.pivot1Id;
          const pivot1Column = metricData?.pivot_1?.columns.find(
            (el) => el.is_pivot
          );
          const pivot1Key =
            pivot1Column?.field_name?.replaceAll('.', '$') || '';
          const pivot2Id = selectedValue.pivot2Id;
          const pivot2Key = pivot?.name?.replaceAll('.', '$') || '';
          dataRow =
            metricData?.pivot_2?.data.find(
              (d) => d[pivot1Key] === pivot1Id && d[pivot2Key] === pivot2Id
            ) || {};
        }

        openSyntheticMetricDetailsModal(
          widget.title || widget.name || 'Drill Down',
          drilldownParams,
          syntheticMetricData,
          {
            ...selectedValue,
            metricsValues: getMetricNamesWithValues(
              parsedClickedMetricFormulaAsArray,
              dataRow,
              syntheticMetricData
            ),
          },
          dispatch
        );
      } else {
        const drilldownParams: TimeSeriesDrillDownParams = {
          filters: clickedMetric?.filters ?? [],
          template_filters: widget.template_filters ?? [],
          drill_down_filters: drillDownFilters,
          time_interval: widget.time_interval,
          time_period: widget.time_period,
          point_in_time: widget.point_in_time,
          target_time_period: selectedValue?.pivot1Id,
          business_type_name: presentBusinessType,
        };

        openDrillDownModal(
          drilldownParams,
          clickedMetric,
          selectedValue?.pivot2Id,
          widget.title || widget.name || 'Drill Down',
          dispatch
        );
      }
    }
  };

  const isMetricListIds: boolean = useMemo(
    () => widget?.metric_list && typeof widget?.metric_list[0] === 'string',
    [JSON.stringify(widget?.metric_list)]
  );

  const widgetFiltersBusinessType: string = useMemo(() => {
    const templateFilterBusinessType = widget.template_filters?.find((filter) =>
      BUSINESS_TYPES_FIELD_NAMES.has(filter.column.name)
    );

    return (templateFilterBusinessType?.value as string[])?.[0];
  }, [JSON.stringify(widget.template_filters)]);

  const metricsBusinessType: string = useMemo(() => {
    let businessType = '';
    (metricsFromList as BIMetrics[])?.forEach((m) => {
      m.filters?.forEach((f) => {
        const condition = f.and_condition?.[0].or_condition?.[0];
        if (BUSINESS_TYPES_FIELD_NAMES.has(condition?.column?.name)) {
          businessType = (condition.value as string[])?.[0];
        }
      });
    });

    return businessType;
  }, [JSON.stringify(metricsFromList)]);

  const presentBusinessType = widgetFiltersBusinessType || metricsBusinessType;

  const titleClicked = () => {
    onNameClicked && onNameClicked(widget);
  };

  const hasData = !!metricData?.pivot_1?.data.length;
  const availablePivots = metricData?.hasOwnProperty('pivot_2')
    ? 2
    : metricData?.hasOwnProperty('pivot_1')
    ? 1
    : 0;

  return (
    <>
      <WidgetContainer
        key={widget.id}
        isDashboard={true}
        isMetricsPreview={false}
        isModal={isDashboardModal}
      >
        <WidgetHeader
          id={widget.id}
          name={widget.name}
          dashboardName={dashboardName}
          isCreateEditMetric={false}
          isDashboardWidget={true}
          isDashboardModal={isDashboardModal}
          onEditWidget={onEditWidget}
          onCloneWidget={onCloneWidget}
          onRemoveWidget={onRemoveWidget}
          onTitleClicked={titleClicked}
          closeWidgetModal={closeWidgetModal}
        />

        <HistoricalWidgetControls
          showControls={isDashboardModal}
          pivot={pivot}
          widget={widget}
          metricToChartType={metricToChartType}
          setPivot={setPivot}
          setWidget={setWidget}
          setMetricToChartType={setMetricToChartType}
        />

        {dataStatus === 'loading' && (
          <LoaderContainer>
            <Loader active content="Loading" />
          </LoaderContainer>
        )}

        {dataStatus === 'success' && (
          <>
            {!!metricData?.pivot_0?.columns?.length && (
              <div
                className={s.totalsContainer}
                data-testing="totals-container"
              >
                <div className={s.totalsBarElement}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <TotalsBlock
                      showControls={isDashboardModal}
                      type={
                        metricsFromList &&
                        metricsFromList.length === 1 &&
                        !metricData?.hasOwnProperty('pivot_1')
                          ? 'singleValue'
                          : (metricsFromList &&
                              metricsFromList.length > 4 &&
                              !metricData?.hasOwnProperty('pivot_1')) ||
                            !metricData?.hasOwnProperty('pivot_1')
                          ? 'totalsOnly'
                          : 'totalsAndChart'
                      }
                      pivot2={pivot}
                      widget={widget}
                      metricData={metricData}
                      metricsInUse={metricsInUse}
                      isPivoted={true}
                      metricToChartType={metricToChartType}
                      onDataClick={() => {}}
                      onChartTypeChange={(metricId, chartType) => {
                        let result: {
                          chartType: BIChartTypes;
                          metricId: string;
                        }[] = [...metricToChartType];
                        if (
                          chartType === BIChartTypes.Table &&
                          (
                            (widget.properties
                              ?.metricToChartType as BIMetricToChartType[]) ||
                            []
                          ).every((el) => el.chartType !== BIChartTypes.Table)
                        ) {
                          result = [
                            ...result.map((el) => ({
                              ...el,
                              chartType: chartType,
                            })),
                          ];
                        }
                        if (
                          (
                            (widget.properties
                              ?.metricToChartType as BIMetricToChartType[]) ||
                            []
                          ).every(
                            (el) => el.chartType === BIChartTypes.Table
                          ) &&
                          chartType !== BIChartTypes.Table
                        ) {
                          result = [
                            ...result.map((el) => ({
                              ...el,
                              chartType: chartType,
                            })),
                          ];
                        }

                        const type = result.find(
                          (el) => el.metricId === metricId
                        );

                        if (!!type) {
                          type.chartType = chartType;
                          result = [...result];
                        } else {
                          result = [...result, { metricId, chartType }];
                        }

                        setMetricToChartType(result);
                        setWidget({
                          ...widget,
                          properties: {
                            metricToChartType: result,
                          },
                          chart_type: chartType,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {hasData && availablePivots > 0 && (
              <>
                {metricToChartType.some((el) => el.chartType === 'table') &&
                  widget.metric_list?.length > 0 &&
                  !!metricData && (
                    <WidgetTable
                      isDateFirstPivot
                      sortByColumn={
                        widget.properties?.table_view_order_by_column
                      }
                      widgetData={metricData}
                      metricsCount={widget.metric_list?.length || 0}
                      availablePivots={availablePivots}
                      onTableDataClick={handleTableDataClick}
                      metricsInUse={metricsInUse}
                    />
                  )}

                {metricToChartType.every((el) => el.chartType !== 'table') &&
                  !!metricData && (
                    <WidgetChart
                      metricToChartType={metricToChartType}
                      metricData={metricData}
                      metricsInUse={metricsInUse}
                      widget={widget}
                      onDataClick={handleChartDataClick}
                    />
                  )}
              </>
            )}

            {!hasData && availablePivots > 0 && (
              <NoDataMessage data-testing="no-data-message" />
            )}
          </>
        )}

        {dataStatus === 'error' && <DataFetchErrorMessage />}
      </WidgetContainer>
    </>
  );
};
