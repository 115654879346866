import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import InfoIcon from 'assets/fonts/boostup-icons/badge_info_outline.svg';
import { formatTo } from 'components/UI/TimeFilter/TabsForFilter/timeSpanOptions';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import {
  chartInfoIcon,
  chartChangeInterval,
  chartHeaderRow,
  chartTitle,
} from 'components/chart-dashboards/Widget/styles';
import { ChartTitleProps } from 'components/chart-dashboards/Widget/types';
import { formatAmount } from 'helpers/moneyTalks';
import * as selectors from 'selectors';

const timeValueToLabel: { [key: string]: string } = {
  ALT: 'All Time',
  FUT: 'Closing in the Future',
  LMO: 'Last Month',
  LQU: 'Last Quarter',
  N3M: 'Next 3 Months',
  N6M: 'Next 6 Months',
  NMO: 'Next Month',
  NQU: 'Next Quarter',
  TMO: 'Current Month',
  TQU: 'Current Quarter',
  TYE: 'Current Year',
  L7D: 'Last 7 Days',
  L14D: 'Last 14 Days',
  L30D: 'Last 30 Days',
  L60D: 'Last 60 Days',
  L90D: 'Last 90 Days',
  L3M: 'Last 3 Months',
  SQS: 'Quarter Start',
};

const getTimeLabel = (
  value: string,
  isQuarterStartReplacement?: boolean
): string => {
  if (timeValueToLabel[value]) {
    if (isQuarterStartReplacement && value == 'TQU') {
      return 'Quarter Start';
    }
    return timeValueToLabel[value];
  }

  const [from, to] = value.split(',');
  return `${formatTo(from, 'YYYY-MM-DD', 'MM/DD/YYYY')}`;
};

const RenderDeltaOverPeriod: React.FC<{ deltaOverPeriod: number }> = ({
  deltaOverPeriod,
}) => {
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);

  if (deltaOverPeriod === 0) {
    return <span>Unchanged </span>;
  }

  const items: any[] = capitalize(
    formatAmount(companyCurrencyCode, deltaOverPeriod, false)
  ).split(' ');

  items[0] = (
    <span
      className={classNames({
        green: deltaOverPeriod > 0,
        red: deltaOverPeriod < 0,
      })}
    >
      {items[0]}
    </span>
  );

  return (
    <>
      {items.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={i}>{item} </Fragment>
      ))}
    </>
  );
};

const ChartTitle: React.FC<ChartTitleProps> = ({
  changeInterval,
  chart_type,
  closeDateInterval,
  deltaOverPeriod,
  extendedTitle,
  segmentInterval,
  title,
}) => {
  const isWaterfallChart = chart_type === 'waterfall';

  const changeIntervalTitle =
    extendedTitle && isWaterfallChart
      ? `since ${getTimeLabel(changeInterval, true)}`
      : null;

  const closeDateIntervalTitle = isWaterfallChart
    ? closeDateInterval
    : segmentInterval;

  const text = extendedTitle
    ? `${title} ${isWaterfallChart ? 'closing in' : 'over'} ${getTimeLabel(
        closeDateIntervalTitle
      )}${title === 'How did your pipeline change' ? '?' : ''}`
    : title;

  return (
    <>
      <div className={chartHeaderRow}>
        <span className={chartTitle}>
          {text}
          {isWaterfallChart && (
            <div className={chartInfoIcon}>
              <TooltipWrapper
                tooltip={
                  <div style={{ width: '200px', textAlign: 'center' }}>
                    This chart displays a view of the evolution in historical
                    pipeline compared to current pipeline over a period of time.
                  </div>
                }
                position="top center"
              >
                <img src={InfoIcon} alt="info" />
              </TooltipWrapper>
            </div>
          )}
        </span>

        {changeIntervalTitle && typeof deltaOverPeriod !== 'undefined' ? (
          <span className={chartChangeInterval}>
            <RenderDeltaOverPeriod deltaOverPeriod={deltaOverPeriod} />
            {changeIntervalTitle}
          </span>
        ) : null}
      </div>
    </>
  );
};

export default ChartTitle;
