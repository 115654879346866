import React, { Dispatch, SetStateAction } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  changeActiveMetric,
  cleanUpMetricCreationState,
} from 'actions/revbi/metrics';
import BuButton from 'components/UI/BuButton';
import BuInput from 'components/UI/BuInput';
import { MetricDefinitionInputs } from 'components/dashboard/Metrics/Create/MetricDefinition/MetricDefinitionInputs/MetricDefinitionInputs';
import { inputsContainer } from 'components/dashboard/Metrics/Create/MetricDefinition/MetricDefinitionInputs/styles';
import {
  actionButtonsContainer,
  breadcrumbs,
  metricsCreateContainer,
} from 'components/dashboard/Metrics/Create/MetricDefinition/styles';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIMetricUnion,
  ComponentMode,
} from 'components/dashboard/Metrics/metrics.types';
import {
  getActiveMetric,
  getCreateMetricComponentMode,
  isActiveMetricReadyForSave,
} from 'selectors/revbi/metrics';

interface Props {
  setIsCreateNewMetric: Dispatch<SetStateAction<boolean>>;
  onSaveMetric: (metric: BIMetricUnion) => void;
}

export const MetricDefinition: React.FC<Props> = ({
  setIsCreateNewMetric,
  onSaveMetric,
}) => {
  const dispatch = useDispatch();

  const componentMode = useSelector(getCreateMetricComponentMode);
  const metric = useSelector(getActiveMetric);
  const canSave = useSelector(isActiveMetricReadyForSave);

  const updateMetric = (metric: BIMetricUnion): void => {
    dispatch(changeActiveMetric(metric));
  };

  const handleBackToMetrics = () => {
    dispatch(cleanUpMetricCreationState());
    setIsCreateNewMetric(false);
  };

  const handleChangeMetricName = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    updateMetric({ ...metric, name: e.target.value });
  };

  return (
    <>
      <div className={breadcrumbs}>
        <a onClick={handleBackToMetrics}>Metrics List</a> /{' '}
        <span>
          {componentMode === ComponentMode.EDIT
            ? 'Edit metric'
            : 'Create new metric'}
        </span>
      </div>
      <div className={metricsCreateContainer}>
        <div className={inputsContainer}>
          <MetricCreateSubTitle>Metric name</MetricCreateSubTitle>
          <BuInput
            placeholder="Enter a metric Name"
            value={metric.name}
            testingLabel="metric-name-input"
            type="text"
            onChange={handleChangeMetricName}
          />
        </div>
        <MetricDefinitionInputs />
        <div className={actionButtonsContainer}>
          <BuButton secondary onClick={() => setIsCreateNewMetric(false)}>
            Cancel
          </BuButton>
          <BuButton onClick={() => onSaveMetric(metric)} disabled={!canSave}>
            Save Metric
          </BuButton>
        </div>
      </div>
    </>
  );
};
