import { css } from 'emotion';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';

import BusinessTypesPanel from 'components/UI/BusinessTypesPanel';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import SankeyWidget from 'components/dashboard/ForecastPipelineStage';
import { IProps } from 'components/dashboard/Pipeline/PipelineDealsProgression/types';
import Tabs from 'components/dashboard/Tabs';

const stickyTop = css`
  position: sticky;
  top: 52px;
  background: var(--bu-white);
  z-index: 989;
`;

const PipelineDealsProgression: React.FC<IProps> = ({
  selectedBusinessType,
  filters,
  toggleAllFilters,
  tabPartition = 'pipeline',
  isRequiredDataReady,
}) => {
  const tab = 'pipeline_deals_progression';

  useEffect(() => {
    AnalyticsTracker.event(
      { tab },
      {
        category: 'Pipeline',
        action: 'Tab: Deals Progression',
        label: 'Deals Progression page',
      }
    );
  }, []);

  if (isEmpty(filters) || !isRequiredDataReady) {
    return <Loader active />;
  }

  return (
    <div className="wrapper" onClick={() => toggleAllFilters(true)}>
      <div className="container">
        <div className="container-dashboard">
          <Tabs partition={tabPartition} wrapped />
          <div className={stickyTop}>
            <OpenFiltersPanel tab={tab} />
          </div>

          <BusinessTypesPanel tab={tab} />

          <div style={{ marginTop: 20, marginBottom: 100 }}>
            <div style={{ marginTop: 20 }}>
              <SankeyWidget
                filters={filters}
                businessType={selectedBusinessType}
                localStorageKeyPrefix="ForecastTrends"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipelineDealsProgression;
