import { PointClickEventObject } from 'highcharts';
import _ from 'lodash';
import React, {
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import { BIChartTypes } from 'common/enums/metrics';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { getMetricType } from 'components/dashboard/Metrics/Create/MetricCreate/utils';
import { WidgetChart } from 'components/dashboard/Metrics/Widget/Chart/WidgetChart';
import { MetricsWidgetControls } from 'components/dashboard/Metrics/Widget/Controls/MetricsWidgetControls';
import { WidgetHeader } from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { DataFetchErrorMessage } from 'components/dashboard/Metrics/Widget/Messages/DataFetchErrorMessage';
import { NoDataMessage } from 'components/dashboard/Metrics/Widget/Messages/NoDataMessage';
import { WidgetTable } from 'components/dashboard/Metrics/Widget/Table/WidgetTable';
import { TotalsBlock } from 'components/dashboard/Metrics/Widget/TotalsBlock/TotalsBlock';
import { NOT_SAVED_METRIC } from 'components/dashboard/Metrics/Widget/constants';
import {
  getMetricNamesWithValues,
  getSyntheticMetricData,
  openSyntheticMetricDetailsModal,
  parseFormulaToMetricsIdArray,
} from 'components/dashboard/Metrics/Widget/helper';
import { useMetricsList } from 'components/dashboard/Metrics/Widget/hooks/useMetricsList';
import {
  createDrillDownParams,
  fetchRecordsAndOpenDrillDownModal,
  fetchWidgetData,
  getDrillDownFilters,
  openDrillDownModal,
} from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import * as s from 'components/dashboard/Metrics/Widget/metrics.widget.styles';
import {
  LoaderContainer,
  WidgetContainer,
} from 'components/dashboard/Metrics/Widget/widgets.styles';
import { getDropdownFriendlyName } from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIColumnListItem,
  BIMetricColumn,
  BIMetricFormula,
  BIMetricToChartType,
  BIMetricUnion,
  BIMetrics,
  BIWidget,
  BIWidgetDataV2,
  DrillDownParams,
  MetricType,
  SelectedChartValue,
  SelectedValue,
  SyntheticMetricDataWidgetState,
} from 'components/dashboard/Metrics/metrics.types';
import { fetchApi, QueryStatus } from 'utils/network';

interface Props {
  widget: Partial<BIWidget>; // TODO Fix type
  metric: Partial<BIMetrics>; // TODO Fix type
  hasValidFilters?: boolean;
  setWidget: Dispatch<SetStateAction<Partial<BIWidget>>>;
}

type PivotRequestParams = {
  tables: string[];
};

export const MetricsWidget: React.FC<Props> = ({
  widget,
  metric,
  hasValidFilters = false,
  setWidget,
}) => {
  const dispatch = useDispatch();

  const metricsInUse = useMemo(() => {
    if (!metric?._id) {
      return {
        [NOT_SAVED_METRIC]: metric as BIMetrics,
      };
    } else {
      return {
        [metric._id]: metric as BIMetrics,
      };
    }
  }, [metric?._id]);

  const [metricToChartType, setMetricToChartType] = useState<
    BIMetricToChartType[]
  >(
    [metric?._id || NOT_SAVED_METRIC].map((el) => ({
      chartType:
        widget.chart_type === 'table'
          ? BIChartTypes.Table
          : BIChartTypes.Column,
      metricId: el,
    }))
  );

  const [metricData, setMetricData] = useState<BIWidgetDataV2>();
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');

  const [firstPivotColumns, setFirstPivotColumns] = useState<BIMetricColumn[]>(
    []
  );
  const [secondPivotColumns, setSecondPivotColumns] = useState<
    BIMetricColumn[]
  >([]);

  const [pivot1, setPivot1] = useState<BIMetricColumn | undefined>(
    widget.group_by && widget?.group_by[0]
  );
  const [pivot2, setPivot2] = useState<BIMetricColumn | undefined>(
    widget.group_by && widget?.group_by[1]
  );

  const usedFormulaMetricIds = useMemo(
    () =>
      parseFormulaToMetricsIdArray(
        (metric as BIMetricFormula).synthetic_metric
      ),
    [(metric as BIMetricFormula).synthetic_metric]
  );

  const { data: usedMetricsMap } = useMetricsList(usedFormulaMetricIds);

  useEffect(() => {
    const objectsList = [];

    if (
      getMetricType(metric as BIMetricUnion) !== MetricType.Formula &&
      metric?.object
    ) {
      objectsList.push(metric.object);
    }

    if (getMetricType(metric as BIMetricUnion) === MetricType.Formula) {
      Object.keys(usedMetricsMap).forEach((element) => {
        const metricObject = usedMetricsMap[element]?.object;

        if (metricObject) {
          objectsList.push(metricObject);
        }
      });
    }

    if (objectsList.length) {
      const queryParams: PivotRequestParams = {
        tables: [...new Set(objectsList)],
      };

      fetchApi<PivotRequestParams, BIColumnListItem[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_first_pivot`,
        queryMethod: 'post',
        queryParams: queryParams,
        setData: (result) => {
          setFirstPivotColumns(
            result.map((column) => ({
              ...column,
              label: getDropdownFriendlyName(column),
            }))
          );
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load first pivot columns: ${error}`);
        },
      });

      fetchApi<PivotRequestParams, BIColumnListItem[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_second_pivot`,
        queryMethod: 'post',
        queryParams: queryParams,
        setData: (result) => {
          setSecondPivotColumns(
            result.map((column) => ({
              ...column,
              label: getDropdownFriendlyName(column),
            }))
          );
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load second pivot columns: ${error}`);
        },
      });
    }
  }, [metric?.object, usedMetricsMap]);

  useEffect(() => {
    if (
      metric &&
      metric.metadata?.type === 'simple' &&
      metric.object !== 'target' &&
      ((metric.aggregation_function !== 'count' && !metric.column) ||
        !metric.aggregation_function)
    ) {
      return;
    }

    if (
      metric &&
      metric.metadata?.type === 'formula' &&
      !metric.synthetic_metric
    ) {
      return;
    }

    const queryWidget = { ...widget, metric_list: [metric] };

    let abortController: AbortController | null = null;

    const metricValid = Boolean(
      (metric?.aggregation_function && metric?.column) ||
        metric?.aggregation_function === 'count' ||
        metric?.synthetic_metric ||
        metric?.object === 'target'
    );

    if (queryWidget?.metric_list?.length && hasValidFilters && metricValid) {
      abortController = new AbortController();

      fetchWidgetData(
        JSON.stringify(queryWidget),
        '',
        (widget._id ?? widget.name) || '',
        widget.time_field,
        abortController,
        setMetricData,
        setDataStatus,
        setPivot1,
        setPivot2
      );
    }

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [
    metric?.aggregation_function,
    metric?.column?.name,
    metric?.metadata?.type,
    metric?.object,
    metric?.select_expression,
    metric?.synthetic_metric,
    metric?.cumulative_sum_period,
    metric?.target_period,
    metric?.target_type,
    metric?.is_hierarchy_rollup_sum,
    JSON.stringify(metric?.filters),
    widget.metric_list?.length,
    widget.time_field?.name,
    widget.time_field?.label,
    JSON.stringify(widget.group_by),
  ]);

  const isTargetMetric = metric?.object === 'target';

  const pivot1Columns = useMemo(() => {
    // If target metric is only metric on widget we show only pivot by User Name
    if (isTargetMetric) {
      return firstPivotColumns.filter((c) => c.name === 'user.name');
    }

    return firstPivotColumns;
  }, [isTargetMetric, firstPivotColumns]);

  const handleTableDataClick = (column: IColumn, row: IRow): void => {
    const clickedMetric = metric as BIMetrics;

    if (clickedMetric) {
      const drillDownFilters = getDrillDownFilters(
        widget,
        metricData,
        column.extraHeader,
        row
      );

      const drilldownParams: DrillDownParams = createDrillDownParams(
        clickedMetric,
        // If it is a subtotal row then we send only the first pivot value
        row.isSubtotal ? [drillDownFilters[0]] : drillDownFilters,
        '',
        widget
      );

      if (clickedMetric.synthetic_metric) {
        if (typeof clickedMetric.synthetic_metric !== 'string') {
          fetchRecordsAndOpenDrillDownModal(
            undefined,
            drilldownParams,
            clickedMetric.object,
            clickedMetric.name,
            dispatch
          );
          return;
        }

        const parsedClickedMetricFormulaAsArray: string[] =
          parseFormulaToMetricsIdArray(clickedMetric.synthetic_metric);

        const syntheticMetricData: SyntheticMetricDataWidgetState =
          getSyntheticMetricData(
            parsedClickedMetricFormulaAsArray,
            metricData,
            clickedMetric
          );

        const selectedValue: SelectedValue = {
          y: !Number.isNaN(row[column.field] as number)
            ? (row[column.field] as number)
            : 0,
          metricsValues: getMetricNamesWithValues(
            parsedClickedMetricFormulaAsArray,
            row,
            syntheticMetricData
          ),
        };

        const pivot1FieldName = pivot1?.field_name;
        if (pivot1FieldName) {
          selectedValue.pivot1Id = row[pivot1FieldName] as string;
        }

        openSyntheticMetricDetailsModal(
          widget.title || widget.name || 'Drill Down',
          drilldownParams,
          syntheticMetricData,
          selectedValue,
          dispatch
        );
      } else {
        fetchRecordsAndOpenDrillDownModal(
          undefined,
          drilldownParams,
          clickedMetric.object,
          clickedMetric.name,
          dispatch
        );
      }
    }
  };

  const handleChartDataClick = (pointEvent?: PointClickEventObject): void => {
    const selectedValue: SelectedChartValue | undefined =
      pointEvent?.point?.options;

    if (selectedValue) {
      const clickedMetric = metric as BIMetrics;

      if (clickedMetric) {
        openDrillDownModal(
          widget,
          selectedValue,
          clickedMetric,
          '',
          false,
          false,
          undefined,
          metricData,
          availablePivots,
          pivot1,
          pivot2,
          dispatch
        );
      }
    }
  };

  const handleTotalsClick = (metricId: string): void => {
    const clickedMetric = metric as BIMetrics;

    const drilldownParams: DrillDownParams = createDrillDownParams(
      clickedMetric,
      [],
      '',
      widget
    );

    if (clickedMetric.synthetic_metric) {
      if (typeof clickedMetric.synthetic_metric !== 'string') {
        fetchRecordsAndOpenDrillDownModal(
          undefined,
          drilldownParams,
          clickedMetric.object,
          clickedMetric.name,
          dispatch
        );
        return;
      }

      const parsedClickedMetricFormulaAsArray: string[] =
        parseFormulaToMetricsIdArray(clickedMetric.synthetic_metric);

      const syntheticMetricData: SyntheticMetricDataWidgetState =
        getSyntheticMetricData(
          parsedClickedMetricFormulaAsArray,
          metricData,
          clickedMetric
        );

      let clickedValue = 0;

      const totals = metricData?.pivot_0?.columns.map((el) => ({
        ...el,
        total: metricData.pivot_0?.data[0][el.field_name],
      }));

      totals?.forEach((total) => {
        if (total.metric_id === metricId) {
          clickedValue = total.total;
        }
      });

      openSyntheticMetricDetailsModal(
        widget.title || widget.name || 'Drill Down',
        drilldownParams,
        syntheticMetricData,
        {
          y: clickedValue,
          metricsValues: getMetricNamesWithValues(
            parsedClickedMetricFormulaAsArray,
            metricData?.pivot_0?.data[0] || {},
            syntheticMetricData
          ),
        },
        dispatch
      );
    } else {
      fetchRecordsAndOpenDrillDownModal(
        undefined,
        drilldownParams,
        clickedMetric.object,
        clickedMetric.name,
        dispatch
      );
    }
  };

  const hasData = !!metricData?.pivot_1?.data.length;

  const availablePivots = metricData?.hasOwnProperty('pivot_2')
    ? 2
    : metricData?.hasOwnProperty('pivot_1')
    ? 1
    : 0;

  const isDateFirstPivot =
    pivot1?.type === 'date' || pivot1?.type === 'timePeriod';

  const isTableVisualization = metricToChartType.some(
    (el) => el.chartType === 'table'
  );

  return (
    <>
      <WidgetContainer
        key={widget._id}
        isDashboard={false}
        isMetricsPreview={true}
        isModal={false}
      >
        <WidgetHeader
          isCreateEditMetric
          id={widget._id}
          name={widget.name}
          data-testing="widget-header"
        />

        <MetricsWidgetControls
          showControls
          isTargetMetricOnlyWidget={isTargetMetric}
          hasTargetMetric={isTargetMetric}
          firstPivotColumns={pivot1Columns}
          secondPivotColumns={secondPivotColumns}
          pivot1={pivot1}
          pivot2={pivot2}
          widget={widget}
          metricToChartType={metricToChartType}
          setMetricToChartType={setMetricToChartType}
          setPivot1={setPivot1}
          setPivot2={setPivot2}
          setWidget={setWidget}
          data-testing="metrics-widget-controls"
        />

        {dataStatus === 'loading' && (
          <LoaderContainer>
            <Loader active content="Loading" />
          </LoaderContainer>
        )}

        {dataStatus === 'success' && (
          <>
            {(!!metricData?.pivot_0?.columns?.length ||
              !!metricData?.pivot_0?.columns_error?.length) && (
              <div
                className={s.totalsContainer}
                data-testing="totals-container"
              >
                <div className={s.totalsBarElement}>
                  <TotalsBlock
                    showControls
                    widget={widget}
                    isPivoted={pivot1?.name !== 'none'}
                    pivot2={pivot2}
                    metricData={metricData}
                    metricsInUse={metricsInUse}
                    metricToChartType={metricToChartType}
                    onDataClick={handleTotalsClick}
                    type={
                      !metricData?.hasOwnProperty('pivot_1')
                        ? 'singleValue'
                        : 'totalsAndChart'
                    }
                    onChartTypeChange={(metricId, chartType) => {
                      let result: {
                        chartType: BIChartTypes;
                        metricId: string;
                      }[] = [...metricToChartType];

                      if (
                        chartType === BIChartTypes.Table &&
                        (
                          (widget.properties
                            ?.metricToChartType as BIMetricToChartType[]) || []
                        ).every((el) => el.chartType !== BIChartTypes.Table)
                      ) {
                        result = [
                          ...result.map((el) => ({
                            ...el,
                            chartType: chartType,
                          })),
                        ];
                      }
                      if (
                        (
                          (widget.properties
                            ?.metricToChartType as BIMetricToChartType[]) || []
                        ).every((el) => el.chartType === BIChartTypes.Table) &&
                        chartType !== BIChartTypes.Table
                      ) {
                        result = [
                          ...result.map((el) => ({
                            ...el,
                            chartType: chartType,
                          })),
                        ];
                      }

                      const type = result.find(
                        (el) => el.metricId === metricId
                      );

                      if (!!type) {
                        type.chartType = chartType;
                        result = [...result];
                      } else {
                        result = [...result, { metricId, chartType }];
                      }

                      setMetricToChartType(result);
                      setWidget((prev) => ({
                        ...prev,
                        properties: {
                          metricToChartType: result,
                        },
                        chart_type: chartType,
                      }));
                    }}
                  />
                </div>
              </div>
            )}

            {hasData && (
              <>
                {isTableVisualization && !!metricData && (
                  <WidgetTable
                    widgetData={metricData}
                    metricsCount={widget.metric_list?.length || 0}
                    availablePivots={availablePivots}
                    isDateFirstPivot={isDateFirstPivot}
                    metricsInUse={metricsInUse}
                    onTableDataClick={handleTableDataClick}
                  />
                )}

                {!isTableVisualization && !!metricData && (
                  <WidgetChart
                    metricToChartType={metricToChartType}
                    metricData={metricData}
                    metricsInUse={metricsInUse}
                    widget={widget}
                    onDataClick={handleChartDataClick}
                    data-testing="widget-chart-section"
                  />
                )}
              </>
            )}

            {availablePivots > 0 && !hasData && (
              <NoDataMessage data-testing="no-data-message" />
            )}
          </>
        )}

        {dataStatus === 'error' && <DataFetchErrorMessage />}
      </WidgetContainer>
    </>
  );
};
