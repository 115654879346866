import {
  SeriesConfig,
  OptionsKeys,
  OptionsOrder,
  OptionCheckboxItem,
  PipelineType,
} from 'components/dashboard/ForecastDashboard/TrackingDashboard/types';

export const apiPoint = `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/pace/overall`;

export const SERIES_CONFIG: Record<string, SeriesConfig> = {
  total_target: {
    color: 'var(--bu-color-constant-forecast-target)',
    type: 'line',
    dashStyle: 'Dash',
  },
  boostup_forecast: {
    color: 'var(--bu-color-chart-boostup_projection)',
    type: 'line',
    dashStyle: 'Solid',
  },
  company_forecast: {
    color: 'var(--bu-color-chart-company_forecast)',
    type: 'column',
  },
  ideal_target: {
    color: 'var(--bu-color-chart-expected_booked)',
    type: 'line',
    dashStyle: 'Dot',
  },
  booked: {
    color: 'var(--bu-color-constant-booked)',
    type: 'column',
  },
  commit: {
    color: 'var(--bu-color-constant-commit)',
    type: 'column',
  },
  best_case: {
    color: 'var(--bu-color-constant-best-case)',
    type: 'column',
  },
  pipeline: {
    color: 'var(--bu-color-constant-pipeline)',
    type: 'column',
  },
};

export const TRACKING_DASHBOARD_CLOSE_DATE_OPTIONS = [
  {
    text: 'Current Quarter',
    value: 'TQU',
  },
  {
    text: 'Current Year',
    value: 'TYE',
  },
  {
    text: 'Last Quarter',
    value: 'LQU',
  },
  {
    text: 'Next Quarter',
    value: 'NQU',
  },
  {
    text: 'Next 3 Months',
    value: 'N3M',
  },
  {
    text: 'Next 6 Months',
    value: 'N6M',
  },
];

export const TRACKING_DASHBOARD_PACE_INTERVAL_OPTIONS = [
  {
    text: 'Current Quarter',
    value: 'TQU',
  },
  {
    text: 'Current Year',
    value: 'TYE',
  },
  {
    text: 'Last Quarter',
    value: 'LQU',
  },
  {
    text: 'Last 4 Quarters',
    value: 'L4Q',
  },
];

export const TRACKING_DASHBOARD_FREQUENCY_OPTIONS = [
  {
    text: 'Week by Week',
    value: 'week',
  },
  {
    text: 'Month by Month',
    value: 'month',
  },
];

export const defaultCheckboxOptions: Record<OptionsKeys, OptionCheckboxItem> = {
  Q: {
    label: 'Quarterly Forecasts',
    value: 'quarterly',
  },
  M: {
    label: 'Monthly Forecasts',
    value: 'monthly',
  },
  W: {
    label: 'Weekly Forecasts',
    value: 'weekly',
  },
  Projections: {
    label: 'Boostup Projections',
    value: 'projections',
  },
  target: {
    label: 'Targets',
    value: 'target',
  },
  pipeline: {
    label: 'Pipelines',
    value: 'pipeline',
  },
};

export const optionsOrder: OptionsOrder[] = [
  'target',
  'pipeline',
  'quarterly',
  'monthly',
  'weekly',
  'projections',
];

export const CONSTANT_COLORS: Record<PipelineType, string> = {
  Target: 'var(--bu-color-constant-forecast-target)',
  Booked: 'var(--bu-color-constant-booked)',
  Commit: 'var(--bu-color-constant-commit)',
  'Best Case': 'var(--bu-color-constant-best-case)',
  Pipeline: 'var(--bu-color-constant-pipeline)',
  'Pipeline Coverage': 'var(--bu-color-constant-pipeline-coverage-target)',
  total_pipe: 'var(--bu-color-constant-total-pipe)',
  Lost: 'var(--bu-color-constant-lost)',
};

export const DYNAMIC_BAR_COLORS: string[] = [
  'var(--bu-color-dynamic-bar-light-yellow)',
  'var(--bu-color-dynamic-bar-light-aqua)',
  'var(--bu-color-dynamic-bar-light-lime)',
  'var(--bu-color-dynamic-bar-light-indigo)',
  'var(--bu-color-dynamic-bar-light-purple)',
  'var(--bu-color-dynamic-bar-light-blue)',
];

export const DYNAMIC_LINE_COLORS: string[] = [
  'var(--bu-color-dynamic-line-dark-green)',
  'var(--bu-color-dynamic-line-green)',
  'var(--bu-color-dynamic-line-dark-lime)',
  'var(--bu-color-dynamic-line-lime)',
  'var(--bu-color-dynamic-line-dark-aqua)',
  'var(--bu-color-dynamic-line-aqua)',
  'var(--bu-color-dynamic-line-mint)',
  'var(--bu-color-dynamic-line-dark-blue)',
  'var(--bu-color-dynamic-line-blue)',
];
