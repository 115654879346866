export const getSellerTargetsOverall = (state: any) =>
  state.sellerTargets.targetsOverall.targets;
export const getSellerTargetsByUser = (state: any) =>
  state.sellerTargets.targetsByUser.targets;
export const getSellerTargetsByManager = (state: any) =>
  state.sellerTargets.targetsByManager.targets;

export const isTargetChanged = (state: any) =>
  state.sellerTargets.setTarget.targetChanged;
export const isTargetsLoading = (state: any) =>
  state.sellerTargets.targetsOverall.loading ||
  state.sellerTargets.targetsByUser.loading ||
  state.sellerTargets.targetsByManager.loading;

export const getSellerTargetsHiddenColumns = (state: any): string[] =>
  state.sellerTargets.setTarget.hiddenColumns;
