import {
  IColumn,
  IDataCellProps,
  IRow,
  isDisable,
  isEditable,
  TypedTableCellConfig,
  ValueProp,
  ValueType,
} from '../TypedTable';
import DeltaValueCell, { getValueFromDelta } from './common/DeltaValueCell';
import classNames from 'classnames';
import { css } from 'emotion';
import * as R from 'ramda';
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Popup,
  Dropdown,
  DropdownItemProps,
  DropdownProps,
} from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { useStateWhile } from 'common/helpers';
import BuIcon from 'components/UI/BuIcon';
import NotAvailableCell from 'components/UI/common/TypedTable/renderers/NotAvailableCell';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import * as s from 'components/UI/common/TypedTable/styles';

export type IDropDownOptions = DropdownItemProps;

type GetOptionsParams<T extends IRow> = {
  column: IColumn;
  row: IRow;
  rows: T[];
};
type GetOptions<T extends IRow> = (
  params: GetOptionsParams<T>
) => DropdownItemProps[];

export function isOptionsFunction<T extends IRow>(
  options: GetOptions<T> | DropdownItemProps[]
): options is GetOptions<T> {
  return options instanceof Function;
}

export interface DropDownCellConfig<T extends IRow = IRow>
  extends TypedTableCellConfig {
  additionalClassName?: string;
  options?: GetOptions<T> | DropdownItemProps[];
  search?: boolean;
}

export const isDropDownCellConfig = (
  config: TypedTableCellConfig
): config is DropDownCellConfig =>
  'additionalClassName' in config || 'options' in config || 'search' in config;

const emptyFieldStyle = css`
  color: var(--bu-gray-500);
`;

const editableCellBorder = css`
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: var(--bu-control-border-radius);

  padding: 1px 4px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  tr:hover .typed-table-cell-type-dropdown & .bu-icon {
    visibility: visible;
  }

  tr:hover &,
  tr.show-controls & {
    border: 1px solid var(--bu-gray-400);
    box-sizing: border-box;
    background-color: var(--bu-white);

    &:hover {
      border-color: var(--bu-gray-500);
      cursor: pointer;
    }
  }

  &.active {
    border-color: var(--bu-primary-500);
  }

  &.loading {
    background-color: #fffaca !important;
  }

  &.success {
    background-color: #cff6d7 !important;
  }

  &.error {
    background-color: #fce3dc !important;
  }
`;

const popupClass = css`
  &.ui.top.popup {
    padding: 0;
  }

  &.ui.bottom.popup {
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
    height: 0;
    width: 0;
    min-width: 0;
    min-height: 0;
    margin-top: -15px;
    padding: 0;
  }
  & > .dropdown > div.text {
    display: none;
  }

  & > ui.dropdown {
    & > .text {
      color: var(--bu-gray-900);
      line-height: 14px;
      font-weight: 100;

      padding-left: 5px;
      padding-right: 15px;
      padding-top: 6px;
    }

    &.search {
      &,
      &.active,
      &.visible {
        & > input.search {
          line-height: 25px;
          padding-left: 6px;
        }
      }
    }
  }
`;

const fluidLabel = css`
  width: 100%;
  padding-left: 7px;
`;

const fontStyle = css`
  font-weight: 100;
  height: 28px;
`;

const dropdownLabelOverflow = css`
  width: 100%;
`;

const chevronIcon = css`
  .bu-icon {
    visibility: hidden;
  }

  tr.show-controls &.ui.dropdown,
  tr:hover &.ui.dropdown,
  &.ui.dropdown.active {
    > .text::after {
      content: '\f0d7';
      display: inline-block;
      font-family: Icons;
      font-size: 10px;
      right: 5px;
      position: absolute;
    }
  }
`;

const cellText = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

/* to inverse dropdown list if there is no enough place to display it */
const dropdownItemHeight = 36;
const dropUp = css`
  div.visible.menu.transition {
    inset: auto auto 0 0;
    transform: translate3d(2px, -${dropdownItemHeight}px, 0px);
  }
`;

const topDropdownWrapper = css`
  &.ui.inline.dropdown {
    display: flex;
  }

  & > .visible.menu.transition {
    margin: 0 !important;
  }

  & > .menu {
    position: relative !important;
  }
`;

const DropDownCell = ({
  column,
  row,
  onChange,
  status,
  rows,
}: IDataCellProps) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef<any>(null);
  const [dropdownDirection, setDropdownDirection] = useState('down');
  const oldValue = getValueFromDelta(
    R.path(column.field.split('.'), row) as ValueType
  ) as ValueType;

  const config = (column.config as DropDownCellConfig) || {};
  const options =
    config.options && isOptionsFunction(config.options)
      ? config.options({ column, row, rows })
      : config.options;

  const [showStatus, setShowStatus] = useStateWhile(false);

  useEffect(() => {
    if (status && status.date + 5000 > Date.now()) {
      setShowStatus(true, 5000);
    }
  }, [status && status.date]);

  // This is necessary to avoid "Can't perform a React state update on an unmounted component"
  // error because Dropdown is inside the PopUp and the PopUp destroy Dropdonw
  // before the Dropdonw finish your internal routines
  const onRequestClose = () => window.setTimeout(() => setOpen(false), 0);

  useEffect(() => {
    return () => setOpen(false);
  }, []);

  /* should be removed once we use BuSelect instead of react-semantic-ui dropdown */
  const handleDropdownDirection = useCallback(() => {
    setTimeout(() => {
      /* maximum len elements that we can fit in dropdown */
      const optionsLength = options!.length <= 8 ? options!.length : 8;
      const dropdownHeight =
        optionsLength * dropdownItemHeight +
        ref.current?.offsetParent.clientHeight;

      if (
        window.innerHeight - ref.current?.getBoundingClientRect()?.top <
        dropdownHeight
      ) {
        setDropdownDirection('up');
      }
    }, 0);
  }, [ref.current?.getBoundingClientRect()?.top, window.innerHeight]);

  const disabled = isDisable(column, row, rows);

  const getOptionItem = (value: ValueProp) =>
    options && options.find((item) => item.value === value);

  const formatter = (value: ValueProp) => {
    const printOption = getOptionItem(value);

    return `${printOption ? printOption.text : oldValue ? oldValue : ''}`;
  };

  const valueAsNotAvailable =
    column.useValueAsNotAvailable &&
    `${(row[column.field] as { value: string }).value} (Inactive)`;

  const placeholder =
    (options &&
      options
        .find((option) => option.value === null)
        ?.text?.toLocaleString()) ||
    valueAsNotAvailable ||
    '-';

  const emptyField = {
    key: '-empty-field-',
    text: <span className={emptyFieldStyle}>{placeholder}</span>,
    value: undefined,
  };

  const dropdownValue =
    options && options.find((option) => option.value === oldValue)?.text;

  const handleChange = (
    event: SyntheticEvent<HTMLElement>,
    { value }: DropdownProps
  ) => {
    if (onChange instanceof Function) {
      setOpen(false);
      onChange(column, row, value as ValueType);
    }
  };

  if (!isEditable(column, row, rows)) {
    return (
      <DeltaValueCell
        column={column}
        row={row}
        rows={rows}
        formatter={formatter}
      >
        <div className={classNames(fluidLabel, s.textOverflow)}>
          {R.isNil(oldValue) ? (
            <NotAvailableCell placeholder={'-'} />
          ) : (
            <TooltipWrapper tooltip={column.showTooltip && formatter(oldValue)}>
              <div
                style={{ width: '100%' }}
                className={getOptionItem(oldValue)?.className}
              >
                {formatter(oldValue)}
              </div>
            </TooltipWrapper>
          )}
        </div>
      </DeltaValueCell>
    );
  }

  return (
    <DeltaValueCell column={column} row={row} rows={rows} formatter={formatter}>
      <div ref={ref}>
        <TooltipWrapper
          tooltip={column.showTooltip && formatter(oldValue)}
          wrap
          disable={isOpen}
        >
          <Popup
            basic
            positionFixed
            position={column.config.popupPosition || 'bottom left'}
            on="click"
            content={
              <Dropdown
                options={
                  options &&
                  placeholder &&
                  dropdownValue === undefined &&
                  !options.find((item) => item.value === dropdownValue)
                    ? [emptyField, ...options]
                    : options
                }
                open={true}
                placeholder={placeholder}
                icon={null}
                className={classNames('dropdown-cell', {
                  [topDropdownWrapper]:
                    column.config.popupPosition === 'top left',
                  [dropUp]: dropdownDirection === 'up',
                })}
                onChange={handleChange}
                value={dropdownValue as string}
                floating
                inline
                scrolling
                search={config.search}
                disabled={disabled}
              />
            }
            open={isOpen}
            className={popupClass}
            onOpen={() => {
              if (!disabled) {
                handleDropdownDirection();
                setOpen(true);
              }
            }}
            onClose={onRequestClose}
            trigger={
              <span
                className={classNames(
                  editableCellBorder,
                  chevronIcon,
                  dropdownLabelOverflow,
                  fontStyle,
                  {
                    [(status && status.status) || '']: showStatus,
                    active: isOpen,
                  },
                  getOptionItem(oldValue)?.className,
                  config.additionalClassName
                )}
              >
                <span className={cellText}>{dropdownValue ?? placeholder}</span>
                <BuIcon
                  name={
                    isOpen ? BoostUpIcons.ChevronUp : BoostUpIcons.ChevronDown
                  }
                />
              </span>
            }
          />
        </TooltipWrapper>
      </div>
    </DeltaValueCell>
  );
};

export default DropDownCell;
