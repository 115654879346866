/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable import/prefer-default-export */
import clx from 'classnames';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from 'actions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { AdditionalDropdown } from 'components/UI/OpenFiltersPanel/ViewDropdownFilter/AdditionalDropdown';
import {
  container,
  view_filters_box,
  view_filters_label,
  arrow_up_down,
  view_filters_options,
  options_box_element,
  options_box_title,
  default_view,
} from 'components/UI/OpenFiltersPanel/ViewDropdownFilter/styles';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { IReduxState } from 'reducers/types';
import {
  getPersistName,
  getFeatureFlags,
  getBusinessTypes,
  getUser,
} from 'selectors';
import {
  getFiltersProfiles,
  getActiveFiltersProfile,
  getBestProfileNameByTab,
} from 'selectors/openFilters';

type Props = {
  tab: string;
  onChange: (name: string) => void;
};

const sections: { code: Filters.CustomViewsType; title: string }[] = [
  { code: 'DEFAULT', title: '' },
  { code: 'PERSONAL', title: 'Your Views' },
  { code: 'GLOBAL', title: 'Recommended Views' },
];

export const ViewDropdownFilter: React.FC<Props> = ({ tab, onChange }) => {
  const dispatch = useDispatch();
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const {
    profiles,
    selectedProfile,
    persistName,
    businessTypes,
    isSharedViewsEnabled,
    bestProfileName,
  } = useSelector((state: IReduxState) => {
    const { deals_overall_view_enabled, shared_views_enabled } =
      getFeatureFlags(state);

    return {
      profiles: getFiltersProfiles(state, tab, shared_views_enabled),
      selectedProfile: getActiveFiltersProfile(state, tab),
      persistName: getPersistName(state, false),
      businessTypes: getBusinessTypes(state, deals_overall_view_enabled),
      isSharedViewsEnabled: shared_views_enabled,
      user: getUser(state),
      bestProfileName: getBestProfileNameByTab(state, tab),
    };
  });

  // When selectedProfile (saved views) change, set the showOrHideColumns
  // and business type according to the saved view
  useEffect(() => {
    if (
      selectedProfile?.profile.tablePersistName &&
      selectedProfile?.profile.columnToggleState
    ) {
      dispatch(
        actions.ui.appState.replaceShowOrHideColumns({
          path: selectedProfile?.profile.tablePersistName,
          columns: selectedProfile?.profile.columnToggleState,
        })
      );
    } else {
      // backward compatible with older views that does not contain column toggles
      dispatch(
        actions.ui.appState.replaceShowOrHideColumns({
          path: persistName,
          columns: [],
        })
      );
    }
    // dispatch to set businesstype
    dispatch(
      actions.ui.manageForecast.setActiveBusinessType(
        selectedProfile?.profile.selectedBusinessType ?? businessTypes[0]
      )
    );
    dispatch(
      actions.ui.filters.setBusinessType({
        businessType:
          selectedProfile?.profile.selectedBusinessType ?? businessTypes[0],
        tab,
      })
    );
  }, [selectedProfile?.name]);

  useEffect(() => {
    if (
      selectedProfile?.name &&
      bestProfileName &&
      selectedProfile?.name !== bestProfileName
    ) {
      dispatch(
        actions.ui.filters.setActiveProfile({
          tab,
          profile: bestProfileName,
        })
      );
    }
  }, [dispatch, tab, selectedProfile?.name, bestProfileName]);

  const handleClickProfile = (name: string) => {
    dispatch(
      actions.ui.filters.setActiveProfile({
        tab,
        profile: name,
      })
    );

    AnalyticsTracker.event(
      { tab },
      {
        category: 'Filters Panel',
        action: 'click on view filters',
        value: name,
      }
    );

    setIsOpen(false);
    onChange(name);
  };

  const isViewShareable =
    isSharedViewsEnabled && selectedProfile?.profile.view_type === 'PERSONAL';

  return (
    <div className={container}>
      <div className={view_filters_box} ref={refElement}>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={clx(view_filters_label, { open: isOpen })}
        >
          View: <span>{selectedProfile?.name}</span>
          <span className={arrow_up_down}>
            <BuIcon
              name={isOpen ? BoostUpIcons.ChevronUp : BoostUpIcons.ChevronDown}
            />
          </span>
        </button>

        <div className={clx(view_filters_options, { open: isOpen })}>
          {sections
            .filter((section) => !!profiles[section.code])
            .map((section) => (
              <div key={section.code}>
                {section.title && (
                  <div className={options_box_title}>{section.title}</div>
                )}

                {profiles[section.code]?.map(({ name }) => (
                  <div
                    key={section.code + name}
                    className={clx(options_box_element, {
                      active: name === selectedProfile?.name,
                    })}
                    onClick={() => handleClickProfile(name)}
                  >
                    {name === selectedProfile?.name && (
                      <BuIcon name={BoostUpIcons.Checked} />
                    )}
                    {name}
                    {section.code === 'DEFAULT' && (
                      <span className={default_view}>(Default view)</span>
                    )}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>

      {selectedProfile?.profile.id &&
        selectedProfile?.profile.view_type !== 'DEFAULT' &&
        (isViewShareable || selectedProfile?.profile.deleteable) && (
          <AdditionalDropdown
            tab={tab}
            profileId={selectedProfile.profile.id}
            profileName={selectedProfile.name}
            profileShareable={isViewShareable}
            profileDeleteable={selectedProfile.profile.deleteable}
          />
        )}
    </div>
  );
};
