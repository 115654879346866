import { combineReducers, Reducer } from 'redux';

import * as t from 'actions/actionTypes';
import {
  SubmissionForecastResponse,
  SubmissionForecastAction,
  SubmissionForecastHistoryAction,
  SubmissionForecastHistory,
  SubmissionBookedAndTargetAction,
  SubmissionBookedAndTargetResponse,
} from 'actions/croOverrideActions';

export type SubmissionForecastState = SubmissionForecastResponse;

const initialState: SubmissionForecastState = {
  created_at: '',
  users: [],
  columns: [],
};

const submissionForecast: Reducer<
  SubmissionForecastState,
  SubmissionForecastAction
> = (
  state: SubmissionForecastState = initialState,
  action: SubmissionForecastAction
) => {
  switch (action.type) {
    case t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.GET + t.SUCCESS:
    case t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.UPDATE + t.SUCCESS:
      return { ...action.payload! };
    case t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + +t.GET + t.ERROR:
      return initialState;
    default:
      return state;
  }
};

const SubmissionBookedAndTargetInitialState = {};

export type SubmissionBookedAndTargetState = SubmissionBookedAndTargetResponse;

const submissionBookedAndTarget: Reducer<
  SubmissionBookedAndTargetState,
  SubmissionBookedAndTargetAction
> = (
  state: SubmissionBookedAndTargetState = SubmissionBookedAndTargetInitialState,
  action: SubmissionBookedAndTargetAction
) => {
  switch (action.type) {
    case t.CRO_OVERRIDE +
      t.SUBMISSION_FORECAST_BOOKED_AND_TARGET +
      t.GET +
      t.SUCCESS:
      return { ...action.payload! };
    case t.CRO_OVERRIDE +
      t.SUBMISSION_FORECAST_BOOKED_AND_TARGET +
      +t.GET +
      t.ERROR:
      return SubmissionBookedAndTargetInitialState;
    default:
      return state;
  }
};

export type SubmissionForecastHistoryState = SubmissionForecastHistory[];

const submissionForecastHistory: Reducer<
  SubmissionForecastHistoryState,
  SubmissionForecastHistoryAction
> = (state = [], action) => {
  switch (action.type) {
    case t.CRO_OVERRIDE + t.SUBMISSION_HISTORY + t.GET + t.SUCCESS:
      return [...action.payload!];
    case t.CRO_OVERRIDE + t.SUBMISSION_HISTORY + +t.GET + t.ERROR:
      return [];
    default:
      return state;
  }
};

const submissionForecastOverrides: Reducer<
  SubmissionForecastState,
  SubmissionForecastAction
> = (state = initialState, action) => {
  switch (action.type) {
    case t.CRO_OVERRIDE + t.SUBMISSION_FORECAST_OVERRIDES + t.GET + t.SUCCESS:
      return { ...action.payload! };
    case t.CRO_OVERRIDE + t.SUBMISSION_FORECAST_OVERRIDES + +t.GET + t.ERROR:
      return initialState;
    default:
      return state;
  }
};

export type SubmissionStatusState =
  | 'notAsked'
  | 'loading'
  | 'success'
  | 'submitted'
  | 'error';

const submissionForecastStatus: Reducer<
  SubmissionStatusState,
  SubmissionForecastAction
> = (state = 'notAsked', action) => {
  switch (action.type) {
    case t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.GET + t.LOADING:
    case t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.UPDATE + t.LOADING:
      return 'loading';
    case t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.GET + t.SUCCESS:
    case t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.UPDATE + t.SUCCESS:
      return action.submit ? 'submitted' : 'success';
    case t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.GET + t.ERROR:
    case t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.UPDATE + t.ERROR:
      return 'error';
    default:
      return state;
  }
};

export type SubmissionBookedAndTargetStatusState =
  | 'notAsked'
  | 'loading'
  | 'success'
  | 'submitted'
  | 'error';

const submissionBookedAndTargetStatus: Reducer<
  SubmissionBookedAndTargetStatusState,
  SubmissionBookedAndTargetAction
> = (state = 'notAsked', action) => {
  switch (action.type) {
    case t.CRO_OVERRIDE +
      t.SUBMISSION_FORECAST_BOOKED_AND_TARGET +
      t.GET +
      t.LOADING:
      return 'loading';
    case t.CRO_OVERRIDE +
      t.SUBMISSION_FORECAST_BOOKED_AND_TARGET +
      t.GET +
      t.SUCCESS:
      return 'success';
    case t.CRO_OVERRIDE +
      t.SUBMISSION_FORECAST_BOOKED_AND_TARGET +
      t.GET +
      t.ERROR:
      return 'error';
    default:
      return state;
  }
};

const submissionForecastOverridesStatus: Reducer<
  SubmissionStatusState,
  SubmissionForecastHistoryAction
> = (state = 'notAsked', action) => {
  switch (action.type) {
    case t.CRO_OVERRIDE + t.SUBMISSION_FORECAST_OVERRIDES + t.GET + t.LOADING:
    case t.CRO_OVERRIDE +
      t.SUBMISSION_FORECAST_OVERRIDES +
      t.UPDATE +
      t.LOADING:
      return 'loading';
    case t.CRO_OVERRIDE + t.SUBMISSION_FORECAST_OVERRIDES + t.GET + t.SUCCESS:
    case t.CRO_OVERRIDE +
      t.SUBMISSION_FORECAST_OVERRIDES +
      t.UPDATE +
      t.SUCCESS:
      return 'success';
    case t.CRO_OVERRIDE + t.SUBMISSION_FORECAST_OVERRIDES + t.GET + t.ERROR:
    case t.CRO_OVERRIDE + t.SUBMISSION_FORECAST_OVERRIDES + t.UPDATE + t.ERROR:
      return 'error';
    default:
      return state;
  }
};

const submissionForecastHistoryStatus: Reducer<
  SubmissionStatusState,
  SubmissionForecastHistoryAction
> = (state = 'notAsked', action) => {
  switch (action.type) {
    case t.CRO_OVERRIDE + t.SUBMISSION_HISTORY + t.GET + t.LOADING:
      return 'loading';
    case t.CRO_OVERRIDE + t.SUBMISSION_HISTORY + t.GET + t.SUCCESS:
      return 'success';
    case t.CRO_OVERRIDE + t.SUBMISSION_HISTORY + t.GET + t.ERROR:
      return 'error';
    default:
      return state;
  }
};

const submissionForecastInitiatorRoute = (
  state = null,
  action: { type: string; payload: string }
) => {
  switch (action.type) {
    case t.CRO_OVERRIDE + t.SET + t.NAVIGATED_FROM:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  submissionForecast,
  submissionForecastStatus,
  submissionForecastOverrides,
  submissionForecastHistory,
  submissionForecastOverridesStatus,
  submissionForecastHistoryStatus,
  submissionBookedAndTarget,
  submissionBookedAndTargetStatus,
  submissionForecastInitiatorRoute,
});
