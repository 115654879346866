import * as R from 'ramda';
import { createSelector } from 'reselect';

export const getAdmin = (state) => state.admin;
export const getAdminEmail = (state) => state.admin.email;

export const isAdmin = createSelector(
  [getAdminEmail],
  R.complement(R.either(R.isNil, R.isEmpty))
);

export const isImpersonate = (state) => {
  const { email } = state.user;
  const { email_impersonation, is_user_impersonation_allowed } = state.admin;

  return (
    email &&
    is_user_impersonation_allowed &&
    email_impersonation &&
    email_impersonation !== email
  );
};

export const getCompanyDomain = (state) => state.admin.email_domain || '';
export const isAdminEnabled = (state) => state.admin.isEnabled;
export const isDeveloper = (state) => state.admin.isDeveloper;
export const isCSM = (state) => state.admin.isCSM;
export const getAdminDataToShow = (state) => state.admin.dataShown;
export const isAdminListLoading = (state) => state.admin.isLoading;
export const getClassType = (state) => state.admin.selected_class_type;
export const getAdminApiError = (state) => state.admin.apiError;
export const getAdminApiSuccess = (state) => state.admin.apiSuccess;
export const getAdminCompanies = (state) => state.admin.companies;
export const getAdminUsers = (state) => state.admin.users;
export const isUserImpersonationAllowed = (state) =>
  state.admin.is_user_impersonation_allowed;
export const getAdminApiAccessToken = (state) => state.admin.api_access_token;
export const getPassword = (state) => state.admin.password;
