import styled from '@emotion/styled';
import React, { useCallback } from 'react';

import { sortAlphabetically } from 'common/helpers';
import BuSelect from 'components/UI/BuSelect';
import { columnsToDropdownOptions } from 'components/dashboard/Metrics/Create/helpers';
import { AGGREGATION_OPTIONS } from 'components/dashboard/Metrics/constants';
import {
  MetricCreateSubTitle,
  FlexRow,
} from 'components/dashboard/Metrics/metrics.styles';
import {
  BIDropdownOptions,
  BIMetricColumn,
  BIMetricSimple,
  AggregationFunction,
  BIMetricSimpleNewborn,
} from 'components/dashboard/Metrics/metrics.types';

const OptionGroup = styled.div({
  marginBottom: '24px',
});
const AggregationDropdownContainer = styled.div({
  display: 'flex',
  flexShrink: 0,
  flexGrow: 0,
  width: '100px',
  marginRight: '12px',
  '> div': {
    width: '100px',
  },
});

const ColumnDropdownContainer = styled.div({
  display: 'flex',
  flexGrow: 1,
  '>.select': {
    width: '100%',
  },
});

interface Props {
  readonly metric: BIMetricSimple | BIMetricSimpleNewborn;
  readonly setMetric: (metric: BIMetricSimple | BIMetricSimpleNewborn) => void;
  readonly columnList: BIMetricColumn[];
}

export const MetricCreateSelectField: React.FC<Props> = ({
  metric,
  setMetric,
  columnList,
}) => {
  const getColumnOptionsByTypes = useCallback(
    (types: string[]): BIDropdownOptions[] => {
      return columnsToDropdownOptions(
        columnList.filter((column) => types.includes(column.type))
      );
    },
    [columnList, metric]
  );

  const columnOptions: BIDropdownOptions[] =
    metric!.aggregation_function === 'count'
      ? getColumnOptionsByTypes(['text'])
      : getColumnOptionsByTypes(['money', 'number']);

  return (
    <div data-testing="property-section">
      <MetricCreateSubTitle>Property</MetricCreateSubTitle>
      <OptionGroup>
        <FlexRow>
          <AggregationDropdownContainer>
            <BuSelect
              options={AGGREGATION_OPTIONS}
              onChange={(values: string[]) => {
                setMetric({
                  ...metric,
                  aggregation_function: values[0] as AggregationFunction,
                  column: values[0] === 'count' ? undefined : metric.column,
                  ...metric.metadata,
                });
              }}
              secondary
              defaults={[metric?.aggregation_function ?? '']}
              fullWidth
            />
          </AggregationDropdownContainer>
          <ColumnDropdownContainer>
            <BuSelect
              options={sortAlphabetically(
                columnOptions.map((column) => ({
                  text: column.label,
                  value: column.value,
                }))
              )}
              onChange={(values: string[]) => {
                const selectedColumn = columnList.find(
                  (oppColumn) => values[0] === oppColumn.name
                );
                setMetric({
                  ...metric,
                  column: selectedColumn,
                });
              }}
              secondary
              placeholder={'Select a column'}
              defaults={[metric.column ? metric.column.name : '']}
              searchable
              className={'select'}
              fullWidth
              isLargePlaceholder
            />
          </ColumnDropdownContainer>
        </FlexRow>
      </OptionGroup>
    </div>
  );
};
