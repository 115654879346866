import {
  FORMULA_METRIC_NEWBORN_MOCK,
  SIMPLE_METRIC_NEWBORN_MOCK,
} from './contants';

import {
  BIMetricFormula,
  BIMetricSimple,
  MetricType,
  BIMetricsFilter,
  BIMetricsQueryFilter,
  AggregationFunction,
  BIMetricSimpleNewborn,
  BIMetricFormulaNewborn,
  BIWidgetPreview,
} from 'components/dashboard/Metrics/metrics.types';

export const getMetricType = (
  metric:
    | BIMetricSimple
    | BIMetricFormula
    | BIMetricFormulaNewborn
    | BIMetricSimpleNewborn
): MetricType => {
  return metric.metadata.type;
};

export const isValidFilter = (filter: Partial<BIMetricsFilter>): boolean => {
  return Boolean(
    filter?.column &&
      filter?.operator &&
      filter?.value !== undefined &&
      filter?.value !== null &&
      filter?.value !== ''
  );
};

export const isMetricFiltersValid = (
  metric: BIMetricSimpleNewborn | BIMetricFormulaNewborn
) => {
  return metric.filters.reduce((pre: boolean, filter: BIMetricsQueryFilter) => {
    return pre && isValidFilter(filter?.and_condition?.[0]?.or_condition?.[0]);
  }, true);
};

export const isSimpleMetricCanPreview = (
  metric: BIMetricSimple | BIMetricSimpleNewborn
) => {
  if (metric.object === 'target') {
    return true;
  } else {
    return Boolean(metric.column);
  }
};

export const isFormulaMetricCanPreview = (
  metric: BIMetricFormula | BIMetricFormulaNewborn
) => {
  return Boolean(metric.synthetic_metric);
};

export const isSimpleMetricValid = (
  metric: BIMetricSimple | BIMetricSimpleNewborn
) => {
  const isNameValid = typeof metric.name === 'string' && metric.name.length > 0;
  return isSimpleMetricCanPreview(metric) && isNameValid;
};

export const isFormulaMetricValid = (
  metric: BIMetricFormula | BIMetricFormulaNewborn
) => {
  const isNameValid = typeof metric.name === 'string' && metric.name.length > 0;
  return isFormulaMetricCanPreview(metric) && isNameValid;
};

/**
 * Function clones some common fields from one to another. Works only with already created metrics.
 * @param recipient Metric with new fields from donor
 * @param donor Metric with fields to copy to recipient
 */
export function reduxStoreMetricCloner<
  T extends BIMetricFormula | BIMetricSimple | null,
  K extends BIMetricFormula | BIMetricSimple
>(recipient: T, donor: K): T {
  const COMMON_FIELDS: (keyof K)[] = ['_id', 'name', 'filters'];
  const initValue: Partial<K> = {};

  if (recipient === null && getMetricType(donor) === MetricType.Formula) {
    recipient = SIMPLE_METRIC_NEWBORN_MOCK as T;
  }
  if (recipient === null && getMetricType(donor) === MetricType.Simple) {
    recipient = FORMULA_METRIC_NEWBORN_MOCK as T;
  }

  return {
    ...recipient,
    ...COMMON_FIELDS.reduce((acc: Partial<K>, fieldKey) => {
      if (fieldKey in donor) {
        acc[fieldKey] = donor[fieldKey];
      }
      return acc;
    }, initValue),
  };
}

export const canWidgetBeSaved = (widget: BIWidgetPreview) => {};
