import styled from '@emotion/styled';

interface WidgetTitleRowProps {
  readonly isDashboardModal: boolean;
}

export const WidgetTitleRow = styled.div(
  ({ isDashboardModal = false }: WidgetTitleRowProps) => ({
    display: 'flex',
    alignItems: 'center',
    p: {
      fontSize: '18px',
      margin: '0px',
      color: isDashboardModal ? 'inherit' : 'var(--bu-primary-500)',
      cursor: isDashboardModal ? 'inherit' : 'pointer',
      span: {
        fontSize: '14px',
        color: 'var(--bu-gray-700)',
        fontWeight: 400,
      },
    },
    i: {
      fontSize: '20px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    padding: isDashboardModal ? '24px 16px 12px 16px' : '24px 16px 0px 16px',
    justifyContent: isDashboardModal ? 'start' : 'space-between',
    maxHeight: '70px',
  })
);
