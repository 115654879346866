import styled from '@emotion/styled/macro';
import { css } from 'emotion';

const ViewContainer = styled.div`
  margin: 0px -20px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SettingsView = {
  ViewContainer,
  HeaderContainer: styled.div`
    display: flex;
    margin: 20px 0;

    ${ViewContainer} & {
      padding: 0 20px;
    }
  `,
  HeaderOne: styled.h1`
    color: #00000;
    letter-spacing: 0;
    flex: 1;
    font-weight: 400;
    font-size: 24px;
    text-transform: capitalize;
  `,
  TableHeaderContainer: styled.div`
    display: flex;
  `,
  Header: styled.div`
    color: #1e306e;
    letter-spacing: 0;
    flex: 1;
  `,
  SubHeader: styled.div`
    color: var(--bu-gray-700);
    margin-top: 7px;
    font-size: 0.8em;
    font-weight: normal;
  `,
  HeaderControls: styled.div`
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
    flex-direction: row;
  `,
  SectionContainer: styled.div`
    ${ViewContainer} & {
      margin: 15px 20px;
    }
    a {
      cursor: pointer;
    }
  `,
  ContentBox: styled.div<{
    transparent?: boolean;
    error?: boolean;
    borderless?: boolean;
    vertical?: boolean;
  }>`
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: ${({ vertical }) => (vertical ? 'flex-start' : 'center')};
    flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
    flex-wrap: wrap;
    gap: 10px;

    border: 1px solid var(--bu-gray-500);
    background-color: var(--bu-gray-200);
    ${({ transparent }) => transparent && 'background-color: transparent'};
    ${({ error }) => error && `background-color: var(--bu-red-200)`};
    ${({ borderless }) => borderless && 'border: none;'}

    padding: 15px 20px;
    color: var(--bu-gray-900);

    ${ViewContainer} &:first-of-type {
      border-top-left-radius: var(--bu-control-border-radius);
      border-top-right-radius: var(--bu-control-border-radius);
    }

    ${ViewContainer} &:not(:first-of-type) {
      border-top: none;
    }

    ${ViewContainer} &:last-of-type {
      border-bottom-left-radius: var(--bu-control-border-radius);
      border-bottom-right-radius: var(--bu-control-border-radius);
    }
    &.noApiKeysContainer {
      height: 114px;
      display: flex;
      justify-content: center;
      > p {
        color: var(--bu-gray-700);
      }
    }
  `,
  Table: styled.section`
    position: relative;
  `,
  ControlsGroup: styled.div`
    display: flex;
    align-items: center;
    gap: 5px;

    &.fluid {
      flex: 1;
    }
  `,
  Label: styled.span`
    margin-right: 30px;
  `,
};

SettingsView.ContentBox.defaultProps = {
  transparent: false,
  error: false,
  borderless: false,
};

SettingsView.Header.defaultProps = {
  className: 'bu-font-section-title',
};

export const removeContainerPadding = css`
  margin: 0px -20px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const contentPadding = css`
  padding: 0 20px;
`;

export const featureSwitch = css`
  align-items: center;
  display: flex;

  & > .feature-label {
    padding-right: 10px;
    color: #4a4a4a;
    margin: 8px 0;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
`;

export const mainTitle = css`
  color: var(--bu-primary-700);
  font-size: var(--bu-font-size-small);
`;

export const configPanel = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 7px 20px;
  gap: 10px;
`;

export const addNewProcessPanel = css`
  margin-left: 10px;
  color: var(--bu-gray-900);

  span:first-child {
    color: var(--bu-gray-700);
  }
`;

export const configPanelBox = css`
  border-radius: 4px;
`;

export const salesProcessDropdown = css`
  .bu-dropdown {
    min-width: 20vw;
    max-width: 20vw;
  }

  .bu-dropdown-content-wrapper {
    min-width: 20vw;
    max-width: 20vw;
    .bu-dropdown-content-box {
      div {
        overflow-x: hidden;
      }
    }
  }
`;

export const addNewProcessButton = css`
  color: var(--bu-primary-500);
  margin-left: 5px;
  cursor: pointer;
`;

export const configPanelControl = css`
  width: 100%;
  justify-content: space-between;
`;

export const subTitle = css`
  display: flex;
  margin-right: 1vw;
`;

export const title = css`
  font-size: var(--bu-font-size-big);
`;

export const salesProcessSecondPanel = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2vw;
`;

export const panelBox = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1vw;
`;

export const inputWithoutBorders = css`
  &.ui.input > input {
    border-color: transparent;
  }
`;

export const dropdown = css`
  font-weight: 100;
  height: 31px;

  margin: 0 5px;

  border: 1px solid var(--bu-gray-400);
  box-sizing: border-box;
  background-color: #fff;
  border-radius: var(--bu-control-border-radius);

  padding: 1px;

  &.ui.search.dropdown.active > input.search,
  &.ui.search.dropdown.visible > input.search {
    height: 100%;
  }

  &.ui.dropdown > .text {
    color: #444444;
    line-height: 24px;

    font-weight: 100;

    padding-left: 5px;
    padding-right: 15px;
    padding-top: 3px;
    width: 100%;
  }

  &.dropdown {
    padding-top: 0;
    padding-bottom: 0;
  }

  &:hover {
    border-color: var(--bu-gray-500);
  }

  &.ui.dropdown.active {
    border-color: var(--bu-primary-500);
  }

  &.loading {
    background-color: #fffaca !important;
  }

  &.success {
    background-color: #cff6d7 !important;
  }

  &.error {
    background-color: #fce3dc !important;
  }

  &.ui.disabled.dropdown {
    border-color: var(--bu-gray-300);
  }

  &.ui.dropdown {
    &,
    &:hover,
    &.active {
      > .text::after {
        content: '\f0d7';
        display: inline-block;
        font-family: Icons;
        font-size: 10px;
        right: 5px;
        position: absolute;
      }
    }
  }
`;

export const tooltip = css`
  margin-left: 3px;
`;

export const btn_ClearCache = css`
  border-color: var(--bu-primary-500);
  color: var(--bu-primary-500);
  background: transparent;

  &:enabled {
    &:hover {
      background: transparent;
    }
  }
`;
