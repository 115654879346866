import styled from '@emotion/styled';

export const ColumnDropdownContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  margin: ' 0 0 12px',
  flexGrow: 1,
  '>.select': {
    width: '95%',
  },
});

export const MetricsFilterDescription = styled.div({
  margin: '4px 0px 24px 0px',
});

export const MetricsFilteDescriptionLong = styled.div({
  paddingTop: '8px',
});

export const FiltersContainer = styled.div({
  overflowY: 'auto',
  height: 'calc(100vh - 365px)',
  maxHeight: 'calc(100vh - 365px)',
});

export const AddFilterButtonContainer = styled.div({
  marginBottom: '16px',
});
