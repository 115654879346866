import * as t from 'actions/actionTypes';
import { get, create, update, remove } from 'sagas/generic';

export const DEFAULT_PAGE_SIZE = 5;

export const fetchAITrainingVersions = () => ({
  type: t.AI_TRAINING + t.VERSION + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/ai-training/versions/`,
  data: {
    page_size: DEFAULT_PAGE_SIZE,
    page_number: 0,
  },
  saga: get,
  success: (payload) => ({
    type: t.AI_TRAINING + t.VERSION + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.AI_TRAINING + t.VERSION + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.AI_TRAINING + t.VERSION + t.GET + t.LOADING,
  }),
});

export const paginateAITrainingVersions = (
  page,
  pageSize = DEFAULT_PAGE_SIZE
) => ({
  type: t.AI_TRAINING + t.VERSION + t.PAGINATION,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/ai-training/versions/`,
  data: {
    page_size: pageSize,
    page_number: page,
  },
  saga: get,
  success: (payload) => ({
    type: t.AI_TRAINING + t.VERSION + t.PAGINATION + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.AI_TRAINING + t.VERSION + t.PAGINATION + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.AI_TRAINING + t.VERSION + t.PAGINATION + t.LOADING,
  }),
});

export const deleteAITrainingDataVersion = (id) => ({
  type: t.AI_TRAINING + t.VERSION + t.REMOVE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/ai-training/versions/${id}/`,
  saga: remove,
  success: () => ({
    type: t.AI_TRAINING + t.VERSION + t.REMOVE + t.SUCCESS,
    payload: id,
  }),
  error: (e) => ({
    type: t.AI_TRAINING + t.VERSION + t.REMOVE + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.AI_TRAINING + t.VERSION + t.REMOVE + t.LOADING,
  }),
});

export const fetchAITrainingContent = (version, filters) => ({
  type: t.AI_TRAINING + t.CONTENT + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/ai-training/content/`,
  data: {
    ...filters,
    version,
    page_size: DEFAULT_PAGE_SIZE,
    page_number: 0,
  },
  saga: get,
  success: (payload) => ({
    type: t.AI_TRAINING + t.CONTENT + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.AI_TRAINING + t.CONTENT + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.AI_TRAINING + t.CONTENT + t.GET + t.LOADING,
  }),
});

export const toggleAITrainingShowFilters = () => ({
  type: t.AI_TRAINING + t.FILTER + t.TOGGLE,
});

export const setAITrainingCurrentVersion = (version) => ({
  type: t.AI_TRAINING + t.VERSION + t.SET,
  payload: version,
});
