import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as metricActions from 'actions/revbi/metrics';
import { Conditions } from 'components/dashboard/Metrics/Create/Conditions/Conditions';
import { getMetricType } from 'components/dashboard/Metrics/Create/MetricCreate/utils';
import { AdvancedOptions } from 'components/dashboard/Metrics/Create/MetricDefinition/MetricDefinitionInputs/AdvancedOptions/AdvancedOptions';
import { FormulaMetricInputs } from 'components/dashboard/Metrics/Create/MetricDefinition/MetricDefinitionInputs/FormulaMetricInputs';
import { MetricTypeSelection } from 'components/dashboard/Metrics/Create/MetricDefinition/MetricDefinitionInputs/MetricTypeSelection';
import { SimpleMetricsInputs } from 'components/dashboard/Metrics/Create/MetricDefinition/MetricDefinitionInputs/SimpleMetricsInputs/SimpleMetricsInputs';
import { parseFormulaToMetricsIdArray } from 'components/dashboard/Metrics/Widget/helper';
import { useMetricsList } from 'components/dashboard/Metrics/Widget/hooks/useMetricsList';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import {
  MetricType,
  BIMetricFormula,
} from 'components/dashboard/Metrics/metrics.types';
import * as metricSelectors from 'selectors/revbi/metrics';

export const MetricDefinitionInputs: React.FC = () => {
  const dispatch = useDispatch();

  const metric = useSelector(metricSelectors.getActiveMetric);

  const usedFormulaMetricIds = useMemo(
    () =>
      parseFormulaToMetricsIdArray(
        (metric as BIMetricFormula).synthetic_metric
      ),
    [(metric as BIMetricFormula).synthetic_metric]
  );

  const { data: usedMetricsMap } = useMetricsList(usedFormulaMetricIds);

  const updateMetricType = (type: MetricType): void => {
    dispatch(metricActions.switchTab(type));
  };

  const metricType = getMetricType(metric);

  useEffect(() => {
    if (
      metricType === MetricType.Simple &&
      metric.analysis_type === AnalysisType.LIVE
    ) {
      dispatch(metricActions.fetchColumnFieldsFilter([metric.object]));
    }
  }, [metricType, metric.object, metric.analysis_type]);

  useEffect(() => {
    if (
      metricType === MetricType.Formula &&
      metric.analysis_type === AnalysisType.LIVE
    ) {
      const objectsSet = new Set<string>();
      Object.keys(usedMetricsMap).forEach((element) => {
        const metricObject = usedMetricsMap[element].object;

        if (metricObject) {
          objectsSet.add(metricObject);
        }
      });

      dispatch(metricActions.fetchColumnFieldsFilter(Array.from(objectsSet)));
    }
  }, [metricType, metric.analysis_type, usedMetricsMap]);

  useEffect(() => {
    if (metric.analysis_type === AnalysisType.HISTORICAL) {
      dispatch(metricActions.fetchColumnFieldsFilterTimeSeries());
    }
  }, [metric.analysis_type]);

  return (
    <>
      <MetricTypeSelection
        metricType={metricType}
        updateMetricType={updateMetricType}
      />

      {metricType === MetricType.Formula && (
        <FormulaMetricInputs metric={metric as BIMetricFormula} />
      )}

      {metricType === MetricType.Simple && (
        <SimpleMetricsInputs metric={metric} />
      )}

      <Conditions metric={metric} />

      {metric.analysis_type === AnalysisType.LIVE &&
        metricType === MetricType.Simple && <AdvancedOptions metric={metric} />}
    </>
  );
};
