import styled from '@emotion/styled';
import debounce from 'lodash/debounce';
import { isNil } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';

import { UserSubmission } from 'actions/croOverrideActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import { fixedTo } from 'components/UI/TableConfig/column-helper';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import {
  CustomComponentFunction,
  getCellValue,
} from 'components/UI/common/TypedTable/renderers/custom/common';
import {
  MyCallCROCellConfig,
  SummaryButton,
  SummaryWrapper,
  Wrapper,
} from 'components/dashboard/CROOverride/cell-renderers/myCallCRO';
import { RowSubmission } from 'components/dashboard/CROOverride/types';

const Cell = styled.div`
  padding-left: 5px;
`;

const CopyPasteButton = styled.button`
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  height: 18px;
  width: 18px;
  border: 1px solid var(--bu-gray-500);
  background-color: var(--bu-white);
  padding: 0;
  text-align: center;
  font-size: 10px;
  cursor: pointer;

  &:hover {
    background-color: var(--bu-gray-200);
  }
`;

const NoDataCell = styled.div`
  text-align: right;
`;

export interface MyCallCellConfig
  extends Pick<MyCallCROCellConfig, 'onSelectDealsClick'> {
  copyFieldLabel: string;
  currencySymbol: string;
  symbolOnLeft: boolean;
  submitEnabledForRoles: string[];

  onCopyClick(column: IColumn, row: IRow): Promise<unknown>;
  currencyFormatter(value: number): string;
}
const myCallCell: CustomComponentFunction<MyCallCellConfig> = ({
  copyFieldLabel,
  currencySymbol,
  symbolOnLeft,
  onCopyClick,
  onSelectDealsClick,
  currencyFormatter,
  submitEnabledForRoles,
}) => (props) => {
  const [amountValue, setAmountValue] = useState<number | ''>('');
  const [isCopyPending, setCopyPending] = useState<boolean>(false);

  const { column, row, onChange = () => {} } = props;

  const { role, managerCanOverride } = row as RowSubmission;

  const submission = getCellValue(props) as UserSubmission;
  const {
    override_amount: amount,
    user_amount: lastSubmissionAmount,
    user_submission_id,
    override_included_deals_ids: includedDeals = [],
    override_excluded_deals_ids: excludedDeals = [],
    override_included_deals_amount: dealsAmount,
  } = submission ?? {};

  const isForecastExists = !isNil(submission);

  const dealsIncluded = includedDeals.length;
  const dealsTotal = dealsIncluded + excludedDeals.length;

  useEffect(() => {
    setAmountValue(amount === null || isNaN(amount) ? '' : amount);
  }, [amount]);

  const triggerChange = useCallback(
    debounce((value: number | null) => onChange(column, row, value), 500),
    [column, row]
  );

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.valueAsNumber;
    if (amountValue !== newValue) {
      setAmountValue(isNaN(newValue) ? '' : newValue);
      triggerChange(isNaN(newValue) ? null : newValue);
    }
  };

  const handleCopy = () => {
    if (user_submission_id) {
      setCopyPending(true);
      onCopyClick(column, row).finally(() => setCopyPending(false));
    }
  };

  const handleOpenDealsModal = () => {
    onSelectDealsClick(column, row);
  };

  if (!submitEnabledForRoles.includes(role)) {
    return <NoDataCell>-</NoDataCell>;
  }

  if (!managerCanOverride) {
    return null;
  }

  return (
    <Wrapper>
      {isForecastExists && (
        <TooltipWrapper
          tooltip={
            <span>
              Copy value from <b>{copyFieldLabel}</b>
            </span>
          }
        >
          <CopyPasteButton
            onClick={handleCopy}
            disabled={isCopyPending || isNil(lastSubmissionAmount)}
          >
            {isCopyPending ? (
              <Loader size="tiny" active inline />
            ) : (
              <BuIcon name={BoostUpIcons.ArrowRight} />
            )}
          </CopyPasteButton>
        </TooltipWrapper>
      )}

      <Cell>
        <BuInput
          type="currency"
          value={fixedTo(amountValue || 0, 2)}
          disabled={!isForecastExists || isCopyPending}
          prefix={symbolOnLeft ? currencySymbol : undefined}
          postfix={!symbolOnLeft ? currencySymbol : undefined}
          style={{ width: '100%' }}
          onChange={handleChange}
        />
      </Cell>

      <SummaryWrapper>
        <SummaryButton
          onClick={handleOpenDealsModal}
          disabled={!isForecastExists || isCopyPending}
        >
          {dealsIncluded} of {dealsTotal} ({currencyFormatter(dealsAmount ?? 0)}
          )
        </SummaryButton>
      </SummaryWrapper>
    </Wrapper>
  );
};

export default myCallCell;
