import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuDropdown, {
  BuDropdownItem,
  BuDropdownItemContainer,
} from 'components/UI/BuDropdown';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import { CreationType } from 'components/dashboard/Metrics/Create/constants';

const HeaderContainer = styled.div({
  padding: '16px 24px 16px 8px',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  '.bu-dropdown div': {
    color: 'var(--bu-gray-900)',
  },
  borderBottom: 'solid var(--bu-gray-400) 1px',
});

const NameInputContainer = styled.div({
  flexGrow: 1,
  cursor: 'pointer',
});

const NameNotEditing = styled.div({
  display: 'flex',
  alignItems: 'center',
  p: {
    margin: '0px 8px 0px 0px',
    fontWeight: 315,
    fontSize: '18px',
    lineHeight: '21.6px',
  },
  '.icon-button': {
    visibility: 'hidden',
    cursor: 'pointer',
    i: {
      fontSize: '20px',
    },
  },
  '&:hover': {
    '>.icon-button': {
      visibility: 'unset',
    },
  },
});

export const ActionDropdownText = styled.div({ minWidth: '60px' });

interface Props {
  id?: string;
  name?: string;
  type: CreationType;
  isSaveDisabled: boolean;
  onNameChange: (newName: string) => void;
  onClone: () => void;
  onDelete: () => void;
  onSave: () => void;
}

export const Header: React.FC<Props> = ({
  id,
  name,
  type,
  isSaveDisabled,
  onNameChange,
  onClone,
  onDelete,
  onSave,
}) => {
  const history = useHistory();

  const [isEditingName, setIsEditingName] = useState<boolean>(true);

  return (
    <HeaderContainer>
      <BuButton
        icon
        borderless
        onClick={() => {
          history.push(`/revbi/${type}s/list`);
        }}
        size={BuControlSize.SMALL}
      >
        <BuIcon name={BoostUpIcons.ArrowLeft} />
      </BuButton>
      <NameInputContainer onClick={() => setIsEditingName(true)}>
        {isEditingName || !name ? (
          <BuInput
            autoFocus
            placeholder={`Enter a ${type} Name`}
            type="text"
            value={name}
            onChange={(e) => {
              onNameChange(e.target.value);
            }}
            onBlur={() => setIsEditingName(false)}
            onFocus={() => setIsEditingName(true)}
            testingLabel="widget-name"
          />
        ) : (
          <NameNotEditing>
            <p>{name}</p>
            <div className="icon-button">
              <BuIcon name={BoostUpIcons.Pencil} />
            </div>
          </NameNotEditing>
        )}
      </NameInputContainer>
      {id && (
        <BuDropdown
          label={<BuIcon name={BoostUpIcons.MenuHoriz} />}
          icon
          noDropdownIcon
          borderless
          placement="bottom-end"
          testingLabel="existing-metric-actions"
        >
          {(hide) => (
            <BuDropdownItemContainer>
              <BuDropdownItem
                onClick={() => {
                  onClone();
                  hide();
                }}
              >
                {<ActionDropdownText>Clone</ActionDropdownText>}
              </BuDropdownItem>
              <BuDropdownItem
                onClick={() => {
                  onDelete();
                  hide();
                }}
              >
                {<ActionDropdownText>Delete</ActionDropdownText>}
              </BuDropdownItem>
            </BuDropdownItemContainer>
          )}
        </BuDropdown>
      )}
      <BuButton onClick={onSave} disabled={isSaveDisabled}>
        Save and Exit
      </BuButton>
    </HeaderContainer>
  );
};
