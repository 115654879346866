import { css } from 'emotion';

export const breakpoints = {
  mobile: '768px',
  tablet: '992px',
  desktop: '1200px',
};

export const mediaQueries = {
  mobile: `@media (min-width: ${breakpoints.mobile})`,
  tablet: `@media (min-width: ${breakpoints.tablet})`,
  desktop: `@media (min-width: ${breakpoints.desktop})`,
};
export const fontDisplay = css`
  font-family: var(--bu-font-medium);
  font-size: 24px;
`;

export const fontHeading = css`
  font-family: var(--bu-font-regular);
  font-size: 16px;
`;

export const fontDefaultDropdown = css`
  font-family: var(--bu-font-regular);
  font-size: 14px;
`;

export const fontDefaultDropdownSubtitle = css`
  font-family: var(--bu-font-regular);
  font-size: 14px;
`;

export const fontSubHeading = css`
  font-family: var(--bu-font-regular);
  font-size: 14px;
  text-transform: uppercase;
`;

export const fontDefault = css`
  font-family: var(--bu-font-regular);
  font-size: 14px;
`;

export const fontSubtitle = css`
  font-family: var(--bu-font-medium);
`;

export const fontCaption = css`
  font-family: var(--bu-font-regular);
  font-size: 12px;
`;

export const checkedIcon = css`
  -webkit-mask: url(${require('assets/fonts/boostup-icons/checked.svg')})
    no-repeat 100% 100%;
  mask: url(${require('assets/fonts/boostup-icons/checked.svg')}) no-repeat 100%
    100%;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: white;
`;
