import React from 'react';

import { FixedWidthContainer } from 'components/dashboard/Metrics/Create/Conditions/Condition/styles';
import { DateOperatorOption } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/types';
import { TargetTimePeriodCalendar } from 'components/dashboard/Metrics/common/TargetTimePeriodCalendar/TargetTimePeriodCalendar';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import { BIMetricsFilter } from 'components/dashboard/Metrics/metrics.types';

type Props = {
  filter: Partial<BIMetricsFilter>;
  targetPeriod: string;
  setFilter: (newFilter: Partial<BIMetricsFilter>) => void;
};

export const TargetPeriodTypeCondition: React.FC<Props> = ({
  filter,
  targetPeriod,
  setFilter,
}) => {
  const handleDateValueChange = (change: DateOperatorOption) => {
    const selectedValue = change.selected[0].id;
    setFilter({
      ...filter,
      operator: change.operator,
      value: selectedValue,
    });
  };

  return (
    <FlexRow>
      <FixedWidthContainer width={20}>in</FixedWidthContainer>
      <TargetTimePeriodCalendar
        selectedValue={filter.value as string}
        targetPeriod={targetPeriod}
        title="Target Time Period"
        onValueChange={handleDateValueChange}
      />
    </FlexRow>
  );
};
