import { useRef, useEffect, useState, RefObject } from 'react';
import { useSelector } from 'react-redux';

import { appDisplayMode } from 'selectors';

type Res = [RefObject<HTMLDivElement>, number];

const HEADER_HEIGHT = 50;

const calcOffset = (height: number, isHeaderVisible: boolean) =>
  isHeaderVisible ? height + HEADER_HEIGHT : height;

export const useTableOffset = (isMetricsExpanded = false): Res => {
  const displayMode = useSelector(appDisplayMode);
  const isHeaderVisible = !['embed', 'compact'].includes(displayMode);
  const [stateTimeout, setStateTimeout] = useState<NodeJS.Timeout | null>(null);
  const [offset, setOffset] = useState<number>(calcOffset(0, isHeaderVisible));
  const [prevMetrics, setPrevMetrics] = useState<boolean>(isMetricsExpanded);

  const ref = useRef<HTMLDivElement>(null);

  const offsetHeight = ref.current?.offsetHeight;

  useEffect(() => {
    if (offsetHeight) {
      setOffset(calcOffset(offsetHeight, isHeaderVisible));
    }
  }, [offsetHeight]);

  useEffect(() => {
    if (prevMetrics !== isMetricsExpanded) {
      if (stateTimeout) {
        clearTimeout(stateTimeout);
      }

      setStateTimeout(
        setTimeout(
          () => {
            if (ref.current) {
              setOffset(calcOffset(ref.current.offsetHeight, isHeaderVisible));
            }
          },
          isMetricsExpanded ? 1200 : 1400
        )
      );

      setPrevMetrics(isMetricsExpanded);
    }

    return () => {
      if (stateTimeout) {
        clearTimeout(stateTimeout);
      }
    };
  }, [ref, stateTimeout, prevMetrics, isMetricsExpanded]);

  return [ref, offset];
};
