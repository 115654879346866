import { MetricCreateSelectField } from './MetricCreateSelectField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { changeActiveMetric } from 'actions/revbi/metrics';
import * as metricActions from 'actions/revbi/metrics';
import BuRadio from 'components/UI/BuRadio';
import BuSelect from 'components/UI/BuSelect';
import BuSkeleton from 'components/UI/BuSkeleton';
import {
  inputsContainer,
  radiosContainer,
} from 'components/dashboard/Metrics/Create/MetricDefinition/MetricDefinitionInputs/styles';
import { OPPORTUNITY } from 'components/dashboard/Metrics/constants';
import { QUARTER } from 'components/dashboard/Metrics/constants';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIMetricColumn,
  BIMetricSimple,
  BIMetricSimpleNewborn,
  BIMetricUnion,
  PicklistOptions,
} from 'components/dashboard/Metrics/metrics.types';
import { IReduxState } from 'reducers/types';
import * as metricSelectors from 'selectors/revbi/metrics';
import { QueryStatus, fetchApi } from 'utils/network';

interface Props {
  metric: BIMetricUnion;
}

export const SimpleMetricsInputs: React.FC<Props> = ({ metric }) => {
  const dispatch = useDispatch();

  const [targetTypes, setTargetTypes] = useState<PicklistOptions[]>([]);
  const [targetPeriods, setTargetPeriods] = useState<PicklistOptions[]>([]);
  const [loadTargetPeriodStatus, setLoadTargetPeriodStatus] =
    useState<QueryStatus>('notAsked');

  const objectSelectorOptions = useSelector((state: IReduxState) =>
    metricSelectors.getObjectsListFormattedByAnalysisType(
      state,
      metric.analysis_type
    )
  );
  const columnList = useSelector((state: IReduxState) =>
    metricSelectors.getColumnFieldsByObject(state, metric.object)
  );

  const updateMetric = (metric: BIMetricUnion): void => {
    dispatch(changeActiveMetric(metric));
  };

  useEffect(() => {
    if (metric.analysis_type === AnalysisType.LIVE) {
      dispatch(metricActions.fetchObjectList());
    }

    if (metric.analysis_type === AnalysisType.HISTORICAL) {
      dispatch(metricActions.fetchTimeSeriesObjectList());
    }
  }, [metric.analysis_type]);

  useEffect(() => {
    dispatch(metricActions.fetchColumnFields(metric.object));
  }, [metric.object]);

  useEffect(() => {
    if (metric.object === 'target') {
      fetchApi<BIMetricColumn, PicklistOptions[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/company_settings/get_column_values`,
        queryMethod: 'post',
        queryParams: {
          label: 'Target Type',
          name: 'target.type',
          type: 'text',
        },
        setData: (result) => {
          setTargetTypes(result);
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load target type values: ${error}`);
        },
      });

      fetchApi<BIMetricColumn, PicklistOptions[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/company_settings/get_column_values`,
        queryMethod: 'post',
        queryParams: {
          label: 'Target Period',
          name: 'target.period',
          type: 'text',
        },
        setData: (result) => {
          setTargetPeriods(result);
        },
        setStatus: setLoadTargetPeriodStatus,
        setError: (error: string | null) => {
          toast.error(`Failed to load target period values: ${error}`);
        },
      });
    }
  }, [metric.object]);

  const handleChangeMetricObject = (values: string[]): void => {
    // changing object type require to clean up the query
    // because different object type allow different columns in the filter or formula
    if (values[0] !== metric.object) {
      updateMetric({
        ...metric,
        object: values[0],
        filters: [],
        column: undefined,
        cumulative_sum_period: undefined,
        is_cumulative_sum: false,
        target_period: values[0] === 'target' ? QUARTER : undefined,
        target_type: values[0] === 'target' ? 'ARR' : undefined,
        ...(values[0] === 'target' && { aggregation_function: undefined }),
      });
    }
  };

  const handleChangeTypeOfTarget = (values: string[]): void => {
    updateMetric({
      ...metric,
      target_type: values[0],
    });
  };

  const handleChangeTargetPeriod = (
    _: React.FormEvent<HTMLInputElement>,
    { value }: { value: string }
  ) => {
    const hasTargetTimePeriodCondition = metric.filters.some(
      (f) =>
        f?.and_condition?.[0].or_condition?.[0].column?.name ===
        'target.target_date'
    );
    updateMetric({
      ...metric,
      target_period: value,
      ...(hasTargetTimePeriodCondition && {
        filters: metric.filters.map((f) => {
          if (
            f?.and_condition?.[0].or_condition?.[0].column?.name !==
            'target.target_date'
          ) {
            return f;
          }

          return {
            ...f,
            and_condition: [
              {
                or_condition: [
                  {
                    column: f?.and_condition?.[0].or_condition?.[0].column,
                    operator: f?.and_condition?.[0].or_condition?.[0].operator,
                    value: '',
                  },
                ],
              },
            ],
          };
        }),
      }),
    });
  };

  return (
    <>
      <div className={inputsContainer} data-testing="object-section">
        <MetricCreateSubTitle>Object</MetricCreateSubTitle>
        <BuSelect
          fullWidth
          isLargePlaceholder
          secondary
          placeholder="Select a table"
          defaults={[metric.object ?? OPPORTUNITY]}
          options={objectSelectorOptions}
          onChange={handleChangeMetricObject}
        />
      </div>
      {metric.object === 'target' && (
        <>
          <div className={inputsContainer}>
            <MetricCreateSubTitle>Type of Target</MetricCreateSubTitle>
            <BuSelect
              fullWidth
              isLargePlaceholder
              secondary
              placeholder="Select a target type"
              defaults={[metric.target_type ?? 'ARR']}
              options={targetTypes.map((value: PicklistOptions) => ({
                text: value.display_name,
                value: value.value,
              }))}
              onChange={handleChangeTypeOfTarget}
            />
          </div>
          <div className={radiosContainer}>
            <MetricCreateSubTitle>Target Period</MetricCreateSubTitle>
            {loadTargetPeriodStatus === 'loading' && (
              <BuSkeleton height={25} width="100%" />
            )}
            {targetPeriods.map((period) => (
              <BuRadio
                key={period.value}
                label={period.display_name}
                checked={metric.target_period === period.value}
                value={period.value}
                onChange={handleChangeTargetPeriod}
              />
            ))}
          </div>
        </>
      )}

      {metric.object !== 'target' && (
        <MetricCreateSelectField
          columnList={columnList}
          metric={metric as BIMetricSimple | BIMetricSimpleNewborn}
          setMetric={updateMetric}
        />
      )}
    </>
  );
};
