import { css } from 'emotion';

export const container = css`
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 2px;
  background-color: #f7f7f7;
`;

export const audioplayer__audiotrack_parent = css`
  background: var(--bu-white);
  border: 1px solid var(--bu-gray-300);
  margin-top: -1px;
  border-right: none;
  border-left: none;
  padding: 0 20px;
  position: relative;
  height: 100px;
  box-sizing: border-box;
`;

export const play_background_line = css`
  position: absolute;
  left: 0;
  top: 0;
  height: 98px;
  width: 1108px;
  background: #c4e8ff;
  content: '';
  opacity: 0.3;
  transition: 0.2s ease-out;
  z-index: 1;
  border-right: 1px solid #9b9b9b;
`;

export const audioplayer__controls = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 8px 0;
  height: 74px;
  background-color: var(--bu-white);
`;

export const audio_controls = css`
  display: flex;
  align-items: center;
`;

export const participant = css`
  color: #4a4a4a;
  font-size: 14px;
  font-family: var(--bu-font-regular);
  margin-right: 10px;
  display: flex;
  align-items: center;
  span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
  }
`;

export const participants = css`
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
`;

export const player_timer = css`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

export const prev_track = css`
  height: 30px;
  padding: 5px 30px 5px 11px;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 15px;
  display: block;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: #666666;
  font-size: 13px;
  font-family: var(--bu-font-regular);
  line-height: 19px;
  &:after {
    position: absolute;
    content: '';
    right: -17px;
    top: -6px;
    width: 40px;
    height: 40px;
    background: url(${require('../../../assets/images/new_icon/skipe_toprev.svg')});
    background-size: contain;
  }
`;

export const next_track = css`
  height: 30px;
  padding: 5px 11px 5px 30px;
  text-align: right;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 15px;
  display: block;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: #666666;
  font-size: 13px;
  font-family: var(--bu-font-regular);
  line-height: 19px;
  &:after {
    position: absolute;
    content: '';
    left: -17px;
    top: -6px;
    width: 40px;
    height: 40px;
    background: url(${require('../../../assets/images/new_icon/skip_tonext.svg')});
    background-size: contain;
  }
`;

export const pause_icon = css`
  width: 50px;
  height: 50px;
  margin: 0 50px;
  background: url(${require('../../../assets/images/new_icon/pause.svg')});
  background-size: contain;
  cursor: pointer;
`;

export const play_icon = css`
  width: 50px;
  height: 50px;
  margin: 0 50px;
  background: url(${require('../../../assets/fonts/boostup-icons/play.svg')});
  background-size: contain;
  cursor: pointer;
`;

export const track_speed = css`
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-right: 20px;
`;

export const play_timer = css`
  display: flex;
`;

export const play_timer_start = css`
  margin-right: 5px;
`;
