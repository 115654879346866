import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { StringParam, useQueryParam } from 'use-query-params';

import * as metricActions from 'actions/revbi/metrics';
import * as widgetActions from 'actions/revbi/widgets';
import checklistPlaceholder from 'assets/images/checklist_placeholder.png';
import developerPlaceholder from 'assets/images/developer_placeholder.png';
import funnelPlaceholder from 'assets/images/funnel_placeholder.png';
import pieChartPlaceholder from 'assets/images/pie_chart_placeholder.png';
import { Header } from 'components/dashboard/Metrics/Create/Header';
import { WidgetCreateOptions } from 'components/dashboard/Metrics/Create/WidgetCreateOptions';
import {
  CreationType,
  FUNNEL_PREDEFINED_METRICS,
  FUNNEL_PREDEFINED_TOP_METRICS,
  OptionSections,
} from 'components/dashboard/Metrics/Create/constants';
import { areFiltersValid } from 'components/dashboard/Metrics/Create/helpers';
import {
  FlexColumn,
  WidgetPreviewColumn,
  WidgetPreviewPlaceholder,
  WidgetPreview,
  SectionTitle,
} from 'components/dashboard/Metrics/Create/widget.create.styles';
import { RevBiQuickView } from 'components/dashboard/Metrics/QuickView/RevBiQuickView';
import { FunnelWidgetPreview } from 'components/dashboard/Metrics/Widget/FunnelWidget/FunnelWidgetPreview';
import { HistoricalWidgetPreview } from 'components/dashboard/Metrics/Widget/HistoricalWidgetPreview';
import { MetricsWidgetPreview } from 'components/dashboard/Metrics/Widget/MetricsWidgetPreview';
import { ReportWidgetPreview } from 'components/dashboard/Metrics/Widget/ReportWidgetPreview';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { SidebarType } from 'components/dashboard/Metrics/constants';
import { UserSettingsProvider } from 'components/dashboard/Metrics/contexts/UserSettingsContext';
import { UsersByActivityProvider } from 'components/dashboard/Metrics/contexts/UsersByActivityContext';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIMetricCreated,
  BIMetricFormula,
  BIMetrics,
  BIMetricSimple,
  BIWidget,
  MetricType,
} from 'components/dashboard/Metrics/metrics.types';
import { IReduxState } from 'reducers/types';
import * as metricSelectors from 'selectors/revbi/metrics';
import * as widgetSelectors from 'selectors/revbi/widgets';

export const WidgetCreate: React.FC = () => {
  const dispatch = useDispatch();

  const match = useRouteMatch<{ widgetId: string }>();

  // local states
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [isTopMetricsSidebarOpen, setIsTopMetricsSidebarOpen] =
    useState<boolean>(false);
  const [isCreateNewMetric, setIsCreateNewMetric] = useState<boolean>(false);
  const [sectionExpanded, setSectionExpanded] = useState<OptionSections>(() =>
    match.params.widgetId ? OptionSections.METRIC : OptionSections.TYPE
  );

  const [saveRedirect] = useQueryParam('saveRedirect', StringParam);
  const [addWidgetToDashboard] = useQueryParam(
    'addWidgetToDashboard',
    StringParam
  );

  // selectors
  const activeMetric = useSelector(metricSelectors.getActiveMetric);
  const widget = useSelector((state: IReduxState) =>
    widgetSelectors.getActiveWidget(state, match.params.widgetId)
  );
  const activeWidgetMetrics = useSelector((state: IReduxState) =>
    widgetSelectors.getActiveWidgetMetricsFromList(state, match.params.widgetId)
  );

  const metricList = useSelector(metricSelectors.getMetricsList);
  const historicalMetricList = useSelector(metricSelectors.getMetricsTSList);

  const loadStatus = useSelector(widgetSelectors.getWidgetFetchByIdStatus);

  const updateWidget = (widget: Partial<BIWidget>): void => {
    dispatch(
      widgetActions.changeWidgetInStore({
        widget,
        widgetId: match.params.widgetId,
      })
    );
  };

  const hasValidFilters: boolean = useMemo(
    () => areFiltersValid(activeMetric.filters || []),
    [activeMetric.filters]
  );

  const [canSave, canPreview]: boolean[] = useMemo(() => {
    const metricType = activeMetric.metadata?.type ?? MetricType.Simple;

    if (metricType === MetricType.Simple) {
      const canPreview =
        widget.analysis_type === AnalysisType.FUNNEL
          ? widget.funnel_stage_column !== undefined &&
            (widget.funnel_stages ?? []).length !== 0
          : Boolean(
              activeWidgetMetrics.length ||
                ((activeMetric as BIMetricSimple)?.aggregation_function &&
                  (activeMetric as BIMetricSimple)?.column) ||
                (activeMetric as BIMetricSimple)?.aggregation_function ===
                  'count' ||
                (activeMetric as BIMetricSimple)?.object === 'target'
            );

      const isInvalidWidget = !(
        widget?.name &&
        (activeWidgetMetrics?.length ||
          widget.analysis_type === AnalysisType.FUNNEL)
      );
      const canSave = !isInvalidWidget;

      return [canSave, canPreview];
    } else if (metricType === MetricType.Formula) {
      const canSave = Boolean(
        (activeMetric as BIMetricFormula).synthetic_metric
      );
      const canPreview = Boolean(
        (activeMetric as BIMetricFormula).synthetic_metric
      );

      return [canSave, canPreview];
    } else {
      return [false, false];
    }
  }, [widget, activeMetric]);

  const [
    canSaveReportWidget,
    canPreviewReportWidget,
    isValidReportViewInput,
  ]: boolean[] = useMemo(() => {
    const canPreview = Boolean(
      (sectionExpanded === OptionSections.METRIC ||
        sectionExpanded === OptionSections.TEMPLATE_FILTERS) &&
        widget?.order_by_column?.name
    );
    const isValidInput = Boolean(
      widget.analysis_type === AnalysisType.REPORT &&
        canPreview &&
        widget?.limit &&
        widget?.limit > 0 &&
        widget?.limit < 101 &&
        (!activeMetric?.filters?.length ||
          activeMetric?.filters?.every((f) =>
            f.and_condition?.every((condition) =>
              condition?.or_condition?.every(
                (cond) => cond.value && cond.operator
              )
            )
          ))
    );
    const canSave = Boolean(widget?.name && isValidInput);

    return [canSave, canPreview, isValidInput];
  }, [activeMetric, widget, sectionExpanded]);

  const selectedMetricsIds: string[] = useMemo(
    () =>
      activeWidgetMetrics?.reduce(
        (results: string[], item: BIMetricCreated) => {
          if (item?._id) results.push(item._id);
          return results;
        },
        []
      ) || [],
    [activeWidgetMetrics]
  );

  useEffect(() => {
    if (match.params.widgetId) {
      dispatch(widgetActions.fetchWidgetByIdExtended(match.params.widgetId));
    }
    dispatch(metricActions.fetchAllMetrics());
    dispatch(metricActions.fetchAllTSMetrics());

    return () => {
      dispatch(metricActions.cleanUpMetricCreationState());
    };
  }, []);

  // Metric Interactions
  const handleAddMetric = (metric: BIMetricCreated): void => {
    dispatch(
      widgetActions.addMetricToMetricsFromList({
        metric,
        widgetId: match.params.widgetId,
      })
    );
  };

  const handleAddTopMetric = (metric: BIMetricCreated): void => {
    updateWidget({
      ...widget,
      funnel_top_metrics: [...(widget.funnel_top_metrics || []), metric._id],
    });
  };

  const handleSaveWidget = (): void => {
    let widgetData: BIWidget = widget;
    if (widget.analysis_type === AnalysisType.REPORT) {
      // if it is report widget → put metric inside
      widgetData = { ...widgetData, metric_list: [activeMetric] };
    }
    if ('_id' in widget) {
      dispatch(
        widgetActions.updateWidgetExtended(
          widgetData,
          addWidgetToDashboard,
          saveRedirect ?? '/revbi/widgets/list'
        )
      );
    } else {
      dispatch(
        widgetActions.createWidgetExtended(
          widgetData,
          addWidgetToDashboard,
          saveRedirect ?? '/revbi/widgets/list'
        )
      );
    }
  };

  const handleCloneAddMetric = (id: string): void => {
    if (id) {
      dispatch(
        metricActions.cloneMetric({
          id,
          addToWidget: match.params.widgetId ? 'edit' : 'create',
        })
      );
    }
  };

  const handleCreateNewMetric = (): void => {
    setIsCreateNewMetric(true);
    setIsSidebarOpen(false);
  };

  const handleNameChange = (newName: string): void => {
    updateWidget({ ...widget, name: newName });
  };

  if (
    match.params.widgetId &&
    widget.name &&
    (loadStatus === 'loading' || loadStatus === 'notAsked')
  ) {
    return <Loader active content="Loading" />;
  }

  return (
    <UsersByActivityProvider includeDisabledUsers={false}>
      <UserSettingsProvider>
        <FlexColumn>
          <Header
            id={widget?.id}
            name={widget?.name}
            type={CreationType.WIDGET}
            isSaveDisabled={!canSave && !canSaveReportWidget}
            onNameChange={handleNameChange}
            onClone={() => {}}
            onDelete={() => {}}
            onSave={handleSaveWidget}
          />
          <FlexRow cssProps={{ height: '100%' }}>
            <WidgetCreateOptions
              sectionExpanded={sectionExpanded}
              widget={widget}
              metric={activeMetric}
              metricsFromList={activeWidgetMetrics}
              isValidReportViewInput={isValidReportViewInput}
              isCreateNewMetric={isCreateNewMetric}
              updateWidget={updateWidget}
              setSectionExpanded={setSectionExpanded}
              setIsCreateNewMetric={setIsCreateNewMetric}
              setIsSidebarOpen={setIsSidebarOpen}
              setIsTopMetricsSidebarOpen={setIsTopMetricsSidebarOpen}
            />
            <WidgetPreviewColumn>
              {widget.analysis_type === AnalysisType.REPORT ? (
                <>
                  {!canPreviewReportWidget && (
                    <WidgetPreviewPlaceholder data-testing="widget-preview-section">
                      <img src={checklistPlaceholder} width="250px" />
                      <SectionTitle>Create a custom report</SectionTitle>
                      <div>
                        Choose the object, conditions and settings to filter and
                        order the desired list of items.
                      </div>
                    </WidgetPreviewPlaceholder>
                  )}

                  {canPreviewReportWidget && (
                    <WidgetPreview data-testing="widget-preview-section">
                      <ReportWidgetPreview
                        widget={widget}
                        metric={activeMetric as BIMetrics}
                        updateWidget={updateWidget}
                      />
                    </WidgetPreview>
                  )}
                </>
              ) : (
                <>
                  {!canPreview && (
                    <WidgetPreviewPlaceholder data-testing="widget-preview-section">
                      <img
                        src={
                          widget.analysis_type === AnalysisType.HISTORICAL
                            ? pieChartPlaceholder
                            : widget.analysis_type === AnalysisType.FUNNEL
                            ? funnelPlaceholder
                            : developerPlaceholder
                        }
                        width="250px"
                      />
                      <SectionTitle>
                        {widget.analysis_type == AnalysisType.HISTORICAL &&
                          'Create a historical analysis'}
                        {widget.analysis_type == AnalysisType.LIVE &&
                          'Metrics with live data'}
                        {widget.analysis_type == AnalysisType.FUNNEL &&
                          'Funnel definition'}
                      </SectionTitle>
                      <div>
                        {widget.analysis_type == AnalysisType.HISTORICAL &&
                          'Setup a metric with a point-in-time snapshot to compare changes over time'}
                        {widget.analysis_type == AnalysisType.LIVE &&
                          'Analyse metrics over pivots of any field using live data'}
                        {widget.analysis_type == AnalysisType.FUNNEL &&
                          'Define the stages of your funnel that you want to analyze and their order'}
                      </div>
                    </WidgetPreviewPlaceholder>
                  )}

                  {canPreview && (
                    <WidgetPreview data-testing="widget-preview-section">
                      {widget.analysis_type === AnalysisType.LIVE && (
                        <MetricsWidgetPreview
                          isCreateEditMetric={isCreateNewMetric}
                          hasValidFilters={hasValidFilters}
                          metricsFromList={[...activeWidgetMetrics]}
                          updateWidget={updateWidget}
                        />
                      )}

                      {widget.analysis_type === AnalysisType.HISTORICAL && (
                        <HistoricalWidgetPreview
                          key={`${widget.id}-${widget.metric_list}`}
                          isCreateEditMetric={isCreateNewMetric}
                          hasValidFilters={hasValidFilters}
                          metricsFromList={[...activeWidgetMetrics]}
                          updateWidget={updateWidget}
                        />
                      )}

                      {widget.analysis_type === AnalysisType.FUNNEL && (
                        <FunnelWidgetPreview updateWidget={updateWidget} />
                      )}
                    </WidgetPreview>
                  )}
                </>
              )}
            </WidgetPreviewColumn>
          </FlexRow>
        </FlexColumn>

        {isSidebarOpen && (
          <RevBiQuickView
            isOpen={isSidebarOpen}
            list={
              widget.analysis_type === AnalysisType.HISTORICAL
                ? historicalMetricList
                : widget.analysis_type === AnalysisType.FUNNEL
                ? FUNNEL_PREDEFINED_METRICS
                : metricList
            }
            analysisType={widget?.analysis_type as AnalysisType}
            sidebarType={SidebarType.METRICS}
            selectedIds={selectedMetricsIds}
            onClose={() => setIsSidebarOpen(false)}
            onAdd={handleAddMetric}
            onCloneAddMetric={handleCloneAddMetric}
            onCreateNew={handleCreateNewMetric}
          />
        )}

        {isTopMetricsSidebarOpen && (
          <RevBiQuickView
            isOpen={isTopMetricsSidebarOpen}
            list={FUNNEL_PREDEFINED_TOP_METRICS}
            analysisType={widget?.analysis_type as AnalysisType}
            sidebarType={SidebarType.METRICS}
            selectedIds={widget.funnel_top_metrics}
            onClose={() => setIsTopMetricsSidebarOpen(false)}
            onAdd={handleAddTopMetric}
            onCloneAddMetric={() => {}}
            onCreateNew={() => {}}
          />
        )}
      </UserSettingsProvider>
    </UsersByActivityProvider>
  );
};
