import { SubmissionColumn } from 'actions/croOverrideActions';
import { formatMoney } from 'common/numbers';
import { IColumn, SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import {
  changeField,
  SwitchComponentOptions,
  switchComponents,
} from 'components/UI/common/TypedTable/renderers/custom';
import {
  forecast,
  forecastCRO,
  myCallHistory,
  summarizeChildren,
} from 'components/dashboard/CROOverride/cell-renderers';
import { cellBackground } from 'components/dashboard/CROOverride/styles';
import {
  RowSubmission,
  RowTypeFieldName,
} from 'components/dashboard/CROOverride/types';
import { SubmissionForecastState } from 'reducers/croOverrideReducer';

const FORECAST_COLUMN_WIDTH = 200;

type GetColumns = (config: {
  companyCurrency: string;
  submissionForecasts: SubmissionForecastState;
  activeBusinessType?: string;
  periodSelected?: string[];
}) => IColumn[];

/**
 * Returns Submission TypedTable column list configuration
 */
export const getColumns: GetColumns = ({
  companyCurrency,
  submissionForecasts,
  activeBusinessType,
  periodSelected = [],
}): IColumn[] => {
  const currencyFormatter = formatMoney.bind(formatMoney, companyCurrency);

  /**
   * Returns pairs of forecast and my call columns
   */
  const getColumnPair = (column: SubmissionColumn): [IColumn, IColumn] => {
    const {
      display_name: label,
      business_type: businessType,
      submission_settings_id: field,
      forecast_name: forecastName,
      period_interval: period,
    } = column;

    const fieldPath: MapPaths.Paths<Pick<RowSubmission, 'submissions'>> = [
      'submissions',
      field,
    ];
    const fieldPathString = fieldPath.join('.');

    return [
      {
        id: `${businessType}-${field}`,
        label: label,
        labelForceWrap: true,
        field: RowTypeFieldName,
        type: ColumnTypes.CUSTOM,
        sort_order: SortOrder.ASCENDING,
        width: FORECAST_COLUMN_WIDTH,
        minWidth: FORECAST_COLUMN_WIDTH,
        maxWidth: FORECAST_COLUMN_WIDTH,
        config: {
          className: cellBackground,
          renderer: switchComponents({
            CRO: changeField(
              forecastCRO({
                companyCurrency: companyCurrency,
                currencyFormatter: currencyFormatter,
                period,
                businessType,
                forecastName,
              }),
              fieldPathString
            ),
            User: changeField(
              forecast({
                companyCurrency: companyCurrency,
                currencyFormatter: currencyFormatter,
                period,
                businessType,
                forecastName,
              }),
              fieldPathString
            ),
          } as SwitchComponentOptions<RowSubmission[RowTypeFieldName]>),
        } as CustomCellConfig,
      },
      {
        id: `${businessType}-${field}-my_call`,
        label: `${label}\nMy Call`,
        labelForceWrap: true,
        field: RowTypeFieldName,
        type: ColumnTypes.CUSTOM,
        sort_order: SortOrder.ASCENDING,
        width: FORECAST_COLUMN_WIDTH,
        minWidth: FORECAST_COLUMN_WIDTH,
        maxWidth: FORECAST_COLUMN_WIDTH,
        editable: false,
        config: {
          renderer: switchComponents({
            CRO: summarizeChildren({
              sumChildField: ['submissions', field, 'override_amount'],
              currencyFormatter: currencyFormatter,
            }),
            User: changeField(
              myCallHistory({
                currencyFormatter: currencyFormatter,
              }),
              fieldPathString
            ),
          } as SwitchComponentOptions<RowSubmission[RowTypeFieldName]>),
        } as CustomCellConfig,
      },
    ];
  };

  /**
   * Create and returns business type grouped columns
   */
  const getBusinessTypeColumns = (): IColumn[] =>
    submissionForecasts.columns
      .sort((a, b) => a.order - b.order)
      .filter(
        (item) =>
          (!activeBusinessType ||
            activeBusinessType === 'Overall' ||
            item.business_type === activeBusinessType) &&
          (!periodSelected.length || periodSelected.includes(item.period_info))
      )
      .map<IColumn[]>(getColumnPair)
      .flat();

  return [
    {
      id: 'user',
      label: 'Sales Org',
      field: 'name',
      type: ColumnTypes.TEXT,
      sort_order: SortOrder.ASCENDING,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      config: {
        subValue: {
          relativeField: 'role',
          template: '',
        },
      },
    },
    ...getBusinessTypeColumns(),
  ];
};
