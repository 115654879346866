import React, { Dispatch, SetStateAction } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { TemplateFilter } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/TemplateFilter';
import {
  FilterBarELement,
  FiltersContainer,
  FiltersDropdownsContainer,
} from 'components/dashboard/Metrics/Widget/TemplateFilters/styles';
import {
  BIMetricColumn,
  BIMetricsFilter,
  BIWidget,
  DateOption,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  isDateFirstPivot?: boolean;
  showControls?: boolean;
  templateFilters?: BIMetricsFilter[];
  widgetFilters?: BIMetricColumn[];
  relativeDateFilterOptions?: DateOption[];
  timeInterval?: string;
  timePeriod?: string;
  targetPeriod?: string;
  setWidget: Dispatch<SetStateAction<Partial<BIWidget>>>;
}

export const TemplateFilters: React.FC<Props> = ({
  isDateFirstPivot = false,
  showControls = false,
  templateFilters = [],
  widgetFilters = [],
  relativeDateFilterOptions = [],
  timeInterval,
  timePeriod,
  targetPeriod,
  setWidget,
}) => (
  <>
    {!!widgetFilters.length && (
      <FiltersContainer
        showControls={showControls}
        data-testing="filters-container"
      >
        <FilterBarELement>
          <BuIcon name={BoostUpIcons.Filter} color="var(--bu-gray-600)" />
        </FilterBarELement>
        <FiltersDropdownsContainer>
          {widgetFilters.map((widgetFilter, idx) => {
            if (widgetFilter && widgetFilter.name && widgetFilter.type) {
              return (
                <FilterBarELement
                  key={`${widgetFilter.type}_${widgetFilter.name}_${widgetFilter.label}`}
                  data-testing={`filter-bar-element-${idx}`}
                >
                  <TemplateFilter
                    isDateFirstPivot={isDateFirstPivot}
                    widgetFilter={widgetFilter}
                    templateFilters={templateFilters}
                    relativeDateFilterOptions={relativeDateFilterOptions}
                    timeInterval={timeInterval}
                    timePeriod={timePeriod}
                    targetPeriod={targetPeriod}
                    setWidget={setWidget}
                  />
                </FilterBarELement>
              );
            }
          })}
        </FiltersDropdownsContainer>
      </FiltersContainer>
    )}
  </>
);
