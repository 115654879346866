import tableStyles from '../../account-insights/styles';
import { css } from 'emotion';

export const styles = {
  table: css`
    margin-top: 20px;
    margin-bottom: 20px;
    color: var(--bu-gray-900);
  `,
  table_header: css`
    z-index: 1;
    position: relative;

    th {
      border-bottom: 1px solid var(--bu-gray-500);
    }
  `,
  table_header_column: css`
    padding: 0 10px;
    color: var(--bu-gray-700);
    position: relative;
  `,
  table_header_first_column: css`
    min-width: 120px;
  `,
  table_body_row: css`
    line-height: 20px;
    td {
      border-bottom: 1px solid var(--bu-gray-500);
    }

    &:hover {
      background: var(--bu-gray-200);
    }

    &:last-child td {
      border-bottom: none;
    }
  `,
  table_row_colum: css`
    padding: 5px 10px;
  `,
  tabs_container: css`
    display: flex;
    margin-bottom: 10px;
  `,
  buttons_box_content: css`
    display: flex;
    justify-content: flex-end;
    width: 100%;
  `,
  section_header: css`
    color: var(--bu-gray-900);
    display: flex;
    align-items: center;
    margin-top: 10px;
  `,
  tab: tableStyles.tab,
  date_call: css`
    display: block;
  `,
  attendees: css``,
  attendee: css`
    line-height: 1.2rem;

    & + & {
      margin-top: 0.5rem;
    }
  `,
  attendee_name: css`
    overflow-wrap: anywhere;
    word-break: break-word;
  `,
  attendeeDeclined: css`
    label: attendee-declined;
    color: #ee724e !important;
    display: inline-block;
  `,
  attendeeAccepted: css`
    label: attendee-accepted;
    color: #61e37d !important;
    display: inline-block;
  `,
  attendeeNotResponded: css`
    label: attendee-accepted;
    color: #000000 !important;
    display: inline-block;
  `,
  attendeeTitle: css`
    font-weight: 600;
    display: inline-block;
  `,
  subject: css`
    line-height: 20px;
    font-weight: 800;
    padding-right: 20px;
    cursor: pointer;

    :hover {
      color: var(--bu-primary-500);
    }
  `,
  email_subject: css`
    font-size: 24px;
  `,
  play_call_btn: css`
    background: none;
    border: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  `,
  play_call_icon: css`
    width: 40px;
    height: 40px;
  `,
  no_show: css`
    display: block;
    color: #f74544;
  `,
  position: css`
    font-weight: 600;
    line-height: 20px;

    &.red {
      color: #f74544;
    }

    &.green {
      color: #0ba5a3;
    }
  `,
  graph: css`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  `,
  attendee_btn_more: css`
    border-radius: 15px;
    background: none;
    padding: 3px 15px;
    cursor: pointer;
    color: #4a4a4a;
    margin-top: 10px;
    background: #fff;
    border: 1px solid #ccc;
  `,
  blue_link_more: css`
    color: var(--bu-primary-500);
    cursor: pointer;
    text-decoration: underline;
  `,
  cursor: css`
    cursor: pointer;

    color: var(--bu-clickable-cell);

    tr:hover & {
      color: var(--bu-clickable-cell__hover);
    }
  `,
  MeetingFollowUpEmail: css`
    word-break: break-word;
  `,
  primaryCell: css`
    font-weight: 600;
  `,
  nothing_found: css`
    padding: 30px;
    font-weight: 100;
    color: var(--bu-gray-700);
    text-align: center;
  `,
  new_table: css`
    box-sizing: border-box;
    border: 1px solid var(--bu-gray-500);
    border-radius: 5px;
    background-color: var(--bu-white);
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  `,
  table_sticky_header: css`
    border-collapse: separate !important;

    & thead tr:first-child > th {
      position: sticky !important;
      top: 0;
      background-color: var(--bu-gray-200) !important;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  `,
  group_button_container: css`
    z-index: 10000;
  `,
};

export default styles;
