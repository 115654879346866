import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import axios from 'axios';
import { getLocation, push, replace } from 'connected-react-router';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import { hotjar } from 'react-hotjar';
import { all, put, select, call, takeEvery, fork } from 'redux-saga/effects';

import { actions } from 'actions';
import * as t from 'actions/actionTypes';
import * as adminActions from 'actions/adminActions';
import { initApp } from 'actions/initActions';
import * as routeActions from 'actions/routeActions';
import * as settingsActions from 'actions/settingsActions';
import * as usersActions from 'actions/userActions';
import errorHandler from 'helpers/errorHandler';
import * as selectors from 'selectors';

const isDevelopment = process.env.NODE_ENV !== 'production';
const HOTJAR_ID = process.env.REACT_APP_HOTJARID;

function* initHotjar() {
  const isActiveHotjarService = yield select(selectors.isActiveHotjarService);

  if (HOTJAR_ID && isActiveHotjarService) {
    hotjar.initialize(process.env.REACT_APP_HOTJARID, 6);
  }
}

function* identifyHotjar() {
  const user = yield select(selectors.getUser);

  if (HOTJAR_ID && user) {
    hotjar.identify('USER_ID', { userProperty: user.email });
  }
}

function* hotjarToggled({ payload: enabled }) {
  if (enabled) {
    yield fork(initHotjar);
    yield fork(identifyHotjar);
  }
}

export default function* init() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });

  yield fork(initHotjar);

  try {
    const sfdcError = Cookies.get('sfdc-canvas-app-info');

    if (sfdcError) {
      return yield put(replace('/error'));
    }

    const params = queryString.parse(window.location.search);
    const { needPersonate, auth_token: accessToken } = params;

    if (accessToken) {
      const headers = {
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const meResponse = yield call(
        axios.get,
        `${process.env.REACT_APP_BACKEND_URL}/api/user/me`,
        headers
      );

      yield put(
        usersActions.createUserAction.updateState({
          apiSuccess: meResponse,
          role: meResponse.data.data.role,
          user_id: meResponse.data.data.user_id,
          name: meResponse.data.data.name,
          email: meResponse.data.data.email,
        })
      );
      yield put(
        adminActions.createAdminAction.updateState({
          email_impersonation: meResponse.data.data.email,
          email_domain: meResponse.data.data.email_domain,
          is_admin: meResponse.data.data.is_admin,
          is_user_impersonation_allowed:
            meResponse.data.data.is_user_impersonation_allowed,
        })
      );
    }

    if (needPersonate) {
      const meResponse = yield call(
        axios.get,
        `${process.env.REACT_APP_BACKEND_URL}/api/user/me`,
        { withCredentials: true }
      );

      yield put(
        usersActions.createUserAction.updateState({
          apiSuccess: meResponse,
          role: meResponse.data.data.role,
          user_id: meResponse.data.data.user_id,
          name: meResponse.data.data.name,
          email: meResponse.data.data.email,
        })
      );
      yield put(
        adminActions.createAdminAction.updateState({
          email_impersonation: meResponse.data.data.email,
          email_domain: meResponse.data.data.email_domain,
          is_admin: meResponse.data.data.is_admin,
          is_user_impersonation_allowed:
            meResponse.data.data.is_user_impersonation_allowed,
        })
      );
    }

    const { user, pathname, isActiveHotjarService } = yield select((state) => ({
      user: selectors.getUser(state),
      pathname: getLocation(state).pathname,
      isActiveHotjarService: selectors.isActiveHotjarService(state),
    }));

    if (user.email && !user.apiError) {
      Sentry.configureScope((scope) => {
        scope.setUser({ email: user.email });
      });

      if (isActiveHotjarService) {
        yield fork(identifyHotjar);
      }

      const { isImpersonate, isAdmin } = yield select((state) => ({
        isImpersonate: selectors.isImpersonate(state),
        isAdmin: selectors.isAdmin(state),
      }));

      const visitorId = user.email;
      const accountId = user.email ? user.email.split('@')[1] : undefined;

      if (!isAdmin && !isImpersonate && !!window.pendo) {
        window.pendo.initialize({
          visitor: {
            company: accountId,
            email: user.email,
            full_name: user.name,
            id: isDevelopment ? `development_${visitorId}` : visitorId,
            isAdmin,
            role: user.role,
          },
          account: {
            id: isDevelopment ? `development_${accountId}` : accountId,
          },
        });
        window.ChurnZero.push([
          'setAppKey',
          process.env.REACT_APP_CHURN_ZERO_APP_KEY,
        ]);
        window.ChurnZero.push([
          'setContact',
          isDevelopment ? `development_${accountId}` : accountId,
          isDevelopment ? `development_${visitorId}` : visitorId,
        ]);
      }

      yield all([
        put(routeActions.getRouterConfig({ read_only: user.read_only })),
        put(actions.ui.filters.init({ role: user.role, email: user.email })),
        put(actions.ui.preferences.user.get()),
      ]);

      yield put(settingsActions.getCompanySettings());
      yield put(settingsActions.getBusinessTypesConfig());
      yield put(settingsActions.getCompanyIsActiveSettings());
    } else if (
      !pathname.includes('login') &&
      !pathname.includes('admin') &&
      !pathname.includes('sign-up')
    ) {
      yield put(replace('/login'));
    }

    yield put(usersActions.appInitiated());
  } catch (e) {
    const nextUrl = errorHandler(e);
    if (nextUrl) {
      yield put(nextUrl);
    }
  }
}

function* initAdmin() {
  yield put(actions.ui.manageForecast.resetSubmissionSettings());
  yield put(initApp());
  yield put(push('/'));
}

export function* initSaga() {
  yield takeEvery([t.ADMIN + t.IMPERSONATE + t.SUCCESS + t.INIT], initAdmin);
  yield takeEvery(actions.ui.hotjarService.toggleHotjarService, hotjarToggled);
}
