import { QUARTER_DELIMITER_COLUMN } from './styles';
import classNames from 'classnames';

import { multiplierFormatter } from 'components/UI/TableConfig/column-helper';
import {
  IColumn,
  IRow,
  SortOrder,
  TypedTableCellConfig,
} from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { Target } from 'reducers/sellerTargetsReducer/types';
import { calcFiscalMonthName } from 'utils/dates';

type GetColumnsFunction = (
  isQuarterly: boolean,
  fiscalYearStartMonth: number,
  targets: Target[],
  fiscalYear: string,
  businessTypes: string[],
  currentFiscalYear: number,
  currentFiscalQuarter: number,
  companyCurrency: string,
  isCoverage: boolean
) => {
  columns: IColumn[];
  extraHeader: IColumn[];
};

const getTargetKey = (
  isQuarterly: boolean,
  period: Target['quarter'],
  fiscalYear: string,
  businessType?: string
) => {
  const label = (isQuarterly ? 'Q' : 'M') + period;

  return businessType
    ? `${label} ${fiscalYear}|${businessType}`
    : `${label} ${fiscalYear}`;
};

const getTargetColumn = (
  isQuarterly: boolean,
  isCoverage: boolean,
  companyCurrency: string,
  period: number | undefined,
  label: string,
  className: string,
  fiscalYear: string,
  currentYear: string,
  businessType?: string
): IColumn => ({
  align: 'right',
  config: {
    isCoverage,
    isMoney: true,
    formatter: (isCoverage
      ? multiplierFormatter
      : currencyFormatter(
          companyCurrency,
          0
        )) as TypedTableCellConfig['formatter'],
    className,
  },
  field: getTargetKey(isQuarterly, period, fiscalYear, businessType),
  id: getTargetKey(isQuarterly, period, fiscalYear, businessType),
  [isQuarterly ? 'quarter' : 'month']: period,
  editable: true,
  sort_order: SortOrder.ASCENDING,
  sortable: false,
  type: ColumnTypes.MONEY,
  ...(businessType
    ? {
        business_type: businessType,
        label: `${businessType}`,
      }
    : {
        label: `${label} ${currentYear} ${fiscalYear}`,
      }),
});

export const getColumns: GetColumnsFunction = (
  isQuarterly,
  fiscalYearStartMonth,
  targets,
  fiscalYear,
  businessTypes,
  currentFiscalYear,
  currentFiscalQuarter,
  companyCurrency,
  isCoverage
) => {
  let columns: IColumn[] = [];
  let extraHeader: IColumn[] = [];
  const currentYear = String(currentFiscalYear) === fiscalYear ? 'CFY' : 'FY';

  if (targets.length) {
    columns.push({
      id: 'user',
      label: 'Users',
      field: 'user',
      type: ColumnTypes.TEXT,
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      config: {},
    });

    if (businessTypes.length > 0) {
      extraHeader.push({
        id: '',
        label: '',
        field: '',
        type: ColumnTypes.TEXT,
        sortable: false,
        sort_order: SortOrder.ASCENDING,
        config: {},
      });
    }

    targets
      // The next two lines ensure the uniqueness of the columns
      .map((item) => (isQuarterly ? item.quarter : item.month))
      .filter((value, index, self) => self.indexOf(value) === index)
      .forEach((period: number | undefined) => {
        const label = isQuarterly
          ? `Q${period}`
          : calcFiscalMonthName(1, period || 1, 'short');

        const className =
          isQuarterly && currentFiscalQuarter === period ? 'current' : '';

        if (businessTypes.length > 0) {
          columns.push(
            ...businessTypes.map((businessType) =>
              getTargetColumn(
                isQuarterly,
                isCoverage,
                companyCurrency,
                period,
                label,
                className,
                fiscalYear,
                currentYear,
                businessType
              )
            )
          );

          extraHeader.push({
            colSpan: businessTypes.length,
            config: { className },
            field: getTargetKey(isQuarterly, period, fiscalYear),
            id: getTargetKey(isQuarterly, period, fiscalYear),
            label: `${label} ${currentYear} ${fiscalYear}`,
            sort_order: SortOrder.ASCENDING,
            sortable: false,
            type: ColumnTypes.MONEY,
          });
        } else {
          columns.push(
            getTargetColumn(
              isQuarterly,
              isCoverage,
              companyCurrency,
              period,
              label,
              className,
              fiscalYear,
              currentYear
            )
          );
        }
      });
  }

  // Adding QUARTER_DELIMITER_COLUMN class to separate vertically the quarters
  if (isQuarterly) {
    let currentQuarter: number;
    columns = columns.map((col) => {
      const { config, quarter } = col;
      let delimiterCls;
      if (currentQuarter !== quarter) {
        currentQuarter = quarter;
        delimiterCls = QUARTER_DELIMITER_COLUMN;
      }

      return {
        ...col,
        config: {
          ...config,
          className: classNames(config.className, delimiterCls),
        },
      };
    });

    extraHeader = extraHeader.map((col) => {
      const { config } = col;
      const className = classNames(config.className, QUARTER_DELIMITER_COLUMN);
      return { ...col, config: { ...config, className } };
    });
  }

  return {
    columns,
    extraHeader,
  };
};

const getTargetRows = (
  isQuarterly: boolean,
  targetList: Target[],
  fiscalYear: string,
  targetType: string
): IRow[] =>
  targetList.map((target: Target, i: number) => ({
    id: `${targetType}-${i}`,
    targetType,
    user: target.user.name || target.user.email,
    email: target.user.email,
    fiscal_year: fiscalYear,
    ...(isQuarterly ? { quarter: target.quarter } : { month: target.month }),
    ...target.targets?.reduce(
      (acc, item: Target) => ({
        ...acc,
        [getTargetKey(
          isQuarterly,
          isQuarterly ? item.quarter : item.month,
          fiscalYear,
          item.business_type
        )]: item.target,
      }),
      {}
    ),
  }));

const getParentRow = (
  isQuarterly: boolean,
  targetList: Target[],
  fiscalYear: string,
  rows: IRow[],
  targetType: string
): IRow => {
  const calculatedCustomTotals: { [key: string]: number } = {};

  targetList.forEach((target: Target) => {
    const key = getTargetKey(
      isQuarterly,
      isQuarterly ? target.quarter : target.month,
      fiscalYear,
      target.business_type
    );

    calculatedCustomTotals[key] = rows.reduce((agg, item) => {
      const value = item[key] as number;
      return isNaN(value) ? agg : agg + value;
    }, 0);
  });

  return {
    id: targetType,
    user: targetType,
    children: rows,
    isTopRow: true,
    disableEdit: true,
    ...calculatedCustomTotals,
  };
};

export const getRows = (
  isQuarterly: boolean,
  fiscalYearStartMonth: number,
  targets: Target[],
  targetByManager: Target[],
  targetsByUser: Target[],
  fiscalYear: string
): IRow[] => {
  const rowOverall: IRow = {
    id: 'overall',
    user: 'Overall',
    isTopRow: true,
    fiscal_year: fiscalYear,
    ...targets.reduce(
      (acc, target: Target) => ({
        ...acc,
        [getTargetKey(
          isQuarterly,
          isQuarterly ? target.quarter : target.month,
          fiscalYear,
          target.business_type
        )]: target.target,
      }),
      {}
    ),
  };

  const rowsManager = getTargetRows(
    isQuarterly,
    targetByManager,
    fiscalYear,
    'manager'
  );
  const rowsUser = getTargetRows(
    isQuarterly,
    targetsByUser,
    fiscalYear,
    'user'
  );

  const rowTopManager = getParentRow(
    isQuarterly,
    targets,
    fiscalYear,
    rowsManager,
    'Managers'
  );
  const rowTopUser = getParentRow(
    isQuarterly,
    targets,
    fiscalYear,
    rowsUser,
    'Sellers'
  );

  return [
    rowOverall,
    { id: 'empty-manager', isEmpty: true },
    rowTopManager,
    { id: 'empty-user', isEmpty: true },
    rowTopUser,
  ];
};
