import { css } from 'emotion';
import { TooltipFormatterContextObject } from 'highcharts';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { setExtremeItemsLabelsFromForecastTrends } from 'actions/opportunities';
import * as CONSTANTS from 'common/constants';
import { getStartDateForChangeInterval } from 'common/dates';
import BusinessTypesPanel from 'components/UI/BusinessTypesPanel';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import Widget from 'components/chart-dashboards/Widget';
import { DataItemType } from 'components/chart-dashboards/Widget/types';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { IProps } from 'components/dashboard/ForecastAnalytics/types';
import {
  TooltipWrapper,
  TooltipDefaultText,
  TooltipAccentText,
} from 'components/dashboard/ForecastDashboard/TrackingDashboard/Chart/styles';
import Tabs from 'components/dashboard/Tabs';
import * as selectors from 'selectors';
import { FiltersForAPI } from 'selectors';

const TOOLTIP_NON_EXPLICIT_FILTER_WARNING = '(only active deals included)';
const FIRST_BAR_INDEX = 0;
const LAST_BAR_INDEX = 8;

interface CustomOptions {
  count: number;
  y: number;
}

interface CustomOptions {
  count: number;
  y: number;
}

const stickyTop = css`
  position: sticky;
  top: 52px;
  background: var(--bu-white);
  z-index: 989;
`;

const DATE_FORMAT = 'MMM D, YYYY';
const CHANGE_INTERVAL_OPTIONS: { [key: string]: string } = {
  L7D: getStartDateForChangeInterval('L7D', new Date()).format(DATE_FORMAT),
  L14D: getStartDateForChangeInterval('L14D', new Date()).format(DATE_FORMAT),
  L30D: getStartDateForChangeInterval('L30D', new Date()).format(DATE_FORMAT),
  L60D: getStartDateForChangeInterval('L60D', new Date()).format(DATE_FORMAT),
  L3M: getStartDateForChangeInterval('L3M', new Date()).format(DATE_FORMAT),
  SQS: getStartDateForChangeInterval('SQS', new Date()).format(DATE_FORMAT),
};

const CHANGES_DEALS_DEFAULT_DATA = [
  {
    label: 'New',
    y: 0,
  },
  {
    label: 'Pulled',
    y: 0,
  },
  {
    label: '+Value',
    y: 0,
  },
  {
    label: 'Pushed',
    y: 0,
  },
  {
    label: '-Value',
    y: 0,
  },
  {
    label: 'Won',
    y: 0,
  },
  {
    label: 'Lost',
    y: 0,
  },
];

export const formatLabel =
  (filters: FiltersForAPI) =>
  ({ value }: { value: string }, addLinebreak = true) => {
    const br = addLinebreak ? '<br />' : '';
    const replaces = {
      Pulled: `Pulled into ${br} current filtered pipeline`,
      Pushed: `Pushed out of ${br} current filtered pipeline`,
    };
    const needsToReplace = Object.keys(replaces).includes(value || '');
    return needsToReplace ? replaces[value as keyof typeof replaces] : value;
  };

const ForecastAnalytics: React.FC<IProps> = ({
  selectedBusinessType,
  filters,
  toggleAllFilters,
  isBusinessTypeEnabled,
  tabPartition = 'forecast',
  isRequiredDataReady,
  savedPrevValuesDate = [],
}) => {
  const dispatch = useDispatch();
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);
  const [trendFilters, setTrendFilters] = useState({
    change_interval: savedPrevValuesDate.length ? savedPrevValuesDate : ['L7D'],
  });

  const handleOnSetData = useCallback((payload) => {
    if (payload.length < 1 || payload[0].data.items < 1) {
      dispatch(setExtremeItemsLabelsFromForecastTrends(undefined, undefined));
      return;
    }

    const [{ data: items }] = payload;
    const first = items[0].label;
    const last = items[items.length - 1].label;
    dispatch(setExtremeItemsLabelsFromForecastTrends(first, last));
  }, []);

  useEffect(() => {
    if (!isEmpty(savedPrevValuesDate)) {
      setTrendFilters({ change_interval: savedPrevValuesDate });
    }

    AnalyticsTracker.event(
      { filters },
      {
        action: 'Open',
        category: 'Forecast',
        label: 'Trends page',
      }
    );
  }, []);

  if (isEmpty(filters) || !isRequiredDataReady) {
    return <Loader active />;
  }

  const tab = 'forecast_analytics_change';
  const { forecast_category_names } = filters;

  return (
    <div className="wrapper" onClick={() => toggleAllFilters(true)}>
      <div className="container">
        <div className="container-dashboard">
          <Tabs partition={tabPartition} wrapped />
          <div className={stickyTop}>
            <OpenFiltersPanel tab={tab} />
          </div>

          {isBusinessTypeEnabled && <BusinessTypesPanel tab={tab} />}

          <div style={{ marginTop: 20, marginBottom: 100 }}>
            <Widget
              onSetData={handleOnSetData}
              tooltip={{
                useHTML: true,
                formatter: function (this: TooltipFormatterContextObject) {
                  const isFirstBar =
                    (this.series.data[FIRST_BAR_INDEX] as DataItemType)
                      .label === this.key?.toString();
                  const isLastBar =
                    (this.series.data[LAST_BAR_INDEX] as DataItemType).label ===
                    this.key?.toString();
                  const showNonExplicitFiltersWarning = isFirstBar || isLastBar;
                  const { count, y } = this.point.options as CustomOptions;
                  const value = `${new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: companyCurrencyCode,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }).format(y)} (${count} deals)`;
                  return renderToString(
                    <TooltipWrapper>
                      <TooltipDefaultText>
                        {formatLabel(filters)(
                          { value: this.point.category as string },
                          false
                        )}
                      </TooltipDefaultText>
                      <br />
                      <TooltipAccentText>{value}</TooltipAccentText>
                      {showNonExplicitFiltersWarning && (
                        <>
                          <br />
                          <TooltipAccentText>
                            {TOOLTIP_NON_EXPLICIT_FILTER_WARNING}
                          </TooltipAccentText>
                        </>
                      )}
                    </TooltipWrapper>
                  );
                },
              }}
              xAxisLabelFormatter={function (ctx) {
                return formatLabel(filters)({
                  value: (this.value || '') as string,
                });
              }}
              apis={[
                {
                  api_url: '/api/data/forecast/change',
                  click_api_url: '/api/data/deals/',
                },
              ]}
              filters={{
                ...filters,
                ...trendFilters,
                forecast_category_names: forecast_category_names?.length
                  ? forecast_category_names
                  : null,
                business_type_name: isBusinessTypeEnabled
                  ? selectedBusinessType
                  : undefined,
              }}
              hideFullScreenButton
              withTimeFilters
              setLocalFilters={setTrendFilters}
              data_type="deals"
              label_path="label"
              chart_options={{
                yAxisTitle: 'Revenue',
              }}
              changesSelectorTab="forecast"
              chart_type="waterfall"
              extendedTitle
              hideTimeSpanButtons
              tab={tab}
              title="changes for deals"
              value_path="y"
              onGetDefaultData={(changeInterval?: string) => {
                const today = moment().format(DATE_FORMAT);
                let label = today;
                if (changeInterval) {
                  const option = CHANGE_INTERVAL_OPTIONS[changeInterval];
                  label = option
                    ? option
                    : moment(changeInterval.split(',')[0]).format(DATE_FORMAT);
                }

                return [
                  {
                    data: [
                      { label, y: 0 },
                      ...CHANGES_DEALS_DEFAULT_DATA,
                      { label: today, y: 0 },
                    ],
                  },
                ];
              }}
              localStorageKeyPrefix="ForecastTrends"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForecastAnalytics;
