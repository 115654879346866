import React, { useEffect, useState } from 'react';

import TypedTable, { IRow } from 'components/UI/common/TypedTable/TypedTable';
import Select from 'components/modals/PersonalActivity/Select';
import { configTable } from 'components/modals/PersonalActivity/configs';
import * as styles from 'components/modals/PersonalActivity/styles';
import { fetchApi } from 'utils/network';

type ApiResponse = {
  data: {
    count: number;
    deals: IRow[];
    total_amount: number;
  };
};

type Props = {
  filters: { [key: string]: any };
  title: string;
  order: 'high' | 'low';
  companyCurrency: string;
};

const Table: React.FC<Props> = ({ filters, title, order, companyCurrency }) => {
  const [data, setData] = useState<IRow[]>([]);
  const [sortOrder, setSortOrder] = useState(
    `${order === 'high' ? '-' : ''}risk_score`
  );
  const [closeDateInterval, setCloseDateInterval] = useState<string[]>(
    filters.close_date_interval
  );

  const queryParams = JSON.stringify({
    ...filters,
    sort: sortOrder || '',
    risk_score: [order],
    close_date_interval: closeDateInterval,
    forecast_category_names: [],
  });

  useEffect(() => {
    fetchApi<string, ApiResponse>({
      queryParams,
      setData: (res) => setData(res.data.deals),
      url: `${process.env.REACT_APP_BACKEND_URL}/api/data/deals/`,
    });
  }, [queryParams]);

  return (
    <div className={styles.widget_table}>
      <div className={styles.widget_table_header}>
        <span>{title}</span>
        <Select onChange={setCloseDateInterval} value={closeDateInterval} />
      </div>
      <div className={styles.widget_table_body}>
        {data.length ? (
          <TypedTable
            sidePanel
            widgetSize
            columns={configTable(companyCurrency)}
            data={data}
            onSort={(v) => {
              if (v) {
                setSortOrder(v);
              }
            }}
            sorting={sortOrder}
            stickyHeader
            stickyColumn
          />
        ) : (
          <div className={styles.dealsNotFound}>No deals found</div>
        )}
      </div>
    </div>
  );
};

export default Table;
