import styled from '@emotion/styled';
import { css } from 'emotion';

export const tableHeaderCellBorders = css`
  & th {
    span {
      text-align: left;
    }
  }
`;

export const WidgetTopBar = styled.div({
  display: 'flex',
});

export const MetricsBlock = styled.div({
  flex: 5,
});

export const FunnelChartWrapper = styled.div({
  marginTop: '10px',
  display: 'flex',
  flexDirection: 'column',
  padding: '5px',
  gap: '4px',
  alignItems: 'center',
  width: '100%',
});

export const FunnelBar = styled.div({
  textAlign: 'center',
  width: '100%',
  height: '50px',
  position: 'relative',
});

export const FunnelBackgroundWrapper = styled.div`
  margin: 0 150px;
  position: relative;

  &:hover .funnel-background {
    background: var(--bu-primary-300);
  }
`;

export const FunnelBackground = styled.div<{ size: number; minSize: number }>`
  background: var(--bu-primary-400);
  width: calc(100% * ${(props) => props.size});
  height: 50px;
  min-width: ${(props) => props.minSize}px;
  transition: 0.3s;
  border-radius: 5px;
  margin: 0 auto;
`;

export const FunnelValue = styled.div({
  fontSize: '16px',
  position: 'absolute',
  top: '30%',
  left: 0,
  width: '100%',
  cursor: 'default',
  zIndex: 100,
});

export const FunnelLine = styled.div((props: { size: number }) => ({
  height: '1px',
  width: '20px',
  background: 'var(--bu-color-dynamic-bar-pale-blue)',
  position: 'absolute',
  top: '50%',
  left: `calc((100% - 300px) * ${props.size} + 150px + (100% - 300px) * (1 - ${props.size}) * 0.5)`,
  marginLeft: '5px',
}));

export const FunnelLabel = styled.div((props: { size: number }) => ({
  width: `calc(150px + (100% - 300px) * (1 - ${props.size}) * 0.5)`,
  marginLeft: `calc((100% - 300px) * ${props.size} + 150px + (100% - 300px) * (1 - ${props.size}) * 0.5)`,
  textAlign: 'left',
  paddingLeft: '35px',
  marginTop: '-35px',
}));

export const PopupLabel = styled.div({
  whiteSpace: 'nowrap',
  textAlign: 'left',
  fontSize: '15px',
});

export const PopupValue = styled.div({
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  textAlign: 'right',
  fontSize: '15px',
});

export const itemContainerWhiteBg = css`
  margin-left: auto;
  .option-group {
    background: var(--bu-white);
  }
`;

export const NoDataMessage = styled.div({
  display: 'flex',
  fontSize: '17px',
  color: 'var(--bu-gray-600)',
  gap: '5px',
  position: 'absolute',
  top: '50%',
  left: '50%',
});

export const popupHeader = css`
  padding-bottom: 10px !important;
`;

export const tableCell = css`
  padding: 5px 10px !important;
`;
