/* eslint-disable import/prefer-default-export */
import React from 'react';

import { ProfileEditor } from 'components/UI/OpenFiltersPanel/SaveViewFilters/ProfileEditor';
import {
  container,
  button,
} from 'components/UI/OpenFiltersPanel/SaveViewFilters/styles';
import { useClickOutside } from 'components/hooks/useClickOutside';

type Props = {
  tab: string;
};

export const SaveViewFilter: React.FC<Props> = ({ tab }) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside();
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div ref={refElement} className={container}>
      <button onClick={toggle} className={button} type="button">
        Save View
      </button>

      <ProfileEditor
        open={isOpen}
        cancel={() => setIsOpen(!isOpen)}
        position="right"
        tab={tab}
      />
    </div>
  );
};
