import moment from 'moment';
import React, { useState, useRef, useEffect } from 'react';
import Calendar, { CalendarTileProperties } from 'react-calendar';

import 'components/UI/TimeFilter/TabsForFilter/StylesForCalendar.css';
import { formatTo } from 'components/UI/TimeFilter/TabsForFilter/timeSpanOptions';
import { BetweenTabType } from 'components/UI/TimeFilter/TabsForFilter/types';
import * as s from 'components/UI/TimeFilter/styles';

const formatDate = (date: number) =>
  moment.unix(date / 1000).format('MM/DD/YYYY');

const BetweenTab: React.FC<BetweenTabType> = ({
  setBetweenDate,
  betweenDate,
  onChange,
}) => {
  const [isWarningMessage, setWarningMessage] = useState({
    isOpen: false,
    message: '',
  });
  const refStartDate = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (refStartDate.current !== null) {
      refStartDate.current.focus();
    }
  }, [refStartDate]);

  const onClickStartDate = (data: Date) => {
    const date = formatDate(data.getTime());
    const isAfterNow = moment(date) > moment();
    const startForApi = formatTo(date, 'MM/DD/YYYY', 'YYYY-MM-DD');
    const endForApi = formatTo(
      `${moment().format('MM/DD/YYYY')}`,
      'MM/DD/YYYY',
      'YYYY-MM-DD'
    );
    const isBeforeDate = moment(date).isBefore(
      moment(`${moment().format('MM/DD/YYYY')}`, 'MM/DD/YYYY').toDate()
    );

    if (isAfterNow) {
      setWarningMessage({
        isOpen: true,
        message: 'Choose an earlier date',
      });
      return;
    }

    if (isBeforeDate) {
      setBetweenDate({ ...betweenDate, startDate: date });
      onChange('change_interval', `${startForApi},${endForApi}`);
    }
  };

  const tileClassNameStart = (data: CalendarTileProperties) => {
    const { date } = data;
    if (moment(date).format('MM/DD/YYYY') === betweenDate.startDate) {
      return ['react-calendar__tile--rangeStart'];
    }
    if (betweenDate.startDate === '') {
      return ['disabled'];
    }
    return [];
  };

  return (
    <>
      <Calendar
        onClickDay={onClickStartDate}
        tileClassName={tileClassNameStart}
        value={
          moment(betweenDate.startDate).isValid()
            ? moment(betweenDate.startDate).toDate()
            : undefined
        }
        locale="en-EN"
      />
      {isWarningMessage.isOpen && (
        <div className={s.message_warning}>
          <div className={s.message_warning_title}>Warning!</div>
          <p>{isWarningMessage.message}</p>
          <button
            className={s.message_warning_btn}
            onClick={() => setWarningMessage({ isOpen: false, message: '' })}
          >
            Ok
          </button>
        </div>
      )}
    </>
  );
};

export default BetweenTab;
