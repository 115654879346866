import styled from '@emotion/styled';
import { css } from 'emotion';

export const MainContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'var(--bu-gray-100)',
  position: 'relative',

  '&.no-scroll': {
    overflow: 'hidden',
  },
});

export const DashboardHeaderRow = styled.div({
  position: 'sticky',
  top: '0px',
  zIndex: 2,
  backgroundColor: 'var(--bu-white)',
  display: 'flex',
  padding: '16px 24px',
  width: '100%',
  gap: '10px',
  height: '64px',
  borderBottom: 'var(--bu-gray-400) 1px solid',
});

export const DashboardHeaderRowTitle = styled.div({
  flexGrow: 1,
  cursor: 'pointer',
});

export const DashboardHeaderRowControls = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: '16px',
  alignItems: 'center',
});

export const DashboardMetricContainer = styled.div(
  (props: { widgetsLength: number }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    padding: '16px 24px',
    gap: '24px',
    height: props.widgetsLength < 2 ? '100vh' : 'fit-content',
    zIndex: 1,
  })
);

export const LoaderContainer = styled.div({
  position: 'relative',
  width: '100%',
  height: '100%',
});

export const PlaceholderWidget = styled.div({
  background: 'var(--bu-white)',
  border: '1px dashed',
  borderColor: 'var(--bu-primary-500)',
  borderRadius: '4px',
  width: '48%',
  minHeight: '620px',
  height: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  button: {
    width: '112px',
    margin: 'auto',
  },
});

export const PopupInfoContainer = styled.div({
  fontSize: '14px',
  letterSpacing: '0',
  lineHeight: '20px',
  padding: '7px',
  textAlign: 'center',
  maxWidth: '240px',
});

export const TimestampSpan = styled.span({
  color: 'var(--bu-gray-700)',
});

export const dashboardSettingContainer = css`
  padding: 8px;
`;

interface WidgetModalComponentProps {
  readonly scrollDistance: number | undefined;
  readonly heightView: number | undefined;
}

export const WidgetModalComponent = styled.div(
  ({ scrollDistance = 0, heightView = 0 }: WidgetModalComponentProps) => ({
    position: 'absolute',
    zIndex: 100,
    width: '100%',
    height: '0',
    top: heightView + scrollDistance,
    backgroundColor: 'white',
    transitionProperty: 'top',
    transitionDuration: '1s',
    visibility: 'hidden',

    '&.opened': {
      top: scrollDistance,
      visibility: 'visible',
      height: 'calc(100%)',
      overflowY: 'auto',
    },

    '&.closing': {
      top: heightView + scrollDistance,
      visibility: 'visible',
      height: 'calc(100%)',
    },
  })
);
