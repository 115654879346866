import React, {
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { BIChartTypes } from 'common/enums/metrics';
import { ISelectOption } from 'components/UI/BuSelect/types';
import { BaseHistoricalWidgetControls } from 'components/dashboard/Metrics/Widget/Controls/BaseHistoricalWidgetControls';
import { groupColumns } from 'components/dashboard/Metrics/Widget/Controls/helpers';
import { TemplateFiltersPreview } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFiltersPreview';
import { getDropdownFriendlyName } from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIBasicColumn,
  BIColumnListItem,
  BIMetricColumn,
  BIMetricToChartType,
  BIWidget,
  BIWidgetDataV2,
  DateOption,
} from 'components/dashboard/Metrics/metrics.types';
import { IReduxState } from 'reducers/types';
import * as widgetSelectors from 'selectors/revbi/widgets';
import { fetchApi } from 'utils/network';

interface Props {
  pivot?: BIMetricColumn;
  metricToChartType: BIMetricToChartType[];
  metricData?: BIWidgetDataV2;
  setPivot: Dispatch<SetStateAction<BIMetricColumn | undefined>>;
  updateWidget: (widget: Partial<BIWidget>) => void;
  setMetricToChartType: Dispatch<SetStateAction<BIMetricToChartType[]>>;
}

export const HistoricalPreviewWidgetControls: React.FC<Props> = ({
  pivot,
  metricToChartType,
  metricData,
  setPivot,
  updateWidget,
  setMetricToChartType,
}) => {
  const match = useRouteMatch<{ widgetId: string }>();

  const widget = useSelector((state: IReduxState) =>
    widgetSelectors.getActiveWidget(state, match.params.widgetId)
  );

  const [timeIntervalOptions, setTimeIntervalOptions] = useState<
    ISelectOption[]
  >([]);
  const [pointInTimeOptions, setPointInTimeOptions] = useState<ISelectOption[]>(
    []
  );
  const [timeValueOptions, setTimeValueOptions] = useState<DateOption[]>([]);
  const [dateTemplateFilterOptions, setDateTemplateFilterOptions] = useState<
    DateOption[]
  >([]);
  const [pivotColumns, setPivotColumns] = useState<BIMetricColumn[]>([]);

  useEffect(() => {
    const signalAbort = new AbortController();
    const signalAbortPivotHistory = new AbortController();

    fetchApi<void, BIColumnListItem[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_pivot_history`,
      queryMethod: 'get',
      setData: (result) => {
        setPivotColumns(
          result.map((column) => ({
            ...column,
            label: getDropdownFriendlyName(column),
          }))
        );
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load columns: ${error}`);
      },
      signal: signalAbortPivotHistory.signal,
    });

    fetchApi<void, BIBasicColumn[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/time_series/get_time_interval_options/`,
      queryMethod: 'get',
      setData: (result) => {
        const resultOptions: ISelectOption[] = [];
        result.map((element) => {
          resultOptions.push({
            text: element.label,
            value: element.name,
          });
        });
        setTimeIntervalOptions(resultOptions);
        updateWidget({
          ...widget,
          time_interval: widget.time_interval ?? resultOptions?.[0].value,
        });
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load time interval options: ${error}`);
      },
      signal: signalAbort.signal,
    });

    return () => {
      signalAbort.abort();
      signalAbortPivotHistory.abort();
    };
  }, []);

  useEffect(() => {
    if (widget.time_interval) {
      const signalAbortPeriodOptions = new AbortController();
      const signalAbortTemplate = new AbortController();
      fetchApi<void, BIBasicColumn[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/time_series/get_time_period_options/${widget.time_interval}`,
        queryMethod: 'get',
        setData: (result) => {
          const resultTimePeriodOptions: DateOption[] = [];
          result.map((element) => {
            resultTimePeriodOptions.push({
              label: element.label,
              value: element.name,
              type: element.type.toUpperCase(),
            });
          });
          const widgetTimePeriod =
            resultTimePeriodOptions.find(
              (timePeriod) => timePeriod.value === widget.time_period
            ) ?? false;

          setTimeValueOptions(resultTimePeriodOptions);
          updateWidget({
            ...widget,
            time_period: widgetTimePeriod
              ? widgetTimePeriod.value
              : resultTimePeriodOptions[0]
              ? resultTimePeriodOptions[0].value
              : '',
          });
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load time period options: ${error}`);
        },
        signal: signalAbortPeriodOptions.signal,
      });

      const getType = (name: string) => {
        if (name === 'all_time') return 'SAME_PERIOD';
        if (name.startsWith('same_')) return 'SAME_PERIOD';
        if (name.startsWith('previous_')) return 'PREVIOUS_PERIOD';
        if (name.startsWith('next_')) return 'NEXT_PERIOD';
      };

      fetchApi<void, any[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/time_series/get_date_template_filter_options/${widget.time_interval}`,
        queryMethod: 'get',
        setData: (result) => {
          setDateTemplateFilterOptions(
            result.map((element) => ({
              label: element.label,
              value: element.name,
              type: getType(element.name),
            }))
          );
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load date template filter options: ${error}`);
        },
        signal: signalAbortTemplate.signal,
      });

      return () => {
        signalAbortTemplate.abort();
        signalAbortPeriodOptions.abort();
      };
    }
  }, [widget.time_interval]);

  useEffect(() => {
    if (widget.time_interval && widget.time_period) {
      const signalAbort = new AbortController();
      fetchApi<void, BIBasicColumn[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/time_series/get_point_in_time_options/${widget.time_interval}`,
        queryMethod: 'get',
        setData: (result) => {
          const resultPitOptions: ISelectOption[] = [];
          result.map((element) => {
            resultPitOptions.push({
              text: element.label,
              value: element.name,
            });
          });
          const widgetPointInTime =
            resultPitOptions.find(
              (pointInTime) => pointInTime.value === widget.point_in_time
            ) ?? false;

          setPointInTimeOptions(resultPitOptions);
          updateWidget({
            ...widget,
            point_in_time: widgetPointInTime
              ? widgetPointInTime.value
              : resultPitOptions[0]
              ? resultPitOptions[0].value
              : '',
          });
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load related point in time options: ${error}`);
        },
        signal: signalAbort.signal,
      });
      return () => signalAbort.abort();
    }
  }, [widget.time_interval, widget.time_period]);

  const groupByColumns: BIMetricColumn[] = useMemo(() => {
    const dateTypePivots = pivotColumns.filter(
      (column) => column.type === 'date'
    );
    return groupColumns(pivotColumns, dateTypePivots);
  }, [pivotColumns]);

  const handleTimeIntervalChange = (values: string[]): void => {
    updateWidget({
      ...widget,
      time_interval: values[0],
    });
  };

  const handleTimePeriodChange = (selected: Filters.PersistValue[]): void => {
    const selectedValue = selected[0].id;
    updateWidget({
      ...widget,
      time_period: selectedValue,
    });

    if (!timeValueOptions.find((option) => option?.value === selectedValue)) {
      timeValueOptions.push({
        label: selectedValue.split(',').join(' / '),
        value: selectedValue,
      });
    }
  };

  const handlePointInTimeChange = (values: string[]): void => {
    updateWidget({
      ...widget,
      point_in_time: values[0],
    });
  };

  const handlePivotChange = (values: string[]): void => {
    const column = groupByColumns.find((column) => column.name === values[0]);
    setPivot(column);

    if (column?.name === 'none') {
      updateWidget({
        ...widget,
        group_by: [],
      });
    } else if (column) {
      let mtc = metricToChartType.map((el) => ({
        ...el,
        chartType:
          !!metricData &&
          metricData?.hasOwnProperty('pivot_2') &&
          metricData.pivot_2?.data[0].hasOwnProperty(el.metricId)
            ? BIChartTypes.ColumnStacked
            : el.chartType,
      }));
      updateWidget({
        ...widget,
        group_by: [column],
        properties: {
          ...widget.properties,
          metricToChartType: mtc,
        },
      });
      setMetricToChartType([...mtc]);
    }
  };

  const selectedTimeInterval = useMemo(
    () =>
      timeIntervalOptions.find((e) => e.value === widget.time_interval)?.text ??
      '',
    [timeIntervalOptions, widget.time_interval]
  );

  const selectedTimeValue = useMemo(
    () =>
      timeValueOptions.find((e) => e.value === widget.time_period)?.label ?? '',
    [timeValueOptions, widget.time_period]
  );

  return (
    <>
      <BaseHistoricalWidgetControls
        showControls
        pivotName={pivot?.name}
        pointInTime={widget.point_in_time}
        timeInterval={widget.time_interval}
        timePeriod={widget.time_period}
        widgetChartType={widget.chart_type}
        groupByColumns={groupByColumns}
        pointInTimeOptions={pointInTimeOptions}
        timeIntervalOptions={timeIntervalOptions}
        timeValueOptions={timeValueOptions}
        onPivotChange={handlePivotChange}
        onPointInTimeChange={handlePointInTimeChange}
        onTimeIntervalChange={handleTimeIntervalChange}
        onTimePeriodChange={handleTimePeriodChange}
      />

      <TemplateFiltersPreview
        templateFilters={widget.template_filters}
        widgetFilters={widget.widget_filters}
        relativeDateFilterOptions={dateTemplateFilterOptions}
        timeInterval={selectedTimeInterval}
        timePeriod={selectedTimeValue}
        updateWidget={updateWidget}
        widget={widget}
      />
    </>
  );
};
