import React from 'react';

import BuButton, { BuControlSize } from 'components/UI/BuButton';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import {
  CustomComponentFunction,
  makeComponent,
} from 'components/UI/common/TypedTable/renderers/custom/common';

export type SaveCancelButtonsProps = {
  saveLabel?: string;
  cancelLabel?: string;

  onSave(row: IRow, rows: IRow[]): void;
  onCancel(row: IRow, rows: IRow[]): void;

  canSave?(row: IRow): boolean;

  disabled?: boolean;
};

export const saveCancelButtons: CustomComponentFunction<SaveCancelButtonsProps> = ({
  saveLabel = 'Done',
  cancelLabel = 'Cancel',
  onSave,
  onCancel,
  canSave = () => true,
  disabled = false,
}) => ({ row, rows }) => (
  <>
    <BuButton
      size={BuControlSize.SMALL}
      onClick={() => onSave(row, rows)}
      disabled={disabled || !canSave(row)}
    >
      {saveLabel}
    </BuButton>
    <BuButton
      size={BuControlSize.SMALL}
      secondary
      onClick={() => onCancel(row, rows)}
      disabled={disabled}
    >
      {cancelLabel}
    </BuButton>
  </>
);

export const SaveCancelButtons = makeComponent(saveCancelButtons);
