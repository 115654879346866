import * as t from 'actions/actionTypes';
import * as genericSagas from 'sagas/generic';

export const createCustomView = (
  data: Filters.CustomView,
  previouslyPinnedId: string = ''
) => ({
  type: t.CUSTOM_VIEWS + t.CREATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/custom_views`,
  data,
  saga: genericSagas.create,
  options: {
    toast: {
      error: true,
      defaultErrorMessage: 'Unexpected failure.',
    },
  },
  success: (payload: any) => ({
    type: t.CUSTOM_VIEWS + t.CREATE + t.SUCCESS,
    payload: {
      ...payload,
      previouslyPinnedId,
    },
  }),
  error: (e: any) => ({
    type: t.CUSTOM_VIEWS + t.CREATE + t.ERROR,
    error: e?.response?.data?.error?.message?.[0] || e,
  }),
  loading: () => ({
    type: t.CUSTOM_VIEWS + t.CREATE + t.LOADING,
  }),
});

export const updateCustomView = (
  id: string,
  data: Filters.CustomView,
  showToast: boolean = true,
  previouslyPinnedId: string = '',
  isPinningAction: boolean = false
) => ({
  type: t.CUSTOM_VIEWS + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/custom_views/${id}`,
  data,
  saga: genericSagas.update,
  success: (payload: any) => ({
    type: t.CUSTOM_VIEWS + t.UPDATE + t.SUCCESS,
    payload: {
      ...payload,
      showToast,
      previouslyPinnedId,
      isPinningAction,
    },
  }),
  error: () => ({
    type: t.CUSTOM_VIEWS + t.UPDATE + t.ERROR,
    error: 'error',
  }),
  loading: () => ({
    type: t.CUSTOM_VIEWS + t.UPDATE + t.LOADING,
  }),
});

export const deleteCustomView = (
  id: string,
  tab: string,
  name: string,
  hasPinned: boolean = false
) => ({
  type: t.CUSTOM_VIEWS + t.REMOVE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/custom_views/${id}`,
  saga: genericSagas.remove,
  success: (payload: any) => ({
    type: t.CUSTOM_VIEWS + t.REMOVE + t.SUCCESS,
    payload: { ...payload, id, tab, name, hasPinned },
  }),
  error: () => ({
    type: t.CUSTOM_VIEWS + t.REMOVE + t.ERROR,
    error: 'error',
  }),
  loading: () => ({
    type: t.CUSTOM_VIEWS + t.REMOVE + t.LOADING,
  }),
});
