import { InteractiveLayout } from './common/InteractiveGrid/InteractiveGrid.types';
import { Layout } from 'react-grid-layout';

import { BIChartTypes } from 'common/enums/metrics';
import { AnalysisType, UnitType } from 'components/dashboard/Metrics/constants';

type MongoDateObject = {
  $date: number;
};

type METRIC_TYPE_VALUE = 'simple' | 'formula' | 'synthetic';

export interface UnitValue {
  unit: string;
  type: UnitType;
}

export type BIMetrics = {
  _id: string;
  id?: string;
  description?: string;
  aggregation_function?: string;
  analysis_type?: string;
  column?: BIMetricColumn | null;
  company_id?: string;
  created_at?: string;
  cumulative_sum_period?: string;
  filters: BIMetricsQueryFilter[];
  is_cumulative_sum?: boolean;
  is_independent: boolean;
  is_hierarchy_rollup_sum?: boolean;
  metadata?: {
    user_id?: string;
    user_name?: string;
    user_email?: string;
    type?: METRIC_TYPE_VALUE;
  };
  name: string;
  object?: string;
  properties?: {
    metricUnit?: UnitValue;
  };
  select_expression?: string;
  synthetic_metric?: string;
  target_type?: string;
  target_period?: string;
  updated_at?: string;
};

export type BIChartType = 'pie' | 'column' | 'bar' | 'table' | 'funnel';

export type BIBasicColumn = {
  name: string;
  label: string;
  type: string;
};

export type BIMetricColumn = BIBasicColumn & {
  field_name?: string;
  display_name?: string;
  applies_to_all_metrics?: boolean;
};

export enum MetricType {
  Formula = 'formula',
  Simple = 'simple',
}

export enum AggregationFunction {
  Sum = 'sum',
  Average = 'avg',
  Min = 'min',
  Max = 'max',
  Count = 'count',
}

type SavedMetricFields = {
  _id: string;
  is_independent: boolean;
  // should be enum
  analysis_type: string;
  company_id: string;
  created_at: string;
  updated_at: string;
};

type BIMetricBase = {
  analysis_type: AnalysisType;
  cumulative_sum_period?: string;
  filters: BIMetricsQueryFilter[];
  is_cumulative_sum?: boolean;
  is_hierarchy_rollup_sum?: boolean;
  metadata: {
    type: MetricType;
  };
  name: string;
  object: string;
  target_type?: string;
  target_period?: string;
};

export type BIMetricSimpleNewborn = BIMetricBase & {
  aggregation_function: AggregationFunction;
  column?: BIMetricColumn;
  properties?: {
    [key: string]: any;
  };
};

export type BIMetricFormulaNewborn = BIMetricBase & {
  properties: {
    metricUnit?: UnitValue;
  };
  synthetic_metric: string;
};

export type BIMetricSimple = BIMetricSimpleNewborn & SavedMetricFields;

export type BIMetricFormula = BIMetricFormulaNewborn & SavedMetricFields;

export type BIMetricReport = Pick<
  BIMetricSimpleNewborn,
  'object' | 'filters' | 'metadata' | 'name'
>;

export type BIMetricUnion =
  | BIMetricSimple
  | BIMetricFormula
  | BIMetricFormulaNewborn
  | BIMetricSimpleNewborn;

export type BIMetricsQueryFilter = {
  and_condition: { or_condition: BIMetricsFilter[] }[];
};

export type BIMetricsFilter = {
  column: BIMetricColumn;
  operator: string;
  value: string | number | boolean | string[] | [number, number];
};

export type BIDashboardSettings = {
  userFilter: string;
};

export type InteractiveBIWidget = BIWidget &
  InteractiveLayout & { metric_list: BIMetrics[] };

export type BIDashboardProperties = {
  /** @deprecated now resize is handled by react-grid-layout using widgetLayout instead */
  widgetsExpanded?: string[];

  /** @deprecated now prder is handled by react-grid-layout using widgetLayout instead */
  widgetsOrder?: string[];
  widgetLayout?: Layout[];
  settings?: BIDashboardSettings;
};

export type BIDashboard = {
  _id?: string;
  id?: string;
  name: string;
  properties: BIDashboardProperties;
  created_at?: MongoDateObject;
  updated_at?: MongoDateObject;
  widget_list: (BIWidget | string)[];
};

export type BIWidget = {
  _id?: string;
  id?: string;
  analysis_type: string;
  chart_type: string;
  company_id?: string;
  created_at?: MongoDateObject;
  dashboard_id?: string;
  dashboard_name?: string;
  group_by: BIMetricColumn[];
  limit?: number;
  metadata?: {
    user_id?: string;
    user_name?: string;
    user_email?: string;
  };
  metric_list: any; // TODO Fix any type
  name: string;
  order_by: string[];
  order_by_column: BIBasicColumn;
  point_in_time: string;
  properties?: {
    [key: string]: any;
  };
  template_filters: BIMetricsFilter[];
  time_field?: BIMetricColumn;
  time_interval?: string;
  time_period?: string;
  updated_at?: MongoDateObject;
  widget_filters: BIMetricColumn[];

  // Funnel specific
  funnel_object?: string;
  funnel_column?: string;
  funnel_metric_list?: string[];
  funnel_top_metrics?: string[];
  funnel_conditions?: BIMetricsQueryFilter[];
  funnel_stage_column?: string;
  funnel_stages?: string[];

  title?: string; // TODO removed ??
};

export type BIMetricCreated = BIMetricFormula | BIMetricSimple;

export type BIWidgetPreview = Omit<BIWidget, 'metadata' | 'company_id'>;

export type BIWidgetDataV2 = {
  pivot_0?: BIWidgetPivotDataV2;
  pivot_1?: BIWidgetPivotDataV2;
  pivot_2?: BIWidgetPivotDataV2;
  synthetic_metric_metadata?: {
    [key: string]: any;
  };
  additional_metrics?: BIAdditionalMetric[];
};

export type BIAdditionalMetric = {
  display_name: string;
  value: any;
  type: string;
};

type BIWidgetPivotDataV2 = {
  columns: BIWidgetColumnV2[];
  columns_error: BIWidgetErrorColumnV2[];
  data: {
    [key: string]: any;
  }[];
};

export type BIWidgetColumnV2 = {
  display_name: string;
  field_name: string;
  is_pivot: boolean;
  is_funnel: boolean;
  metric_id: string;
  type: string;
};

export type BIWidgetErrorColumnV2 = BIWidgetColumnV2 & {
  message: string;
};

export type BIDropdownOptions = {
  key?: string;
  label: string;
  value: any;
};

export type BIColumnListItem = {
  name: string;
  label: string;
  table_name: string;
  type: string;
};

export type BIMetricSimplified = {
  id: string;
  name: string;
  color: string;
};

export interface BIMetricsMap {
  [id: string]: BIMetrics;
}

type MetricDeleteElement = {
  name: string;
  object_id: string;
};

export type MetricDeleteInfo = {
  widget_deleted_list: MetricDeleteElement[];
  widget_edited_list: MetricDeleteElement[];
  synthetic_metric_list: MetricDeleteElement[];
};

export type DeleteInfo = MetricDeleteInfo | string[];

export type DrillDownFilter = {
  column: BIMetricColumn;
  operator: string;
  value: string | number;
};

interface BaseDrillDownParams {
  filters: BIMetricsQueryFilter[];
  drill_down_filters: DrillDownFilter[];
  template_filters: BIMetricsFilter[];
  business_type_name?: string;
}

export interface DrillDownParams extends BaseDrillDownParams {
  cumulative_sum_period?: string;
  duration: string;
  is_cumulative_sum?: boolean;
  is_hierarchy_rollup_sum?: boolean;
  limit: number;
  offset: number;
  order_by: string[];
  skip_business_validation?: boolean;
}

export interface TimeSeriesDrillDownParams extends BaseDrillDownParams {
  point_in_time: string | undefined;
  target_time_period: string | undefined;
  time_interval: string | undefined;
  time_period: string | undefined;
}

export type SyntheticMetricsValuesAsChartValue = {
  metricName: string;
  id: string;
  value: number | string;
};

export type SelectedValue = {
  metricsValues?: SyntheticMetricsValuesAsChartValue[];
  pivot1Id?: string;
  pivot2Id?: string;
  y: number;
};

export interface SyntheticMetricDataWidgetState {
  syntheticMetric?: BIMetrics;
  parsedFormula?: string;
  metadata?: { [key: string]: string };
}

export type DateOption = {
  label: string;
  type?: string;
  value: string;
  is_quarter_field?: boolean;
};

export interface DateFilterOptionsResponse {
  predefined: DateOption[];
  fiscal_year: DateOption[];
}

export interface PicklistOptions {
  display_name: string;
  value: string;
  is_active: boolean;
}

export enum ComponentMode {
  CREATE,
  EDIT,
}

export interface IOption {
  text: string;
  value: string;
}

// Action Payloads

export interface GetMetricByIdSuccessPayload {
  id: string;
  data: BIMetricSimple | BIMetricFormula;
  isEditing?: boolean;
}

export interface UpdateMetricByIdSuccessPayload {
  id: string;
  data: BIMetricSimple | BIMetricFormula;
}

export interface GetWidgetByIdSuccessPayload {
  id: string;
  data: BIWidget;
  isEditing?: boolean;
}

export interface CloneMetricActionPayload {
  id: string;
  addToWidget?: 'edit' | 'create';
  redirectToTheNewMetric?: boolean;
  toast?: boolean;
}

export interface DeleteMetricActionPayload {
  metric: BIMetricSimple | BIMetricFormula;
  redirect: string;
  toast: boolean;
}

export interface CreateMetricExtendedActionPayload {
  metric: BIMetricSimpleNewborn | BIMetricFormulaNewborn;
  addToWidgetCreationMetricsList?: boolean;
  redirect?: string;
  toast?: boolean;
}

export interface UpdateMetricExtendedActionPayload {
  metric: BIMetricSimple | BIMetricFormula;
  redirect?: string;
  toast?: boolean;
}

// Action Payloads End

export interface BIMetricToChartType {
  chartType: BIChartTypes;
  metricId: string;
}

export interface SelectedChartValue extends Highcharts.PointOptionsObject {
  metricId?: string;
  pivot1Id?: string;
  pivot2Id?: string;
}
