import BuSkeleton from '../../UI/BuSkeleton';
import * as styles from './styles';
import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { formatAmount } from 'common/helpers';
import { formatMoney } from 'common/numbers';
import BuIcon from 'components/UI/BuIcon';
import Tooltip from 'components/UI/common/Tooltip';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

interface IProps {
  id?: string;
  title: string;
  value: number;
  count?: number;
  highRisk?: boolean;
  changedValue?: string;
  companyCurrency: string;
  onClick?: () => void;
  selectedItem?: string;
  deltaValue?: any;
  metricNumber?: number;
  isLoading: boolean;
  clickable?: boolean;
}

const getMetricTooltipContent = (
  metricName: string,
  isExcludeDisabledBySetting: boolean
) => {
  return (
    <div>
      {metricName === 'Booked' ? (
        <>
          <strong>Booked</strong> metric is a sum of all won deals
          {isExcludeDisabledBySetting
            ? '. It does not include deals of disabled users based on current configuration. '
            : ' which may include deals of disabled users based on current configuration. '}
          This ignores <strong>Forecast Category</strong> and{' '}
          <strong>Deal Stages</strong> filters and has no dependency on the
          other metrics
        </>
      ) : (
        <>
          <strong>{metricName}</strong> metric includes the{' '}
          <strong>Forecast Category</strong> filter set to <i>'{metricName}'</i>{' '}
          and <strong>Deal Stages</strong> filter set to the default or selected
          options
        </>
      )}
    </div>
  );
};

function TotalPanel(props: IProps) {
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);
  const isExcludeDisabledBySetting = useSelector(
    selectors.getExcludeDisabledUserFromBookedInOppsMetric
  );
  const toggleBackground = (): boolean => {
    const { selectedItem, title, highRisk } = props;
    // checking if box has `highRisk` prop value for select correct box
    return selectedItem === `${title}${highRisk ? '.high' : ''}`;
  };

  const isSelected = props.selectedItem === props.id;
  const isInitialView = !props.selectedItem;

  const panelClasses = classNames({
    [styles.totalPanel]: true,
    [styles.highRisk]: props.highRisk,
    [styles.selectedBg]: toggleBackground(),
    is_selected: isSelected,
    initial_view: isInitialView,
  });

  const currencyValue = formatMoney(props.companyCurrency, props.value, 0);

  return (
    <div
      id={props.id}
      role="button"
      tabIndex={0}
      onClick={props.clickable ? props.onClick : () => {}}
      className={classNames('total-box', panelClasses)}
      data-metric-number={`${props.metricNumber}`}
    >
      <Popup
        content={getMetricTooltipContent(
          props.title!,
          isExcludeDisabledBySetting
        )}
        position="bottom center"
        mouseEnterDelay={1000}
        trigger={
          <div className={styles.totalBox}>
            {props.isLoading ? (
              <BuSkeleton height={15} width={120} />
            ) : (
              <div className={classNames('total-title', styles.totalTitle)}>
                {props.highRisk ? `${props.title} At Risk` : `${props.title} `}

                <div style={{ display: 'inline-block', marginRight: '3px' }}>
                  <BuIcon
                    name={BoostUpIcons.BadgeInfoOutline}
                    color="#6B7275"
                  />
                </div>

                {(isSelected || isInitialView) && props.count ? (
                  <span>
                    ({props.count} {props.count === 1 ? 'Deal' : 'Deals'})
                  </span>
                ) : null}
              </div>
            )}

            {props.isLoading ? (
              <BuSkeleton height={20} width={100} />
            ) : (
              <div className={classNames('total-footer', styles.totalFooter)}>
                {isSelected || isInitialView ? (
                  <div
                    className={classNames(
                      'total-currency',
                      styles.totalCurrency
                    )}
                  >
                    <Tooltip tooltip={currencyValue}>{`${formatAmount(
                      companyCurrencyCode,
                      props.value
                    )}`}</Tooltip>
                  </div>
                ) : (
                  <div className={styles.details}>Сlick to see deals</div>
                )}
              </div>
            )}
          </div>
        }
      />
    </div>
  );
}

const mapStateToProps = (state: IReduxState) => ({
  companyCurrency: selectors.getUserLocalCurrency(state),
});

export default connect(mapStateToProps)(TotalPanel);
