import { reducerWithInitialState } from 'typescript-fsa-reducers';

import * as widgetActions from 'actions/revbi/widgets';
import {
  NEW_WIDGET_MOCK,
  optionSectionsEnum,
} from 'components/dashboard/Metrics/Create/WidgetCreate/constants';
import { FUNNEL_PREDEFINED_METRICS } from 'components/dashboard/Metrics/Create/constants';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import {
  BIMetricSimple,
  BIMetricFormula,
  BIWidgetPreview,
  BIWidget,
  BIMetricCreated,
  BIMetricColumn,
} from 'components/dashboard/Metrics/metrics.types';

export type RevBIWidgetsReduxStore = {
  create: {
    widget: BIWidgetPreview;
    sectionExpanded: optionSectionsEnum;
    metricsFromList: (BIMetricSimple | BIMetricFormula)[];
    columnList: BIMetricColumn[];
  };
  edit: {
    widget: BIWidget | null;
    sectionExpanded: optionSectionsEnum;
    metricsFromList: (BIMetricSimple | BIMetricFormula)[];
    getStatus: string;
    columnList: BIMetricColumn[];
  };

  widgetsList: BIWidget[];
  widgetsListStatus: string;
  widgetUpdateStatus: string;
  widgetCreateStatus: string;
};

const initialState = {
  create: {
    widget: NEW_WIDGET_MOCK,
    sectionExpanded: optionSectionsEnum.type,
    metricsFromList: [],
    columnList: [],
  },
  edit: {
    widget: null,
    sectionExpanded: optionSectionsEnum.metric,
    metricsFromList: [],
    getStatus: 'notAsked',
    columnList: [],
  },
  widgetsList: [],
  widgetsListStatus: 'notAsked',
  widgetCreateStatus: 'notAsked',
  widgetUpdateStatus: 'notAsked',
};

export const widgetsReducer = reducerWithInitialState<RevBIWidgetsReduxStore>(
  initialState
)
  .case(widgetActions.fetchWidgetByIdSuccess, (state, payload) => {
    const newState: RevBIWidgetsReduxStore = { ...state };
    newState.edit.getStatus = 'success';
    let isUpdated = false;
    const newList =
      state.widgetsList.length === 0
        ? state.widgetsList.concat(payload.data)
        : state.widgetsList.reduce((acc: BIWidget[], widget, index) => {
            if (widget._id === payload.id) {
              acc.push(payload.data);
              isUpdated = true;
            } else {
              if (index === state.widgetsList.length && !isUpdated) {
                acc.push(widget);
                acc.push(payload.data);
              } else {
                acc.push(widget);
              }
            }
            return acc;
          }, []);
    newState.widgetsList = newList;
    if (payload.isEditing) {
      newState.edit.widget = {
        ...payload.data,
        metric_list:
          payload.data.metric_list?.map((m: BIMetricCreated) => m._id) || [],
      };

      newState.edit.metricsFromList =
        payload.data.analysis_type === AnalysisType.FUNNEL
          ? payload.data.funnel_metric_list
              ?.map((id: string) =>
                FUNNEL_PREDEFINED_METRICS.find((m) => m._id === id)
              )
              .filter((m) => m !== undefined)
          : payload.data.metric_list;
    }
    return newState;
  })
  .case(widgetActions.fetchWidgetByIdLoading, (state) => ({
    ...state,
    edit: {
      ...state.edit,
      getStatus: 'loading',
    },
  }))
  .case(widgetActions.fetchAllWidgetsSuccess, (state, payload) => ({
    ...state,
    widgetsList: payload,
    widgetsListStatus: 'success',
  }))
  .case(widgetActions.fetchAllWidgetsLoading, (state) => ({
    ...state,
    widgetsListStatus: 'loading',
  }))
  .case(widgetActions.addMetricToMetricsFromList, (state, payload) => {
    const element = payload.widgetId ? state.edit : state.create;
    const newMetricsList = (element.metricsFromList || []).concat(
      payload.metric
    );

    const metricIds = newMetricsList
      .filter((el) => !el._id.startsWith('__FUNNEL__'))
      .map((el) => el._id);
    const funnelMetricIds = newMetricsList
      .filter((el) => el._id.startsWith('__FUNNEL__'))
      .map((el) => el._id);

    if (payload.widgetId) {
      return {
        ...state,
        edit: {
          ...state.edit,
          metricsFromList: newMetricsList,
          widget: state.edit.widget
            ? {
                ...state.edit.widget,
                metric_list: metricIds,
                funnel_metric_list: funnelMetricIds,
                chart_type:
                  state.edit.widget.analysis_type === AnalysisType.FUNNEL
                    ? state.edit.widget.chart_type
                    : newMetricsList.length > 1
                    ? 'table'
                    : state.edit.widget.chart_type === 'table'
                    ? 'column'
                    : state.edit.widget.chart_type,
              }
            : null,
        },
      };
    } else {
      return {
        ...state,
        create: {
          ...state.create,
          metricsFromList: newMetricsList,
          widget: {
            ...state.create.widget,
            metric_list: metricIds,
            funnel_metric_list: funnelMetricIds,
            chart_type:
              state.create.widget.analysis_type === AnalysisType.FUNNEL
                ? state.create.widget.chart_type
                : newMetricsList.length > 1
                ? 'table'
                : state.create.widget.chart_type === 'table'
                ? 'column'
                : state.create.widget.chart_type,
          },
        },
      };
    }
  })
  .case(widgetActions.removeMetricFromMetricsFromList, (state, payload) => {
    if (payload.widgetId) {
      const newMetricsList = state.edit.metricsFromList.filter(
        (m) => m._id !== payload.metricId
      );
      const metric_list_field =
        state.edit.widget?.analysis_type === AnalysisType.FUNNEL
          ? 'funnel_metric_list'
          : 'metric_list';
      return {
        ...state,
        edit: {
          ...state.edit,
          metricsFromList: newMetricsList,
          widget: state.edit.widget
            ? {
                ...state.edit.widget,
                [metric_list_field]: newMetricsList.map((m) => m._id),
                chart_type:
                  state.edit.widget.analysis_type === AnalysisType.FUNNEL
                    ? state.edit.widget.chart_type
                    : newMetricsList.length > 1
                    ? 'table'
                    : state.edit.widget.chart_type === 'table'
                    ? 'column'
                    : state.edit.widget.chart_type,
              }
            : null,
        },
      };
    } else {
      const newMetricsList = state.create.metricsFromList.filter(
        (m) => m._id !== payload.metricId
      );
      const metric_list_field =
        state.create.widget?.analysis_type === AnalysisType.FUNNEL
          ? 'funnel_metric_list'
          : 'metric_list';
      return {
        ...state,
        create: {
          ...state.create,
          metricsFromList: state.create.metricsFromList.filter(
            (m) => m._id !== payload.metricId
          ),
          widget: {
            ...state.create.widget,
            [metric_list_field]: newMetricsList.map((m) => m._id),
            chart_type:
              state.create.widget.analysis_type === AnalysisType.FUNNEL
                ? state.create.widget.chart_type
                : newMetricsList.length > 1
                ? 'table'
                : state.create.widget.chart_type === 'table'
                ? 'column'
                : state.create.widget.chart_type,
          },
        },
      };
    }
  })
  .case(widgetActions.updateMetricFromMetricsFromList, (state, payload) => {
    if (payload.widgetId) {
      const newMetricsList = state.edit.metricsFromList.map((m) =>
        m._id === payload.metricId ? payload.metric : m
      );
      return {
        ...state,
        edit: {
          ...state.edit,
          metricsFromList: newMetricsList,
          widget: state.edit.widget
            ? { ...state.edit.widget, metric_list: newMetricsList }
            : null,
        },
      };
    } else {
      const newMetricsList = state.create.metricsFromList.map((m) =>
        m._id === payload.metricId ? payload.metric : m
      );
      return {
        ...state,
        create: {
          ...state.create,
          metricsFromList: newMetricsList,
          widget: {
            ...state.create.widget,
            metric_list: newMetricsList,
          },
        },
      };
    }
  })
  .case(widgetActions.changeCreateWidgetPageSection, (state, payload) => {
    if (payload.widgetId) {
      return {
        ...state,
        edit: {
          ...state.edit,
          sectionExpanded: payload.section,
        },
      };
    } else {
      return {
        ...state,
        create: {
          ...state.create,
          sectionExpanded: payload.section,
        },
      };
    }
  })
  .case(widgetActions.changeWidgetInStore, (state, payload) => {
    if (payload.widgetId) {
      return {
        ...state,
        edit: {
          ...state.edit,
          widget: payload.widget as BIWidget,
        },
      };
    } else {
      return {
        ...state,
        create: {
          ...state.create,
          widget: payload.widget as BIWidgetPreview,
        },
      };
    }
  })
  .case(widgetActions.createWidgetSuccess, (state, payload) => ({
    ...state,
    widgetsList: state.widgetsList.concat(payload),
    widgetCreateStatus: 'success',
  }))
  .case(widgetActions.createWidgetLoading, (state) => ({
    ...state,
    widgetCreateStatus: 'loading',
  }))
  .case(widgetActions.updateWidgetSuccess, (state, payload) => ({
    ...state,
    widgetUpdateStatus: 'success',
    widgetsList: state.widgetsList.map((w) => {
      if (w._id === payload._id) {
        return payload;
      }
      return w;
    }),
  }))
  .case(widgetActions.updateWidgetLoading, (state) => ({
    ...state,
    widgetUpdateStatus: 'loading',
  }))
  .case(widgetActions.fetchColumnsListSuccess, (state, payload) => {
    if (payload.widgetId) {
      return {
        ...state,
        edit: { ...state.edit, columnList: payload.columnsList },
      };
    }
    return {
      ...state,
      create: { ...state.create, columnList: payload.columnsList },
    };
  })
  .case(widgetActions.resetWidgetStorage, (state) => {
    return {
      ...state,
      create: {
        widget: NEW_WIDGET_MOCK,
        sectionExpanded: optionSectionsEnum.type,
        metricsFromList: [],
        columnList: [],
      },
      edit: {
        widget: null,
        sectionExpanded: optionSectionsEnum.metric,
        metricsFromList: [],
        getStatus: 'notAsked',
        columnList: [],
      },
    };
  });
