import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';

import ICON_NO_DATA from 'assets/images/no_data_icon.svg';

const container = css`
  align-items: center;
  display: flex;
  height: 29px;

  img {
    margin: 0 auto;
  }
`;

const justifyCenter = css`
  justify-content: center;
`;

const justifyStart = css`
  justify-content: start;
`;

const justifyEnd = css`
  justify-content: end;
`;

const NotAvailableCell = ({
  placeholder,
  justify,
}: {
  placeholder?: string | number;
  justify?: 'start' | 'center' | 'end';
}) => (
  <div
    className={classNames(container, {
      [justifyCenter]: justify === 'center',
      [justifyStart]: justify === 'start',
      [justifyEnd]: justify === 'end',
    })}
  >
    {placeholder || <img src={ICON_NO_DATA} alt="No Data" />}
  </div>
);

export default NotAvailableCell;
