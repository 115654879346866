import * as s from './styles';
import classNames from 'classnames';
import React, { useContext, useMemo } from 'react';
import { Dropdown, Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { BIChartTypes } from 'common/enums/metrics';
import { formatAmount, formatMoney, shortNumber } from 'common/numbers';
import BuIcon from 'components/UI/BuIcon';
import BuPopup from 'components/UI/BuPopup';
import { SUPPORTED_DRILLDOWN_OBJECTS } from 'components/dashboard/Metrics/Widget/constants';
import { getUnits } from 'components/dashboard/Metrics/Widget/helper';
import { TooltipContent } from 'components/dashboard/Metrics/common/MetricTooltipContent/TooltipContent';
import { UnitType } from 'components/dashboard/Metrics/constants';
import { UserSettingsContext } from 'components/dashboard/Metrics/contexts/UserSettingsContext';
import {
  BIMetricColumn,
  BIMetrics,
  BIWidgetColumnV2,
  BIWidgetDataV2,
  BIWidgetErrorColumnV2,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  isPivoted: boolean;
  isMetricNameOnly: boolean;
  showControls: boolean;
  metricData: BIWidgetDataV2;
  type: BIChartTypes;
  pivot2?: BIMetricColumn;
  column: BIWidgetColumnV2 | BIWidgetErrorColumnV2;
  metric: BIMetrics;
  popupPosition: 'bottom left' | 'bottom center';
  metricsCount: number;
  onDataClick: (metricId: string) => void;
  onChartTypeChange: (metricId: string, chartType: BIChartTypes) => void;
  index?: number;
}

export const Total: React.FC<Props> = ({
  isPivoted = false,
  isMetricNameOnly = false,
  showControls = false,
  metricData,
  type,
  pivot2,
  column,
  metric,
  popupPosition,
  metricsCount = 0,
  onDataClick,
  onChartTypeChange,
  index = 0,
}) => {
  const hasErrorMessage = !!(column as BIWidgetErrorColumnV2).message;

  const chartTypesOptions = {
    pivot1: [
      {
        chartType: BIChartTypes.Column,
        icon: BoostUpIcons.ColumnGraph,
        displayName: 'Column chart',
        disabled: !metricData.hasOwnProperty('pivot_1') || metricsCount > 4,
      },
      {
        chartType: BIChartTypes.Line,
        icon: BoostUpIcons.LineGraph,
        displayName: 'Line chart',
        disabled: !metricData.hasOwnProperty('pivot_1') || metricsCount > 4,
      },
      {
        chartType: BIChartTypes.Pie,
        icon: BoostUpIcons.PieChart,
        displayName: 'Pie chart',
        disabled:
          !metricData.hasOwnProperty('pivot_1') ||
          metricData.hasOwnProperty('pivot_2') ||
          metricsCount > 1 ||
          metric?.is_hierarchy_rollup_sum,
      },
      {
        chartType: BIChartTypes.Table,
        icon: BoostUpIcons.Table,
        displayName: 'Table',
        disabled: metricsCount < 1 || (!!pivot2 && pivot2?.name !== 'none'),
      },
    ],
    pivot2: [
      {
        chartType: BIChartTypes.Table,
        icon: BoostUpIcons.Table,
        displayName: 'Table',
        disabled:
          metricsCount < 1 ||
          !pivot2 ||
          pivot2?.name === 'none' ||
          !metricData.hasOwnProperty('pivot_2') ||
          (!!metricData.pivot_2 &&
            metricData.pivot_2?.data.length > 0 &&
            !metricData.pivot_2?.data[0].hasOwnProperty(column.field_name)),
      },
      {
        chartType: BIChartTypes.ColumnStacked,
        icon: BoostUpIcons.StackedColumnGraph,
        displayName: 'Stacked column chart',
        disabled:
          metricsCount > 4 ||
          !pivot2 ||
          pivot2?.name === 'none' ||
          !metricData.hasOwnProperty('pivot_2') ||
          (!!metricData.pivot_2 &&
            metricData.pivot_2?.data.length > 0 &&
            !metricData.pivot_2?.data[0].hasOwnProperty(column.field_name)),
      },
    ],
  };

  const total = metricData?.pivot_0?.data[0]?.[column.field_name] ?? 0;

  const tooltipContent = useMemo(
    () => <TooltipContent metric={metric} total={total} />,
    [metric]
  );

  const { currency } = useContext(UserSettingsContext);
  const totalValue = useMemo(() => {
    if (typeof total === 'number') {
      return !(
        metric?.column?.type === 'money' &&
        metric?.aggregation_function !== 'count'
      )
        ? shortNumber(total)
        : total > 999999
        ? formatAmount(total, currency)
        : formatMoney(currency, total);
    } else {
      return '–';
    }
  }, [metric, total, currency]);

  const shouldItemUseAThirdOfSpace = metricsCount % 2 !== 0;

  return (
    <>
      <div
        data-testing={`total-${index}`}
        className={classNames([
          s.totalCard,
          {
            clickable: SUPPORTED_DRILLDOWN_OBJECTS.includes(
              metric?.object ?? ''
            ),
          },
          'totalCard',
          shouldItemUseAThirdOfSpace
            ? s.totalCardAThirdOfSpace
            : s.totalCardHalfSpace,
        ])}
        onClick={() => onDataClick(metric?._id || '')}
      >
        {hasErrorMessage ? (
          <div className={s.totalValueContainer}>
            <p className={s.totalTitle}>{column.display_name}</p>

            <BuPopup
              placement="top"
              trigger={(ref) => (
                <div className={s.noDataContainer} ref={ref}>
                  {<>-</>}
                </div>
              )}
            >
              <div className={s.popupInfoContainer}>
                {(column as BIWidgetErrorColumnV2).message}
              </div>
            </BuPopup>
          </div>
        ) : (
          <Popup
            key={column.metric_id}
            basic
            hoverable
            size={metric?.synthetic_metric ? 'large' : 'small'}
            position={popupPosition}
            disabled={metric === undefined}
            className={s.popup}
            trigger={
              isMetricNameOnly ? (
                <div className={s.metricNameOnlyContainer}>
                  <p className={s.metricNameParagraph}>{column.display_name}</p>
                </div>
              ) : (
                <div className={s.totalValueContainer}>
                  <p data-testing="total-title" className={s.totalTitle}>
                    {column.display_name}
                  </p>

                  <span data-testing="total-value" className={s.totalNumber}>
                    {getUnits(UnitType.Prefix, metric)}
                    {totalValue}
                    {getUnits(UnitType.Suffix, metric)}
                  </span>
                </div>
              )
            }
            content={tooltipContent}
          />
        )}

        {isPivoted && showControls && (
          <Dropdown
            data-testing="total-chart-type"
            className={classNames(
              s.totalChartTypeDropdown,
              'totalChartTypeDropdown'
            )}
            trigger={
              <BuIcon
                className={s.totalChartTypeIcon}
                name={
                  Object.values(chartTypesOptions)
                    .flatMap((el) => el)
                    .filter((el) => !el.disabled)
                    .find((el) => el.chartType === type)?.icon ||
                  BoostUpIcons.Table
                }
              />
            }
            icon={
              <BuIcon
                className={s.totalChartTypeDropdownIcon}
                name={BoostUpIcons.TriangleDown}
              />
            }
            fluid
            direction="left"
          >
            <Dropdown.Menu className={s.totalChartTypeDropdownMenu}>
              {chartTypesOptions.pivot1.some((el) => !el.disabled) && (
                <div className={s.pivotVisualizationLabel}>
                  1st pivot visualization
                </div>
              )}

              {chartTypesOptions.pivot1.some((el) => !el.disabled) &&
                chartTypesOptions.pivot1
                  .filter((el) => !el.disabled)
                  .map((el) => (
                    <div
                      data-testing={`${el.displayName
                        .toLowerCase()
                        .replace(' ', '-')}_option`}
                      key={el.chartType}
                      className={s.totalChartTypeDropdownOption}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onChartTypeChange(column.metric_id || '', el.chartType);
                      }}
                    >
                      <BuIcon name={el.icon} />
                      {el.displayName}
                    </div>
                  ))}

              {chartTypesOptions.pivot2.some((el) => !el.disabled) && (
                <div className={s.pivotVisualizationLabel}>
                  2nd pivot visualization
                </div>
              )}

              {chartTypesOptions.pivot2.some((el) => !el.disabled) &&
                chartTypesOptions.pivot2
                  .filter((el) => !el.disabled)
                  .map((el) => (
                    <div
                      data-testing={`${el.displayName
                        .toLowerCase()
                        .replace(' ', '-')}_option`}
                      key={el.chartType}
                      className={s.totalChartTypeDropdownOption}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onChartTypeChange(column.metric_id || '', el.chartType);
                      }}
                    >
                      <BuIcon name={el.icon} />
                      {el.displayName}
                    </div>
                  ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    </>
  );
};
