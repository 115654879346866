import moment from 'moment';

import {
  ACCOUNT,
  AnalysisType,
  OPPORTUNITY,
} from 'components/dashboard/Metrics/constants';
import {
  BIMetrics,
  BIMetricColumn,
  AggregationFunction,
  MetricType,
} from 'components/dashboard/Metrics/metrics.types';

export enum OptionSections {
  TYPE = 'type',
  METRIC = 'metric',
  FUNNEL = 'funnel',
  TEMPLATE_FILTERS = 'templateFilters',
}

export enum CreationType {
  METRIC = 'metric',
  WIDGET = 'widget',
}

export const SUPPORTED_REPORT_VIEW_OBJECTS = [OPPORTUNITY, ACCOUNT];

export const DEFAULT_OPPORTUNITIES_ORDER_BY: BIMetricColumn = {
  name: 'opportunity.last_touched',
  label: 'Last Touched',
  type: 'date',
};
export const DEFAULT_ACCOUNTS_ORDER_BY: BIMetricColumn = {
  name: 'account.open_opportunities',
  label: 'Open opportunities',
  type: 'number',
};

export const NEW_METRIC: Partial<BIMetrics> = {
  aggregation_function: 'sum',
  object: 'opportunity',
  name: '',
  filters: [],
  metadata: {
    type: 'simple',
  },
};

export const FUNNEL_PREDEFINED_METRICS = [
  {
    _id: '__FUNNEL__OBJ_COUNT',
    name: 'Deal Count',
    description: 'Number of deals in the stage',
    aggregation_function: AggregationFunction.Count,
    column: {
      name: 'opportunity.opportunity_name',
      label: 'Opportunity Name',
      type: 'text',
    },
  },
  {
    _id: '__FUNNEL__OBJ_COUNT_CONVERSION',
    name: 'Stage Conversion Rate (Count)',
    description: 'Conversion rate on the number of deals in the stage',
    aggregation_function: AggregationFunction.Count,
    column: {
      name: 'opportunity.opportunity_name',
      label: 'Opportunity Name',
      type: 'text',
    },
  },
  {
    _id: '__FUNNEL__OBJ_AMOUNT',
    name: 'Deal Amount',
    description: 'Total amount of deals in the stage',
    aggregation_function: AggregationFunction.Sum,
    column: {
      name: 'opportunity.amount',
      label: 'Amount',
      type: 'text',
    },
  },
  {
    _id: '__FUNNEL__OBJ_AMOUNT_CONVERSION',
    name: 'Stage Conversion Rate (Amount)',
    description: 'Conversion rate on the amount of deals in the stage',
    aggregation_function: AggregationFunction.Sum,
    column: {
      name: 'opportunity.amount',
      label: 'Amount',
      type: 'text',
    },
  },
  {
    _id: '__FUNNEL__AVG_DAYS',
    name: 'Average Days',
    description:
      'Average number of days between entering and exiting the stage',
    aggregation_function: AggregationFunction.Average,
    column: {
      name: '',
      label: 'Days in Stage',
      type: 'text',
    },
  },
  {
    _id: '__FUNNEL__WIN_RATE_COUNT',
    name: 'Win Rate (count)',
    description: 'Conversion rate of deals that went into the last stage',
    aggregation_function: AggregationFunction.Count,
    column: {
      name: '',
      label: 'Days in Stage',
      type: 'text',
    },
  },
  {
    _id: '__FUNNEL__WIN_RATE_AMOUNT',
    name: 'Win Rate (amount)',
    description: 'Conversion rate of the amount that went into the last stage',
    aggregation_function: AggregationFunction.Sum,
    column: {
      name: '',
      label: 'Amount',
      type: 'text',
    },
  },
].map((metric) => ({
  ...metric,
  is_independent: true,
  company_id: 'NONE',
  created_at: moment().toISOString(),
  updated_at: moment().toISOString(),
  analysis_type: AnalysisType.FUNNEL,
  filters: [],
  metadata: { type: MetricType.Simple },
  object: 'opportunity',
}));

export const FUNNEL_PREDEFINED_TOP_METRICS = [
  {
    _id: '__FUNNEL__DEAL_COUNT_OVERALL',
    name: 'Overall Conversion Rate',
    description: 'Conversion Rate of entire funnel',
    aggregation_function: AggregationFunction.Count,
    column: {
      name: 'opportunity.opportunity_name',
      label: 'Opportunity Name',
      type: 'text',
    },
  },
  {
    _id: '__FUNNEL__AVG_DEAL_CYCLE',
    name: 'Average Days in Funnel',
    description:
      'Average number of days between entering and exiting the funnel',
    aggregation_function: AggregationFunction.Average,
    column: {
      name: 'avg_days_in_funnel',
      label: 'Days in stage',
      type: 'text',
    },
  },
].map((metric) => ({
  ...metric,
  is_independent: true,
  company_id: 'NONE',
  created_at: moment().toISOString(),
  updated_at: moment().toISOString(),
  analysis_type: AnalysisType.FUNNEL,
  filters: [],
  metadata: { type: MetricType.Simple },
  object: 'opportunity',
}));
