import { css } from 'emotion';

export const fixedFilters = css`
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10;
  border-bottom: 1px solid transparent;

  &.scrolled {
    z-index: 905;
    border-bottom: 1px solid #dddddd;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 20px 10px 20px;
  }
`;

export const wrapper = css`
  padding: 0 20px 20px 20px;
`;

export const charts = css`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 10px;
  z-index: 992;
`;

export const chartRow = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const pagePlaceholder = css`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20% 0;
  font-family: var(--bu-font-regular);
  font-size: 16px;
`;

export const chartWrapper = (width: number) => css`
  width: ${width}%;
`;

export const backButton = css`
  box-sizing: border-box;
  height: 32px;
  width: 92px;
  border: 1px solid var(--bu-gray-300);
  border-radius: 4px;
  background: var(--bu-white);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-family: var(--bu-font-regular);
  font-size: 14px;
  color: #4a4a4a;
  transition: 0.5s;

  &:hover {
    background: #044eb8;
    color: var(--bu-white);
  }
`;

export const groupButtons = css`
  margin: 10px 0px 20px !important;

  &.ui.buttons {
    & > .ui.button.basic {
      padding: 8px 12px;

      &:first-child {
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
      }

      &:last-child {
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
      }

      &.blue {
        color: #9b9b9b !important;
        background-color: #fff !important;
        box-shadow: 0 0 0 1px #0762ec inset !important;

        &.active {
          background-color: #cfeafd !important;
          color: #0762ec !important;
        }
      }
    }
  }
`;

export const groupButtonContainer = css`
  position: sticky;
  top: 95px;
  height: 55px;
  z-index: 8;
  background: #fff;
`;

export const heightChart = css`
  .segment {
    height: 100%;
    .chart-wrapper {
      border-radius: 4px;
    }
  }
`;
