import * as styles from './styles';
import { IProps } from './types';
import classNames from 'classnames';
import { isNil } from 'ramda';
import React, { useState, useEffect, useRef, KeyboardEvent } from 'react';
import { Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { useStateWhile } from 'common/helpers';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import dealStyles from 'components/deal/styles';

const EditableText = ({
  value,
  forceRefresh,
  disabled,
  onEdit,
  type,
  formatter,
  status,
  currencyCode,
  showIcon,
  ...rest
}: IProps) => {
  const [editing, setEditing] = useState(false);
  const [hovering, setHovering] = useState<boolean>(false);
  const [text, setText] = useState(value);
  const [savedText, setSavedText] = useState(value);
  const [skipSave, setSkipSave] = useState<boolean>(true);
  const [showStatus, setShowStatus] = useStateWhile(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setText(value);
  }, [forceRefresh]);

  useEffect(() => {
    if (status && status.date + 5000 > Date.now()) {
      setShowStatus(true, 5000);
    }
  }, [status && status.date]);

  useEffect(() => {
    if (hovering) {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [hovering]);

  const onEditClick = () => {
    if (disabled) {
      return;
    }
    setHovering(!hovering);
  };

  const onAccept = () => {
    if (!skipSave && typeof text === 'string') {
      onEdit(text);
      setSavedText(text);
    }
    setEditing(false);
    setHovering(false);
    setSkipSave(true);
  };

  const onCancel = () => {
    setText(savedText);
    setHovering(false);
    setEditing(false);
  };

  const mouseLeaveInput = () => {
    if (!editing) {
      setHovering(false);
    }
  };
  const onKey = (event: KeyboardEvent<HTMLInputElement>) => {
    switch (true) {
      case event.key === 'Escape':
        setSkipSave(true);
        onCancel();
        break;
      case event.key === 'Enter':
        setHovering(false);
        event.currentTarget.blur();
        break;
      case skipSave && event.key.includes('Arrow'):
        setSkipSave(true);
        break;
      default:
        setSkipSave(false);
        break;
    }
  };

  const displayClasses = classNames({
    [styles.displayValue]: true,
    [styles.editable]: !disabled,
    [styles.hidden]: hovering,
    [status ? status.status : '']: showStatus,
  });

  const displayText =
    !isNil(text) && formatter ? formatter(text) : text || 'Not Available';

  const displayValue = (
    <section className={displayClasses} onMouseOver={onEditClick}>
      {displayText.length > 10 ? (
        <Popup
          className={styles.textTruncate}
          hoverable
          position="bottom left"
          trigger={<span className={styles.textTruncate}>{displayText}</span>}
          content={<span>{displayText}</span>}
          offset={[0, 0]}
        />
      ) : (
        displayText
      )}
    </section>
  );

  if (disabled) {
    return <section className={styles.editableBox}>{displayValue}</section>;
  }
  const inputClasses = classNames({
    [styles.inputWrapper]: true,
    [styles.hidden]: !hovering,
  });

  return (
    <section className={styles.editableBox}>
      {displayValue}
      {showIcon && (
        <BuIcon className={dealStyles.pencilIcon} name={BoostUpIcons.Pencil} />
      )}
      <div className={inputClasses} onMouseLeave={mouseLeaveInput}>
        <BuInput
          onFocus={() => setEditing(true)}
          type={type}
          code={currencyCode}
          value={text || ''}
          onChange={(event) => setText(event.currentTarget.value)}
          onBlur={onAccept}
          handleKeyDown={(event) => onKey(event)}
          isAlignLeftText
          {...rest}
        />
      </div>
    </section>
  );
};

export default EditableText;
