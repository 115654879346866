import styled from '@emotion/styled';
import { css } from 'emotion';

export const TooltipContainer = styled.div();
TooltipContainer.defaultProps = { className: 'bu-font-default' };

export const TooltipHeader = styled.p();
TooltipHeader.defaultProps = { className: 'bu-font-subtitle' };

export const TooltipAmountLink = styled.a`
  color: var(--bu-primary-500);
  &:hover {
    color: var(--bu-primary-500);
    text-decoration: underline;
  }
`;

export const WidgetContainer = styled.div`
  border: 1px solid var(--bu-gray-400);
  border-radius: var(--bu-control-border-radius);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const WidgetControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 60px;
  border-bottom: 1px solid var(--bu-gray-400);
`;

export const WidgetControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const WidgetLegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  padding: 10px 0;
  border-bottom: 1px solid var(--bu-gray-400);
`;

export const WidgetChart = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 200px;

  & .highcharts-label-box.highcharts-data-label-box {
    opacity: 0.4;
  }
`;

export const WidgetInfoIcon = styled.div`
  img {
    margin-top: 5px;
    width: 22px;
  }
`;

export const DropdownLabel = styled.span`
  color: var(--bu-primary-500);
  text-decoration: underline dashed var(--bu-primary-500) 1px;
  text-underline-offset: 5px;
`;
DropdownLabel.defaultProps = {
  className: 'bu-font-heading',
};

export const positiveSentimentCell = css`
  background-color: var(--bu-green-200);
`;

export const negativeSentimentCell = css`
  background-color: var(--bu-red-200);
`;

export const forecastPipelineStageCell = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;
  margin: -2px -10px;
`;

export const clickableAmount = css`
  cursor: pointer;

  color: var(--bu-clickable-cell);

  tr:hover & {
    color: var(--bu-clickable-cell__hover);
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const dealsTransfered = css`
  color: var(--bu-gray-500);
  font-size: 12px;
`;

export const tooltipAmountCell = css`
  color: var(--bu-primary-500);
  &:hover {
    color: var(--bu-primary-500);
    text-decoration: underline;
    cursor: pointer;
  }
`;
// & > thead > tr:nth-of-type(2) > th:nth-of-type(3)
// and & > thead > tr:nth-of-type(2) > th:nth-last-of-type(1)
// are hacks to
// target the first transfer column header (third column of the second row of the table header )
// and the ending values column header (last column of the second row of the table header )
// we have a classname property on the column to apply classnames
// But there is a bug when sending a function, as it wont be applied to
// the column header, only to the column body

export const tableStyle = css`
overflow-x: auto;
.ui.table {
  &
    > tbody
    > tr
    > td.section-delimiter,
    &
    > thead
    > tr
    > th.section-delimiter,
    & > thead > tr:nth-of-type(2) > th:nth-of-type(3), 
    & > thead > tr:nth-of-type(2) > th:last-child
    {
    border-left: 1px solid var(--bu-gray-500);
  }

.totalRow { 
    border-top: 1px solid var(--bu-gray-500);
    background-color: var(--bu-gray-100);
}

.totalSentimentRow { 
  background-color: var(--bu-gray-100);
}
`;
