import { css } from 'emotion';

export const labelParagraph = css`
  color: var(--bu-gray-700);
  font-size: 12px;
  line-height: 130%;
  margin-top: 8px !important;
  margin-bottom: 4px !important;
`;

export const conditionsList = css`
  padding-left: 20px;
  margin-bottom: 0;
`;

export const conditionElement = css`
  font-size: 12px;
`;
