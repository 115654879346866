import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { sortAlphabetically } from 'common/helpers';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import BuSelect from 'components/UI/BuSelect';
import { DateTypeCondition } from 'components/dashboard/Metrics/Create/Conditions/Condition/DateTypeCondition/DateTypeCondition';
import { NumberTypeCondition } from 'components/dashboard/Metrics/Create/Conditions/Condition/NumberTypeCondition/NumberTypeCondition';
import { PickListTypeCondition } from 'components/dashboard/Metrics/Create/Conditions/Condition/PickListTypeCondition/PickListTypeCondition';
import { TargetPeriodTypeCondition } from 'components/dashboard/Metrics/Create/Conditions/Condition/TargetPeriodTypeCondition/TargetPeriodTypeCondition';
import { UserTypeCondition } from 'components/dashboard/Metrics/Create/Conditions/Condition/UserTypeCondition/UserTypeCondition';
import {
  BOOLEAN_OPERATION_DROPDOWN_OPTIONS,
  BOOLEAN_VALUE_DROPDOWN_OPTIONS,
  NULL_OPERATOR_VALUES,
  NUMBER_OPERATION_DROPDOWN_OPTIONS,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/constants';
import {
  FilterColumnDropdownContainer,
  FixedWidthContainer,
  FlexColumn,
  FlexGrowContainer,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/styles';
import { columnsToDropdownOptions } from 'components/dashboard/Metrics/Create/helpers';
import {
  BOOL_COLUMN_TYPES,
  DATE_COLUMN_TYPES,
  NUMBER_COLUMN_TYPES,
  PICKLIST_COLUMN_TYPES,
  SINGLE_SELECT_COLUMN_TYPES,
  TEXT_COLUMN_TYPES,
} from 'components/dashboard/Metrics/constants';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import { RemoveIconContainer } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIMetricColumn,
  BIMetricsFilter,
  PicklistOptions,
} from 'components/dashboard/Metrics/metrics.types';
import { fetchApi } from 'utils/network';

export const Condition: React.FC<{
  columnList: BIMetricColumn[];
  filter: Partial<BIMetricsFilter>;
  targetPeriod: string;
  updateFilter: (newFilter: Partial<BIMetricsFilter>) => void;
  onRemove: () => void;
}> = ({ columnList, filter, targetPeriod, updateFilter, onRemove }) => {
  const [picklistValues, setPicklistValues] = useState<PicklistOptions[]>([]);

  const [filterColumns, filterColumnsDropdownOptions] = useMemo(() => {
    const filterColumns = columnList.reduce((pre, column) => {
      if (
        PICKLIST_COLUMN_TYPES.has(column.type) ||
        NUMBER_COLUMN_TYPES.has(column.type) ||
        DATE_COLUMN_TYPES.has(column.type) ||
        BOOL_COLUMN_TYPES.has(column.type) ||
        SINGLE_SELECT_COLUMN_TYPES.has(column.type)
      ) {
        pre.push(column);
      }
      return pre;
    }, [] as BIMetricColumn[]);

    return [filterColumns, columnsToDropdownOptions(filterColumns)];
  }, [columnList]);

  useEffect(() => {
    if (
      filter?.column?.label &&
      filter?.column?.name &&
      filter?.column?.type &&
      (PICKLIST_COLUMN_TYPES.has(filter.column.type) ||
        SINGLE_SELECT_COLUMN_TYPES.has(filter.column.type))
    ) {
      fetchApi<BIMetricColumn, PicklistOptions[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/company_settings/get_column_values`,
        queryMethod: 'post',
        queryParams: {
          label: filter.column.label,
          name: filter.column.name,
          type: filter.column.type,
        },
        setData: (result) => {
          if (SINGLE_SELECT_COLUMN_TYPES.has(filter?.column?.type ?? '')) {
            // Special case for companies like Redsift which have 'All' business type
            const allIndex = result.findIndex(
              (v) => v.value.toLocaleLowerCase() === 'all'
            );
            if (allIndex !== -1) {
              result[allIndex] = {
                ...result[allIndex],
                value: '-ALL-',
                display_name: '-ALL-',
              };
            }
          }

          setPicklistValues(
            // Filter to remove duplicates which are causing issues
            result.filter(
              (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i
            )
          );
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load condition values: ${error}`);
        },
      });
    }
  }, [filter?.column?.name]);

  const getDefaultOperator = (column: BIMetricColumn): string => {
    const columnType = column?.type ?? '';
    if (BOOL_COLUMN_TYPES.has(columnType)) {
      return 'eq';
    }
    if (
      PICKLIST_COLUMN_TYPES.has(columnType) ||
      SINGLE_SELECT_COLUMN_TYPES.has(columnType)
    ) {
      return 'in';
    }
    if (NUMBER_COLUMN_TYPES.has(columnType)) {
      return NUMBER_OPERATION_DROPDOWN_OPTIONS[0].value;
    }

    return '';
  };

  const handleColumnSelect = (selectedColumn: string): void => {
    const column = columnList.find((column) => column.name === selectedColumn);
    const operator = column ? getDefaultOperator(column) : '';
    const value = BOOL_COLUMN_TYPES.has(column?.type ?? '') ? true : '';
    updateFilter({
      column,
      operator,
      value,
    });
  };

  const handleOperatorSelect = (operator: string): void => {
    if (!NULL_OPERATOR_VALUES.includes(operator)) {
      // operator is not in nullOperatorValues
      // check if the filter.value is 'is_null' or 'is_not_null'
      // if it is, remove the value. otherwise preserve it.
      const value =
        typeof filter?.value === 'string' &&
        NULL_OPERATOR_VALUES.includes(filter?.value)
          ? ''
          : filter?.value;
      updateFilter({
        column: filter.column,
        operator,
        value,
      });
    } else {
      // operator in nullOperatorValues
      // backend expect value is same as the operator
      updateFilter({
        ...filter,
        operator,
        value: operator,
      });
    }
  };

  const handleTextValueChange = (text: string): void => {
    if (!filter?.column?.type) {
      return;
    }
    if (filter?.column?.type === 'picklist') {
      updateFilter({
        ...filter,
        value: text.split(','),
      });
    } else {
      updateFilter({
        ...filter,
        value: text,
      });
    }
  };

  const handleBoolValueChange = (value: string): void => {
    updateFilter({
      ...filter,
      value: value !== 'no',
    });
  };

  const handleSingleSelectValueChange = (values: string[]): void => {
    // Special case for companies like Redsift which have 'All' business type
    updateFilter({
      ...filter,
      value: values[0] === '-ALL-' ? ['All'] : values,
    });
  };

  const handlePicklistChange = (values: string[]): void => {
    if (values.some((el) => el === '__all_active__')) {
      updateFilter({
        ...filter,
        value: picklistValues
          .filter(
            (el) =>
              el.is_active &&
              el.value !== '__all__' &&
              el.value !== '__all_active__'
          )
          .map((el) => el.value),
      });
      return;
    }

    if (values.some((el) => el === '__all__')) {
      updateFilter({
        ...filter,
        value: picklistValues
          .filter(
            (el) => el.value !== '__all__' && el.value !== '__all_active__'
          )
          .map((el) => el.value),
      });
      return;
    }

    updateFilter({ ...filter, value: values });
  };

  const columnType = useMemo(
    () =>
      filterColumns.find((column) => column.name === filter?.column?.name)
        ?.type || '',
    [filter?.column?.name, filterColumns]
  );

  const filterOptions = useMemo(
    () =>
      sortAlphabetically(
        filterColumnsDropdownOptions.map((column) => ({
          text: column.label,
          value: column.value,
        }))
      ),
    [filterColumnsDropdownOptions]
  );

  const isUserFilter = filter.column?.name === 'user.name';

  return (
    <FlexColumn>
      <FlexRow>
        <FilterColumnDropdownContainer>
          <BuSelect
            fullWidth
            isLargePlaceholder
            searchable
            secondary
            options={filterOptions}
            onChange={(values: string[]) => {
              handleColumnSelect(values[0]);
            }}
            placeholder={'Select a column'}
            testingLabel="column"
            defaults={[filter.column ? filter.column.name : '']}
            className={'select'}
          />
        </FilterColumnDropdownContainer>
        <RemoveIconContainer onClick={onRemove}>
          <BuIcon name={BoostUpIcons.Trash} />
        </RemoveIconContainer>
      </FlexRow>

      {NUMBER_COLUMN_TYPES.has(columnType) && (
        <NumberTypeCondition
          columnType={columnType}
          filter={filter}
          onOperatorSelect={handleOperatorSelect}
          onTextValueChange={handleTextValueChange}
        />
      )}

      {SINGLE_SELECT_COLUMN_TYPES.has(columnType) && (
        <FlexRow>
          <FixedWidthContainer width={20}>is</FixedWidthContainer>
          <FlexGrowContainer data-testing="operator-value">
            <BuSelect
              fullWidth
              secondary
              options={(picklistValues ?? []).map((value: PicklistOptions) => ({
                text: value.display_name,
                value: value.value,
              }))}
              onChange={handleSingleSelectValueChange}
              testingLabel="is-values"
              defaults={
                // Special case for companies like Redsift which have 'All' business type
                (filter?.value as string[])?.[0] === 'All'
                  ? ['-ALL-']
                  : ((filter?.value ?? []) as string[])
              }
            />
          </FlexGrowContainer>
        </FlexRow>
      )}

      {PICKLIST_COLUMN_TYPES.has(columnType) && (
        <>
          {isUserFilter ? (
            <UserTypeCondition
              filter={filter}
              onOperatorSelect={handleOperatorSelect}
              onChange={handlePicklistChange}
            />
          ) : (
            <PickListTypeCondition
              filter={filter}
              picklistValues={picklistValues}
              onOperatorSelect={handleOperatorSelect}
              onChange={handlePicklistChange}
            />
          )}
        </>
      )}

      {/*
      free text field may not be support for filter
       */}
      {TEXT_COLUMN_TYPES.has(columnType) && (
        <FlexRow>
          <FixedWidthContainer width={70}>contains</FixedWidthContainer>
          <FlexGrowContainer data-testing="operator-value">
            <BuInput
              testingLabel="contains"
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                const newValue = e.currentTarget.value;
                handleTextValueChange(newValue);
              }}
              value={
                Array.isArray(filter.value)
                  ? filter.value.join(',')
                  : (filter.value as string)
              }
            />
          </FlexGrowContainer>
        </FlexRow>
      )}

      {DATE_COLUMN_TYPES.has(columnType) && (
        <>
          {Boolean(targetPeriod) ? (
            <TargetPeriodTypeCondition
              filter={filter}
              targetPeriod={targetPeriod}
              setFilter={updateFilter}
            />
          ) : (
            <DateTypeCondition
              filter={filter}
              onOperatorSelect={handleOperatorSelect}
              setFilter={updateFilter}
            />
          )}
        </>
      )}

      {BOOL_COLUMN_TYPES.has(columnType) && (
        <FlexRow cssProps={{ gap: '12px' }}>
          <FixedWidthContainer width={100}>
            <BuSelect
              options={BOOLEAN_OPERATION_DROPDOWN_OPTIONS}
              onChange={(values: string[]) => {
                handleOperatorSelect(values[0]);
              }}
              secondary
              placeholder=""
              defaults={[
                filter.operator
                  ? filter.operator
                  : BOOLEAN_OPERATION_DROPDOWN_OPTIONS[0].value,
              ]}
              fullWidth
              isLargePlaceholder
              testingLabel="operator"
            />
          </FixedWidthContainer>

          {!NULL_OPERATOR_VALUES.includes(filter.operator ?? '') && (
            <FlexGrowContainer data-testing="operator-value">
              <BuSelect
                options={BOOLEAN_VALUE_DROPDOWN_OPTIONS}
                onChange={(values: string[]) => {
                  handleBoolValueChange(values[0]);
                }}
                secondary
                placeholder=""
                defaults={[
                  filter.value === false
                    ? (BOOLEAN_VALUE_DROPDOWN_OPTIONS.find(
                        (boolean) => boolean.value === 'no'
                      )?.value as string)
                    : BOOLEAN_VALUE_DROPDOWN_OPTIONS[0].value,
                ]}
                fullWidth
                isLargePlaceholder
                testingLabel="boolean-values"
              />
            </FlexGrowContainer>
          )}
        </FlexRow>
      )}
    </FlexColumn>
  );
};
