export const METRIC = 'METRIC';
export const METRIC_TIME_SERIES = 'METRIC_TIME_SERIES';
export const WIDGET = 'REVBI_WIDGET';
export const CREATE_METRIC_TAB = 'CREATE_METRIC_TAB';
export const COLUMN_FIELDS_FILTER = 'COLUMN_FIELDS_FILTER';
export const COLUMN_FIELDS = 'COLUMN_FIELDS';
export const VALIDATE = '_VALIDATE';
export const FORMULA_VALID = 'FORMULA_VALID';
export const METRIC_FORMULA = 'METRIC_FORMULA';
export const ACTIVE_METRIC = 'ACTIVE_METRIC';
export const OBJECT_LIST = 'OBJECT_LIST';
export const CHANGE = '_CHANGE';
export const SUCCESS = '_SUCCESS';
export const LOADING = '_LOADING';
export const FAILED = '_FAILED';
export const GET = '_GET';
export const UPDATE = '_UPDATE';
export const CLONE = '_CLONE';
export const CREATE = '_CREATE';
export const ADD = '_ADD';
export const REMOVE = '_REMOVE';
export const DELETE = '_DELETE';
export const READY = '_READY';
export const CLEAN_UP_CREATION_STATE = 'CLEAN_UP_CREATION_STATE';
export const CREATE_METRIC_COMPONENT_MODE = 'CREATE_METRIC_COMPONENT_MODE';
export const WIDGET_CREATION_METRICS_LIST = 'WIDGET_CREATION_METRICS_LIST';
export const PREVIEW_WIDGET = 'PREVIEW_WIDGET';
export const ALL = '_ALL';
export const FOR_WIDGET = '_FOR_WIDGET';
export const WIDGET_CREATION_SECTION = 'WIDGET_CREATION_SECTION';
export const EXTENDED = '_EXTENDED';
export const TIME_SERIES = '_TIME_SERIES';
