import styled from '@emotion/styled';
import classNames from 'classnames';
import { css } from 'emotion';
import debounce from 'lodash/debounce';
import { isNil } from 'ramda';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  CROUserSubmission,
  SubmissionColumn,
  SubmissionSettingsId,
  UserSubmission,
} from 'actions/croOverrideActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuDropdown, {
  BuDropdownItem,
  BuDropdownItemContainer,
  BuDropdownItemGroup,
  BuDropdownNonClickableItem,
  BuDropdownWrapper,
} from 'components/UI/BuDropdown';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import BuRadio from 'components/UI/BuRadio';
import { fixedTo } from 'components/UI/TableConfig/column-helper';
import Scrolling from 'components/UI/common/Scrolling/Scrolling';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import {
  CustomComponentFunction,
  getCellValue,
  getFieldValue,
} from 'components/UI/common/TypedTable/renderers/custom/common';
import { Sub } from 'components/dashboard/CROOverride/cell-renderers/forecastCRO';
import {
  isCRORow,
  RowSubmission,
  SubmissionTouched,
  SubmissionTouchedFieldName,
} from 'components/dashboard/CROOverride/types';

export const SummaryWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 5px;
`;

export const SummaryButton = styled.button`
  background: transparent;
  border: 0;
  font-size: 0.8em;
  padding: 0;

  &:enabled {
    color: var(--bu-primary-500);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 4px 0;
  position: relative;
`;

const DropdownForecastItem = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 5px 15px;

  &.active {
    background-color: var(--bu-primary-200);
    top: 0;
    bottom: 0;
    position: sticky;
    z-index: 100;
  }
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
`;

const Total = styled.div`
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 0.9em;
`;

const Fluid = styled.div`
  flex: 1;
`;

const actionButton = css`
  &,
  &.bu-button-icon {
    margin: 0;
    border: none;
    background-color: var(--bu-primary-500);
    color: var(--bu-white);

    &:enabled {
      &:hover {
        background-color: var(--bu-primary-700);
      }
    }
  }
`;

const circleIconButton = css`
  &.bu-button-icon {
    border-radius: 100%;
    height: 22px;
    width: 22px;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 9px;

    border-color: var(--bu-gray-400);
    color: var(--bu-primary-500);
    background-color: var(--bu-gray-300);

    &:enabled {
      &:hover,
      &.bu-dropdown-open {
        border: none;
        color: var(--bu-white);
        background-color: var(--bu-primary-500);
      }
    }
  }
`;

const SubmissionLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const DraftTag = styled.span`
  background-color: var(--bu-primary-500);
  padding: 0 6px;
  color: var(--bu-white);
  border-radius: var(--bu-control-border-radius);
  font-size: 0.8em;
`;

const SubmissionLabel: React.FC<{
  title: SubmissionColumn['display_name'];
  data: SubmissionTouched<
    Partial<
      Pick<
        CROUserSubmission,
        | 'override_included_deals_amount'
        | 'override_included_deals_ids'
        | 'override_excluded_deals_ids'
      >
    >
  >;
  currencyFormatter(value: number): string;
}> = ({
  title,
  data: {
    override_included_deals_amount,
    override_included_deals_ids,
    override_excluded_deals_ids,
    [SubmissionTouchedFieldName]: isTouched,
  },
  currencyFormatter,
}) => {
  const total_deals =
    (override_excluded_deals_ids?.length ?? 0) +
    (override_included_deals_ids?.length ?? 0);

  return (
    <label>
      <SubmissionLabelContainer>
        <div>
          <div>{title}</div>
          <Sub>
            {override_included_deals_ids?.length ?? 0} of {total_deals} (
            {currencyFormatter(override_included_deals_amount ?? 0)})
          </Sub>
        </div>
        {isTouched && <DraftTag>Draft</DraftTag>}
      </SubmissionLabelContainer>
    </label>
  );
};

type CopySubmissionSettingsProps = {
  submissions: Array<{
    title: SubmissionColumn['display_name'];
    submissionSettingsId: SubmissionColumn['submission_settings_id'];
    data: CROUserSubmission;
  }>;
  onChange(submissionSettingId: SubmissionSettingsId): void;
  currencyFormatter(value: number): string;
};

const CopySubmission: React.FC<CopySubmissionSettingsProps> = ({
  submissions,
  onChange,
  currencyFormatter,
}) => {
  const [selectedSubmissionId, setSelectedSubmissionId] =
    useState<SubmissionSettingsId>();

  const handleApply = () => {
    if (selectedSubmissionId) {
      onChange(selectedSubmissionId);
    }
  };

  return (
    <BuDropdown
      className={circleIconButton}
      label={<BuIcon name={BoostUpIcons.Clipboard} />}
      icon
      noDropdownIcon
      placement="bottom-end"
    >
      {(hide) => (
        <BuDropdownItemContainer style={{ textAlign: 'left', minWidth: 400 }}>
          <BuDropdownNonClickableItem className="no-hover bu-font-heading">
            Copy Deals from
          </BuDropdownNonClickableItem>

          <BuDropdownItemGroup>
            <Scrolling alwaysShow shadows maxHeight={400} height>
              {submissions.map((item) => (
                <DropdownForecastItem
                  key={item.submissionSettingsId}
                  className={classNames({
                    active: item.submissionSettingsId === selectedSubmissionId,
                  })}
                >
                  <BuRadio
                    onChange={(e, { value }) =>
                      setSelectedSubmissionId(value as SubmissionSettingsId)
                    }
                    value={item.submissionSettingsId}
                    checked={item.submissionSettingsId === selectedSubmissionId}
                    label={
                      <SubmissionLabel
                        title={item.title}
                        data={item.data}
                        currencyFormatter={currencyFormatter}
                      />
                    }
                  />
                </DropdownForecastItem>
              ))}
            </Scrolling>
          </BuDropdownItemGroup>

          <BuDropdownNonClickableItem className="no-hover">
            <BuButton
              disabled={!selectedSubmissionId}
              onClick={() => {
                hide();
                handleApply();
              }}
            >
              Apply
            </BuButton>
            <BuButton secondary onClick={hide}>
              Cancel
            </BuButton>
          </BuDropdownNonClickableItem>
        </BuDropdownItemContainer>
      )}
    </BuDropdown>
  );
};

const isAmountNumber = (amount: number | undefined | null): amount is number =>
  !isNil(amount);

export interface MyCallCROCellConfig {
  currencySymbol: string;
  symbolOnLeft: boolean;
  submissions: SubmissionColumn[];
  submissionSettingsId: SubmissionSettingsId;
  currencyFormatter(value: number): string;
  onNoteEditorOpen(column: IColumn, row: IRow, rows: IRow[]): void;
  onSelectDealsClick(column: IColumn, row: IRow): void;
  onCopySubmissionSettings(
    submissionSettingsIdFrom: SubmissionSettingsId,
    submissionSettingsIdTo: SubmissionSettingsId
  ): void;
}
const myCallCROCell: CustomComponentFunction<MyCallCROCellConfig> =
  ({
    currencySymbol,
    symbolOnLeft,
    currencyFormatter,
    onNoteEditorOpen,
    onSelectDealsClick,
    submissions,
    onCopySubmissionSettings,
    submissionSettingsId,
  }) =>
  (props) => {
    const [value, setValue] = useState<number | ''>('');

    const { column, row, rows, onChange = () => {} } = props;
    const rowT = row as RowSubmission;

    const copySubmissionsOptions = useMemo(() => {
      return isCRORow(rowT)
        ? submissions.map((item) => ({
            title: item.display_name,
            submissionSettingsId: item.submission_settings_id,
            data: rowT.submissions[item.submission_settings_id],
          }))
        : [];
    }, [submissions, rowT.submissions]);

    const submission = getCellValue(props) as CROUserSubmission;
    const {
      last_submission_amount,
      override_amount: amount,
      override_excluded_deals_ids: excludedDeals = [],
      override_included_deals_ids: includedDeals = [],
      override_included_deals_amount: dealsAmount,
    } = submission ?? {};

    const dealsIncluded = includedDeals.length;
    const dealsTotal = dealsIncluded + excludedDeals.length;

    const sumFieldPath: MapPaths.Paths<Pick<RowSubmission, 'submissions'>> = [
      'submissions',
      submissionSettingsId,
      'override_amount',
    ];

    const childrenRows = row.children || [];

    const isForecastExists = !isNil(submission);

    useEffect(() => {
      setValue(amount === null || isNaN(amount) ? '' : amount);
    }, [amount]);

    const triggerChange = useCallback(
      debounce((value: number | null) => onChange(column, row, value), 500),
      [column, row]
    );

    const onHintClick = useCallback(
      (handleCloseDropdown: () => void, value: number) => () => {
        handleCloseDropdown();
        onChange(column, row, value);
      },
      [column, row]
    );

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
      const newValue = e.currentTarget.valueAsNumber;
      if (value !== newValue) {
        setValue(isNaN(newValue) ? '' : newValue);
        triggerChange(isNaN(newValue) ? null : newValue);
      }
    };

    const handleOpenDealsModal = () => {
      onSelectDealsClick(column, row);
    };

    const handleOpenNoteEditor = () => {
      onNoteEditorOpen(column, row, rows);
    };

    const sum = childrenRows
      .map(
        (row) =>
          getFieldValue(sumFieldPath.join('.'), row) as
            | UserSubmission['override_amount']
            | undefined
      )
      .filter(isAmountNumber)
      .reduce((acc, item) => acc + item, 0);

    return (
      <Wrapper>
        <Cell>
          <Fluid>
            <BuDropdownWrapper
              placement="bottom-end"
              trigger={({ ref, open }) => (
                <BuInput
                  type="currency"
                  value={fixedTo(value || 0, 2)}
                  disabled={!isForecastExists}
                  innerRef={ref}
                  prefix={symbolOnLeft ? currencySymbol : undefined}
                  postfix={!symbolOnLeft ? currencySymbol : undefined}
                  style={{ width: '100%' }}
                  onChange={handleChange}
                  onFocus={open}
                  size={BuControlSize.SMALL}
                />
              )}
            >
              {(close) => (
                <BuDropdownItemContainer>
                  <BuDropdownItem onClick={onHintClick(close, sum)}>
                    Override Total: {currencyFormatter(sum)}
                  </BuDropdownItem>
                  <BuDropdownItem
                    onClick={onHintClick(close, dealsAmount ?? 0)}
                  >
                    Deals Included: {currencyFormatter(dealsAmount ?? 0)}
                  </BuDropdownItem>
                  <BuDropdownItem
                    onClick={onHintClick(close, last_submission_amount ?? 0)}
                  >
                    Last Submitted:{' '}
                    {currencyFormatter(last_submission_amount ?? 0)}
                  </BuDropdownItem>
                </BuDropdownItemContainer>
              )}
            </BuDropdownWrapper>
          </Fluid>

          <BuButton
            className={actionButton}
            icon
            onClick={handleOpenNoteEditor}
            secondary
            size={BuControlSize.SMALL}
          >
            <BuIcon name={BoostUpIcons.Submit} />
          </BuButton>
        </Cell>

        <Total>Override Total: {currencyFormatter(sum ?? 0)}</Total>

        <SummaryWrapper>
          <SummaryButton onClick={handleOpenDealsModal}>
            {dealsIncluded} of {dealsTotal} (
            {currencyFormatter(dealsAmount ?? 0)})
          </SummaryButton>

          <CopySubmission
            submissions={copySubmissionsOptions}
            currencyFormatter={currencyFormatter}
            onChange={(submissionSettingsIdFrom) =>
              onCopySubmissionSettings(
                submissionSettingsIdFrom,
                submissionSettingsId
              )
            }
          />
        </SummaryWrapper>
      </Wrapper>
    );
  };

export default myCallCROCell;
