import classNames from 'classnames';
import { css } from 'emotion';
import { isNil } from 'ramda';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { USER_ROLES } from 'common/constants';
import TabTitle from 'components/dashboard/Tabs/TabTitle';
import { tabs as tabStyle } from 'components/dashboard/Tabs/styles';
import { useScrolled } from 'components/hooks/useScrolled';
import { partitions, Features, Tabs, Partition } from 'navigation/tabs';
import { IUserProfileNavigationItem } from 'navigation/types';
import { IReduxState } from 'reducers/types';
import {
  getCompanySettings,
  getHiddenPages,
  getUserRole,
  isAdminEnabled,
  isPagesAvailable,
  getFeatureFlag,
} from 'selectors';
import { getAppNavigation } from 'selectors/navigation';

const MAX_TOTAL_TITLES_LENGTH = 120; // chars

type OwnProps = {
  partition: Partition;
  wrapped?: boolean;
};

type StateProps = {
  userRole: string;
  features: Features;
  hiddenPages: string[];
};

type Props = OwnProps & StateProps & RouteComponentProps;

const stickyStyles = css`
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10;

  &.scrolled {
    z-index: 988;
    border-bottom: 1px solid var(--bu-gray-300);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 5px 7px 5px;
  }
`;

type ITab = {
  name: string; // uniq id or name
  title: string;
  dashboard_id?: string;
};

type ITabConfig = {
  scheme: string;
  name: string;
  title: string;
};

type ITabsConfig = {
  [key: string]: ITabConfig[];
};

const navigationToTabsConfig = (partition: string, tab: ITab) => ({
  scheme: `/${partition}/rev-bi/dashboard/${tab.dashboard_id}`,
  title: tab.title,
  name: tab.name,
});

const geTabsConfig = (
  navigationItems: IUserProfileNavigationItem[],
  partition: string,
  isRevBiTabEnabled: boolean
): ITabsConfig => {
  const tabsConfig = { ...partitions } as ITabsConfig;

  const currentNavigation = navigationItems?.find(
    (navigation) => navigation.name === partition.toLowerCase()
  );

  if (
    currentNavigation &&
    !(currentNavigation.name! in partitions) &&
    currentNavigation['sub_nav']
  ) {
    if (currentNavigation.name === 'accounts') {
      tabsConfig['accounts'] = [
        {
          scheme: '/accounts/all',
          title: 'Accounts',
          name: 'accounts__dashboard',
        },
      ];
    }
  }

  if (currentNavigation && currentNavigation['sub_nav']) {
    tabsConfig[partition] = currentNavigation.sub_nav.map((subNavigation) =>
      subNavigation.type === 'revbi_dashboard' && isRevBiTabEnabled
        ? navigationToTabsConfig(partition, subNavigation)
        : tabsConfig[partition].find(
            (tabScheme) => tabScheme.name === subNavigation.name
          ) || { scheme: '' }
    ) as ITabConfig[];
  }

  return tabsConfig;
};

const FilterTabs: React.FC<Props> = ({
  partition,
  match,
  wrapped = false,
  userRole,
  features,
  children,
  hiddenPages,
}) => {
  const tabs: Tabs = useSelector((state: IReduxState) => {
    const isRevBiTabEnabled =
      isAdminEnabled(state) ||
      getFeatureFlag(state, 'revbi_show_dashboard_nav_items_enabled');
    const tabsConfig = geTabsConfig(
      getAppNavigation(state),
      partition,
      isRevBiTabEnabled
    );

    if (partition === 'targets' && userRole === USER_ROLES.SALES_OPERATIONS) {
      return tabsConfig[partition];
    }

    return isPagesAvailable(state, tabsConfig[partition]);
  });

  const scrolled = useScrolled();
  const titleLength = Math.floor(MAX_TOTAL_TITLES_LENGTH / tabs.length);

  if (!tabs.length) {
    return null;
  }

  // requirement added as part of https://vocalo.atlassian.net/browse/REV-1226
  if (tabs.length === 1 && tabs[0].name === 'accounts__dashboard') {
    return null;
  }

  return (
    <div
      className={classNames(tabStyle, {
        [stickyStyles]: wrapped,
        scrolled: wrapped && scrolled,
      })}
    >
      {tabs
        .filter(
          (elem) =>
            isNil(elem.isVisible) ||
            (typeof elem.isVisible === 'boolean'
              ? elem.isVisible
              : elem.isVisible(userRole, features, hiddenPages))
        )
        .map(({ scheme, title }) => (
          <TabTitle
            isActive={match.url === scheme}
            key={scheme}
            scheme={scheme}
            title={title}
            titleLength={titleLength}
          />
        ))}

      {children}
    </div>
  );
};

const mapStateToProps = (state: IReduxState): StateProps => {
  const companySettings = getCompanySettings(state);

  return {
    userRole: getUserRole(state) as string,
    features: {
      isAdminEnabled: isAdminEnabled(state),
      isForecastDashboardEnabled: companySettings?.forecast_dashboard_enabled,
      isRepPaceEnabled: companySettings?.rep_pace_table_enabled,
      isScorecardEnabled: companySettings?.scorecard_enabled,
      isMeetingsDashboardEnabled: companySettings?.meetings_dashboard_enabled,
      isSankeyChartEnabled: companySettings?.sankey_chart_enabled,
    },
    hiddenPages: getHiddenPages(state),
  };
};

export default withRouter(connect(mapStateToProps)(FilterTabs));
