import * as momentStyles from '../MomentsTable/styles';
import ShowMoreButton from '../common/Buttons/ShowMoreButton';
import Row from './Row';
import * as styles from './styles';
import { OwnProps, IDataState, ApiResponse } from './types';
import axios from 'axios';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';

import { tableSizeStyle } from 'components/UI/common/TypedTable/styles';

const AttachmentsTable = (props: OwnProps) => {
  const [dataState, setDataState] = useState<IDataState>({
    attachments: [],
    totalCount: 0,
    isLoading: false,
    apiError: undefined,
  });
  const [currentPage, setCurrentPage] = useState(0);

  const fetch = async () => {
    try {
      setDataState({ ...dataState, isLoading: true });
      const response: ApiResponse = await axios.get(
        '/api/data/emails/attachments',
        {
          params: {
            [props.type]: props.id,
            page_number: currentPage,
            page_size: 5,
            date_interval: props.timeSpan,
          },
        }
      );
      setDataState({
        ...dataState,
        attachments: [...dataState.attachments, ...response.data.data],
        totalCount: response.data.total_count,
        isLoading: false,
        apiError: undefined,
      });

      props.setCount(response.data.total_count);
    } catch (e) {
      setDataState({ ...dataState, apiError: e });
    }
  };

  useEffect(() => {
    fetch();
  }, [currentPage, props.timeSpan]);

  useEffect(() => {
    setDataState({
      attachments: [],
      totalCount: 0,
      isLoading: false,
      apiError: undefined,
    });
    setCurrentPage(0);
  }, [props.timeSpan]);

  if (dataState.apiError) {
    return <div className={styles.apiError}>Error loading from API</div>;
  }

  return (
    <section className={momentStyles.table}>
      <table
        className={classNames(
          styles.table_wrapper,
          styles.table_sticky_header,
          tableSizeStyle,
          'table-compact'
        )}
      >
        <thead className={styles.table_header_box_columns}>
          <tr>
            <th
              className={classNames(
                styles.table_header_column,
                'table-header-label'
              )}
            >
              Name
            </th>
            <th
              className={classNames(
                styles.table_header_column,
                'table-header-label'
              )}
            >
              Type
            </th>
            <th
              className={classNames(
                styles.table_header_column,
                'table-header-label'
              )}
            >
              Email
            </th>
            <th
              className={classNames(
                styles.table_header_column,
                'table-header-label'
              )}
            >
              Who / When
            </th>
          </tr>
        </thead>
        <tbody>
          {!dataState.isLoading && isEmpty(dataState.attachments) ? (
            <tr>
              <td colSpan={4} className={momentStyles.table_body_empty}>
                Nothing found
              </td>
            </tr>
          ) : null}
          {dataState.attachments.map((data, index) => (
            <Row data={data} key={`${data.id}_${index}`} />
          ))}

          {dataState.isLoading ? (
            <tr className={styles.whiteWrapper}>
              <td colSpan={4} style={{ textAlign: 'center' }}>
                <Loader inline="centered" active size="small" />
              </td>
            </tr>
          ) : (
            dataState.totalCount > dataState.attachments.length && (
              <tr>
                <td colSpan={4}>
                  <div className={momentStyles.section_btn}>
                    <ShowMoreButton
                      showing={dataState.attachments.length}
                      count={dataState.totalCount}
                      onClick={() => setCurrentPage(currentPage + 1)}
                    />
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </section>
  );
};

export default AttachmentsTable;
