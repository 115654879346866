import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchDealsForForecastSubmissionSetting,
  fetchForecastsSubmissionsByPeriodToCopy,
  fetchForecastSubmissionHistory,
} from 'actions/forecastActions';
import { IReduxState } from 'reducers/types';
import { getHideViewIncludeExcludeSubmissions } from 'selectors';
import { getForecastSubmissionSettingsById } from 'selectors/forecastSubmission';

const usePopulateDealsAndHistoryForForecastSubmissionSetting = (
  submissionSettingId: string,
  hasToFetch: boolean = true
) => {
  const forecastSetting = useSelector((state) =>
    getForecastSubmissionSettingsById(state as IReduxState, submissionSettingId)
  );

  const hideViewIncludeExcludeSubmissions = useSelector(
    getHideViewIncludeExcludeSubmissions
  );

  const hasToFetchCopy =
    hasToFetch && !!forecastSetting?.include_exclude_supported;

  const hasToFetchDeals =
    hasToFetch &&
    (!!forecastSetting?.include_exclude_supported ||
      !hideViewIncludeExcludeSubmissions);

  const hasToFetchHistory = hasToFetch;

  const historyStatus = forecastSetting?.historyStatus;
  const dealsStatus = forecastSetting?.dealsStatus;
  const submissionsToCopyStatus = forecastSetting?.submissionsToCopyStatus;

  const dispatch = useDispatch();

  useEffect(() => {
    const dealsRequestNotDone = dealsStatus === 'NOT_ASKED';
    if (hasToFetchDeals && dealsRequestNotDone && forecastSetting) {
      dispatch(fetchDealsForForecastSubmissionSetting(forecastSetting.id));
    }
  }, [hasToFetchDeals, forecastSetting]);

  useEffect(() => {
    const copyRequestNotDone = submissionsToCopyStatus === 'NOT_ASKED';

    const dealsRequested = dealsStatus === 'SUCCESS';
    const deals = forecastSetting?.deals?.deals.map((d) => d._id) || [];
    const thereAreDeals = deals.length > 0;
    if (
      hasToFetchCopy &&
      copyRequestNotDone &&
      forecastSetting &&
      dealsRequested &&
      thereAreDeals
    ) {
      dispatch(
        fetchForecastsSubmissionsByPeriodToCopy({
          submissionSettingId: forecastSetting.id,
          periodType: forecastSetting.period_type,
          includedDeals: deals,
        })
      );
    }
  }, [hasToFetchCopy, forecastSetting]);

  useEffect(() => {
    const historyRequestNotDone = historyStatus === 'NOT_ASKED';

    if (historyRequestNotDone && hasToFetchHistory && forecastSetting) {
      dispatch(
        fetchForecastSubmissionHistory({
          submissionSettingId: forecastSetting.id,
          forecastName: forecastSetting.forecast_name,
          businessTypeName: forecastSetting.business_type_name,
          periodInterval: forecastSetting.period_interval,
        })
      );
    }
  }, [hasToFetchHistory, forecastSetting]);

  const someThataIsNotCopyIsLoading = [dealsStatus, historyStatus].some(
    (status) => status === 'LOADING'
  );

  const historyNotCalledYet = historyStatus === 'NOT_ASKED';

  const deals = forecastSetting?.deals;
  const history = forecastSetting?.history || [];
  const submissionsToCopy = forecastSetting?.submissionsToCopy || [];

  return {
    deals,
    history,
    submissionsToCopy,
    isLoading: someThataIsNotCopyIsLoading || historyNotCalledYet,
  };
};

export default usePopulateDealsAndHistoryForForecastSubmissionSetting;
