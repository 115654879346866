import {
  container,
  metricNameParagraph,
  objectNameParagaph,
  syntheticContainer,
  tagContainer,
  totalParagraph,
  tooltipConditions,
} from './styles';
import React, { useContext } from 'react';

import { formatMoney, formatNumber } from 'common/numbers';
import SyntheticFormulaPreview from 'components/UI/BuFormulaTextField/SyntheticFormulaPreview';
import { ConditionsDescription } from 'components/dashboard/Metrics/common/ConditionsDescription/ConditionsDescription';
import { MetricAdvancedOptionsList } from 'components/dashboard/Metrics/common/MetricAdvancedOptionsList/MetricAdvancedOptionsList';
import { MetricTag } from 'components/dashboard/Metrics/common/MetricTag/MetricTag';
import { AGGREGATION_OPTIONS } from 'components/dashboard/Metrics/constants';
import { UserSettingsContext } from 'components/dashboard/Metrics/contexts/UserSettingsContext';
import {
  formatMetricObject,
  getPeriodPrettyPrint,
} from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIMetrics,
  MetricType,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  metric: BIMetrics;
  total: number | string;
}

export const TooltipContent: React.FC<Props> = ({ metric, total }) => {
  const { currency } = useContext(UserSettingsContext);

  const aggregationFunction = AGGREGATION_OPTIONS.find(
    (option) => option.value === metric?.aggregation_function
  )?.text;

  const columnPP =
    metric?.aggregation_function !== 'count'
      ? 'of ' + metric?.column?.label
      : '';

  return (
    <div className={container}>
      <p className={totalParagraph}>
        {metric?.column?.type === 'money' && typeof total === 'number'
          ? formatMoney(currency, Number(total))
          : typeof total === 'number'
          ? formatNumber(total)
          : total ?? '-'}
      </p>

      <p className={metricNameParagraph}>{metric?.name}</p>

      {metric?.metadata?.type === MetricType.Simple && (
        <p className={objectNameParagaph}>
          {`${formatMetricObject(metric?.object || '')} object`}
        </p>
      )}

      <div className={tagContainer}>
        {metric?.synthetic_metric &&
        metric?.metadata?.type === MetricType.Formula ? (
          <div className={syntheticContainer}>
            <SyntheticFormulaPreview formula={metric.synthetic_metric} />
            <br />
          </div>
        ) : (
          <>
            {metric?.object === 'target' ? (
              <MetricTag
                aggregationFunction={metric?.aggregation_function || ''}
                metricDescription={`${getPeriodPrettyPrint(
                  metric.target_period ?? ''
                )} ${metric.target_type} Target`}
              />
            ) : (
              <MetricTag
                aggregationFunction={metric?.aggregation_function || ''}
                metricDescription={`${aggregationFunction} ${columnPP}`}
              />
            )}
          </>
        )}
      </div>

      {metric?.filters && metric.filters.length > 0 && (
        <div className={tooltipConditions}>
          <ConditionsDescription filters={metric.filters} />
        </div>
      )}

      {((metric?.is_cumulative_sum && metric?.cumulative_sum_period) ||
        metric?.is_hierarchy_rollup_sum) && (
        <div className={tooltipConditions}>
          <MetricAdvancedOptionsList
            isHierarchyRollupSum={metric?.is_hierarchy_rollup_sum}
            isCumulativeSum={metric?.is_cumulative_sum}
            cumulativeSumPeriod={metric?.cumulative_sum_period}
          />
        </div>
      )}
    </div>
  );
};
