import classNames from 'classnames';
import { css } from 'emotion';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import {
  DayPickerSingleDateController,
  DayPickerSingleDateControllerShape,
} from 'react-dates';
import 'react-dates/initialize';
import { Popup, DropdownItemProps } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import styles from 'components/deal/styles';

interface OwnProps {
  value: string;
  onChange: (value?: string) => any;
  showIcon?: boolean;
}

interface StateProps {
  options?: DropdownItemProps[];
}

type IProps = OwnProps & StateProps;

const container = css`
  display: flex;
  align-items: center;
  height: 33px;
  margin-left: -5px;
`;

const dateValueContainer = css`
  padding: 5px;
  height: 33px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
`;

const hover = css`
  border-color: var(--bu-gray-500);
  box-sizing: border-box;
  border-radius: var(--bu-control-border-radius);
  background-color: var(--bu-white);
`;

const LABEL_DATE_FORMAT = 'DD MMM \\ YYYY';
const VALUE_DATE_FORMAT = 'YYYY-MM-DD';

const InlineDropDownDate = ({ value, onChange, showIcon }: IProps) => {
  const date: moment.Moment = moment(value, VALUE_DATE_FORMAT);

  const [focus, setFocus] = useState<boolean>(true);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [hovering, setHovering] = useState<boolean>(false);

  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const handleOpen = useCallback(() => setOpen(true), [setOpen]);

  const handleChange: DayPickerSingleDateControllerShape['onDateChange'] =
    useCallback(
      (newDate) => {
        if (newDate) {
          onChange(newDate.format(VALUE_DATE_FORMAT));
          handleClose();
          setHovering(false);
        }
      },
      [onChange, handleClose]
    );

  return (
    <div
      className={container}
      onMouseOver={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Popup
        trigger={
          <span
            className={classNames(dateValueContainer, {
              [hover]: hovering || isOpen,
            })}
            style={
              isOpen
                ? { borderColor: 'var(--bu-primary-500)', cursor: 'pointer' }
                : { cursor: 'pointer' }
            }
          >
            {date ? date.format(LABEL_DATE_FORMAT) : 'Not Available'}
          </span>
        }
        on="click"
        position="bottom left"
        open={isOpen}
        onClose={handleClose}
        onOpen={handleOpen}
        content={
          <DayPickerSingleDateController
            noBorder
            date={date as DayPickerSingleDateControllerShape['date']}
            onDateChange={handleChange}
            focused={focus}
            onFocusChange={({ focused }) => setFocus(!focused)}
          />
        }
      />
      {showIcon && !hovering && !isOpen && (
        <BuIcon className={styles.pencilIcon} name={BoostUpIcons.Pencil} />
      )}
    </div>
  );
};

export default InlineDropDownDate;
