import * as selectors from '../../../selectors';
import { AnalyticsTracker } from '../../common/analyticsUtils';
import * as service from './service';
import * as s from './styles';
import './styles.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';

const speedOptions = {
  1: '1x Speed',
  1.25: 'Faster (1.25x)',
  1.5: 'Faster (1.5x)',
};

class TrackSpeed extends Component {
  render() {
    const { playbackRate } = this.props;
    return (
      <div className={s.track_speed}>
        <div
          onClick={() => {
            service.changePlayback('slower', playbackRate);
            AnalyticsTracker.event(this.props, {
              category: 'Call Insights',
              action: 'Audio Timeline',
              label: `Playback: slower`,
            });
          }}
          className="select__track-speed-btn prev"
        >
          <Icon color="grey" name="minus" />
        </div>
        <div className="select__track-speed">{speedOptions[playbackRate]}</div>
        <div
          onClick={() => {
            service.changePlayback('faster', playbackRate);
            AnalyticsTracker.event(this.props, {
              category: 'Call Insights',
              action: 'Audio Timeline',
              label: `Playback: faster`,
            });
          }}
          className="select__track-speed-btn next"
        >
          <Icon color="grey" name="plus" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  playbackRate: selectors.getPlaybackRate(state),
  isAdmin: selectors.isAdmin(state),
});

export default connect(mapStateToProps)(TrackSpeed);
