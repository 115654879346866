import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Modal } from 'semantic-ui-react';

import {
  CROUserSubmission,
  SubmissionColumn,
} from 'actions/croOverrideActions';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import { footer } from 'components/UI/BuConfirmationPopup/styles';
import { ModalProps } from 'components/UI/BuDataPopup';

const Row = styled.p`
  color: var(--bu-gray-700);
  padding: 10px 0 0 0;
`;

const Title = styled.h5`
  display: inline-block;
`;

const Total = styled.span`
  font-weight: bold;
  margin-right: 5px;
  color: var(--bu-gray-900);
`;

const NoteTextArea = styled.textarea`
  width: 100%;
  min-height: 300px;

  border-radius: var(--bu-control-border-radius);

  border: 1px solid var(--bu-primary-500);
`;

interface SubmitSubmissionModalProps extends ModalProps {
  title: SubmissionColumn['forecast_display_name'];
  amount: CROUserSubmission['override_amount'];
  dealsIncluded: number;
  dealsTotal: number;
  dealsAmount: CROUserSubmission['override_included_deals_amount'];
  notes: CROUserSubmission['notes'];

  currencyFormatter(value: number): string;
  onSave(props: { notes: CROUserSubmission['notes'] }): void;
}

const SubmitSubmissionModal: React.FC<SubmitSubmissionModalProps> = ({
  isOpen,
  onSave,
  onClose,
  title,
  amount,
  dealsIncluded,
  dealsTotal,
  dealsAmount,
  notes: baseNotes,
  currencyFormatter,
}) => {
  const [notes, setNotes] = useState<string>('');

  useEffect(() => {
    setNotes(baseNotes ?? '');
  }, [baseNotes, isOpen]);

  return (
    <Modal title={title} size="small" open={isOpen} onClose={onClose}>
      <Modal.Header>
        <Title>Submit {title}</Title>
      </Modal.Header>
      <Modal.Content style={{ padding: '0 1.5em' }}>
        <Row>
          <Total>{currencyFormatter(amount ?? 0)}</Total>
          Deals Included: {dealsIncluded} of {dealsTotal} (
          {currencyFormatter(dealsAmount ?? 0)})
        </Row>

        <Row>Note (optional)</Row>
        <NoteTextArea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Notes..."
          data-testing="txt_field"
        />
      </Modal.Content>
      <Modal.Actions className={footer}>
        <BuButton
          size={BuControlSize.REGULAR}
          onClick={() =>
            onSave({
              notes: notes,
            })
          }
        >
          Submit
        </BuButton>
        <BuButton secondary size={BuControlSize.REGULAR} onClick={onClose}>
          Cancel
        </BuButton>
      </Modal.Actions>
    </Modal>
  );
};

export default SubmitSubmissionModal;
