import classNames from 'classnames';
import moment from 'moment';
import React, { useMemo } from 'react';
import Calendar, { DateCallback } from 'react-calendar';

import IconCalendar from 'assets/images/icons/calendar_small.svg';
import { dateStyle } from 'components/dashboard/Forecast/MeddicSidePanel/styles';
import { useClickOutside } from 'components/hooks/useClickOutside';

type IProps = {
  value: string;
  onChange(value: string | null): void;
  disabled: boolean;
  onClose?(): void;
  onOpen?(): void;
};

const DatePicker: React.FC<IProps> = ({
  value,
  onChange,
  disabled,
  onClose,
  onOpen,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside(onClose);

  const date: moment.Moment | null = useMemo(
    () => (value ? moment(value as string) : null),
    [value]
  );

  const handleDayClick: DateCallback = (newDate) => {
    onChange(newDate ? moment(newDate).format('YYYY-MM-DD') : null);
    setIsOpen(false);
    onClose && onClose();
  };

  const handleCalendarVisibility = () => {
    if (isOpen) {
      onClose && onClose();
    } else {
      onOpen && onOpen();
    }

    setIsOpen(!isOpen);
  };

  return (
    <div className={classNames([dateStyle, { disabled }])}>
      <div
        className="date-button"
        onClick={() => !disabled && handleCalendarVisibility()}
      >
        <img
          src={IconCalendar}
          alt="calendar icons"
          className={classNames({ disabled })}
        />
        {date?.format('YYYY-MM-DD')}
      </div>
      {isOpen && (
        <div ref={refElement} className="date-popup">
          <Calendar
            onClickDay={handleDayClick}
            value={date && date.isValid() ? date.toDate() : undefined}
            locale="en-EN"
          />
        </div>
      )}
    </div>
  );
};

export default DatePicker;
