import styled from '@emotion/styled';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as metricActions from 'actions/revbi/metrics';
import { MetricsDashboard } from 'components/dashboard/Metrics/Dashboard/MetricsDashboard';
import { MetricsDashboardList } from 'components/dashboard/Metrics/Dashboard/MetricsDashboardList';
import { MetricsList } from 'components/dashboard/Metrics/List/MetricsList';
import { WidgetsList } from 'components/dashboard/Metrics/List/WidgetsList';
import { RevbiPages } from 'components/dashboard/Metrics/constants';
import { LastSyncProvider } from 'components/dashboard/Metrics/contexts/LastSyncContext';
import { UserSettingsProvider } from 'components/dashboard/Metrics/contexts/UserSettingsContext';
import { UsersByActivityProvider } from 'components/dashboard/Metrics/contexts/UsersByActivityContext';
import { BIDashboard } from 'components/dashboard/Metrics/metrics.types';
import { fetchApi, QueryStatus } from 'utils/network';

const PageContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
});

const getNewDashboard = (): BIDashboard => {
  return {
    name: '',
    properties: {
      // Needed to differentiate between new dashboards using the new dashboard editor
      // and existing dashboards before https://vocalo.atlassian.net/browse/REV-1020
      // and the InteractiveGrid component
      widgetLayout: [],
    },
    widget_list: [],
  };
};

const formatDashboardForSave = (dashboard: BIDashboard) => {
  const { id, _id, created_at, updated_at, ...saveData } = dashboard;
  return saveData;
};

export const parseDashboardResponse = (
  dashboard: BIDashboard
): BIDashboard => ({
  ...dashboard,
  id: dashboard.id || dashboard?._id,
});

const parseDashboardList = (dashboardList: BIDashboard[]): BIDashboard[] => {
  if (!Array.isArray(dashboardList)) {
    return [];
  }

  return dashboardList.map((dashboard: BIDashboard) =>
    parseDashboardResponse(dashboard)
  );
};

export const RevBIContainer: React.FC = () => {
  const match = useRouteMatch<{ dashboardId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedDashboardIdRef = useRef(match.params.dashboardId);

  const [dashboardList, setDashboardList] = useState<BIDashboard[]>([]);
  const [selectedDashboard, setSelectedDashboard] = useState<BIDashboard>();
  const [pageShown, setPageShown] = useState<string>(RevbiPages.DASHBOARD);
  const [loadDashboardStatus, setLoadDashboardStatus] =
    useState<QueryStatus>('notAsked');
  const [loadDashboardListStatus, setLoadDashboardListStatus] =
    useState<QueryStatus>('notAsked');
  const [includeDisabledUsers, setIncludeDisabledUsers] = useState<boolean>(
    selectedDashboard?.properties?.settings?.userFilter !== 'active'
  );

  useEffect(() => {
    setIncludeDisabledUsers(
      selectedDashboard?.properties?.settings?.userFilter !== 'active'
    );
  }, [selectedDashboard?.properties?.settings?.userFilter]);

  useEffect(() => {
    if (match.path.includes('widget')) {
      setPageShown(RevbiPages.WIDGETS);
    } else if (match.path.includes('metric')) {
      setPageShown(RevbiPages.METRICS);
    } else {
      setPageShown(RevbiPages.DASHBOARD);
    }
  }, [match.path]);

  useEffect(() => {
    // Obtain basic data with actions.
    dispatch(metricActions.fetchAllMetrics());
    dispatch(metricActions.fetchAllTSMetrics());
    dispatch(metricActions.fetchObjectList());
    dispatch(metricActions.fetchTimeSeriesObjectList());
  }, []);

  useEffect(() => {
    fetchApi<void, any>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards`,
      queryMethod: 'get',
      setData: (result) => {
        setDashboardList(parseDashboardList(result ?? []));
        if (match.path === '/revbi' && result.length > 0) {
          history.push(`/revbi/dashboard/${result[0]._id}`);
        }
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load dashboard: ${error}`);
      },
      setStatus: setLoadDashboardListStatus,
    });
    return () => {
      setDashboardList([]);
    };
  }, []);

  useEffect(() => {
    if (
      match.params.dashboardId &&
      selectedDashboardIdRef.current !== match.params.dashboardId
    ) {
      setPageShown(RevbiPages.DASHBOARD);
      fetchApi<void, BIDashboard>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards/${match.params.dashboardId}/complete`,
        queryMethod: 'get',
        setData: (result) => {
          setSelectedDashboard(parseDashboardResponse(result));
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load dashboard: ${error}`);
        },
        setStatus: setLoadDashboardStatus,
      });
    }
  }, [match.params.dashboardId]);

  const handleAddDashboard = () => {
    fetchApi<BIDashboard, BIDashboard>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/dashboards`,
      queryMethod: 'post',
      queryParams: formatDashboardForSave(getNewDashboard()),
      setData: (result) => {
        const savedDashboard = parseDashboardResponse(result);
        setDashboardList((prev) => [savedDashboard, ...prev]);
        setSelectedDashboard(savedDashboard);
        history.replace({
          pathname: `/revbi/dashboard/${
            savedDashboard?.id || savedDashboard?._id
          }`,
        });
        toast.success('Dashboard Created');
      },
      setError: (error: string | null) => {
        toast.error(`Failed to create dashboard: ${error}`);
      },
      setStatus: setLoadDashboardStatus,
    });
  };

  return (
    <UsersByActivityProvider includeDisabledUsers={includeDisabledUsers}>
      <LastSyncProvider>
        <UserSettingsProvider>
          <PageContainer>
            <MetricsDashboardList
              loadDashboardListStatus={loadDashboardListStatus}
              selectedDashboardId={match.params.dashboardId}
              dashboardList={dashboardList}
              onAddDashboard={handleAddDashboard}
            />
            {pageShown === RevbiPages.WIDGETS ? (
              <WidgetsList />
            ) : pageShown === RevbiPages.METRICS ? (
              <MetricsList />
            ) : (
              <MetricsDashboard
                key={selectedDashboard?.id}
                loadDashboardStatus={loadDashboardStatus}
                selectedDashboard={selectedDashboard}
                selectedDashboardIdRef={selectedDashboardIdRef}
                dashboardList={dashboardList}
                setSelectedDashboard={setSelectedDashboard}
                setDashboardList={setDashboardList}
              />
            )}
          </PageContainer>
        </UserSettingsProvider>
      </LastSyncProvider>
    </UsersByActivityProvider>
  );
};
