import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';

import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import Table from 'components/UI/TableConfig';
import { CallbackProps } from 'components/UI/TableConfig/column-helper';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import * as styles from 'components/dashboard/ForecastRollUps/styles';
import Tabs from 'components/dashboard/Tabs';
import { columns, columnAudio } from 'components/new-library/Row';
import * as s from 'components/new-library/styles';
import { IProps } from 'components/new-library/types';
import { openModal } from 'navigation/utils';

const CallsTabs: React.FC<IProps> = ({
  pagination,
  activeTab,
  loadingStatus,
  paginationStatus,
  clearFilters,
}) => {
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    pagination(activeTab.request, activeTab.key);
  }, [activeTab.key]);

  const data: IRow[] = activeTab.calls as any;

  const handleSpecialClick = ({ objectId }: CallbackProps) => {
    openModal({
      scheme: '/call/:id',
      params: { id: objectId },
    });
  };

  const handlePageChange = (page_number: number, page_size: number) => {
    pagination({ ...activeTab.request, page_number, page_size }, activeTab.key);
    setPageNumber(page_number);
  };

  return (
    <div className={classNames('wrapper', s.wrapper)}>
      <div className={styles.header}>
        <Tabs partition="calls" />
        <OpenFiltersPanel tab={activeTab.filtersTab} />

        {loadingStatus === 'loading' && activeTab.calls.length === 0 && (
          <Loader active inline="centered" />
        )}
      </div>

      <div className={styles.content}>
        {activeTab.calls.length > 0 ? (
          <Table
            currentPage={pageNumber}
            customTableColumnConfiguration={{
              order: {},
              columns:
                activeTab.key === 'completedCalls'
                  ? [...columns, columnAudio]
                  : columns,
            }}
            data={data}
            fullscreen
            hidePaginationEnd
            onPaginationChange={handlePageChange}
            onSpecialClick={handleSpecialClick}
            rowsPerPage={50}
            hideSearch
            searchPlaceholder="Search..."
            title="Calls"
            totalCount={activeTab.count}
          />
        ) : (
          <div>
            {loadingStatus === 'success' && paginationStatus === 'success' && (
              <div className={s.clear_message}>
                <p>
                  No calls found for your request. Please change filters and try
                  again.
                </p>
                <button className={s.clear_btn} onClick={() => clearFilters()}>
                  Clear Filters
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CallsTabs;
