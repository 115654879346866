import React from 'react';

import BuSelect from 'components/UI/BuSelect';
import {
  Container,
  Title,
  Description,
} from 'components/dashboard/Metrics/Create/FunnelObjectSelector/styles';
import { BIWidget } from 'components/dashboard/Metrics/metrics.types';

interface OwnProps {
  widget: BIWidget;
  setWidget: (widget: BIWidget) => void;
}

const FunnelObjectSelector: React.FC<OwnProps> = ({ widget, setWidget }) => {
  const selectedField = 'opportunity';
  const fieldOptions = [{ value: 'opportunity', text: 'Opportunity' }];

  return (
    <Container>
      <Title>Object</Title>
      <Description>Type of object used to create the funnel</Description>

      <BuSelect
        fullWidth
        isLargePlaceholder
        secondary
        placeholder="Select a field"
        defaults={[selectedField]}
        options={fieldOptions}
        onChange={() => {}}
      />
    </Container>
  );
};

export default FunnelObjectSelector;
