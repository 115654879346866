import Highcharts, { PointClickEventObject } from 'highcharts';
import _ from 'lodash';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import { BIChartTypes } from 'common/enums/metrics';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { getMetricType } from 'components/dashboard/Metrics/Create/MetricCreate/utils';
import { WidgetChart } from 'components/dashboard/Metrics/Widget/Chart/WidgetChart';
import { MetricsWidgetControls } from 'components/dashboard/Metrics/Widget/Controls/MetricsWidgetControls';
import { WidgetHeader } from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { DataFetchErrorMessage } from 'components/dashboard/Metrics/Widget/Messages/DataFetchErrorMessage';
import { NoDataMessage } from 'components/dashboard/Metrics/Widget/Messages/NoDataMessage';
import { WidgetTable } from 'components/dashboard/Metrics/Widget/Table/WidgetTable';
import { TemplateFilters } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilters';
import { TotalsBlock } from 'components/dashboard/Metrics/Widget/TotalsBlock/TotalsBlock';
import { NOT_SAVED_METRIC } from 'components/dashboard/Metrics/Widget/constants';
import {
  getMetricNamesWithValues,
  getSyntheticMetricData,
  openSyntheticMetricDetailsModal,
  parseFormulaToMetricsIdArray,
} from 'components/dashboard/Metrics/Widget/helper';
import { useMetricsList } from 'components/dashboard/Metrics/Widget/hooks/useMetricsList';
import {
  createDrillDownParams,
  fetchRecordsAndOpenDrillDownModal,
  fetchWidgetData,
  getDrillDownFilters,
  openDrillDownModal,
} from 'components/dashboard/Metrics/Widget/metrics.widget.helpers';
import * as s from 'components/dashboard/Metrics/Widget/metrics.widget.styles';
import {
  LoaderContainer,
  WidgetContainer,
} from 'components/dashboard/Metrics/Widget/widgets.styles';
import { BUSINESS_TYPES_FIELD_NAMES } from 'components/dashboard/Metrics/constants';
import { getDropdownFriendlyName } from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIColumnListItem,
  BIDashboardSettings,
  BIMetricColumn,
  BIMetricToChartType,
  BIMetrics,
  BIWidget,
  BIWidgetDataV2,
  DrillDownParams,
  MetricType,
  SelectedChartValue,
  SelectedValue,
  SyntheticMetricDataWidgetState,
} from 'components/dashboard/Metrics/metrics.types';
import { getFeatureFlag } from 'selectors/settings';
import { fetchApi, QueryStatus } from 'utils/network';

interface Props {
  widget: Partial<BIWidget>;
  dashboardId?: string;
  dashboardName?: string;
  isDashboardModal?: boolean;
  metricsFromList: any[];
  dashboardSettings?: BIDashboardSettings;
  setWidget: Dispatch<SetStateAction<Partial<BIWidget>>>;
  onEditWidget?: (metricId: string) => void;
  onCloneWidget?: (metricId: string) => void;
  onRemoveWidget?: (metricId: string) => void;
  onNameClicked?: (widget: Partial<BIWidget>) => void;
  closeWidgetModal?: () => void;
}

export const MetricsWidgetDashboard: React.FC<Props> = ({
  widget,
  dashboardId = '',
  dashboardName = '',
  isDashboardModal = false,
  metricsFromList,
  dashboardSettings,
  setWidget,
  onEditWidget,
  onCloneWidget,
  onRemoveWidget,
  onNameClicked,
  closeWidgetModal,
}) => {
  const dispatch = useDispatch();

  const { revbiEmailsDrilldownEnabled, revbiEventsDrilldownEnabled } =
    useSelector((state) => {
      return {
        revbiEmailsDrilldownEnabled: getFeatureFlag(
          state,
          'revbi_emails_drilldown_enabled'
        ),
        revbiEventsDrilldownEnabled: getFeatureFlag(
          state,
          'revbi_events_drilldown_enabled'
        ),
      };
    });

  const lastFetchedWidget = useRef<string>();

  const { data: metricsInUse } = useMetricsList(widget.metric_list);

  const widgetFiltersBusinessType: string = useMemo(() => {
    const templateFilterBusinessType = widget.template_filters?.find((filter) =>
      BUSINESS_TYPES_FIELD_NAMES.has(filter.column.name)
    );

    return (templateFilterBusinessType?.value as string[])?.[0];
  }, [JSON.stringify(widget.template_filters)]);

  const [metricToChartType, setMetricToChartType] = useState<
    BIMetricToChartType[]
  >(
    widget?.properties?.metricToChartType ||
      (widget.metric_list as string[]).map((el) => ({
        chartType:
          widget.chart_type === 'table'
            ? BIChartTypes.Table
            : BIChartTypes.Column,
        metricId: el,
      }))
  );

  const [metricData, setMetricData] = useState<BIWidgetDataV2>();
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');

  const [firstPivotColumns, setFirstPivotColumns] = useState<BIMetricColumn[]>(
    []
  );
  const [secondPivotColumns, setSecondPivotColumns] = useState<
    BIMetricColumn[]
  >([]);

  const [pivot1, setPivot1] = useState<BIMetricColumn | undefined>(
    widget.group_by && widget?.group_by[0]
  );
  const [pivot2, setPivot2] = useState<BIMetricColumn | undefined>(
    widget.group_by && widget?.group_by[1]
  );

  useEffect(() => {
    if (pivot2?.name.toLowerCase() === 'none') {
      metricToChartType.forEach((el) => {
        el.chartType =
          el.chartType === BIChartTypes.ColumnStacked
            ? BIChartTypes.Column
            : el.chartType;
      });
      setMetricToChartType([...metricToChartType]);
    }
  }, [pivot2?.name]);

  useEffect(() => {
    if (!!widget?.properties?.metricToChartType) {
      setMetricToChartType(widget?.properties?.metricToChartType);
    }
  }, [JSON.stringify(widget)]);

  // TODO Investigate is this necessary any more?
  useEffect(() => {
    if (widget.chart_type === 'pie') {
      setWidget((prev) => ({
        ...prev,
        group_by: prev && prev.group_by ? [prev.group_by[0]] : [],
      }));
    }
  }, [widget.chart_type]);

  const usedFormulaMetricIds = useMemo(() => {
    return (metricsFromList || [])
      .filter((m) => getMetricType(m) === MetricType.Formula)
      .flatMap((m) => parseFormulaToMetricsIdArray(m.synthetic_metric));
  }, [metricsFromList]);

  const { data: usedFormulaMetricsMap } = useMetricsList(usedFormulaMetricIds);

  useEffect(() => {
    const objectsList: string[] = [];

    const simpleMetrics = metricsFromList?.filter(
      (m) => getMetricType(m) === MetricType.Simple
    );

    if (simpleMetrics?.length) {
      simpleMetrics.forEach((m) => {
        const metricObject = m.object;

        if (metricObject) {
          objectsList.push(metricObject);
        }
      });
    }

    Object.keys(usedFormulaMetricsMap).forEach((element) => {
      const metricObject = usedFormulaMetricsMap[element].object;

      if (metricObject) {
        objectsList.push(metricObject);
      }
    });

    if (objectsList?.length) {
      const queryParams = {
        tables: [...new Set(objectsList)],
      };

      fetchApi<any, BIColumnListItem[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_first_pivot`,
        queryMethod: 'post',
        queryParams: queryParams,
        setData: (result) => {
          setFirstPivotColumns(
            result.map((column) => ({
              ...column,
              label: getDropdownFriendlyName(column),
            }))
          );
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load first pivot columns: ${error}`);
        },
      });

      fetchApi<any, BIColumnListItem[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_second_pivot`,
        queryMethod: 'post',
        queryParams: queryParams,
        setData: (result) => {
          setSecondPivotColumns(
            result.map((column) => ({
              ...column,
              label: getDropdownFriendlyName(column),
            }))
          );
        },
        setError: (error: string | null) => {
          toast.error(`Failed to load second pivot columns: ${error}`);
        },
      });
    }
  }, [
    JSON.stringify(dashboardSettings),
    JSON.stringify(metricsFromList),
    JSON.stringify(usedFormulaMetricsMap),
  ]);

  useEffect(() => {
    const queryWidget = {
      ...widget,
      ...dashboardSettings,
      dashboard_id: dashboardId,
      dashboard_name: dashboardName,
    };
    const previewJson = JSON.stringify(queryWidget);

    let abortController: AbortController | null = null;

    if (previewJson !== lastFetchedWidget.current) {
      lastFetchedWidget.current = previewJson;
      abortController = new AbortController();

      const querySetting = dashboardSettings
        ? `?user_status=${dashboardSettings.userFilter}`
        : '';

      fetchWidgetData(
        previewJson,
        querySetting,
        (widget._id ?? widget.name) || '',
        widget.time_field,
        abortController,
        setMetricData,
        setDataStatus,
        setPivot1,
        setPivot2
      );
    }

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [
    widget.metric_list?.length,
    widget.time_field?.name,
    widget.time_field?.label,
    JSON.stringify(widget.template_filters),
    JSON.stringify(widget.group_by),
    JSON.stringify(dashboardSettings),
  ]);

  const isTargetMetricOnlyWidget = useMemo(
    () =>
      Object.keys(metricsInUse).length === 1 &&
      metricsInUse[Object.keys(metricsInUse)[0]].object === 'target',
    [metricsInUse]
  );

  const pivot1Columns = useMemo(() => {
    // If target metric is only metric on widget we show only pivot by User Name
    if (isTargetMetricOnlyWidget) {
      return firstPivotColumns.filter((c) => c.name === 'user.name');
    }

    return firstPivotColumns;
  }, [isTargetMetricOnlyWidget, firstPivotColumns]);

  const firstTargetMetric = useMemo(() => {
    const keys = Object.keys(metricsInUse);
    for (const key of keys) {
      const current = metricsInUse[key];
      if (current.object === 'target') {
        return current;
      }
    }

    return null;
  }, [metricsInUse]);

  const handleTableDataClick = (column: IColumn, row: IRow): void => {
    const metricId = column.metricId;

    if (metricId in metricsInUse) {
      const clickedMetric: BIMetrics = metricsInUse[metricId];

      const drillDownFilters = getDrillDownFilters(
        widget,
        metricData,
        column.extraHeader,
        row
      );

      const drilldownParams: DrillDownParams = createDrillDownParams(
        clickedMetric,
        // If it is a subtotal row then we send only the first pivot value
        row.isSubtotal ? [drillDownFilters[0]] : drillDownFilters,
        widgetFiltersBusinessType,
        widget
      );

      if (clickedMetric.synthetic_metric) {
        if (typeof clickedMetric.synthetic_metric !== 'string') {
          fetchRecordsAndOpenDrillDownModal(
            dashboardSettings,
            drilldownParams,
            clickedMetric.object,
            clickedMetric.name,
            dispatch
          );
          return;
        }
        const parsedClickedMetricFormulaAsArray: string[] =
          parseFormulaToMetricsIdArray(clickedMetric.synthetic_metric);

        const syntheticMetricData: SyntheticMetricDataWidgetState =
          getSyntheticMetricData(
            parsedClickedMetricFormulaAsArray,
            metricData,
            clickedMetric
          );

        const selectedValue: SelectedValue = {
          y: !Number.isNaN(row[column.field] as number)
            ? (row[column.field] as number)
            : 0,
          metricsValues: getMetricNamesWithValues(
            parsedClickedMetricFormulaAsArray,
            row,
            syntheticMetricData
          ),
        };

        const pivot1FieldName = pivot1?.field_name;
        if (pivot1FieldName) {
          selectedValue.pivot1Id = row[pivot1FieldName] as string;
        }

        openSyntheticMetricDetailsModal(
          widget.title || widget.name || 'Drill Down',
          drilldownParams,
          syntheticMetricData,
          selectedValue,
          dispatch
        );
      } else {
        fetchRecordsAndOpenDrillDownModal(
          dashboardSettings,
          drilldownParams,
          clickedMetric.object,
          clickedMetric.name,
          dispatch
        );
      }
    }
  };

  const handleChartDataClick = (pointEvent?: PointClickEventObject): void => {
    const selectedValue: SelectedChartValue | undefined =
      pointEvent?.point?.options;

    if (selectedValue) {
      const metricId = selectedValue?.metricId || '';

      if (metricId in metricsInUse) {
        const clickedMetric: BIMetrics = metricsInUse[metricId];

        openDrillDownModal(
          widget,
          selectedValue,
          clickedMetric,
          widgetFiltersBusinessType,
          revbiEmailsDrilldownEnabled,
          revbiEventsDrilldownEnabled,
          dashboardSettings,
          metricData,
          availablePivots,
          pivot1,
          pivot2,
          dispatch
        );
      }
    }
  };

  const handleTotalsClick = (metricId: string): void => {
    if (metricId === undefined || metricId === '') {
      // handling case when metric is not created yet
      metricId = NOT_SAVED_METRIC;
    }

    if (metricId in metricsInUse) {
      const clickedMetric = metricsInUse[metricId];

      const drilldownParams: DrillDownParams = createDrillDownParams(
        clickedMetric,
        [],
        widgetFiltersBusinessType,
        widget
      );

      if (clickedMetric.synthetic_metric) {
        if (typeof clickedMetric.synthetic_metric !== 'string') {
          fetchRecordsAndOpenDrillDownModal(
            dashboardSettings,
            drilldownParams,
            clickedMetric.object,
            clickedMetric.name,
            dispatch
          );
          return;
        }

        const parsedClickedMetricFormulaAsArray: string[] =
          parseFormulaToMetricsIdArray(clickedMetric.synthetic_metric);

        const syntheticMetricData: SyntheticMetricDataWidgetState =
          getSyntheticMetricData(
            parsedClickedMetricFormulaAsArray,
            metricData,
            clickedMetric
          );

        let clickedValue = 0;

        const totals = metricData?.pivot_0?.columns.map((el) => ({
          ...el,
          total: metricData.pivot_0?.data[0][el.field_name],
        }));

        totals?.forEach((total) => {
          if (total.metric_id === metricId) {
            clickedValue = total.total;
          }
        });

        openSyntheticMetricDetailsModal(
          widget.title || widget.name || 'Drill Down',
          drilldownParams,
          syntheticMetricData,
          {
            y: clickedValue,
            metricsValues: getMetricNamesWithValues(
              parsedClickedMetricFormulaAsArray,
              metricData?.pivot_0?.data[0] || {},
              syntheticMetricData
            ),
          },
          dispatch
        );
      } else {
        fetchRecordsAndOpenDrillDownModal(
          dashboardSettings,
          drilldownParams,
          clickedMetric.object,
          clickedMetric.name,
          dispatch
        );
      }
    }
  };

  const titleClicked = () => {
    onNameClicked && onNameClicked(widget);
  };

  const hasData = !!metricData?.pivot_1?.data.length;

  const availablePivots = metricData?.hasOwnProperty('pivot_2')
    ? 2
    : metricData?.hasOwnProperty('pivot_1')
    ? 1
    : 0;

  const isDateFirstPivot =
    pivot1?.type === 'date' || pivot1?.type === 'timePeriod';

  const isTableVisualization = metricToChartType.some(
    (el) => el.chartType === 'table'
  );

  return (
    <>
      <WidgetContainer
        key={widget._id}
        isDashboard={true}
        isMetricsPreview={false}
        isModal={isDashboardModal}
      >
        <WidgetHeader
          isDashboardWidget
          id={widget._id}
          name={widget.name}
          dashboardName={dashboardName}
          isDashboardModal={isDashboardModal}
          onEditWidget={onEditWidget}
          onCloneWidget={onCloneWidget}
          onRemoveWidget={onRemoveWidget}
          onTitleClicked={titleClicked}
          closeWidgetModal={closeWidgetModal}
        />

        <MetricsWidgetControls
          isTargetMetricOnlyWidget={isTargetMetricOnlyWidget}
          hasTargetMetric={Boolean(firstTargetMetric)}
          showControls={isDashboardModal}
          firstPivotColumns={pivot1Columns}
          secondPivotColumns={secondPivotColumns}
          pivot1={pivot1}
          pivot2={pivot2}
          widget={widget}
          metricToChartType={metricToChartType}
          setMetricToChartType={setMetricToChartType}
          setPivot1={setPivot1}
          setPivot2={setPivot2}
          setWidget={setWidget}
          data-testing="metrics-widget-controls"
        />

        <TemplateFilters
          isDateFirstPivot={isDateFirstPivot}
          showControls={isDashboardModal}
          templateFilters={widget.template_filters}
          widgetFilters={widget.widget_filters}
          targetPeriod={firstTargetMetric?.target_period}
          setWidget={setWidget}
        />

        {dataStatus === 'loading' && (
          <LoaderContainer>
            <Loader active content="Loading" />
          </LoaderContainer>
        )}

        {dataStatus === 'success' && (
          <>
            {(!!metricData?.pivot_0?.columns?.length ||
              !!metricData?.pivot_0?.columns_error?.length) && (
              <div
                className={s.totalsContainer}
                data-testing="totals-container"
              >
                <div className={s.totalsBarElement}>
                  <TotalsBlock
                    showControls={isDashboardModal}
                    widget={widget}
                    isPivoted={pivot1?.name !== 'none'}
                    pivot2={pivot2}
                    metricData={metricData}
                    metricsInUse={metricsInUse}
                    metricToChartType={metricToChartType}
                    onDataClick={handleTotalsClick}
                    type={
                      metricsFromList &&
                      metricsFromList.length === 1 &&
                      !metricData?.hasOwnProperty('pivot_1')
                        ? 'singleValue'
                        : (metricsFromList &&
                            metricsFromList.length > 4 &&
                            !metricData?.hasOwnProperty('pivot_1')) ||
                          !metricData?.hasOwnProperty('pivot_1')
                        ? 'totalsOnly'
                        : 'totalsAndChart'
                    }
                    onChartTypeChange={(metricId, chartType) => {
                      let result: {
                        chartType: BIChartTypes;
                        metricId: string;
                      }[] = [...metricToChartType];
                      if (
                        chartType === BIChartTypes.Table &&
                        (
                          widget.properties
                            ?.metricToChartType as BIMetricToChartType[]
                        ).every((el) => el.chartType !== BIChartTypes.Table)
                      ) {
                        result = [
                          ...result.map((el) => ({
                            ...el,
                            chartType: chartType,
                          })),
                        ];
                      }
                      if (
                        (
                          widget.properties
                            ?.metricToChartType as BIMetricToChartType[]
                        ).every((el) => el.chartType === BIChartTypes.Table) &&
                        chartType !== BIChartTypes.Table
                      ) {
                        result = [
                          ...result.map((el) => ({
                            ...el,
                            chartType: chartType,
                          })),
                        ];
                      }

                      const type = result.find(
                        (el) => el.metricId === metricId
                      );

                      if (!!type) {
                        type.chartType = chartType;
                        result = [...result];
                      } else {
                        result = [...result, { metricId, chartType }];
                      }

                      setMetricToChartType(result);
                      setWidget((prev) => ({
                        ...prev,
                        properties: {
                          metricToChartType: result,
                        },
                        chart_type: chartType,
                      }));
                    }}
                  />
                </div>
              </div>
            )}

            {hasData && (
              <>
                {isTableVisualization && !!metricData && (
                  <WidgetTable
                    sortByColumn={widget.properties?.table_view_order_by_column}
                    widgetData={metricData}
                    metricsCount={widget.metric_list?.length || 0}
                    availablePivots={availablePivots}
                    isDateFirstPivot={isDateFirstPivot}
                    onTableDataClick={handleTableDataClick}
                    metricsInUse={metricsInUse}
                  />
                )}

                {!isTableVisualization && !!metricData && (
                  <WidgetChart
                    metricToChartType={metricToChartType}
                    metricData={metricData}
                    metricsInUse={metricsInUse}
                    widget={widget}
                    onDataClick={handleChartDataClick}
                    data-testing="widget-chart-section"
                  />
                )}
              </>
            )}

            {availablePivots > 0 && !hasData && (
              <NoDataMessage data-testing="no-data-message" />
            )}
          </>
        )}

        {dataStatus === 'error' && <DataFetchErrorMessage />}
      </WidgetContainer>
    </>
  );
};
