import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { QUARTER } from 'components/dashboard/Metrics/constants';
import { DateOption } from 'components/dashboard/Metrics/metrics.types';
import { fetchApi } from 'utils/network';

export const useTargetPeriodOptions = (targetPeriod: string) => {
  const [options, setOptions] = useState<DateOption[]>([]);

  const getOptionsFromAPI = async () => {
    fetchApi<string, DateOption[]>({
      url: `${
        process.env.REACT_APP_BACKEND_URL
      }/rev_bi/target/get_time_period_options/${targetPeriod ?? QUARTER}`,
      queryMethod: 'get',
      setData: (result) => {
        setOptions(result);
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load time period options: ${error}`);
      },
    });
  };

  useEffect(() => {
    if (targetPeriod) {
      getOptionsFromAPI();
    }
  }, [targetPeriod]);

  return { options };
};
