import classNames from 'classnames';
import React from 'react';

import { Container, Dot, Bar } from 'components/UI/BuToggle/styles';

export type IToggleProps = {
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
} & Common.DataTestingType;
const BuToggle: React.FC<IToggleProps> = ({
  checked,
  onChange,
  className,
  disabled,
  children,
  testingLabel = '',
  ...rest
}) => {
  const testingId = testingLabel ? `${testingLabel}_BuToggle` : `BuToggle`;
  return (
    <Container
      className={classNames(className, { disabled })}
      data-testing={testingId}
      {...rest}
    >
      <Dot
        checked={checked}
        aria-checked={checked}
        readOnly
        onChange={!disabled ? onChange : undefined}
      />
      <Bar />
      {children}
    </Container>
  );
};

export default BuToggle;
