import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'actions';
import { createCustomView, updateCustomView } from 'actions/customViews';
import * as forecastActions from 'actions/forecastActions';
import { tabsWithAnalytics, allTabs, BusinessTypeTab } from 'common/constants';
import FILTERS from 'common/filters';
import CreateNewViewModal from 'components/UI/OpenFiltersPanel/CreateNewViewModal/index';
import CustomViewsDropdown from 'components/UI/OpenFiltersPanel/CustomViewsDropdown';
import { DropdownCalendar } from 'components/UI/OpenFiltersPanel/Dropdowns/Calendar';
import { DropdownMoneySlider } from 'components/UI/OpenFiltersPanel/Dropdowns/MoneySlider';
import { DropdownNormal } from 'components/UI/OpenFiltersPanel/Dropdowns/Normal';
import { DropdownText } from 'components/UI/OpenFiltersPanel/Dropdowns/Text';
import SaveViewDropdown from 'components/UI/OpenFiltersPanel/SaveViewDropdown';
import { SaveViewFilter } from 'components/UI/OpenFiltersPanel/SaveViewFilters';
import { ViewDropdownFilter } from 'components/UI/OpenFiltersPanel/ViewDropdownFilter';
import * as s from 'components/UI/OpenFiltersPanel/styles';
import { Props as HandlerProps } from 'components/UI/OpenFiltersPanel/types';
import { PinnedView } from 'components/UI/OpenFiltersPanel/types';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { IReduxState } from 'reducers/types';
import {
  getActiveFiltersProfile,
  getFeatureFlags,
  getFiltersForCustomViews,
  getFiltersProfilesNames,
  getForecastActiveBusinessType,
  getPersistName,
  getSelectedBusinessTypeForPanel,
  getShowOrHideColumns,
  getSelectedItem,
  getFiltersState,
  getShowCustomViews,
  isReadOnlyUser,
} from 'selectors';

type OwnProps = {
  tab: string;
  activeTab?: string;
  isModal?: boolean;
};
type ReduxStateProps = ReturnType<typeof stateToProps>;
type ReduxDispatchProps = typeof dispatchToProps;

type Props = ReduxStateProps & ReduxDispatchProps & OwnProps;

type HandlerType = Record<Filters.Config['type'], React.FC<HandlerProps>>;

const Handlers: HandlerType = {
  date: DropdownCalendar,
  datepicker: DropdownCalendar,
  radio: DropdownNormal,
  checkbox: DropdownNormal,
  text_field: DropdownNormal,
  search_bar: DropdownText,
  slider: DropdownMoneySlider,
};

const isShowCustomViewPage: Record<string, boolean> = {
  accounts: true,
  forecast_opportunities: true,
  forecast_roll_ups: true,
  forecast_analytics: true,
  forecast_analytics_change: true,
  pipeline_dashboard: true,
  pipeline_analytics: true,
  seller_dashboard: true,
};

const OpenFiltersPanel: React.FC<Props> = ({
  clearFilters,
  filterPersist,
  filters,
  tab,
  activeTab,
  isModal = false,
  isShowCustomViews,
  isReadOnlyUser,
  selectedMetric,
  selectMetric,
}) => {
  const dispatch = useDispatch();

  const {
    persistName,
    properties,
    showOrHideColumns,
    selectedBusinessType,
    selectedProfile,
    isLegacyViewsEnabled,
    filterProfilesNames,
  } = useSelector((state: IReduxState) => {
    return {
      persistName: getPersistName(state, false),
      properties: getFiltersForCustomViews(state, tab),
      showOrHideColumns: getShowOrHideColumns(state, false),
      selectedBusinessType: allTabs.includes(tab as BusinessTypeTab)
        ? getSelectedBusinessTypeForPanel(state, tab as BusinessTypeTab)
        : getForecastActiveBusinessType(state),
      selectedProfile: getActiveFiltersProfile(state, tab),
      isLegacyViewsEnabled: getFeatureFlags(state).legacy_views_enabled,
      filterProfilesNames: getFiltersProfilesNames(state, tab),
    };
  });

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [pinnedView, setPinnedView] = useState<PinnedView>({
    id: '',
    name: '',
  });

  if (!filters) {
    return null;
  }

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSubmitNewView = (name: string, isPinned: boolean) => {
    if (name && properties) {
      const data: Filters.CustomView = {
        view_type: 'PERSONAL',
        view_section: tab,
        pinned: isPinned,
        properties: {
          ...properties,
          tablePersistName: persistName,
          columnToggleState: showOrHideColumns,
          display_name: name,
          selectedBusinessType,
        },
      };

      dispatch(createCustomView(data, pinnedView.id));
      setModalOpen(false);
      handleViewAnalytics(data, name, true);
    }
  };

  const handleUpdateCustomView = () => {
    if (selectedProfile?.name && selectedProfile?.profile?.id && properties) {
      const data: Filters.CustomView = {
        view_type: 'PERSONAL',
        view_section: tab,
        pinned: Boolean(selectedProfile.profile.pinned),
        properties: {
          ...properties,
          tablePersistName: persistName,
          columnToggleState: showOrHideColumns,
          display_name: selectedProfile.name,
          selectedBusinessType,
        },
      };

      dispatch(updateCustomView(selectedProfile.profile.id, data));
      handleViewAnalytics(data, selectedProfile?.name, false);
    }
  };

  const handleViewAnalytics = (
    data: Filters.CustomView,
    name: string,
    isNew?: boolean
  ) => {
    if (tabsWithAnalytics.includes(tab)) {
      AnalyticsTracker.event(
        { tab, data },
        {
          category: isNew ? 'Save view' : 'Update view',
          action: isNew ? 'Create new view' : 'Update view',
          label: name,
        }
      );
    }
  };

  const handleAnalytics = (
    action: string,
    label: string = '',
    values?: Filters.PersistValue[]
  ): void => {
    if (tabsWithAnalytics.includes(tab)) {
      AnalyticsTracker.event(
        { tab, values },
        {
          category: 'Filters Panel',
          action,
          label,
        }
      );
    }
  };

  const filtersList = Object.keys(filters).filter(
    (filter) =>
      filter !== 'users_seller' &&
      (activeTab !== 'scorecard' ||
        filter === 'activity_period' ||
        filter === 'sales_managers')
  );

  const isDefaultView = selectedProfile?.profile.view_type === 'DEFAULT';

  const onChangeView = (name: string): void => {
    clearFilters({ tab });
    selectMetric('');
    handleAnalytics('Change View', name);
  };

  return (
    <div className={`${s.panel} ${isModal ? 'modal' : ''}`}>
      <div className={s.panel_filters}>
        {isShowCustomViews && isShowCustomViewPage[tab] && (
          <>
            {!isLegacyViewsEnabled ? (
              <CustomViewsDropdown
                tab={tab}
                pinnedView={pinnedView}
                setPinnedView={setPinnedView}
                onChange={onChangeView}
                openCreateNewViewModal={handleOpenModal}
              />
            ) : (
              <ViewDropdownFilter tab={tab} onChange={onChangeView} />
            )}
          </>
        )}

        {filtersList.map((name) => {
          const filter = filters[name];
          const Handler = Handlers[filter.type] || DropdownNormal;

          return (
            <Handler
              tab={tab}
              key={name}
              name={name}
              config={filter}
              onChange={(
                values: Filters.PersistValue[],
                withReset: boolean = false
              ) => {
                // unselect metrics bar when change forecast category filter
                if (name === FILTERS.FORECAST_CATEGORY_NAMES.key) {
                  selectMetric('');
                }

                // unselect metric if opp stage filter is changed while Booked is selected metric
                if (
                  name === FILTERS.OPPORTUNITY_STAGES.key &&
                  selectedMetric === 'Booked'
                ) {
                  selectMetric('');
                }

                filterPersist({ tab, name, values, withReset });
                handleAnalytics('Change Filter', filter.title, values);
              }}
            />
          );
        })}

        <button
          className={s.panel_button}
          onClick={() => {
            clearFilters({ tab });
            handleAnalytics('Reset Filters');
            selectMetric('');
          }}
        >
          Reset to Default
        </button>
      </div>

      <div className={s.saveBtnContainer}>
        {Boolean(
          !isReadOnlyUser && isShowCustomViews && isShowCustomViewPage?.[tab]
        ) && (
          <>
            {!isLegacyViewsEnabled ? (
              <SaveViewDropdown
                isDefaultView={isDefaultView}
                openCreateNewViewModal={handleOpenModal}
                onUpdateCustomView={handleUpdateCustomView}
              />
            ) : (
              <SaveViewFilter tab={tab} />
            )}
          </>
        )}
      </div>

      <CreateNewViewModal
        isOpen={isModalOpen}
        filterProfilesNames={filterProfilesNames}
        onClose={handleCloseModal}
        onSubmit={handleSubmitNewView}
      />
    </div>
  );
};

const stateToProps = (state: IReduxState, { tab }: OwnProps) => ({
  filters: getFiltersState(state, tab),
  isShowCustomViews: getShowCustomViews(state),
  isReadOnlyUser: isReadOnlyUser(state),
  selectedMetric: getSelectedItem(state),
});

const dispatchToProps = {
  clearFilters: actions.ui.filters.clearAll,
  filterPersist: actions.ui.filters.persist,
  selectMetric: forecastActions.selectItem,
};

export default connect(stateToProps, dispatchToProps)(OpenFiltersPanel);
