import { css } from 'emotion';
import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { actions } from 'actions';
import { ViewBy } from 'common/enums/filters';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';

const buttons = css`
  &.ui.buttons {
    & > .ui.button.basic {
      padding: 8px 12px;

      &:first-child {
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
      }

      &:last-child {
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
      }

      &.blue {
        color: #9b9b9b !important;
        background-color: #fff !important;
        box-shadow: 0 0 0 1px var(--bu-primary-500) inset !important;

        &.active {
          background-color: var(--bu-primary-200) !important;
          color: var(--bu-primary-500) !important;
        }
      }
    }
  }
`;

type ReduxStateProps = ReturnType<typeof stateToProps>;
type ReduxDispatchProps = typeof dispatchToProps;
type Props = ReduxStateProps & ReduxDispatchProps;

type IViewButton = {
  viewBy: ViewBy;
  type: ViewBy;
  onClick(viewBy: ViewBy): void;
  label: string;
};
const ViewButton: React.FC<IViewButton> = ({
  viewBy,
  type,
  onClick,
  label,
}) => {
  const isActive = viewBy === type;

  return (
    <Button
      icon
      color="blue"
      basic
      active={isActive}
      onClick={() => !isActive && onClick(type)}
    >
      {label}
    </Button>
  );
};

const ViewTypeButtons: React.FC<Props> = ({ viewBy, updateViewBy }) => (
  <Button.Group className={buttons}>
    <ViewButton
      viewBy={viewBy}
      type={ViewBy.MANAGER}
      onClick={updateViewBy}
      label="Manager View"
    />
    <ViewButton
      viewBy={viewBy}
      type={ViewBy.SELLER}
      onClick={updateViewBy}
      label="Seller View"
    />
  </Button.Group>
);

const stateToProps = (state: IReduxState) => ({
  viewBy: selectors.filtersViewBy(state),
});

const dispatchToProps = {
  updateViewBy: actions.ui.filters.updateViewBy,
};

export default connect(stateToProps, dispatchToProps)(ViewTypeButtons);
