/**
 * Highcharts mixin for Sankey chart, it is allowing to change point data label position
 */
export default function (H: any) {
  H.wrap(H.seriesTypes.sankey.prototype, 'alignDataLabel', function (
    this: any,
    proceeds: any
  ) {
    proceeds.apply(this, Array.prototype.slice.call(arguments, 1));

    const [point, dataLabel] = Array.prototype.slice.call(arguments, 1);

    const pointBBox = point.graphic?.getBBox(),
      dataLabelBBox = dataLabel?.getBBox();

    if (!pointBBox || !dataLabelBBox) {
      dataLabel.hide();
      return;
    }

    if (point.options?.dataLabels?.position === 'right') {
      dataLabel.alignAttr.x = pointBBox.x + pointBBox.width + 10;
      dataLabel.attr({
        x: pointBBox.x + pointBBox.width + 10,
      });
    } else if (point.options?.dataLabels?.position === 'left') {
      dataLabel.alignAttr.x = pointBBox.x - 10;
      dataLabel.attr({
        x: pointBBox.x - 10,
        'text-anchor': 'end',
      });
    }

    if (!point.isNode) {
      dataLabel.alignAttr.x =
        pointBBox.x + pointBBox.width - dataLabelBBox.width - 5;
      dataLabel.alignAttr.y =
        point.graphic.pathArray[1][6] - dataLabelBBox.height;
      dataLabel.alignAttr.opacity = 0;
      dataLabel.newOpacity = 0;
      dataLabel.attr({
        x: pointBBox.x + pointBBox.width - dataLabelBBox.width - 5,
        y:
          point.graphic.pathArray[1][6] +
          (point.graphic.pathArray[3][2] -
            point.graphic.pathArray[1][6] -
            dataLabelBBox.height) /
            2,
        opacity: 0,
      });
      dataLabel.hide();
    } else {
      dataLabel.alignAttr.opacity = 1;
      dataLabel.newOpacity = 1;

      dataLabel.show();
      dataLabel.attr({
        opacity: 1,
      });
    }
  });
}
