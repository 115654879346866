import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { FunnelWidget } from 'components/dashboard/Metrics/Widget/FunnelWidget/FunnelWidget';
import { HistoricalWidgetDashboard } from 'components/dashboard/Metrics/Widget/HistoricalWidgetDashboard';
import { MetricsWidgetDashboard } from 'components/dashboard/Metrics/Widget/MetricsWidgetDashboard';
import { ReportWidget } from 'components/dashboard/Metrics/Widget/ReportWidget';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import {
  BIDashboardSettings,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  isDashboardModal?: boolean;
  widget: Partial<BIWidget>;
  dashboardId?: string;
  dashboardName?: string;
  metricsFromList: any[];
  dashboardSettings?: BIDashboardSettings;
  onCloneWidget: (widgetId: string) => void;
  onRemoveWidget: (widgetId: string) => void;
  onUpdateWidget: (widget: Partial<BIWidget>) => void;
  readOnly: boolean;
  onNameClicked?: (widget: Partial<BIWidget>) => void;
  closeWidgetModal?: () => void;
}

export const DashboardWidget: React.FC<Props> = ({
  isDashboardModal = false,
  widget,
  dashboardId = '',
  dashboardName = '',
  metricsFromList,
  dashboardSettings,
  onCloneWidget,
  onRemoveWidget,
  onUpdateWidget,
  readOnly = false,
  onNameClicked,
  closeWidgetModal,
}) => {
  const history = useHistory();

  const setDashboardWidget = useCallback(
    (params) => {
      if (params instanceof Function) {
        onUpdateWidget(params(widget));
      } else {
        onUpdateWidget(params);
      }
    },
    [JSON.stringify(widget)]
  );

  const handleEditWidget = (widgetId: string): void => {
    history.push(
      `/revbi/widgets/edit/${widgetId}?saveRedirect=${history.location.pathname}`
    );
  };

  // this is need for update the dashboard widget in the modal and in the dashboard.

  return (
    <>
      {widget.analysis_type === AnalysisType.LIVE && (
        <MetricsWidgetDashboard
          key={widget._id}
          isDashboardModal={isDashboardModal}
          dashboardSettings={dashboardSettings}
          widget={widget}
          dashboardId={dashboardId}
          dashboardName={dashboardName}
          metricsFromList={metricsFromList}
          setWidget={setDashboardWidget}
          onCloneWidget={readOnly ? undefined : onCloneWidget}
          onEditWidget={readOnly ? undefined : handleEditWidget}
          onRemoveWidget={readOnly ? undefined : onRemoveWidget}
          onNameClicked={onNameClicked}
          closeWidgetModal={closeWidgetModal}
        />
      )}

      {widget.analysis_type === AnalysisType.HISTORICAL && (
        <HistoricalWidgetDashboard
          key={widget._id}
          isDashboardModal={isDashboardModal}
          dashboardSettings={dashboardSettings}
          widget={widget}
          dashboardId={dashboardId}
          dashboardName={dashboardName}
          metricsFromList={metricsFromList}
          setWidget={setDashboardWidget}
          onCloneWidget={readOnly ? undefined : onCloneWidget}
          onEditWidget={readOnly ? undefined : handleEditWidget}
          onRemoveWidget={readOnly ? undefined : onRemoveWidget}
          onNameClicked={onNameClicked}
          closeWidgetModal={closeWidgetModal}
        />
      )}

      {widget.analysis_type === AnalysisType.REPORT && (
        <ReportWidget
          key={widget._id}
          isDashboardModal={isDashboardModal}
          dashboardSettings={dashboardSettings}
          widget={widget}
          dashboardName={dashboardName}
          metric={widget.metric_list[0]}
          setWidget={setDashboardWidget}
          onCloneWidget={readOnly ? undefined : onCloneWidget}
          onEditWidget={readOnly ? undefined : handleEditWidget}
          onRemoveWidget={readOnly ? undefined : onRemoveWidget}
          onNameClicked={onNameClicked}
          closeWidgetModal={closeWidgetModal}
        />
      )}

      {widget.analysis_type === AnalysisType.FUNNEL && (
        <FunnelWidget
          isDashboardWidget
          isDashboardModal={isDashboardModal}
          dashboardSettings={dashboardSettings}
          widget={widget}
          dashboardName={dashboardName}
          key={widget._id}
          setWidget={setDashboardWidget}
          onCloneWidget={readOnly ? undefined : onCloneWidget}
          onEditWidget={readOnly ? undefined : handleEditWidget}
          onRemoveWidget={readOnly ? undefined : onRemoveWidget}
          onNameClicked={onNameClicked}
          closeWidgetModal={closeWidgetModal}
        />
      )}
    </>
  );
};
