import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import {
  BuDropdownItem,
  BuDropdownItemContainer,
} from 'components/UI/BuDropdown';
import BuDropdown from 'components/UI/BuDropdown/BuDropdown';
import BuSelect from 'components/UI/BuSelect';
import TypedTable, { IRow } from 'components/UI/common/TypedTable/TypedTable';
import {
  StagesSection,
  StagesDescription,
  StagesTitle,
  StageValues,
  AddStageButtonWrapper,
  LoaderContainer,
  ItemsWrapper,
} from 'components/dashboard/Metrics/Create/StagesSelector/styles';
import {
  IProps,
  SFField,
  SFValue,
} from 'components/dashboard/Metrics/Create/StagesSelector/types';
import { getColumns } from 'components/dashboard/Metrics/Create/StagesSelector/utils';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import { fetchApi } from 'utils/network';

const StagesSelector: React.FC<IProps> = ({ widget, setWidget }) => {
  const [dataLoaded, setDataLoaded] = useState(false);

  const [fieldLoading, setFieldLoading] = useState(true);
  const [fieldOptions, setFieldOptions] = useState<SFField[]>([]);
  const [selectedField, setSelectedField] = useState('');

  const [valuesLoading, setValuesLoading] = useState(false);
  const [valuesOptions, setValuesOptions] = useState<SFValue[]>([]);

  const [removedStages, setRemovedStages] = useState<SFValue[]>([]);

  // Load Field Values on component load
  useEffect(() => {
    fetchApi({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields/opportunity`,
      queryMethod: 'get',
      setData: (fields: SFField[]) => {
        setFieldLoading(false);
        setFieldOptions(fields.filter((field) => field.type === 'picklist'));
      },
      setError: (error: string | null) => {
        setFieldLoading(false);
        toast.error(`Failed to load field to select stages: ${error}`);
      },
    });
  }, []);

  // Set the selected field if we're editing the widget
  useEffect(() => {
    if (fieldOptions.length === 0) {
      return;
    }
    if (widget.funnel_stage_column) {
      setSelectedField(widget.funnel_stage_column);
    }
  }, [fieldOptions]);

  // Request the stage values when the selected field changes
  useEffect(() => {
    if (selectedField === '') {
      return;
    }

    const selected = fieldOptions.find((field) => field.name === selectedField);
    setValuesLoading(true);
    fetchApi({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/company_settings/get_column_values`,
      queryMethod: 'post',
      queryParams: selected,
      setData: (responseValues: SFValue[]) => {
        setValuesLoading(false);
        const values = responseValues.filter(
          (item) => !(item.value.startsWith('__') && item.value.endsWith('__'))
        );

        if (
          dataLoaded ||
          widget.funnel_stages === undefined ||
          widget.funnel_stages?.length === 0
        ) {
          if (values.length > 15) {
            setValuesOptions(values.slice(0, 15));
            setRemovedStages(values.slice(15));
          } else {
            setValuesOptions(values);
            setRemovedStages([]);
          }
        } else {
          setValuesOptions(
            widget.funnel_stages
              .filter(
                (stage) =>
                  values.filter((value) => value.value == stage).length > 0
              )
              .map((stage) => values.filter((value) => value.value == stage)[0])
          );
          setRemovedStages(
            values.filter(
              (value) => !widget.funnel_stages?.includes(value.value)
            )
          );
        }
        setDataLoaded(true);
      },
      setError: (error: string | null) => {
        setValuesLoading(false);
        toast.error(
          `Failed to load values for field ${selectedField}: ${error}`
        );
      },
    });
    return;
  }, [selectedField]);

  // Save the stage field
  useEffect(() => {
    if (fieldLoading) {
      return;
    }

    setWidget({ ...widget, funnel_stage_column: selectedField });
  }, [selectedField]);

  // Save the stages when the stage values change
  const saveStages = (funnel_stages: string[]) => {
    setWidget({ ...widget, funnel_stages });
  };

  // Save when removed stages change
  useEffect(() => {
    if (fieldLoading) {
      return;
    }

    const stages = valuesOptions
      .filter((stage) => !removedStages.includes(stage))
      .map((stage) => stage.value);
    saveStages(stages);
  }, [removedStages]);

  // Handles the reordering of stage values
  const handleReorderRows = (rows: IRow[]) => {
    const stages = rows.map((row) => ({
      value: row.id as string,
      display_name: row.name as string,
    }));
    setValuesOptions(stages);
    saveStages(stages.map((stage) => stage.value));
  };

  // Handles the removal of a stage value
  const onRemoveStage = (removedRow: IRow, rows: IRow[]) => {
    setValuesOptions(
      rows
        .filter((row) => row.id != removedRow.id)
        .map((row) => ({
          value: row.id as string,
          display_name: row.name as string,
        }))
    );
    setRemovedStages((removedStages) => [
      ...removedStages,
      {
        value: removedRow.id as string,
        display_name: removedRow.name as string,
      },
    ]);
  };

  // Handles adding back a stage
  const onAddStage = (addedStage: SFValue) => {
    setValuesOptions((stageValueOptions) => [...stageValueOptions, addedStage]);
    setRemovedStages((removedStages) =>
      removedStages.filter(
        (removedStage) => removedStage.value !== addedStage.value
      )
    );
  };

  const stageFieldOptions = useMemo(
    () =>
      fieldOptions
        .map((field) => ({
          value: field.name,
          text: field.label,
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    [fieldOptions]
  );

  const columns = getColumns(onRemoveStage);

  const rows =
    valuesOptions.map((value, index) => ({
      order: index + 1,
      id: value.value,
      name: value.display_name,
    })) || [];

  return (
    <StagesSection>
      <StagesTitle>Stages</StagesTitle>
      <StagesDescription>
        Define the stages of your funnel, their order and which date fields will
        be used to calculate when records enter and exit each stage
      </StagesDescription>

      <MetricCreateSubTitle>
        CRM Field that represents the stages
      </MetricCreateSubTitle>

      {fieldLoading ? (
        <LoaderContainer>
          <Loader active />
        </LoaderContainer>
      ) : (
        <BuSelect
          fullWidth
          isLargePlaceholder
          secondary
          searchable
          placeholder="Select a field"
          defaults={[selectedField]}
          options={stageFieldOptions}
          onChange={(selection) => {
            setSelectedField(selection[0]);
          }}
          testingLabel="funnel-stage-column-select"
        />
      )}

      {valuesLoading ? (
        <LoaderContainer>
          <Loader active />
        </LoaderContainer>
      ) : (
        <>
          <StageValues data-testing="funnel-stages-table-container">
            <TypedTable
              columns={columns}
              data={rows}
              width="100%"
              minWidth="100%"
              enableReorderingRows
              hideColumnHeader
              onDrop={handleReorderRows}
              hasActionsColumn
            />
          </StageValues>

          <AddStageButtonWrapper>
            {removedStages.length > 0 && (
              <BuDropdown label="+ Add Stage" secondary>
                {(hide) => (
                  <BuDropdownItemContainer>
                    <ItemsWrapper>
                      {removedStages.map((removedStage) => (
                        <BuDropdownItem
                          key={removedStage.value}
                          onClick={() => {
                            onAddStage(removedStage);
                            hide();
                          }}
                        >
                          {removedStage.display_name}
                        </BuDropdownItem>
                      ))}
                    </ItemsWrapper>
                  </BuDropdownItemContainer>
                )}
              </BuDropdown>
            )}
          </AddStageButtonWrapper>
        </>
      )}
    </StagesSection>
  );
};

export default StagesSelector;
