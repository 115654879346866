import React from 'react';

import { HierarchicalUserFilter } from 'components/dashboard/Metrics/common/HierarchicalUserFilter/HierarchicalUserFilter';

interface Props {
  values: string[];
  updateFilters: (
    emptyFilters: boolean,
    operator: string,
    value: string | string[] | number
  ) => void;
}

export const UserTypeFilter: React.FC<Props> = ({ values, updateFilters }) => {
  const handleChangeUserFilter = (
    filterValues: string[],
    checkedAll: boolean = false
  ) => {
    const noneChecked = !filterValues.length;
    updateFilters(checkedAll || noneChecked, 'in', filterValues);
  };

  return (
    <HierarchicalUserFilter
      currentValues={values}
      onChangeUserFilter={handleChangeUserFilter}
    />
  );
};
