import cf from 'currency-formatter';

const BROWSER_LANGUAGE_CODE = 'en-US'; // TODO start using `window.navigator.language`;

/**
 * @deprecated use below functions
 * @param value
 * @param code
 * @param precision
 */
export const formatCurrency = (
  value: number,
  code: string,
  precision: number
) => cf.format(value, { code, precision });

/**
 * Returning localized number, eg.: (en-US) 1234567.89 -> 1,234,567.89
 * @param number
 * @param precision
 */
export const formatNumber = (number: number, precision?: number) =>
  number.toLocaleString(BROWSER_LANGUAGE_CODE, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

/**
 * Shorting number function
 * Return localized shorten number, e.g.: (en-US) 1234567.89 -> 1.9M
 * @param value
 */
export const shortNumber = (value: number) => {
  const absValue: number = Math.abs(value);
  switch (true) {
    case absValue < 1e3:
      return formatNumber(value, 0);
    case absValue < 1e6:
      return formatNumber(value / 1e3, 0) + 'K';
    case absValue < 1e9:
      return formatNumber(value / 1e6, 1) + 'M';
    case absValue < 1e12:
      return formatNumber(value / 1e9, 1) + 'B';
    default:
      return formatNumber(value / 1e12, 1) + 'T';
  }
};

const PRECISION = 2;
const NUMBER_TO_REPLACE = 0;
const FORMATTED_NUMBER_TO_REPLACE = formatNumber(NUMBER_TO_REPLACE, PRECISION);
/**
 * Return localized money value, e.g. ('eb-US' + currency 'USD') 1234567.89 -> $1,234,567.89
 * @param companyCurrency
 * @param number
 * @param precision
 */
export const formatMoney = (
  companyCurrency: string,
  number: number,
  precision: number = 0
) =>
  number?.toLocaleString
    ? number.toLocaleString(BROWSER_LANGUAGE_CODE, {
        style: 'currency',
        currency: companyCurrency,
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      })
    : '-';

/**
 * Return localized money shorten value, e.g. ('eb-US' + currency 'USD') 1234567.89 -> $1.9M
 * @param companyCurrency
 * @param number
 * @param shortNumberFn
 * @param positiveSign
 */
export const formatMoneyAndShort = (
  companyCurrency: string,
  number: number,
  shortNumberFn: typeof shortNumber,
  positiveSign: boolean = false
) => {
  const formatedMoney = formatMoney(
    companyCurrency,
    NUMBER_TO_REPLACE,
    PRECISION
  ).replace(FORMATTED_NUMBER_TO_REPLACE, shortNumberFn(Math.abs(number)));
  let signal = '';

  if (number > 0 && positiveSign) {
    signal = '+ ';
  } else if (number < 0) {
    signal = '- ';
  }

  return `${signal}${formatedMoney}`;
};

/**
 * Short for `formatMoneyAndShort` function with predefined shorten number function
 * @param number
 * @param companyCurrency
 * @param positiveSign
 */
export const formatAmount = (
  number: number,
  companyCurrency: string,
  positiveSign: boolean = false
) => formatMoneyAndShort(companyCurrency, number, shortNumber, positiveSign);

/**
 * Return currency symbol, e.g. USD -> $
 * @param companyCurrency
 */
export const getCurrencySymbol = (companyCurrency: string) =>
  formatMoney(companyCurrency, NUMBER_TO_REPLACE, PRECISION)
    .replace(FORMATTED_NUMBER_TO_REPLACE, '')
    .trim();
