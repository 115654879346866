import moment from 'moment';

export const formatTo = (
  date: string,
  prevFormat: string,
  nextFormat: string
) => {
  return moment(date, prevFormat).format(nextFormat);
};

export const timeOptions = {
  firstCol: [
    { value: 'L7D', text: 'Last 7 days' },
    { value: 'L60D', text: 'Last 60 days' },
  ],
  secondCol: [
    { value: 'L14D', text: 'Last 14 days' },
    { value: 'L3M', text: 'Last 3 months' },
  ],
  thirdCol: [
    { value: 'L30D', text: 'Last 30 days' },
    { value: 'SQS', text: 'Quarter Start' },
  ],
};

export const timeSpanOptions = {
  timeSpanPresent: [
    { value: 'TWE', text: 'Current Week' },
    { value: 'TQU', text: 'Current Quarter' },
    { value: 'TMO', text: 'Current Month' },
    { value: 'TYE', text: 'Current Year' },
    { value: 'ALT', text: 'All Time' },
  ],
  timeSpanPast: [
    { value: 'LWE', text: 'Last Week' },
    { value: 'LQU', text: 'Last Quarter' },
    { value: 'LMO', text: 'Last Month' },
    { value: 'L7D', text: 'Last 7 days' },
    { value: 'L14D', text: 'Last 14 days' },
    { value: 'L30D', text: 'Last 30 days' },
  ],
  timeSpanFuture: [
    { value: 'NQU', text: 'Next Quarter' },
    { value: 'NMO', text: 'Next Month' },
    { value: 'N3M', text: 'Next 3 Months' },
    { value: 'N6M', text: 'Next 6 Months' },
    { value: 'FUT', text: 'Closing in the Future' },
  ],
  timeSpanOther: [
    { value: 'any', text: 'Any' },
    { value: 'none', text: 'None' },
  ],
};

export const selectTimeRange = (timeSpan: string[], isDashboard?: boolean) => {
  const value = timeSpan[0];
  const now = Date.now();
  const format = moment.unix(now / 1000).format('MM/DD/YYYY');

  switch (value) {
    case 'TWE': {
      // Current Week
      const startDate = moment().startOf('week').format('YYYY-MM-DD');
      const endDate = isDashboard
        ? moment().format('YYYY-MM-DD')
        : moment().endOf('week').format('YYYY-MM-DD');
      return [{ [startDate]: endDate }];
    }
    case 'LWE': {
      // Last Week
      const startDate = moment()
        .subtract(1, 'w')
        .startOf('week')
        .format('YYYY-MM-DD');
      const endDate = moment()
        .subtract(1, 'w')
        .endOf('week')
        .format('YYYY-MM-DD');
      return [{ [startDate]: endDate }];
    }
    case 'TQU': // Current Quarter
      const startDateQ = moment().startOf('quarter').format('YYYY-MM-DD');
      const endDateQ = isDashboard
        ? moment().format('YYYY-MM-DD')
        : moment().endOf('quarter').format('YYYY-MM-DD');
      return [{ [startDateQ]: endDateQ }];
    case 'TMO': // Current Month
      const startDateM = moment().startOf('month').format('YYYY-MM-DD');
      const endDateM = isDashboard
        ? moment().format('YYYY-MM-DD')
        : moment().endOf('month').format('YYYY-MM-DD');
      return [{ [startDateM]: endDateM }];
    case 'TYE': // Current Year
      const startDateY = moment().startOf('year').format('YYYY-MM-DD');
      const endDateY = isDashboard
        ? moment().format('YYYY-MM-DD')
        : moment().endOf('year').format('YYYY-MM-DD');
      return [{ [startDateY]: endDateY }];
    case 'LQU': // Last Quarter
      const startDateLQ = moment(format, 'MM/DD/YYYY')
        .subtract(1, 'Q')
        .startOf('quarter')
        .format('YYYY-MM-DD');
      const endDateLQ = moment(format, 'MM/DD/YYYY')
        .subtract(1, 'Q')
        .endOf('quarter')
        .format('YYYY-MM-DD');
      return [{ [startDateLQ]: endDateLQ }];
    case 'LMO': // Last Month
      const startDateLM = moment(format, 'MM/DD/YYYY')
        .subtract(1, 'M')
        .startOf('month')
        .format('YYYY-MM-DD');
      const endDateLM = moment(format, 'MM/DD/YYYY')
        .subtract(1, 'M')
        .endOf('month')
        .format('YYYY-MM-DD');
      return [{ [startDateLM]: endDateLM }];
    case 'NQU': // Next Quarter
      const startDateNQ = moment(format, 'MM/DD/YYYY')
        .add(1, 'quarter')
        .startOf('month')
        .format('YYYY-MM-DD');
      const endDateNQ = moment(format, 'MM/DD/YYYY')
        .add(1, 'quarter')
        .endOf('quarter')
        .format('YYYY-MM-DD');
      return [{ [startDateNQ]: endDateNQ }];
    case 'NMO': // Next Month
      const startDateNMO = moment(format, 'MM/DD/YYYY')
        .add(1, 'month')
        .startOf('month')
        .format('YYYY-MM-DD');
      const endDateNMO = moment(format, 'MM/DD/YYYY')
        .add(1, 'month')
        .endOf('month')
        .format('YYYY-MM-DD');
      return [{ [startDateNMO]: endDateNMO }];
    case 'N3M': // Next 3 Month
      const startDateN3M = moment(format, 'MM/DD/YYYY')
        .add(1, 'month')
        .startOf('month')
        .format('YYYY-MM-DD');
      const endDateN3M = moment(format, 'MM/DD/YYYY')
        .add(3, 'month')
        .endOf('month')
        .format('YYYY-MM-DD');
      return [{ [startDateN3M]: endDateN3M }];
    case 'N6M': // Next 6 Month
      const startDateN6M = moment(format, 'MM/DD/YYYY')
        .add(1, 'month')
        .startOf('month')
        .format('YYYY-MM-DD');
      const endDateN6M = moment(format, 'MM/DD/YYYY')
        .add(6, 'month')
        .endOf('month')
        .format('YYYY-MM-DD');
      return [{ [startDateN6M]: endDateN6M }];
    case 'L7D': // Last 7 days
      const startDateL7D = moment().format('YYYY-MM-DD');
      const endDateL7D = moment().subtract(7, 'days').format('YYYY-MM-DD');
      return [{ [endDateL7D]: startDateL7D }];
    case 'L14D': // Last 14 days
      const startDateL14D = moment().format('YYYY-MM-DD');
      const endDateL14D = moment().subtract(14, 'days').format('YYYY-MM-DD');
      return [{ [endDateL14D]: startDateL14D }];
    case 'L30D': // Last 30 days
      const startDateL30D = moment().format('YYYY-MM-DD');
      const endDateL30D = moment().subtract(30, 'days').format('YYYY-MM-DD');
      return [{ [endDateL30D]: startDateL30D }];
    case 'FUT': // Closing in the Future

    case 'ALT': // All Time
  }
};
