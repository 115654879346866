import { SimpleCellConfig } from '../common/TypedTable/renderers/SimpleCell';
import * as R from 'ramda';

import customDealNameCell from 'components/UI/TableConfig/CustomDealNameCell';
import {
  isColumnConfigClickable,
  isColumnConfigQuery,
  isColumnConfigRisk,
  isColumnConfigSalesProcess,
  PicklistColumnConfig,
  SelectColumnConfig,
  TableConfigurationColumn,
} from 'components/UI/TableConfig/types';
import {
  IColumn,
  IRow,
  SortOrder,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { DropDownCellConfig } from 'components/UI/common/TypedTable/renderers/DropDownCell';
import { ISalesProcessCellConfig } from 'components/UI/common/TypedTable/renderers/SalesProcessCell';
import { getExpressionWithDisplayName } from 'components/dashboard/ForecastRollUps/helpers/formulas';
import { ModalsScheme } from 'navigation/modals';
import { openModal } from 'navigation/utils';

export * from './helper-methods';

export interface CallbackProps {
  column: IColumn;
  columnConfig: TableConfigurationColumn;
  objectId: any;
  row: IRow;
  extraData?: { [key: string]: ValueProp };
}

interface IColumnTypes
  extends Pick<
    IColumn,
    | 'type'
    | 'delta'
    | 'editable'
    | 'maxWidth'
    | 'minWidth'
    | 'width'
    | 'align'
    | 'disable'
    | 'length'
  > {
  type: ColumnTypes;
  config: {
    [key: string]: any;
  };
}

export const ACTIONS_COLUMN_DEFAULT_WIDTH = 80;

export const fixedTo = (number: number, decimals: number) => {
  const k = Math.pow(10, decimals);
  return Math.round(number * k) / k;
};

export const numberFormatter = (value: unknown) => `${value ?? '-'}`;

export const percentFormatter = (value: number | string) =>
  isNaN(Number(value)) ? value : `${Number(value).toFixed(0)}%`;

export const percentDecimalsFormatter =
  (decimals: number) => (value: number | string) => {
    const parsedValue = Number(value);

    return isNaN(parsedValue) ? value : `${fixedTo(parsedValue, decimals)}%`;
  };

export const multiplierFormatter = (value: number | string) =>
  isNaN(Number(value)) ? value : `${Number(value).toFixed(1)}x`;

export type ColumnTypesCallback = (props: CallbackProps) => void;

type ColumnTypesProps = {
  currencyFormatter: Function;
  riskMoreButtonLabel?: string;
  showSyncDealButton?: boolean;
  getButtonLabel?: Function;
  sequence?: Array<{ [key: string]: any }>;
};

export const typeToScheme: { [key: string]: ModalsScheme } = {
  account_id: '/account/:id',
  _id: '/deal/:id',
};

export const columnTypes =
  (
    {
      currencyFormatter,
      riskMoreButtonLabel = 'View More',
      showSyncDealButton = false,
      getButtonLabel,
      sequence,
    }: ColumnTypesProps,
    callback: ColumnTypesCallback = () => {},
    sfBaseUrl: string,
    isMulticurrencyEnabled?: boolean
  ) =>
  (
    columnConfig: TableConfigurationColumn,
    allColumnsConfig?: TableConfigurationColumn[]
  ): IColumnTypes => {
    switch (columnConfig.type) {
      case 'text':
      case 'number':
      case 'checkbox':
      case 'percentage':
      case 'money':
      case 'corporate_currency':
      case 'multiplier': {
        const columnDetails: IColumnTypes = {
          type: columnConfig.type as ColumnTypes,
          editable: columnConfig.editable,
          delta: columnConfig.meta.delta,
          minWidth: columnConfig.min_width || 120,
          maxWidth: 160,
          config: {},
          align: 'left',
        };

        if (columnConfig.meta.type === 'clickable') {
          columnDetails.type = ColumnTypes.TEXT;
          columnDetails.config = {
            new:
              columnConfig.meta.new_flag &&
              columnConfig.meta.new_flag === 'new',
            click(
              column: IColumn,
              row: IRow,
              extraData?: CallbackProps['extraData']
            ) {
              if (isColumnConfigClickable(columnConfig)) {
                callback({
                  columnConfig,
                  column,
                  row,
                  objectId: R.path(
                    (columnConfig.meta.object_id || '').split('.'),
                    row
                  ),
                  extraData,
                });
              }
            },
          } as SimpleCellConfig;

          if (columnConfig.type === 'text') {
            columnDetails.maxWidth = 160;
          }
        }

        if (columnConfig.object_field === 'crm_metadata.opportunity_name') {
          columnDetails.type = ColumnTypes.CUSTOM;
          columnDetails.config = {
            renderer: customDealNameCell({
              callback,
              columnConfig,
              showSyncDealButton,
            }),
          };
        }

        if (columnConfig.type === 'number') {
          columnDetails.align = 'center';
          columnDetails.config.formatter = numberFormatter;
          columnDetails.config.isNumber = true;
        }

        if (columnConfig.type === 'checkbox') {
          columnDetails.disable = columnConfig.disable;
          columnDetails.align = 'center';
          columnDetails.minWidth = 80;
          columnDetails.maxWidth = 90;
          columnDetails.width = 90;
        }

        if (columnConfig.type === 'money') {
          columnDetails.type = ColumnTypes.NUMBER;
          columnDetails.width = 160;
          columnDetails.align = 'right';
          columnDetails.config.isMoney = true;
          columnDetails.config.isMulticurrency = isMulticurrencyEnabled;
          columnDetails.config.formatter = currencyFormatter;
        }

        if (columnConfig.type === 'corporate_currency') {
          columnDetails.type = ColumnTypes.CORPORATE_CURRENCY;
          columnDetails.width = 140;
          columnDetails.align = 'right';
          columnDetails.config.isMoney = true;
          columnDetails.config.formatter = currencyFormatter;
        }

        if (columnConfig.type === 'percentage') {
          columnDetails.type = ColumnTypes.NUMBER;
          columnDetails.align = 'right';
          columnDetails.config.formatter = columnConfig.meta.decimals
            ? percentDecimalsFormatter(columnConfig.meta.decimals)
            : percentFormatter;
        }

        if (columnConfig.type === 'multiplier') {
          columnDetails.type = ColumnTypes.NUMBER;
          columnDetails.align = 'right';
          columnDetails.config.formatter = multiplierFormatter;
        }

        if (columnConfig.meta.type === 'formula') {
          columnDetails.config.isFormulaColumn = true;
          columnDetails.config.formulaExpressionDisplayName = allColumnsConfig
            ? getExpressionWithDisplayName(
                columnConfig.meta.expression || '',
                allColumnsConfig
              )
            : columnConfig.meta.expression || '';
        }

        if (columnConfig.meta.type === 'change') {
          columnDetails.type = ColumnTypes.CHANGE;
          columnDetails.config.formatter = currencyFormatter;

          if (columnConfig.type === 'percentage') {
            columnDetails.config.formatter = columnConfig.meta.decimals
              ? percentDecimalsFormatter(columnConfig.meta.decimals)
              : percentFormatter;
          }

          if (sequence) {
            columnDetails.config.sequence = sequence;
          }
        }

        if (columnConfig.meta.type === 'progress_bar') {
          columnDetails.config.progressBar = {
            relativeField: columnConfig.meta.relative_field,
          };
        }

        if (columnConfig.meta.progress_bar) {
          columnDetails.config.progressBar = {
            relativeField: columnConfig.meta.progress_bar.relative_field,
          };
        }

        if (columnConfig.meta.sub_value) {
          columnDetails.config.subValue = {
            ...columnConfig.meta.sub_value,
            className: columnConfig.meta.sub_value.class_name,
            relativeField: columnConfig.meta.sub_value.relative_field,
          };
        }

        if (columnConfig.meta.tooltip) {
          columnDetails.config.tooltip = {
            ...columnConfig.meta.tooltip,
            relativeField: columnConfig.meta.tooltip.relative_field,
            relativeFields: columnConfig.meta.tooltip.relative_fields,
          };
        }

        if (columnConfig.meta.header) {
          columnDetails.config.header = columnConfig.meta.header;
        }

        if (columnConfig.meta.class_name) {
          columnDetails.config.className = columnDetails.config.className
            ? `${columnDetails.config.className} ${columnConfig.meta.class_name}`
            : columnConfig.meta.class_name;
        }

        if (columnConfig.meta.get_class_name) {
          columnDetails.config.getClassName = columnConfig.meta.get_class_name;
        }

        if (columnConfig.meta.getPrefix) {
          columnDetails.config.getPrefix = columnConfig.meta.getPrefix;
        }

        return columnDetails;
      }
      case 'date':
        const notScheduled = ['next_event', 'next_meeting'].includes(
          columnConfig.field_name
        )
          ? 'Not Scheduled'
          : undefined;

        return {
          type:
            columnConfig.meta.type === 'change'
              ? ColumnTypes.CHANGE
              : (columnConfig.type as ColumnTypes),
          editable: columnConfig.editable,
          delta: columnConfig.meta.delta,
          minWidth: 130,
          maxWidth: 130,
          width: 130,
          config: {
            format:
              columnConfig.meta.type === 'relative'
                ? 'ago'
                : columnConfig.meta.type === 'local'
                ? 'local'
                : undefined,
            not_available_placeholder: notScheduled,
          },
        };
      case 'bool':
        return {
          type: ColumnTypes.DROPDOWN,
          editable: columnConfig.editable,
          delta: columnConfig.meta.delta,
          minWidth: 120,
          maxWidth: 100,
          config: {
            options: [
              {
                value: true,
                text: 'Yes',
              },
              {
                value: false,
                text: 'No',
              },
            ],
          } as DropDownCellConfig,
        };
      case 'note':
        return {
          type: ColumnTypes.NOTE,
          editable: columnConfig.editable,
          delta: columnConfig.meta.delta,
          minWidth: 120,
          width: 200,
          length: columnConfig.length,
          maxWidth: 200,
          config: {},
        };
      case 'picklist':
        return {
          type:
            columnConfig.meta.type === 'change'
              ? ColumnTypes.CHANGE
              : ColumnTypes.DROPDOWN,
          editable: columnConfig.editable,
          delta: columnConfig.meta.delta,
          minWidth: 120,
          maxWidth: 160,
          config: {
            options: ((columnConfig as PicklistColumnConfig).choices || []).map(
              (item) => ({
                key: item ?? '-',
                value: item,
                text: item ?? '-',
              })
            ),
            sequence,
          },
        };
      case 'multipicklist':
        return {
          type: ColumnTypes.SELECT,
          editable: columnConfig.editable,
          delta: columnConfig.meta.delta,
          minWidth: 120,
          maxWidth: 160,
          config: {
            multiselect: true,
            secondary: true,
            options: ((columnConfig as SelectColumnConfig).choices || []).map(
              (item) => ({
                key: item,
                value: item,
                text: item,
              })
            ),
          },
        };
      case 'custom':
        switch (columnConfig.meta.type) {
          case 'query':
            return {
              type: ColumnTypes.TEXT,
              minWidth: 120,
              maxWidth: 160,
              align: 'center',
              config: {
                click(column: IColumn, row: IRow) {
                  if (isColumnConfigQuery(columnConfig)) {
                    callback({
                      columnConfig,
                      column,
                      row,
                      objectId: R.path(
                        (columnConfig.meta.object_id || '').split('.'),
                        row
                      ),
                    });
                  }
                },
              },
            };
          case 'risk':
            const label = getButtonLabel
              ? getButtonLabel(
                  columnConfig.field_name.includes('account')
                    ? 'Account'
                    : 'Deal'
                )
              : riskMoreButtonLabel;

            return {
              type: ColumnTypes.SCORE,
              delta: columnConfig.meta.delta,
              minWidth: 120,
              maxWidth: 160,
              width: 110,
              config: {
                risk_factors_field: columnConfig.meta.risk_factors_field,
                positive_indicators_field:
                  columnConfig.meta.positive_indicators_field,
                moreButton: {
                  label,
                  click(column: IColumn, row: IRow) {
                    if (isColumnConfigRisk(columnConfig)) {
                      callback({
                        columnConfig,
                        column,
                        row,
                        objectId: R.path(
                          (columnConfig.meta.object_id || '').split('.'),
                          row
                        ),
                      });
                    }
                  },
                },
              },
            };

          case 'popup_table': {
            const getColumnTypes = columnTypes(
              {
                currencyFormatter,
                riskMoreButtonLabel,
              },
              callback,
              sfBaseUrl
            );
            const subColumns = columnConfig.meta.columns || [];

            return {
              type: ColumnTypes.POPUP_TABLE,
              minWidth: 120,
              maxWidth: 140,
              config: {
                table: {
                  dataPath: columnConfig.meta.data_path,
                  columns: subColumns
                    .map<IColumn>((column, index) => ({
                      field: column.object_field,
                      editable: false,
                      sort_order: SortOrder.DESCENDING,
                      id: `${column.object_field}-${index}`,
                      label: column.display_name,
                      ...getColumnTypes({
                        object_field: column.object_field,
                        field_name: column.object_field,
                        editable: false,
                        sortable: false,
                        protected: false,
                        display_name: column.display_name,
                        type: column.type,
                        meta: {
                          type: 'none',
                        },
                      }),
                    }))
                    .map((col) => ({
                      ...col,
                      type:
                        col.type === ColumnTypes.NOTE
                          ? ColumnTypes.SHOW_MORE
                          : col.type,
                    })),
                },
              },
            };
          }
          case 'sales_process': {
            const { steps, version } = columnConfig.meta;
            return {
              type: ColumnTypes.SALES_PROCESS,
              config: {
                steps,
                version,
                name: columnConfig.display_name,
                click(column, row) {
                  if (isColumnConfigSalesProcess(columnConfig)) {
                    callback({
                      columnConfig,
                      column,
                      row,
                      objectId: null,
                    });
                  }
                },
              } as ISalesProcessCellConfig,
            };
          }
          case 'include_summary': {
            return {
              type: ColumnTypes.SUBMISSION_SUMMARY,
              minWidth: 120,
              width: 120,
              maxWidth: 120,
              config: {},
            };
          }
          case 'with_render': {
            const subValue = columnConfig.meta.sub_value
              ? {
                  ...columnConfig.meta.sub_value,
                  className: columnConfig.meta.sub_value.class_name,
                  relativeField: columnConfig.meta.sub_value.relative_field,
                }
              : undefined;

            return {
              type: ColumnTypes.CUSTOM,
              config: {
                subValue,
                renderer: columnConfig.meta.renderer,
                click(column: IColumn, row: IRow) {
                  callback({
                    columnConfig,
                    column,
                    row,
                    objectId: null,
                  });
                },
              },
              width: columnConfig.width,
              minWidth: columnConfig.min_width || 120,
              maxWidth: columnConfig.max_width || 160,
            };
          }
          default:
            return { type: ColumnTypes.CUSTOM, config: {} };
        }
      case 'CRM Lookup': {
        return {
          type: ColumnTypes.CRM_LOOKUP,
          editable: columnConfig.editable,
          delta: columnConfig.meta.delta,
          minWidth: 120,
          width: 200,
          maxWidth: 200,
          config: {
            isNewWindow: true,
            sfBaseUrl,
          },
        };
      }
      default:
        return {
          type: ColumnTypes.TEXT,
          minWidth: 120,
          maxWidth: 160,
          editable: columnConfig.editable,
          delta: columnConfig.meta.delta,
          config: {},
        };
    }
  };

export const handleColumnClick: ColumnTypesCallback = ({
  column,
  columnConfig,
  objectId,
}) => {
  if (column.type === ColumnTypes.TEXT) {
    if (isColumnConfigClickable(columnConfig) && columnConfig.meta.object_id) {
      const scheme = typeToScheme[columnConfig.meta.object_id];

      if (scheme) {
        openModal({
          scheme,
          params: { id: objectId },
        });
      } else {
        console.warn('Action not supported');
      }
    }
  } else if (column.type === ColumnTypes.SCORE) {
    const scheme = column.field.includes('account_risk')
      ? '/account/:id'
      : '/deal/:id';

    openModal({
      scheme,
      params: {
        id: objectId,
      },
    });
  }
};
