import classNames from 'classnames';
import moment from 'moment';
import * as R from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Icon } from 'semantic-ui-react';

import { activityRemapRequest } from 'actions/activityActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import PlayIcon from 'assets/fonts/boostup-icons/play.svg';
import { capitalizeFirstLetter, notNullAndEmpty } from 'common/utils';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import CustomerTeamEngagement from 'components/UI/EngagementTable';
import MeetingsCalls from 'components/UI/MeetingAndCallsTable';
import MomentsTabs from 'components/UI/MomentsTabs';
import ProspectEngagement from 'components/UI/ProspectEngagement';
import RiskScoreBlock from 'components/UI/RiskScoreBlock';
import TopicsTabs from 'components/UI/TopicsDiscussedTabs';
import Truncate from 'components/UI/TruncatedText';
import AccountSkeleton from 'components/account-insights/AccountSkeleton';
import DealsAccount from 'components/account-insights/DealsAccount';
import 'components/account-insights/style.css';
import styles, { debugInfoStyles } from 'components/account-insights/styles';
import { Link } from 'navigation/Link';
import {
  getAllowProcessEmailBody,
  getCrmType,
  getDealsCount,
  isReadOnlyUser,
  getFeatureFlags,
} from 'selectors';

export const getTtype = (ttype) => {
  const caps = capitalizeFirstLetter(ttype);
  return caps === 'Event' ? 'Meeting' : caps;
};

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDebugPanelOpen: false,
    };
    this.handleGoBack = this.handleGoBack.bind(this);
    this.renderProspectEngagement = this.renderProspectEngagement.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      remapActivityStatus: { status, message },
    } = this.props;

    if (status && message) {
      toast[status](message, {
        position: 'bottom-left',
      });
    }
  }

  isFactorActive = (factor) => {
    return true;
  };

  handleGoBack() {
    const { goBack, previousRoute } = this.props;
    goBack(previousRoute);
  }

  renderProspectEngagement() {
    const { id } = this.props.account;
    return (
      <ProspectEngagement
        title="PROSPECT ENGAGEMENT"
        accountId={id}
        timespan={this.props.defaultTimeFilter}
      />
    );
  }

  renderMeetingsAndCalls() {
    const data = this.props.account;
    return (
      <MeetingsCalls objectId={data.id} objectType="account" isAdmin={false} />
    );
  }

  renderCustomerTeamEngagement() {
    const { account, changeSentiment, removeSentiment } = this.props;
    return (
      <CustomerTeamEngagement
        isAdmin={false}
        accountId={account.id}
        onSentimentChange={(prospect, grade, phrases) =>
          changeSentiment(account.id, prospect, grade, phrases)
        }
        onSentimentRemove={(prospect) => removeSentiment(account.id, prospect)}
      />
    );
  }

  renderTopics() {
    const { account, defaultTimeFilter } = this.props;
    return <TopicsTabs accountId={account.id} timespan={defaultTimeFilter} />;
  }

  getContactsCount(contact_domain_counts, index) {
    return contact_domain_counts ? contact_domain_counts[index] : 0;
  }

  renderDebugPanel() {
    const { isDebugPanelOpen } = this.state;
    const {
      account: {
        additional_email_domains,
        contacts,
        email_domain,
        num_contacts,
        contact_domain_counts,
        same_domain_accounts,
      },
    } = this.props;

    const {
      column,
      container,
      marginTop15,
      marginBottom10,
      row,
      title,
      subtitle,
      paddingRow,
      lineHeight22,
      toggle_box,
      toggle_box_btn,
      content,
      iconContainer,
    } = debugInfoStyles;

    const additionalEmailDomains = additional_email_domains
      ? additional_email_domains.map(
          (d, i) =>
            `${d}
            (${this.getContactsCount(contact_domain_counts, d)} contacts) ${
              i < additional_email_domains.length - 1 ? ', ' : ''
            }`
        )
      : null;

    const contactsLinkedToAccount = contacts
      ? contacts.map(
          (c, i) => `${c.email}${i < contacts.length - 1 ? ', ' : ''}`
        )
      : null;

    const sameDomainAccounts = same_domain_accounts
      ? same_domain_accounts.map(
          (s, i) => `${s.name}${i < same_domain_accounts - 1 ? ', ' : ''}`
        )
      : null;

    const linkedToAccountCount = contacts ? contacts.length : 0;

    return (
      <div className={classNames(container, { open: isDebugPanelOpen })}>
        <div className={iconContainer}>
          <BuIcon name={BoostUpIcons.ContactsLinked} />
        </div>
        <div className={content}>
          <div className={classNames(row, paddingRow)}>
            <div className={row}>
              <span className={title}>Primary Email Domain:</span>
              <span className={subtitle}>{email_domain}</span>
            </div>
            <div className={row}>
              <span className={title}>
                Number of contacts Linked to account in SFDC
              </span>
              <span className={subtitle}>{`${num_contacts} Contacts`}</span>
            </div>
          </div>

          {isDebugPanelOpen && (
            <div className={classNames(column, paddingRow, marginBottom10)}>
              {additionalEmailDomains && (
                <>
                  <span className={classNames(title, marginTop15)}>
                    Additional Email Domains
                  </span>
                  <span className={subtitle}>{additionalEmailDomains}</span>
                </>
              )}
              {contactsLinkedToAccount && (
                <>
                  <div className={row}>
                    <span className={classNames(title, marginTop15)}>
                      Contacts linked to account
                    </span>
                    <span className={classNames(subtitle, marginTop15)}>
                      {`(${linkedToAccountCount} Contacts)`}
                    </span>
                  </div>
                  <span className={classNames(subtitle, lineHeight22)}>
                    {contactsLinkedToAccount}
                  </span>
                </>
              )}
              {same_domain_accounts && (
                <>
                  <span className={classNames(title, marginTop15)}>
                    Accounts that have the same email domain
                  </span>
                  <span className={classNames(subtitle)}>
                    {sameDomainAccounts}
                  </span>
                </>
              )}
            </div>
          )}

          <span
            onClick={() => {
              this.setState((prev) => ({
                isDebugPanelOpen: !prev.isDebugPanelOpen,
              }));
            }}
            className={classNames(toggle_box, {
              hidden_panel: !isDebugPanelOpen,
            })}
          >
            <BuButton className={toggle_box_btn}>
              <BuIcon
                className={classNames({
                  down: !isDebugPanelOpen,
                })}
                name={BoostUpIcons.ChevronLeft}
              />
              {`show ${isDebugPanelOpen ? 'less' : 'more'}`}
            </BuButton>
          </span>
        </div>
      </div>
    );
  }

  render() {
    const {
      account,
      status,
      remapActivity,
      isPanel,
      allowProcessEmailBody,
      crmType,
      isReadOnlyUser,
      featureFlags,
      isFullScreen,
      id,
    } = this.props;

    const lastCall = R.path(['last_call', 'call'], account);
    const lastCallSummary = R.path(['last_call', 'summary'], account);

    if (
      status === 'loading' ||
      status === 'notAsked' ||
      !account ||
      !account.id
    ) {
      return <AccountSkeleton />;
    }

    const bannerNotSynced = (
      <div className="underHeader" text-align="left">
        <p>
          Warning: The data in this account is not complete because of the
          following reasons.
        </p>
        <ol style={{ paddingLeft: '20px', marginTop: '10px' }}>
          {notNullAndEmpty(account.activity_sync_info)
            ? account.activity_sync_info.map((e) => <li>{e.text}</li>)
            : null}
        </ol>
      </div>
    );

    const showEngagementData = Boolean(
      featureFlags.deal_show_account_insights_enabled
    );

    return (
      <div className="wrapper">
        <div className="container">
          <div className="container-deal">
            <div className="deal-header">
              <div className="deal-header__top-section">
                {!isPanel && !isFullScreen && (
                  <div className="deal-header__breadcrump">
                    <button
                      type="button"
                      className={debugInfoStyles.back_btn}
                      onClick={this.handleGoBack}
                    >
                      <Icon name="arrow left" />
                      Back
                    </button>
                    <div className="deal-header__breadcrump-info">
                      Account Insights
                    </div>
                  </div>
                )}
                {notNullAndEmpty(account.activity_sync_info)
                  ? bannerNotSynced
                  : null}
                <div className="deal-header__top">
                  <div className="deal-header__top-left">
                    <p className="deal-header-title bu-font-section-title">
                      <Truncate length={35} text={account.name} />
                    </p>
                  </div>
                  {!isFullScreen && (
                    <div className="deal-header__top-right">
                      {account.salesforce_url ? (
                        <a
                          className="deal-header-link"
                          target="_blank"
                          href={account.salesforce_url}
                        >
                          Open in Salesforce
                          <Icon name="external alternate" />
                        </a>
                      ) : (
                        <span className="deal-header-link-empty">
                          Account not found in Salesforce
                        </span>
                      )}

                      {!isReadOnlyUser && (
                        <BuButton
                          size={BuControlSize.SMALL}
                          onClick={() =>
                            remapActivity({ account_id: account.id })
                          }
                        >
                          Remap all activity
                        </BuButton>
                      )}

                      {isPanel && (
                        <Link
                          key={account.id}
                          url={{
                            scheme: '/accounts/preview/:id',
                            params: { id: account.id },
                          }}
                        >
                          <img
                            className={styles.fullScreenIcon}
                            src={require('../../assets/images/new_icon/full_screen_icon.svg')}
                            alt="full screen"
                          />
                        </Link>
                      )}
                    </div>
                  )}
                </div>

                {this.renderDebugPanel()}
              </div>

              {showEngagementData && (
                <div className="deal-header-info-meeting">
                  <div className="deal-header-info-meeting-item">
                    <span className="deal-header-info-meeting-item-title">
                      AE:
                    </span>
                    {account.account_executive || 'Not Available'}
                  </div>
                  <div className="deal-header-info-meeting-item">
                    <span className="deal-header-info-meeting-item-title">
                      Next Meeting:
                    </span>
                    {account.next_meeting
                      ? moment(account.next_meeting.start, 'YYYY-MM-DD').format(
                          'MMM D, YYYY'
                        )
                      : 'Not scheduled'}
                  </div>

                  {lastCall && lastCall?.no_show && (
                    <div className="deal-header-info-meeting-item">
                      <span className="deal-header-info-meeting-item-title">
                        Last Meeting:
                      </span>
                      No Show
                    </div>
                  )}

                  {lastCall && !lastCall?.no_show && (
                    <Link
                      url={{
                        scheme: '/transcript/:id',
                        params: { id: lastCall },
                      }}
                    >
                      <div className="deal-header-info-meeting-item">
                        <span className="deal-header-info-meeting-item-title">
                          Last Meeting:
                        </span>
                        {lastCallSummary}
                        <img
                          style={{ marginLeft: '10px' }}
                          src={PlayIcon}
                          alt="icon"
                        />
                      </div>
                    </Link>
                  )}

                  {account.last_activity && (
                    <div className="deal-header-info-meeting-item">
                      <span className="deal-header-info-meeting-item-title">
                        Last Prospect Engagement:
                      </span>
                      {moment(account.last_activity.occurred_at).fromNow()}
                    </div>
                  )}

                  <RiskScoreBlock
                    name="Account Risk Score"
                    riskFactors={account.risk_factors}
                    positiveIndicators={account.positive_indicators}
                    score={account.score}
                    activitySyncInfo={account.activity_sync_info}
                    isProgression={false}
                  />

                  {account.create_date && (
                    <div className="deal-header-info-meeting-item">
                      <span className="deal-header-info-meeting-item-title">
                        Create Date:{' '}
                      </span>
                      {moment(account.create_date, 'YYYY-MM-DD').format(
                        ' Do MMM YY'
                      )}
                    </div>
                  )}
                </div>
              )}

              {showEngagementData && this.renderProspectEngagement()}

              {Boolean(showEngagementData && allowProcessEmailBody) && (
                <MomentsTabs
                  id={account.id}
                  type="account"
                  timespan={this.props.defaultTimeFilter}
                />
              )}

              {crmType !== 'no-crm' && (
                <DealsAccount
                  accountId={this.props.account.id}
                  openDealsCount={this.props.openDealsCount}
                  closedDealsCount={this.props.closedDealsCount}
                  noResave={this.props.noResave}
                />
              )}

              {showEngagementData && this.renderCustomerTeamEngagement()}

              {Boolean(showEngagementData && allowProcessEmailBody) &&
                this.renderTopics()}

              {showEngagementData && this.renderMeetingsAndCalls()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  crmType: getCrmType(state),
  remapActivityStatus: state.activity.remapActivity,
  openDealsCount: getDealsCount(state, 'Open'),
  closedDealsCount: getDealsCount(state, 'Closed'),
  allowProcessEmailBody: getAllowProcessEmailBody(state),
  isReadOnlyUser: isReadOnlyUser(state),
  featureFlags: getFeatureFlags(state),
});

const dispatchToProps = {
  remapActivity: activityRemapRequest,
};

export default withRouter(connect(mapStateToProps, dispatchToProps)(Account));
