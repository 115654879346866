import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast, ToastOptions } from 'react-toastify';
import { Checkbox, Dimmer, Loader } from 'semantic-ui-react';

import BuButtonRefresh from 'components/UI/BuButtonRefresh';
import BuCheckbox from 'components/UI/BuCheckbox';
import ScrollableChart from 'components/UI/BuScrollableChart';
import Scrolling from 'components/UI/common/Scrolling/Scrolling';
import TypedTable, {
  BorderType,
} from 'components/UI/common/TypedTable/TypedTable';
import Chart from 'components/dashboard/ForecastDashboard/TrackingDashboard/Chart';
import { DropdownCustom } from 'components/dashboard/ForecastDashboard/TrackingDashboard/DropdownCustom';
import SeriesLabel from 'components/dashboard/ForecastDashboard/TrackingDashboard/SeriesLabel';
import { StyleResolver } from 'components/dashboard/ForecastDashboard/TrackingDashboard/StyleResolver';
import {
  apiPoint,
  defaultCheckboxOptions,
  optionsOrder,
  SERIES_CONFIG,
  TRACKING_DASHBOARD_CLOSE_DATE_OPTIONS,
  TRACKING_DASHBOARD_FREQUENCY_OPTIONS,
  TRACKING_DASHBOARD_PACE_INTERVAL_OPTIONS,
} from 'components/dashboard/ForecastDashboard/TrackingDashboard/constants';
import {
  getColumns,
  getLabelIcon,
  getRows,
  MAIN_COLUMN_WIDTH,
  PERIOD_COLUMN_WIDTH,
  setTableFluid,
} from 'components/dashboard/ForecastDashboard/TrackingDashboard/helpers';
import * as styles from 'components/dashboard/ForecastDashboard/TrackingDashboard/styles';
import {
  ApiResponse,
  IProps,
  OptionsKeys,
  Series,
  SeriesLine,
  SeriesVisibility,
  Stats,
  TitlesOptions,
} from 'components/dashboard/ForecastDashboard/TrackingDashboard/types';
import { useBoundary } from 'components/hooks/useBoundary';
import { useLocalStorage } from 'components/hooks/useLocalStorage';
import * as selectors from 'selectors';
import { getUser } from 'selectors';
import { fetchApi, QueryStatus } from 'utils/network';

const toastOptions: ToastOptions = { position: 'bottom-left' };

const CategoryCheckboxPanel: React.FC<{
  options: TitlesOptions[];
  series: Series[];
  onChange: (s: Series) => void;
}> = ({ options, series, onChange }) => {
  return (
    <div className={styles.moreGraphicsOptionsDropdown}>
      {options?.map((option) => (
        <div key={option.label}>
          <h4>{option.label}</h4>
          <ul className={styles.listContainer}>
            {series.map((s) => {
              return (
                s.forecasttype &&
                defaultCheckboxOptions[s.forecasttype as OptionsKeys].value ===
                  option.value && (
                  <li key={s.field} className={styles.listItem}>
                    <BuCheckbox
                      checked={s.visible}
                      onChange={() => onChange(s)}
                      elementLabel={
                        <label>
                          <SeriesLabel color={s.color} icon={getLabelIcon(s)}>
                            {s.name}
                          </SeriesLabel>
                        </label>
                      }
                    />
                  </li>
                )
              );
            })}
          </ul>
        </div>
      ))}
    </div>
  );
};

const useChartWidth = (
  container: React.RefObject<HTMLDivElement>,
  periods?: number
) => {
  const [availableWidth, setAvailableWidth] = useState(0);

  useEffect(() => {
    const handleResize = () =>
      setAvailableWidth(container.current?.clientWidth || 0);
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [container]);

  if (
    container.current &&
    container.current.clientWidth >= container.current.scrollWidth
  ) {
    const columnWidth =
      document.getElementById('metric')?.offsetWidth || MAIN_COLUMN_WIDTH;

    return {
      columnWidth,
      total: availableWidth,
      column: (availableWidth - columnWidth) / (periods || 1),
    };
  }

  return {
    columnWidth: MAIN_COLUMN_WIDTH,
    total: MAIN_COLUMN_WIDTH + (periods ?? 0) * PERIOD_COLUMN_WIDTH,
    column: PERIOD_COLUMN_WIDTH,
  };
};

const TrackingDashboard: React.FC<IProps> = ({
  filters,
  selectedBusinessType,
  position,
  setForecastDashboardFilter,
}) => {
  const isFirstLoad = useBoundary(position);
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);
  const { email: userEmail } = useSelector(getUser);
  const [stat, setStat] = useState<Stats>();
  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');
  const [persitedSeriesVisibility, setPersistedSeriesVisibility] =
    useLocalStorage<SeriesVisibility[]>(`TrackingDashboard.${userEmail}`, []);
  const [cacheDate, setCacheDate] = useState('');
  const [refreshCache, setRefreshCache] = useState(false);
  const usedParams = useRef<string>('');
  const scrollRef = useRef<HTMLDivElement>(null);
  const [showMoreGraphicsOptions, setShowMoreGraphicsOptions] = useState(false);

  const width = useChartWidth(scrollRef, stat?.periods.length);

  const isLoading = ['notAsked', 'loading'].includes(dataStatus);

  const serializedQueryParams = useMemo(
    () =>
      JSON.stringify({
        close_date_interval: filters.closeDateInterval,
        pace_freq: filters.frequency,
        pace_interval: filters.paceInterval,
        managers: filters.sales_managers,
        business_type_name: selectedBusinessType,
        opportunity_types: filters.opportunity_types,
        opportunity_stages: filters.opportunity_stages,
        custom_filters: filters.custom_filters,
      }),
    [JSON.stringify(filters), selectedBusinessType]
  );

  useEffect(() => {
    let abortController: AbortController | null = null;

    if (
      !isFirstLoad &&
      (refreshCache || usedParams.current !== serializedQueryParams)
    ) {
      abortController = new AbortController();
      usedParams.current = serializedQueryParams;

      fetchApi<string, ApiResponse>({
        queryParams: serializedQueryParams,
        setData: (response) => {
          setStat(response);
        },
        setError: (error: string | null) =>
          toast.error(`Fetching data failed: ${error}`, toastOptions),
        setStatus: setDataStatus,
        setHeaders: (headers) => {
          setCacheDate(headers.get('cache-created') || '');
          setRefreshCache(false);
        },
        signal: abortController.signal,
        url: refreshCache ? `${apiPoint}?cache_refresh=1` : apiPoint,
      });
    }

    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [isFirstLoad, serializedQueryParams, refreshCache]);

  const disableScrolling = useMemo(() => {
    const { frequency, paceInterval } = filters as unknown as {
      frequency: string;
      paceInterval: string;
    };
    return (
      frequency === 'month' &&
      (paceInterval === 'LQU' || paceInterval === 'TQU')
    );
  }, [filters.frequency, filters.paceInterval]);

  const forecastFields = useMemo(() => {
    const styleResolver = new StyleResolver();
    return (
      stat?.data
        .filter((item) => item.forecast)
        .map<SeriesLine>((item) => ({
          color: styleResolver.getColor(item),
          name: item.display_name,
          field: item.name,
          forecasttype: item.forecasttype,
          type: 'line',
          dashStyle: styleResolver.getDashStyle(item.forecasttype),
          visible: true,
          filterable: true,
        })) || []
    );
  }, [stat?.data]);

  const baseFields = useMemo<Series[]>(() => {
    const styleResolver = new StyleResolver();
    return (
      stat?.data
        .filter((item) => !item.forecast)
        .map<Series>((item) =>
          SERIES_CONFIG[item.name]
            ? {
                ...(SERIES_CONFIG[item.name] as Series),
                name: item.display_name,
                field: item.name,
                forecasttype: item.forecasttype,
                visible: true,
                filterable: true,
              }
            : {
                color: styleResolver.getColor(item),
                name: item.display_name,
                field: item.name,
                forecasttype: item.forecasttype,
                visible: true,
                filterable: true,
                type: item.forecast_category ? 'column' : 'line',
                dashStyle: styleResolver.getDashStyle(item.forecasttype),
              }
        ) || []
    );
  }, [forecastFields, stat]);

  // This effect will persist series that are yet not persisted in local storage
  useEffect(() => {
    const series = [...baseFields, ...forecastFields];
    setPersistedSeriesVisibility((persistedSeries) => {
      const newToPersist = series
        .filter((s) => !persistedSeries.find((p) => p.field === s.field))
        .map((s) => ({ field: s.field, visible: s.visible }));

      return [...persistedSeries, ...newToPersist];
    });
  }, [baseFields, forecastFields]);

  // this will return series configured for this chart
  // with the sate of visibility from local storage
  const series = useMemo(
    () =>
      [...baseFields, ...forecastFields].map((s) => {
        const persisted = persitedSeriesVisibility.find(
          (p) => p.field === s.field
        );
        return persisted ? { ...s, visible: persisted.visible } : s;
      }),
    [baseFields, forecastFields, persitedSeriesVisibility]
  );

  const statSerialized = JSON.stringify(stat);

  const columns = useMemo(
    () => setTableFluid(getColumns(stat), disableScrolling),
    [statSerialized, disableScrolling]
  );

  const rows = useMemo(
    () =>
      getRows(
        companyCurrencyCode,
        stat,
        series.filter((item) => item.visible)
      ),
    [statSerialized, stat, series]
  );

  const handleCheckColumn = (option: Series): void => {
    setPersistedSeriesVisibility((seriesVisibility) =>
      seriesVisibility.map((item) =>
        item.field === option.field ? { ...item, visible: !item.visible } : item
      )
    );
  };

  const scrollToCurrentPeriod = () => {
    if (stat) {
      const currentWeekIndex =
        (stat.data
          .find((item) => item.name === 'booked')
          ?.values?.findIndex((item) => item === null) ?? 0) - 1;

      if (scrollRef.current) {
        const scrollSpace =
          scrollRef.current.scrollWidth - scrollRef.current.offsetWidth;

        if (currentWeekIndex >= 0 && stat.periods.length) {
          const viewPortSize = scrollRef.current.offsetWidth;
          const chartWidth =
            stat.periods.length * PERIOD_COLUMN_WIDTH + MAIN_COLUMN_WIDTH;
          const currentPeriodPos =
            currentWeekIndex * PERIOD_COLUMN_WIDTH + MAIN_COLUMN_WIDTH;
          const bestPos = viewPortSize / 2;
          const chartOffset = Math.max(currentPeriodPos - bestPos, 0);
          const rest = Math.max(
            viewPortSize - bestPos - chartWidth + currentPeriodPos,
            0
          );
          const scrollPercent =
            (chartOffset + rest) / Math.max(chartWidth - viewPortSize, 1);

          scrollRef.current.scrollLeft = scrollPercent * scrollSpace;
        } else {
          scrollRef.current.scrollLeft = scrollSpace;
        }
      }
    }
  };

  useMemo(() => {
    scrollToCurrentPeriod();
  }, [stat, scrollRef.current?.scrollWidth]);

  const totalSelected = useMemo(
    () => series.reduce((acc, curr) => acc + (curr.visible ? 1 : 0), 0),
    [series]
  );

  const options = useMemo(() => {
    let forecastTypes: OptionsKeys[] = [];
    stat?.data.forEach((i) => {
      if (i.forecasttype && !forecastTypes.includes(i.forecasttype)) {
        forecastTypes.push(i.forecasttype);
      }
    });

    const newTitlesOptions = forecastTypes.map((item) => {
      const newKey = item as OptionsKeys;
      return {
        value: defaultCheckboxOptions[newKey]?.value,
        label: defaultCheckboxOptions[newKey]?.label,
      };
    });

    let orderedTitlesOptions = newTitlesOptions as TitlesOptions[];

    orderedTitlesOptions.sort((a, b) => {
      return optionsOrder.indexOf(a.value) - optionsOrder.indexOf(b.value);
    });

    return orderedTitlesOptions;
  }, [stat]);

  return (
    <Dimmer.Dimmable dimmed={isLoading}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          How are we tracking towards our goal?
          <BuButtonRefresh
            status={refreshCache}
            cacheDate={cacheDate}
            onClick={() => setRefreshCache(true)}
          />
          <div className={styles.filters}>
            <DropdownCustom
              options={TRACKING_DASHBOARD_CLOSE_DATE_OPTIONS}
              value={filters.closeDateInterval}
              label="Closing in"
              onClick={setForecastDashboardFilter}
              interval="closeDateInterval"
            />

            <DropdownCustom
              options={TRACKING_DASHBOARD_PACE_INTERVAL_OPTIONS}
              value={filters.paceInterval}
              label="Time period"
              onClick={setForecastDashboardFilter}
              interval="paceInterval"
            />

            <DropdownCustom
              options={TRACKING_DASHBOARD_FREQUENCY_OPTIONS}
              value={filters.frequency}
              label="Time interval"
              onClick={setForecastDashboardFilter}
              interval="frequency"
            />
          </div>
        </div>

        <div>
          <div className={styles.columnsContainer}>
            <div className={styles.seriesContainer}>
              {series
                .filter((item) => item.filterable)
                .map((item) => (
                  <Checkbox
                    key={item.field}
                    onClick={() => handleCheckColumn(item)}
                    className={styles.checkboxContainer}
                    label={
                      <label>
                        <SeriesLabel
                          color={item.color}
                          icon={getLabelIcon(item)}
                        >
                          {item.name}
                        </SeriesLabel>
                      </label>
                    }
                    checked={item.visible}
                  />
                ))}
            </div>
            <button
              className={styles.moreGraphicsOptions}
              onClick={() =>
                setShowMoreGraphicsOptions(!showMoreGraphicsOptions)
              }
            >
              {`... More (${totalSelected ?? 0}/${series?.length})`}
            </button>
          </div>

          {showMoreGraphicsOptions ? (
            <CategoryCheckboxPanel
              options={options}
              series={series}
              onChange={handleCheckColumn}
            />
          ) : null}

          <Scrolling
            shadowLeftOffset={MAIN_COLUMN_WIDTH}
            width
            shadows
            innerScrollRef={scrollRef}
          >
            <ScrollableChart
              YAxisWidth={width.columnWidth}
              width={width.total}
              disable={disableScrolling}
            >
              <Chart
                data={stat}
                series={series}
                yAxisWidth={width.columnWidth}
                pointWidth={width.column}
                fluid={disableScrolling}
              />
            </ScrollableChart>

            <TypedTable.Border
              className={styles.typedTableBorder}
              borders={BorderType.TOP}
              width="fit-content"
            >
              <TypedTable
                columns={columns}
                data={rows}
                fixColumns={!disableScrolling}
                stickyColumn
                stickyHeader
                width="min-content"
                minWidth="100% + 5px"
                rowClassName={(row) => (row.name === 'target' ? 'hide' : '')}
              />
            </TypedTable.Border>
          </Scrolling>
        </div>
      </div>

      <Dimmer active={isLoading} inverted>
        <Loader />
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

export default TrackingDashboard;
