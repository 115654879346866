import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import * as metricActions from 'actions/revbi/metrics';
import { CommonList } from 'components/dashboard/Metrics/List/Common/CommonList';
import { SelectedItem } from 'components/dashboard/Metrics/List/types';
import { BIMetrics } from 'components/dashboard/Metrics/metrics.types';
import * as metricSelectors from 'selectors/revbi/metrics';
import { fetchApi, QueryStatus } from 'utils/network';

export const MetricsList: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const allMetricList = useSelector(metricSelectors.getAllMetricsWithTS);
  const status = useSelector(metricSelectors.getAllMetricsWithTSStatus);
  const [cloneStatus, setCloneStatus] = useState<QueryStatus>('notAsked');

  const [selectedMetric, setSelectedMetric] = useState<SelectedItem>();

  const handleDeleteConfirm = (
    affectedEntitiesIds: string[] | undefined
  ): void => {
    fetchApi<void, any>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${selectedMetric?.id}`,
      queryMethod: 'delete',
      setData: () => {
        dispatch(
          metricActions.removeMetric({
            ids: [
              ...(selectedMetric?.id ? [selectedMetric?.id] : []),
              ...(Array.isArray(affectedEntitiesIds)
                ? affectedEntitiesIds
                : []),
            ],
          })
        );
        toast.success(`Metric ${selectedMetric?.name} has been deleted`);
      },
      setError: (error: string | null) => {
        console.error(
          `Failed to delete metric "${selectedMetric?.name}": ${error}`
        );
        toast.error(`Failed to delete metric "${selectedMetric?.name}"`);
      },
      setStatus: () => {},
    });
  };

  const handleClone = (id: string, name: string): void => {
    if (cloneStatus !== 'loading') {
      fetchApi<void, BIMetrics>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${id}/clone`,
        queryMethod: 'get',
        setData: (result) => {
          dispatch(metricActions.fetchAllMetrics());
          dispatch(metricActions.fetchAllTSMetrics());
          history.push(`/revbi/metrics/edit/${result._id}`);
          toast.success(`Metric "${name}" has been cloned`);
        },
        setError: (error: string | null) => {
          toast.error(`Failed to clone metric "${name}": ${error}`);
        },
        setStatus: setCloneStatus,
      });
    }
  };

  return (
    <CommonList
      isMetrics
      list={allMetricList}
      status={status as QueryStatus}
      selectedElement={selectedMetric}
      onDeleteConfirm={handleDeleteConfirm}
      onClone={handleClone}
      setSelectedElement={setSelectedMetric}
    />
  );
};
