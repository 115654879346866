import { ForecastSubmissionSettingItemReduxState } from 'common/types/forecastSubmission';
import { TableConfigurationColumn } from 'components/UI/TableConfig/types';

export {
  processTableData,
  isEmptyValue,
  getPipelineGapClassName,
} from 'components/dashboard/ForecastRollUps/helpers/processTableData';

export { calculatePipelinesTree } from 'components/dashboard/ForecastRollUps/helpers/calculatePipelinesTree';

export { findCurrentUserRow } from 'components/dashboard/ForecastRollUps/helpers/findCurrentUserRow';

export { sortAndAggTree } from 'components/dashboard/ForecastRollUps/helpers/sortAndAggTree';

export {
  getCSVHeaders,
  getCSVData,
  buildFlatList,
} from 'components/dashboard/ForecastRollUps/helpers/csvFunctions';

export const getSubmissionWithColumn = (
  columns: TableConfigurationColumn[],
  submissionSettings: ForecastSubmissionSettingItemReduxState[],
  selectedBusinessType: string
) => {
  const submissionWithColumn: (ForecastSubmissionSettingItemReduxState &
    TableConfigurationColumn)[] = [];
  const submissionColumns = columns.filter(
    (column) => column.meta.forecast_settings_id
  );
  submissionColumns.forEach((column) => {
    const forecastSettingsIds = column.meta.forecast_settings_id || {};
    const forecastSettingsId =
      forecastSettingsIds[selectedBusinessType] || forecastSettingsIds.default;
    const settingsItem = submissionSettings.find(
      (submission) => submission.id === forecastSettingsId
    );

    if (settingsItem) {
      submissionWithColumn.push({
        ...settingsItem,
        ...column,
      });
    }
  });

  return submissionWithColumn;
};

export const getColumnByFieldName = (
  fieldName: string,
  columns: TableConfigurationColumn[]
): TableConfigurationColumn | undefined =>
  columns.find((c) => c.field_name === fieldName);
