import { select, put, all, takeLatest } from 'redux-saga/effects';

import { transcriptTypes } from 'actions/actionTypes';
import { createTranscriptAction } from 'actions/transcriptActions';
import { notNullAndEmpty, filterSnippets } from 'common/utils';
import * as service from 'components/UI/AudioPlayer/service';
import * as selectors from 'selectors';

function* doTranscriptSearch() {
  const searchText = yield select(selectors.getSearchText);
  const snippets = yield select(selectors.getCallSnippets);

  if (searchText && snippets) {
    const filteredSnippets = filterSnippets(snippets, '', '', searchText);
    const selectedSnippet = notNullAndEmpty(filteredSnippets)
      ? filteredSnippets[0]
      : null;
    const selectedSnippetIndex = notNullAndEmpty(filteredSnippets) ? 0 : null;
    // set searchIndex = -1 to handle case where no results are found
    const searchIndex = notNullAndEmpty(filteredSnippets) ? 0 : -1;

    yield put(createTranscriptAction.setFilteredSnippets(filteredSnippets));
    yield put(
      createTranscriptAction.setSelectedSnippet(
        selectedSnippet,
        selectedSnippetIndex
      )
    );
    yield put(createTranscriptAction.resetActiveTopic());
    yield put(createTranscriptAction.setSearchIndex(searchIndex));

    if (selectedSnippet) {
      service.setCurrentTime(selectedSnippet.start_time);
    }
  }
}

export default function* callTranscriptSaga() {
  yield all([takeLatest(transcriptTypes.DO_SEARCH, doTranscriptSearch)]);
}
