// src/components/dashboard/CROOverride/SubmissionForecast/styles.ts
import styled from '@emotion/styled';
import { css } from 'emotion';

export const modalHeader = css`
  padding-bottom: 0 !important;
  padding-right: 0 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
`;

export const Title = styled.h3`
  display: inline-block;
`;

export const AutoIncludeInfoIcon = styled.div`
  display: inline-block;
  margin-left: 8px;

  img {
    margin-top: -3px;
  }
`;
