import React, { useEffect, useState } from 'react';

import BuInput from 'components/UI/BuInput';
import BuSelect from 'components/UI/BuSelect';
import { ISelectOption } from 'components/UI/BuSelect/types';
import * as s from 'components/dashboard/Metrics/common/Calendar/TimeFilter/styles';

export const CUSTOM_TIMING_OPERATOR_OPTIONS: ISelectOption[] = [
  { text: 'Last', value: 'last' },
  { text: 'Next', value: 'next' },
];

export const CUSTOM_TIMING_PERIOD_OPTIONS: ISelectOption[] = [
  { text: 'Days', value: 'days' },
  { text: 'Weeks', value: 'weeks' },
  { text: 'Months', value: 'months' },
  { text: 'Quarters', value: 'quarters' },
  { text: 'Years', value: 'years' },
];

type Props = {
  value: string;
  onChange: (value: string) => void;
};

const CustomPeriodTab: React.FC<Props> = ({ value, onChange }) => {
  const [operator, setOperator] = useState<string>(
    CUSTOM_TIMING_OPERATOR_OPTIONS[0].value
  );
  const [period, setPeriod] = useState<string>('1');
  const [timing, setTiming] = useState<string>(
    CUSTOM_TIMING_PERIOD_OPTIONS[0].value
  );

  useEffect(() => {
    if (!!value && value.length > 0) {
      const split = value.split('_');
      if (split.length === 3 && parseInt(split[1])) {
        setOperator(split[0]);
        setPeriod(split[1]);
        setTiming(split[2]);
      }
    }
  }, []);

  return (
    <div className={s.custom_period_tab}>
      <BuSelect
        className={s.custom_period_input}
        options={CUSTOM_TIMING_OPERATOR_OPTIONS}
        onChange={(values: string[]) => {
          setOperator(values[0]);
          onChange(`${values[0]}_${period}_${timing}`);
        }}
        secondary
        placeholder={''}
        defaults={[operator ?? CUSTOM_TIMING_OPERATOR_OPTIONS[0].value]}
        fullWidth
        isLargePlaceholder
      />
      <BuInput
        type={'number'}
        className={s.custom_period_input}
        value={period}
        onChange={(event) => {
          const value =
            !event.currentTarget.value ||
            parseInt(event.currentTarget.value) < 1 ||
            parseInt(event.currentTarget.value) > 32 ||
            event.currentTarget.value === '-'
              ? '1'
              : event.currentTarget.value;
          setPeriod(value);
          onChange(`${operator}_${value}_${timing}`);
        }}
      />
      <BuSelect
        className={s.custom_period_input}
        options={CUSTOM_TIMING_PERIOD_OPTIONS}
        onChange={(values: string[]) => {
          setTiming(values[0]);
          onChange(`${operator}_${period}_${values[0]}`);
        }}
        secondary
        placeholder={''}
        defaults={[timing ?? CUSTOM_TIMING_PERIOD_OPTIONS[0].value]}
        fullWidth
        isLargePlaceholder
      />
    </div>
  );
};

export default CustomPeriodTab;
