import { CUMULATIVE_SUM_OPTIONS } from './constants';
import {
  advancedOptionsContainer,
  infoParagraph,
  optionContainer,
} from './styles';
import React from 'react';
import { useDispatch } from 'react-redux';

import { changeActiveMetric } from 'actions/revbi/metrics';
import BuSelect from 'components/UI/BuSelect';
import BuToggle from 'components/UI/BuToggle';
import { MONTH, MONTHLY } from 'components/dashboard/Metrics/constants';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import { BIMetricUnion } from 'components/dashboard/Metrics/metrics.types';

interface Props {
  metric: BIMetricUnion;
}

export const AdvancedOptions: React.FC<Props> = ({ metric }) => {
  const dispatch = useDispatch();

  const updateMetric = (metric: BIMetricUnion): void => {
    dispatch(changeActiveMetric(metric));
  };

  const handleChangeEnableCumulativeSum = (): void => {
    const isEnabled = !Boolean(metric.is_cumulative_sum);
    const hasCumulativePeriod = Boolean(metric.cumulative_sum_period);
    updateMetric({
      ...metric,
      is_cumulative_sum: isEnabled,
      ...(isEnabled
        ? {
            cumulative_sum_period: hasCumulativePeriod
              ? metric.cumulative_sum_period
              : MONTH,
          }
        : { cumulative_sum_period: undefined }),
    });
  };

  const handleChangeCumulative = (values: string[]): void => {
    updateMetric({
      ...metric,
      cumulative_sum_period: values[0],
    });
  };

  const handleChangeHierarchyRollupSum = (): void => {
    const isEnabled = !Boolean(metric.is_hierarchy_rollup_sum);
    updateMetric({
      ...metric,
      is_hierarchy_rollup_sum: isEnabled,
    });
  };

  return (
    <div
      className={advancedOptionsContainer}
      data-testing="advanced-options-container"
    >
      <MetricCreateSubTitle>Advanced options</MetricCreateSubTitle>
      <div className={optionContainer}>
        <BuToggle
          checked={Boolean(metric.is_cumulative_sum)}
          onChange={handleChangeEnableCumulativeSum}
          testingLabel="cumulative-sum-over-time"
        >
          Cumulative sum over time
        </BuToggle>
        <p className={infoParagraph}>
          Shows the metric accumulating over periods of time of your choice when
          pivoted by a date field
        </p>
        {metric.is_cumulative_sum && (
          <BuSelect
            fullWidth
            secondary
            defaults={[metric.cumulative_sum_period ?? MONTHLY]}
            options={CUMULATIVE_SUM_OPTIONS}
            testingLabel="cumulative-sum-selector"
            onChange={handleChangeCumulative}
          />
        )}
      </div>
      <div className={optionContainer}>
        <BuToggle
          checked={Boolean(metric.is_hierarchy_rollup_sum)}
          onChange={handleChangeHierarchyRollupSum}
          testingLabel="manager-level-aggregation"
        >
          Manager level aggregation
        </BuToggle>
        <p className={infoParagraph}>
          Aggregates the metric over the pre-defined manager-user hierarchy by
          rolling up the user metric data to their manager. Only applies when
          pivoting by user.
        </p>
      </div>
    </div>
  );
};
