import React, { useState } from 'react';

import { WidgetHeader } from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { BaseReportWidget } from 'components/dashboard/Metrics/Widget/Report/BaseReportWidget';
import { TemplateFiltersPreview } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFiltersPreview';
import { WidgetContainer } from 'components/dashboard/Metrics/Widget/widgets.styles';
import {
  BIColumnListItem,
  BIMetrics,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  widget: BIWidget;
  metric?: BIMetrics;
  updateWidget: (widget: Partial<BIWidget>) => void;
}

export const ReportWidgetPreview: React.FC<Props> = ({
  widget,
  metric,
  updateWidget,
}) => {
  const [tooltipContent, setTooltipContent] =
    useState<JSX.Element | undefined>(undefined);

  const handleUpdateOrderBy = (column: BIColumnListItem) => {
    updateWidget({
      ...widget,
      order_by_column: column,
    });
  };

  return (
    <>
      <WidgetContainer
        key={widget._id}
        isDashboard={false}
        isMetricsPreview={false}
        isModal={false}
      >
        <WidgetHeader
          isReportViewWidget
          id={widget._id}
          name={widget.name}
          tooltip={tooltipContent}
        />

        <TemplateFiltersPreview
          templateFilters={widget.template_filters ?? []}
          widgetFilters={widget.widget_filters ?? []}
          widget={widget}
          updateWidget={updateWidget}
        />

        <BaseReportWidget
          widget={widget}
          metric={metric}
          setTooltipContent={setTooltipContent}
          updateOrderBy={handleUpdateOrderBy}
        />
      </WidgetContainer>
    </>
  );
};
