import { css } from 'emotion';

export const modal_body = css`
  position: relative;
  padding: 15px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const btn_box = css`
  display: flex;
  justify-content: flex-end;
`;

export const btn_copy = css`
  width: 150px;
  padding: 5px 11px;
  color: #4a4a4a;
  font-family: var(--bu-font-regular);
  font-size: 12px;
  text-align: center;
  background-color: #b5ffc5;
  border-radius: 2px;
`;

export const btnGroup = css`
  display: flex;
  margin: 10px 0 10px;
`;

export const btnGroup_btn = css`
  font-family: var(--bu-font-regular);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--bu-control-height-regular);
  min-width: 80px;
  border-color: var(--bu-gray-500);
  border-width: 1px;
  border-style: solid;
  text-align: center;
  letter-spacing: 0px;
  color: var(--bu-gray-900);
  padding: 7px 14px 8px 14px;
  margin: 0px -0.5px;
  cursor: pointer;

  &.active {
    background: #f1fbfd 0% 0% no-repeat padding-box;
    color: var(--bu-primary-500);
    border-color: var(--bu-primary-500);
    margin: 0 -1px;
    z-index: 1;
  }

  &:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  &:last-child {
    border-radius: 0px 4px 4px 0px;
  }
`;
