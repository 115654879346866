import { AnalysisType, UnitType } from 'components/dashboard/Metrics/constants';
import {
  MetricType,
  BIMetricSimpleNewborn,
  AggregationFunction,
  BIMetricFormulaNewborn,
} from 'components/dashboard/Metrics/metrics.types';

export const SIMPLE_METRIC_NEWBORN_MOCK: BIMetricSimpleNewborn = {
  aggregation_function: AggregationFunction.Sum,
  analysis_type: AnalysisType.LIVE,
  filters: [],
  name: '',
  object: 'opportunity',
  metadata: {
    type: MetricType.Simple,
  },
};

export const SIMPLE_HISTORICAL_METRIC_NEWBORN_MOCK: BIMetricSimpleNewborn = {
  aggregation_function: AggregationFunction.Sum,
  analysis_type: AnalysisType.HISTORICAL,
  filters: [],
  name: '',
  object: 'opportunity',
  metadata: {
    type: MetricType.Simple,
  },
};

export const FORMULA_METRIC_NEWBORN_MOCK: BIMetricFormulaNewborn = {
  filters: [],
  analysis_type: AnalysisType.LIVE,
  name: '',
  metadata: {
    type: MetricType.Formula,
  },
  properties: {
    metricUnit: {
      unit: '',
      type: UnitType.Prefix,
    },
  },
  object: 'opportunity',
  synthetic_metric: '',
};

export const CREATE_METRIC_API = `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics`;

export const METRIC_REDUCER_INITIAL_STATE = {
  simpleMetricNewborn: SIMPLE_METRIC_NEWBORN_MOCK,
  formulaMetricNewborn: FORMULA_METRIC_NEWBORN_MOCK,
  simpleMetric: undefined,
  formulaMetric: undefined,
};
