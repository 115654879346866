import classNames from 'classnames';
import { css } from 'emotion';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { actions } from 'actions';
import { setSelectedLabel } from 'actions/opportunities';
import InfoIcon from 'assets/fonts/boostup-icons/badge_info_outline.svg';
import {
  CHANGE_INTERVAL_OPTIONS,
  FILTER_NAME,
  TABLE_NAME,
} from 'common/constants';
import DealsTable from 'components/UI/DealsFlatTableConfig/DealsTable.container';
import { DealsRequestState } from 'components/UI/DealsFlatTableConfig/types';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import Badge from 'components/dashboard/Forecast/Dashboard/Badge';
import { formatLabel } from 'components/dashboard/ForecastAnalytics/ForecastAnalytics';
import { useLocalStorage } from 'components/hooks/useLocalStorage';
import { IProps } from 'components/modals/DealsModal/types';
import {
  getFiltersForModal,
  haveFiltersConfigurated,
} from 'reducers/filters/helpers';
import { getIsFiltersOnModalsEnabled, getUser } from 'selectors';
import { history } from 'store/configureStore';

const badgeContainer = css`
  margin-left: 10px;

  .amount-badge {
    margin-top: -2px;
  }
`;

const headerInfoIcon = css`
  display: inline-block;
  margin-left: 8px;

  img {
    width: 16px;
  }
`;

const timeIntervalContainer = css`
  color: #666666;
  margin: -2px 0 0 10px;
  font-size: 12px;
  line-height: 14px;
  font-family: var(--bu-font-regular);
`;

const tableWrapper = css`
  margin-left: -20px;
  margin-right: -20px;
  height: 80vh;
`;

const includedDealsTableWrapper = css`
  margin-left: -20px;
  margin-right: -20px;
`;

export const filtersContainer = css`
  margin-left: 20px;
  margin-right: 20px;
`;

const FORECAST_SERIES_WITH_BADGE = [
  'new_deals',
  'pulled',
  'plus_value',
  'pushed',
  'minus_value',
  'won',
  'lost',
  'current_forecast',
];

const INFO_ICON_TOOLTIP_TEXTS: { [key: string]: string } = {
  A: 'These deals matched the current filtering criteria at the starting date AND had open Stage and Forecast Category values. Data displayed corresponds to data at the starting date (snapshot).',
  NEW: 'These deals match the current filtering criteria but were created after the starting date selected. Current (live) data is displayed.',
  PULLED:
    'These deals did not initially match your filtering criteria by the starting date but now do. Current (live) data is displayed.',
  '+VALUE':
    'These deals have had their amount value increased since the starting date. Current (live) data is displayed.',
  PUSHED:
    'These deals initially matched your filtering criteria by the starting date but no longer do. Current (live) data is displayed.',
  '-VALUE':
    'These deals have had their amount value decreased since the starting date. Current (live) data is displayed.',
  WON: 'These deals were closed-won (deal stage) during the selected time period. Current (live) data is displayed.',
  LOST: 'These deals were closed-lost (deal stage) during the selected time period. Current (live) data is displayed.',
  B: 'These deals match the current filtering criteria date AND have open Stage and Forecast Category values. Current (live) data is displayed.',
};

export const modalFiltersContainer = css`
  height: 50px;
  background-color: var(--bu-gray-200);
  padding: 0 20px;
  display: contents;
`;

const DealsModal: React.FC<IProps> = ({
  modalProps,
  modalOptions,
  isIncludedDeals,
  closeModal,
  modalFilters,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const isFiltersOnModalsEnabled = useSelector(getIsFiltersOnModalsEnabled);
  const localStorageKey = modalOptions.localStorageKeyPrefix
    ? `${modalOptions.localStorageKeyPrefix}SortColumn:${user.email}`
    : '';

  const showFilters =
    modalProps.showFilters &&
    isFiltersOnModalsEnabled &&
    haveFiltersConfigurated(modalFilters || {});

  const [
    sortColumnLocalStorage,
    setSortColumnLocalStorage,
    getAndSetSortColumnLocalStorage,
  ] = useLocalStorage(localStorageKey, '');

  const parsedFilters = JSON.parse(modalProps.filters || '{}');
  const [filters, setFilters] = useState({
    ...parsedFilters,
    page_size: 50,
    page_number: 0,
  });
  const [sortColumn, setSortColum] = useState(sortColumnLocalStorage);

  const {
    apiUrl,
    context,
    deltaOverPeriod,
    id,
    isHistoricalSubmissionsModal,
    title,
    filterIncludedTogglerInitialState,
    isDealsDelta,
    overrideModalAmount,
    hideDelta,
    submissionSetting,
    isFirstBar,
    isLastBar,
    showTotalAmount,
    onIncludedDealsChange,
  } = modalProps;
  const initialBadgeValue =
    filters.forecast_series === 'current_forecast' ? deltaOverPeriod : id;

  const [badgeValue, setBadgeValue] = useState(initialBadgeValue);

  useEffect(() => {
    const value = getAndSetSortColumnLocalStorage(localStorageKey);
    setSortColum(value);
  }, [localStorageKey]);

  const filtersForModal = useMemo(
    () => (modalFilters ? getFiltersForModal(modalFilters, parsedFilters) : []),
    [modalProps.filters]
  );

  useEffect(() => {
    if (showFilters) {
      dispatch(
        actions.ui.filters.setForecastModalFilters({
          filters: filtersForModal,
          tab: FILTER_NAME.ForecastOpportunitiesModal,
        })
      );
    }
  }, [filtersForModal]);

  useEffect(() => {
    setFilters({
      ...JSON.parse(modalProps.filters || '{}'),
      page_size: 50,
      page_number: 0,
    });
  }, [modalProps.filters]);

  useEffect(() => {
    if (showFilters) {
      setFilters({
        ...modalFilters,
        ids: parsedFilters.ids,
        ...(parsedFilters.business_type_name
          ? {
              business_type_name: parsedFilters.business_type_name,
            }
          : {}),
        page_size: isIncludedDeals ? 0 : 50,
        page_number: 0,
      });
    }
  }, [modalFilters, showFilters]);

  useEffect(() => {
    dispatch(setSelectedLabel(title));
  }, [title]);

  const infoIconTooltipText = useMemo(() => {
    if (isFirstBar) {
      return INFO_ICON_TOOLTIP_TEXTS.A;
    }

    if (isLastBar) {
      return INFO_ICON_TOOLTIP_TEXTS.B;
    }

    if (title && INFO_ICON_TOOLTIP_TEXTS[title.toUpperCase()]) {
      return INFO_ICON_TOOLTIP_TEXTS[title.toUpperCase()];
    }

    return '';
  }, [title]);

  const countFiltersParams = Object.keys(filters).length;
  if (isEmpty(modalProps) || (countFiltersParams <= 2 && !isIncludedDeals)) {
    return <Loader active />;
  }

  const handleIncludedDealsSearch = (newRequest: Object) => {
    setFilters({
      ...newRequest,
      page_size: isIncludedDeals ? (newRequest as any).page_size : 50,
    });
  };

  let timeInterval = '',
    showBadge = false;
  // Show time interval and badge only in forecast sections.
  if (
    (history.location.pathname.includes('/forecast') ||
      history.location.pathname.includes('/pipeline')) &&
    filters.change_interval &&
    filters.change_interval.split
  ) {
    timeInterval = filters.change_interval
      ? CHANGE_INTERVAL_OPTIONS[filters.change_interval] ||
        moment(filters.change_interval.split(',')[0]).format('MM/DD/YYYY')
      : '';
    showBadge = FORECAST_SERIES_WITH_BADGE.includes(filters.forecast_series);
  }

  const updateHeader = (newDeals: DealsRequestState) => {
    setBadgeValue(newDeals.total_amount);
  };

  const persistLocallyColumnSort = (column: string): void => {
    setSortColum(column);
    if (localStorageKey) {
      setSortColumnLocalStorage(column);
    }
  };

  const isDealsTable =
    (history.location.pathname.includes('forecast/analytics') &&
      apiUrl === '/api/data/deals/') ||
    isDealsDelta;
  const replacedTitle = formatLabel(filters)({ value: String(title) }, false);

  return (
    <div className={isIncludedDeals ? includedDealsTableWrapper : tableWrapper}>
      {showFilters && (
        <div className={filtersContainer}>
          <OpenFiltersPanel
            tab={FILTER_NAME.ForecastOpportunitiesModal}
            isModal
          />
        </div>
      )}

      <DealsTable
        apiUrl={apiUrl}
        inModal
        initialRequest={{
          ...filters,
          table_name: isDealsTable // only for trends and for first chart
            ? TABLE_NAME.DealsDelta
            : undefined,
          sort: sortColumn,
        }}
        forecastTable={filters.is_forecast}
        persistName={`deals_modal_${apiUrl + title! + id}`}
        tableConfigCollection="opportunity"
        submissionSetting={submissionSetting}
        tableConfigName={
          isIncludedDeals
            ? TABLE_NAME.ForecastSubmissionSettings
            : isDealsTable // only for trends and for first chart
            ? TABLE_NAME.DealsDelta
            : TABLE_NAME.Opportunities
        }
        title={replacedTitle}
        dataType="Deals"
        isIncludedDeals={isIncludedDeals}
        filterIncludedTogglerInitialState={filterIncludedTogglerInitialState}
        isHistoricalSubmissionsModal={isHistoricalSubmissionsModal}
        onIncludedDealsChange={onIncludedDealsChange}
        onRequestChange={handleIncludedDealsSearch}
        renderTitleExtra={() => (
          <>
            {infoIconTooltipText && (
              <div className={classNames(headerInfoIcon)}>
                <TooltipWrapper
                  tooltip={
                    <div style={{ width: '200px', textAlign: 'center' }}>
                      {infoIconTooltipText}
                    </div>
                  }
                  position="top center"
                >
                  <img src={InfoIcon} alt="info" />
                </TooltipWrapper>
              </div>
            )}
            {showBadge && !hideDelta ? (
              <div className={classNames(badgeContainer)}>
                <Badge value={badgeValue} />
              </div>
            ) : null}
            {timeInterval ? (
              <div className={classNames(timeIntervalContainer)}>
                {context
                  ? `${context} changes over ${timeInterval}`
                  : `Changes since ${timeInterval}`}
              </div>
            ) : null}
          </>
        )}
        onCloseModal={closeModal}
        onDealsDataChanged={updateHeader}
        styleFirstColumn={false}
        forceBasicFilters={showFilters}
        overrideAmount={overrideModalAmount}
        localStorageSortColumn={sortColumn}
        onSortChangePersistLocally={persistLocallyColumnSort}
        showTotalAmount={showTotalAmount}
      />
    </div>
  );
};

export default DealsModal;
