import { css } from 'emotion';

export const table_wrapper = css`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  position: relative;
  border: 1px solid var(--bu-gray-500);
  border-radius: 5px;
  background-color: var(--bu-white);
  color: var(--bu-gray-900);
`;

export const table_header_box_columns = css`
  z-index: 1;
  position: relative;
  th {
    border-bottom: 1px solid var(--bu-gray-500);
  }
`;

export const table_header_column = css`
  padding: 0 10px;
  color: var(--bu-gray-700);
  position: relative;
  width: 10%;

  .bu-icon {
    display: none;
  }

  &.sortable {
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      > span {
        flex: 1;
      }
    }

    &:hover > div > .bu-icon {
      display: inline-block;
    }

    &.sorting > div > {
      span {
        color: var(--bu-gray-900);
      }

      .bu-icon {
        display: inline-block;
        color: var(--bu-primary-500);
      }
    }
  }
`;

export const table_sticky_header = css`
  border-collapse: separate !important;

  & thead tr:first-child > th {
    position: sticky !important;
    top: 0;
    z-index: 2;
    background-color: var(--bu-gray-200) !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

export const whiteWrapper = css`
  padding: 30px;
  background: white;
  margin-bottom: 20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
`;

export const apiError = css`
  padding: 20px 5px;
  background: white;
  margin-bottom: 20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
`;

export const scrollableBody = css`
  max-height: 500px;
  overflow-y: auto;
`;

export const body_table_row = css`
  td {
    border-bottom: 0.1px solid var(--bu-gray-500);
  }

  &:hover {
    background: var(--bu-gray-200);
  }

  &:last-child td {
    border-bottom: none;
  }
`;
