import { css } from 'emotion';

export const totalCardsContainer = css`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  height: 100%;
  align-items: stretch;

  &.singleValue,
  &.totalsOnly {
    .totalChartTypeDropdown {
      display: none !important;
    }
    & > div {
      margin: unset;
      border: solid var(--bu-gray-400) 1px;
    }
    & > div:first-child {
      border-radius: 4px 0 0 4px;
    }
    & > div:last-child {
      border-radius: 0 4px 4px 0;
    }
    p {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 5px;
      width: fit-content;
      max-width: 100%;
    }
    span {
      line-height: 100%;
      width: 100%;
      height: 100%;
    }
  }

  &.totalsAndChart {
    & > .totalCard {
      border: solid var(--bu-gray-400) 1px;
      border-radius: 4px;
    }
  }

  &.singleValue {
    border: none;
    gap: 4px;
    width: 100%;
    height: 100%;
    & > div {
      border: none;
      align-items: center;
      width: 100%;
    }
    & > div > div {
      align-items: center;
    }
    p {
      font-size: 14px;
    }
    span {
      text-align: center;
      line-height: 100%;
    }
  }
`;
