import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { Roles } from 'common/constants';
import WidgetPie from 'components/dashboard/ProspectAccountDashboard/WidgetPie';
import {
  apiPointForPies,
  pieChartsNames,
  pieConfigs,
  piesDataInitialState,
} from 'components/dashboard/ProspectAccountDashboard/WidgetThreePies/config';
import {
  PieChartName,
  PiesData,
} from 'components/dashboard/ProspectAccountDashboard/WidgetThreePies/types';
import * as s from 'components/dashboard/ProspectAccountDashboard/styles';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import { QueryStatus, fetchApi } from 'utils/network';

type OwnProps = {
  tab: string;
};

type Props = OwnProps & {
  filters?: {
    users?: string[];
    roles?: Roles;
    change_interval?: string;
  };
};

const WidgetThreePies: React.FC<Props> = ({ filters }) => {
  const serializedFetchParams = JSON.stringify({
    users: filters!.users,
    roles: filters!.roles,
    change_interval: filters!.change_interval,
  });

  const [data, setData] = useState<PiesData>(piesDataInitialState);
  const [error, setError] = useState<null | string>(null);
  const [status, setStatus] = useState<QueryStatus>('notAsked');

  useEffect(() => {
    setStatus('notAsked');
  }, [
    JSON.stringify(filters!.users),
    JSON.stringify(filters!.roles),
    JSON.stringify(filters!.change_interval),
  ]);

  useEffect(() => {
    const controller = new AbortController();

    if (status === 'notAsked') {
      setError(null);

      fetchApi<string, PiesData>({
        queryParams: serializedFetchParams,
        setData,
        setError,
        setStatus,
        signal: controller.signal,
        url: apiPointForPies,
      });
    }

    return () => {
      if (status === 'loading') {
        controller.abort();
      }
    };
  }, [serializedFetchParams, status]);

  const renderPie = (name: PieChartName) => {
    if (data === null) {
      return null;
    }

    const chartConfig = pieConfigs[name];
    const [first, second] = chartConfig.data[0].data;
    const fullData = [
      {
        data: [
          {
            color: 'var(--bu-orange-400)',
            name: first.name,
            y: data[name],
          },
          {
            color: 'var(--bu-primary-400)',
            name: second.name,
            y: data.total_accounts - data[name],
          },
        ],
        name: 'Total',
        innerSize: '70%',
      },
    ];

    if (status === 'loading') {
      return (
        <div key={name} className={s.chart}>
          <Loader size="tiny" active inline />
        </div>
      );
    }

    if (status === 'error') {
      return (
        <div key={name} className={s.chart}>
          <span>{`error: ${error}`}</span>
        </div>
      );
    }

    return (
      <div key={name} className={s.chart}>
        <WidgetPie
          data={fullData}
          numberAccounts={data[name]}
          title={chartConfig.title}
        />
      </div>
    );
  };

  return <Fragment>{pieChartsNames.map(renderPie)}</Fragment>;
};

const mapStateToProps = (state: IReduxState, props: OwnProps): Object => ({
  filters: selectors.getFiltersForAPI(state, props.tab),
});

export default connect(mapStateToProps)(WidgetThreePies);
