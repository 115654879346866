import React from 'react';

import { formatMoney, formatNumber } from 'common/numbers';
import {
  IColumn,
  IRow,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import {
  COLUMN_DEFAULTS,
  SUBTOTAL,
} from 'components/dashboard/Metrics/Widget/Table/constants';
import {
  ExtraHeaderBodyCell,
  ExtraHeaderCell,
  ExtraSubTotalCell,
  FirstSubTotalCell,
  LastRowRegularCell,
  MergedPivotRegularCell,
  RegularCell,
  SubTotalCell,
} from 'components/dashboard/Metrics/Widget/Table/helpers/cell.styles';
import {
  BIMetrics,
  BIMetricsMap,
  BIWidgetColumnV2,
  BIWidgetDataV2,
} from 'components/dashboard/Metrics/metrics.types';

/**
 * Returns columns based on how many pivots are used.
 *
 * @param availablePivots
 * @param widgetData
 * @returns BIWidgetColumnV2[]
 */
const getTableColumns = (
  availablePivots: number,
  widgetData: BIWidgetDataV2
): BIWidgetColumnV2[] => {
  if (availablePivots === 1) {
    return [
      ...(widgetData?.pivot_1?.columns_error || []),
      ...(widgetData?.pivot_1?.columns || []),
    ];
  }

  if (availablePivots === 2) {
    return [
      ...(widgetData?.pivot_2?.columns_error || []),
      ...(widgetData?.pivot_2?.columns || []),
    ];
  }

  return [];
};

/**
 * Reorders columns so pivots are in the first two places.
 *
 * @param availablePivots
 * @param widgetData
 * @returns BIWidgetColumnV2[]
 */
const reorderColumnsByPivots = (
  availablePivots: number,
  widgetData: BIWidgetDataV2
): BIWidgetColumnV2[] => {
  const tableColumns = getTableColumns(availablePivots, widgetData);

  if (availablePivots === 1) {
    const first = tableColumns.pop();
    if (first) {
      return [first, ...tableColumns];
    }
  }

  if (availablePivots === 2) {
    const second = tableColumns.pop();
    const first = tableColumns.pop();

    if (first && second) {
      return [first, second, ...tableColumns];
    }
  }

  return tableColumns;
};

/**
 * Gets data from the response based on how many pivots are used.
 *
 * @param widgetData
 * @param availablePivots
 * @returns [key: string]: any;
 */
const getData = (
  widgetData: BIWidgetDataV2,
  availablePivots: number
): {
  [key: string]: any;
}[] => {
  if (availablePivots === 1) {
    return widgetData.pivot_1?.data || [];
  }

  if (availablePivots === 2) {
    return widgetData.pivot_2?.data || [];
  }

  return [];
};

/**
 * Returns cell style based on type which is it.
 *
 * @param row
 * @param isMergedFirstPivot
 * @returns
 */
const getExtraHeaderCellStyle = (row: IRow): string => {
  if (row.isSubtotal) {
    return ExtraSubTotalCell;
  }

  return ExtraHeaderBodyCell;
};

type ExtraHeaderTableProps = {
  columns: IColumn[];
  extraHeader: IColumn[];
};

type ListOfObjects = {
  [key: string]: any;
}[];

const getColumnsConfigWithExtraHeaderSinglePivot = (
  companyCurrencyCode: string,
  currentData: ListOfObjects,
  pivot1: BIWidgetColumnV2,
  metricColumns: BIWidgetColumnV2[],
  metricsCount: number,
  metricsInUse: BIMetricsMap,
  onTableDataClick: (column: IColumn, row: IRow) => void
): ExtraHeaderTableProps => {
  const columns: IColumn[] = [];
  const extraHeader: IColumn[] = [];

  currentData.forEach((e) => {
    const pivotValue = e[pivot1.field_name];
    extraHeader.push({
      id: pivotValue,
      label: pivotValue,
      field: pivotValue,
      type: ColumnTypes.TEXT,
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      config: {
        className: ExtraHeaderCell,
      },
      colSpan: metricsCount,
    });

    metricColumns.forEach((metricColumn) => {
      const id = `${metricColumn.field_name}|${pivotValue}`;

      const metricDefinition = metricsInUse[metricColumn.metric_id];
      const { properties = {} } = metricDefinition ?? {};
      const { metricUnit } = properties;
      const { type, unit } = metricUnit || {};

      if (metricColumn.type === 'money' || metricColumn.type === 'number') {
        columns.push({
          ...COLUMN_DEFAULTS,
          align: 'right',
          extraHeader: pivotValue,
          field: id,
          label: metricColumn.display_name,
          sortable: false,
          type: metricColumn.type,
          id: id,
          metricId: metricColumn.metric_id,
          config: {
            className: (row) => getExtraHeaderCellStyle(row),
            click: (column: IColumn, row: IRow) => {
              onTableDataClick(column, row);
            },
            formatter: (value: any) => (
              <strong>
                {value !== '-' && type === 'prefix' && unit}
                {metricColumn.type === 'money'
                  ? formatMoney(companyCurrencyCode, value)
                  : metricColumn.type === 'number'
                  ? formatNumber(value)
                  : value}
                {value !== '-' && type === 'suffix' && unit}
              </strong>
            ),
          },
        } as IColumn);
      } else {
        columns.push({
          ...COLUMN_DEFAULTS,
          align: 'right',
          extraHeader: pivotValue,
          field: id,
          label: metricColumn.display_name,
          sortable: false,
          type:
            metricColumn.type === 'date' ? ColumnTypes.TEXT : metricColumn.type,
          id: id,
          metricId: metricColumn.metric_id,
          config: {
            className: (row) => getExtraHeaderCellStyle(row),
            formatter: (value: any) => (
              <>
                {value !== '-' && type === 'prefix' && unit}
                {value}
                {value !== '-' && type === 'suffix' && unit}
              </>
            ),
          },
        } as IColumn);
      }
    });
  });

  return {
    columns,
    extraHeader,
  };
};

const getColumnsConfigWithExtraHeaderTwoPivots = (
  companyCurrencyCode: string,
  currentData: ListOfObjects,
  metricColumns: BIWidgetColumnV2[],
  metricsCount: number,
  metricsInUse: { [key: string]: BIMetrics },
  pivot1: BIWidgetColumnV2,
  pivot2: BIWidgetColumnV2,
  onTableDataClick: (column: IColumn, row: IRow) => void
) => {
  const columns: IColumn[] = [];
  const extraHeader: IColumn[] = [];

  extraHeader.push({
    id: '',
    label: '',
    field: '',
    type: ColumnTypes.TEXT,
    sortable: false,
    sort_order: SortOrder.ASCENDING,
    config: {
      className: ExtraHeaderCell,
    },
  });

  columns.push({
    id: pivot2.field_name,
    label: pivot2.display_name,
    field: pivot2.field_name,
    type: ColumnTypes.TEXT,
    sortable: true,
    sort_order: SortOrder.ASCENDING,
    config: {
      className: (row) => getExtraHeaderCellStyle(row),
    },
  });

  const pivot1Set: Set<string> = new Set();
  currentData.forEach((d) => {
    pivot1Set.add(d[pivot1.field_name]);
  });

  pivot1Set.forEach((pivotValue) => {
    extraHeader.push({
      id: pivotValue,
      label: pivotValue,
      field: pivotValue,
      type: ColumnTypes.TEXT,
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      config: {
        className: ExtraHeaderCell,
      },
      colSpan: metricsCount,
    });

    metricColumns.forEach((metricColumn) => {
      const id = `${metricColumn.field_name}|${pivotValue}`;
      const metricDefinition = metricsInUse[metricColumn.metric_id];
      const { properties = {} } = metricDefinition ?? {};
      const { metricUnit } = properties;
      const { type, unit } = metricUnit || {};

      if (metricColumn.type === 'money' || metricColumn.type === 'number') {
        columns.push({
          ...COLUMN_DEFAULTS,
          align: 'right',
          extraHeader: pivotValue,
          field: id,
          label: metricColumn.display_name,
          sortable: true,
          type: metricColumn.type,
          id: id,
          metricId: metricColumn.metric_id,
          config: {
            className: (row) => getExtraHeaderCellStyle(row),
            click: (column: IColumn, row: IRow) => {
              onTableDataClick(column, row);
            },
            formatter: (value: any) => (
              <strong>
                {value !== '-' && type === 'prefix' && unit}
                {metricColumn.type === 'money'
                  ? formatMoney(companyCurrencyCode, value)
                  : metricColumn.type === 'number'
                  ? formatNumber(value)
                  : value}
                {value !== '-' && type === 'suffix' && unit}
              </strong>
            ),
          },
        } as IColumn);
      } else {
        columns.push({
          ...COLUMN_DEFAULTS,
          align: 'right',
          extraHeader: pivotValue,
          field: id,
          label: metricColumn.display_name,
          sortable: true,
          type:
            metricColumn.type === 'date' ? ColumnTypes.TEXT : metricColumn.type,
          id: id,
          metricId: metricColumn.metric_id,
          config: {
            className: (row) => getExtraHeaderCellStyle(row),
            formatter: (value: any) => (
              <>
                {value !== '-' && type === 'prefix' && unit}
                {value}
                {value !== '-' && type === 'suffix' && unit}
              </>
            ),
          },
        } as IColumn);
      }
    });
  });

  return {
    columns,
    extraHeader,
  };
};

/**
 * Called when first pivot is of type Date so we need to include extra header
 * and reorder columns and data.
 *
 * @param widgetData
 * @param availablePivots
 * @param companyCurrencyCode
 * @param metricsCount
 * @param metricsInUse
 * @param metricsInUse
 * @param onTableDataClick
 * @returns ExtraHeaderTableProps
 */
export const getColumnsConfigWithExtraHeader = (
  widgetData: BIWidgetDataV2,
  availablePivots: number,
  companyCurrencyCode: string,
  metricsCount: number,
  metricsInUse: BIMetricsMap,
  onTableDataClick: (column: IColumn, row: IRow) => void
): ExtraHeaderTableProps => {
  const tableColumns = reorderColumnsByPivots(availablePivots, widgetData);

  const [pivot1, pivot2] = tableColumns;
  const currentData = getData(widgetData, availablePivots);

  const metricColumns = tableColumns.filter((e) => !e.is_pivot);

  if (availablePivots === 1) {
    return getColumnsConfigWithExtraHeaderSinglePivot(
      companyCurrencyCode,
      currentData,
      pivot1,
      metricColumns,
      metricsCount,
      metricsInUse,
      onTableDataClick
    );
  }

  if (availablePivots === 2) {
    return getColumnsConfigWithExtraHeaderTwoPivots(
      companyCurrencyCode,
      currentData,
      metricColumns,
      metricsCount,
      metricsInUse,
      pivot1,
      pivot2,
      onTableDataClick
    );
  }

  return {
    columns: [],
    extraHeader: [],
  };
};

/**
 * Returns cell style based on type which is it.
 *
 * @param row
 * @param isMergedFirstPivot
 * @returns
 */
const getCellStyle = (row: IRow, isMergedFirstPivot: boolean): string => {
  if (row.isSubtotal) {
    if (isMergedFirstPivot) {
      return FirstSubTotalCell;
    }

    return SubTotalCell;
  } else {
    if (isMergedFirstPivot) {
      return MergedPivotRegularCell;
    }
    if (row.isLastRow) {
      return LastRowRegularCell;
    }

    return RegularCell;
  }
};

/**
 * Called when first pivot is not date type. Returns regular IColumn[]
 *
 * @param companyCurrencyCode
 * @param widgetData
 * @param availablePivots
 * @param onTableDataClick
 * @returns IColumn[]
 */
export const getColumnsConfig = (
  companyCurrencyCode: string,
  widgetData: BIWidgetDataV2,
  availablePivots: number,
  metricsInUse: BIMetricsMap,
  onTableDataClick: (column: IColumn, row: IRow) => void
): IColumn[] => {
  const data = reorderColumnsByPivots(availablePivots, widgetData);

  return data.map((column, index) => {
    const isPivotColumn = column.is_pivot;
    const isMergedFirstPivot =
      isPivotColumn && index === 0 && availablePivots === 2;

    const metricDefinition = metricsInUse[column.metric_id];
    const { properties = {} } = metricDefinition ?? {};
    const { metricUnit } = properties;
    const { type, unit } = metricUnit || {};

    if (column.type === 'money' || column.type === 'number') {
      return {
        ...COLUMN_DEFAULTS,
        field: column.field_name,
        label: column.display_name,
        sortable: true,
        type: column.type,
        id: column.metric_id,
        metricId: column.metric_id,
        align: isPivotColumn ? 'left' : 'right',
        config: {
          className: (row) => getCellStyle(row, isMergedFirstPivot),
          click: (column: IColumn, row: IRow) => {
            onTableDataClick(column, row);
          },
          formatter: (value: any) => (
            <strong>
              {value !== '-' && type === 'prefix' && unit}
              {column.type === 'money'
                ? formatMoney(companyCurrencyCode, value)
                : column.type === 'number'
                ? formatNumber(value)
                : value}
              {value !== '-' && type === 'suffix' && unit}
            </strong>
          ),
        },
      } as IColumn;
    }

    return {
      ...COLUMN_DEFAULTS,
      field: column.field_name,
      label: column.display_name,
      sortable: true,
      type: column.type === 'date' ? ColumnTypes.TEXT : column.type,
      id: column.field_name,
      metricId: column.metric_id,
      align: isPivotColumn ? 'left' : 'right',
      config: {
        className: (row) => getCellStyle(row, isMergedFirstPivot),
        formatter: (value: unknown) => {
          if (value === SUBTOTAL) {
            return <strong>{value}</strong>;
          } else {
            return (
              <>
                {value !== '-' && type === 'prefix' && unit}
                {value}
                {value !== '-' && type === 'suffix' && unit}
              </>
            );
          }
        },
      },
    } as IColumn;
  });
};
