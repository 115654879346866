import {
  BIMetricColumn,
  BIMetricFormula,
  BIMetricSimple,
  BIWidget,
  BIWidgetPreview,
} from 'components/dashboard/Metrics/metrics.types';
import { IReduxState } from 'reducers/types';

export const getWidgetFetchByIdStatus = (state: IReduxState): string =>
  state.revbi.widgets.edit.getStatus;

/**
 * Selector return widget data depending on Create Widget Component Mode. If widgetId is passed selector returns editing widget. If not, newborn widget.
 * If widget is not fetched yet (null in store). Newborn widget will be returned.
 * @param widgetId pass widget ID optionally
 */
export const getActiveWidget = (
  state: IReduxState,
  widgetId?: string
): BIWidget | BIWidgetPreview => {
  if (widgetId) {
    const widget = state.revbi.widgets.edit.widget;
    if (widget === null) {
      return state.revbi.widgets.create.widget;
    } else {
      return widget;
    }
  } else {
    return state.revbi.widgets.create.widget;
  }
};

export const getActiveWidgetMetricsFromList = (
  state: IReduxState,
  widgetId?: string
): (BIMetricFormula | BIMetricSimple)[] => {
  if (widgetId) {
    return state.revbi.widgets.edit.metricsFromList;
  }
  return state.revbi.widgets.create.metricsFromList;
};

export const getColumnsList = (
  state: IReduxState,
  widgetId?: string
): BIMetricColumn[] => {
  if (widgetId) {
    return state.revbi.widgets.edit.columnList;
  }
  return state.revbi.widgets.create.columnList;
};
