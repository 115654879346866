import * as t from './actionTypes';

import { optionSectionsEnum } from 'components/dashboard/Metrics/Create/WidgetCreate/constants';
import {
  BIBasicColumn,
  BIMetricFormula,
  BIMetricFormulaNewborn,
  BIMetricSimple,
  BIMetricSimpleNewborn,
  BIWidget,
  BIWidgetPreview,
  GetWidgetByIdSuccessPayload,
} from 'components/dashboard/Metrics/metrics.types';
import * as genericSagas from 'sagas/generic';
import * as revbiSagas from 'sagas/revbi';
import { actionCreator } from 'utils/factories';

/**
 * Action saves the new widget and keep it in revbi store.
 * @param widget new widget mock
 */
export const createWidget = (widget: BIWidgetPreview) => ({
  type: t.WIDGET + t.CREATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets/`,
  saga: genericSagas.create,
  data: widget,
  success: (payload: BIWidget) => createWidgetSuccess(payload),
  loading: createWidgetLoading,
});
export const createWidgetSuccess = actionCreator<BIWidget>(
  t.WIDGET + t.CREATE + t.SUCCESS
);
export const createWidgetLoading = actionCreator(
  t.WIDGET + t.CREATE + t.LOADING
);

/**
 * Action saves the new widget and keep it in revbi store.
 * Extended version can add widget to a dashboard and do the save redirect
 * @param widget new widget mock
 * @param dashboardId id of dashboard to add the widget
 * @param redirect path to redirect if saving went success
 */
export const createWidgetExtended = (
  widget: BIWidgetPreview,
  dashboardId?: string,
  redirect?: string
) => ({
  type: t.WIDGET + t.CREATE + t.EXTENDED,
  saga: revbiSagas.createWidgetExtended,
  payload: {
    widget,
    dashboardId,
    redirect,
  },
});

/**
 * Action saves the new widget and keep it in revbi store.
 * Extended version can add widget to a dashboard and do the save redirect
 * @param widget new widget mock
 * @param dashboardId id of dashboard to add the widget
 * @param redirect path to redirect if saving went success
 */
export const updateWidgetExtended = (
  widget: BIWidgetPreview,
  dashboardId?: string,
  redirect?: string
) => ({
  type: t.WIDGET + t.CREATE + t.EXTENDED,
  saga: revbiSagas.updateWidgetExtended,
  payload: {
    widget,
    dashboardId,
    redirect,
  },
});

/**
 * Action updates the new widget and keep it in revbi store.
 * @param widget new widget mock
 */
export const updateWidget = (widget: BIWidget) => ({
  type: t.WIDGET + t.CREATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets/${widget._id}`,
  saga: genericSagas.update,
  data: widget,
  success: (payload: BIWidget) => updateWidgetSuccess(payload),
  loading: updateWidgetLoading,
});
export const updateWidgetSuccess = actionCreator<BIWidget>(
  t.WIDGET + t.UPDATE + t.SUCCESS
);
export const updateWidgetLoading = actionCreator(
  t.WIDGET + t.UPDATE + t.LOADING
);

/**
 * Action gets widget by ID and keep it in revbi store.
 * @param id Id of the requesting widget
 * @param isEditing It's optional boolean param. If it is true fetched metric is saving as editing widget.
 */
export const fetchWidgetById = (id: string, isEditing?: boolean) => ({
  type: t.WIDGET + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets/${id}?expand=true`,
  saga: genericSagas.get,
  success: (payload: BIWidget) =>
    fetchWidgetByIdSuccess({
      id,
      data: payload,
      isEditing,
    }),
  loading: () => fetchWidgetByIdLoading({ id, isEditing }),
});
export const fetchWidgetByIdSuccess =
  actionCreator<GetWidgetByIdSuccessPayload>(t.WIDGET + t.GET + t.SUCCESS);
export const fetchWidgetByIdLoading = actionCreator<{
  id: string;
  isEditing?: boolean;
}>(t.WIDGET + t.GET + t.LOADING);

/**
 * Action gets widget by ID and keep it in revbi store. Additionally it sets metrics for editing state.
 * @param id Id of the requesting widget
 */
export const fetchWidgetByIdExtended = (id: string) => ({
  type: t.WIDGET + t.GET + t.EXTENDED,
  saga: revbiSagas.getWidgetByIdExtended,
  payload: {
    id,
  },
});

/**
 * Action gets all widgets from the server.
 * @see /rev_bi/widgets API GET
 * @param isEditing It's optional boolean param. If it is true fetched metric is saving as editing widget.
 */
export const fetchAllWidgets = () => ({
  type: t.WIDGET + t.GET + t.ALL,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets`,
  saga: genericSagas.get,
  success: (payload: BIWidget[]) => fetchAllWidgetsSuccess(payload),
  loading: fetchAllWidgetsLoading,
});
export const fetchAllWidgetsSuccess = actionCreator<BIWidget[]>(
  t.WIDGET + t.GET + t.ALL + t.SUCCESS
);
export const fetchAllWidgetsLoading = actionCreator(
  t.WIDGET + t.GET + t.ALL + t.LOADING
);

/**
 * Action adds metric to widget metrics list.
 * @param metric
 * @param widgetId It's optional boolean param. If it is true passed metric is saving as editing widget.
 */
export const addMetricToMetricsFromList = actionCreator<{
  metric: BIMetricSimple | BIMetricFormula;
  widgetId?: string;
}>(t.WIDGET + t.ADD + t.METRIC);

/**
 * Action removes metric from widget metrics list.
 * @param metric
 * @param widgetId It's optional boolean param. If it is true passed metric is removing from editing widget.
 */
export const removeMetricFromMetricsFromList = actionCreator<{
  metricId: string;
  widgetId?: string;
}>(t.WIDGET + t.REMOVE + t.METRIC);

/**
 * Action updates metric from widget metrics list.
 * @param metric
 * @param widgetId It's optional boolean param. If it is true passed metric is removing from editing widget.
 */
export const updateMetricFromMetricsFromList = actionCreator<{
  metricId: string;
  widgetId?: string;
  metric: BIMetricSimple | BIMetricFormula;
}>(t.WIDGET + t.UPDATE + t.METRIC);

/**
 * Action creates new metric and put it to metricsFromList
 * @param metric
 * @param widgetId It's optional boolean param. If it is true passed metric is removing from editing widget.
 * @see revbiSagas.createNewMetricInWidgetCreationProccess
 */
export const createMetricForWidgetCreation = (
  metric: BIMetricSimpleNewborn | BIMetricFormulaNewborn,
  widgetId?: string
) => ({
  type: t.METRIC + t.CREATE + t.FOR_WIDGET,
  saga: revbiSagas.createNewMetricInWidgetCreationProccess,
  payload: {
    metric,
    widgetId,
  },
});

/**
 * Action updates metric on the server and put it to metricsFromList
 * @param metric
 * @param widgetId It's optional boolean param. If it is true passed metric is removing from editing widget.
 * @see revbiSagas.updateNewMetricInWidgetCreationProccess
 */
export const updateMetricForWidgetCreation = (
  metric: BIMetricSimple | BIMetricFormula,
  widgetId?: string
) => ({
  type: t.METRIC + t.UPDATE + t.FOR_WIDGET,
  saga: revbiSagas.updateNewMetricInWidgetCreationProccess,
  payload: {
    metric,
    widgetId,
  },
});

/**
 * Action changes active section on widget creation / editing page
 * @param section new active section
 * @param widgetId It's optional boolean param. If it is true passed metric is removing from editing widget.
 */
export const changeCreateWidgetPageSection = actionCreator<{
  section: optionSectionsEnum;
  widgetId?: string;
}>(t.WIDGET_CREATION_SECTION + t.CHANGE);

/**
 * Action changes editing / creation widget in store.
 * @param widget new widget data
 * @param widgetId It's optional boolean param. If it is true passed metric is removing from editing widget.
 */
export const changeWidgetInStore = actionCreator<{
  widget: Partial<BIWidget>;
  widgetId?: string;
}>(t.WIDGET + t.CHANGE);

/**
 * Action gets columns fields filter for widget.
 * @see /rev_bi/external/get_column_fields_filter API POST
 * @param tables List of objects. (metric.object)
 * @param widgetId ID of the widget.
 */
export const fetchColumnsList = (tables: string[], widgetId?: string) => ({
  type: t.WIDGET + t.GET + t.COLUMN_FIELDS_FILTER,
  url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_filter`,
  saga: genericSagas.create,
  data: { tables },
  success: (payload: BIBasicColumn[]) =>
    fetchColumnsListSuccess({ columnsList: payload, widgetId }),
  loading: fetchColumnsListLoading,
});
export const fetchColumnsListSuccess = actionCreator<{
  columnsList: BIBasicColumn[];
  widgetId?: string;
}>(t.WIDGET + t.GET + t.COLUMN_FIELDS_FILTER + t.SUCCESS);
export const fetchColumnsListLoading = actionCreator(
  t.WIDGET + t.GET + t.COLUMN_FIELDS_FILTER + t.LOADING
);

/**
 * Action cleanup create / edit state in Redux
 */
export const resetWidgetStorage = actionCreator(
  t.WIDGET + t.CLEAN_UP_CREATION_STATE
);
