import DeltaValueCell, { getValueFromDelta } from './common/DeltaValueCell';
import classNames from 'classnames';
import { css } from 'emotion';
import moment from 'moment';
import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import {
  SingleDatePickerShape,
  DayPickerSingleDateController,
} from 'react-dates';
import { Popup } from 'semantic-ui-react';

import { useStateWhile } from 'common/helpers';
import {
  IDataCellProps,
  TypedTableCellConfig,
  ValueType,
  isDisable,
  isEditable,
} from 'components/UI/common/TypedTable/TypedTable';
import NotAvailableCell from 'components/UI/common/TypedTable/renderers/NotAvailableCell';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import * as s from 'components/UI/common/TypedTable/styles';

export interface DateCellConfig extends TypedTableCellConfig {
  withoutTooltip?: boolean;
}

const popupWrapper = css`
  .CalendarMonth {
    td {
      background: #fff !important;
      &.CalendarDay__selected {
        background: var(--bu-primary-500) !important;
      }
      &.CalendarDay {
        border-top: 1px solid rgba(34, 36, 38, 0.1) !important;
      }
    }
  }
`;

const notAvailableStyles = css`
  input::placeholder {
    font-style: italic;
  }
`;

const editableCellBorder = css`
  font-family: var(--bu-font-regular);
  font-weight: 100;
  color: #444;
  font-size: 13px;
  line-height: 26px;
  min-height: 20px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: var(--bu-control-border-radius);
  box-sizing: border-box;
  background-color: transparent;
  padding: 0px 5px;

  tr:hover & {
    border: 1px solid var(--bu-gray-400);
    box-sizing: border-box;
    background-color: var(--bu-white);
  }

  &.focused {
    border-color: var(--bu-primary-500);
  }

  &.loading {
    background-color: #fffaca !important;
  }

  &.success {
    background-color: #cff6d7 !important;
  }

  &.error {
    background-color: #fce3dc !important;
  }
`;

type TimeStamp = string | number | null;

const handleDate = (timestamp: TimeStamp) =>
  moment.parseZone(timestamp).utc(false);

const formatter =
  (format: string, not_available_placeholder?: string) =>
  (timestamp: TimeStamp): string => {
    const date = handleDate(timestamp);

    if (timestamp) {
      if (format === 'ago') {
        return date.fromNow();
      }

      if (format === 'local') {
        return date.local().format('DD MMM YYYY, h:mm A');
      }

      return date.format(format);
    }

    return not_available_placeholder || 'Not Available';
  };

const DateCell = ({ column, row, onChange, status, rows }: IDataCellProps) => {
  const rawValue = R.path(column.field.split('.'), row) as ValueType;
  const timestamp: TimeStamp = (
    column.delta ? getValueFromDelta(rawValue) : rawValue
  ) as TimeStamp;

  const rawDate = handleDate(timestamp);
  const date: SingleDatePickerShape['date'] = timestamp
    ? (rawDate as any)
    : null;

  const config = (column.config as DateCellConfig) || {};
  const format = config.format || "Do MMM 'YY";
  const { withoutTooltip } = config;

  const [focus, setFocus] = useState(false);
  const [showStatus, setShowStatus] = useStateWhile(false);

  useEffect(() => {
    if (status && status.date + 5000 > Date.now()) {
      setShowStatus(true, 5000);
    }
  }, [status && status.date]);

  const dateText =
    timestamp && date
      ? ['ago', 'to'].includes(format)
        ? date.fromNow()
        : format === 'local'
        ? rawDate.local().format('DD MMM YYYY, h:mm A')
        : date.format(format)
      : null;

  const onFocusChange = ({ focused }: { focused: boolean | null }) => {
    focused && setFocus(focused as boolean);
  };

  const disabled = isDisable(column, row, rows);

  const handleChange: SingleDatePickerShape['onDateChange'] = (newDate) => {
    if (onChange instanceof Function) {
      onChange(column, row, newDate ? newDate.format('YYYY-MM-DD') : null);
    }
    onCloseChange();
  };

  const onCloseChange = () => {
    setFocus(false);
  };

  const onRequestOpen = () => {
    if (!disabled) {
      setFocus(true);
    }
  };

  if (!isEditable(column, row, rows)) {
    const label = <div className={s.textOverflow}>{dateText}</div>;
    return (
      <DeltaValueCell
        column={column}
        row={row}
        rows={rows}
        formatter={
          formatter(
            format,
            config.not_available_placeholder
          ) as TypedTableCellConfig['formatter']
        }
      >
        {dateText ? (
          withoutTooltip ? (
            label
          ) : (
            <TooltipWrapper
              tooltip={
                column.showTooltip &&
                formatter(format, config.not_available_placeholder)(timestamp)
              }
            >
              {label}
            </TooltipWrapper>
          )
        ) : (
          <NotAvailableCell />
        )}
      </DeltaValueCell>
    );
  }

  const renderedSingleDatePicker = (
    <Popup
      on="click"
      className={popupWrapper}
      trigger={
        <div
          className={classNames(
            s.textOverflow,
            notAvailableStyles,
            editableCellBorder,
            {
              [(status && status.status) || '']: showStatus,
              focused: focus,
            }
          )}
        >
          {dateText}
        </div>
      }
      open={focus}
      onClose={onCloseChange}
      onOpen={onRequestOpen}
      content={
        <DayPickerSingleDateController
          date={date && date.isValid() ? date : null}
          focused={focus}
          hideKeyboardShortcutsPanel
          isOutsideRange={() => false}
          noBorder
          numberOfMonths={1}
          onClose={onCloseChange}
          onDateChange={handleChange}
          onFocusChange={onFocusChange}
        />
      }
    />
  );

  return (
    <DeltaValueCell
      column={column}
      row={row}
      rows={rows}
      formatter={
        formatter(
          format,
          config.not_available_placeholder
        ) as TypedTableCellConfig['formatter']
      }
    >
      {withoutTooltip ? (
        renderedSingleDatePicker
      ) : (
        <TooltipWrapper
          tooltip={
            column.showTooltip &&
            formatter(format, config.not_available_placeholder)(timestamp)
          }
          wrap
        >
          {renderedSingleDatePicker}
        </TooltipWrapper>
      )}
    </DeltaValueCell>
  );
};

export default DateCell;
