import { AxiosError } from 'axios';
import { Action } from 'redux';

import { SagaActionFunction } from 'actions';
import * as t from 'actions/actionTypes';
import { SubmissionBookedAndTargetStatusState } from 'reducers/croOverrideReducer';
import * as genericSagas from 'sagas/generic';

export type SubmissionSettingsId = string;

export type SubmissionColumn = {
  submission_settings_id: SubmissionSettingsId;
  order: number;
  forecast_name: string;
  business_type: string;
  period_info: string;
  period_interval: string;
  display_name: string;
  forecast_display_name: string;
  submit_enabled_for_profiles: string[];
  submit_enabled_for_roles: string[];
};

export type SummaryFields = {
  user_deals_included?: number;
  user_deals_total?: number;
  user_deals_amount?: number | null;
};

export type UserSubmissionBookedAndTargetData = {
  [period: string]: {
    [businesType: string]: {
      booked: number;
      target: number;
    };
  };
};

export type UserSubmissionBookedAndTarget = {
  requestStatus: SubmissionBookedAndTargetStatusState;
  data: UserSubmissionBookedAndTargetData;
};

export type CROUserSubmission = {
  submission_settings_id: SubmissionSettingsId; // identify the column

  user_amount?: number;

  override_amount: number | null;

  last_submission_amount?: number | null;
  last_submission_deals_amount?: number | null;
  last_submission_timestamp?: string;
  last_submission_deals_included?: number;
  last_submission_deals_total?: number;

  override_excluded_deals_ids: string[];
  override_included_deals_ids: string[];
  override_included_deals_amount: number | null;

  notes: string | null;

  delta?: 'up' | 'down'; // up/down icon
  dirty?: boolean; // exclamation icon
} & SummaryFields;

export type UserSubmission = {
  submission_settings_id: SubmissionSettingsId; // identify the column

  user_amount?: number;

  override_amount: number | null;

  override_updated_at?: string | null;
  override_last_user_submission?: SubmissionSettingsId | null;
  user_submission_id?: SubmissionSettingsId | null;

  override_excluded_deals_ids: string[];
  override_included_deals_ids: string[];
  override_included_deals_amount: number | null;

  delta?: 'up' | 'down'; // up/down icon
  dirty?: boolean; // exclamation icon
} & SummaryFields;

export type UserRow = {
  user_id: string;
  manager_id: string | null;
  name: string;
  email: string;
  profile: string | null;
  role: string;
  currency?: string;
  manager_can_override: boolean;
  submissions: (CROUserSubmission | UserSubmission)[];
};

export type SubmissionForecastResponse = {
  created_at?: string;
  submitted_at?: string;
  columns: SubmissionColumn[];
  users: UserRow[];
};

type SubmissionForecastPayload = SubmissionForecastResponse;

export interface SubmissionForecastAction extends Action {
  type: string;
  payload?: SubmissionForecastResponse;
  submit?: boolean;
  error?: string;
}

export const getSubmissionForecasts: SagaActionFunction<
  SubmissionForecastPayload,
  AxiosError<SubmissionForecastPayload>,
  SubmissionForecastAction
> = () => ({
  type: t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submissions/override/current`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.GET + t.ERROR,
    error: 'error',
  }),
  loading: () => ({
    type: t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.GET + t.LOADING,
  }),
});

interface UserBookedAndTarget {
  user_id: {
    $oid: string;
  };
  Target: number;
  Booked: number;
}

export type SubmissionBookedAndTargetResponse = {
  // key of the objet is the period which it represents
  [period: string]: {
    [businesType: string]: {
      data: UserBookedAndTarget[];
    };
  };
};

type SubmissionBookedAndTargetPayload = SubmissionBookedAndTargetResponse;

export interface SubmissionBookedAndTargetAction extends Action {
  type: string;
  payload?: SubmissionBookedAndTargetResponse;
  error?: string;
}

export const getSubmissionBookedAndTarget: SagaActionFunction<
  SubmissionBookedAndTargetPayload,
  AxiosError<SubmissionBookedAndTargetPayload>,
  SubmissionBookedAndTargetAction
> = () => ({
  type: t.CRO_OVERRIDE + t.SUBMISSION_FORECAST_BOOKED_AND_TARGET + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submissions/override/rollups_table/forecast_roll_ups_for_override`,
  saga: genericSagas.get,
  success: (payload) => ({
    type:
      t.CRO_OVERRIDE +
      t.SUBMISSION_FORECAST_BOOKED_AND_TARGET +
      t.GET +
      t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type:
      t.CRO_OVERRIDE +
      t.SUBMISSION_FORECAST_BOOKED_AND_TARGET +
      t.GET +
      t.ERROR,
    error: 'error',
  }),
  loading: () => ({
    type:
      t.CRO_OVERRIDE +
      t.SUBMISSION_FORECAST_BOOKED_AND_TARGET +
      t.GET +
      t.LOADING,
  }),
});

type DealId = string;
type OverrideAmount = number | null;

export type SubmissionSummary = {
  submission_settings_id: string;
  amount: OverrideAmount;
  excluded_deals_ids: DealId[] | null;
  included_deals_amount: OverrideAmount;
  included_deals_ids: DealId[] | null;
  notes?: string | null;
};

export type SubmissionForecastChanges = {
  user_id: string;
  submission_settings_id: string;
  submission_id: string | null;
  amount: OverrideAmount;
  excluded_deals_ids: DealId[] | null;
  included_deals_amount: OverrideAmount;
  included_deals_ids: DealId[] | null;
};

export type CopyUserSubmissionRequest = {
  submission_id: string | null;
  submission_settings_id: string;
};

export type CopyUserSubmissionResponse = {
  amount: number;
  excluded_deal: OverrideAmount;
  excluded_deal_ids: DealId[];
  included_deal: OverrideAmount;
  included_deal_ids: DealId[];
  included_deals_amount: OverrideAmount;
};

export type CopyCROSubmissionRequest = {
  submission_settings_id_from: string;
  submission_settings_id_to: string;
};

export type CopyCROSubmissionResponse = {
  data: Array<{
    included_deal_ids: DealId[];
    included_deal: number;
    excluded_deal_ids: DealId[];
    excluded_deal: number;
    included_deals_amount: OverrideAmount;
    user_id: string;
    amount?: OverrideAmount;

    //CRO only
    notes?: string | null;
  }>;
};

export type SubmissionForecastParams = {
  submit: boolean;
  summary: SubmissionSummary[];
  overrides: SubmissionForecastChanges[];
  submission_settings_id?: string;
};

export const saveSubmissionForecast: SagaActionFunction<
  SubmissionForecastPayload,
  AxiosError<SubmissionForecastPayload>,
  SubmissionForecastAction,
  SubmissionForecastParams
> = (data) => ({
  type: t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.UPDATE,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submissions/override/update`,
  data,
  saga: genericSagas.create,
  success: (payload) => ({
    type: t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.UPDATE + t.SUCCESS,
    payload: payload,
    submit: data.submit,
  }),
  error: (e) => ({
    type: t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.UPDATE + t.ERROR,
    error: 'error',
  }),
  loading: () => ({
    type: t.CRO_OVERRIDE + t.SUBMISSION_FORECAST + t.UPDATE + t.LOADING,
  }),
});

export type SubmissionForecastHistory = {
  id: string;
  created_at: string;
};

type SubmissionForecastHistoryPayload = {
  data: SubmissionForecastHistory[];
};

export interface SubmissionForecastHistoryAction extends Action {
  type: string;
  payload?: SubmissionForecastHistory[];
  error?: string;
}

export const getSubmissionForecastHistory: SagaActionFunction<
  SubmissionForecastHistoryPayload,
  AxiosError<SubmissionForecastHistoryPayload>,
  SubmissionForecastHistoryAction
> = () => ({
  type: t.CRO_OVERRIDE + t.SUBMISSION_HISTORY + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submissions/override/history`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.CRO_OVERRIDE + t.SUBMISSION_HISTORY + t.GET + t.SUCCESS,
    payload: payload.data,
  }),
  error: () => ({
    type: t.CRO_OVERRIDE + t.SUBMISSION_HISTORY + t.GET + t.ERROR,
    error: 'error',
  }),
  loading: () => ({
    type: t.CRO_OVERRIDE + t.SUBMISSION_HISTORY + t.GET + t.LOADING,
  }),
});

export const getSubmissionForecastOverrides: SagaActionFunction<
  SubmissionForecastPayload,
  AxiosError<SubmissionForecastPayload>,
  SubmissionForecastAction,
  string
> = (historyId) => ({
  type: t.CRO_OVERRIDE + t.SUBMISSION_FORECAST_OVERRIDES + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/submissions/override/history/${historyId}`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.CRO_OVERRIDE + t.SUBMISSION_FORECAST_OVERRIDES + t.GET + t.SUCCESS,
    payload: payload,
  }),
  error: (e) => ({
    type: t.CRO_OVERRIDE + t.SUBMISSION_FORECAST_OVERRIDES + t.GET + t.ERROR,
    error: 'error',
  }),
  loading: () => ({
    type: t.CRO_OVERRIDE + t.SUBMISSION_FORECAST_OVERRIDES + t.GET + t.LOADING,
  }),
});

export const setNavigatedFromRoute = (route: string) => ({
  type: t.CRO_OVERRIDE + t.SET + t.NAVIGATED_FROM,
  payload: route,
});
