import { css } from 'emotion';

export const totalsContainer = css`
  &:has(.totalsOnly) {
    padding: 15px;
    height: 100%;
    overflow: auto;
  }
  &:has(.singleValue) {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  padding: 8px 16px;
  i {
    margin-right: 8px;
  }
`;

export const totalsBarElement = css`
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 50px;
  & > i {
    font-size: 20px;
  }
`;

export const metricsContainer = css`
  display: flex;
  flex-direction: row;
`;
