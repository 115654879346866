import * as Highcharts from 'highcharts';
import { SeriesScatterOptions, TooltipPositionerPointObject } from 'highcharts';
import moment from 'moment';

import {
  DictionaryPoint,
  IProps,
} from 'components/UI/ProspectEngagement/EngagementChart/types';

export const getChartConfig = <T>(
  min: number,
  max: number,
  color: string,
  lineColor: string,
  tooltipFormatter: IProps<T>['tooltipFormatter'],
  onPointClick: IProps<T>['onPointClick'],
  series: Partial<SeriesScatterOptions>[]
): Highcharts.Options => ({
  chart: {
    type: 'scatter',
    height: '70px',
    marginRight: 20,
    backgroundColor: 'transparent',
  },
  title: {
    text: '',
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    endOnTick: true,
    lineWidth: 2,
    minRange: 1,
    minTickInterval: 1000 * 3600,
    reversed: false,
    showFirstLabel: true,
    startOnTick: true,
    tickLength: 5,
    type: 'datetime',
    labels: {
      formatter() {
        const dateTime = moment(this.value);

        return dateTime.utc().hour() ||
          dateTime.utc().minutes() ||
          dateTime.utc().second()
          ? ''
          : dateTime.format('M/D');
      },
    },
    lineColor: lineColor,
    tickColor: lineColor,
    min,
    max,
  },
  yAxis: [
    {
      showEmpty: true,
      min: 0,
      max: 50,
      visible: false,
    },
  ],
  tooltip: {
    enabled: true,
    useHTML: true,
    formatter() {
      return tooltipFormatter(
        (this.point.options.custom as DictionaryPoint<T>).item
      );
    },
    positioner(tooltipWidth: number, tooltipHeight: number, point) {
      const { plotX, plotY } = point as TooltipPositionerPointObject;
      const x: number =
        this.chart && plotX + tooltipWidth > this.chart.chartWidth
          ? plotX - tooltipWidth < -200
            ? -200
            : plotX - tooltipWidth
          : plotX;

      return { x, y: plotY - (tooltipHeight + 15) };
    },
  },
  plotOptions: {
    scatter: {
      animation: false,
      cursor: 'pointer',
      dataLabels: {
        crop: false,
        enabled: true,
        overflow: 'allow',
        format: '',
      },
      label: {
        connectorAllowed: false,
      },
      events: {
        click(e) {
          onPointClick((e.point.options.custom as DictionaryPoint<T>).item);
        },
      },
      marker: {
        radius: 6,
        states: {
          hover: {
            enabled: true,
            lineColor: 'rgb(100,100,100)',
          },
        },
      },
    },
    series: {
      stickyTracking: false,
    },
  },
  series: series.map((seriesItem) => ({
    type: 'scatter',
    color: color,
    cursor: 'pointer',
    marker: {
      symbol: 'circle',
    },
    ...seriesItem,
  })),
});
