import { css } from 'emotion';

export const fullWidth = css`
  width: 100%;
`;

export const textInput = css`
  width: 100%;
  margin-left: 2px;

  input {
    cursor: text;
  }
`;

export const inputError = css`
  border-color: var(--bu-red-400) !important;

  input {
    background-color: var(--bu-red-100);
    color: var(--bu-red-600);
    box-shadow: none !important;
  }
`;

export const container = css`
  height: 100%;

  display: flex;
  flex-direction: column;

  color: #4a4a4a;
  font-size: 14px;
  line-height: 16px;
`;

export const header = css`
  background-color: var(--bu-white);

  height: 49px;
  padding: 14px;

  color: var(--bu-gray-900);

  font-family: var(--bu-font-medium);
  font-size: 18px;
  line-height: 22px;

  border-bottom: 1px solid var(--bu-gray-400);
`;

export const mainContent = css`
  flex: 1;
`;

export const detailsContainer = css`
  padding-left: 20px;
  padding-right: 30px;

  & > .item {
    margin: 10px 0;

    display: flex;
    flex-direction: row;

    & > .lab {
      width: 185px;
      line-height: 38px;
      font-size: 12px;
    }

    & > .lab-long {
      line-height: 18px;
      font-size: 12px;
    }

    & > .lab-multipicker {
      width: 187px;
    }

    & > .control {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .ui.dropdown,
      .ui.input {
        width: 100%;
      }

      .ui.label {
        font-weight: 500;
      }

      .info {
        color: var(--bu-gray-700);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
      }

      .sub-header {
        color: var(--bu-gray-900);
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 17px;
        margin: 15px 0;
      }

      .radio-container {
        display: grid;
        grid-template-columns: 1fr 3fr;
        row-gap: 10px;

        .ui.checkbox {
          margin-right: 5px;
        }

        .label-title {
          color: var(--bu-gray-700);
        }
      }
    }
  }
`;

export const actionButtons = css`
  border-top: 1px solid var(--bu-gray-300);

  height: 60px;
  padding: 13px 22px;

  text-align: right;

  & .ui.button {
    margin: 8px;
  }
`;

export const noteParagraph = css`
  color: var(--bu-gray-700);
  line-height: 22px;
`;

export const visibilityLabel = css`
  padding-top: 16px;
  padding-bottom: 8px;
`;

export const wideButtons = css`
  width: 108px;
`;

export const graySpan = css`
  color: var(--bu-gray-700);
`;

export const errorInfo = css`
  padding-top: 6px;
  color: var(--bu-red-600);
`;
