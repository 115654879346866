import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import classNames from 'classnames';
import { css } from 'emotion';
import update from 'immutability-helper';
import difference from 'lodash/difference';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Icon, Loader, Popup } from 'semantic-ui-react';

import { actions } from 'actions';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import WrappedFilterDropdown from 'components/settings/ManageFilters/ManagePageDefaults/WrappedFilterDropdown';
import { IReduxState } from 'reducers/types';
import {
  FiltersForAPI,
  getFiltersForAPI,
  getUser,
  getSettingsFiltersAvailableByProfile,
} from 'selectors';
import {
  getSettingsFiltersAvailable,
  getFiltersState,
} from 'selectors/openFilters';
import { QueryStatus, fetchApi } from 'utils/network';

type OwnProps = {
  handleLock?: (tab: string, name: string) => void;
  handleRemove?: () => void;
  profileId?: string;
  tab: string;
};

type StateProps = {
  available: Filters.AvailableFilters;
  filters: Filters.FiltersState | null;
  filtersForApi: FiltersForAPI;
  user: {
    email: string;
    role: string;
  };
};

type DispatchProps = {
  reloadFilters: Function;
  resetToDefaults: Function;
};

type Props = OwnProps & StateProps & DispatchProps;

export const CLEAR_ICON_TIMEOUT = 3000; // msec
const API_POINT_BASE = `${process.env.REACT_APP_BACKEND_URL}/api/settings/enabled_filters`;
const API_POINT_BASE_FOR_PROFILES = `${process.env.REACT_APP_BACKEND_URL}/api/profile/profile`;

const wrapper = css`
  padding: 1rem;
`;

const panel = css`
  background-color: var(--bu-gray-200);
  margin: 0 -1rem 0px -1rem;
  position: relative;
  border-bottom: 1px solid var(--bu-gray-300);
  padding: 8px 20px 0px 20px;
  display: flex;
  flex-wrap: wrap;
`;

const buttons_container = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f4f7f8;
  margin: 0 -1rem 0px -1rem;
  padding: 10px 20px 20px 20px;

  .ui.selection.dropdown {
    height: 32px !important;
    min-height: 32px !important;
  }

  .ui.selection.visible.dropdown > .text {
    color: var(--bu-primary-500) !important;
  }
`;

const buttonResetStyle = css`
  margin-right: 20px;
`;

const actionIconContainerStyle = css`
  width: 20px;
`;

const dropdownStyle = css`
  height: 32px !important;
  margin-right: 20px !important;
  border: none !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  color: var(--bu-primary-500) !important;
  padding-top: 2px;
  cursor: pointer;
  background: transparent !important;
  transition: 0.4s;

  &:hover {
    background: var(--bu-gray-200) !important;
  }

  &.active {
    color: var(--bu-primary-500) !important;
    background: var(--bu-gray-200) !important;
  }
`;

const dropdownStubStyle = css`
  height: 29px !important;
  width: 84px;
  padding-top: 8px;
  background: transparent;
  text-align: center;
  cursor: not-allowed;

  &:hover {
    background: var(--bu-gray-200) !important;
  }

  &.active {
    color: var(--bu-primary-500) !important;
    background: var(--bu-gray-200) !important;
  }
`;

const emptyOptions = css`
  .menu {
    display: none !important;
  }
`;

const FiltersPanel: React.FC<Props> = ({
  available,
  filters,
  filtersForApi,
  handleLock,
  handleRemove: handleRemoveExternal,
  profileId,
  reloadFilters,
  resetToDefaults,
  tab,
  user,
}: Props) => {
  const { available_filters, enabled_filters } = available;

  const [dropdownValue, setDropdownValue] = useState<string[]>([]);
  const [lastSuccess, setLastSuccess] = useState<string[]>(
    enabled_filters || []
  );
  const [order, setOrder] = useState<string[]>(enabled_filters || []);
  const [reset, setReset] = useState<boolean>(false);
  const [status, setStatus] = useState<QueryStatus | null>(null);

  const abortController = new AbortController();
  const serializedEnabled = JSON.stringify(enabled_filters);
  const serializedOrder = JSON.stringify(order);

  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const queryMethod = profileId ? 'put' : 'post';
  const url = profileId
    ? `${API_POINT_BASE_FOR_PROFILES}/${profileId}/filters/${tab}/enabled_filters`
    : `${API_POINT_BASE}/${tab}`;

  const dropdownOptions = difference(available_filters, order).map((f) => ({
    key: f,
    text: f,
    value: f,
  }));

  useEffect(() => {
    setLastSuccess(enabled_filters);
  }, []);

  useEffect(() => {
    if (status === 'error') {
      timeoutId = setTimeout(() => {
        setOrder(lastSuccess);
        setStatus(null);
      }, CLEAR_ICON_TIMEOUT);
    }

    if (status === 'success') {
      timeoutId = setTimeout(() => setStatus(null), CLEAR_ICON_TIMEOUT);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [status]);

  useEffect(() => {
    if (status === 'notAsked') {
      const setData = () => {
        !profileId && reloadFilters(user);
        setLastSuccess(order);
        setDropdownValue([]);

        if (handleRemoveExternal) {
          handleRemoveExternal();
        }
      };

      fetchApi<{ filters: string[] }, {}>({
        queryMethod,
        queryParams: { filters: order },
        setData,
        setStatus,
        signal: abortController.signal,
        url,
      });
    }

    return () => {
      if (status === 'loading') {
        abortController.abort();
      }
    };
  }, [JSON.stringify(order), status]);

  const handleDrop = useCallback(() => {
    if (serializedEnabled !== serializedOrder) {
      setStatus('notAsked');
    }
  }, [serializedEnabled, serializedOrder]);

  const handleMove = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const draggable = order[dragIndex];

      const newOrder = update(order, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, draggable],
        ],
      });
      setOrder(newOrder);
    },
    [serializedOrder]
  );

  const handleRemove = useCallback((filterName: string) => {
    setOrder((prev) => prev.filter((f) => f !== filterName));
    setStatus('notAsked');
  }, []);

  const handleResetToDefaults = useCallback(() => {
    resetToDefaults({ tab });
    setReset(true);
  }, [tab]);

  const handleDropdownChange = useCallback(
    (e, { value }) => {
      setDropdownValue(value);
      setOrder((prev) => [...prev, ...value]);
      setStatus('notAsked');
    },
    [JSON.stringify(dropdownValue)]
  );

  const handleDropdownClose = useCallback(() => {
    if (dropdownValue.length) {
      setOrder((prev) => [...prev, ...dropdownValue]);
      setStatus('notAsked');
    }
  }, [JSON.stringify(dropdownValue)]);

  const handleSubmit = useCallback(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  if (!filters) {
    return null;
  }

  const isAddButtonActive = dropdownOptions.length > 0;

  return (
    <div className={wrapper}>
      <div className={panel}>
        {order.map((name, index) => {
          const filter = filters[name];
          const { group } = filter || {};

          const preset_values = group
            ? (filtersForApi[group] || {})[name]
            : filtersForApi[name];

          return (
            <WrappedFilterDropdown
              filter={{ config: filter, name }}
              index={index}
              key={`${tab}-${name}`}
              onDrop={handleDrop}
              onLock={handleLock}
              onMove={handleMove}
              onRemove={handleRemove}
              onSubmit={handleSubmit}
              partition="initialState"
              profileId={profileId}
              queryParams={JSON.stringify({
                page: tab,
                filter_name: name,
                preset_values,
              })}
              reset={reset}
              tab={tab}
            />
          );
        })}
      </div>

      <div className={buttons_container}>
        <Popup
          content={
            isAddButtonActive ? null : 'There are no more filters to be added'
          }
          disabled={isAddButtonActive}
          on="hover"
          trigger={
            isAddButtonActive ? (
              <Dropdown
                basic
                compact
                className={classNames(dropdownStyle, {
                  [emptyOptions]:
                    dropdownOptions.length === dropdownValue.length,
                })}
                closeOnChange
                floating
                multiple
                onChange={handleDropdownChange}
                onClose={handleDropdownClose}
                options={dropdownOptions}
                scrolling
                selection
                text="Add filters"
                value={dropdownValue}
              />
            ) : (
              <div className={classNames(dropdownStyle, dropdownStubStyle)}>
                Add filters
              </div>
            )
          }
        />

        <BuButton
          secondary
          size={BuControlSize.REGULAR}
          className={buttonResetStyle}
          onClick={handleResetToDefaults}
        >
          Reset to defaults
        </BuButton>

        <div className={actionIconContainerStyle}>
          {status === 'error' && <Icon color="red" name="info circle" />}
          {status === 'loading' && <Loader active inline size="mini" />}
          {status === 'success' && (
            <Icon color="green" name="check circle outline" />
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IReduxState, ownProps: OwnProps) => {
  const { email, role } = getUser(state);
  const { profileId, tab } = ownProps;

  return {
    available: profileId
      ? getSettingsFiltersAvailableByProfile(state, tab, profileId)
      : getSettingsFiltersAvailable(state, tab),
    filters: profileId
      ? getFiltersState(state, tab, 'data', profileId)
      : getFiltersState(state, tab, 'initialState'),
    filtersForApi: profileId
      ? getFiltersForAPI(state, tab, 'data', profileId)
      : getFiltersForAPI(state, tab, 'initialState'),
    user: { email, role },
  };
};

const mapDispatchToProps = (
  dispatch: Function,
  ownProps: OwnProps
): DispatchProps => {
  const { profileId } = ownProps;

  return {
    reloadFilters: actions.ui.filters.init,
    resetToDefaults: ({ tab }: { tab: string }) =>
      dispatch(actions.ui.filtersSettings.resetToDefaults({ profileId, tab })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FiltersPanel);
