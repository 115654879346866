import { getMinSizeForLayout } from './common/InteractiveGrid/InteractiveGrid.helper';
import {
  InteractiveLayout,
  InteractiveLayoutMinSizeConstraint,
} from './common/InteractiveGrid/InteractiveGrid.types';
import React from 'react';
import { Layout } from 'react-grid-layout';

import { formatMoney } from 'common/numbers';
import SyntheticFormulaPreview from 'components/UI/BuFormulaTextField/SyntheticFormulaPreview';
import {
  MONTH,
  MONTHLY,
  QUARTER,
  QUARTERLY,
  YEAR,
  YEARLY,
} from 'components/dashboard/Metrics/constants';
import {
  AGGREGATION_OPTIONS,
  AnalysisType,
  BUSINESS_TYPES_FIELD_NAMES,
} from 'components/dashboard/Metrics/constants';
import {
  BIDashboard,
  BIMetricColumn,
  BIMetrics,
  BIMetricsFilter,
  BIWidget,
  BIMetricUnion,
  BIMetricReport,
  BIWidgetPreview,
  IOption,
} from 'components/dashboard/Metrics/metrics.types';

export const isValidFilter = (filter: Partial<BIMetricsFilter>): boolean => {
  return Boolean(
    filter?.column &&
      filter?.operator &&
      filter?.value !== undefined &&
      filter?.value !== null &&
      filter?.value !== ''
  );
};

const parseMetric = (metric: BIMetrics): BIMetrics => {
  return {
    ...metric,
    id: metric.id || metric?._id,
  };
};

export const parseWidget = (widget: BIWidget): BIWidget => {
  return {
    ...widget,
    id: widget.id || widget?._id,
    metadata: {
      ...widget.metadata,
    },
  };
};

export const parseMetricList = (metricList: BIMetrics[]): BIMetrics[] => {
  return metricList.map(parseMetric);
};

export const parseWidgetList = (widgetList: BIWidget[]): BIWidget[] => {
  return widgetList.map(parseWidget);
};

const getValidFilters = (filters: BIMetrics['filters']) =>
  filters.reduce((pre: BIMetrics['filters'], filter) => {
    if (isValidFilter(filter?.and_condition?.[0]?.or_condition?.[0])) {
      pre.push(filter);
    }
    return pre;
  }, []);

export const parseSaveRedirect = (saveRedirect: string) => {
  // process the url to make sure it redirect within the same domain
  // first create a url object, then throw away the host from the url object
  // so the redirect is always within boostup
  const location = document.location;
  const url = new URL(saveRedirect, `${location.protocol}\\${location.host}`);
  return `${url.pathname}${url.search}`;
};

export const formatMetricForApi = (metric: Partial<BIMetrics>): BIMetrics => {
  // _id, created_at, updated_at will need to be removed before sending them to
  // backend. created_at, updated_at is server generated, when edit metric,
  // ui should not send it. id is just a client side short cut to metric._id['$oid']
  const validFilters = getValidFilters(metric.filters ?? []);

  const { id, _id, created_at, updated_at, ...cleanMetric } = metric;

  if (
    metric.metadata?.type === 'formula' ||
    metric.metadata?.type === 'synthetic'
  ) {
    // column and aggregation_function is not needed. the query is base on the
    // formula input (select_expression)
    const { column, aggregation_function, ...cleanMetric } = metric ?? {};

    return {
      name: cleanMetric.name ?? '',
      ...cleanMetric,
      filters: validFilters,
      // @ts-ignore temporary hack while we waiting typec decision
      _id: metric._id,
    };
  } else {
    // simple metric, force select_expression to be empty string

    // the reason is backend api decide a metric is formula / simple base on
    // select_expression === '' or not.
    // but in the UI, user can fill something in the formula input box, then
    // save the metric as a simple metric. in this case we need to set
    // select_expression to empty string so that backend treat it as simple metric

    return {
      name: cleanMetric.name ?? '',
      ...cleanMetric,
      filters: validFilters,
      select_expression: '',
      // @ts-ignore temporary hack while we waiting typec decision
      _id: metric._id,
    };
  }
};

export const formatMetricObject = (metricObject: string): string =>
  metricObject
    .split('_')
    .filter((v) => v.length > 0)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
    .join(' ');

export const getDropdownFriendlyName = (column: BIMetricColumn): string => {
  if (!column) return '';

  // Special case for Business Type it does not have prefix
  if (BUSINESS_TYPES_FIELD_NAMES.has(column.name)) return column.label;

  const object = column.name.split('.')[0];
  const objectNamePretty = formatMetricObject(object);

  return `${objectNamePretty} - ${column.label}`;
};

const filterOperatorToTextMap: Record<string, string> = {
  lt: 'is less than',
  lte: 'is less than or equal to',
  eq: 'is equal to',
  gte: 'is greater than or equal to',
  gt: 'is greater than',
  in: 'is in',
  not_in: 'is not in',
  is_null: 'is null',
  is_not_null: 'is not null',
};

export const getFilterHumanReadable = (
  filter: BIMetricsFilter,
  currencyCode: string
): string => {
  const excludeValueFieldByOperator = new Set(['is_null', 'is_not_null']);
  const label = filter.column?.label;
  const operatorText = filterOperatorToTextMap[filter.operator];
  const type = filter.column?.type;
  const value = Array.isArray(filter.value)
    ? filter.value.join(', ')
    : type === 'money'
    ? formatMoney(currencyCode, Number(filter.value))
    : filter.value;

  return `${label} ${operatorText} ${
    excludeValueFieldByOperator.has(filter.operator) ? '' : value
  }`;
};

export const getWidgetsIdsList = (widgetList: BIWidget[]): string[] =>
  widgetList.reduce<string[]>((results, widget) => {
    const id = (widget as BIWidget)?._id;
    if (typeof id === 'string') {
      results.push(id);
    }
    return results;
  }, []);

export const formatDashboardForSave = (dashboard: BIDashboard) => {
  const widgetListIds: string[] = getWidgetsIdsList(
    dashboard.widget_list as BIWidget[]
  );
  const { id, _id, created_at, updated_at, ...saveData } = dashboard;
  saveData.widget_list = widgetListIds;

  return saveData;
};

export const getPeriodPrettyPrint = (period: string): string => {
  switch (period) {
    case MONTH:
      return MONTHLY;
    case QUARTER:
      return QUARTERLY;
    case YEAR:
      return YEARLY;
    default:
      return '';
  }
};

export const getMetricDescription = (
  metric: BIMetrics,
  hasPopups: boolean = false
): string | JSX.Element => {
  if (metric.description) {
    return metric.description;
  }
  if (metric.synthetic_metric) {
    return (
      <SyntheticFormulaPreview
        formula={metric.synthetic_metric}
        hasPopups={hasPopups}
      />
    );
  } else if (metric.object === 'target') {
    return `${getPeriodPrettyPrint(metric.target_period ?? '')} ${
      metric.target_type
    } Target`;
  } else {
    const aggregationFunction = AGGREGATION_OPTIONS.find(
      (option) => option.value === metric?.aggregation_function
    )?.text;
    const column = `of ${
      !!metric?.column ? metric?.column?.label : metric?.object
    }`;

    if (aggregationFunction && column) {
      return `${aggregationFunction} ${column}`;
    }

    if (aggregationFunction) {
      return `${aggregationFunction}`;
    }

    return '';
  }
};

const formatMetricForReportWidget = (metric: BIMetricUnion): BIMetricReport => {
  return {
    filters: metric.filters,
    name: metric.name,
    metadata: metric.metadata,
    object: metric.object,
  };
};

export const formatWidgetForSaving = (
  widget: BIWidgetPreview | BIWidget
): BIWidgetPreview | BIWidget => {
  if (
    widget.metric_list &&
    Array.isArray(widget.metric_list) &&
    typeof widget.metric_list[0] === 'object'
  ) {
    if (widget.analysis_type === AnalysisType.REPORT) {
      return {
        ...widget,
        metric_list: widget.metric_list.map((m) =>
          formatMetricForReportWidget(m)
        ),
      };
    } else {
      return {
        ...widget,
        metric_list: widget.metric_list.map((el) => el._id),
      };
    }
  }
  return widget;
};

export const formatWidgetForUpdating = (widget: BIWidget): BIWidget => {
  if (
    widget.metric_list &&
    Array.isArray(widget.metric_list) &&
    typeof widget.metric_list[0] === 'object' &&
    widget.analysis_type !== AnalysisType.REPORT
  ) {
    return {
      ...widget,
      metric_list: widget.metric_list.map((el) => el._id),
    };
  }
  return widget;
};

export const formatObjectsList = (list: string[]): IOption[] =>
  list.map((object: string) => ({
    text: formatMetricObject(object),
    value: object,
  }));

export const geSizeRestrictionsForWidgetConfiguration = (
  widgetElement: BIWidget
): Pick<InteractiveLayout, 'maxW' | 'maxH' | 'minSizes'> => {
  // Max widget size
  const maxW = 4;
  const maxH = 3;

  const metricsCount = widgetElement.metric_list?.length || 0;
  const isPieChart = widgetElement.chart_type.includes('pie');
  const hasPivots =
    widgetElement.group_by.length > 0 ||
    widgetElement.analysis_type === AnalysisType.HISTORICAL;

  const isReport = widgetElement.analysis_type === AnalysisType.REPORT;

  let minSizes: InteractiveLayoutMinSizeConstraint[] = [];

  if (isPieChart) {
    minSizes = [{ minW: 1, minH: 2 }];
  } else if (isReport) {
    minSizes = [{ minW: 4, minH: 2 }];
  } else if (!hasPivots) {
    // No Pivots
    if (metricsCount <= 2) {
      minSizes = [{ minW: 1, minH: 1 }];
    } else if (metricsCount <= 4) {
      minSizes = [
        { minW: 2, minH: 1 },
        { minW: 1, minH: 2 },
      ];
    } else if (metricsCount <= 6) {
      minSizes = [
        { minW: 3, minH: 1 },
        { minW: 2, minH: 2 },
      ];
    } else if (metricsCount === 6) {
      minSizes = [
        { minW: 3, minH: 1 },
        { minW: 2, minH: 2 },
      ];
    } else {
      minSizes = [
        { minW: 4, minH: 1 },
        { minW: 2, minH: 2 },
      ];
    }
  } else {
    // With Pivots
    if (metricsCount <= 4) {
      minSizes = [{ minW: 2, minH: 2 }];
    } else if (metricsCount <= 5) {
      minSizes = [{ minW: 3, minH: 2 }];
    } else if (metricsCount <= 6) {
      minSizes = [{ minW: 3, minH: 2 }];
    } else {
      minSizes = [{ minW: 4, minH: 2 }];
    }
  }

  return { maxW, maxH, minSizes };
};

export const getLayoutForWidget = (
  widgetElement: BIWidget,
  widgetsLayout: Layout[]
): InteractiveLayout => {
  const widgetId = widgetElement._id?.toString() || '';
  const widgetLayout = widgetsLayout.find((layout) => layout.i === widgetId);

  const sizeRestriction =
    geSizeRestrictionsForWidgetConfiguration(widgetElement);

  if (!widgetLayout) {
    const nextFreeRow =
      widgetsLayout.reduce((max, item) => Math.max(max, item.y + item.h), 0) +
      1;
    return {
      i: widgetId,
      x: 0,
      y: nextFreeRow,
      w: sizeRestriction.minSizes[0].minW,
      h: sizeRestriction.minSizes[0].minH,
      ...sizeRestriction,
    };
  }

  const minSize = getMinSizeForLayout(widgetLayout, sizeRestriction.minSizes);
  // Condition in case widget restrictions change and stored width and height
  // are not longer valid
  if (minSize) {
    // If the current item width is less than the minimum width,
    // we update the layout item and placeholder width to match the minimum width.

    if (widgetLayout.w < minSize.minW) {
      widgetLayout.w = minSize.minW;
    }

    // Similarly, if the current item height is less than the minimum height,
    // we update the layout item and placeholder height to match the minimum height.
    if (widgetLayout.h < minSize.minH) {
      widgetLayout.h = minSize.minH;
    }
  }

  return {
    ...widgetLayout,
    ...sizeRestriction,
  };
};
