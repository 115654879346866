import * as selectors from '../../../selectors';
import './styles.css';
import * as moment from 'moment';
import * as R from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';

const momentDurationFormatSetup = require('moment-duration-format');

momentDurationFormatSetup(moment);

class TimeLine extends Component {
  renderSelectedTopics = () => {
    const { selectedTopics, callDetails } = this.props;
    if (!callDetails) return false;
    const { duration, segments } = callDetails;
    const selectedTopicsSegments = R.map(
      findSegmentByTimeOffset,
      selectedTopics
    );

    return selectedTopicsSegments.map(
      (t) =>
        t && (
          <span
            className="cut"
            style={{
              left: `${this.getPercentageOffset(t.start_time)}px`,
              width: `${(t.duration / duration) * 100}%`,
            }}
            key={t.start_time}
          />
        )
    );

    function findSegmentByTimeOffset(timeOffset) {
      return segments.find(
        ({ start_time, duration }) =>
          start_time >= timeOffset && timeOffset <= start_time + duration
      );
    }
  };

  renderFilteredSnippets = () => {
    const { callDetails, filteredSnippets } = this.props;
    return filteredSnippets.map((t) => (
      <span
        className="cut"
        style={{
          left: `${this.getPercentageOffset(t.start_time)}px`,
          width: `${(t.duration / callDetails.duration) * 100}%`,
        }}
        key={t.start_time}
      />
    ));
  };

  getPercentageOffset = (time) => {
    const timeline = document.getElementById('timeline'); // timeline
    if (!timeline) return 0;
    const timelineWidth = timeline.offsetWidth;
    return timelineWidth * (time / this.props.callDetails.duration);
  };

  render() {
    const { currentTime, filteredSnippets, callDetails } = this.props;

    const audio = document.getElementById('music');
    const timer = moment.duration(audio ? audio.currentTime : 0, 'seconds');
    const hours = timer.hours();
    const minutes = timer.minutes();
    const seconds = timer.seconds();

    const marks =
      filteredSnippets.length > 0
        ? this.renderFilteredSnippets()
        : this.renderSelectedTopics();

    return (
      <div id="timeline" onClick={this.handleClick} role="none">
        {marks}
        <a
          id="pButton"
          style={{ left: `${this.getPercentageOffset(currentTime)}px` }}
          className="play"
        >
          <span className="play-background" />
        </a>
        <div className="timeline-fake">
          {this.props.snippets !== null &&
            this.props.snippets.map((snippet) => (
              <div
                className={`timeline-fake-line ${
                  snippet.type === 'customer' ? 'blue' : 'turquoise'
                }`}
                style={{ width: `${snippet.percentage}%` }}
                key={`key_${snippet.start_time}`}
              />
            ))}
        </div>
        {/* <div className={s.player_timer_box}>
          <span className={s.play_timer}>
            {moment.duration(callDetails.duration, 'seconds').format()}
            {` / `}
            {`${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
              seconds < 10 ? `0${seconds}` : seconds
            }`}
          </span>
        </div> */}
      </div>
    );

    // Rendering
  }
}

const mapStateToProps = (state) => ({
  callDetails: selectors.getCallDetail(state),
  selectedTopics: selectors.getSelectedTopics(state),
  currentTime: selectors.getCurrentTimeOffset(state),
  filteredSnippets: selectors.getFilteredSnippets(state),
});

export default connect(mapStateToProps)(TimeLine);
