import * as R from 'ramda';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';

const toLowerCaseIsString = <T>(obj: T): T | string | number =>
  typeof obj === 'string' ? (obj === '-' ? 0 : R.toLower(obj)) : obj;

const pickDeltaPathIsExist =
  <T>(path: R.Path) =>
  (obj: any): T | string | undefined =>
    R.hasPath([...(path as string[]), 'value'], obj)
      ? R.path<T>([...path, 'value'], obj)
      : R.pathOr<T | string>('', path, obj);

const useTableSort = <T>(
  initSortByField?: string
): [
  string | undefined,
  Dispatch<SetStateAction<string | undefined>>,
  (list: readonly T[]) => T[]
] => {
  const [sortByField, setSortByField] =
    useState<string | undefined>(initSortByField);

  const sortBy = useMemo(() => {
    const isDescending = sortByField?.[0] === '-';

    const fieldPath = (sortByField ?? '')
      .slice(isDescending ? 1 : 0)
      .split('.');

    return R.sort<T>(
      (isDescending ? R.descend : R.ascend)(
        R.compose(toLowerCaseIsString, pickDeltaPathIsExist(fieldPath))
      )
    );
  }, [sortByField]);

  return [sortByField, setSortByField, sortBy];
};

export default useTableSort;
