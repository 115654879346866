import { MetricFiltersDescription, Title } from './styles';
import React, { useContext } from 'react';

import { UserSettingsContext } from 'components/dashboard/Metrics/contexts/UserSettingsContext';
import { getFilterHumanReadable } from 'components/dashboard/Metrics/metrics.helpers';
import { BIMetricsQueryFilter } from 'components/dashboard/Metrics/metrics.types';

interface IProps {
  filters: BIMetricsQueryFilter[];
}

export const BuMetricFiltersDescription: React.FC<IProps> = ({ filters }) => {
  const { currency: companyCurrencyCode } = useContext(UserSettingsContext);

  if (filters.length > 0) {
    return (
      <MetricFiltersDescription>
        <Title>{'Conditions:'}</Title>
        <ul>
          {filters.map(
            (filter) =>
              filter.and_condition &&
              filter.and_condition[0].or_condition[0].column && (
                <li
                  key={`${filter.and_condition[0].or_condition[0].column.name}_${filter.and_condition[0].or_condition[0].value}`}
                >
                  {getFilterHumanReadable(
                    filter.and_condition[0].or_condition[0],
                    companyCurrencyCode
                  )}
                </li>
              )
          )}
        </ul>
      </MetricFiltersDescription>
    );
  }

  return <></>;
};
