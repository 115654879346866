import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import WrappedComponent from 'components/UI/WrappedComponent';
import { Switch } from 'navigation/Switch';
import { ROUTES, SCHEMES, Scheme } from 'navigation/routes';
import { IReduxState } from 'reducers/types';
import { getDefaultLanding } from 'selectors';
import { getHiddenPages } from 'selectors/route';

const RootRedirect: React.FC = () => {
  const defaultLanding: Scheme | undefined = useSelector(getDefaultLanding);

  if (!defaultLanding) {
    return null;
  }

  return <Redirect to={defaultLanding} />;
};

const RoutesSwitch: React.FC = () => {
  const hiddenPages = useSelector<IReduxState, Scheme[]>(getHiddenPages);

  return (
    <Switch>
      <Route exact path="/" render={() => <RootRedirect />} />

      <Route
        exact
        path="/forecast"
        render={() => <Redirect to="/opportunities" />}
      />
      {hiddenPages
        .filter((path) => ROUTES[path])
        .map((path) => {
          const { exact, strict } = ROUTES[path];

          return (
            <Route
              key={path}
              exact={exact}
              strict={strict}
              path={path}
              render={() => <RootRedirect />}
            />
          );
        })}
      {SCHEMES.filter((path) => !hiddenPages.includes(path)).map((path) => {
        const { exact, strict, wrapped, component } = ROUTES[path];

        const commonProps = {
          key: path,
          path,
          exact,
          strict,
        };

        return wrapped ? (
          <Route
            {...commonProps}
            render={(props) => (
              <WrappedComponent component={component} {...props} />
            )}
          />
        ) : (
          <Route {...commonProps} component={component} />
        );
      })}
    </Switch>
  );
};

export default RoutesSwitch;
