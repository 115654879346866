import { css } from 'emotion';

export const container = css`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  align-content: stretch;
  padding-right: 0px !important;
`;

export const children = css`
  overflow: hidden;
  flex: 1;
`;

export const modalHeight = css`
  height: 80vh;
`;

export const panel = css`
  color: #000000;
  width: 100%;
  width: 380px;
  height: calc(100vh - 50px);
  overflow: auto;
  background-color: var(--bu-white);
  border-left: 1px solid var(--bu-gray-400);
  z-index: 990;
`;

export const isModal = css`
  max-height: 80vh;
`;
