import React, { useState, Dispatch, SetStateAction } from 'react';

import { WidgetHeader } from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { BaseReportWidget } from 'components/dashboard/Metrics/Widget/Report/BaseReportWidget';
import { TemplateFilters } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilters';
import { WidgetContainer } from 'components/dashboard/Metrics/Widget/widgets.styles';
import {
  BIColumnListItem,
  BIDashboardSettings,
  BIMetrics,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  widget: Partial<BIWidget>;
  metric?: Partial<BIMetrics>;
  isDashboardModal?: boolean;
  dashboardName?: string;
  dashboardSettings?: BIDashboardSettings;
  setWidget: Dispatch<SetStateAction<Partial<BIWidget>>>;
  onEditWidget?: (metricId: string) => void;
  onCloneWidget?: (metricId: string) => void;
  onRemoveWidget?: (metricId: string) => void;
  onNameClicked?: (widget: Partial<BIWidget>) => void;
  closeWidgetModal?: () => void;
}

export const ReportWidget: React.FC<Props> = ({
  widget,
  metric,
  isDashboardModal = false,
  dashboardName = '',
  dashboardSettings,
  setWidget,
  onEditWidget,
  onCloneWidget,
  onRemoveWidget,
  onNameClicked,
  closeWidgetModal,
}) => {
  const [tooltipContent, setTooltipContent] =
    useState<JSX.Element | undefined>(undefined);

  const handleUpdateOrderBy = (column: BIColumnListItem) => {
    setWidget((prev) => ({ ...prev, order_by_column: column }));
  };

  const handleTitleClicked = () => {
    onNameClicked && onNameClicked(widget);
  };

  return (
    <>
      <WidgetContainer
        key={widget._id}
        isDashboard={true}
        isMetricsPreview={false}
        isModal={isDashboardModal}
      >
        <WidgetHeader
          isDashboardWidget
          isReportViewWidget
          id={widget._id}
          name={widget.name}
          dashboardName={dashboardName}
          tooltip={tooltipContent}
          isDashboardModal={isDashboardModal}
          onEditWidget={onEditWidget}
          onCloneWidget={onCloneWidget}
          onRemoveWidget={onRemoveWidget}
          onTitleClicked={handleTitleClicked}
          closeWidgetModal={closeWidgetModal}
        />

        <TemplateFilters
          showControls={isDashboardModal}
          templateFilters={widget.template_filters ?? []}
          widgetFilters={widget.widget_filters ?? []}
          setWidget={setWidget}
        />

        <BaseReportWidget
          dashboardSettings={dashboardSettings}
          widget={widget}
          metric={metric}
          setTooltipContent={setTooltipContent}
          updateOrderBy={handleUpdateOrderBy}
        />
      </WidgetContainer>
    </>
  );
};
