import {
  getMetricNamesWithValues,
  getSyntheticMetricData,
  openSyntheticMetricDetailsModal,
  parseFormulaToMetricsIdArray,
} from './helper';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { Action } from 'typescript-fsa';

import { actions } from 'actions';
import { BIChartTypes } from 'common/enums/metrics';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import {
  CELL_EMPTY_VALUE,
  SUBTOTAL,
} from 'components/dashboard/Metrics/Widget/Table/constants';
import { SUPPORTED_DRILLDOWN_OBJECTS } from 'components/dashboard/Metrics/Widget/constants';
import {
  ACCOUNT,
  BUSINESS_TYPES_FIELD_NAMES,
  OPPORTUNITY_SPLIT,
} from 'components/dashboard/Metrics/constants';
import {
  BIDashboardSettings,
  BIMetricColumn,
  BIMetrics,
  BIWidget,
  BIWidgetColumnV2,
  BIWidgetDataV2,
  DrillDownFilter,
  DrillDownParams,
  SelectedChartValue,
  SyntheticMetricDataWidgetState,
} from 'components/dashboard/Metrics/metrics.types';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import { QueryStatus, fetchApi } from 'utils/network';

const DRILL_DOWN_ERROR_MESSAGE = 'Failed to load drill down table data';

const getPivot1ValueFromRow = (
  extraHeader: string | undefined,
  row: IRow,
  pivot1Column: BIWidgetColumnV2 | undefined
): string => {
  if (extraHeader) return extraHeader;

  const value = row[pivot1Column?.field_name ?? ''] as string;

  if (value === CELL_EMPTY_VALUE || value === SUBTOTAL) {
    return row['pivot1Value' ?? ''] as string;
  }

  return value;
};

export const getDrillDownFilters = (
  widget: Partial<BIWidget>,
  metricData: BIWidgetDataV2 | undefined,
  extraHeader: string | undefined,
  row: IRow
): DrillDownFilter[] => {
  const pivot1Column = metricData?.pivot_1?.columns.find((el) => el.is_pivot);
  const pivot2Column = metricData?.pivot_2?.columns.find(
    (el) => el.is_pivot && el.field_name !== pivot1Column?.field_name
  );

  return [
    ...(widget.group_by ?? []).map((column, idx) => ({
      column:
        column.type === 'date' && widget.time_field
          ? widget.time_field
          : column,
      operator: 'eq',
      value:
        idx === 0
          ? getPivot1ValueFromRow(extraHeader, row, pivot1Column)
          : (row[pivot2Column?.field_name ?? ''] as string),
    })),
  ];
};

const getBusinessTypeNameFromMetricFilters = (metric: BIMetrics): string => {
  let businessTypeName = '';
  metric?.filters?.forEach((f) => {
    const condition = f.and_condition?.[0].or_condition?.[0];
    if (BUSINESS_TYPES_FIELD_NAMES.has(condition?.column?.name)) {
      businessTypeName = (condition.value as string[])?.[0];
    }
  });

  return businessTypeName;
};

export const createDrillDownParams = (
  clickedMetric: BIMetrics,
  drillDownFilters: DrillDownFilter[],
  widgetFiltersBusinessType: string,
  widget: Partial<BIWidget>
): DrillDownParams => {
  const businessTypeName =
    getBusinessTypeNameFromMetricFilters(clickedMetric) ||
    widgetFiltersBusinessType;

  return {
    filters: clickedMetric?.filters ?? [],
    template_filters: widget.template_filters ?? [],
    drill_down_filters: drillDownFilters,
    order_by: [],
    offset: 0,
    // backend suppose to return all the rows, this is
    // a tmp fix so that the ui get all the records
    // api will make limit optional later
    limit: 1000000,
    duration:
      widget.group_by?.[0]?.type === 'date' ? widget.group_by[0].name : '',
    skip_business_validation: businessTypeName ? false : true,
    ...(businessTypeName && {
      business_type_name: businessTypeName,
    }),
    is_hierarchy_rollup_sum: clickedMetric.is_hierarchy_rollup_sum,
    is_cumulative_sum: clickedMetric.is_cumulative_sum,
    ...(clickedMetric.is_cumulative_sum && {
      cumulative_sum_period: clickedMetric.cumulative_sum_period,
    }),
  };
};

export const fetchRecordsAndOpenDrillDownModal = (
  dashboardSettings: BIDashboardSettings | undefined,
  drilldownParams: DrillDownParams,
  metricObject: string | undefined,
  metricName: string,
  dispatch: Dispatch<Action<PersistQueryParams>>
): void => {
  if (metricObject && SUPPORTED_DRILLDOWN_OBJECTS.includes(metricObject)) {
    //this is the first dashboard setting, but in case we add one more, we should rethink this.
    //?user_status=active
    let querySetting = '';
    if (dashboardSettings) {
      querySetting = `?user_status=${dashboardSettings.userFilter}`;
    }

    if (metricObject === OPPORTUNITY_SPLIT) {
      openModal({
        scheme: '/opportunity-split',
        persistParams: {
          apiUrl: `/api/settings/tables/opportunity_split/RevbiOpportunitySplit`,
          filters: [],
          title: metricName,
          showTotalAmount: false,
          metricObject,
          metricName,
          drilldownParams,
        },
        persistor: (params: PersistQueryParams) => {
          dispatch(actions.ui.modal.persist(params));
        },
      });
    } else {
      fetchApi<DrillDownParams, string[]>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/${metricObject}${querySetting}`,
        queryMethod: 'post',
        queryParams: drilldownParams,
        setData: (result) => {
          const data = result ?? [];

          const modalSchema = metricObject === ACCOUNT ? '/accounts' : '/deals';

          if (data.length) {
            openModal({
              scheme: modalSchema,
              persistParams: {
                apiUrl: `/api/data/${
                  metricObject === ACCOUNT ? 'accounts/' : 'deals/'
                }`,
                filters: JSON.stringify({
                  ids: data.map((id) => id),
                  skip_business_validation: drilldownParams.business_type_name
                    ? false
                    : true,
                  ...(drilldownParams.business_type_name && {
                    business_type_name: drilldownParams.business_type_name,
                  }),
                }),
                title: metricName,
                showTotalAmount: metricObject === ACCOUNT,
                metricObject,
                metricName,
              },
              persistor: (params: PersistQueryParams) => {
                dispatch(actions.ui.modal.persist(params));
              },
            });
          }
        },
        setError: (_: string | null) => {
          toast.error('Failed to load drill down table data');
        },
      });
    }
  }
};

const fetchEventsAndOpenModal = (
  drilldownParams: DrillDownParams,
  dispatch: Dispatch<Action<PersistQueryParams>>
): void => {
  fetchApi<DrillDownParams, string[]>({
    url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/calendar_event`,
    queryMethod: 'post',
    queryParams: drilldownParams,
    setData: (data) => {
      if (data.length) {
        openModal({
          scheme: '/events',
          persistParams: {
            apiUrl: `/api/data/events/`,
            filters: JSON.stringify({ ids: data.map((id) => id) }),
          },
          persistor: (params: PersistQueryParams) => {
            dispatch(actions.ui.modal.persist(params));
          },
        });
      }
    },
    setError: () => toast.error(DRILL_DOWN_ERROR_MESSAGE),
  });
};

const fetchEmailsAndOpenModal = (
  drilldownParams: DrillDownParams,
  dispatch: Dispatch<Action<PersistQueryParams>>
): void => {
  fetchApi<DrillDownParams, string[]>({
    url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/email`,
    queryMethod: 'post',
    queryParams: drilldownParams,
    setData: (data) => {
      if (data.length) {
        openModal({
          scheme: '/emails',
          persistParams: {
            apiUrl: `/api/data/emails/`,
            title: 'Emails',
            filters: JSON.stringify({ ids: data.map((id) => id) }),
          },
          persistor: (params: PersistQueryParams) => {
            dispatch(actions.ui.modal.persist(params));
          },
        });
      }
    },
    setError: () => toast.error(DRILL_DOWN_ERROR_MESSAGE),
  });
};

export const fetchWidgetData = (
  requestPayload: string,
  querySettings: string,
  widgetInfo: string,
  widgetTimeField: BIMetricColumn | undefined,
  abortController: AbortController,
  setMetricData: React.Dispatch<
    React.SetStateAction<BIWidgetDataV2 | undefined>
  >,
  setDataStatus: React.Dispatch<React.SetStateAction<QueryStatus>>,
  setPivot1: React.Dispatch<React.SetStateAction<BIMetricColumn | undefined>>,
  setPivot2: React.Dispatch<React.SetStateAction<BIMetricColumn | undefined>>
): void => {
  fetchApi<string, BIWidgetDataV2>({
    url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/v2/widgets/editor/data${querySettings}`,
    queryMethod: 'post',
    queryParams: requestPayload,
    setData: (result) => {
      setMetricData(result);

      if (result.hasOwnProperty('pivot_1')) {
        const pivot1Column = result.pivot_1?.columns.find((el) => el.is_pivot);
        if (!!pivot1Column) {
          setPivot1(
            pivot1Column?.field_name === 'week'
              ? {
                  name: `${widgetTimeField?.name}_WoW`,
                  label: `${widgetTimeField?.label}-WoW`,
                  type: 'timePeriod',
                }
              : pivot1Column?.field_name === 'month'
              ? {
                  name: `${widgetTimeField?.name}_MoM`,
                  label: `${widgetTimeField?.label}-MoM`,
                  type: 'timePeriod',
                }
              : pivot1Column?.field_name === 'quarter'
              ? {
                  name: `${widgetTimeField?.name}_QoQ`,
                  label: `${widgetTimeField?.label}-QoQ`,
                  type: 'timePeriod',
                }
              : {
                  name: pivot1Column?.field_name.replace('$', '.'),
                  label: pivot1Column?.display_name,
                  type: pivot1Column?.type,
                }
          );
        }
      }

      if (result.hasOwnProperty('pivot_2')) {
        const pivot1Column = result.pivot_1?.columns.find((el) => el.is_pivot);
        const pivot2Column = result.pivot_2?.columns.find(
          (el) => el.is_pivot && el.display_name !== pivot1Column?.display_name
        );
        if (!!pivot2Column) {
          setPivot2({
            name: pivot2Column?.field_name.replace('$', '.'),
            label: pivot2Column?.display_name,
            type: pivot2Column?.type,
          });
        }
      } else {
        setPivot2({ name: 'none', label: 'None', type: 'text' });
      }
    },
    setError: (error: string | null) => {
      setMetricData(undefined);
      toast.error(`Failed to load widget data ${widgetInfo}: ${error}`);
    },
    setStatus: setDataStatus,
    signal: abortController.signal,
  });
};

export const openDrillDownModal = (
  widget: Partial<BIWidget>,
  selectedValue: SelectedChartValue,
  clickedMetric: BIMetrics,
  widgetFiltersBusinessType: string,
  revbiEmailsDrilldownEnabled: boolean,
  revbiEventsDrilldownEnabled: boolean,
  dashboardSettings: BIDashboardSettings | undefined,
  metricData: BIWidgetDataV2 | undefined,
  availablePivots: number,
  pivot1: BIMetricColumn | undefined,
  pivot2: BIMetricColumn | undefined,
  dispatch: Dispatch<Action<PersistQueryParams>>
) => {
  const drillDownFilters = (widget.group_by ?? []).reduce(
    (accumulator: DrillDownFilter[], column, idx) => {
      if (idx === 0 && selectedValue.pivot1Id) {
        accumulator.push({
          column:
            column.type === 'date' && widget.time_field
              ? widget.time_field
              : column,
          operator: 'eq',
          value: selectedValue.pivot1Id,
        });
      }

      if (idx === 1 && selectedValue.pivot2Id) {
        accumulator.push({
          column:
            column.type === 'date' && widget.time_field
              ? widget.time_field
              : column,
          operator: 'eq',
          value: selectedValue.pivot2Id,
        });
      }

      return accumulator;
    },
    []
  );

  const drilldownParams: DrillDownParams = createDrillDownParams(
    clickedMetric,
    drillDownFilters,
    widgetFiltersBusinessType,
    widget
  );

  if (clickedMetric.object === 'email' && revbiEmailsDrilldownEnabled) {
    fetchEmailsAndOpenModal(drilldownParams, dispatch);
  }

  if (
    clickedMetric.object === 'calendar_event' &&
    revbiEventsDrilldownEnabled
  ) {
    fetchEventsAndOpenModal(drilldownParams, dispatch);
  }

  if (clickedMetric.synthetic_metric) {
    if (typeof clickedMetric.synthetic_metric !== 'string') {
      fetchRecordsAndOpenDrillDownModal(
        dashboardSettings,
        drilldownParams,
        clickedMetric.object,
        clickedMetric.name,
        dispatch
      );
      return;
    }

    const parsedClickedMetricFormulaAsArray: string[] =
      parseFormulaToMetricsIdArray(clickedMetric.synthetic_metric);

    const syntheticMetricData: SyntheticMetricDataWidgetState =
      getSyntheticMetricData(
        parsedClickedMetricFormulaAsArray,
        metricData,
        clickedMetric
      );

    let dataRow = {};
    if (availablePivots === 1) {
      const pivot1Id = selectedValue.pivot1Id;
      const key = pivot1?.name?.replaceAll('.', '$') || '';
      dataRow =
        metricData?.pivot_1?.data.find((d) => d[key] === pivot1Id) || {};
    } else if (availablePivots === 2) {
      const pivot1Id = selectedValue.pivot1Id;
      const pivot1Key = pivot1?.name?.replaceAll('.', '$') || '';
      const pivot2Id = selectedValue.pivot2Id;
      const pivot2Key = pivot2?.name?.replaceAll('.', '$') || '';
      dataRow =
        metricData?.pivot_2?.data.find(
          (d) => d[pivot1Key] === pivot1Id && d[pivot2Key] === pivot2Id
        ) || {};
    }

    openSyntheticMetricDetailsModal(
      widget.title || widget.name || 'Drill Down',
      drilldownParams,
      syntheticMetricData,
      {
        metricsValues: getMetricNamesWithValues(
          parsedClickedMetricFormulaAsArray,
          dataRow,
          syntheticMetricData
        ),
        pivot1Id: selectedValue.pivot1Id,
        pivot2Id: selectedValue.pivot2Id,
        y: Number(selectedValue.y),
      },
      dispatch
    );
  } else {
    fetchRecordsAndOpenDrillDownModal(
      dashboardSettings,
      drilldownParams,
      clickedMetric.object,
      clickedMetric.name,
      dispatch
    );
  }
};
