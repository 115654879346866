import { getColumns, getRows } from '../helpers';
import * as styles from './styles';
import axios from 'axios';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Icon, Loader, Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import {
  MappingOptions,
  ThirdPartyDatabase,
  ThirdPartyLink,
  ThirdPartySchema,
  ValidateTableModel,
  SharedDatabaseNameModel,
} from 'common/types/ThirdPartyIntegration';
import BuButton from 'components/UI/BuButton';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import { exclamation_icon } from 'components/UI/BuConfirmationPopup/styles';
import BuDropdown from 'components/UI/BuDropdown';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import TypedSearchInput from 'components/UI/TypedSearchInput';
import Tooltip from 'components/UI/common/Tooltip';
import TypedTable, {
  IColumn,
  IRow,
} from 'components/UI/common/TypedTable/TypedTable';

enum AddIntegrationStages {
  SourceSetUp,
  ReviewAndLinkFields,
}

const AddThirdPartyIntegration: React.FC = () => {
  const history = useHistory();
  const [stage, setStage] = useState<AddIntegrationStages>(
    AddIntegrationStages.SourceSetUp
  );
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);
  const [isValidationVisible, setValidationVisible] = useState(false);
  const [isSettingUpSourceLoading, setSettingUpSourceLoading] = useState(false);
  const [
    isProceedWithoutLinkedFieldsVisible,
    setProceedWithoutLinkedFieldsVisible,
  ] = useState(false);
  const [databases, setDatabases] = useState<string[]>([]);
  const [mappings, setMappings] = useState<MappingOptions | null>(null);
  const [database, setDatabase] = useState<ThirdPartyDatabase>();
  const [searchTerm, setSearchTerm] = useState('');
  const [validatedTables, setValidatedTables] = useState<ValidateTableModel[]>(
    []
  );

  const tables = useMemo(
    () =>
      database?.schemas.flatMap((schema: ThirdPartySchema) =>
        schema.tables.map((table) => ({
          databaseName: database.name,
          schemaName: schema.name,
          tableName: table.name,
          columns: table.columns,
        }))
      ),
    [JSON.stringify(database)]
  );

  const [currentDatabase, setCurrentDatabase] = useState('');
  const [currentTableIndex, setCurrentTableIndex] = useState(-1);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/third_party_integration/snowflake`
      )
      .then((res) => {
        setDatabases(
          res.data.snowflake_integrations.map(
            ({ shared_db_name }: SharedDatabaseNameModel) => shared_db_name
          )
        );
      })
      .catch(() => toast.error('Error while getting existing databases.'));
  }, []);

  const handleFirstStepNextClick = useCallback(async () => {
    const res = await validateTables();
    if (res?.status === 200) {
      setValidationVisible(true);
      setValidatedTables(res?.data.tables);
    }
  }, [currentDatabase]);

  const handleNextClick = useCallback(async () => {
    if (isValidationVisible) {
      setValidationVisible(false);
    }
    if (stage === AddIntegrationStages.SourceSetUp) {
      setSettingUpSourceLoading(true);
      try {
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/third_party_integration/snowflake`,
          {
            shared_db_names: [{ shared_db_name: currentDatabase }],
          }
        );
        await getMappings();
        await getIntegrations();
        setSettingUpSourceLoading(false);
        setCurrentTableIndex(0);
        setStage(AddIntegrationStages.ReviewAndLinkFields);
      } catch (ex) {
        setSettingUpSourceLoading(false);
        toast.error(ex.response.data.error.message[0]);
      }
    } else {
      if (
        !!tables &&
        tables[currentTableIndex].columns.some((column) => !!column.linked_to)
      ) {
        handleNextAndSaveClick();
      } else {
        setProceedWithoutLinkedFieldsVisible(true);
      }
    }
  }, [
    currentDatabase,
    stage,
    tables,
    database,
    currentTableIndex,
    isValidationVisible,
  ]);

  const handleNextAndSaveClick = useCallback(async () => {
    if (!!tables) {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/third_party_integration/snowflake/table`,
        {
          shared_db_name: tables[currentTableIndex].databaseName,
          schema_name: tables[currentTableIndex].schemaName,
          table_name: tables[currentTableIndex].tableName,
          columns: tables[currentTableIndex].columns,
        }
      );
      if (currentTableIndex !== tables.length - 1) {
        setCurrentTableIndex(currentTableIndex + 1);
        setSearchTerm('');
      } else {
        history.goBack();
      }
    }
  }, [tables, database, currentTableIndex]);

  const getMappings = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/third_party_integration/mappings`
      );
      setMappings(res.data);
    } catch (ex) {
      toast.error(ex.response.data.error.message[0]);
    }
  };

  const getIntegrations = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/third_party_integration/snowflake/database/${currentDatabase}`
      );
      setDatabase(res.data);
    } catch (ex) {
      toast.error(ex.response.data.error.message[0]);
    }
  };

  const validateTables = useCallback(async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/third_party_integration/snowflake/validate`,
        {
          shared_db_name: currentDatabase,
        }
      );
      return res;
    } catch (ex) {
      toast.error(ex.response.data.error.message[0]);
    }
  }, [currentDatabase]);

  const onCloseButtonClick = () => {
    if (stage === AddIntegrationStages.ReviewAndLinkFields) {
      setConfirmationVisible(true);
    } else {
      history.goBack();
    }
  };

  const onConfirm = () => {
    history.goBack();
  };

  const columns = useMemo(
    () => getColumns(true, !!mappings ? mappings : undefined, false),
    [mappings]
  );
  const rows = useMemo(
    () =>
      !!tables && currentTableIndex !== -1
        ? getRows(
            tables[currentTableIndex].columns.filter(
              (column) =>
                column.display_name
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                column.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                column.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
                column.linked_to?.path.some((el) =>
                  el.toLowerCase().includes(searchTerm.toLowerCase())
                )
            )
          )
        : [],
    [tables, currentTableIndex, searchTerm]
  );

  const handleChangeRow = useCallback(
    (column: IColumn, row: IRow, newValue: any) => {
      if (!!tables && !!database) {
        const { schemaName, tableName } = tables[currentTableIndex];
        const schema = database.schemas.find((s) => s.name === schemaName);
        const table = schema?.tables.find((t) => t.name === tableName);
        const field = table?.columns.find((f) => f.name === row['name']);
        if (!!field) {
          if (column.field === 'display_name') {
            field.display_name = newValue as string;
          }
          if (column.field === 'link') {
            field.linked_to = newValue as ThirdPartyLink;
          }
          setDatabase({ ...database });
        }
      }
    },
    [JSON.stringify(database), currentTableIndex, tables]
  );

  const handleBackClick = useCallback(() => {
    if (currentTableIndex > 0) {
      setCurrentTableIndex(currentTableIndex - 1);
    }
  }, [currentTableIndex]);

  return (
    <div className={styles.addThirdPartyContainer}>
      <div className={styles.header}>
        <div onClick={onCloseButtonClick}>
          <BuIcon name={BoostUpIcons.ArrowLeft}></BuIcon>
        </div>
        &nbsp;Add New Integration
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.stageContainer}>
          <div className={styles.stagesLine}></div>
          <div
            className={classNames(styles.stage, {
              selected: stage === AddIntegrationStages.SourceSetUp,
            })}
          >
            <div
              className={classNames(styles.stageMark, {
                selected: stage === AddIntegrationStages.SourceSetUp,
                completed: stage === AddIntegrationStages.ReviewAndLinkFields,
              })}
            ></div>
            <span>Source set up</span>
          </div>
          {tables?.map((table, index) => (
            <div
              key={table.tableName}
              className={classNames(styles.stage, {
                selected: stage === AddIntegrationStages.ReviewAndLinkFields,
              })}
            >
              <div
                className={classNames(styles.stageMark, {
                  selected:
                    stage === AddIntegrationStages.ReviewAndLinkFields &&
                    currentTableIndex === index,
                  completed:
                    stage === AddIntegrationStages.ReviewAndLinkFields &&
                    currentTableIndex > index,
                  exclamation:
                    stage === AddIntegrationStages.ReviewAndLinkFields &&
                    currentTableIndex > index &&
                    table.columns.every((column) => !column.linked_to),
                })}
              >
                <Popup
                  inverted
                  basic
                  hoverable
                  hideOnScroll
                  position={'bottom right'}
                  trigger={
                    stage === AddIntegrationStages.ReviewAndLinkFields &&
                    currentTableIndex > index &&
                    table.columns.every((column) => !column.linked_to) && (
                      <Icon name="exclamation" className={styles.exclamation} />
                    )
                  }
                  content="You have not linked any fields on this table"
                  offset={[0, 0]}
                />
              </div>
              <span>Review & Link Table {index + 1}</span>
            </div>
          ))}
        </div>
        <div className={styles.addDatabasesContainer}>
          {stage === AddIntegrationStages.SourceSetUp && (
            <>
              <div className={styles.pageContainer}>
                <span className={styles.label}>Source Type</span>
                <BuDropdown
                  label={'Snowflake'}
                  className={styles.dropdownSelector}
                  children={{}}
                  readOnly
                ></BuDropdown>
                <span className={styles.label}>
                  Talk to our CSM to complete the Snowflake setup. Once
                  Snowflake setup is complete, link it with BoostUp by filling
                  following details. For multiple Databases add multiple
                  entries.
                </span>
                {databases.map((database, index) => (
                  <div
                    className={styles.existingDatabaseContainer}
                    key={database}
                  >
                    <span className={styles.databaseIdLabel}>
                      <b>{index + 1}.</b> Database ID
                    </span>
                    <div className={styles.databaseInputContainer}>
                      <BuInput
                        className={styles.databaseIdInput}
                        value={database}
                        disabled
                        readOnly
                        placeholder="Type e.g. Company-snowflake-1234940"
                        onChange={(event) => {}}
                      ></BuInput>
                    </div>
                  </div>
                ))}
                <>
                  <span className={styles.databaseIdLabel}>
                    <b>{databases.length + 1}.</b> Database ID (You can find it
                    in Snowflake account)
                  </span>
                  <div className={styles.databaseInputContainer}>
                    <BuInput
                      className={styles.databaseIdInput}
                      value={currentDatabase}
                      placeholder="Type e.g. Company-snowflake-1234940"
                      onChange={(event) =>
                        setCurrentDatabase(event.target.value)
                      }
                    ></BuInput>
                  </div>
                </>
              </div>
            </>
          )}
          {stage === AddIntegrationStages.ReviewAndLinkFields && (
            <>
              <div className={styles.pageContainer}>
                <span className={styles.label}>
                  Review the tables and fields we got from your Snowflake
                  account. Link the fields to other BoostUp/CRM/Snowflake fields
                  to define the relationships.
                </span>
              </div>
              <div className={styles.controlsContainer}>
                <span>
                  {!!tables &&
                    `${tables[currentTableIndex].databaseName}, ${tables[currentTableIndex].schemaName}, ${tables[currentTableIndex].tableName} (${tables[currentTableIndex].columns.length} fields)`}
                </span>
                <TypedSearchInput
                  name="third-party-integration"
                  onChange={(value) => setSearchTerm(value)}
                  value={searchTerm}
                ></TypedSearchInput>
              </div>
              {tables && (
                <TypedTable
                  columns={columns}
                  data={rows}
                  onSort={() => {}}
                  fullscreen
                  onRender={() => {}}
                  onChange={handleChangeRow}
                  rowClassName={(row) =>
                    classNames({
                      topRow: row.isTopRow,
                      empty: row.isEmpty,
                      noEdit: row.disableEdit,
                    })
                  }
                />
              )}
            </>
          )}
          <div className={styles.footerContainer}>
            {currentTableIndex > 0 && (
              <BuButton onClick={handleBackClick} secondary>
                Back
              </BuButton>
            )}
            <BuButton
              className={styles.nextButton}
              disabled={
                !currentDatabase && stage === AddIntegrationStages.SourceSetUp
              }
              onClick={
                currentTableIndex >= 0
                  ? handleNextClick
                  : handleFirstStepNextClick
              }
            >
              {currentTableIndex >= 0 ? 'Save & Next' : 'Next'}
            </BuButton>
          </div>
        </div>
      </div>
      <BuConfirmationPopup
        showControls={false}
        headerText="Please wait, we're setting up the source!"
        isOpen={isSettingUpSourceLoading}
        onClose={() => {}}
        onConfirm={() => {}}
        icon={
          <Loader size="massive" style={{ marginRight: '5px' }} active inline />
        }
      >
        <p>
          This may take few minutes as we are setting up the source connection
          and fetching the fields. Don't Refresh or press Back button while
          processing
        </p>
      </BuConfirmationPopup>
      <BuConfirmationPopup
        cancelText="Go Back"
        confirmText="Proceed"
        headerText="No fields linked on this table"
        isOpen={isProceedWithoutLinkedFieldsVisible}
        onClose={() => setProceedWithoutLinkedFieldsVisible(false)}
        onConfirm={() => {
          setProceedWithoutLinkedFieldsVisible(false);
          handleNextAndSaveClick();
        }}
        icon={
          <Icon
            name="exclamation circle"
            color="orange"
            className={exclamation_icon}
          />
        }
      >
        <p>
          Are you sure you want to proceed without adding the links for fields?
          Having links to the other fields help us define the relationship
          between the tables for better results.
        </p>
      </BuConfirmationPopup>
      <BuConfirmationPopup
        cancelText="No"
        confirmText="Yes"
        headerText="Confirmation Required!"
        isOpen={isConfirmationVisible}
        onClose={() => setConfirmationVisible(false)}
        onConfirm={onConfirm}
      >
        <p>Unsaved data will be lost.</p>
      </BuConfirmationPopup>
      <BuConfirmationPopup
        cancelText="Go Back"
        confirmText="Proceed"
        headerText="Validate the Tables to Proceed."
        isOpen={isValidationVisible}
        onClose={() => setValidationVisible(false)}
        onConfirm={handleNextClick}
        size={'small'}
        icon={
          <Icon
            name="check circle"
            color="green"
            className={styles.checkModalIcon}
          />
        }
      >
        <p>
          We found following tables on the selected database. Please review the
          same before procceding further. You will be able to link fields for
          each table on next step.
        </p>
        <div className={styles.tableReviewContainer}>
          {validatedTables.map((el, index) => (
            <div key={el.shared_db_name} className={styles.tableRow}>
              <span>{`${index + 1}. ${el.shared_db_name}, ${el.schema_name}, ${
                el.table_name
              } (${el.fields} ${el.fields === 1 ? 'field' : 'fields'})`}</span>
            </div>
          ))}
        </div>
      </BuConfirmationPopup>
    </div>
  );
};

export default AddThirdPartyIntegration;
