import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as metricActions from 'actions/revbi/metrics';
import { Conditions } from 'components/dashboard/Metrics/Create/Conditions/Conditions';
import { ConditionsContainer } from 'components/dashboard/Metrics/Create/FunnelConditions/styles';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import {
  AggregationFunction,
  BIMetricSimpleNewborn,
  BIMetricUnion,
  BIWidget,
  MetricType,
} from 'components/dashboard/Metrics/metrics.types';
import * as metricSelectors from 'selectors/revbi/metrics';

interface OwnProps {
  widget: BIWidget;
  setWidget: (widget: BIWidget) => void;
}

const FunnelConditions: React.FC<OwnProps> = ({ widget, setWidget }) => {
  const dispatch = useDispatch();

  const [metric, setMetric] = useState<BIMetricSimpleNewborn>({
    analysis_type: AnalysisType.FUNNEL,
    aggregation_function: AggregationFunction.Sum,
    filters: widget.funnel_conditions ?? [],
    metadata: { type: MetricType.Simple },
    name: 'Funnel',
    object: 'opportunity',
  });
  const conditionColumnList = useSelector(metricSelectors.getColumnFiltersList);
  const opportunityColumnList = conditionColumnList.filter((item) =>
    item.name.startsWith('opportunity.')
  );

  useEffect(() => {
    dispatch(metricActions.fetchColumnFieldsFilter(['opportunity']));
  }, []);

  useEffect(() => {
    if (
      metric &&
      metric.filters.every((f) =>
        f.and_condition?.every((condition) =>
          condition?.or_condition?.every((cond) => cond.value && cond.operator)
        )
      )
    ) {
      setWidget({
        ...widget,
        funnel_conditions: metric.filters,
      });
    }
  }, [metric.filters]);

  if (metric === undefined) {
    return <></>;
  }

  return (
    <ConditionsContainer>
      <Conditions
        title="Entry Conditions"
        description="Set of filters that will be applied always and using the live data values of the objects."
        metric={metric}
        customColumnList={opportunityColumnList}
        customUpdateMetric={(metric: BIMetricUnion) =>
          setMetric(metric as BIMetricSimpleNewborn)
        }
      />
    </ConditionsContainer>
  );
};

export default FunnelConditions;
