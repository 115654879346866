import { css } from 'emotion';

export const hidden = css`
  display: none;
`;

export const editableBox = css`
  display: flex;
  position: relative;
  align-items: center;
  height: 33px;
  width: 50%;
`;

export const displayValue = css`
  padding: 10px 6px;
  font-weight: 100;
  cursor: default;
  margin-left: -6px;

  @media (max-width: 1370px) {
    height: 26px;
    font-size: 10px;
  }
`;

export const editable = css`
  border: 1px solid transparent;
  cursor: pointer;

  tr:hover & {
    border-color: #edf0f2;
    cursor: pointer;
    background-color: #fff;

    &:hover {
      border-color: #9b9b9b;
    }
  }

  &.loading {
    background-color: #fffaca !important;
  }

  &.success {
    background-color: #cff6d7 !important;
  }

  &.error {
    background-color: #fce3dc !important;
  }
`;

export const inputWrapper = css`
  cursor: pointer;
  height: 33px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
  margin-left: -5px;

  input {
    width: -webkit-fill-available;
    height: 100%;
    padding: 0px 5px 0px;
    color: #444444;
    font-weight: 100;
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const textTruncate = css`
  width: auto;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
