import * as transcriptActions from '../../../actions/transcriptActions';
import { notNullAndEmpty } from '../../../common/utils';
import * as selectors from '../../../selectors';
import { AnalyticsTracker } from '../../common/analyticsUtils';
import * as service from './service';
import * as s from './styles.js';
import TimeLine from './timeLine';
import TrackSpeed from './trackSpeed';
import $ from 'jquery';
import __ from 'lodash';
import * as moment from 'moment';
import * as _ from 'ramda';
import React, { Component } from 'react';
import { connect } from 'react-redux';

const momentDurationFormatSetup = require('moment-duration-format');

momentDurationFormatSetup(moment);

class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timelineOffset: '0px',
    };
  }

  componentDidMount() {
    const audio = document.getElementById('music');
    audio.addEventListener('timeupdate', this.onTimeUpdate, false);
  }

  componentDidUpdate({ selectedTopics, updateState }) {
    if (
      selectedTopics !== this.props.selectedTopics &&
      this.props.selectedTopics.length > 0
    ) {
      const audio = document.getElementById('music');
      audio.currentTime = this.props.selectedTopics[0];
      updateState('isPlaying', true);
      audio.play();
    }
  }

  handleClick = (e) => {
    const parrentOffsetX = $(this.refs.containerAudio).offset().left;
    const relativeClickedOffsetX = e.clientX - parrentOffsetX;
    const percentage =
      (relativeClickedOffsetX / this.refs.containerAudio.offsetWidth) * 100;
    this.handleChangeTimeline(percentage);
  };

  onTimeUpdate = () => {
    const audio = document.getElementById('music');
    const timeline = document.getElementById('timeline'); // timeline
    if (!timeline) return false;
    const {
      callDetail: { duration, segments },
      selectedSnippet,
      updateState,
    } = this.props;
    const timelineWidth = timeline.offsetWidth;
    const playPercent = timelineWidth * (audio.currentTime / duration);
    this.setState({ timelineOffset: `${playPercent}px` });
    updateState('currentTimeOffset', audio.currentTime);
    const newSelectedSnippet = this.getSnippetAtTimeOffset(
      segments,
      audio.currentTime
    );
    if (newSelectedSnippet.index !== selectedSnippet.index) {
      updateState('selectedSnippet', newSelectedSnippet);
    }
  };

  handleChangeTimeline = (timePercentage) => {
    const {
      callDetail: { duration },
      updateState,
    } = this.props;
    const audio = document.getElementById('music');
    const newTime = (timePercentage * duration) / 100;
    audio.currentTime = newTime;
    const timeline = document.getElementById('timeline'); // timeline
    if (timeline) {
      const timelineWidth = timeline.offsetWidth;
      const playPercent = timelineWidth * (newTime / duration);
      this.setState({ timelineOffset: `${playPercent}px` }, () => audio.play());
      updateState('currentTimeOffset', newTime);
      updateState('isPlaying', true);
    }
  };

  prepareSnippetsForTimeline = () => {
    const { callDetail } = this.props;
    return (
      callDetail &&
      callDetail.segments &&
      callDetail.segments.map((snippet) => ({
        type: snippet.speaker_type,
        percentage: (snippet.duration / callDetail.duration) * 100,
        start_time: snippet.start_time,
      }))
    );
  };

  getSnippetAtTimeOffset = (snippets, timeOffset) => {
    if (notNullAndEmpty(snippets)) {
      for (let i = 0; i < snippets.length; i++) {
        const { start_time } = snippets[i];
        const end_time = start_time + snippets[i].duration;
        if (start_time <= timeOffset && timeOffset <= end_time) {
          return { snippet: snippets[i], index: i };
        }
        if (timeOffset < start_time && i > 0) {
          return { snippet: snippets[i - 1], index: i - 1 };
        }
      }
      return { snippet: snippets[0], index: 0 };
    }
    return { snippet: null, index: null };
  };

  handleResetSearch = () => {
    const { updateState } = this.props;
    updateState('searchText', '');
    updateState('searchIndex', null);
    updateState('filteredSnippets', []);
  };

  onPrevious = () => {
    const {
      selectedTopics,
      formattedFilteredSnippets,
      updateState,
      searchIndex,
    } = this.props;
    const audio = document.getElementById('music');
    const isSearch = formattedFilteredSnippets.length > 0;
    const fragments = isSearch ? formattedFilteredSnippets : selectedTopics;
    if (fragments.length > 0) {
      const nextTopicIndex = fragments.findIndex((t) => t > audio.currentTime);
      if (nextTopicIndex === -1) {
        audio.currentTime =
          fragments[fragments.length > 2 ? fragments.length - 2 : 0];
      } else {
        audio.currentTime =
          fragments[
            nextTopicIndex > 1 ? nextTopicIndex - 2 : fragments.length - 1
          ];
      }
      if (isSearch) {
        updateState(
          'searchIndex',
          searchIndex === 0 ? fragments.length - 1 : searchIndex - 1
        );
      }
    } else {
      audio.currentTime -= 10; // go backward by 10 seconds
    }

    AnalyticsTracker.event(this.props, {
      category: 'Call Insights',
      action: 'Search Results',
      label: 'Go Previous',
    });
  };

  onNext = () => {
    const { selectedTopics, formattedFilteredSnippets, updateState } =
      this.props;
    const audio = document.getElementById('music');
    const isSearch = formattedFilteredSnippets.length > 0;
    const fragments = isSearch ? formattedFilteredSnippets : selectedTopics;

    if (fragments.length > 0) {
      const nextTopic =
        fragments.find((t) => t > audio.currentTime) || fragments[0];
      audio.currentTime = nextTopic;
      if (isSearch) {
        const index = fragments.findIndex((t) => t > audio.currentTime);
        updateState(
          'searchIndex',
          index === -1 ? fragments.length - 1 : index - 1
        );
      }
    } else {
      audio.currentTime += 10; // go forward by 10 seconds
    }
    AnalyticsTracker.event(this.props, {
      category: 'Call Insights',
      action: 'Search Results',
      label: 'Go Next',
    });
  };

  render() {
    const {
      callDetail,
      currentTimeOffset,
      activeTopic,
      searchText,
      filteredSnippets,
      isPlaying,
      callDetails,
    } = this.props;

    const audio = document.getElementById('music');
    const timer = moment.duration(audio ? audio.currentTime : 0, 'seconds');
    const hours = timer.hours();
    const minutes = timer.minutes();
    const seconds = timer.seconds();

    return (
      <div className={s.container}>
        <div className={s.audioplayer__audiotrack_parent}>
          <span
            className={s.play_background_line}
            style={{
              width: `${
                callDetail &&
                service.getPlayingProgressWidth(
                  currentTimeOffset,
                  callDetail.duration
                )
              }px`,
            }}
          />
          <div
            className="audioplayer__audiotrack"
            ref="containerAudio"
            onClick={(e) => {
              this.handleClick(e);
              AnalyticsTracker.event(this.props, {
                category: 'Call Insights',
                action: 'Audio Timeline',
                label: 'Audio Timeline Seek',
              });
            }}
          >
            <audio id="music">
              <source
                src={callDetail && _.prop('audio_url', callDetail)}
                type="audio/wav"
              />
              <track
                kind="subtitles"
                label="English Subtitles"
                src="../chat-box/assets/audio/vocalo-demo-audio.vtt"
                srcLang="en"
                default=""
              />
            </audio>
            <TimeLine
              snippets={this.prepareSnippetsForTimeline()}
              handleChangeTimeline={(e) => {
                this.handleChangeTimeline(e);
                AnalyticsTracker.event(this.props, {
                  category: 'Call Insights',
                  action: 'Audio Timeline',
                  label: 'Audio Timeline Seek',
                });
              }}
            />
            <div id="seek-block" />
          </div>
        </div>
        <div className={s.audioplayer__controls}>
          <div className={s.player_timer}>
            {!__.isNil(callDetails) && (
              <span className={s.play_timer}>
                <div className={s.play_timer_start}>
                  {`${hours < 10 ? `0${hours}` : hours}:${
                    minutes < 10 ? `0${minutes}` : minutes
                  }:${seconds < 10 ? `0${seconds}` : seconds}`}
                </div>
                <div className="text-gray">
                  {` / `}
                  {moment.duration(callDetails.duration, 'seconds').format()}
                </div>
              </span>
            )}
          </div>
          {/* {activeTopic === '' && (
            <div className={s.participants}>
              {!__.isEmpty(callDetail) &&
                __.some(callDetail.segments, ['speaker_type', 'customer']) && (
                  <div className={s.participant}>
                    <span style={{ background: '#6772e5' }} />
                    Customer
                  </div>
                )}
              {!__.isEmpty(callDetail) &&
                __.some(callDetail.segments, ['speaker_type', 'company']) && (
                  <div className={s.participant}>
                    <span style={{ background: '#fabb15' }} />
                    Company
                  </div>
                )}
            </div>
          )} */}
          {/* {activeTopic !== '' && !searchText && (
            <div className="btn-topics">
              <span className="btn-topics-name">Topic:</span>
              <a className="btn-topics-link" href="#">
                {activeTopic}
              </a>
              <Icon
                className="btn-topics-close"
                name="close"
                onClick={e => {
                  this.props.resetActiveTopic(e);
                  AnalyticsTracker.event(this.props, {
                    category: 'Call Insights',
                    action: 'Audio Timeline',
                    label: 'Reset Active Topic'
                  });
                }}
              />
            </div>
          )} */}
          {searchText !== null && filteredSnippets.length > 0 && (
            <div className="btn-topics">
              <span className="btn-topics-name">Playing Topic:</span>
              <a className="btn-topics-link" href="#">
                {searchText}
              </a>
              <a
                className="callTranscript__tags-item-close"
                onClick={(e) => {
                  this.handleResetSearch(e);
                  AnalyticsTracker.event(this.props, {
                    category: 'Call Insights',
                    action: 'Audio Timeline',
                    label: 'Reset Search',
                  });
                }}
              />
            </div>
          )}
          <div className={s.audio_controls}>
            <a
              href="javascript:;"
              className={s.prev_track}
              onClick={() => {
                service.backward();
                AnalyticsTracker.event(this.props, {
                  category: 'Call Insights',
                  action: 'Audio Timeline',
                  label: 'Go Backward',
                });
              }}
            >
              Skip to Prev
            </a>

            {isPlaying ? (
              <a
                href="javascript:;"
                className={s.pause_icon}
                onClick={() => {
                  service.pause();
                  AnalyticsTracker.event(this.props, {
                    category: 'Call Insights',
                    action: 'Audio Timeline',
                    label: 'Pause',
                  });
                }}
              >
                <i className="" />
              </a>
            ) : (
              <a
                href="javascript:;"
                className={s.play_icon}
                onClick={() => {
                  service.play();
                  AnalyticsTracker.event(this.props, {
                    category: 'Call Insights',
                    action: 'Audio Timeline',
                    label: 'Play',
                  });
                }}
              />
            )}

            <a
              href="javascript:;"
              className={s.next_track}
              onClick={() => {
                service.forward();
                AnalyticsTracker.event(this.props, {
                  category: 'Call Insights',
                  action: 'Audio Timeline',
                  label: 'Go Forward',
                });
              }}
            >
              Skip to Next
            </a>
          </div>
          <TrackSpeed />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  callDetails: selectors.getCallDetail(state),
  isPlaying: selectors.getPlayingState(state),
  callDetail: selectors.getCallDetail(state),
  playbackRate: selectors.getPlaybackRate(state),
  selectedTopics: selectors.getSelectedTopics(state),
  selectedSnippet: selectors.getSelectedSnippet(state),
  filteredSnippets: selectors.getFilteredSnippets(state),
  searchIndex: selectors.getSearchIndex(state),
  searchText: selectors.getSearchText(state),
  formattedFilteredSnippets: selectors.getFormattedFilteredSnippets(state),
  activeTopic: selectors.getActiveTopic(state),
  currentTimeOffset: selectors.getCurrentTimeOffset(state),
  isAdmin: selectors.isAdmin(state),
});

const mapDispatchToProps = {
  updateState: transcriptActions.createTranscriptAction.updateState,
  resetActiveTopic: transcriptActions.createTranscriptAction.resetActiveTopic,
  addTag: transcriptActions.doAddTag,
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioPlayer);
