import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import {
  ButtonsConfig,
  ButtonTypes,
} from 'components/UI/common/TypedTable/renderers/ActionCell';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import {
  IGetColumnsConfig,
  TableName,
  ToastType,
} from 'components/dashboard/RepsRecommendations/types';

const getDismissHandler = (dismiss: (row: IRow) => void) => (
  column: IColumn,
  row: IRow
): void => {
  dismiss(row);

  AnalyticsTracker.event(
    {
      row,
    },
    {
      action: `Dismiss recommendation ${row.id}`,
      category: 'Reps Recommendation',
      label: 'Recommendations dismissed',
    }
  );
};

export const getComposeDismissButtonsConfig = ({
  dismiss,
}: IGetColumnsConfig): ButtonsConfig => [
  {
    params: {
      clickHandler: getDismissHandler(dismiss),
      title: 'Dismiss',
    },
    type: ButtonTypes.BUTTON,
  },
];

export const abortControllers: {
  [key in TableName]: {
    [key: string]: AbortController;
  };
} = {
  'contact-follow-up': {},
  'email-follow-up': {},
  'meeting-follow-up': {},
  'upcoming-meeting-no-agenda': {},
  'upcoming-meeting-no-rsvp': {},
} as const;

export const selectDismissalMessage: {
  [key in ToastType]: string;
} = {
  error: 'Recommendation dismiss failed',
  success: 'Recommendation successfully dismissed permanently',
} as const;

export const selectTableTitle: {
  [key in TableName]: string;
} = {
  'contact-follow-up': 'Contacts to follow up on',
  'email-follow-up': 'Emails to follow up on',
  'meeting-follow-up': 'Meetings to follow up on',
  'upcoming-meeting-no-agenda': 'Meetings with no agenda',
  'upcoming-meeting-no-rsvp': 'Meetings with no RSVP',
} as const;
