import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { TemplateFilterPreview } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/TemplateFilterPreview';
import {
  FilterBarELement,
  FiltersContainer,
  FiltersDropdownsContainer,
} from 'components/dashboard/Metrics/Widget/TemplateFilters/styles';
import {
  BIMetricColumn,
  BIMetricsFilter,
  BIWidget,
  DateOption,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  isDateFirstPivot?: boolean;
  templateFilters?: BIMetricsFilter[];
  widgetFilters?: BIMetricColumn[];
  relativeDateFilterOptions?: DateOption[];
  timeInterval?: string;
  timePeriod?: string;
  targetPeriod?: string;
  updateWidget: (widget: Partial<BIWidget>) => void;
  widget: Partial<BIWidget>;
}

export const TemplateFiltersPreview: React.FC<Props> = ({
  isDateFirstPivot = false,
  templateFilters = [],
  widgetFilters = [],
  relativeDateFilterOptions = [],
  timeInterval,
  timePeriod,
  targetPeriod,
  updateWidget,
  widget,
}) => (
  <>
    {!!widgetFilters?.length && (
      <FiltersContainer showControls data-testing="filters-container">
        <FilterBarELement>
          <BuIcon name={BoostUpIcons.Filter} color="var(--bu-gray-600)" />
        </FilterBarELement>
        <FiltersDropdownsContainer>
          {widgetFilters.map((widgetFilter, idx) => {
            if (widgetFilter && widgetFilter.name && widgetFilter.type) {
              return (
                <FilterBarELement
                  // please do not remove the `${widgetFilters.length}` for now it force an updates the widget referenced inside the updateWidget
                  key={`${widgetFilter.type}_${widgetFilter.name}_${widgetFilter.label}_${widgetFilters.length}`}
                  data-testing={`filter-bar-element-${idx}`}
                >
                  <TemplateFilterPreview
                    isDateFirstPivot={isDateFirstPivot}
                    widgetFilter={widgetFilter}
                    templateFilters={templateFilters}
                    relativeDateFilterOptions={relativeDateFilterOptions}
                    timeInterval={timeInterval}
                    timePeriod={timePeriod}
                    targetPeriod={targetPeriod}
                    updateWidget={updateWidget}
                    widget={widget}
                  />
                </FilterBarELement>
              );
            }
          })}
        </FiltersDropdownsContainer>
      </FiltersContainer>
    )}
  </>
);
