import clx from 'classnames';
import { css } from 'emotion';
import moment from 'moment';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import Tooltip from 'components/UI/common/Tooltip';

const COMPONENT_TESTING_NAME = 'RefreshAction';

const BUTTON_SIZE = 30;

const styles = {
  box: css`
    margin: 0 10px;
    display: flex;
    align-items: center;
  `,
  lastUpdate: css`
    font-family: var(--bu-font-regular);
    cursor: default;
    font-size: 12px;
    color: var(--bu-gray-700);
    margin-left: 10px;
  `,
  button: css`
    cursor: pointer;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${BUTTON_SIZE}px;
    width: ${BUTTON_SIZE}px;
    color: var(--bu-color-buying_team_email);
    background: var(--bu-white);
    border: 1px solid var(--bu-gray-500);
    border-radius: 4px;
    transition: 0.4s;
    &:hover {
      background: #edf0f2;
    }
    &.load {
      i {
        animation: spin 3s linear 0s infinite;
        display: inline-block;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  `,
};

type IBuButtonRefreshProps = {
  onClick: () => void;
  cacheDate: string;
  status: boolean;
  message?: string;
  showLastUpdatedMessage?: boolean;
} & Common.DataTestingType;

const BuButtonRefresh: React.FC<IBuButtonRefreshProps> = ({
  onClick,
  cacheDate,
  status,
  message = 'Update data',
  testingLabel = '',
  showLastUpdatedMessage = true,
}) => {
  const componentDataTestingKey = testingLabel
    ? `${testingLabel}-${COMPONENT_TESTING_NAME}`
    : COMPONENT_TESTING_NAME;
  const now = moment();
  const since = cacheDate && moment(cacheDate);
  const isSameDay = since && now.isSame(since, 'day');
  const sinceDay =
    !isSameDay && since ? `on ${since.format('MMM DD')}` : 'Today';
  const time = since
    ? since.format('h.mmA').toLocaleLowerCase()
    : now.format('h.mmA').toLocaleLowerCase();
  const lastUpdatedMessage = `Updated ${sinceDay} at ${time}`;
  const displayLasUpdatedMessageAsTooltip = !showLastUpdatedMessage;

  return (
    <div className={styles.box} data-testing={componentDataTestingKey}>
      <Tooltip
        position="bottom right"
        tooltip={
          displayLasUpdatedMessageAsTooltip ? lastUpdatedMessage : message
        }
      >
        <button
          onClick={onClick}
          className={clx(styles.button, { load: status })}
          type="button"
          data-testing={`${componentDataTestingKey}-Btn`}
        >
          <BuIcon name={BoostUpIcons.RefreshIcon} />
        </button>
      </Tooltip>
      <p
        data-testing={`${componentDataTestingKey}-Date`}
        className={styles.lastUpdate}
      >
        {showLastUpdatedMessage && lastUpdatedMessage}
      </p>
    </div>
  );
};

export default BuButtonRefresh;
