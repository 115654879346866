import React, { useEffect } from 'react';

import { DateOperatorOption } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/types';
import { DropdownCalendar } from 'components/dashboard/Metrics/common/Calendar/Calendar';
import { Checkbox } from 'components/dashboard/Metrics/common/Calendar/TimeFilter/TabsForFilter/types';
import { ALL_TIME_VALUE } from 'components/dashboard/Metrics/constants';
import { useTimeOptions } from 'components/dashboard/Metrics/hooks/hooks';
import {
  BIMetricsFilter,
  DateOption,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  timeInterval?: string;
  timePeriod?: string;
  templateFilter?: BIMetricsFilter;
  relativeDateFilterOptions: DateOption[];
  widgetFilterLabel: string;
  widgetFilterName: string;
  onValueChange: (change: DateOperatorOption) => void;
}

export const DateTypeFilter: React.FC<Props> = ({
  timeInterval,
  timePeriod,
  templateFilter,
  relativeDateFilterOptions,
  widgetFilterLabel,
  widgetFilterName,
  onValueChange,
}) => {
  const { options: timeFilterOptions } = useTimeOptions();

  useEffect(() => {
    // when loading a metric to edit, if the filter value is a custom time period
    // the custom time period need to be added to timeValueOptions so that it
    // can be pre-selected in the UI
    if (
      typeof templateFilter?.value === 'string' &&
      templateFilter?.value.includes(',') &&
      !timeFilterOptions.predefined.some(
        (e) => e.value === templateFilter?.value
      ) &&
      timeFilterOptions.predefined.length
    ) {
      timeFilterOptions.predefined.push({
        label: templateFilter.value.split(',').join(' / '),
        value: templateFilter.value,
      });
    }
  }, [JSON.stringify(timeFilterOptions)]);

  const buildDateOptions = (options: DateOption[]): Checkbox[] => {
    const isChecked = (option: DateOption): boolean => {
      if (option.value === ALL_TIME_VALUE) {
        return true;
      }

      if (templateFilter && templateFilter.value) {
        return option.value === templateFilter.value;
      }

      return false;
    };

    return options.map((option) => ({ ...option, checked: isChecked(option) }));
  };

  return (
    <DropdownCalendar
      config={{
        allowFuture: true,
        checkedAll: false,
        checkedTotal: 0,
        title: widgetFilterLabel,
        withShowAll: true,
        isLocked: false,
        customEnd: true,
        customStart: true,
        customPeriodValue:
          templateFilter && templateFilter.value
            ? (templateFilter.value as string)
            : undefined,
        values: buildDateOptions(timeFilterOptions.predefined),
        fiscalYearValues: buildDateOptions(timeFilterOptions.fiscal_year),
        relativeValues: buildDateOptions(relativeDateFilterOptions),
        timeInterval: timeInterval,
        timePeriod: timePeriod,
        showInNotInFilter: true,
      }}
      key={widgetFilterName}
      name="Filter"
      onChange={onValueChange}
    />
  );
};
