import {
  formatTo,
  selectTimeRange,
} from './TimeFilter/TabsForFilter/timeSpanOptions';
import { BetweenDate } from './TimeFilter/TabsForFilter/types';
import { FiltersConfig } from './TimeFilter/TabsForFilter/types';
import { TimeFilter } from './TimeFilter/TimeFilter';
import * as s from './styles';
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import React, { useState, useEffect } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuRadio from 'components/UI/BuRadio';
import { DateOperatorOption } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/types';
import { useClickOutside } from 'components/hooks/useClickOutside';

type Props = {
  config: FiltersConfig;
  name: string;
  onChange: (values: DateOperatorOption) => void;
};

export const DropdownCalendar: React.FC<Props> = ({
  config,
  name,
  onChange,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const [localTab, setLocalTab] = useState<string>('Predefined');
  const [operator, setOperator] = useState<string>('in');
  const [selectedDate, setSelectedDate] = useState<string>('all_time');
  const [betweenDate, setBetweenDate] = useState<BetweenDate>({
    startDate: '',
    endDate: '',
  });

  const {
    isChanged,
    isLocked,
    title,
    values,
    fiscalYearValues,
    relativeValues,
    customPeriodValue,
    showInNotInFilter,
  } = config;

  const currValue =
    (fiscalYearValues && fiscalYearValues.find((item) => item.checked)) ||
    (relativeValues && relativeValues.find((item) => item.checked)) ||
    values.find((item) => item.checked);

  const value = currValue?.value || customPeriodValue || '';
  const label = currValue?.label
    ? currValue?.label
    : !!customPeriodValue && customPeriodValue.length > 0
    ? customPeriodValue?.split('_').join(' ')
    : !!betweenDate?.startDate && !!betweenDate?.endDate
    ? `${betweenDate?.startDate} - ${betweenDate?.endDate}`
    : undefined;

  useEffect(() => {
    setSelectedDate((value as string) || 'all_time');

    const format = (date: string) => formatTo(date, 'YYYY-MM-DD', 'MM/DD/YYYY');

    if (isString(value) && value.length === 21) {
      const dates = value.split(',');

      setLocalTab('Range');
      setBetweenDate({
        startDate: format(dates[0]),
        endDate: format(dates[1]),
      });
    } else if (value && value[0] && value[0].length >= 10) {
      const dates = value[0].split(',');

      setBetweenDate({
        startDate: format(dates[0]),
        endDate: format(dates[1]),
      });
    } else {
      const date = selectTimeRange(isArray(value) ? value : [value], false);

      setBetweenDate({
        startDate: date ? format(Object.keys(date[0])[0]) : '',
        endDate: date ? format(date[0][Object.keys(date[0])[0]]) : '',
      });
    }

    const isPredefined = values?.some((el) => el.value === value);
    const isFiscalYear = fiscalYearValues?.some((el) => el.value === value);
    const isRelative = relativeValues?.some((el) => el.value === value);

    if (isPredefined) {
      setLocalTab('Predefined');
    }
    if (isFiscalYear) {
      setLocalTab('Fiscal Year');
    }
    if (isRelative) {
      setLocalTab('Relative');
    }
    if (typeof value === 'string' && value.includes(',')) {
      setLocalTab('Range');
    }
    if (
      typeof value === 'string' &&
      value.includes('_') &&
      value.split('_').length - 1 === 2 &&
      parseInt(value.split('_')?.[1] || '')
    ) {
      setLocalTab('Custom');
    }
  }, [JSON.stringify(value)]);

  const handleChangeDetected = (id: string): void => {
    setSelectedDate(id);
    onChange({
      selected: [{ id, checked: true }],
      operator: operator,
    });
  };

  const handleRadioChange = (
    _: React.FormEvent<HTMLInputElement>,
    { value }: { value: string }
  ) => {
    setOperator(value);
    onChange({
      selected: [{ id: selectedDate, checked: true }],
      operator: value,
    });
  };

  return (
    <div
      data-testing="operator-value"
      id={`${name}_dropdown`}
      ref={refElement}
      className={s.panel_wrapper}
    >
      <div
        data-testing="calendar_dropdown"
        className={classNames(s.panel_dropdown_button, {
          isChanged,
          isLocked: isLocked,
          isOpen,
        })}
        onClick={() => !isLocked && setIsOpen(!isOpen)}
      >
        <span className={s.panel_dropdown_name}>{title}:</span>
        {showInNotInFilter && (
          <span className={s.operator_value}>{`${operator.replace(
            '_',
            ' '
          )}`}</span>
        )}
        <span className={s.panel_dropdown_value}>{label || ''}</span>

        <BuIcon
          name={isOpen ? BoostUpIcons.TriangleUp : BoostUpIcons.TriangleDown}
          color="var(--bu-gray-900)"
          className={s.icon}
        />
      </div>

      {isOpen && (
        <div className={classNames(s.panel_dropdown, 'isCalendar')}>
          {showInNotInFilter && (
            <section
              data-testing="operator-selection-section"
              className={s.operator_section}
            >
              <BuRadio
                label="In"
                value="in"
                checked={'in' === operator}
                onChange={handleRadioChange}
              />
              <BuRadio
                label="Not In"
                value="not_in"
                onChange={handleRadioChange}
                checked={'not_in' === operator}
              />
            </section>
          )}
          <TimeFilter
            config={config}
            betweenDate={betweenDate}
            localTab={localTab}
            isHistorical={Boolean(relativeValues?.length)}
            value={value}
            onChange={handleChangeDetected}
            onChangeLocalTab={setLocalTab}
            onClose={setIsOpen}
            setBetweenDate={setBetweenDate}
          />
        </div>
      )}
    </div>
  );
};
