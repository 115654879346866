import { useEffect, useState } from 'react';

export const useScrolled = () => {
  const [scrolled, setScrolled] = useState<boolean>(false);

  const listener = (e: any) => setScrolled(e.target.scrollTop !== 0);

  useEffect(() => {
    const content = document.getElementById('main_content');

    if (content !== null) {
      content.addEventListener('scroll', listener);

      return () => {
        content.removeEventListener('scroll', listener);
      };
    }
  }, []);

  return scrolled;
};
