import classNames from 'classnames';
import { css } from 'emotion';
import React, {
  CSSProperties,
  MouseEvent,
  TouchEvent,
  ReactNode,
  forwardRef,
} from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import Tooltip from 'components/UI/common/Tooltip';

export const INTERACTIVE_GRID_ITEM_DRAG_HANDLE_CLASSNAME = 'draggableHandle';

const contentWrapper = css`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  transition: filter 200ms;
  overflow: auto;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 1px 3px rgba(0, 0, 0, 0.12);
  border-color: var(--bu-gray-200);
  background: var(--bu-white);
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.16) 0px 3px 9px;
    > .cardDrag {
      opacity: 1;
    }
  }
`;

const drag = css`
  opacity: 0;
  position: absolute;
  top: -12px;
  width: 100%;
  height: 28px;
  transition: opacity 200ms;
  &:hover {
    cursor: grab;
    > .widgetDragHandle {
      background-color: var(--bu-primary-300);
    }
    > .dragIcon {
      background-color: var(--bu-primary-300);
    }
  }
`;

const dragHandle = css`
  width: 100%;
  height: 16px;
  position: relative;
  top: 12px;
  border-radius: 8px 8px 0 0;
  background: var(--bu-white);
  transition: background 500ms;
`;

const dragIconWrapper = css`
  width: 120px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  background: white;
  border-radius: 8px;
  position: relative;
  bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -1px 0px;
  transition: background 500ms;
`;

// Adding padding so resize handled is not overlapped by the content
const interactiveWrapper = css`
  padding-bottom: 8px;
  padding-right: 8px;
`;

interface InteractiveGridDashboardItemProps {
  key: string;
  children?: ReactNode;
  isInteractive?: boolean;
  style?: CSSProperties;
  idx: number;
  onMouseDown?: (event: MouseEvent<HTMLDivElement>) => void;
  onMouseUp?: (event: MouseEvent<HTMLDivElement>) => void;
  onTouchEnd?: (event: TouchEvent<HTMLDivElement>) => void;
}

const InteractiveGridDashboardItem = forwardRef<
  HTMLDivElement,
  InteractiveGridDashboardItemProps
>(
  (
    {
      key,
      children,
      isInteractive = true,
      style,
      idx,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      ...props
    },
    ref
  ) => {
    return (
      <div
        key={key}
        data-testing={`interactive-item-${idx}`}
        style={{
          ...style,
        }}
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        {...props}
      >
        <div
          className={classNames(
            contentWrapper,
            isInteractive && interactiveWrapper
          )}
        >
          {isInteractive && (
            <div
              className={classNames(
                drag,
                'cardDrag',
                INTERACTIVE_GRID_ITEM_DRAG_HANDLE_CLASSNAME
              )}
            >
              <div className={classNames('widgetDragHandle', dragHandle)} />
              <div className={classNames('dragIcon', dragIconWrapper)}>
                <Tooltip tooltip="Drag to reorder">
                  <BuIcon
                    color={'var(--bu-primary-500)'}
                    name={BoostUpIcons.Drag}
                  />
                </Tooltip>
              </div>
            </div>
          )}
          {children}
        </div>
      </div>
    );
  }
);
export default InteractiveGridDashboardItem;
