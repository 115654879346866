import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { NOT_SAVED_METRIC } from 'components/dashboard/Metrics/Widget/constants';
import {
  BIMetrics,
  BIMetricsMap,
} from 'components/dashboard/Metrics/metrics.types';
import { fetchApi } from 'utils/network';

export const useMetricsList = (metricsIds: string[] | BIMetrics[]) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<BIMetricsMap>({});

  const getMetricsFromAPI = async (
    metricsIds: string[],
    abortController: AbortController
  ) => {
    const metrics: BIMetrics[] = [];

    const metricsDataAsPromisesArray = metricsIds.map(async (id) => {
      if (typeof id === 'string' && id) {
        return await fetchApi<string, BIMetrics>({
          url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${id}`,
          queryMethod: 'get',
          setData: (metricData: BIMetrics) => {
            metrics.push(metricData);
          },
          setError: (error: string | null) => {
            toast.error(`Failed to load metric data ${id}: ${error}`);
          },
          signal: abortController.signal,
        });
      }
    });

    return Promise.all(metricsDataAsPromisesArray).then(() => {
      const result: { [key: string]: BIMetrics } = {};
      metrics.forEach((metric) => {
        const id = metric._id;
        result[id] = metric;
      });
      setData(result);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (typeof metricsIds === 'object' && typeof Array.isArray(metricsIds)) {
      const abortController = new AbortController();

      // temporary hack due to uncertain API
      if (typeof metricsIds[0] === 'string') {
        getMetricsFromAPI(metricsIds as string[], abortController);
      }

      if (typeof metricsIds[0] === 'object') {
        const result = (metricsIds as BIMetrics[]).reduce((acc, metric) => {
          acc[metric._id || NOT_SAVED_METRIC] = metric;
          return acc;
        }, {} as BIMetricsMap);
        setData(result);
      }

      if (!metricsIds.length) {
        setData({});
      }

      return () => {
        if (abortController) {
          abortController.abort();
        }
      };
    }
  }, [JSON.stringify(metricsIds)]);

  return { loading, data };
};
