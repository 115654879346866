import React from 'react';

import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import { SelectedItem } from 'components/dashboard/Metrics/List/types';
import { MetricDeleteInfo } from 'components/dashboard/Metrics/metrics.types';

interface Props {
  onEditConfirm: (id: string) => void;
  isEditConfirmationOpen?: boolean;
  setIsEditConfirmationOpen?: any;
  selectedElement?: SelectedItem;
  metricDeleteInfo?: MetricDeleteInfo;
}

export const MetricEditConfirmationPopup: React.FC<Props> = ({
  onEditConfirm,
  isEditConfirmationOpen,
  setIsEditConfirmationOpen,
  selectedElement,
  metricDeleteInfo,
}) => {
  const isMetricUsedInWidgets =
    (metricDeleteInfo?.widget_deleted_list &&
      metricDeleteInfo.widget_deleted_list.length > 0) ||
    (metricDeleteInfo?.widget_edited_list &&
      metricDeleteInfo.widget_edited_list.length > 0);

  const isMetricUsedInSynthMetrics =
    metricDeleteInfo?.synthetic_metric_list &&
    metricDeleteInfo.synthetic_metric_list.length > 0;

  return (
    <BuConfirmationPopup
      cancelText="No"
      confirmText="Yes"
      headerText="Confirmation Required!"
      isOpen={isEditConfirmationOpen ?? false}
      onClose={() => {
        setIsEditConfirmationOpen(false);
      }}
      onConfirm={() => {
        setIsEditConfirmationOpen(false);
        onEditConfirm(selectedElement ? selectedElement?.id : '');
      }}
    >
      {isMetricUsedInWidgets && isMetricUsedInSynthMetrics
        ? `'${selectedElement?.name}' is being used in existing widgets and synthetic metrics and editing it will impact them. Are you sure you want to continue?`
        : isMetricUsedInWidgets
        ? `'${selectedElement?.name}' is being used in existing widgets and editing it will impact them. Are you sure you want to continue?`
        : `'${selectedElement?.name}' is being used in existing synthetic metrics and editing it will impact them. Are you sure you want to continue?`}
    </BuConfirmationPopup>
  );
};
