import { css } from 'emotion';

export const container = css`
  padding-right: 10px;
  margin-right: 10px;
  position: relative;
  display: flex;

  &:after {
    content: '';
    position: absolute;
    right: 0px;
    background: var(--bu-gray-500);
    top: 0;
    height: 30px;
    width: 1px;
  }
`;

export const view_filters_box = css`
  position: relative;
  margin-right: 5px;
`;

export const view_filters_label = css`
  border: none;
  padding: 6px 10px;
  font-size: 14px;
  font-family: var(--bu-font-regular);
  cursor: pointer;
  background: none;
  border-radius: 4px;
  transition: 0.4s;

  span {
    font-family: var(--bu-font-medium);
  }

  &.open {
    background: var(--bu-gray-300);
  }
`;

export const view_filters_options = css`
  position: absolute;
  top: 37px;
  left: 0;
  display: none;
  min-width: 400px;
  width: max-content;
  background: var(--bu-white);
  z-index: 150;
  border: 1px solid var(--bu-gray-300);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  max-height: 450px;
  overflow-y: auto;

  &.open {
    display: block;
  }
`;

export const saved_views_label_container = css`
  height: 30px;
  padding-left: 14px;
  background-color: var(--bu-gray-200);
`;

export const saved_views_label = css`
  font-size: 12px;
  letter-spacing: 0;
  line-height: 30px;
`;

export const arrow_up_down = css`
  margin-left: 8px;
  font-size: 7px;
`;

export const additional_features = css`
  position: relative;
  padding-top: 4px;
`;

export const additional_features_box = css`
  position: absolute;
  top: 33px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background: var(--bu-white);
  width: max-content;
  max-width: 200px;
  z-index: 150;
  border: 1px solid var(--bu-gray-300);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  padding: 6px 0;

  &.open {
    opacity: 1;
    visibility: visible;
  }
`;

export const additional_features_btn = css`
  width: 24px;
  height: 24px;
  cursor: pointer;
  padding: 0;
  margin: 0;
  position: relative;
  border-radius: 2px;
  border: 1px solid var(--bu-gray-500);
  background: var(--bu-white);
  color: var(--bu-gray-700);
  transition: 0.4s;

  &:focus {
    outline: none;
  }

  &.open {
    background: var(--bu-primary-200);
    color: var(--bu-primary-500);
    border-color: var(--bu-primary-500);
  }
`;

export const additional_features_box_item = css`
  padding: 5px 15px;
  color: var(--bu-gray-900);
  font-size: 13px;
  font-family: var(--bu-font-regular);
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  transition: 0.4s;

  &:hover,
  &.active {
    background: var(--bu-primary-200);
  }
`;

export const create_new_view_container = css`
  padding: 8px 32px 32px 32px;
`;

export const create_saved_view_p = css`
  color: var(--bu-gray-600);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  word-break: normal;
  max-width: 360px;
  margin-bottom: 16px;
`;

export const delete_modal_message = css`
  word-break: break-word;
`;
