import ExpandibleItems from '../../UI/ExpandibleItems';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import * as styles from 'components/UI/EventsTable/styles';
import Table from 'components/UI/TableConfig/Table';
import { IColumn, SortOrder } from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import { IProps } from 'components/modals/EventsModal/types';
import { ModalLink } from 'navigation/Link';
import { fetchApi, QueryStatus } from 'utils/network';

const renderEmailsCell = (data: any) => {
  if (!data || !data.row || !data.row['recepients']) {
    return null;
  }

  return (
    <ExpandibleItems
      max={2}
      items={data.row['recepients'].map((item: React.ReactNode) => (
        <span style={{ padding: '10px 0' }}>
          {item}
          <br />
        </span>
      ))}
    />
  );
};

const renderLinksCell = (
  rowObject: Array<{ _id: string; name: string }>,
  field: 'deal' | 'account'
) => {
  if (rowObject && rowObject.length) {
    return (
      <div>
        {rowObject.map((item, index) => (
          <>
            <ModalLink
              key={item._id}
              url={{
                scheme: `/${field}/:id` as any,
                params: { id: item._id },
              }}
              className={styles.name_field_name}
            >
              {item.name}
            </ModalLink>
            {rowObject.length !== 1 && index !== rowObject.length ? (
              <br />
            ) : null}
          </>
        ))}
      </div>
    );
  } else {
    return <div>-</div>;
  }
};

const COLUMNS: IColumn[] = [
  {
    id: 'received_at',
    label: 'date',
    field: 'received_at',
    type: ColumnTypes.DATE,
    sort_order: SortOrder.ASCENDING,
    sortable: false,
    config: {
      format: 'DD MMM YYYY, h:mm A',
    },
  },
  {
    id: 'subject',
    label: 'subject',
    field: 'subject',
    width: 250,
    type: ColumnTypes.CUSTOM,
    sort_order: SortOrder.ASCENDING,
    sortable: false,
    config: {
      renderer: (data: any) => (
        <ModalLink
          key={data.row._id}
          url={{
            scheme: '/email/:emailId',
            params: { emailId: data.row._id },
          }}
          className={styles.name_field_name}
        >
          {data.row.subject}
        </ModalLink>
      ),
      className: 'actionColumn',
    } as CustomCellConfig,
  },
  {
    id: 'opportunities',
    label: 'Deal Name',
    field: 'opportunities',
    type: ColumnTypes.CUSTOM,
    sort_order: SortOrder.ASCENDING,
    sortable: false,
    config: {
      renderer: ({ row }: any) => renderLinksCell(row['opportunities'], 'deal'),
      className: 'actionColumn',
    } as CustomCellConfig,
  },
  {
    id: 'accounts',
    label: 'Account',
    field: 'accounts',
    type: ColumnTypes.CUSTOM,
    sort_order: SortOrder.ASCENDING,
    sortable: false,
    config: {
      renderer: ({ row }: any) => renderLinksCell(row['accounts'], 'account'),
      className: 'actionColumn',
    } as CustomCellConfig,
  },
  {
    id: 'sender',
    label: 'from',
    field: 'sender',
    maxWidth: 200,
    type: ColumnTypes.TEXT,
    sort_order: SortOrder.ASCENDING,
    sortable: false,
    config: {},
  },
  {
    id: 'recepients',
    label: 'to',
    field: 'recepients',
    type: ColumnTypes.CUSTOM,
    sort_order: SortOrder.ASCENDING,
    sortable: false,
    config: {
      renderer: renderEmailsCell,
    } as CustomCellConfig,
  },
];

const ROWS_PER_PAGE = 20;

const EmailsModal = ({ modalProps }: IProps) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [apiStatus, setApiStatus] = useState<QueryStatus>();
  const [data, setData] = useState({
    count: 0,
    rows: [],
  });

  useEffect(() => {
    if (modalProps.apiUrl && modalProps.filters) {
      fetchApi({
        url: modalProps.apiUrl,
        queryMethod: 'post',
        queryParams: {
          ...JSON.parse(modalProps.filters),
          page_size: ROWS_PER_PAGE,
          page_number: pageNumber - 1,
        },
        setStatus: setApiStatus,
        setData: ({ data }: any) => {
          setData({
            rows: data.emails,
            count: data.count,
          });
        },
        setError: () => toast.error('Failed to load.'),
      });
    }
  }, [modalProps.apiUrl, modalProps.filters, pageNumber]);

  const handlePaginationChange = (page: number) => {
    setPageNumber(page);
  };

  return (
    <Table
      title={'Emails'}
      columns={COLUMNS}
      data={data.rows}
      loading={apiStatus === 'loading'}
      currentPage={pageNumber}
      hidePaginationEnd
      rowsPerPage={ROWS_PER_PAGE}
      totalCount={data.count}
      onPaginationChange={handlePaginationChange}
      hideSearch
    />
  );
};

export default EmailsModal;
