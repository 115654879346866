import classNames from 'classnames';
import { css } from 'emotion';
import { isNil } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import { formatAmount } from 'common/helpers';
import * as selectors from 'selectors';

const fontColor: Record<string, string> = {
  green: '#0ba5a3',
  grey: 'var(--bu-gray-800)',
  red: '#F74544',
};

const bgColor: Record<string, string> = {
  green: '#cff6d7',
  grey: 'var(--bu-gray-300)',
  red: '#FCE3DC',
};

const badge = (color: string, onClick?: () => void) => css`
  height: 16px;
  width: fit-content;
  margin-top: 5px;
  border-radius: 7px;
  font-family: var(--bu-font-regular);
  padding: 1.5px 7px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  cursor: ${onClick ? 'pointer' : 'default'};
  background-color: ${bgColor[color]} !important;
  color: ${fontColor[color]} !important;
  margin-left: auto;
`;

const Badge = ({
  value,
  onClick,
}: {
  value?: number | string;
  onClick?: () => void;
}) => {
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);
  let color = 'grey';

  if (isNaN(Number(value)) || isNil(value)) {
    return null;
  }

  if (value > 0) {
    color = 'green';
  } else if (value < 0) {
    color = 'red';
  }

  return (
    <div
      onClick={onClick}
      className={classNames('amount-badge', badge(color, onClick))}
    >
      {formatAmount(companyCurrencyCode, value as number, true)}
    </div>
  );
};

export default Badge;
