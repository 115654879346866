import { css } from 'emotion';
import React from 'react';

import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import { SortBy } from 'components/chart-dashboards/Dashboard/SortBy';
import ViewTypeButtons from 'components/chart-dashboards/Dashboard/ViewTypeButtons';

const stickyStyles = css`
  position: sticky;
  top: 50px;
  background: #fff;
  z-index: 991;
  border-bottom: 1px solid transparent;

  &.scrolled {
    z-index: 905;
    border-bottom: 1px solid #dddddd;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    margin-left: -20px;
    margin-right: -20px;
    padding: 10px 20px 10px 20px;
  }
`;

const titlePanelStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const customFilterStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0 0 0;
`;

const ActivityDashboardFilters: React.FC<{
  showViewBySwitch?: boolean;
  showSortBy?: boolean;
  activeTab?: string;
}> = ({ showViewBySwitch = false, showSortBy = true, activeTab = '' }) => (
  <div className={stickyStyles}>
    <OpenFiltersPanel tab={'team_dashboard'} activeTab={activeTab} />
    <div className={titlePanelStyle}>
      <div className={customFilterStyle}>
        {showViewBySwitch && <ViewTypeButtons />}
        {showSortBy && <SortBy />}
      </div>
    </div>
  </div>
);

export default ActivityDashboardFilters;
