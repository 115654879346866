import { OpenModalProps } from './types';
import { openModal as openModalImpl } from './utils';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

const ModalContext = React.createContext({
  openModal: (props: OpenModalProps) => {},
  closeModal: (scheme: string, callback: () => {}) => {},
});

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [onCloseCallbacks, setOnCloseCallbacks] = useState<any>({});
  const history = useHistory();

  const openModal = useCallback(
    (props: OpenModalProps) => {
      if (!!props.onClose) {
        setOnCloseCallbacks({
          ...onCloseCallbacks,
          [props.scheme]: props.onClose,
        });
      }
      openModalImpl(props);
    },
    [onCloseCallbacks]
  );

  const closeModal = useCallback(
    (scheme: string, callback: () => void) => {
      const { location } = history;
      const { pathname } = location;

      const path = pathname.split('/~/').slice(0, -1).join('/~/');
      const onCloseCallback = getOnCloseCallback(scheme);
      if (!!onCloseCallback) {
        onCloseCallback();
      }
      history.push({
        ...location,
        pathname: path,
      });

      callback();

      removeOnCloseCallback(scheme);
    },
    [onCloseCallbacks]
  );

  const getOnCloseCallback = useCallback(
    (scheme: string) => onCloseCallbacks[scheme],
    [onCloseCallbacks]
  );

  const removeOnCloseCallback = useCallback(
    (scheme: string) => {
      delete onCloseCallbacks[scheme];
      setOnCloseCallbacks({
        ...onCloseCallbacks,
      });
    },
    [onCloseCallbacks]
  );
  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => React.useContext(ModalContext);
