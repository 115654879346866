import React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { goBackOr, goToCalls } from 'actions/routeActions';
import * as transcriptActions from 'actions/transcriptActions';
import Desktop from 'components/call-transcription';
import * as selectors from 'selectors';

class Call extends React.PureComponent {
  componentWillMount() {
    this.getCallDetailHandler();
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;

    if (match.url !== prevProps.match.url) {
      this.getCallDetailHandler();
    }
  }

  getCallDetailHandler = () => {
    const { getCallDetailes, resetState, call, user, match } = this.props;
    resetState();
    const callId = match.params.id;
    getCallDetailes(user.email, user.api_access_token, callId);
    if (call && call.topics_discussed) {
      this.setState({
        numberTopics: call.topics_discussed.length,
      });
    }
  };

  render() {
    if (this.props.status === 'loading') {
      return <Loader active />;
    }

    return <Desktop {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  user: selectors.getUser(state),
  call: selectors.getCallDetail(state),
  isTranscriptOpen: selectors.getActiveTranscriptTab(state),
  currentTime: selectors.getCurrentTimeOffset(state),
  isPlaying: selectors.getPlayingState(state),
  activeTopic: selectors.getActiveTopic(state),
  isAdmin: selectors.isAdmin(state),
  previousRoute: selectors.getPreviousRoute(state),
  previousSection: selectors.getPreviousSection(state, 'Calls'),
  status: selectors.getTranscriptStatus(state),
});

const mapDispatchToProps = {
  resetState: transcriptActions.createTranscriptAction.resetState,
  updateState: transcriptActions.createTranscriptAction.updateState,
  getCallDetailes: transcriptActions.getTranscriptionById,
  addTag: transcriptActions.doAddTag,
  removeTag: transcriptActions.doRemoveTag,
  goBack: goBackOr(goToCalls),
  setSearchText: transcriptActions.createTranscriptAction.setSearchText,
  doSearch: transcriptActions.createTranscriptAction.doSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(Call);
