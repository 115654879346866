import { css } from 'emotion';

export const wrapper = css`
  margin-left: 10px;
  height: 22px;
  margin-right: 5px;
  border-radius: 2px;
  padding: 2px 7px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: var(--bu-font-regular);
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: var(--bu-white);
  background-color: var(--bu-green-500);
`;
