import { getModalProps } from '../../selectors/modals';
import clx from 'classnames';
import { css } from 'emotion';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import MindtickeIconTransparent from 'assets/fonts/boostup-icons/mindtickle_call_transparent.svg';
import NextStepIcon from 'assets/images/new_icon/sfdc_activity_icon.svg';
import BuButton from 'components/UI/BuButton';
import { CRMActivityGroup } from 'components/UI/ProspectEngagement/types';
import { ModalRouteProps } from 'navigation/types';
import { IReduxState } from 'reducers/types';
import { getEngagementStatus } from 'selectors';
import { getSFDCActivityGroups } from 'selectors/engagement';

type OwnProps =
  | ModalRouteProps<{ activityId: string; modalProps: string }>
  | any;
type StateProps = { activityGroup: CRMActivityGroup; status: string };
type Props = OwnProps & StateProps;

const emptyModalStyle = css`
  text-align: center;
  font-size: 20px;
  padding: 30px;
`;

const box_email = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 5px;
  text-align: left;

  font-family: var(--bu-font-regular);
  font-size: 14px;
  color: #777777;
`;

const box_email_line = css`
  display: grid;
  grid-template-columns: 1.5fr 10fr;
`;

const box_email_icon = css`
  display: flex;
  justify-content: flex-end;
  font-family: var(--bu-font-medium);
  font-size: 14px;
  color: #777777;
  padding-right: 10px;

  img {
    width: 30px;
    height: 30px;
  }
`;

const margin_bottom = css`
  margin-bottom: 20px;
`;

const align_left = css`
  text-align: left;
`;

const subject_name = css`
  margin-bottom: 7px;
  font-size: 18px;
  color: #4a4a4a;
  font-family: var(--bu-font-medium);
`;

const date = css`
  color: #777777;
  font-size: 14px;
  font-family: var(--bu-font-regular);
  margin-bottom: 20px;
`;

const text = css`
  display: flex;
  flex-direction: column;
  color: #777777;
  font-size: 14px;
  font-family: var(--bu-font-regular);
  text-align: left;
`;

const displayBox = css`
  // structure came from BE
  dl {
    display: grid;
    grid-template-columns: 1.5fr 10fr;

    dt,
    dd {
      margin-bottom: 10px;
    }

    dt {
      text-align: right;
      padding-right: 10px;
    }
  }
  a {
    &,
    &:hover,
    &:focus,
    &:visited {
      color: #0d71bb;
    }
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const redirect_btn = css`
  min-width: 170px;
  span {
    padding-left: 5px;
    padding-top: 2px;
  }
`;

export const mt_icon = css`
  width: 16px;
  height: 16px;
`;

const improveHTMLContent = (data: string): string =>
  data.replaceAll('<a ', '<a target="_blank" ');

const ActivityModal = ({
  params,
  modalProps,
  activityGroup,
  status,
}: Props) => {
  if (status === 'loading') {
    return <Loader active />;
  }

  if (status === 'error') {
    return <div className={emptyModalStyle}>Something wrong</div>;
  }

  if (!activityGroup && !modalProps.activity) {
    return <div className={emptyModalStyle}>Activity not found</div>;
  }

  const [entityId, activityId] = params.activityId.split('_');
  const activity = modalProps.activity
    ? modalProps.activity.activities[0]
    : activityGroup.activities?.find(
        (item: { record_id: string }) => item.record_id === activityId
      );

  if (!activity) {
    return <div className={emptyModalStyle}>Activity not found</div>;
  }

  const { subject, activity_date, display_value = '' } = activity;

  const redirectTo = () => {
    const win = window.open(
      activity.additional_fields.recording_url__c,
      '_blank'
    );
    win?.focus();
  };

  return (
    <div className={box_email}>
      <div className={clx(box_email_line, margin_bottom)}>
        <div className={box_email_icon}>
          <img src={NextStepIcon} alt="icon" />
        </div>
        <div className={align_left}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className={subject_name}>{subject}</div>
            {activity.additional_fields?.recording_url__c && (
              <div>
                <BuButton className={redirect_btn} onClick={redirectTo}>
                  {/* added that icon with img tag because BuIcon component have bug with transparent icons */}
                  <img
                    className={mt_icon}
                    src={MindtickeIconTransparent}
                    alt="play"
                  />
                  <span>{'View Call Insights'}</span>
                </BuButton>
              </div>
            )}
          </div>
          <div className={date}>
            {moment(activity_date).format('dddd, DD MMM YYYY [at] HH:mm:ss')}
          </div>
        </div>
      </div>

      <div
        className={clx(displayBox, margin_bottom)}
        dangerouslySetInnerHTML={{
          __html: improveHTMLContent(display_value),
        }}
      />
    </div>
  );
};

const mapStateToProps = (
  state: IReduxState,
  { params }: ModalRouteProps<{ activityId: string }>
) => {
  const [entityId, activityId] = params.activityId.split('_');

  return {
    activityGroup: getSFDCActivityGroups(state, entityId).find(
      (group: CRMActivityGroup) =>
        group.activities
          ?.map((activity) => activity.record_id)
          .includes(activityId)
    ),
    status: getEngagementStatus(state),
    modalProps: getModalProps<{ activity?: any }>(
      state,
      '/activity/:activityId'
    ),
  };
};

export default connect(mapStateToProps)(ActivityModal);
