import * as R from 'ramda';
import React, { useState } from 'react';
import { Popup } from 'semantic-ui-react';

import { TableConfigurationColumn } from 'components/UI/TableConfig/types';
import { IDataCellProps } from 'components/UI/common/TypedTable/TypedTable';
import styles from 'components/new-library/Table/styles';
import { Attendee } from 'components/new-library/types';
import { Link, ModalLink } from 'navigation/Link';

const defaults = {
  editable: false,
  sortable: false,
  protected: false,
};

const RenderDealName: React.FC<IDataCellProps> = ({ row }) => {
  const {
    account_executive,
    account_id,
    account_name,
    deal_id,
    opportunity_name,
  } = row;

  function renderDealName() {
    if (opportunity_name && deal_id && opportunity_name !== 'Unnamed') {
      // if deal is not mapped and account name is presented – show account executive
      return (
        <ModalLink
          url={{
            scheme: '/deal/:id',
            params: { id: deal_id as string },
          }}
        >
          <div className={styles.deal_name}>{opportunity_name}</div>
        </ModalLink>
      );
    }

    if (!deal_id && account_name && account_id) {
      // if deal is not mapped and account name is presented – show account executive
      return (
        <Link
          url={{
            scheme: '/accounts/preview/:id',
            params: { id: account_id as string },
          }}
        >
          <div className={styles.deal_name}>{account_name}</div>
        </Link>
      );
    }

    return null;
  }

  return (
    <div className={styles.body_column}>
      {renderDealName()}
      <div className={styles.deal_ae}>{account_executive}</div>
    </div>
  );
};

const RenderProspectAttendees: React.FC<IDataCellProps> = ({ row }) => {
  const [pageProspectState, setpageProspectState] = useState(false);

  const prospectAttendes = (row.attendees as any)?.filter(
    (el: any) => el.is_customer === true
  );

  const prospectAttendesForRendering = R.compose<Attendee[], Attendee[]>(
    R.dropLast<Attendee>(pageProspectState ? 0 : prospectAttendes.length - 2)
  )(prospectAttendes);

  return (
    <div className={styles.body_column_row}>
      <div className={styles.body_column_column}>
        {prospectAttendesForRendering.map((attendee: Attendee) => (
          <div
            className={styles.attendees_field}
            key={attendee.display_name || attendee.email}
          >
            <div className={styles.attendees_name}>
              <Popup
                hoverable
                trigger={<span>{attendee.display_name || attendee.email}</span>}
                content={attendee.display_name || attendee.email}
              />
            </div>
          </div>
        ))}
      </div>

      {!pageProspectState && prospectAttendes.length > 2 && (
        <div
          onClick={() => setpageProspectState(true)}
          className={styles.btn_show_Propspect}
        >
          {`+${prospectAttendes.length - 2}`}
        </div>
      )}
    </div>
  );
};

const RenderCompanyAttendees: React.FC<IDataCellProps> = ({ row }) => {
  const [pageCompanytState, setpageCompanyState] = useState(false);

  const companyAttendes = (row.attendees as any)?.filter(
    (el: any) => el.is_customer === false
  );

  const prospectCompanyForRendering = R.compose<Attendee[], Attendee[]>(
    R.dropLast<Attendee>(pageCompanytState ? 0 : companyAttendes.length - 2)
  )(companyAttendes);

  return (
    <div className={styles.body_column_row}>
      <div className={styles.body_column_column}>
        {prospectCompanyForRendering.map((attendee: Attendee) => (
          <div
            className={styles.attendees_field}
            key={attendee.display_name || attendee.email}
          >
            <div className={styles.attendees_name}>
              <Popup
                hoverable
                trigger={<span>{attendee.display_name || attendee.email}</span>}
                content={attendee.display_name || attendee.email}
              />
            </div>
          </div>
        ))}
      </div>

      {!pageCompanytState && companyAttendes.length > 2 && (
        <div
          onClick={() => setpageCompanyState(true)}
          className={styles.btn_show_Propspect}
        >
          {`+${companyAttendes.length - 2}`}
        </div>
      )}
    </div>
  );
};

const RenderCallAudio: React.FC<IDataCellProps> = ({ row }) => {
  const { id, no_show, status, source, recording_url } = row;

  if (no_show) {
    return <span className={styles.red_text}>No Show</span>;
  }

  if (status === 'summarized') {
    if (source && source === 'Mindtickle' && recording_url) {
      return (
        <a
          target="_blank"
          href={recording_url as string}
          className="callTranscript__table-body-btn margin"
        >
          Play
          <img
            style={{
              width: '25px',
              marginLeft: '5px',
              marginRight: '-1px',
            }}
            src={require('../../assets/fonts/boostup-icons/mindtickle_call_icon.svg')}
            alt=""
          />
        </a>
      );
    }

    return (
      <Link url={{ scheme: '/transcript/:id', params: { id: id as string } }}>
        <span className="callTranscript__table-body-btn margin">
          Play
          <img
            src={require('../../assets/fonts/boostup-icons/play.svg')}
            alt=""
          />
        </span>
      </Link>
    );
  }

  if (status === 'recorded' && !no_show) {
    return <span className={styles.table_body_spanText}>Being Processed</span>;
  }

  if (status === 'scheduled' && !no_show) {
    return <span className={styles.table_body_spanText}>Not Recorded</span>;
  }

  if (status === 'skipped' && !no_show) {
    return <span className={styles.table_body_spanText}>Not Available</span>;
  }

  return null;
};

export const columns: TableConfigurationColumn[] = [
  {
    ...defaults,
    protected: true,
    object_field: 'summary',
    field_name: 'summary',
    display_name: 'Name',
    type: 'text',
    min_width: 300,
    max_width: 300,
    meta: {
      object_id: 'id',
      type: 'clickable',
      sub_value: {
        relative_field: 'start',
        badge: {
          type: 'date',
          relative_fields: ['start'],
        },
      },
    },
  },
  {
    ...defaults,
    object_field: '',
    field_name: '',
    display_name: 'Deal Name',
    type: 'custom',
    min_width: 200,
    max_width: 200,
    width: 200,
    meta: {
      type: 'with_render',
      renderer: RenderDealName,
    },
  },
  {
    ...defaults,
    object_field: '',
    field_name: '',
    display_name: 'Prospect Attendees',
    type: 'custom',
    min_width: 300,
    max_width: 300,
    meta: {
      type: 'with_render',
      renderer: RenderProspectAttendees,
    },
  },
  {
    ...defaults,
    object_field: '',
    field_name: '',
    display_name: 'Company Attendees',
    type: 'custom',
    min_width: 300,
    max_width: 300,
    meta: {
      type: 'with_render',
      renderer: RenderCompanyAttendees,
    },
  },
];

export const columnAudio: TableConfigurationColumn = {
  ...defaults,
  object_field: '',
  field_name: '',
  display_name: 'Call Audio',
  type: 'custom',
  meta: {
    type: 'with_render',
    renderer: RenderCallAudio,
  },
};
