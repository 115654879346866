import { css } from 'emotion';

const styles = {
  body_column: css`
    display: flex;
    flex-direction: column;
    padding: 13px 0;
    overflow: hidden;

    &:first-child {
      padding-left: 15px;
    }
  `,
  body_column_row: css`
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 5px 0;
    height: fit-content;

    &:first-child {
      padding-left: 10px;
    }
  `,
  body_column_column: css`
    display: flex;
    flex-direction: column;
  `,
  deal_name: css`
    color: var(--bu-primary-500);
    font-size: 1em !important;
    line-height: 19px;
    font-weight: 300;
  `,
  deal_ae: css`
    color: var(--bu-gray-700);
    font-size: 0.85em;
    line-height: 19px;
    font-weight: 300;
  `,
  attendees_field: css`
    display: flex;
    flex-direction: row;
    padding: 3px 0;
  `,
  attendees_name: css`
    height: 19px;
    width: fit-content;
    max-width: 250px;
    margin-right: 3px;
    color: var(--bu-gray-900);
    font-size: 1em;
    line-height: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  btn_show_Propspect: css`
    display: flex;
    align-items: flex-end;
    color: var(--bu-primary-500);
    padding: 3px 0;
    font-size: 1em;
    line-height: 19px;
    cursor: pointer;
  `,
  red_text: css`
    color: rgb(238, 114, 78);
    font-size: 1em;
    font-weight: bold;
  `,
  table_body_spanText: css`
    font-size: 1em;
    color: var(--bu-gray-700);
  `,
};

export default styles;
