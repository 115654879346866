import { DealsRow, DealsTableProps } from './types';
import union from 'lodash/union';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { USER_ROLES } from 'common/constants';
import BuSelect from 'components/UI/BuSelect';
import Table from 'components/UI/TableConfig';
import { useLocalPagination } from 'components/UI/TableConfig/TableControls';
import { handleColumnClick } from 'components/UI/TableConfig/column-helper';
import { TableOwnProps } from 'components/UI/TableConfig/types';
import CustomTableTitle from 'components/dashboard/CROOverride/SubmissionForecast/ForecastDealsTable/CustomTableTitle';
import {
  CallFilter,
  getSumAmount,
  SELECT_ROW_FIELD,
  useMySummaryMemo,
  useTeamSummaryMemo,
  getCallOptionsForType,
} from 'components/dashboard/CROOverride/SubmissionForecast/ForecastDealsTable/helper';
import useComplexOptionFilter from 'components/hooks/useComplexDropdownFilter';
import useTableSearch from 'components/hooks/useTableSearch';
import useTableSort from 'components/hooks/useTableSort';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import { getForecastSubmissionSettingsById } from 'selectors';
import { getUserRole } from 'selectors/user';

const SelectDealsTable: React.FC<DealsTableProps> = ({
  submissionSettingsId,
  selectedDealsIds,
  allDealsIds,
  onSelectedDeals,
  deals,
  firstForecastDealsData,
  callOptionType,
  onChange,
  statuses,
  viewMode,
  hideIncludeColumn = false,
  userHasTeam,
  loading,
}) => {
  const callOptionsForModal = getCallOptionsForType(
    callOptionType,
    userHasTeam
  );

  const tableConfig = useSelector((state) =>
    selectors.tableColumnConfiguration(
      state,
      'opportunity',
      'ForecastSubmissionSettings'
    )
  );
  const companyCurrency = useSelector(selectors.getUserLocalCurrency);

  const isMulticurrencyEnabled = useSelector(selectors.isMulticurrencyEnabled);

  const userRole = useSelector(getUserRole);

  const [callFilterValue, setCallFilterValue, callFilter] =
    useComplexOptionFilter<DealsRow>(
      callOptionsForModal,
      callOptionsForModal[0].value
    );

  const [, setSearchText, searchFilter] = useTableSearch<Deals.Deal>([
    ['crm_metadata', 'opportunity_name'],
    ['crm_metadata', 'account_name'],
    ['account_executive'],
  ]);

  const [sortByField, setSortByField, sortBy] = useTableSort<DealsRow>(
    undefined,
    tableConfig.columns,
    isMulticurrencyEnabled
  );

  const submissionSettings = useSelector((state) =>
    getForecastSubmissionSettingsById(
      state as IReduxState,
      submissionSettingsId
    )
  );

  // Here we're not adding selectedDealsIds as deps as we don't want to
  // update the list with every deal selection when we are filtering by call
  // As we want the deal still be present even if it was deselected
  const callFilteredDeals = useMemo(() => {
    return deals
      .map((deal) => ({
        ...deal,
        id: deal._id,
        [SELECT_ROW_FIELD]: selectedDealsIds.includes(deal._id as string),
      }))
      .filter(callFilter);
  }, [deals, callFilter]);

  const searchFilteredDeals = useMemo(
    () => callFilteredDeals.filter(searchFilter) || [],
    [callFilter, searchFilter, callFilteredDeals]
  );

  const sortedDeals = useMemo(
    () => sortBy(searchFilteredDeals),
    [sortBy, searchFilteredDeals]
  );

  const { data: paginatedDeals, ...paginationProps } =
    useLocalPagination<DealsRow>(sortedDeals, 50);

  const mappedDeals = useMemo(
    () =>
      paginatedDeals.map((deal) => ({
        ...deal,
        id: deal._id,
        [SELECT_ROW_FIELD]: selectedDealsIds.includes(deal._id as string),
      })),
    [paginatedDeals, selectedDealsIds]
  );

  const dealsRows = useMemo(
    () =>
      submissionSettings?.deals?.deals?.map((deal) => ({
        ...deal,
        amount: { value: deal.amount || 0 },
      })) ?? [],
    [submissionSettings]
  );

  const [myIncludes, myIncludesAmount] = useMySummaryMemo(
    dealsRows,
    companyCurrency,
    selectedDealsIds
  );

  const [teamIncluded, teamIncludedAmount] = useTeamSummaryMemo(
    firstForecastDealsData.deals as DealsRow[],
    companyCurrency
  );

  const handleSelectDeal: TableOwnProps['onSelect'] = (
    changedRows,
    checked,
    checkAllSelected
  ) => {
    const ids = checkAllSelected
      ? searchFilteredDeals.map(({ id }) => id as string)
      : changedRows.map(({ id }) => id as string);

    const newSelectedIds = checked
      ? union(selectedDealsIds, ids)
      : selectedDealsIds.filter((item) => !ids.includes(item));

    const selectedDeals = firstForecastDealsData.deals.filter(({ id, _id }) =>
      newSelectedIds.includes(id || _id)
    ) as DealsRow[];

    onSelectedDeals(newSelectedIds, allDealsIds, getSumAmount(selectedDeals));
  };

  const hiddenColumns =
    !viewMode &&
    ![USER_ROLES.CLEVEL, USER_ROLES.SALES_MANAGER].includes(userRole as string)
      ? ['included_in_submission']
      : [];

  if (!viewMode && hideIncludeColumn) {
    hiddenColumns.push('included_in_submission');
  }

  return (
    <Table
      {...paginationProps}
      hiddenColumns={hiddenColumns}
      tableConfigCollection="opportunity"
      tableConfigName="ForecastSubmissionSettings"
      data={mappedDeals}
      title={
        <CustomTableTitle
          allIds={allDealsIds.length}
          teamIds={teamIncluded}
          myIds={selectedDealsIds.length}
          teamAmount={teamIncludedAmount}
          myAmount={myIncludesAmount}
          showTeamIncludes={callOptionType === 'OVERRIDE_ME_AND_TEAM_CALLS'}
        />
      }
      controls={
        <BuSelect
          inlineLabel="Call"
          secondary
          options={callOptionsForModal}
          defaults={[callFilterValue]}
          onChange={(selection) => {
            setCallFilterValue(selection[0] as CallFilter);
          }}
        />
      }
      onSearchChange={setSearchText}
      sortOrder={sortByField}
      onSort={setSortByField}
      isModal
      selectable={!viewMode && SELECT_ROW_FIELD}
      selectedCount={myIncludes}
      onSelect={handleSelectDeal}
      fullscreen
      hidePaginationEnd
      onChange={onChange}
      statuses={statuses}
      onSpecialClick={handleColumnClick}
      hasActionsColumn
      loading={loading}
      showTotalResultsNextToPagination
    />
  );
};

export default SelectDealsTable;
