import { isEmpty } from 'ramda';
import React, { useCallback, useEffect } from 'react';
import { Loader } from 'semantic-ui-react';

import BusinessTypesPanel from 'components/UI/BusinessTypesPanel';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import PipelineAttainmentWidget from 'components/dashboard/Pipeline/PipelineDashboard/PipelineAttainmentWidget';
import PipelineCreationWidget from 'components/dashboard/Pipeline/PipelineDashboard/PipelineCreationWidget';
import {
  NewPipelineCreatedWidget,
  PipelineByCloseDateWidget,
} from 'components/dashboard/Pipeline/PipelineDashboard/PipelineWidget';
import * as styles from 'components/dashboard/Pipeline/PipelineDashboard/styles';
import { IProps } from 'components/dashboard/Pipeline/PipelineDashboard/types';
import Tabs from 'components/dashboard/Tabs';

const PipelineDashboard: React.FC<IProps> = ({
  selectedBusinessType,
  filters,
  toggleFilters,
  tabPartition = 'pipeline',
  isRequiredDataReady,
}) => {
  useEffect(() => {
    AnalyticsTracker.event(
      {},
      {
        action: 'Open',
        category: 'Pipeline',
        label: 'Dashboard page',
      }
    );
  }, []);

  const handleClickWrapper = useCallback(() => {
    toggleFilters('topOpportunities', true);
  }, []);

  if (isEmpty(filters) || !isRequiredDataReady) {
    return <Loader active />;
  }

  return (
    <div onClick={handleClickWrapper}>
      <div className="wrapper">
        <div className="container">
          <div className="container-dashboard">
            <Tabs partition={tabPartition} wrapped />
            <div className={styles.stickyTop}>
              <OpenFiltersPanel tab="pipeline_dashboard" />
            </div>

            <BusinessTypesPanel tab="pipeline_dashboard" />

            <div className={styles.widgetsContainer}>
              <NewPipelineCreatedWidget
                selectedBusinessType={selectedBusinessType}
                localStorageKeyPrefix="PipelineDashboard"
              />
              <PipelineByCloseDateWidget
                selectedBusinessType={selectedBusinessType}
                localStorageKeyPrefix="PipelineDashboard"
              />
              <PipelineCreationWidget
                selectedBusinessType={selectedBusinessType}
              />
              <PipelineAttainmentWidget
                selectedBusinessType={selectedBusinessType}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PipelineDashboard;
