import * as s from './styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { Popup } from 'semantic-ui-react';

import * as CONSTANTS from 'components/UI/BuFormulaTextField/constants';
import {
  metricColorBlue,
  metricColorIndigo,
  metricColorPurple,
  metricColorYellow,
  metricColorLime,
  metricColorOrange,
} from 'components/UI/BuFormulaTextField/styles';
import { TooltipContent } from 'components/dashboard/Metrics/common/MetricTooltipContent/TooltipContent';
import {
  BIMetricFormula,
  BIMetricSimple,
} from 'components/dashboard/Metrics/metrics.types';
import { IReduxState } from 'reducers/types';
import * as metricSelectors from 'selectors/revbi/metrics';

interface IProps {
  formula: string;
  hasPopups?: boolean;
}

export const SyntheticFormulaPreview: React.FC<IProps> = ({
  formula,
  hasPopups = false,
}) => {
  const fetchAllMetricsLoadingStatus = useSelector(
    metricSelectors.getFetchingAllMetricsStatus
  );
  const metrics = useSelector((state: IReduxState) =>
    metricSelectors.getAllMetricsWithTS(state)
  );

  const colors = [
    metricColorLime,
    metricColorBlue,
    metricColorPurple,
    metricColorIndigo,
    metricColorYellow,
    metricColorOrange,
  ];

  const renderMetric = (
    element: BIMetricFormula | BIMetricSimple,
    index: number,
    colorsIndex: number
  ) => {
    const metricJSX = (
      <s.metricHighlighted
        key={`${element._id}_${index}`}
        className={colors[colorsIndex]}
      >
        {element.name}
      </s.metricHighlighted>
    );

    if (hasPopups) {
      return (
        <Popup
          key={element._id}
          basic
          hoverable
          position="bottom left"
          size="small"
          disabled={element === undefined}
          trigger={metricJSX}
          content={<TooltipContent metric={element} total={''} />}
        />
      );
    }

    return metricJSX;
  };

  const renderedFormula = React.useMemo(() => {
    const elements = [];
    let match;
    while ((match = CONSTANTS.FORMULA_PARSER_REGEXP.exec(formula)) !== null) {
      const isMetric =
        match[0].length > 1 && !CONSTANTS.NUMBERS_REGEXP.test(match[0]);
      const isSign =
        match[0].length === 1 && CONSTANTS.SIGNS_ARRAY.includes(match[0]);
      const isNumber =
        match[0].length >= 1 && CONSTANTS.NUMBERS_REGEXP.test(match[0]);

      if (isMetric) {
        const metricId = match[0].replace(new RegExp(/[{}]/gm), '');
        const metric = metrics.find((m) => m._id === metricId);
        if (metric) {
          elements.push(metric);
        }
      }
      if (isSign) {
        elements.push(match[0]);
      }
      if (isNumber) {
        elements.push(match[0]);
      }
    }
    let colorsIndex = 0;

    return elements.map((element, index) => {
      if (typeof element === 'string') {
        return (
          <s.symbolElement key={`${index}_${element}`}>
            {element}
          </s.symbolElement>
        );
      }

      if (typeof element === 'object') {
        if (colorsIndex > colors.length - 1) {
          colorsIndex = 0;
        }
        return renderMetric(element, index, colorsIndex++);
      }
    });
  }, [formula, metrics]);

  if (fetchAllMetricsLoadingStatus === 'loading') {
    return <Loader size="tiny" active inline />;
  }

  return <span>{renderedFormula}</span>;
};
