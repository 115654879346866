import { css } from 'emotion';

import { fontDefault, fontSubtitle } from 'assets/css/common';

export const wrapper = css`
  border: 1px solid var(--bu-gray-300);
  border-radius: 2px;
  margin-bottom: 20px;
`;

export const header = css`
  align-items: center;
  border-bottom: 1px solid var(--bu-gray-300);
  display: flex;
  font-size: 14px;
  padding: 14px 30px;
  ${fontSubtitle};
`;

export const filters = css`
  display: flex;
  flex-shrink: 0;
  margin-left: auto;
`;

export const dropdownContainer = css`
  font-size: 14px;
  margin-left: 16px;

  label {
    margin-right: 5px;
  }

  .ui.dropdown {
    height: 30px;
    min-height: 30px;
    min-width: 140px;
    padding-top: 6px;

    > .dropdown.icon {
      padding-top: 7px;
    }
  }
`;

export const dropdrown = css`
  max-height: 30px;
  display: flex;
  border: 1px solid var(--bu-gray-500);
  padding: 5px 8px;
  border-radius: 3px;
  cursor: pointer;
  background: var(--bu-white);
  position: relative;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: rgba(49, 92, 121, 0.12);
  }

  &.active {
    border-color: var(--bu-primary-500);
  }
`;

export const dropdrown_label = css`
  ${fontSubtitle};
  margin-right: 5px;
  font-size: 14px;
`;

export const dropdrown_value = css`
  ${fontDefault};
`;

export const dropdrown_options = css`
  &.open {
    display: block;
  }

  display: none;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  right: -2px;
  top: 36px;
  z-index: 1000;
  background: #fff;
  border-radius: 4px;
  width: max-content;
  max-height: 204px;
  overflow: auto;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
`;

export const dropdrown_options_item = css`
  padding: 7px 15px;
  font-size: 14px;

  &.active,
  &:hover {
    background: rgba(48, 176, 209, 0.08);
  }
`;

export const triangle = css`
  border-style: solid;
  height: 0;
  width: 0;
  border-width: 4px;
  border-color: var(--bu-gray-900);
  display: inline-block;
  margin: 0 2px 0 6px;

  &.bu-up {
    border-top-width: 0;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    transform: translateY(-50%);
    border-color: var(--bu-primary-500);
  }

  &.bu-down {
    border-bottom-width: 0;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    transform: translateY(-50%);
  }
`;
