import React from 'react';

import { AnalysisTypeSelector } from 'components/dashboard/Metrics/Create/AnalysisTypes/AnalysisTypeSelector/AnalysisTypeSelector';
import { MetricAnalysisTypeBlock } from 'components/dashboard/Metrics/Create/AnalysisTypes/MetricAnalysisTypes/styles';
import { AnalysisTypeSelectContainer } from 'components/dashboard/Metrics/Create/AnalysisTypes/common.styles';
import { DefinitionsTitle } from 'components/dashboard/Metrics/Create/widget.create.styles';
import { AnalysisType } from 'components/dashboard/Metrics/constants';

interface Props {
  selectedAnalysisType: AnalysisType;
  disabled: boolean;
  onChangeAnalysisType: (analysisType: AnalysisType) => void;
}

export const MetricAnalysisTypes: React.FC<Props> = ({
  selectedAnalysisType,
  disabled,
  onChangeAnalysisType,
}) => (
  <MetricAnalysisTypeBlock data-testing="data-type-section">
    <DefinitionsTitle>Data Type</DefinitionsTitle>
    <AnalysisTypeSelectContainer>
      <AnalysisTypeSelector
        analysisType={AnalysisType.LIVE}
        selectedAnalysisType={selectedAnalysisType}
        description="Current data available"
        disabled={disabled}
        onChangeAnalysisType={onChangeAnalysisType}
      />
      <AnalysisTypeSelector
        analysisType={AnalysisType.HISTORICAL}
        selectedAnalysisType={selectedAnalysisType}
        description="Snapshots of data recorded"
        disabled={disabled}
        onChangeAnalysisType={onChangeAnalysisType}
      />
    </AnalysisTypeSelectContainer>
  </MetricAnalysisTypeBlock>
);
