import {
  MONTH,
  MONTHLY,
  QUARTER,
  QUARTERLY,
  YEAR,
  YEARLY,
} from 'components/dashboard/Metrics/constants';

export const CUMULATIVE_SUM_OPTIONS: {
  text: string;
  value: string;
}[] = [
  { text: MONTHLY, value: MONTH },
  { text: QUARTERLY, value: QUARTER },
  { text: YEARLY, value: YEAR },
];
