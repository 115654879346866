import { createSelector } from 'reselect';

import { IReduxState } from 'reducers/types';

export const submissionForecast = (state: IReduxState) =>
  state.croOverride.submissionForecast;

export const submissionForecastStatus = (state: IReduxState) =>
  state.croOverride.submissionForecastStatus;

export const submissionForecastOverrides = (state: IReduxState) =>
  state.croOverride.submissionForecastOverrides;

export const submissionForecastHistory = (state: IReduxState) =>
  state.croOverride.submissionForecastHistory;

export const submissionForecastOverridesStatus = (state: IReduxState) =>
  state.croOverride.submissionForecastOverridesStatus;

export const submissionForecastHistoryStatus = (state: IReduxState) =>
  state.croOverride.submissionForecastHistoryStatus;

export const submissionBookedAndTargetStatus = (state: IReduxState) =>
  state.croOverride.submissionBookedAndTargetStatus;

export const submissionBookedAndTarget = (state: IReduxState) =>
  state.croOverride.submissionBookedAndTarget;

export const isSubmissionOverrideEnabled = (state: IReduxState) =>
  (
    state.settings?.companySettings?.submissions_overrides?.enabled_users || []
  ).includes(state.user.email) ||
  (
    state.settings?.companySettings?.submissions_overrides?.enabled_roles || []
  ).includes(state.user.role);

export const getSubmissionForecastInitiatorRoute = createSelector(
  [(state: IReduxState) => state.croOverride.submissionForecastInitiatorRoute],
  (route) => route
);
