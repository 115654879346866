import produce, { Draft } from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'actions';
import { ViewBy } from 'common/enums/filters';
import { ForecastSubmission } from 'components/settings/ForecastSettings/ForecastSubmissionTypes/types';
import {
  calcNewFilterState,
  getInitialData,
  parseRawDateFilter,
} from 'reducers/filters/helpers';
import { ColumnToggleState } from 'selectors/table';

type SelectedProfile = string;

export type ProfileData = {
  id?: string;
  deleteable?: boolean;
  view_type: Filters.CustomViewsType;
  state: Filters.FiltersState;
  initialState: Filters.FiltersState;
  business_type_name?: string;
  tablePersistName?: string;
  columnToggleState?: ColumnToggleState[];
  selectedBusinessType?: string;
  user?: { id: string };
  pinned?: boolean;
};

export type ProfileDataWithName = ProfileData & {
  name: string;
};

export type ProfileDataByTabDictionary = {
  [tab: string]: {
    profiles: {
      [profile: string]: ProfileData;
    };
  };
};

export type IState = {
  data: ProfileDataByTabDictionary;
  selectedProfiles: { [tab: string]: SelectedProfile };
  initialState: { [tab: string]: Filters.FiltersState };
  rawFragments: {
    users?: Filters.UserFilterElement[];
    user_role?: Filters.UserRoleFilterElement;
    forecast_category_names?: Filters.Default[];
    sales_managers?: Filters.UserFilterElement[];
  };
  internal: {
    activitySortBy: Filters.FiltersActivitySortBy;
  };
  settings: {
    initialState: { [tab: string]: Filters.FiltersState };
    available: Filters.BackendAvailableFiltersList;
    tabs: { [tab: string]: Filters.TabConfig };
    profiles: {
      [ProfileId: string]: {
        available: Filters.BackendAvailableFiltersList;
        data: { [tab: string]: Filters.FiltersState };
        initialState: { [tab: string]: Filters.FiltersState };
      };
    };
    globalDefaults: Filters.FiltersState;
  };
  status: {
    loaded: boolean;
    viewBy: ViewBy;
  };
};

export const initialState: IState = {
  data: {},
  selectedProfiles: {},
  initialState: {},
  rawFragments: {},
  internal: {
    activitySortBy: {
      field: 'count',
      order: 'desc',
    },
  },
  settings: {
    initialState: {},
    available: {},
    tabs: {},
    profiles: {},
    globalDefaults: {},
  },
  status: {
    loaded: false,
    viewBy: ViewBy.SELLER,
  },
};

const persist = (
  draft: Draft<IState>,
  payload: Filters.PersistFiltersParams
) => {
  const { name, tab } = payload;
  const selectedProfile = draft.selectedProfiles[tab];

  if (tab && selectedProfile) {
    const base = draft.data[tab].profiles[selectedProfile];

    if (base) {
      const prev = base.state[name];
      const toCompareWith = base.initialState[name];

      base.state[name] = {
        ...prev,
        ...calcNewFilterState(payload, prev, toCompareWith),
      };
    }
  }
};

const setBusinessType = (
  draft: Draft<IState>,
  payload: Filters.SetBusinessTypeCreator
) => {
  const { tab, businessType } = payload;
  const selectedProfile = draft.selectedProfiles[tab];

  if (tab && selectedProfile) {
    const base = draft.data?.[tab]?.profiles?.[selectedProfile];

    if (base) {
      base.business_type_name = businessType;
    }
  }
};

const persistSettings =
  (partition: Filters.FiltersPartition) =>
  (draft: Draft<IState>, payload: Filters.PersistSettingsFiltersParams) => {
    const { name, profileId, tab } = payload;

    if (profileId && !draft.settings.profiles[profileId]) {
      return;
    }

    if (partition === 'globalDefaults') {
      const prev = draft.settings.globalDefaults[name];

      draft.settings.globalDefaults[name] = {
        ...prev,
        ...calcNewFilterState(payload, prev),
      };
    } else if (partition === 'initialState') {
      const prev = draft[partition][tab][name];
      const toCompareWith = draft.settings.globalDefaults[name];
      const updated = calcNewFilterState(payload, prev, toCompareWith);

      draft[partition][tab][name] = {
        ...prev,
        ...updated,
      };
    } else if (tab && profileId) {
      const prev = draft.settings.profiles[profileId][partition][tab][name];
      const toCompareWith =
        partition === 'data'
          ? draft.settings.profiles[profileId].initialState[tab][name]
          : draft.settings.globalDefaults[name];

      draft.settings.profiles[profileId][partition][tab][name] = {
        ...prev,
        ...calcNewFilterState(payload, prev, toCompareWith),
      };
    }
  };

const addCustomView = (
  draft: Draft<IState>,
  {
    id,
    tab,
    name,
    viewType,
    deleteable,
    tablePersistName,
    columnToggleState,
    selectedBusinessType,
    user,
    pinned,
    previouslyPinnedId,
  }: Filters.AddCustomViewMeta
) => {
  const selectedProfile = draft.selectedProfiles[tab];

  if (selectedProfile) {
    const base = draft.data[tab].profiles;
    const old = base[selectedProfile];

    base[name] = {
      id,
      deleteable,
      view_type: viewType,
      state: cloneDeep(old.state),
      initialState: cloneDeep(old.state),
      tablePersistName,
      columnToggleState,
      selectedBusinessType,
      user,
      pinned,
    };

    old.state = cloneDeep(old.initialState);

    draft.selectedProfiles[tab] = name;

    // Unpin the previous one
    if (pinned && previouslyPinnedId) {
      const profiles = Object.keys(base);
      const previouslyPinned = profiles.find(
        (name) => base[name].id === previouslyPinnedId
      );
      if (previouslyPinned) {
        base[previouslyPinned].pinned = false;
      }
    }
  }
};

const updateCustomView = (
  draft: Draft<IState>,
  {
    id,
    tab,
    name,
    viewType,
    deleteable,
    tablePersistName,
    columnToggleState,
    selectedBusinessType,
    pinned,
    previouslyPinnedId,
    isPinningAction,
  }: Filters.AddCustomViewMeta
) => {
  const selectedProfile = draft.selectedProfiles[tab];

  if (selectedProfile) {
    const base = draft.data[tab].profiles;
    const profiles = Object.keys(base);

    const profile = profiles.find((name) => base[name].id === id);

    if (profile && profile !== name) {
      base[name] = base[profile];
      delete base[profile];

      if (selectedProfile === profile) {
        draft.selectedProfiles[tab] = name;
      }
    }

    if (!isPinningAction) {
      base[name].initialState = cloneDeep(base[name].state);
      base[name].deleteable = deleteable;
      base[name].view_type = viewType;
      base[name].tablePersistName = tablePersistName;
      base[name].columnToggleState = columnToggleState;
      base[name].selectedBusinessType = selectedBusinessType;
    }
    base[name].pinned = pinned;

    // Unpin the previous one
    if (pinned && previouslyPinnedId && previouslyPinnedId !== id) {
      const previouslyPinned = profiles.find(
        (name) => base[name].id === previouslyPinnedId
      );
      if (previouslyPinned) {
        base[previouslyPinned].pinned = false;
      }
    }
  }
};

const removeCustomView = (
  draft: Draft<IState>,
  { id, tab, hasPinned }: Filters.RemoveCustomViewMeta
) => {
  const selectedProfile = draft.selectedProfiles[tab];

  if (selectedProfile) {
    const base = draft.data[tab].profiles;
    const profiles = Object.keys(base);

    const defaultProfile = profiles.find(
      (name) => base[name].view_type === 'DEFAULT'
    );
    const profile = profiles.find((name) => base[name].id === id);

    if (profile && defaultProfile) {
      delete base[profile];
      if (!hasPinned) {
        draft.selectedProfiles[tab] = defaultProfile;
      }
    }
  }
};

const clearAll = (
  draft: Draft<IState>,
  { tab }: Filters.ClearFiltersParams
) => {
  const selectedProfile = draft.selectedProfiles[tab];

  if (selectedProfile) {
    const base = draft.data[tab].profiles[selectedProfile];
    base.state = cloneDeep(base.initialState);
  }
};

const resetToDefaults = (
  draft: Draft<IState>,
  { profileId, tab }: Filters.ClearFiltersParams
) => {
  if (profileId) {
    if (draft.settings.profiles[profileId]) {
      Object.keys(draft.settings.profiles[profileId].data[tab]).forEach(
        (name) => {
          if (draft.settings.profiles[profileId].data[tab][name].isChanged) {
            draft.settings.profiles[profileId].data[tab][name] = cloneDeep(
              draft.settings.profiles[profileId].initialState[tab][name]
            );
          }
        }
      );
    }
  } else {
    Object.keys(draft.initialState[tab]).forEach((name) => {
      if (draft.initialState[tab][name].isChanged) {
        draft.initialState[tab][name] = cloneDeep(
          draft.settings.globalDefaults[name]
        );
      }
    });
  }
};

const setForecastModalFilters = (
  draft: Draft<IState>,
  { tab, filters }: Filters.ForecastModalFilterParams
) => {
  const selectedProfile = draft.selectedProfiles[tab];
  const filtersWithDefaultFromSetting = draft.initialState[tab];

  if (selectedProfile) {
    const defaultFilterForModal = draft.initialState[tab];
    const filterForTabAndProfile =
      draft.data?.[tab]?.profiles?.[selectedProfile];
    if (filterForTabAndProfile) {
      filterForTabAndProfile.state = cloneDeep(defaultFilterForModal);

      filters.forEach(({ name, values }) => {
        const prev = filtersWithDefaultFromSetting[name];
        const newFilterState = {
          ...prev,
          ...calcNewFilterState(
            {
              withReset: true,
              values,
              tab: tab,
              name: name,
            },
            prev,
            prev
          ),
        };
        const valuesSelectedOnly = newFilterState.values.filter(
          (v) => v.checked
        );

        filterForTabAndProfile.state[name] = {
          ...newFilterState,
          values: valuesSelectedOnly,
        };
      });

      filterForTabAndProfile.initialState = filterForTabAndProfile.state;
    }
  }
};

const setExplicitly = (
  draft: Draft<IState>,
  { config, name, tab, partition, profileId }: Filters.SetExplicitlyParams
) => {
  if (partition === 'globalDefaults') {
    draft.settings.globalDefaults[name] = config;
  }

  if (partition === 'initialState') {
    if (profileId) {
      if (!draft.settings.profiles[profileId]) {
        return;
      }

      draft.settings.profiles[profileId].initialState[tab][name] = config;
    } else {
      draft.initialState[tab][name] = config;
    }
  }
};

const updateInternalActivitySortBy = (
  draft: Draft<IState>,
  activitySortBy: Partial<Filters.FiltersActivitySortBy>
) => {
  draft.internal.activitySortBy = cloneDeep({
    ...draft.internal.activitySortBy,
    ...activitySortBy,
  });
};

const updateViewBy = (draft: Draft<IState>, viewBy: ViewBy) => {
  draft.status.viewBy = viewBy;
};

const setActiveProfile = (
  draft: Draft<IState>,
  { profile, tab }: Filters.SetActiveProfile
) => {
  const config = draft.data[tab];
  const selectedProfile = draft.selectedProfiles[tab];

  if (config && selectedProfile !== profile) {
    draft.selectedProfiles[tab] = profile;
  }
};

const reloadRollUpsFilters = (
  draft: Draft<IState>,
  {
    rollUps,
    state,
  }: {
    rollUps: Filters.FiltersLoadedParams['rollUps'];
    state: Filters.AddTabParams['filters'];
  }
) => {
  const profileName = 'Roll Ups';
  const tabName = 'forecast_roll_ups';
  const newState: Filters.FiltersState = {};

  Object.keys(rollUps).forEach((filterName) => {
    const cdi = rollUps.close_date_interval;

    const parsedRollUps = parseRawDateFilter(
      cdi.fields,
      undefined,
      cdi.title,
      cdi.allow_future,
      cdi.custom_start,
      cdi.custom_end
    );

    const source = parsedRollUps;
    source.isLocked = false;

    const result = calcNewFilterState(
      {
        tab: tabName,
        name: filterName,
        values: cdi.preset_values.map((id) => ({
          id,
          checked: true,
        })),
        withReset: true,
      },
      source
    );

    const final = {
      ...source,
      values: result.values,
      checkedAll: result.checkedAll,
      checkedTotal: result.checkedTotal,
    } as Filters.Config;

    newState[filterName] = cloneDeep(final);
  });

  const newProfileData: {
    state: Filters.FiltersState;
    initialState: Filters.FiltersState;
    view_type: Filters.CustomViewsType;
  } = {
    state: {
      ...state,
      ...newState,
    },
    initialState: {
      ...state,
      ...newState,
    },
    view_type: 'DEFAULT',
  };

  if (draft.data[tabName]) {
    draft.data[tabName].profiles[profileName] = {
      ...draft.data[tabName].profiles[profileName],
      ...newProfileData,
    };
  } else {
    draft.data[tabName] = {
      profiles: {
        [profileName]: newProfileData,
      },
    };
  }
};

const loaded = (
  draft: Draft<IState>,
  { available, filters, state, user, rollUps }: Filters.FiltersLoadedParams
) => {
  const customFilters = filters.custom_filters || {};
  const customAccountFilters = filters.custom_account_filters || {};

  const allFilters = {
    ...customFilters,
    ...filters,
  };

  const fullFiltersList = {
    ...allFilters,
    ...customAccountFilters,
  };

  const cdi = rollUps.close_date_interval;

  const parsedRollUps = parseRawDateFilter(
    cdi.fields,
    undefined,
    cdi.title,
    cdi.allow_future,
    cdi.custom_start,
    cdi.custom_end
  );

  const initialData: Filters.FiltersState = getInitialData(filters, user);

  const parsedFilters = Object.keys(initialData);

  state.forEach((profile) => {
    const tabs = Object.keys(profile.fields);

    tabs.forEach((tab) => {
      const {
        display_name,
        enabled_filters = [],
        locked_filters = [],
        preset_values,
        section,
        columnToggleState,
        tablePersistName,
        selectedBusinessType,
      } = profile.fields[tab] || {};

      const filtersState: Filters.FiltersState = {};
      const settingsInitialState: Filters.FiltersState = {};

      const enabledFilters = enabled_filters;

      const mapFilters = (name: string) => {
        const isRollUpsCdi =
          tab === 'forecast_roll_ups' && name === 'close_date_interval';
        const loadPresetFromCdi =
          isRollUpsCdi && profile.view_type === 'DEFAULT' && cdi.preset_values;

        const presetValues = loadPresetFromCdi
          ? cdi.preset_values
          : preset_values[name] ||
            (profile.view_type === 'DEFAULT'
              ? (fullFiltersList[name].preset_values as Filters.Value[])
              : []);

        const source = isRollUpsCdi ? parsedRollUps : initialData[name];
        source.isLocked = locked_filters.includes(name);

        if (isEmpty(presetValues)) {
          return source;
        }

        const result = calcNewFilterState(
          {
            tab,
            name,
            values: presetValues.map((id) => ({
              id,
              checked: true,
            })),
            withReset: true,
          },
          source
        );

        return {
          ...source,
          values: result.values,
          checkedAll: result.checkedAll,
          checkedTotal: result.checkedTotal,
        };
      };

      enabledFilters
        .filter((name) => parsedFilters.includes(name))
        .forEach((name) => {
          filtersState[name] = mapFilters(name);
        });

      if (profile.view_type === 'DEFAULT') {
        parsedFilters.forEach((name) => {
          settingsInitialState[name] = mapFilters(name);
        });

        draft.initialState[tab] = cloneDeep(filtersState);
        draft.settings.initialState[tab] = cloneDeep(settingsInitialState);

        draft.settings.tabs[tab] = {
          title: display_name,
          section,
        };
      }

      const newProfileData = {
        id: profile.id,
        deleteable: profile.deleteable,
        view_type: profile.view_type,
        state: cloneDeep(filtersState),
        initialState: cloneDeep(filtersState),
        columnToggleState,
        tablePersistName,
        selectedBusinessType,
        pinned: profile.pinned,
        ...(profile.view_type === 'DEFAULT' ? {} : { user: profile.user }),
      };

      if (draft.data[tab]) {
        draft.data[tab].profiles[display_name] = newProfileData;
      } else {
        draft.data[tab] = {
          profiles: {
            [display_name]: newProfileData,
          },
        };
      }

      if (profile.view_type === 'DEFAULT' && !draft.selectedProfiles[tab]) {
        draft.selectedProfiles[tab] = display_name;
      }
    });
  });

  draft.settings.available = cloneDeep(available);
  draft.settings.globalDefaults = cloneDeep(initialData);

  draft.rawFragments.users = filters.users.fields as any;
  draft.rawFragments.sales_managers = filters.sales_managers
    .fields as Filters.UserFilterElement[];
  draft.rawFragments.user_role = filters.user_role.fields;
  draft.rawFragments.forecast_category_names = filters.forecast_category_names
    ? (filters.forecast_category_names.fields as any)
    : [];
  draft.status.loaded = true;
};

const addForecastDealsCustomFiltersTab = (
  draft: Draft<IState>,
  {
    filter,
    filters,
  }: {
    filter: ForecastSubmission;
    filters: Filters.FiltersState;
  }
) => {
  const tab = `forecast_deals_modal_${filter.id}`;
  const display_name = `Deals Modal ${filter.id}`;
  const defaultFilterValues = filter.deals_filter?.default_filter_values || {};

  let enabledFilters: string[] = [];
  let preset_values: Record<string, string[]> = {};
  let locked_filters: Record<string, boolean> = {};

  const getIsAll = (filterValues: string[]) =>
    isEmpty(filterValues) || filterValues?.indexOf('__all__') !== -1;

  Object.keys(defaultFilterValues || {}).forEach((filterValuesName) => {
    const isAll = getIsAll(defaultFilterValues[filterValuesName]);

    enabledFilters = [...enabledFilters, filterValuesName];
    preset_values[filterValuesName] = defaultFilterValues[filterValuesName];
    locked_filters[filterValuesName] =
      !isAll && defaultFilterValues[filterValuesName].length < 2;
  });

  const filtersState: Filters.FiltersState = {};
  const settingsInitialState: Filters.FiltersState = {};

  const parsedFilters = Object.keys(filters || {});

  const mapFilters = (name: string) => {
    /**
     * When loading custom filters on deals modal we need to load a different
     * filter config from the filters list, but still using the other info from
     * the main filter (e.g. default values, labels)
     */
    const _name =
      name !== 'close_date_interval' ? name : 'forecast_close_date_interval';
    const presetValues = preset_values[name];
    const isAll = getIsAll(presetValues);
    const source = filters
      ? cloneDeep(filters[_name] as Filters.Config)
      : ({} as Filters.Config);
    source.isLocked = locked_filters[name];

    source.values =
      !isAll && presetValues.length > 1
        ? source.values.filter(
            (value) =>
              presetValues.indexOf(value.value) !== -1 ||
              value.value === '__all__'
          )
        : source.values;

    if (isEmpty(presetValues)) {
      return source;
    }

    const result = calcNewFilterState(
      {
        tab,
        name,
        values: presetValues.map((id) => ({ id, checked: true })),
        withReset: true,
      },
      source
    );

    return {
      ...source,
      values: result.values,
      checkedAll: isAll || result.checkedAll,
      checkedTotal: result.checkedTotal,
    };
  };

  enabledFilters
    .filter((name) => parsedFilters.includes(name))
    .forEach((name) => {
      filtersState[name] = mapFilters(name);
    });

  parsedFilters.forEach((name) => {
    settingsInitialState[name] = mapFilters(name);
  });

  draft.initialState[tab] = cloneDeep(filtersState);
  draft.settings.initialState[tab] = cloneDeep(settingsInitialState);
  draft.settings.tabs[tab] = { title: display_name, section: '' };

  const newProfileData: ProfileData = {
    deleteable: false,
    view_type: 'DEFAULT',
    state: cloneDeep(filtersState),
    initialState: cloneDeep(filtersState),
  };

  draft.data[tab] = {
    profiles: {
      [display_name]: newProfileData,
    },
  };

  draft.selectedProfiles[tab] = display_name;
};

const profileFiltersLoaded = (
  draft: Draft<IState>,
  {
    filtersState,
    profileId,
  }: {
    filtersState: Filters.BackendFiltersState;
    profileId: string;
  }
) => {
  draft.settings.profiles[profileId] = {
    available: {},
    data: {},
    initialState: {},
  };
  Object.keys(filtersState).forEach((tabName) => {
    draft.settings.profiles[profileId].available[tabName] = {
      available_filters: filtersState[tabName].available_filters || [],
      enabled_filters: filtersState[tabName].enabled_filters,
    };

    draft.settings.profiles[profileId].initialState[tabName] = cloneDeep(
      draft.settings.initialState[tabName]
    );

    (filtersState[tabName].available_filters || []).forEach((filterName) => {
      if (!isEmpty(filtersState[tabName].preset_values)) {
        const source = draft.settings.initialState[tabName][filterName] || {};

        source.isLocked =
          filtersState[tabName].locked_filters.includes(filterName);

        const result = calcNewFilterState(
          {
            tab: '',
            name: '',
            values: (filtersState[tabName].preset_values[filterName] || []).map(
              (id) => ({
                id,
                checked: true,
              })
            ),
            withReset: true,
          },
          source
        );

        draft.settings.profiles[profileId].initialState[tabName][filterName] = {
          ...source,
          values: result.values,
          checkedAll: result.checkedAll,
          checkedTotal: result.checkedTotal,
        };
      }
    });

    draft.settings.profiles[profileId].data = cloneDeep(
      draft.settings.profiles[profileId].initialState
    );
  });
};

const updateProfileFilters = (
  draft: Draft<IState>,
  { filters, pages, filterName }: Filters.InjectFilters
) => {
  pages.forEach((page) => {
    const profilesKeys = Object.keys(draft.data[page].profiles);
    let updatedProfiles = { ...draft.data[page].profiles };

    profilesKeys.forEach((profile) => {
      updatedProfiles[profile] = {
        ...updatedProfiles[profile],
        state: {
          ...updatedProfiles[profile].state,
          [filterName]: {
            ...updatedProfiles[profile].state[filterName],
            checkedTotal: filters.length,
            values: [
              { checked: true, label: 'All', value: '__all__' },
              ...filters.map((filter) => ({
                checked: false,
                label: filter.display_name,
                value: filter.value,
              })),
            ],
          },
        },
      };
    });

    draft.data[page].profiles = { ...updatedProfiles };
  });
};

const reducer = reducerWithInitialState<IState>(initialState)
  // filters
  .case(actions.ui.filters.clearAll, produce(clearAll))
  .case(actions.ui.filters.loaded, produce(loaded))
  .case(actions.ui.filters.reloadRollUpsFilters, produce(reloadRollUpsFilters))
  .case(
    actions.ui.filters.addForecastDealsCustomFiltersTab,
    produce(addForecastDealsCustomFiltersTab)
  )
  .case(actions.ui.filters.persist, produce(persist))
  .case(
    actions.ui.filters.setForecastModalFilters,
    produce(setForecastModalFilters)
  )
  .case(actions.ui.filters.updateSortBy, produce(updateInternalActivitySortBy))
  .case(actions.ui.filters.updateViewBy, produce(updateViewBy))
  .case(actions.ui.filters.updateProfileFilters, produce(updateProfileFilters))
  .case(actions.ui.filters.setActiveProfile, produce(setActiveProfile))
  .case(actions.ui.filters.onAddCustomView, produce(addCustomView))
  .case(actions.ui.filters.onUpdateCustomView, produce(updateCustomView))
  .case(actions.ui.filters.onRemoveCustomView, produce(removeCustomView))
  .case(actions.ui.filters.setBusinessType, produce(setBusinessType))

  // filters settings
  .case(
    actions.ui.filtersSettings.persistPageDefaults,
    produce(persistSettings('initialState'))
  )
  .case(
    actions.ui.filtersSettings.persistProfileFilters,
    produce(persistSettings('data'))
  )
  .case(
    actions.ui.filtersSettings.persistGlobalDefaults,
    produce(persistSettings('globalDefaults'))
  )
  .case(actions.ui.filtersSettings.resetToDefaults, produce(resetToDefaults))
  .case(
    actions.ui.filtersSettings.profileFiltersLoaded,
    produce(profileFiltersLoaded)
  )
  .case(actions.ui.filtersSettings.setExplicitly, produce(setExplicitly));

export const openFilters = { initialState, reducer };
