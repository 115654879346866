import { BaseMetricsWidgetControls } from './BaseMetricsWidgetControls';
import React, { useMemo, Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { BIChartTypes } from 'common/enums/metrics';
import { groupColumns } from 'components/dashboard/Metrics/Widget/Controls/helpers';
import {
  BIMetricColumn,
  BIMetricToChartType,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { IReduxState } from 'reducers/types';
import * as widgetSelectors from 'selectors/revbi/widgets';

interface Props {
  isTargetMetricOnlyWidget: boolean;
  hasTargetMetric: boolean;
  showControls?: boolean;
  firstPivotColumns: BIMetricColumn[];
  secondPivotColumns: BIMetricColumn[];
  pivot1?: BIMetricColumn;
  pivot2?: BIMetricColumn;
  metricToChartType: BIMetricToChartType[];
  setPivot1: Dispatch<SetStateAction<BIMetricColumn | undefined>>;
  setPivot2: Dispatch<SetStateAction<BIMetricColumn | undefined>>;
  setWidget: (widget: Partial<BIWidget>) => void;
  setMetricToChartType: Dispatch<SetStateAction<BIMetricToChartType[]>>;
}

export const MetricsWidgetPreviewControls: React.FC<Props> = ({
  isTargetMetricOnlyWidget = false,
  hasTargetMetric = false,
  showControls = false,
  firstPivotColumns,
  secondPivotColumns,
  pivot1,
  pivot2,
  metricToChartType,
  setPivot1,
  setPivot2,
  setWidget,
  setMetricToChartType,
}) => {
  const match = useRouteMatch<{ widgetId: string }>();

  const widget = useSelector((state: IReduxState) =>
    widgetSelectors.getActiveWidget(state, match.params.widgetId)
  );

  const [dateTypeOptionsFirstPivot, groupByColumnsFirstPivot] = useMemo(() => {
    const dateTypes = firstPivotColumns.filter(
      (column) => column.type === 'date'
    );
    const groupedColumns = groupColumns(firstPivotColumns, dateTypes);

    return [dateTypes, groupedColumns];
  }, [firstPivotColumns]);

  const groupByColumnsSecondPivot = useMemo(() => {
    const dateTypes = secondPivotColumns.filter(
      (column) => column.type === 'date'
    );
    const groupedColumns = groupColumns(secondPivotColumns, dateTypes);

    return groupedColumns;
  }, [secondPivotColumns]);

  const handlePivot1Change = (values: string[]): void => {
    const column = groupByColumnsFirstPivot.find(
      (column) => column.name === values[0]
    );
    setPivot1(column);

    const isDateTypePivot =
      column?.type === 'date' || column?.type === 'timePeriod';
    let targetDateIndex = -1;
    const newFilters = widget.template_filters || [];

    if (isDateTypePivot && hasTargetMetric) {
      const idx = widget.template_filters?.findIndex(
        (f) => f.column.name === 'target.target_date'
      );
      targetDateIndex = idx ?? -1;
      if (targetDateIndex > -1) {
        newFilters.splice(targetDateIndex, 1);
      }
    }

    if (column?.type === 'timePeriod') {
      setWidget({
        ...widget,
        time_field: dateTypeOptionsFirstPivot.find((timePeriod) =>
          column.label.includes(timePeriod.label)
        ),
        group_by: [
          column.label.includes('MoM')
            ? {
                name: 'month',
                label: 'Month',
                type: 'date',
              }
            : column.label.includes('QoQ')
            ? {
                name: 'quarter',
                label: 'Quarter',
                type: 'date',
              }
            : { name: 'week', label: 'Week', type: 'date' },
        ],
        ...(targetDateIndex > -1 && {
          template_filters: newFilters,
        }),
      });
    } else if (column?.name === 'none') {
      setWidget({ ...widget, group_by: [] });
    } else if (column) {
      setWidget({
        ...widget,
        group_by: [column],
        ...(targetDateIndex > -1 && {
          template_filters: newFilters,
        }),
      });
    }
  };

  const handlePivot2Change = (values: string[]): void => {
    const column = groupByColumnsSecondPivot.find(
      (column) => column.name === values[0]
    );
    setPivot2(column);

    if (column?.name === 'none') {
      setWidget({
        ...widget,
        group_by: widget.group_by ? [widget.group_by[0]] : undefined,
      });
    } else if (column) {
      const isTableVisualization = metricToChartType.some(
        (el) => el.chartType === BIChartTypes.Table
      );
      const mtc = metricToChartType.map((el) => ({
        ...el,
        chartType: isTableVisualization
          ? BIChartTypes.Table
          : BIChartTypes.ColumnStacked,
      }));

      setWidget({
        ...widget,
        group_by:
          widget.group_by && widget.group_by[0]
            ? [widget.group_by[0], column]
            : [column],
        properties: {
          ...widget.properties,
          metricToChartType: mtc,
        },
        chart_type: isTableVisualization
          ? BIChartTypes.Table
          : BIChartTypes.ColumnStacked,
      });
      setMetricToChartType([...mtc]);
    }
  };

  return (
    <BaseMetricsWidgetControls
      isTargetMetricOnlyWidget={isTargetMetricOnlyWidget}
      showControls={showControls}
      firstPivotColumns={firstPivotColumns}
      secondPivotColumns={secondPivotColumns}
      pivot1={pivot1}
      pivot2={pivot2}
      widgetChartType={widget.chart_type || 'column'}
      onPivot1Change={handlePivot1Change}
      onPivot2Change={handlePivot2Change}
    />
  );
};
