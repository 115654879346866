import { css } from 'emotion';

// new styles
export const table = css`
  box-sizing: border-box;
  border: 1px solid var(--bu-gray-500);
  border-radius: 5px;
  background-color: var(--bu-white);
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  color: var(--bu-gray-900);
  position: relative;
  z-index: 1;
`;

export const table_header = css`
  position: relative;
  z-index: 1;

  th {
    border-bottom: 1px solid var(--bu-gray-500);
  }
`;

export const table_header_column = css`
  padding: 0 10px;
  color: var(--bu-gray-700);
  position: relative;
  width: 10%;

  .bu-icon {
    display: none;
  }

  &.sortable {
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      > span {
        flex: 1;
      }
    }

    &:hover > div > .bu-icon {
      display: inline-block;
    }

    &.sorting > div > {
      span {
        color: var(--bu-gray-900);
      }

      .bu-icon {
        display: inline-block;
        color: var(--bu-primary-500);
      }
    }
  }
`;

export const table_sticky_header = css`
  border-collapse: separate !important;

  & thead tr:first-child > th {
    position: sticky !important;
    top: 0;
    background-color: var(--bu-gray-200) !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

export const table_body_row = css`
  td {
    border-bottom: 1px solid var(--bu-gray-500);
  }

  &:hover {
    background: var(--bu-gray-200);
  }

  &:last-child td {
    border-bottom: none;
  }
`;

export const body_row_column = css`
  padding: 5px 10px;
`;

export const time = css`
  &.green {
    color: #0ba5a3;
  }

  &.red {
    color: #ee724e;
  }
`;

export const filters_container = css`
  display: flex;
  margin-bottom: 10px;
`;

export const tabs = css`
  display: flex;
  margin-right: 10px;
  justify-content: flex-end;
  width: 100%;
`;

export const section_header = css`
  color: var(--bu-gray-900);
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const filters = css`
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
`;

export const timespan_container = css`
  display: flex;
  align-items: center;
  color: var(--bu-gray-900);
  background: var(--bu-white);
  border-radius: var(--bu-control-border-radius);
  border: 1px solid var(--bu-gray-500);
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: var(--bu-gray-400);
  }

  .dropdown {
    padding: 5px 5px 5px 12px !important;
    display: flex;
    align-items: center;

    .menu {
      right: 0;
      width: 100%;
    }
  }
`;

export const nothing_found = css`
  padding: 30px;
  color: var(--bu-gray-700);
  text-align: center;
`;

export const box_btn_show = css`
  text-align: center;
  padding: 10px;
`;

export const btn_tab = css`
  display: inline-block;
  padding: 5px 10px;
  margin-right: 15px;
  align-items: center;
  cursor: pointer;
  font-family: var(--bu-font-regular);
  font-size: 13px;
  font-weight: 300;
  color: #777777;
  border-radius: 2px;
  background-color: #f4f7f8;
  outline: 0;
  border: none;
  height: 30px;
  transition: 0.4s;
  width: 100%;
  max-width: 220px;
  min-width: 150px;

  &.active {
    color: var(--bu-white);
    background-color: #4a4a4a;
  }

  &:hover {
    color: var(--bu-white);
    background-color: #4a4a4a;
  }
`;
