import React, { useContext, useMemo, useRef } from 'react';

import Table from 'components/UI/TableConfig/Table';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import {
  getColumnsConfig,
  getColumnsConfigWithExtraHeader,
} from 'components/dashboard/Metrics/Widget/Table/helpers/columnsHelpers';
import {
  addSubTotalRows,
  remapDatePivotRows,
  getNonDatePivotRows,
} from 'components/dashboard/Metrics/Widget/Table/helpers/rowsHelpers';
import * as s from 'components/dashboard/Metrics/Widget/Table/styles';
import useTableSort from 'components/dashboard/Metrics/Widget/Table/useTableSort';
import { UserSettingsContext } from 'components/dashboard/Metrics/contexts/UserSettingsContext';
import {
  BIMetricsMap,
  BIWidgetDataV2,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  sortByColumn?: string;
  widgetData: BIWidgetDataV2;
  metricsCount: number;
  metricsInUse: BIMetricsMap;
  availablePivots: number;
  isDateFirstPivot: boolean;
  onTableDataClick: (column: IColumn, row: IRow) => void;
  onTableSortChange?: (columnName: string) => void;
}

export const WidgetTable: React.FC<Props> = ({
  sortByColumn,
  widgetData,
  metricsCount,
  metricsInUse,
  availablePivots,
  isDateFirstPivot,
  onTableDataClick,
  onTableSortChange,
}) => {
  const { currency: companyCurrencyCode } = useContext(UserSettingsContext);

  const pivot1FieldName =
    widgetData?.pivot_1?.columns[widgetData?.pivot_1?.columns.length - 1]
      ?.field_name || '';
  const [sortByField, setSortByField, sortBy] = useTableSort<IRow>(
    sortByColumn || pivot1FieldName
  );

  const isPivot1Descending = useRef<boolean>(false);

  const { columns, extraHeader } = useMemo(() => {
    if (metricsCount > 0 && availablePivots > 0) {
      if (isDateFirstPivot) {
        return getColumnsConfigWithExtraHeader(
          widgetData,
          availablePivots,
          companyCurrencyCode,
          metricsCount,
          metricsInUse,
          onTableDataClick
        );
      }

      return {
        columns: getColumnsConfig(
          companyCurrencyCode,
          widgetData,
          availablePivots,
          metricsInUse,
          onTableDataClick
        ),
        extraHeader: undefined,
      };
    }

    return { columns: [], extraHeader: [] };
  }, [
    metricsCount,
    availablePivots,
    companyCurrencyCode,
    widgetData,
    isDateFirstPivot,
  ]);

  const tableData = useMemo(() => {
    if (metricsCount > 0 && availablePivots > 0) {
      if (isDateFirstPivot) {
        return remapDatePivotRows(availablePivots, widgetData);
      } else {
        return getNonDatePivotRows(availablePivots, widgetData);
      }
    }

    return [];
  }, [availablePivots, metricsCount, widgetData, isDateFirstPivot]);

  const rowsData = useMemo(() => {
    if (tableData.length) {
      const sorted = sortBy(tableData);

      const isDescending = sortByField?.[0] ? sortByField?.[0] === '-' : true;

      onTableSortChange && onTableSortChange(sortByField ?? '');

      if (availablePivots === 1) {
        // For this case we add flag isLastRow in order to apply bottom border
        if (!isDateFirstPivot) {
          sorted[sorted.length - 1] = {
            ...sorted[sorted.length - 1],
            isLastRow: true,
          };
        }
        return sorted;
      }

      if (!isDateFirstPivot) {
        const fieldPath = (sortByField ?? '')
          .slice(isDescending ? 1 : 0)
          .split('.');

        if (fieldPath[0] === pivot1FieldName) {
          isPivot1Descending.current = isDescending;
        }
      }

      return addSubTotalRows(
        sorted,
        availablePivots,
        isDateFirstPivot,
        isPivot1Descending.current,
        sortByField,
        widgetData
      );
    }

    return [];
  }, [sortByField, tableData, sortBy]);

  const headerClassName = useMemo(() => {
    if (isDateFirstPivot) {
      if (metricsCount === 1) {
        return s.extraHeaderTableCellBordersSingleMetric;
      } else {
        if (availablePivots === 2) {
          return s.extraHeaderTwoPivotsTableCellBorders(metricsCount);
        } else {
          return s.extraHeaderSinglePivotTableCellBorders(metricsCount);
        }
      }
    }

    return s.tableHeaderCellBorders;
  }, [isDateFirstPivot, metricsCount, availablePivots]);

  return (
    <Table
      fullscreen
      hidePaginationEnd
      hidePaginationStart
      hideSearch
      stickyColumn={availablePivots === 2}
      columns={columns}
      extraHeader={extraHeader}
      data={rowsData}
      rowsPerPage={10}
      currentPage={0}
      className={headerClassName}
      sortOrder={sortByField}
      onSort={setSortByField}
    />
  );
};
