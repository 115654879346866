import { css } from 'emotion';

export const icon = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 0;

  .bu-icon {
    font-size: 1.7em;
    margin-right: 8px;
    margin-left: 8px;
  }
`;

export const popup = css`
  color: var(--bu-red-500);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  padding: 4px 8px;
`;
