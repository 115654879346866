import styled from '@emotion/styled';
import React from 'react';

import { ConditionsDescription } from 'components/dashboard/Metrics/common/ConditionsDescription/ConditionsDescription';
import { MetricAdvancedOptionsList } from 'components/dashboard/Metrics/common/MetricAdvancedOptionsList/MetricAdvancedOptionsList';
import { MetricTag } from 'components/dashboard/Metrics/common/MetricTag/MetricTag';
import { BIMetrics } from 'components/dashboard/Metrics/metrics.types';

const MetricFiltersDescription = styled.div({
  color: 'var(--bu-gray-700)',
  fontSize: '12px',
  ul: {
    marginBottom: '0',
    paddingLeft: '20px',
  },
});

interface Props {
  readonly metric: BIMetrics;
  readonly metricDescription: string | JSX.Element;
}

export const MetricInfo: React.FC<Props> = ({ metric, metricDescription }) => (
  <>
    {metricDescription && (
      <>
        {metric.synthetic_metric ? (
          metricDescription
        ) : (
          <MetricTag
            metricDescription={metricDescription}
            aggregationFunction={metric?.aggregation_function || ''}
          />
        )}
      </>
    )}

    {metric?.filters && metric.filters.length > 0 && (
      <MetricFiltersDescription>
        <ConditionsDescription filters={metric.filters} />
      </MetricFiltersDescription>
    )}

    {((metric?.is_cumulative_sum && metric?.cumulative_sum_period) ||
      metric?.is_hierarchy_rollup_sum) && (
      <MetricFiltersDescription>
        <MetricAdvancedOptionsList
          isHierarchyRollupSum={metric?.is_hierarchy_rollup_sum}
          isCumulativeSum={metric?.is_cumulative_sum}
          cumulativeSumPeriod={metric?.cumulative_sum_period}
        />
      </MetricFiltersDescription>
    )}
  </>
);
