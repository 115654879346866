import { NumberDropdownOption } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/types';
import { IOption } from 'components/dashboard/Metrics/metrics.types';

export const NUMBER_OPERATION_DROPDOWN_OPTIONS: NumberDropdownOption[] = [
  { text: 'All', value: 'all', symbol: 'all' },
  { text: '< Less than', value: 'lt', symbol: '<' },
  { text: '≤ Less than or equal to', value: 'lte', symbol: '≤' },
  { text: '= Equals to', value: 'eq', symbol: '=' },
  { text: '≥ Greater than or equal to', value: 'gte', symbol: '≥' },
  { text: '> Greater than', value: 'gt', symbol: '>' },
];

export const BOOL_OPTIONS: IOption[] = [
  { text: 'All', value: 'all' },
  { text: 'True', value: 'true' },
  { text: 'False', value: 'false' },
];

export const ALL_OPTION = {
  text: 'All',
  value: '__all__',
};

export const ALL_ACTIVE_OPTION = {
  text: 'All active',
  value: '__all_active__',
};
