import Highcharts from 'highcharts';

import {
  CustomDictionary,
  isSankeyLink,
  isSankeyNode,
  SankeyLink,
  SankeyNode,
} from 'components/dashboard/ForecastPipelineStage/types';

const isConnectedNodeSelected = (link: SankeyLink) =>
  link.fromNode?.selected || link.toNode?.selected;

/**
 * Highcharts mixin for Sankey chart, it is adding gradient color for links,
 * color is calculated using connected Sankey node
 */
export default function (H: any) {
  H.seriesTypes.sankey.prototype.pointAttribs = function (
    point: SankeyNode<CustomDictionary> | SankeyLink<CustomDictionary>,
    state: false | Highcharts.PointStateValue | ''
  ) {
    let opacity = this.options.linkOpacity,
      color = this.options.states.normal.color ?? point.color,
      toColor =
        this.options.states.normal.color ??
        (isSankeyLink(point) ? point.toNode.color : point.color),
      attr: { zIndex?: number } = { zIndex: undefined };

    const atLeastOneNodeSelected = this.nodes.some(
      (node: Highcharts.Point) => node.selected
    );

    if (state) {
      opacity = this.options.states[state].linkOpacity ?? opacity;
      color = this.options.states[state].color ?? point.color;
      toColor =
        this.options.states[state].color ??
        (isSankeyLink(point) ? point.toNode.color : point.color);
    }

    if (atLeastOneNodeSelected) {
      opacity = this.options.states.inactive.linkOpacity ?? opacity;
      color = this.options.states.inactive.color ?? point.color;
      toColor =
        this.options.states.inactive.color ??
        (isSankeyLink(point) ? point.toNode.color : point.color);
    }

    if (
      isSankeyNode(point) &&
      (point.linksFrom?.some(isConnectedNodeSelected) ||
        point.linksTo?.some(isConnectedNodeSelected))
    ) {
      opacity = this.options.states.select.linkOpacity ?? opacity;
      color = point.color;
    }

    if (state === 'inactive') {
      attr.zIndex = -1;
    }

    if (
      !atLeastOneNodeSelected &&
      isSankeyNode(point) &&
      (state === '' || state === false)
    ) {
      color = point.color;
    }

    if (
      isSankeyLink(point) &&
      (point.fromNode?.selected || point.toNode?.selected)
    ) {
      opacity = this.options.states.select.linkOpacity ?? opacity;
      color = point.color;
      toColor = point.toNode.color;
    }

    if (isSankeyLink(point)) {
      const dataLabels = ((point as unknown) as {
        dataLabels: Highcharts.SVGElement[];
      }).dataLabels;

      dataLabels?.forEach((label) =>
        label[Math.floor(opacity) ? 'show' : 'hide']()
      );
    }

    if (isSankeyLink(point)) {
      (point as any).graphic[opacity ? 'show' : 'hide']();
      (point as any).graphic.attr(attr);

      return {
        opacity: opacity,
        fill: {
          linearGradient: {
            x1: 0,
            x2: 1,
            y1: 0,
            y2: 0,
          },
          stops: [
            [0, H.color(color).get()],
            [1, H.color(toColor).get()],
          ],
        },
      };
    }

    return {
      fill: color,
    };
  };
}
