import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';

import { actions } from 'actions';
import {
  SubmissionColumn,
  setNavigatedFromRoute,
} from 'actions/croOverrideActions';
import { fetchForecastSubmissionsSettingsAvailable } from 'actions/forecastActions';
import { formatMoney } from 'common/numbers';
import { IOption } from 'components/UI/BuGroupButton/types';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import { setRowExpand, treeify } from 'components/UI/common/TypedTable/helper';
import { teamRowBGColor } from 'components/dashboard/CROOverride/styles';
import {
  isCRORow,
  RowSubmission,
  RowTypeFieldName,
} from 'components/dashboard/CROOverride/types';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import { SubmissionForecastState } from 'reducers/croOverrideReducer';
import * as selectors from 'selectors';

export const ALL_TEXT = 'All';

export const cf = (companyCurrency: string) => (value: number) =>
  formatMoney(companyCurrency, value, 0);

/**
 *  Returns Dropdown Options list, in order to filter visible columns
 */
export const getAvailablePeriods = ({
  columns,
}: {
  columns: SubmissionColumn[];
}) => {
  return Array.from(new Set(columns.map(({ period_info }) => period_info)));
};

export const getVisibleSubmissions = (
  submissions: SubmissionColumn[],
  periodSelected: string[],
  activeBusinessType: string
) =>
  submissions
    .filter(
      (item) =>
        (!activeBusinessType ||
          activeBusinessType === 'Overall' ||
          item.business_type === activeBusinessType) &&
        (!periodSelected.length || periodSelected.includes(item.period_info))
    )
    .sort((a, b) => a.order - b.order);

/**
 * sets special css classs for CRO rows
 * @param row
 */
export const getTeamRowStyle = (row: IRow) =>
  isCRORow(row as RowSubmission) ? teamRowBGColor : '';

/**
 * Convert BE result to table rows
 * @param users
 */
export const createTableRows = ({
  users = [],
}: SubmissionForecastState): RowSubmission[] =>
  users.map<RowSubmission>((item) => ({
    id: item.user_id,
    [RowTypeFieldName]: item.manager_id === null ? 'CRO' : 'User',
    ...item,
    managerCanOverride: item.manager_can_override,
    submissions: item.submissions.reduce(
      (acc, submission) => ({
        ...acc,
        [`${submission.submission_settings_id}`]: submission,
      }),
      {}
    ),
  }));

/**
 * Creates tree structure from rows
 * @param rows
 */
export const createTreeRows = (rows: RowSubmission[]): RowSubmission[] =>
  setRowExpand(treeify(rows, 'manager_id'), 0, true);

export const getBusinessTypeButtonList = (
  allBusinessTypes: string[]
): IOption[] =>
  allBusinessTypes.map((option) => {
    return {
      id: option,
      text: option,
    };
  });

export const openDealsModal = (
  user_submission_id: string,
  title: string,
  dispatch: Dispatch<any>
) => {
  openModal({
    scheme: '/deals/included/:submissionId',
    params: {
      submissionId: user_submission_id,
      localStorageKeyPrefix: 'DealsIncluded',
    },
    persistParams: {
      apiUrl: `/api/data/forecast/submission/deals_data_history/${user_submission_id}`,
      title: title,
      isHistoricalSubmissionsModal: true,
    },
    persistor: (params: PersistQueryParams) =>
      dispatch(actions.ui.modal.persist(params)),
  });
};

export const showUserWithAccessToSubmission =
  (visibleSubmissions: SubmissionColumn[]) => (row: RowSubmission) =>
    visibleSubmissions.some((column) =>
      column.submit_enabled_for_roles.includes(row.role)
    );

export type WithSubmissionWrapperProps = {
  canNavigateToInitiatorRoute: boolean;
  goToInitiatorRoute: () => void;
};

export const withSubmissionWrapper = (
  Component: React.FC<WithSubmissionWrapperProps & any>
): React.FC<any> => {
  return (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isSubmissionLoaded = useSelector(
      selectors.isForecastSubmissionLoaded
    );
    const previousRoute = useSelector(selectors.getPreviousRoute);
    const initiatorRoute = useSelector(
      selectors.getSubmissionForecastInitiatorRoute
    );

    useEffect(() => {
      if (!isSubmissionLoaded) {
        dispatch(fetchForecastSubmissionsSettingsAvailable());
      }
    }, [isSubmissionLoaded]);

    useEffect(() => {
      if (!previousRoute) {
        return;
      }

      switch (previousRoute) {
        case '/submission/forecast':
        case '/submission/history':
          break;
        default:
          dispatch(setNavigatedFromRoute(previousRoute));
      }
    }, [dispatch, previousRoute]);

    const goToInitiatorRoute = useCallback(() => {
      if (initiatorRoute) {
        history.push(initiatorRoute);
      }
    }, [initiatorRoute]);

    return (
      <Component
        {...props}
        canNavigateToInitiatorRoute={Boolean(initiatorRoute)}
        goToInitiatorRoute={goToInitiatorRoute}
      />
    );
  };
};
