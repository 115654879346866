import styled from '@emotion/styled';
import { css } from 'emotion';

export const wrapper = css`
  position: fixed;
  right: 0;
  bottom: 0;
`;

export const QuickViewContainer = styled.div`
  width: 80%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  transition: 0.7s;
  overflow-x: hidden;
  text-align: initial;

  display: flex;
  flex-direction: column;

  &.large {
    width: 60%;
  }

  &.small {
    width: 50%;
  }

  &.tiny {
    width: 40%;
  }

  &.mini {
    width: 30%;
  }
`;

export const QuickViewCloseButton = styled.button`
  --bu-quick-view-close-btn-size: 48px;
  --bu-quick-view-close-btn--icon-size: 24px;

  width: var(--bu-quick-view-close-btn-size);
  height: var(--bu-quick-view-close-btn-size);
  background: #fff;
  position: absolute;
  right: 80%;
  top: 0;
  transition: 0.4s;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.large {
    right: 60%;
  }

  &.small {
    right: 50%;
  }

  &.tiny {
    right: 40%;
  }

  &.mini {
    right: 30%;
  }

  &:after {
    content: '';
    width: 1px;
    height: var(--bu-quick-view-close-btn--icon-size);
    background: #000;
    transform: rotate(45deg);
  }

  &:before {
    content: '';
    width: 1px;
    height: var(--bu-quick-view-close-btn--icon-size);
    background: #000;
    transform: rotate(-45deg);
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: var(--bu-gray-500);

    &:after {
      background: #fff;
    }

    &:before {
      background: #fff;
    }
  }
`;

export const QuickViewHeader = styled.div`
  border-bottom: 1px solid var(--bu-gray-500);

  height: 49px;
  padding: 0 20px;

  color: var(--bu-gray-900);

  display: flex;
  flex-direction: row;
  align-items: center;
`;
QuickViewHeader.defaultProps = {
  className: 'bu-font-section-title',
};

export const QuickViewContent = styled.div`
  padding: 10px 30px;
  flex: 1;
  overflow: auto;
  margin-bottom: 70px;
`;

export const QuickViewActions = styled.div`
  border-top: 1px solid var(--bu-gray-500);
  height: 60px;
  padding: 8px 20px 10px 0;
  text-align: right;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
