import clx from 'classnames';
import React, {
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { actions } from 'actions';
import { deleteCustomView } from 'actions/customViews';
import { updateCustomView } from 'actions/customViews';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { tabsWithAnalytics, allTabs, BusinessTypeTab } from 'common/constants';
import BuButton from 'components/UI/BuButton';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import BuIcon from 'components/UI/BuIcon';
import CustomViewItem from 'components/UI/OpenFiltersPanel/CustomViewsDropdown/CustomViewItem/CustomViewItem';
import * as s from 'components/UI/OpenFiltersPanel/CustomViewsDropdown/styles';
import { PinnedView } from 'components/UI/OpenFiltersPanel/types';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { ProfileDataWithName } from 'reducers/filters/openFilters';
import { IReduxState } from 'reducers/types';
import {
  getPersistName,
  getFeatureFlags,
  getBusinessTypes,
  getShowOrHideColumns,
  getSelectedBusinessTypeForPanel,
  getForecastActiveBusinessType,
} from 'selectors';
import {
  getFiltersProfiles,
  getActiveFiltersProfile,
  getBestProfileNameByTab,
  getFiltersForCustomViews,
  getFiltersProfilesNames,
} from 'selectors/openFilters';

type Props = {
  tab: string;
  pinnedView: PinnedView;
  setPinnedView: Dispatch<SetStateAction<PinnedView>>;
  onChange: (name: string) => void;
  openCreateNewViewModal: () => void;
};

type RemoveViewModalState = {
  isVisible: boolean;
  id: string;
  name: string;
};

const VIEW_TYPES: Filters.CustomViewsType[] = ['DEFAULT', 'PERSONAL'];

const CustomViewsDropdown: React.FC<Props> = ({
  tab,
  pinnedView,
  setPinnedView,
  onChange,
  openCreateNewViewModal,
}) => {
  const dispatch = useDispatch();
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const {
    bestProfileName,
    businessTypes,
    filterProfilesNames,
    persistName,
    profiles,
    properties,
    selectedBusinessType,
    selectedProfile,
    showOrHideColumns,
  } = useSelector((state: IReduxState) => {
    const { deals_overall_view_enabled } = getFeatureFlags(state);

    return {
      bestProfileName: getBestProfileNameByTab(state, tab),
      businessTypes: getBusinessTypes(state, deals_overall_view_enabled),
      filterProfilesNames: getFiltersProfilesNames(state, tab),
      persistName: getPersistName(state, false),
      profiles: getFiltersProfiles(state, tab, false),
      properties: getFiltersForCustomViews(state, tab),
      selectedBusinessType: allTabs.includes(tab as BusinessTypeTab)
        ? getSelectedBusinessTypeForPanel(state, tab as BusinessTypeTab)
        : getForecastActiveBusinessType(state),
      selectedProfile: getActiveFiltersProfile(state, tab),
      showOrHideColumns: getShowOrHideColumns(state, false),
    };
  });

  const REMOVE_VIEW_MODAL_INIT_STATE: RemoveViewModalState = {
    isVisible: false,
    id: '',
    name: '',
  };
  const [removeViewModal, setRemoveModalView] = useState<RemoveViewModalState>(
    REMOVE_VIEW_MODAL_INIT_STATE
  );
  const [profileInEditId, setProfileInEditId] = useState<string>('');

  useEffect(() => {
    const allProfiles = Object.values(profiles).flat();
    const selectedProfile: ProfileDataWithName | undefined = allProfiles.find(
      (p) => p.name === selectedProfile?.name
    );

    // In case that selected profile is someone else shared profile, we must not show it.
    // Show pinned or default one.
    if (!selectedProfile) {
      const pinnedProfile: ProfileDataWithName | undefined = allProfiles.find(
        (p) => p.pinned
      );

      dispatch(
        actions.ui.filters.setActiveProfile({
          tab,
          profile: pinnedProfile
            ? pinnedProfile.name
            : profiles['DEFAULT'][0].name,
        })
      );
    }
  }, []);

  // When selectedProfile (saved views) change, set the showOrHideColumns
  // and business type according to the saved view
  useEffect(() => {
    if (
      selectedProfile?.profile.tablePersistName &&
      selectedProfile?.profile.columnToggleState
    ) {
      dispatch(
        actions.ui.appState.replaceShowOrHideColumns({
          path: selectedProfile?.profile.tablePersistName,
          columns: selectedProfile?.profile.columnToggleState,
        })
      );
    } else {
      // backward compatible with older views that does not contain column toggles
      dispatch(
        actions.ui.appState.replaceShowOrHideColumns({
          path: persistName,
          columns: [],
        })
      );
    }
    // dispatch to set business type
    dispatch(
      actions.ui.manageForecast.setActiveBusinessType(
        selectedProfile?.profile.selectedBusinessType ?? businessTypes[0]
      )
    );
    dispatch(
      actions.ui.filters.setBusinessType({
        businessType:
          selectedProfile?.profile.selectedBusinessType ?? businessTypes[0],
        tab,
      })
    );
  }, [selectedProfile?.name]);

  useEffect(() => {
    if (
      selectedProfile?.name &&
      bestProfileName &&
      selectedProfile?.name !== bestProfileName
    ) {
      dispatch(
        actions.ui.filters.setActiveProfile({
          tab,
          profile: bestProfileName,
        })
      );
    }
  }, [dispatch, tab, selectedProfile?.name, bestProfileName]);

  const handleChangePinned = (
    viewName: string,
    viewId: string,
    isPinned: boolean
  ) => {
    if (viewId) {
      handleUpdateView(viewName, viewId, isPinned, false, true);
    } else {
      // Case for DEFAULT, just unpin the previous one
      if (isPinned && pinnedView.id) {
        handleUpdateView(pinnedView.name, pinnedView.id, false, false, false);

        setPinnedView({
          id: '',
          name: '',
        });
      }
    }
  };

  const handleUpdateView = (
    viewName: string,
    viewId: string,
    isPinned: boolean,
    isFullUpdate: boolean = true,
    showToast: boolean = true
  ): void => {
    if (viewName && viewId && properties) {
      const data: Filters.CustomView = {
        view_type: 'PERSONAL',
        view_section: tab,
        pinned: isPinned,
        ...(isFullUpdate && {
          properties: {
            ...properties,
            tablePersistName: persistName,
            columnToggleState: showOrHideColumns,
            display_name: viewName,
            selectedBusinessType,
          },
        }),
      };

      dispatch(
        updateCustomView(viewId, data, showToast, pinnedView.id, !isFullUpdate)
      );

      if (tabsWithAnalytics.includes(tab)) {
        AnalyticsTracker.event(
          { tab, data },
          {
            category: 'Update view',
            action: 'Update view',
            label: selectedProfile?.name,
          }
        );
      }
    }
  };

  const handleSetRemoveView = (id: string, name: string): void => {
    setRemoveModalView({ isVisible: true, id, name });
  };

  const handleRemoveView = () => {
    // Find pinned and set it if different than deleted
    const pinnedProfile: ProfileDataWithName | undefined = Object.values(
      profiles
    )
      .flat()
      .find((p) => p.pinned);

    const hasOtherPinnedProfile =
      pinnedProfile && pinnedProfile.id !== removeViewModal.id;

    dispatch(
      deleteCustomView(
        removeViewModal.id,
        tab,
        removeViewModal.name,
        hasOtherPinnedProfile
      )
    );

    if (hasOtherPinnedProfile) {
      dispatch(
        actions.ui.filters.setActiveProfile({
          tab,
          profile: pinnedProfile
            ? pinnedProfile.name
            : profiles['DEFAULT'][0].name,
        })
      );
    }

    setRemoveModalView(REMOVE_VIEW_MODAL_INIT_STATE);
  };

  const views = useMemo(
    () =>
      VIEW_TYPES.filter((viewType) => !!profiles[viewType]).flatMap((profile) =>
        profiles[profile].sort(
          (a: ProfileDataWithName, b: ProfileDataWithName) =>
            a.name.localeCompare(b.name, undefined, {
              numeric: true,
              sensitivity: 'base',
            })
        )
      ),
    [VIEW_TYPES, JSON.stringify(profiles)]
  );

  useEffect(() => {
    const pinned = views.find((view) => view.pinned);

    setPinnedView({
      id: pinned?.id || '',
      name: pinned?.name || '',
    });
  }, [views]);

  return (
    <div className={s.container}>
      <div className={s.view_filters_box} ref={refElement}>
        <button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          className={clx(s.view_filters_label, { open: isOpen })}
        >
          View: <span>{selectedProfile?.name}</span>
          <BuIcon
            className={s.arrow_up_down}
            name={isOpen ? BoostUpIcons.TriangleUp : BoostUpIcons.TriangleDown}
          />
        </button>

        <div className={clx(s.view_filters_options, { open: isOpen })}>
          <div className={s.saved_views_label_container}>
            <span className={s.saved_views_label}>
              <strong>My Saved Views</strong>
            </span>
          </div>
          {views.map(({ id, name, view_type, pinned }) => (
            <CustomViewItem
              key={`${view_type}_${id}`}
              tab={tab}
              viewType={view_type}
              id={id}
              name={name}
              isPinned={
                Boolean(pinned) ||
                (view_type === 'DEFAULT' && pinnedView.id === '')
              }
              isDefaultView={view_type === 'DEFAULT'}
              selectedProfileName={selectedProfile?.name}
              filterProfilesNames={filterProfilesNames}
              isInEditMode={id === profileInEditId}
              setProfileInEdit={setProfileInEditId}
              setIsDropdownOpen={setIsOpen}
              onChangeName={onChange}
              onUpdateView={handleUpdateView}
              onRemoveView={handleSetRemoveView}
              onPinnedChange={handleChangePinned}
            />
          ))}
          {views.length === 1 && (
            <div className={s.create_new_view_container}>
              <p className={s.create_saved_view_p}>
                Create your first Saved view. It will save current state of
                filters, columns and business types.
              </p>
              <BuButton onClick={openCreateNewViewModal}>Create View</BuButton>
            </div>
          )}
        </div>
      </div>

      <BuConfirmationPopup
        cancelText="Cancel"
        confirmText="Delete"
        isOpen={removeViewModal.isVisible}
        onClose={() => setRemoveModalView(REMOVE_VIEW_MODAL_INIT_STATE)}
        onConfirm={handleRemoveView}
      >
        <div className={s.delete_modal_message}>
          {`Are you sure you want to delete ‘${removeViewModal.name}’ view?  It will be permanently removed.`}
        </div>
      </BuConfirmationPopup>
    </div>
  );
};

export default CustomViewsDropdown;
