import className from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { HotjarNotification } from 'components/UI/HotjarNotification';
import LeftPanel from 'components/UI/Wrapper/LeftPanel';
import Header from 'components/UI/Wrapper/Header';
import * as s from 'components/UI/Wrapper/styles';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import BuImpersonationPanel from 'components/UI/BuImpersonationPanel';

const Wrapper: React.FC = ({ children }) => {
  const isShowNotification = useSelector((state: IReduxState) =>
    selectors.isShowHotjarModal(state)
  );

  return (
    <div className={className('main-wrapper', s.wrapper)}>
      <div className={s.content}>
        <LeftPanel />

        <Header />

        <BuImpersonationPanel />
        <div id="main_content" className={s.content_box}>
          {children}
        </div>

        {isShowNotification && <HotjarNotification />}
      </div>
    </div>
  );
};

export default Wrapper;
