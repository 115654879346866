import * as s from './styles';
import React, { useEffect, useState, ChangeEvent } from 'react';
import { CheckboxProps } from 'semantic-ui-react/dist/commonjs/modules/Checkbox/Checkbox';

import BuInput from 'components/UI/BuInput';
import BuRadio from 'components/UI/BuRadio';
import { UnitType } from 'components/dashboard/Metrics/constants';
import { UnitValue } from 'components/dashboard/Metrics/metrics.types';

interface IProps {
  onChange: (value: UnitValue) => void;
  initialValue?: UnitValue;
}

export const MetricCreateUnitsField: React.FC<IProps> = ({
  onChange,
  initialValue = {
    unit: '',
    type: UnitType.Prefix,
  },
}) => {
  const [value, setValue] = useState<UnitValue>(initialValue);

  useEffect(() => {
    onChange(value);
  }, [value]);

  const handleChangeUnit = (event: ChangeEvent<HTMLInputElement>) => {
    setValue({ ...value, unit: event.target.value });
  };

  const handleChangePrefix = (
    event: ChangeEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => {
    setValue({ ...value, type: data.value as UnitType.Prefix });
  };

  return (
    <>
      <BuInput
        placeholder="E.g. %, $, USD"
        onChange={handleChangeUnit}
        value={value.unit}
      />
      <s.TypeBlock>
        <BuRadio
          value={UnitType.Prefix}
          checked={value.type === UnitType.Prefix}
          label="Prefix"
          onChange={handleChangePrefix}
        />
        <BuRadio
          value={UnitType.Suffix}
          checked={value.type === UnitType.Suffix}
          label="Suffix"
          onChange={handleChangePrefix}
        />
      </s.TypeBlock>
    </>
  );
};
