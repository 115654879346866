import styled from '@emotion/styled';
import React from 'react';

import BuRadio from 'components/UI/BuRadio';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import { MetricType } from 'components/dashboard/Metrics/metrics.types';

const MetricTypeSelect = styled.div((props: { selected?: boolean }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  border: '1px solid',
  borderRadius: '4px',
  borderColor: props.selected ? `var(--bu-primary-500)` : `var(--bu-gray-500)`,
  padding: '8px',
  cursor: 'pointer',
  width: '100%',
  backgroundColor: 'var(--bu-white)',
  '>div .ui.radio.checkbox label': {
    fontSize: '16px',
    paddingLeft: '26px',
    marginBottom: '5px',
  },
}));

const MetricTypeSelectDescription = styled.div({
  paddingLeft: '26px',
  color: `var(--bu-gray-500)`,
});

interface Props {
  readonly metricType: MetricType;
  readonly updateMetricType: (type: MetricType) => void;
}

export const MetricTypeSelection: React.FC<Props> = ({
  metricType,
  updateMetricType,
}) => (
  <FlexRow
    cssProps={{
      marginBottom: '24px',
      flexDirection: 'column',
    }}
    data-testing="type-of-metric-section"
  >
    <MetricCreateSubTitle>Type of metric</MetricCreateSubTitle>
    <FlexRow cssProps={{ gap: '8px' }}>
      <MetricTypeSelect
        selected={metricType === MetricType.Simple}
        onClick={() => updateMetricType(MetricType.Simple)}
      >
        <div>
          <BuRadio label="Simple" checked={metricType === MetricType.Simple} />
        </div>
        <MetricTypeSelectDescription>
          Single field aggregation
        </MetricTypeSelectDescription>
      </MetricTypeSelect>
      <MetricTypeSelect
        selected={metricType === MetricType.Formula}
        onClick={() => updateMetricType(MetricType.Formula)}
      >
        <div>
          <BuRadio
            label="Formula"
            checked={metricType === MetricType.Formula}
          />
        </div>
        <MetricTypeSelectDescription>
          Formula with 2+ metrics
        </MetricTypeSelectDescription>
      </MetricTypeSelect>
    </FlexRow>
  </FlexRow>
);
