import styled from '@emotion/styled';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { pick } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { actions } from 'actions';
import InfoIcon from 'assets/fonts/boostup-icons/badge_info_outline.svg';
import { CHANGE_INTERVAL_OPTIONS, FILTER_NAME } from 'common/constants';
import DealsTable from 'components/UI/DealsFlatTableConfig/DealsTable.container';
import { QueryParams } from 'components/UI/DealsFlatTableTS/Table/types';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import { DisplayedColumns } from 'components/UI/TableConfig/TableControls';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import Badge from 'components/dashboard/Forecast/Dashboard/Badge';
import { useLocalStorage } from 'components/hooks/useLocalStorage';
import * as styles from 'components/modals/ManageForecastModal/styles';
import { IProps } from 'components/modals/ManageForecastModal/types';
import {
  getFiltersForModal,
  haveFiltersConfigurated,
} from 'reducers/filters/helpers';
import { IReduxState } from 'reducers/types';
import {
  getFiltersForAPI,
  getIsFiltersOnModalsEnabled,
  getUser,
} from 'selectors';

const TABS = ['current deals', 'changed deals'];

const TableContainer = styled.div<{ showChangedDeals?: boolean }>`
  min-height: 40vh;

  .container .container-dashboard {
    height: ${({ showChangedDeals }) =>
      showChangedDeals ? 'calc(80vh - 43px)' : '80vh'};
  }
`;

const ManageForecastModal: React.FC<IProps> = ({
  modalProps,
  modalOptions,
  filters,
}) => {
  const user = useSelector(getUser);
  const isFiltersOnModalsEnabled = useSelector(getIsFiltersOnModalsEnabled);
  const dispatch = useDispatch();
  const [sortColumnFromLocalStorage, setSortColumnToLocalStorage] =
    useLocalStorage(
      `${modalOptions.localStorageKeyPrefix}SortColumn:${user.email}`,
      ''
    );
  const showChangedDeals =
    !modalProps.context?.includes('Booked') && !!modalProps.delta;
  const [tab, setTab] = useState(
    !showChangedDeals ? TABS[0] : modalOptions.subtab
  );

  const rawFilters: QueryParams = Object.assign({}, modalProps);
  const updatedFilters = pick(
    [
      'managers',
      'business_type_name',
      'split_view',
      'change_interval',
      'visibility',
      'include_disabled',
      'amount_field',
    ],
    rawFilters
  );

  const modalFilters = useSelector((state) =>
    getFiltersForAPI(
      state as IReduxState,
      FILTER_NAME.ForecastOpportunitiesModal
    )
  );

  const filtersForModal = useMemo(
    () =>
      modalProps.filtersForModalFilterPanel
        ? getFiltersForModal(
            modalFilters,
            modalProps.filtersForModalFilterPanel
          )
        : [],
    [JSON.stringify(filters), JSON.stringify(rawFilters)]
  );

  useEffect(() => {
    dispatch(
      actions.ui.filters.setForecastModalFilters({
        filters: filtersForModal,
        tab: FILTER_NAME.ForecastOpportunitiesModal,
      })
    );
  }, [JSON.stringify(filtersForModal)]);

  if (isEmpty(modalProps)) {
    return <Loader active />;
  }

  const amountFieldName = updatedFilters.amount_field?.split('.').pop();

  const isChangedDealsTab = tab !== TABS[0];

  const changedDealsFilters = {
    table_id: modalProps.table_id,
    column_name: modalProps.column_name,
  };

  const _currentDealsFilters = {
    ...filters,
    ...updatedFilters,
    ids: !isChangedDealsTab && modalProps.dealIds,
    current_ids: isChangedDealsTab && modalProps.dealIds,
    prev_ids: isChangedDealsTab && modalProps.prevDealsIds,
    only_with_changes: isChangedDealsTab,
    page_size: 50,
    sort: sortColumnFromLocalStorage,
  };

  const currentDealsFilters =
    filtersForModal.length > 0
      ? ({
          ..._currentDealsFilters,
          ...modalFilters,
        } as QueryParams)
      : ({
          ..._currentDealsFilters,
        } as QueryParams);

  // If we have users filter with values selected, we should not send managers filter
  // As BE will not filter by users if we send it
  // [VPD-12195]
  currentDealsFilters.managers =
    currentDealsFilters.users && currentDealsFilters.users?.length > 0
      ? undefined
      : currentDealsFilters.managers;

  const changeInterval = updatedFilters.change_interval
    ? updatedFilters.change_interval[0] || ''
    : '';
  const timeInterval = changeInterval
    ? CHANGE_INTERVAL_OPTIONS[changeInterval]
    : '';

  const getAmountFieldHighlightIcon = (
    displayedColumns: DisplayedColumns
  ): JSX.Element => {
    const amountFieldColumnName = displayedColumns?.find(
      (column) =>
        column.field &&
        amountFieldName &&
        column.field.indexOf(amountFieldName) !== -1
    )?.label;

    if (
      updatedFilters.amount_field &&
      (amountFieldColumnName || rawFilters.opportunity_column_name)
    ) {
      return (
        <TooltipWrapper
          position="top center"
          tooltip={
            <span>
              This metric's value and Delta are mapped to the custom field{' '}
              <span style={{ fontWeight: 'bold' }}>
                {amountFieldColumnName || rawFilters.opportunity_column_name}
              </span>
              .
              {!amountFieldColumnName &&
                rawFilters.opportunity_column_name &&
                ' According to your company account settings, this column has been hidden in this table.'}
            </span>
          }
        >
          <img
            className={classNames(styles.amountFieldHighlightIcon, 'ui')}
            src={InfoIcon}
            alt="info"
          />
        </TooltipWrapper>
      );
    }

    return <></>;
  };

  const showFilters =
    isFiltersOnModalsEnabled && haveFiltersConfigurated(modalFilters || {});

  return (
    <div>
      {showFilters && (
        <OpenFiltersPanel
          tab={FILTER_NAME.ForecastOpportunitiesModal}
          isModal
        />
      )}

      {showChangedDeals && (
        <div className={classNames('modal-tab-container', styles.tabContainer)}>
          {TABS.map((t) => (
            <div
              key={t}
              onClick={() => setTab(t)}
              className={styles.tab(tab === t)}
            >
              {t}
            </div>
          ))}
        </div>
      )}

      <TableContainer
        className={styles.root}
        showChangedDeals={showChangedDeals}
      >
        {isChangedDealsTab && (
          <DealsTable
            apiUrl="/api/data/deals/changed/"
            inModal
            title={modalProps.context}
            renderTitleExtra={(displayedColumns) => (
              <>
                <div className={classNames(styles.badgeContainer)}>
                  <Badge value={modalProps.delta} />
                </div>

                {getAmountFieldHighlightIcon(displayedColumns)}

                {timeInterval && (
                  <div className={classNames(styles.timeIntervalContainer)}>
                    Changes since {timeInterval}
                  </div>
                )}
              </>
            )}
            dataType="Deals"
            initialRequest={{
              ...currentDealsFilters,
              ...changedDealsFilters,
            }}
            forecastTable
            persistName={`deals_modal_${modalProps.context}`}
            tableConfigCollection="opportunity"
            tableConfigName={'DealsDelta'}
            amountFieldName={amountFieldName}
            localStorageSortColumn={sortColumnFromLocalStorage}
            onSortChangePersistLocally={setSortColumnToLocalStorage}
            forceBasicFilters
            showTotalAmount={false}
          />
        )}

        {!isChangedDealsTab && (
          <DealsTable
            inModal
            title={modalProps.context}
            renderTitleExtra={getAmountFieldHighlightIcon}
            dataType="Deals"
            tableConfigCollection="opportunity"
            tableConfigName="Opportunities"
            amountFieldName={amountFieldName}
            persistName={`deals_modal_${modalProps.context}`}
            initialRequest={currentDealsFilters}
            forecastTable
            localStorageSortColumn={sortColumnFromLocalStorage}
            onSortChangePersistLocally={setSortColumnToLocalStorage}
            forceBasicFilters
          />
        )}
      </TableContainer>
    </div>
  );
};

export default ManageForecastModal;
