import styled from '@emotion/styled';
import { css } from 'emotion';

import { fontBody, fontCaption2 } from 'assets/css/fontStyles';

export const ControlsContainer = styled.div(
  ({
    isSpaceBetween,
    showControls,
  }: {
    isSpaceBetween: boolean;
    showControls: boolean;
  }) => ({
    justifyContent: isSpaceBetween ? 'space-between' : 'flex-start',
    padding: '8px 16px',
    alignItems: 'center',
    display: showControls ? 'flex' : 'none',
  })
);

export const FilterBarElement = styled.div({
  paddingRight: '6px',
  '>i': {
    fontSize: '20px',
  },
});

export const PivotFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  > .secondary-background {
    margin-right: 8px;
  }
  &.notExpanded {
    .bu-dropdown {
      max-width: calc((100vw - 150px) / 6);
      min-width: auto;
    }
  }
  .bu-btn-content {
    div {
      max-width: fit-content;
    }
  }
`;

export const pivotWidget = css`
  border: 1px solid var(--bu-gray-500);
  height: 32px;
  margin-right: 8px;
  border-radius: 4px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  min-width: 80px !important;
  width: unset !important;
  z-index: 989;
`;

export const pivotWidgetMenu = css`
  display: flex;
  flex-direction: column;
  max-height: 380px;
  overflow-y: scroll;
  margin-top: 4px !important;
`;

export const pivotWidgetSearch = css`
  display: flex;
  flex-direction: row;
  margin: 8px;
  background-color: var(--bu-gray-200);
  padding: 6px;
  border-radius: 4px;
  input {
    border: none;
    outline: none;
    background-color: var(--bu-gray-200);
  }
`;

export const pivotWidgetSearchOption = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  width: 100%;
  height: 32px;
  ${fontBody}
  &:hover {
    background-color: var(--bu-primary-200);
    cursor: pointer;
  }
  &.selected {
    background-color: var(--bu-primary-200);
  }
`;

export const pivot2WidgetSeparator = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
  padding: 6px 12px;
  width: 100%;
  height: 32px;
  border-top: 1px solid var(--bu-gray-300);
  ${fontCaption2}
`;
