import React from 'react';

import BuButton, { BuControlSize } from 'components/UI/BuButton';
import SaveViewActions from 'components/UI/OpenFiltersPanel/SaveViewDropdown/SaveViewActions/index';
import { container } from 'components/UI/OpenFiltersPanel/SaveViewDropdown/styles';
import { useClickOutside } from 'components/hooks/useClickOutside';

type IProps = {
  isDefaultView: boolean;
  openCreateNewViewModal: () => void;
  onUpdateCustomView: () => void;
};

const SaveViewDropdown: React.FC<IProps> = ({
  isDefaultView,
  openCreateNewViewModal,
  onUpdateCustomView,
}) => {
  const { isOpen, refElement, setIsOpen } = useClickOutside();
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div ref={refElement} className={container}>
      <BuButton
        dropdown={isOpen ? 'open' : 'close'}
        onClick={toggle}
        size={BuControlSize.REGULAR}
      >
        Save view
      </BuButton>
      <SaveViewActions
        isDefaultView={isDefaultView}
        isOpen={isOpen}
        toggle={toggle}
        openCreateNewViewModal={openCreateNewViewModal}
        onUpdateCustomView={onUpdateCustomView}
      />
    </div>
  );
};

export default SaveViewDropdown;
