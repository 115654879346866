import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuDropdown, {
  BuDropdownItem,
  BuDropdownItemContainer,
  getDropdownItemProps,
} from 'components/UI/BuDropdown';
import BuGroupButton from 'components/UI/BuGroupButton';
import BuIcon from 'components/UI/BuIcon';
import { ScheduleSubmissionAlert } from 'components/UI/ScheduleSubmissionAlert';
import Scrolling from 'components/UI/common/Scrolling/Scrolling';
import TypedTable, {
  BorderType,
  TableDetails,
} from 'components/UI/common/TypedTable/TypedTable';
import { getColumns } from 'components/dashboard/CROOverride/SubmissionHistory/helper';
import { IProps } from 'components/dashboard/CROOverride/SubmissionHistory/types';
import {
  createTableRows,
  createTreeRows,
  getBusinessTypeButtonList,
  getTeamRowStyle,
  getVisibleSubmissions,
  showUserWithAccessToSubmission,
} from 'components/dashboard/CROOverride/helper';
import { withSubmissionWrapper } from 'components/dashboard/CROOverride/helper';
import {
  ComponentBox,
  Fluid,
  removeMargins,
  RowContainer,
  tableCellBorders,
  ViewContainer,
  TabsContainer,
  CloseButton,
} from 'components/dashboard/CROOverride/styles';
import { RowSubmission } from 'components/dashboard/CROOverride/types';
import Tabs from 'components/dashboard/Tabs';

const formatDate = (s: string) => moment(s).format('DD MMM YYYY, h:mm A');

const SubmissionHistory: React.FC<IProps> = ({
  submissionForecasts,
  history: submissionForecastHistory,
  status,
  historyStatus,
  allBusinessTypes,
  activeBusinessType,
  companyCurrency,
  onSelectHistory,
  selectedHistory,
  onSelectBusinessType,
  canNavigateToInitiatorRoute,
  goToInitiatorRoute,
}) => {
  const [shadowTopOffset, setShadowTopOffset] = useState(0);
  const [shadowLeftOffset, setShadowLeftOffset] = useState(0);
  const [dataFlat, setDataFlat] = useState<RowSubmission[]>([]);

  const handleTableDetails = useCallback((details: TableDetails) => {
    if (details.columns && details.columns[0]) {
      setShadowLeftOffset(details.columns[0].width);
      setShadowTopOffset(details.columns[0].height + 36);
    }
  }, []);

  useEffect(() => {
    setDataFlat(createTableRows(submissionForecasts));
  }, [submissionForecasts]);

  const submissions = useMemo(
    () =>
      getVisibleSubmissions(
        submissionForecasts.columns,
        [],
        activeBusinessType
      ),
    [submissionForecasts.columns, activeBusinessType]
  );

  const filterUserWithAccessToSubmission = useMemo(
    () => showUserWithAccessToSubmission(submissions),
    [submissions]
  );

  const data = useMemo(
    () => createTreeRows(dataFlat.filter(filterUserWithAccessToSubmission)),
    [dataFlat, filterUserWithAccessToSubmission]
  );

  const tableColumnConfig = useMemo(
    () =>
      getColumns({
        companyCurrency,
        submissionForecasts,
        activeBusinessType,
      }),
    [companyCurrency, submissionForecasts, activeBusinessType]
  );

  const businessTypeButtons = useMemo(
    () => getBusinessTypeButtonList(allBusinessTypes),
    [allBusinessTypes]
  );

  const SubmissionHistoryDropdown = React.memo(() => (
    <BuDropdown
      label={
        selectedHistory?.created_at
          ? formatDate(selectedHistory?.created_at)
          : 'No data'
      }
      secondary
    >
      {(hide) => (
        <BuDropdownItemContainer style={{ maxHeight: 300 }}>
          {submissionForecastHistory?.map((item) => (
            <BuDropdownItem
              key={item.id}
              {...getDropdownItemProps(
                item,
                selectedHistory?.id === item.id,
                hide,
                onSelectHistory
              )}
            >
              {formatDate(item.created_at)}
            </BuDropdownItem>
          ))}
        </BuDropdownItemContainer>
      )}
    </BuDropdown>
  ));

  return (
    <ViewContainer>
      <RowContainer>
        <Tabs partition="cro_override" wrapped>
          <div className={TabsContainer}>
            <ScheduleSubmissionAlert />
            {canNavigateToInitiatorRoute && (
              <a
                className={CloseButton}
                onClick={goToInitiatorRoute}
                href="javascript:;"
              >
                <BuIcon name={BoostUpIcons.ClosePopup} />
              </a>
            )}
          </div>
        </Tabs>
      </RowContainer>

      <RowContainer>
        <ComponentBox>
          <p>Forecast submitted on</p>

          <SubmissionHistoryDropdown />

          <BuGroupButton
            className={removeMargins}
            options={businessTypeButtons}
            selectedOption={activeBusinessType}
            onSelect={onSelectBusinessType}
          />
        </ComponentBox>
      </RowContainer>

      <Fluid>
        <TypedTable.Border borders={BorderType.TOP} height="100%">
          <Dimmer.Dimmable
            dimmed={status === 'loading' || historyStatus === 'loading'}
            style={{ height: '100%', overflowY: 'auto' }}
          >
            <Dimmer
              active={status === 'loading' || historyStatus === 'loading'}
              inverted
            >
              <Loader id="loading_spinner" />
            </Dimmer>

            <Scrolling
              alwaysShow
              height="100%"
              shadowLeftOffset={shadowLeftOffset}
              shadowTopOffset={shadowTopOffset}
              shadows
              width
            >
              <TypedTable
                columns={tableColumnConfig}
                data={data}
                onRender={handleTableDetails}
                fullscreen
                stickyColumn
                stickyHeader
                fixColumns
                rowClassName={getTeamRowStyle}
                className={tableCellBorders}
              />
            </Scrolling>
          </Dimmer.Dimmable>
        </TypedTable.Border>
      </Fluid>
    </ViewContainer>
  );
};

export default withSubmissionWrapper(SubmissionHistory);
