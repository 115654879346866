import React from 'react';

export const DataFetchErrorMessage: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
      }}
      data-testing="data-fetch-error-message"
    >
      <h1>ERROR!</h1>
      <p>Failed to fetch the data for the widget!</p>
    </div>
  );
};
