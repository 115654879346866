import { css } from 'emotion';

export const callTranscript_modal = css`
  display: block;
  max-width: 880px;
  max-height: 400px;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 10000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
