import React, { useState, Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import * as metricsActions from 'actions/revbi/metrics';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import {
  MetricTitle,
  MetricsButtonsContainer,
  SavedMetricBlock,
  MetricInfoContainer,
  ActionButtonsContainer,
} from 'components/dashboard/Metrics/Create/DefinitionsMetricsList/styles';
import { SIMPLE_HISTORICAL_METRIC_NEWBORN_MOCK } from 'components/dashboard/Metrics/Create/MetricCreate/contants';
import { MetricEditConfirmationPopup } from 'components/dashboard/Metrics/MetricEditConfirmationPopup';
import { MetricInfo } from 'components/dashboard/Metrics/common/MetricInfo/MetricInfo';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { getMetricDescription } from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIMetricCreated,
  ComponentMode,
  DeleteInfo,
  MetricDeleteInfo,
} from 'components/dashboard/Metrics/metrics.types';
import { fetchApi } from 'utils/network';

interface Props {
  analysisType: AnalysisType;
  metricsFromList: BIMetricCreated[];
  setIsCreateNewMetric: Dispatch<SetStateAction<boolean>>;
  setIsSideBarOpen: Dispatch<SetStateAction<boolean>>;
  onRemoveMetric: (metric: BIMetricCreated) => void;
}

export const DefinitionsMetricsList: React.FC<Props> = ({
  analysisType,
  metricsFromList,
  setIsCreateNewMetric,
  setIsSideBarOpen,
  onRemoveMetric,
}) => {
  const dispatch = useDispatch();

  const [metricDeleteInfo, setMetricDeleteInfo] = useState<MetricDeleteInfo>();
  const [isEditConfirmationOpen, setIsEditConfirmationOpen] =
    useState<boolean>(false);
  const [selectedElement, setSelectedElement] =
    useState<{
      id: string;
      name: string;
    }>();

  const handleEditConfirm = (id: string) => {
    dispatch(metricsActions.fetchMetricById(id, true));
    dispatch(metricsActions.fetchAllMetrics());
    dispatch(metricsActions.fetchAllTSMetrics());
    if (id) {
      setIsCreateNewMetric(true);
    }
  };

  const handleEditMetric = (id: string, name: string): void => {
    fetchApi<void, DeleteInfo>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics_delete_info/${id}`,
      queryMethod: 'get',
      setData: (result) => {
        setMetricDeleteInfo(result as MetricDeleteInfo);
        if (
          (result as MetricDeleteInfo).widget_deleted_list.length > 0 ||
          (result as MetricDeleteInfo)?.widget_edited_list.length > 0 ||
          (result as MetricDeleteInfo)?.synthetic_metric_list.length > 0
        ) {
          setIsEditConfirmationOpen(true);
          setSelectedElement({ id, name });
        } else {
          handleEditConfirm(id);
        }
      },
      setError: (_: string | null) => {
        toast.error(`Failed to check if safe to edit metric`);
      },
    });
  };

  const handleCreateNewMetric = () => {
    // for some reason we need to clean up the metrics state,
    // so we need to fetch again the metric based on the current state.
    // dispatch(metricsActions.fetchAllMetricsLoading());
    // dispatch(metricsActions.fetchAllTSMetricsLoading());

    dispatch(metricsActions.cleanUpMetricCreationState());
    dispatch(metricsActions.fetchAllMetrics());
    dispatch(metricsActions.fetchAllTSMetrics());
    dispatch(
      metricsActions.changeCreateMetricComponentMode(ComponentMode.CREATE)
    );

    if (analysisType === AnalysisType.HISTORICAL) {
      dispatch(
        metricsActions.changeActiveMetric(SIMPLE_HISTORICAL_METRIC_NEWBORN_MOCK)
      );
    }
    setIsCreateNewMetric(true);
  };

  return (
    <>
      {metricsFromList?.map((metric: BIMetricCreated) => (
        <SavedMetricBlock key={metric._id}>
          <MetricInfoContainer>
            <MetricTitle>{metric?.name ?? metric.name}</MetricTitle>
            <MetricInfo
              metric={metric}
              metricDescription={getMetricDescription(metric, true)}
            />
          </MetricInfoContainer>

          <ActionButtonsContainer>
            <BuButton
              borderless
              icon
              size={BuControlSize.SMALL}
              onClick={() => {
                handleEditMetric(metric._id as string, metric.name);
              }}
            >
              <BuIcon name={BoostUpIcons.Pencil} />
            </BuButton>
            <BuButton
              borderless
              icon
              size={BuControlSize.SMALL}
              onClick={() => onRemoveMetric(metric)}
            >
              <BuIcon name={BoostUpIcons.Trash} />
            </BuButton>
          </ActionButtonsContainer>
        </SavedMetricBlock>
      ))}

      <div>
        <span>Add an existing metric or create a new metric</span>
      </div>

      <MetricsButtonsContainer>
        <BuButton secondary onClick={() => setIsSideBarOpen(true)}>
          + Existing Metric
        </BuButton>
        <BuButton secondary onClick={handleCreateNewMetric}>
          + New Metric
        </BuButton>
      </MetricsButtonsContainer>

      <MetricEditConfirmationPopup
        isEditConfirmationOpen={isEditConfirmationOpen}
        metricDeleteInfo={metricDeleteInfo}
        selectedElement={selectedElement}
        onEditConfirm={handleEditConfirm}
        setIsEditConfirmationOpen={setIsEditConfirmationOpen}
      />
    </>
  );
};
