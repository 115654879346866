import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { Action } from 'typescript-fsa';

import { actions } from 'actions';
import { TIME_SERIES_SUPPORTED_DRILLDOWN_OBJECTS } from 'components/dashboard/Metrics/Widget/constants';
import {
  BIMetricColumn,
  BIMetrics,
  BIWidgetDataV2,
  TimeSeriesDrillDownParams,
} from 'components/dashboard/Metrics/metrics.types';
import { PersistQueryParams } from 'navigation/types';
import { openModal } from 'navigation/utils';
import { QueryStatus, fetchApi } from 'utils/network';

export const openDrillDownModal = (
  drilldownParams: TimeSeriesDrillDownParams,
  drillDownMetric: BIMetrics,
  pivotValue: string | undefined,
  title: string | undefined,
  dispatch: Dispatch<Action<PersistQueryParams>>
): void => {
  if (
    drillDownMetric?.object &&
    TIME_SERIES_SUPPORTED_DRILLDOWN_OBJECTS.includes(drillDownMetric.object)
  ) {
    openModal({
      scheme: '/historical-drilldown',
      persistParams: {
        drilldownParams: drilldownParams,
        metric: drillDownMetric,
        pivotValue: pivotValue,
        showTotalAmount: false,
      },
      params: {
        title: title || 'Drill Down',
      },
      persistor: (params: PersistQueryParams) => {
        dispatch(actions.ui.modal.persist(params));
      },
    });
  }
};

export const fetchWidgetData = (
  requestPayload: string,
  querySettings: string,
  widgetInfo: string,
  abortController: AbortController,
  setMetricData: React.Dispatch<
    React.SetStateAction<BIWidgetDataV2 | undefined>
  >,
  setDataStatus: React.Dispatch<React.SetStateAction<QueryStatus>>,
  setPivot: React.Dispatch<React.SetStateAction<BIMetricColumn | undefined>>
): void => {
  fetchApi<string, BIWidgetDataV2>({
    url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/v2/widgets/editor/data${querySettings}`,
    queryMethod: 'post',
    queryParams: requestPayload,
    setData: (result) => {
      setMetricData(result);

      const pivot1Column = result.pivot_1?.columns.find((el) => el.is_pivot);
      if (result.hasOwnProperty('pivot_2')) {
        const pivot2Column = result.pivot_2?.columns.find(
          (el) => el.is_pivot && el.display_name !== pivot1Column?.display_name
        );
        setPivot({
          name: pivot2Column?.field_name.replace('$', '.') || '',
          label: pivot2Column?.display_name || '',
          type: pivot2Column?.type || '',
        });
      }
    },
    setError: (error: string | null) => {
      setMetricData(undefined);
      toast.error(`Failed to load widget data ${widgetInfo}: ${error}`);
    },
    setStatus: setDataStatus,
    signal: abortController.signal,
  });
};
