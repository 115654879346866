import {
  getOptionsForFilter,
  filtersForFilterPillsFactory,
  prepareFilterData,
} from './filters';
import { getSortedTopicsList } from './topics';
import * as R from 'ramda';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

export const AI_TRAINING_KPI = [
  'sentiment',
  'questions',
  'topics',
  'speaker_classification',
  'relevant_questions',
];
export const getAITrainingVersions = R.path(['aiTraining', 'versions']);
export const getAITrainingVersionsCount = R.path([
  'aiTraining',
  'versionsCount',
]);
export const getAITrainingVersionsStatus = R.path([
  'aiTraining',
  'versionsStatus',
]);
export const getAITrainingStatus = R.path(['aiTraining', 'status']);
export const getAITrainingCurrentVersion = R.path([
  'aiTraining',
  'currentVersion',
]);
export const getAITrainingVersionErrors = R.path([
  'aiTraining',
  'versionErrors',
]);
export const getAITrainingContentStatus = R.path([
  'aiTraining',
  'contentStatus',
]);
export const getAITrainingContentIds = R.path(['aiTraining', 'contentIds']);
const getAITrainingContentById = R.path(['aiTraining', 'contentById']);
export const getAITrainingContentTotalCount = R.path([
  'aiTraining',
  'contentTotalCount',
]);
export const getAITrainingContentCurrentCount = R.path([
  'aiTraining',
  'contentCurrentCount',
]);
export const getAITrainingContent = createCachedSelector(
  [R.nthArg(1), getAITrainingContentById],
  R.prop
)(R.nthArg(1));
export const getAITrainingLabeledContentStatus = R.path([
  'aiTraining',
  'labeledContentStatus',
]);
export const getAITrainingLabeledContentIds = R.path([
  'aiTraining',
  'labeledContentIds',
]);
const getAITrainingLabeledContentById = R.path([
  'aiTraining',
  'labeledContentById',
]);
export const getAITrainingLabeledContentCount = R.path([
  'aiTraining',
  'labeledContentCount',
]);
export const getAITrainingLabeledContent = createCachedSelector(
  [R.nthArg(1), getAITrainingLabeledContentById],
  R.prop
)(R.nthArg(1));
export const getAITrainingTopicOptions = createSelector(
  [getSortedTopicsList],
  R.map(({ name }) => ({ text: name, value: name }))
);
export const isAITrainingFilterVisible = R.path(['aiTraining', 'showFilters']);
export const getAITrainingFilters = R.path(['aiTraining', 'filters']);
const getAITrainingFiltersWithoutDate = createSelector(
  [getAITrainingFilters],
  R.omit('time')
);
export const getAITrainingFiltersForPills = filtersForFilterPillsFactory(
  getAITrainingFiltersWithoutDate
);
const getAITrainingFilterFactory = (filterName) =>
  createSelector([getAITrainingFilters], R.prop(filterName));
const getAITrainingFilterDataFactory = (filterName, alias) =>
  createSelector(
    [
      getAITrainingFilterFactory(R.defaultTo(filterName)(alias)),
      R.partialRight(getOptionsForFilter, [filterName]),
    ],
    prepareFilterData
  );
export const getAITrainingSellerFilter = getAITrainingFilterDataFactory(
  'users',
  'sellers'
);
export const getAITrainingStageFilter = getAITrainingFilterDataFactory(
  'opportunity_stages',
  'stages'
);
export const getAITrainingTopicFilter =
  getAITrainingFilterDataFactory('topics');
export const getAITrainingTimeFilter = createSelector(
  [getAITrainingFilters],
  R.compose(R.nth(0), R.prop('time'))
);
export const getAITrainingIgnoreDomainsFilter = createSelector(
  [getAITrainingFilters],
  R.prop('ignore_domains')
);
