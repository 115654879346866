import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeActiveMetric } from 'actions/revbi/metrics';
import BuButton from 'components/UI/BuButton';
import { Condition } from 'components/dashboard/Metrics/Create/Conditions/Condition/Condition';
import * as s from 'components/dashboard/Metrics/Create/Conditions/styles';
import {
  BIColumnListItem,
  BIMetricColumn,
  BIMetricsFilter,
  BIMetricsQueryFilter,
  BIMetricUnion,
} from 'components/dashboard/Metrics/metrics.types';
import { getColumnFiltersList } from 'selectors/revbi/metrics';

interface Props {
  readonly title?: string;
  readonly description?: string;
  readonly metric: BIMetricUnion;
  readonly customColumnList?: BIColumnListItem[];
  readonly customUpdateMetric?: (metric: BIMetricUnion) => void;
}

export const Conditions: React.FC<Props> = ({
  title,
  description,
  metric,
  customColumnList,
  customUpdateMetric,
}) => {
  const dispatch = useDispatch();

  const columnList = customColumnList ?? useSelector(getColumnFiltersList);

  const updateMetric = (metric: BIMetricUnion): void => {
    if (customUpdateMetric) {
      customUpdateMetric(metric);
    } else {
      dispatch(changeActiveMetric(metric));
    }
  };

  const handleAddCondition = (): void => {
    if (canAddNewCondition) {
      updateMetric({
        ...metric,
        filters: [...(metric?.filters ?? []), {}] as BIMetricsQueryFilter[],
      });
    }
  };

  const handleRemoveCondition = (index: number): void => {
    const currentFilters = metric?.filters ?? [];
    const newFilters = [
      ...currentFilters.slice(0, index),
      ...currentFilters.slice(index + 1),
    ];
    updateMetric({
      ...metric,
      filters: newFilters,
    });
  };

  const handleAddFilterCondition = (
    filter: Partial<BIMetricsFilter>,
    idx: number
  ): void => {
    const currentFilters: BIMetricsQueryFilter[] = metric?.filters ?? [];
    const newFilters: BIMetricsQueryFilter[] = [
      ...currentFilters.slice(0, idx),
      { and_condition: [{ or_condition: [filter as BIMetricsFilter] }] },
      ...currentFilters.slice(idx + 1),
    ];
    updateMetric({
      ...metric,
      filters: newFilters,
    });
  };

  const selectedFiltersColumnNames: string[] = useMemo(
    () =>
      metric?.filters?.flatMap((filter) =>
        filter?.and_condition?.flatMap((a) =>
          a?.or_condition?.flatMap((b) => b.column.name)
        )
      ),
    [metric?.filters]
  );

  const canAddNewCondition: boolean = useMemo(
    () => (metric?.filters?.length ?? 0) < columnList?.length,
    [metric?.filters?.length, columnList?.length]
  );

  const getOptions = (filter: BIMetricsQueryFilter): BIMetricColumn[] => {
    const thisFilterColumnNames = filter?.and_condition?.flatMap((f) =>
      f?.or_condition?.flatMap((g) => g.column.name)
    );
    const usedColumnsInConditions = selectedFiltersColumnNames?.filter(
      (e) => !thisFilterColumnNames?.includes(e)
    );

    return columnList.filter((c) => !usedColumnsInConditions?.includes(c.name));
  };

  return (
    <div className={s.conditionsContainer} data-testing="conditions-section">
      <div className={s.flexColumn}>
        <div className={s.conditionSubTitle}>
          {title ? title : 'Conditions'}
        </div>
        <div className={s.conditionDescription}>
          {description ? description : ''}
        </div>

        {metric?.filters?.map((filter, idx) => (
          <div
            className={s.optionGroup}
            key={idx}
            data-testing={`condition-${idx}`}
          >
            <Condition
              columnList={getOptions(filter)}
              filter={filter?.and_condition?.[0]?.or_condition?.[0] ?? {}}
              targetPeriod={metric.target_period ?? ''}
              updateFilter={(newFilter: Partial<BIMetricsFilter>) =>
                handleAddFilterCondition(newFilter, idx)
              }
              onRemove={() => handleRemoveCondition(idx)}
            />
          </div>
        ))}
        <div className={s.addButtonContainer}>
          <BuButton
            secondary
            className={canAddNewCondition ? s.addConditionButton : ''}
            disabled={!canAddNewCondition}
            onClick={handleAddCondition}
          >
            + Add condition
          </BuButton>
        </div>
      </div>
    </div>
  );
};
