import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

import ToggleFullScreenIcon from 'assets/images/new_icon/full_screen_icon.svg';
import DownloadButton from 'components/UI/DownloadButton';
import { TimeFilterForTrends } from 'components/UI/TimeFilter';
import * as styles from 'components/chart-dashboards/Widget/styles';
import { WidgetHeaderProps } from 'components/chart-dashboards/Widget/types';

const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  chart_type,
  children,
  match,
  onChangeDropdown,
  serializedQueryParams,
  setFullScreen = null,
  setLocalFilters = () => {},
  tab,
  tabsPanel,
  timeFilterValue,
  url,
  valuePath,
  valueTitle,
  withTimeFilters,
  changeWidgetSinceDate,
  extraFilter,
  extraBeforeFilters,
}) => {
  const [value, setValue] = useState('');
  const chartsWithDownload = ['waterfall', 'column', 'line'];

  useEffect(() => {
    if (typeof valuePath === 'string') {
      setValue(valuePath);
      return;
    }
    const value =
      valuePath.find((el) => el.display_name === valueTitle)?.path || '';

    setValue(value);
  }, [valuePath, valueTitle]);

  return (
    <div className={styles.widgetHeader}>
      <div className={styles.chartHeader}>
        {children}

        <div className={styles.additionalFunction}>
          {extraBeforeFilters}
          {(match.path.includes('/forecast/analytics') ||
            match.path.includes('/dashboard/trends')) &&
            withTimeFilters && (
              <TimeFilterForTrends
                tab={tab}
                path={match.path}
                value={timeFilterValue}
                onChange={(filter: string, values: string[]) => {
                  setLocalFilters({
                    [filter]: values,
                  });
                  changeWidgetSinceDate({ [tab]: values });
                }}
              />
            )}

          <div className={styles.buttons}>
            {!extraFilter && Array.isArray(valuePath) && valuePath.length > 1 && (
              <div className={styles.dropdown}>
                <Dropdown
                  text={valueTitle}
                  options={valuePath.map((item, i) => ({
                    key: item.display_name,
                    text: item.display_name,
                    value: item.path,
                  }))}
                  simple
                  item
                  onChange={onChangeDropdown}
                />
              </div>
            )}

            {extraFilter}

            {chartsWithDownload.includes(chart_type) && (
              <DownloadButton
                buttonClassName={styles.downloadBtn}
                serializedQueryParams={serializedQueryParams}
                url={url}
              />
            )}

            {setFullScreen && (
              <button
                type="button"
                className={styles.fullScreenBtn}
                onClick={() => setFullScreen(true)}
              >
                <img src={ToggleFullScreenIcon} alt="full screen" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(WidgetHeader);
