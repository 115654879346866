import classNames from 'classnames';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';

import { CompanyUsersQueryParams } from 'actions/companyUsersActions';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuCheckbox from 'components/UI/BuCheckbox';
import BuDropdown, {
  BuDropdownItem,
  BuDropdownItemContainer,
} from 'components/UI/BuDropdown';
import Table from 'components/UI/TableConfig/Table';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import {
  getColumns,
  ALL_TEXT,
} from 'components/settings/ManageUsers/UsersAddView/AddUserSteps/SFUserList/helpers';
import { IProps } from 'components/settings/ManageUsers/UsersAddView/AddUserSteps/SFUserList/types';
import * as s from 'components/settings/ManageUsers/UsersAddView/styles';
import { typedRows } from 'components/settings/ManageUsers/helpers';
import {
  addButtonColor,
  tableActiveRowColor,
} from 'components/settings/ManageUsers/styles';
import { RowField } from 'components/settings/ManageUsers/types';

const SFUserList: React.FC<IProps> = ({
  currentUserRole,
  isLoading,
  getUsers,
  usersData,
  managersOptions,
  checked,
  onNextStepClick,
  onPrevStepClick,
}) => {
  const [rows, setRows] = useState<IRow[]>([]);
  const [sortOrder, setSortOrder] = useState('name');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const [searchText, setSearchText] = useState('');
  const [profileList, setProfileList] = useState<string[]>([]);
  const [profilesSelected, setProfilesSelected] = useState<string[]>([]);

  const [checkedRows, setCheckedRows] = useState<IRow[]>([]);

  useEffect(() => {
    setCheckedRows(checked);
  }, [JSON.stringify(checked)]);

  const checkedUsers = useMemo(
    () => typedRows(checkedRows).map((user) => user.user_id),
    [JSON.stringify(checkedRows)]
  );

  useEffect(() => {
    setProfileList(usersData.profile_names?.sort());
    setRows(
      usersData.users.map<RowField>((user, index) => ({
        ...user,
        id: user.email + index,
        name: user.name || user.email,
        manager: user.manager?.email || undefined,
        status: 'active',
        isChecked: checkedUsers.includes(user.user_id),
        sfdc_user_id: Array.isArray(user.sfdc_user_id)
          ? user.sfdc_user_id.find((el) => el.selected)?.id || ''
          : user.sfdc_user_id || '',
      }))
    );
  }, [usersData.users, checkedUsers]);

  useEffect(() => {
    const base: CompanyUsersQueryParams = {
      page_size: pageSize,
      page_number: pageNumber,
      sort: sortOrder,
      search: searchText,
    };

    const query = profilesSelected.length
      ? profilesSelected.reduce(
          (acc, profile) => `${acc}profile_names=${profile}&`,
          ''
        )
      : '';

    getUsers(base, query);
  }, [pageSize, pageNumber, sortOrder, searchText, profilesSelected]);

  const handleCheckRows = (changedRows: IRow[], checked: boolean) => {
    const changedUsers = typedRows(changedRows).map((user) => user.user_id);

    if (checked) {
      setCheckedRows(
        typedRows([...changedRows, ...checkedRows]).filter(
          (item, i, ar) => ar.indexOf(item) === i
        )
      );
    } else {
      setCheckedRows(
        typedRows(checkedRows).filter(
          (row) => !changedUsers.includes(row.user_id)
        )
      );
    }
  };

  const handleSort = (sort?: string) => {
    setPageNumber(1);
    setSortOrder(sort || '');
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPageNumber(page);
    setPageSize(pageSize);
  };

  const handleSearchChange = (text: string) => {
    setSearchText(text);
    setPageNumber(1);
  };
  const debounceSearchHandler = debounce(handleSearchChange, 1000);

  const handleProfileNameChange = (profile: string, checked: boolean) => {
    const isAll = profile === ALL_TEXT;
    const selected = checked
      ? [...profilesSelected, profile]
      : profilesSelected.filter((p: string) => p !== profile);
    const finalList = isAll ? [] : selected;
    setProfilesSelected(finalList);
  };

  const optionMaker = (label: string, checked: boolean, hide: Function) => (
    <BuDropdownItem key={label} value={label} active={false}>
      <BuCheckbox
        label={label}
        checked={checked}
        onChange={(checked) => {
          hide();
          handleProfileNameChange(label, checked);
        }}
      />
    </BuDropdownItem>
  );

  const optionList = (hide: Function) => {
    const options = profileList?.map((profile) =>
      optionMaker(profile, profilesSelected.includes(profile), hide)
    );

    options.unshift(optionMaker(ALL_TEXT, !profilesSelected.length, hide));
    return options;
  };

  const hasMoreThanOne = profilesSelected.length > 1;
  const sfdcProfileLabel = profilesSelected.length
    ? hasMoreThanOne
      ? `${profilesSelected[0]} (+${profilesSelected.length - 1})`
      : `${profilesSelected[0]}`
    : ALL_TEXT;

  return (
    <>
      <div
        className={classNames(
          s.mainContent,
          addButtonColor,
          tableActiveRowColor
        )}
      >
        <Table
          columns={getColumns({
            currentUserRole,
            managersOptions,
            highlightText: searchText,
          })}
          title="Select users to add"
          data={rows}
          loading={isLoading}
          rowsPerPage={pageSize}
          currentPage={pageNumber}
          totalCount={usersData.total}
          hidePaginationEnd
          paginationOptions={[25, 50, 100]}
          onPaginationChange={handlePaginationChange}
          searchPlaceholder="Search"
          onSearchChange={debounceSearchHandler}
          sortOrder={sortOrder}
          onSort={handleSort}
          fullscreen
          selectable="isChecked"
          selectedCount={checkedUsers.length}
          onSelect={handleCheckRows}
          controls={
            profileList?.length ? (
              <BuDropdown
                inlineLabel="SFDC Profile"
                label={sfdcProfileLabel}
                secondary
              >
                {(hide) => (
                  <BuDropdownItemContainer style={{ maxHeight: 300 }}>
                    {optionList(hide)}
                  </BuDropdownItemContainer>
                )}
              </BuDropdown>
            ) : null
          }
        />
      </div>

      <div className={s.actionButtons}>
        <BuButton
          secondary
          size={BuControlSize.REGULAR}
          onClick={onPrevStepClick}
        >
          Cancel
        </BuButton>
        <BuButton
          size={BuControlSize.REGULAR}
          onClick={() => onNextStepClick(checkedRows)}
          disabled={checkedRows.length === 0}
        >
          Next
        </BuButton>
      </div>
    </>
  );
};

export default SFUserList;
