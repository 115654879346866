import styled from '@emotion/styled';
import { css } from 'emotion';

import { fontSubtitle, checkedIcon } from 'assets/css/common';

const MORE_OPTIONS_WIDTH = 110;

export const wrapper = css`
  border: 1px solid var(--bu-gray-300);
  border-radius: 2px;
`;

export const header = css`
  align-items: center;
  border-bottom: 1px solid var(--bu-gray-300);
  display: flex;
  padding: 14px 20px;
  ${fontSubtitle};
  font-size: 14px;
`;

export const Scrollable = styled.div`
  overflow-x: auto;
`;

export const seriesContainer = css`
  width: calc(100% - ${MORE_OPTIONS_WIDTH}px);
  margin: 0 auto;
`;

export const columnsContainer = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
  height: 40px;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--bu-gray-500);
`;

export const checkboxContainer = css`
  margin: 10px 15px 0 !important;

  &:first-child {
    margin-left: 20px !important;
  }

  label {
    padding-left: 1.4em !important;

    &:before {
      width: 12px !important;
      height: 12px !important;
      top: 3px !important;
      border: 1px solid var(--bu-gray-500) !important;
    }

    &:after {
      width: 12px !important;
      height: 12px !important;
      font-size: 8px !important;
    }
  }

  input:checked ~ label:before {
    background-color: var(--bu-primary-500) !important;
    border-color: var(--bu-primary-500) !important;
  }

  input:checked ~ label:after {
    content: '' !important;
    top: 4px;
    left: 1px;
    width: 10px !important;
    height: 10px !important;

    ${checkedIcon}
  }
`;

export const filters = css`
  display: flex;
  flex-shrink: 0;
  margin-left: auto;
`;

export const dropdownContainer = css`
  font-size: 14px;
  margin-left: 16px;

  label {
    margin-right: 5px;
  }

  .ui.dropdown {
    height: 30px;
    min-height: 30px;
    min-width: 140px;
    padding-top: 6px;

    > .dropdown.icon {
      padding-top: 7px;
    }
  }
`;

export const moreGraphicsOptions = css`
  background: var(--bu-gray-300);
  width: ${MORE_OPTIONS_WIDTH}px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bu-gray-900);
  outline: none;
  border: 1px solid var(--bu-gray-500);
  border-top: none;
  border-right: none;
  cursor: pointer;
  font-size: 12px;
  z-index: 100;
`;

export const moreGraphicsOptionsDropdown = css`
  box-sizing: border-box;
  width: 100%;
  min-height: 250px;
  height: auto;
  border: 1px solid var(--bu-gray-400);
  border-radius: 4px;
  background-color: var(--bu-white);
  box-shadow: 0 22px 24px 0 rgba(0, 0, 0, 0.2);
  z-index: 160;
  position: absolute;
  margin-top: 5px;
  margin-left: -1px;
  padding-rigth: 1px;
  display: flex;
  justify-content: space-evenly;
  padding: 20px;

  h4 {
    height: 14px;
    color: var(--bu-gray-900);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    font-family: var(--bu-font-medium);
    font-size: 14px;
  }

  div {
    min-width: 150px;
  }
`;

export const listContainer = css`
  list-style-type: none;
  padding: 0;
  margin-top: 15px;
`;

export const listItem = css`
  height: 25px;
  margin-left: -8px;
`;

export const typedTableBorder = css`
  position: relative;
  z-index: 5;
`;
