import React, { useEffect } from 'react';

import BuSelect from 'components/UI/BuSelect';
import {
  NULL_OPERATOR_VALUES,
  SET_OPERATION_DROPDOWN_OPTIONS,
} from 'components/dashboard/Metrics/Create/Conditions/Condition/constants';
import { FixedWidthContainerWithMargin } from 'components/dashboard/Metrics/Create/Conditions/Condition/styles';
import { DateOperatorOption } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/types';
import { DropdownCalendar } from 'components/dashboard/Metrics/common/Calendar/Calendar';
import { useTimeOptions } from 'components/dashboard/Metrics/hooks/hooks';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import { BIMetricsFilter } from 'components/dashboard/Metrics/metrics.types';

type Props = {
  filter: Partial<BIMetricsFilter>;
  onOperatorSelect: (operator: string) => void;
  setFilter: (newFilter: Partial<BIMetricsFilter>) => void;
};

export const DateTypeCondition: React.FC<Props> = ({
  filter,
  onOperatorSelect,
  setFilter,
}) => {
  const { options: timeFilterOptions } = useTimeOptions();

  useEffect(() => {
    // when loading a metric to edit, if the filter value is a custom time period
    // the custom time period need to be added to timeValueOptions so that it
    // can be pre-selected in the UI
    if (
      typeof filter?.value === 'string' &&
      filter?.value.includes(',') &&
      !timeFilterOptions.predefined.some((e) => e.value === filter?.value) &&
      timeFilterOptions.predefined.length
    ) {
      timeFilterOptions.predefined.push({
        label: filter.value.split(',').join(' / '),
        value: filter.value,
      });
    }
  }, [JSON.stringify(timeFilterOptions)]);

  const handleDateValueChange = (change: DateOperatorOption): void => {
    const selectedValue = change.selected[0].id;

    setFilter({
      ...filter,
      operator: filter.operator
        ? filter.operator
        : SET_OPERATION_DROPDOWN_OPTIONS[0].value,
      value: selectedValue,
    });

    if (
      ![...timeFilterOptions.fiscal_year, ...timeFilterOptions.predefined].find(
        (option) => option.value === selectedValue
      )
    ) {
      timeFilterOptions.predefined.push({
        label: selectedValue.split(',').join(' / '),
        value: selectedValue,
      });
    }
  };

  return (
    <FlexRow>
      <FixedWidthContainerWithMargin marginRight={12} width={100}>
        <BuSelect
          fullWidth
          isLargePlaceholder
          secondary
          options={SET_OPERATION_DROPDOWN_OPTIONS}
          placeholder=""
          defaults={[
            filter.operator
              ? filter.operator
              : SET_OPERATION_DROPDOWN_OPTIONS[0].value,
          ]}
          onChange={(values: string[]) => {
            onOperatorSelect(values[0]);
          }}
          testingLabel="operator"
        />
      </FixedWidthContainerWithMargin>

      {!NULL_OPERATOR_VALUES.includes(filter.operator ?? '') && (
        <DropdownCalendar
          config={{
            allowFuture: true,
            checkedAll: false,
            checkedTotal: 0,
            title: 'Date Period',
            withShowAll: true,
            isLocked: false,
            customEnd: true,
            customStart: true,
            customPeriodValue:
              filter && filter.value ? (filter.value as string) : undefined,
            values: timeFilterOptions.predefined.map((option) => ({
              ...option,
              checked: option.value === filter.value,
            })),
            fiscalYearValues: timeFilterOptions.fiscal_year.map((option) => ({
              ...option,
              checked: option.value === filter.value,
            })),
            showInNotInFilter: false,
          }}
          key="Filter"
          name="Filter"
          onChange={handleDateValueChange}
        />
      )}
    </FlexRow>
  );
};
