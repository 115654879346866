import { css } from 'emotion';
import React, { useCallback, useState } from 'react';
import { Form, Segment } from 'semantic-ui-react';

import BuButton, { BuControlSize } from 'components/UI/BuButton';

interface OwnProps {
  value?: string;
  onChange: (value?: string | number) => any;
  placeholder?: string;
  disabled?: boolean;
}

type IProps = OwnProps;

const colors = css`
  &.ui.form {
    textarea,
    textarea:focus {
      border-color: var(--bu-primary-500);
      border-radius: 0;
    }

    .ui.button.primary {
      background-color: var(--bu-primary-500);
    }
  }
`;

const EditableTextBox: React.FC<IProps> = ({
  value,
  placeholder = '',
  onChange: _handleChange,
  disabled,
}: IProps) => {
  const [editing, setEditing] = useState(false);
  const [text, setText] = useState<string | number | undefined>(value);

  const handleSave = useCallback(() => {
    setEditing(false);
    _handleChange(text);
  }, [text, setEditing]);

  const handleCancel = useCallback(() => {
    setEditing(false);
    setText(value);
  }, [value, setText, setEditing]);

  const handleTextChange = useCallback(
    (_, field) => setText(field.value),
    [setText]
  );

  return (
    <>
      {(!editing || disabled) && (
        <Segment basic onClick={() => setEditing(true)}>
          {value
            ? (value || '')
                .split('\n')
                .map((line) => (
                  <p key={`${line}-${Math.random()}`}>{line || '\u00A0'}</p>
                ))
            : placeholder}
        </Segment>
      )}
      {editing && !disabled && (
        <Form onSubmit={handleSave} className={colors}>
          <Form.TextArea
            value={text}
            onChange={handleTextChange}
            placeholder={placeholder}
            autoHeight
          />

          <div>
            <BuButton size={BuControlSize.SMALL} onClick={handleSave}>
              Save
            </BuButton>
            <BuButton
              secondary
              size={BuControlSize.SMALL}
              onClick={handleCancel}
            >
              Cancel
            </BuButton>
          </div>
        </Form>
      )}
    </>
  );
};

export default EditableTextBox;
