import React, { useCallback, useState } from 'react';

import TypedRowWrapper from 'components/UI/common/TypedTable/TypedRowWrapper';
import {
  IColumn,
  IRow,
  onChangeCallback,
  OnSelect,
  RowStatusType,
} from 'components/UI/common/TypedTable/TypedTable';

export interface ITypedHierarchicalRow {
  draftRow?: IRow;
  level?: number;
  maxLevel: number;
  onChange: onChangeCallback;
  onDraftChange: onChangeCallback;
  onSelect?: OnSelect;
  row: IRow;
  rowClassName?: (row: IRow) => string;
  rowStatus?: RowStatusType;
  rows: IRow[];
  selectable?: false | string;
  setShowMoreDetailsVisible: Function;
  showMoreDetailsVisible: { [key: string]: boolean };
  showOrHideColumns?: { label: string; id: string; show: boolean }[];
  visibleColumns: IColumn[];
  enableReorderingRows: boolean;
  moveRow(id: IRow['id'], atIndex: number): void;
  findRow(id: IRow['id']): { row: IRow; index: number };
  canDragRow(row: IRow, rows: IRow[]): boolean;
  onDrop(rows: IRow[], row: IRow): void;
}

const TypedHierarchicalRow: React.FC<ITypedHierarchicalRow> = ({
  level = 0,
  row,
  showOrHideColumns,
  ...restProps
}) => {
  const { children, ...passedRowData } = row;
  const rowData = { ...passedRowData, hasChildren: !!children, children };
  const noHierarchy = children === undefined && level === 0;

  const [isHierarchyOpen, setHierarchyState] = useState(
    (row.isChildrenVisible as boolean) || false
  );

  const toggleHierarchy = useCallback(() => {
    setHierarchyState((prev) => !prev);
  }, []);

  const monthsExpandedRows = row.monthsExpandedRows as IRow[];

  const renderMonthsExpandedRows = () => {
    if (monthsExpandedRows) {
      return monthsExpandedRows.map((r) => (
        <TypedHierarchicalRow
          key={`row-${r.id}`}
          level={level + 1}
          showOrHideColumns={showOrHideColumns}
          row={r}
          {...restProps}
        />
      ));
    }
  };

  if (noHierarchy) {
    return (
      <>
        <TypedRowWrapper
          showOrHideColumns={showOrHideColumns}
          row={rowData}
          {...restProps}
        />
        {renderMonthsExpandedRows()}
      </>
    );
  }

  return (
    <>
      <TypedRowWrapper
        isHierarchyOpen={children ? isHierarchyOpen : undefined}
        level={level}
        row={rowData}
        toggleHierarchy={children ? toggleHierarchy : undefined}
        showOrHideColumns={showOrHideColumns}
        {...restProps}
      />
      {renderMonthsExpandedRows()}
      {isHierarchyOpen && children
        ? children.map((r) => (
            <TypedHierarchicalRow
              key={`row-${r.id}`}
              level={level + 1}
              showOrHideColumns={showOrHideColumns}
              row={r}
              {...restProps}
            />
          ))
        : null}
    </>
  );
};

export default TypedHierarchicalRow;
