/* eslint-disable import/prefer-default-export */
import { Scheme } from 'navigation/routes';

export type Features = {
  isAdminEnabled: boolean;
  isForecastDashboardEnabled: boolean;
  isRepPaceEnabled: boolean;
  isScorecardEnabled: boolean;
  isMeetingsDashboardEnabled: boolean;
  isSankeyChartEnabled: boolean;
};

export type Tabs = {
  scheme: Scheme;
  title: string;
  name?: string;
  isVisible?: (
    userRole: string,
    features: Features,
    hiddenPages?: string[]
  ) => boolean;
}[];

export type Partition =
  | 'forecast'
  | 'accounts'
  | 'cro_override'
  | 'opportunities'
  | 'calls'
  | 'meetings'
  | 'pipeline'
  | 'targets'
  | 'activity'
  | 'dashboards'
  | 'metrics';

type Partitions = {
  [key in Partition]: Tabs;
};

export const partitions: Partitions = {
  forecast: [
    {
      scheme: '/forecast/dashboard',
      name: 'forecast__dashboard',
      title: 'Dashboard',
    },
    {
      scheme: '/forecast/analytics',
      name: 'forecast__trends',
      title: 'Trends',
    },
    {
      scheme: '/forecast/pacing',
      name: 'forecast__pacing',
      title: 'Pacing',
    },
    {
      scheme: '/forecast/roll-ups',
      title: 'Roll Ups',
      name: 'forecast__rollups',
    },
  ],
  opportunities: [
    {
      scheme: '/opportunities',
      name: 'opportunities__dashboard',
      title: 'Opportunities',
    },
  ],
  calls: [
    {
      scheme: '/calls/upcoming',
      name: 'calls__upcoming_calls',
      title: 'Upcoming Calls',
    },
    {
      scheme: '/calls',
      name: 'calls__completed_calls',
      title: 'Completed Calls',
    },
  ],
  pipeline: [
    {
      scheme: '/pipeline/dashboard',
      name: 'pipeline__dashboard',
      title: 'Dashboard',
    },
    {
      scheme: '/pipeline/analytics',
      name: 'pipeline__risk_analytics',
      title: 'Risk Analytics',
    },
    {
      scheme: '/pipeline/deals-progression',
      title: 'Deals Progression',
      name: 'pipeline__deals_progression',
      isVisible: (userRole, features) => features.isSankeyChartEnabled,
    },
  ],
  targets: [
    {
      scheme: '/targets/forecast',
      name: 'targets__forecast',
      title: 'Forecast Targets',
      isVisible: (userRole, features, hiddenPages) =>
        !hiddenPages?.includes('/targets/forecast'),
    },
    {
      scheme: '/targets/pipeline-creation',
      name: 'targets__pipeline_creation',
      title: 'Pipeline Creation Targets',
      isVisible: (userRole, features, hiddenPages) =>
        !hiddenPages?.includes('/targets/pipeline-creation'),
    },
    {
      scheme: '/targets/pipeline-coverage',
      title: 'Pipeline Coverage Targets',
      name: 'targets__pipeline_coverage',
      isVisible: (userRole, features, hiddenPages) =>
        !hiddenPages?.includes('/targets/pipeline-coverage'),
    },
  ],
  activity: [
    {
      scheme: '/dashboards/0',
      title: 'Leaderboard',
      name: 'activity__dashboard',
    },
    {
      scheme: '/scorecard',
      title: 'Scorecard',
      name: 'activity__scorecard',
      isVisible: (userRole, features) => features.isScorecardEnabled,
    },
  ],
  cro_override: [
    {
      scheme: '/submission/forecast',
      title: 'Submission Forecast',
    },
    {
      scheme: '/submission/history',
      title: 'Submission History',
    },
  ],
  meetings: [
    {
      scheme: '/meetings',
      title: 'Upcoming meetings',
      name: 'meetings__upcoming',
      isVisible: (userRole, features) => features.isMeetingsDashboardEnabled,
    },
    {
      scheme: '/meetings/completed',
      title: 'Completed Meetings',
      name: 'meetings__completed',
      isVisible: (userRole, features) => features.isMeetingsDashboardEnabled,
    },
  ],
  dashboards: [
    {
      scheme: '/dashboard/forecast',
      title: 'Forecast',
      name: 'dashboards__forecast',
      isVisible: (userRole, features) => features.isForecastDashboardEnabled,
    },
    {
      scheme: '/dashboard/trends',
      title: 'Trends',
      name: 'dashboards__trends',
    },
    {
      scheme: '/dashboard/pacing',
      title: 'Pacing',
      name: 'dashboards__pacing',
      isVisible: (userRole, features) => features.isRepPaceEnabled,
    },
    {
      scheme: '/dashboard/pipeline',
      name: 'dashboards__pipeline',
      title: 'Pipeline',
    },
    {
      scheme: '/dashboard/risk_analytics',
      name: 'dashboards__risk_analytics',
      title: 'Risk Analytics',
    },
    {
      scheme: '/dashboard/leaderboard',
      name: 'dashboards__leaderboard',
      title: 'Leaderboard',
    },
    {
      scheme: '/dashboard/scorecard',
      name: 'dashboards__scorecard',
      title: 'Scorecard',
      isVisible: (userRole, features) => features.isScorecardEnabled,
    },
    {
      scheme: '/dashboard/seller',
      name: 'dashboards__seller',
      title: 'Seller',
    },
  ],
  metrics: [
    {
      scheme: '/revbi/metrics/list',
      title: 'Metrics List',
    },
    {
      scheme: '/revbi',
      title: 'Revbi',
    },
  ],
} as Partitions;
