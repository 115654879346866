import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Dropdown, Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { sortAlphabetically } from 'common/helpers';
import BuIcon from 'components/UI/BuIcon';
import { ISelectOption } from 'components/UI/BuSelect/types';
import {
  TOOLTIP_CONTENT_ALL,
  TOOLTIP_CONTENT_SOME,
} from 'components/dashboard/Metrics/Widget/Controls/constants';
import { groupColumns } from 'components/dashboard/Metrics/Widget/Controls/helpers';
import {
  ControlsContainer,
  FilterBarElement,
  PivotFiltersContainer,
} from 'components/dashboard/Metrics/Widget/Controls/styles';
import * as s from 'components/dashboard/Metrics/Widget/Controls/styles';
import {
  BIMetricColumn,
  BIWidgetDataV2,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  isTargetMetricOnlyWidget: boolean;
  showControls?: boolean;
  firstPivotColumns: BIMetricColumn[];
  secondPivotColumns: BIMetricColumn[];
  pivot1?: BIMetricColumn;
  pivot2?: BIMetricColumn;
  widgetChartType: string;
  onPivot1Change: (values: string[]) => void;
  onPivot2Change: (values: string[]) => void;
}

export const BaseMetricsWidgetControls: React.FC<Props> = ({
  isTargetMetricOnlyWidget = false,
  showControls = false,
  firstPivotColumns,
  secondPivotColumns,
  pivot1,
  pivot2,
  widgetChartType,
  onPivot1Change,
  onPivot2Change,
}) => {
  const [searchValuePivot1, setSearchValuePivot1] = useState('');
  const [searchValuePivot2, setSearchValuePivot2] = useState('');

  const groupByColumnsFirstPivot = useMemo(() => {
    const dateTypes = firstPivotColumns.filter(
      (column) => column.type === 'date'
    );

    return groupColumns(firstPivotColumns, dateTypes);
  }, [firstPivotColumns]);

  const groupByColumnsSecondPivot = useMemo(() => {
    const dateTypes = secondPivotColumns.filter(
      (column) => column.type === 'date'
    );
    const groupedColumns = groupColumns(secondPivotColumns, dateTypes);

    return groupedColumns;
  }, [secondPivotColumns]);

  const pivot1Options: ISelectOption[] = useMemo(
    () =>
      sortAlphabetically(
        groupByColumnsFirstPivot.map((column) => ({
          text: column.label,
          value: column.name,
        })),
        'none'
      ),
    [groupByColumnsFirstPivot]
  );

  const pivot2Options: any[] = useMemo(() => {
    // TODO fix type
    if (pivot1?.name === 'none') return [];

    return sortAlphabetically(
      groupByColumnsSecondPivot
        .filter(
          (column) => column.name != pivot1?.name && column.type != 'timePeriod'
        )
        .map((column) => ({
          text: column.label,
          value: column.name,
          applies_to_all_metrics: column.applies_to_all_metrics,
        })),
      'none'
    );
  }, [groupByColumnsSecondPivot, pivot1]);

  return (
    <ControlsContainer isSpaceBetween showControls={showControls}>
      <PivotFiltersContainer className={'notExpanded'}>
        <FilterBarElement>
          <BuIcon name={BoostUpIcons.Layers} color="var(--bu-gray-700)" />
        </FilterBarElement>
        <Dropdown
          text={
            pivot1?.label && pivot1?.label !== 'None'
              ? pivot1.label
              : 'Add pivot'
          }
          className={s.pivotWidget}
          fluid
          direction="right"
          onClose={() => setSearchValuePivot1('')}
          data-testing="pivot-one"
        >
          <Dropdown.Menu className={s.pivotWidgetMenu}>
            <div className={s.pivotWidgetSearch}>
              <BuIcon name={BoostUpIcons.Search} />
              <input
                value={searchValuePivot1}
                type="text"
                placeholder="Search..."
                data-testing="search_txt_field"
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSearchValuePivot1(e.target.value);
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onKeyDown={(e) => {
                  if (e.key === ' ') {
                    e.preventDefault();
                    e.stopPropagation();
                    setSearchValuePivot1((prev) => prev + ' ');
                  }
                }}
              />
            </div>
            {pivot1Options
              .filter((el) =>
                el.text.toLowerCase().includes(searchValuePivot1.toLowerCase())
              )
              .map((el, idx) =>
                el.text === 'None' ? (
                  pivot1?.label !== 'None' &&
                  pivot1 !== undefined &&
                  !!pivot1 && (
                    <div
                      key={el.value}
                      className={classNames(s.pivotWidgetSearchOption, {
                        selected: pivot1?.name === el.value,
                      })}
                      onClick={() => onPivot1Change([el.value])}
                      data-testing={`pivot-one-option-${idx}`}
                    >
                      {el.text === 'None' ? 'Remove pivot' : el.text}
                    </div>
                  )
                ) : (
                  <div
                    key={el.value}
                    className={classNames(s.pivotWidgetSearchOption, {
                      selected: pivot1?.name === el.value,
                    })}
                    onClick={() => onPivot1Change([el.value])}
                    data-testing={`pivot-one-option-${idx}`}
                  >
                    {el.text === 'None' ? 'Remove pivot' : el.text}
                  </div>
                )
              )}
          </Dropdown.Menu>
        </Dropdown>

        {widgetChartType !== 'pie' && (
          <Dropdown
            data-testing="pivot-two"
            text={
              pivot2?.label && pivot2?.label !== 'None'
                ? pivot2.label
                : 'Add pivot'
            }
            className={s.pivotWidget}
            fluid
            direction="right"
            onClose={() => setSearchValuePivot2('')}
            disabled={
              !pivot1 ||
              pivot1.name.toLocaleLowerCase() === 'none' ||
              isTargetMetricOnlyWidget
            }
          >
            <Dropdown.Menu className={s.pivotWidgetMenu}>
              <div className={s.pivotWidgetSearch}>
                <BuIcon name={BoostUpIcons.Search} />
                <input
                  value={searchValuePivot2}
                  type="text"
                  placeholder="Search..."
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSearchValuePivot2(e.target.value);
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === ' ') {
                      e.preventDefault();
                      e.stopPropagation();
                      setSearchValuePivot2((prev) => prev + ' ');
                    }
                  }}
                  data-testing="search_txt_field"
                />
              </div>
              <div className={s.pivot2WidgetSeparator}>
                Applies to all metrics
                <Popup
                  position="top center"
                  content={TOOLTIP_CONTENT_ALL}
                  trigger={
                    <div>
                      <BuIcon name={BoostUpIcons.Table} />
                      <BuIcon name={BoostUpIcons.GroupedColumnGraph} />
                      <BuIcon name={BoostUpIcons.GroupedBarGraph} />
                      <BuIcon name={BoostUpIcons.CombinedGraphs} />
                    </div>
                  }
                  hoverable
                ></Popup>
              </div>
              {pivot2Options
                .filter(
                  (el) =>
                    !!el.applies_to_all_metrics &&
                    el.text
                      .toLowerCase()
                      .includes(searchValuePivot2.toLowerCase())
                )
                .map((el, idx) =>
                  el.text === 'None' ? (
                    pivot2 !== undefined &&
                    !!pivot2 &&
                    pivot2.label !== 'None' && (
                      <div
                        key={el.value}
                        className={classNames(s.pivotWidgetSearchOption, {
                          selected: pivot2?.name === el.value,
                        })}
                        onClick={() => onPivot2Change([el.value])}
                        data-testing={`pivot-two-all-option-${idx}`}
                      >
                        {el.text === 'None' ? 'Remove pivot' : el.text}
                      </div>
                    )
                  ) : (
                    <div
                      key={el.value}
                      className={classNames(s.pivotWidgetSearchOption, {
                        selected: pivot2?.name === el.value,
                      })}
                      onClick={() => onPivot2Change([el.value])}
                      data-testing={`pivot-two-all-option-${idx}`}
                    >
                      {el.text === 'None' ? 'Remove pivot' : el.text}
                    </div>
                  )
                )}

              <div className={s.pivot2WidgetSeparator}>
                Applies to some metrics
                <Popup
                  position="top center"
                  content={TOOLTIP_CONTENT_SOME}
                  trigger={
                    <div>
                      <BuIcon name={BoostUpIcons.CombinedGraphs} />
                    </div>
                  }
                  hoverable
                ></Popup>
              </div>
              {pivot2Options
                .filter(
                  (el) =>
                    !el.applies_to_all_metrics &&
                    el.text
                      .toLowerCase()
                      .includes(searchValuePivot2.toLowerCase())
                )
                .map((el, idx) => (
                  <div
                    key={el.value}
                    className={classNames(s.pivotWidgetSearchOption, {
                      selected: pivot2?.name === el.value,
                    })}
                    onClick={() => onPivot2Change([el.value])}
                    data-testing={`pivot-two-some-option-${idx}`}
                  >
                    {el.text}
                  </div>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </PivotFiltersContainer>
    </ControlsContainer>
  );
};
