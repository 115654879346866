import { isNil } from 'ramda';
import React, { useMemo } from 'react';

import { UserSubmission } from 'actions/croOverrideActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import {
  CustomComponentFunction,
  getCellValue,
} from 'components/UI/common/TypedTable/renderers/custom/common';
import {
  Cell,
  DeltaIconWrapper,
  Sub,
  Row,
} from 'components/dashboard/CROOverride/cell-renderers/forecastCRO';
import { RowSubmission } from 'components/dashboard/CROOverride/types';
import Tooltip from 'components/dashboard/Forecast/Dashboard/SubmitForecast/Tooltip';

type ForecastCellConfig = {
  companyCurrency: string;
  businessType: string;
  forecastName: string;
  period: string;
  currencyFormatter(value: number): string;
};
const forecastCell: CustomComponentFunction<ForecastCellConfig> = ({
  companyCurrency,
  currencyFormatter,
  period,
  businessType,
  forecastName,
}) => (props) => {
  const { row, column } = props;

  const { user_id, currency } = row as RowSubmission;
  const submission = getCellValue(props) as UserSubmission;
  const {
    user_amount,
    user_deals_included,
    user_deals_total,
    user_deals_amount,
    override_last_user_submission,
    user_submission_id,
    delta,
  } = submission ?? {};

  const isForecastExists = !isNil(submission);

  const isCurrencyDiffThenUser = Boolean(
    currency && currency !== companyCurrency
  );

  const tooltip = useMemo(
    () => (
      <Tooltip
        title={column.label}
        userId={user_id}
        forecastName={forecastName}
        businessTypeName={businessType}
        companyCurrency={companyCurrency}
        closeDateInterval={period}
        isCurrencyDiffThenUser={isCurrencyDiffThenUser}
      />
    ),
    [
      column.label,
      user_id,
      forecastName,
      businessType,
      companyCurrency,
      period,
      isCurrencyDiffThenUser,
    ]
  );

  const cellContent = useMemo(
    () => (
      <Cell>
        <Row>
          {override_last_user_submission &&
            user_submission_id &&
            override_last_user_submission !== user_submission_id && (
              <TooltipWrapper
                tooltip={
                  <span>
                    Submitted forecast amount for this user has changed and you
                    may want to change yours
                  </span>
                }
              >
                <span>
                  <BuIcon
                    name={BoostUpIcons.DangerCircle}
                    color="var(--bu-red-500)"
                  />
                </span>
              </TooltipWrapper>
            )}

          {delta && (
            <DeltaIconWrapper className={delta}>
              <BuIcon
                name={
                  delta === 'up'
                    ? BoostUpIcons.ChangeUp
                    : BoostUpIcons.ChangeDown
                }
              />
            </DeltaIconWrapper>
          )}
          {isNil(user_amount) || !isForecastExists ? (
            '-'
          ) : (
            <>
              {' '}
              {isCurrencyDiffThenUser && (
                <BuIcon name={BoostUpIcons.MultiCurrency} />
              )}
              {currencyFormatter(user_amount)}
            </>
          )}
        </Row>

        {!isNil(user_deals_amount) && (
          <Sub>
            {user_deals_included} of {user_deals_total} (
            {currencyFormatter(user_deals_amount)})
          </Sub>
        )}
      </Cell>
    ),
    [
      user_submission_id,
      override_last_user_submission,
      delta,
      isForecastExists,
      user_amount,
      tooltip,
      user_deals_included,
      user_deals_total,
      user_deals_amount,
    ]
  );

  return isNil(user_amount) || !isForecastExists ? (
    cellContent
  ) : (
    <TooltipWrapper tooltip={tooltip} hoverable>
      {cellContent}
    </TooltipWrapper>
  );
};

export default forecastCell;
