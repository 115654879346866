import * as styles from './styles';
import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';

import { multiplierFormatter } from 'components/UI/TableConfig/column-helper';
import {
  IColumn,
  IRow,
  SortOrder,
  TypedTableCellConfig,
} from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import { Target } from 'reducers/sellerTargetsReducer/types';
import { calcFiscalMonthName } from 'utils/dates';

type GetColumnsFunction = (
  isQuarterly: boolean,
  fiscalYearStartMonth: number,
  targets: Target[],
  fiscalYear: string,
  businessTypes: string[],
  currentFiscalYear: number,
  currentFiscalQuarter: number,
  companyCurrency: string,
  isCoverage: boolean
) => {
  columns: IColumn[];
  extraHeader: IColumn[];
};

const getTargetKey = (
  isQuarterly: boolean,
  period: Target['quarter'],
  fiscalYear: string
) => {
  const label = (isQuarterly ? 'Q' : 'M') + period;

  return `${label} ${fiscalYear}`;
};

const getTargetColumn = (
  isQuarterly: boolean,
  isCoverage: boolean,
  companyCurrency: string,
  period: number | undefined,
  label: string,
  fiscalYear: string,
  currentYear: string,
  businessType?: string
): IColumn => ({
  align: 'right',
  config: {
    isCoverage,
    isMoney: true,
    formatter: (isCoverage
      ? multiplierFormatter
      : currencyFormatter(
          companyCurrency,
          0
        )) as TypedTableCellConfig['formatter'],
    className: (row: IRow, rows: IRow[], column: IColumn) => {
      const targets = row[column.field]?.valueOf() as {
        target: number;
        new_target: number;
      };
      if (!targets || targets.new_target === targets.target) {
        return '';
      }
      return targets.target < targets.new_target
        ? styles.cellGreen
        : styles.cellRed;
    },
    renderer: (params) => {
      let targets = params.row[params.column.field]?.valueOf() as {
        target: number;
        new_target: number;
      };
      let value = undefined;
      let subValue = undefined;
      if (targets && params.column.config.formatter) {
        value = !isNaN(targets.new_target)
          ? params.column.config.formatter(targets.new_target)
          : params.column.config.formatter(targets.target);
        subValue = !isNaN(targets.target)
          ? params.column.config.formatter(targets.target)
          : undefined;
      }
      return (
        <div className={styles.targetCellContainer}>
          <span className={styles.targetCellValue}>{value}</span>
          {!!subValue && subValue !== value && (
            <span className={styles.targetCellSubValue}>{subValue}</span>
          )}
        </div>
      );
    },
  } as CustomCellConfig,
  field: getTargetKey(isQuarterly, period, fiscalYear),
  id: getTargetKey(isQuarterly, period, fiscalYear),
  [isQuarterly ? 'quarter' : 'month']: period,
  editable: false,
  sort_order: SortOrder.ASCENDING,
  sortable: false,
  type: ColumnTypes.CUSTOM,
  ...(businessType
    ? {
        business_type: businessType,
        label: `${businessType}`,
      }
    : {
        label: `${label} ${currentYear} ${fiscalYear}`,
      }),
});

export const getColumns: GetColumnsFunction = (
  isQuarterly,
  fiscalYearStartMonth,
  targets,
  fiscalYear,
  businessTypes,
  currentFiscalYear,
  currentFiscalQuarter,
  companyCurrency,
  isCoverage
) => {
  let columns: IColumn[] = [];
  const currentYear = String(currentFiscalYear) === fiscalYear ? 'CFY' : 'FY';

  if (targets.length) {
    columns.push({
      id: 'user',
      label: 'Users',
      field: 'user',
      type: ColumnTypes.CUSTOM,
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      config: {
        className: (row: IRow, rows: IRow[]) => css`
          background-color: ${row.isChanged
            ? 'var(--bu-orange-300) !important'
            : 'var(--bu-white)'};
        `,
        renderer: (params) => <>{params.row[params.column.field]}</>,
      } as CustomCellConfig,
    });

    targets
      // The next two lines ensure the uniqueness of the columns
      .map((item) => (isQuarterly ? item.quarter : item.month))
      .filter((value, index, self) => self.indexOf(value) === index)
      .forEach((period: number | undefined) => {
        const label = isQuarterly
          ? `Q${period}`
          : calcFiscalMonthName(1, period || 1, 'short');

        columns.push(
          getTargetColumn(
            isQuarterly,
            isCoverage,
            companyCurrency,
            period,
            label,
            fiscalYear,
            currentYear
          )
        );
      });
  }

  // Adding QUARTER_DELIMITER_COLUMN class to separate vertically the quarters
  if (isQuarterly) {
    let currentQuarter: number;
    columns = columns.map((col) => {
      const { config, quarter } = col;
      let delimiterCls: string;
      if (currentQuarter !== quarter) {
        currentQuarter = quarter;
        delimiterCls = styles.QUARTER_DELIMITER_COLUMN;
      }

      return {
        ...col,
        config: {
          ...config,
          className: (row: IRow, rows: IRow[], clmn?: IColumn) =>
            classNames(
              config.className && typeof config.className === 'function'
                ? config.className(row, rows, clmn)
                : config.className,
              delimiterCls
            ),
        },
      };
    });
  }

  return {
    columns,
    extraHeader: [],
  };
};

const getTargetRows = (
  isQuarterly: boolean,
  targetList: Target[],
  fiscalYear: string,
  targetType: string
): IRow[] =>
  targetList.map((target: Target, i: number) => ({
    id: `${targetType}-${i}`,
    targetType,
    user: target.user.name || target.user.email,
    email: target.user.email,
    fiscal_year: fiscalYear,
    isChanged: target.targets?.some((el) => el.target !== el.new_target),
    ...(isQuarterly ? { quarter: target.quarter } : { month: target.month }),
    ...target.targets?.reduce(
      (acc, item: Target) => ({
        ...acc,
        [getTargetKey(
          isQuarterly,
          isQuarterly ? item.quarter : item.month,
          fiscalYear
        )]: { new_target: item.new_target, target: item.target },
      }),
      {}
    ),
  }));

const getParentRow = (
  isQuarterly: boolean,
  targetList: Target[],
  fiscalYear: string,
  rows: IRow[],
  targetType: string
): IRow => {
  const calculatedCustomTotals: {
    [key: string]: { target: number; new_target: number };
  } = {};

  targetList.forEach((target: Target) => {
    const key = getTargetKey(
      isQuarterly,
      isQuarterly ? target.quarter : target.month,
      fiscalYear
    );

    calculatedCustomTotals[key] = rows.reduce(
      (agg, item) => {
        const value = item[key] as { target: number; new_target: number };
        return {
          target: isNaN(value.target) ? agg.target : agg.target + value.target,
          new_target: isNaN(value.new_target)
            ? agg.new_target
            : agg.new_target + value.new_target,
        };
      },
      { target: 0, new_target: 0 }
    );
  });

  return {
    id: targetType,
    user: targetType,
    children: rows,
    isTopRow: true,
    disableEdit: true,
    ...calculatedCustomTotals,
  };
};

export const getRows = (
  isQuarterly: boolean,
  fiscalYearStartMonth: number,
  targets: Target[],
  targetByManager: Target[],
  targetsByUser: Target[],
  fiscalYear: string,
  businessType: string
): IRow[] => {
  const rowOverall: IRow = {
    id: 'overall',
    user: 'Overall',
    isTopRow: true,
    fiscal_year: fiscalYear,
    ...targets
      .filter((target) => target.business_type === businessType)
      .reduce(
        (acc, target: Target) => ({
          ...acc,
          [getTargetKey(
            isQuarterly,
            isQuarterly ? target.quarter : target.month,
            fiscalYear
          )]: { target: target.target, new_target: target.target },
        }),
        {}
      ),
  };

  const rowsManager = getTargetRows(
    isQuarterly,
    targetByManager,
    fiscalYear,
    'manager'
  );
  const rowsUser = getTargetRows(
    isQuarterly,
    targetsByUser,
    fiscalYear,
    'user'
  );

  const rowTopManager = getParentRow(
    isQuarterly,
    targets,
    fiscalYear,
    rowsManager,
    'Managers'
  );
  const rowTopUser = getParentRow(
    isQuarterly,
    targets,
    fiscalYear,
    rowsUser,
    'Sellers'
  );

  return [
    rowOverall,
    { id: 'empty-manager', isEmpty: true },
    rowTopManager,
    { id: 'empty-user', isEmpty: true },
    rowTopUser,
  ];
};
