import * as R from 'ramda';
import { combineReducers } from 'redux';

import * as t from 'actions/actionTypes';
import { DEFAULT_PAGE_SIZE } from 'actions/aiTrainingActions';

const defaultVersions = [];
const versions = (state = defaultVersions, action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.VERSION + t.GET + t.SUCCESS:
      return action.payload.data;
    case t.AI_TRAINING + t.VERSION + t.PAGINATION + t.SUCCESS:
      return R.concat(state, action.payload.data);
    case t.AI_TRAINING + t.VERSION + t.REMOVE + t.SUCCESS:
      return R.reject(R.propEq('id', action.payload))(state);
    default:
      return state;
  }
};

const versionsCount = (state = null, action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.VERSION + t.GET + t.SUCCESS:
    case t.AI_TRAINING + t.VERSION + t.PAGINATION + t.SUCCESS:
      return action.payload.total_count;
    default:
      return state;
  }
};

const defaultVersionsStatus = 'loaded';
const versionsStatus = (state = defaultVersionsStatus, action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.VERSION + t.GET + t.LOADING:
      return 'loading';
    case t.AI_TRAINING + t.VERSION + t.GET + t.SUCCESS:
      return 'loaded';
    case t.AI_TRAINING + t.VERSION + t.GET + t.ERROR:
      return 'error';
    default:
      return state;
  }
};

const currentVersion = (state = null, action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.VERSION + t.SET:
      return action.payload;
    case t.AI_TRAINING + t.VERSION + t.CREATE + t.SUCCESS:
      return action.payload.data;
    case t.AI_TRAINING + t.CLEAR:
      return null;
    default:
      return state;
  }
};

const defaultVersionErrors = {};
const versionErrors = (state = defaultVersionErrors, action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.CLEAR:
    case t.AI_TRAINING + t.VERSION + t.CREATE:
    case t.AI_TRAINING + t.VERSION + t.ERROR + t.CLEAR:
      return defaultVersionErrors;
    case t.AI_TRAINING + t.VERSION + t.CREATE + t.ERROR:
      return action.error.response.data;
    default:
      return state;
  }
};

const status = (state = null, action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.VERSION + t.SET:
      return 'ro';
    case t.AI_TRAINING + t.VERSION + t.CREATE + t.SUCCESS:
      return 'rw';
    case t.AI_TRAINING + t.STATUS + t.CHANGE:
      return action.payload;
    case t.AI_TRAINING + t.CLEAR:
      return null;
    default:
      return state;
  }
};

const contentStatus = (state = 'loaded', action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.CONTENT + t.GET + t.LOADING:
      return 'loading';
    case t.AI_TRAINING + t.CONTENT + t.GET + t.SUCCESS:
      return 'loaded';
    case t.AI_TRAINING + t.CONTENT + t.GET + t.ERROR:
      return 'error';
    default:
      return state;
  }
};

const defaultContentIds = [];
const contentIds = (state = defaultContentIds, action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.CONTENT + t.GET + t.SUCCESS:
      return R.pluck('id')(action.payload.data.content);
    case t.AI_TRAINING + t.CONTENT + t.PAGINATION + t.SUCCESS:
      return R.compose(
        R.concat(state),
        R.pluck('id')
      )(action.payload.data.content);
    case t.AI_TRAINING + t.CLEAR:
      return defaultContentIds;
    default:
      return state;
  }
};

const defaultContentById = {};
const contentById = (state = defaultContentById, action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.CONTENT + t.GET + t.SUCCESS:
      return R.indexBy(R.prop('id'))(action.payload.data.content);
    case t.AI_TRAINING + t.CONTENT + t.PAGINATION + t.SUCCESS:
      return R.compose(
        R.merge(state),
        R.indexBy(R.prop('id'))
      )(action.payload.data.content);
    case t.AI_TRAINING + t.CLEAR:
      return defaultContentIds;
    default:
      return state;
  }
};

const contentTotalCount = (state = null, action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.CONTENT + t.GET + t.SUCCESS:
    case t.AI_TRAINING + t.CONTENT + t.PAGINATION + t.SUCCESS:
      return action.payload.total_count;
    case t.AI_TRAINING + t.CLEAR:
      return null;
    default:
      return state;
  }
};

const contentCurrentCount = (state = null, action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.CONTENT + t.GET + t.SUCCESS:
      return DEFAULT_PAGE_SIZE;
    case t.AI_TRAINING + t.CONTENT + t.PAGINATION + t.SUCCESS:
      return state + DEFAULT_PAGE_SIZE;
    case t.AI_TRAINING + t.CLEAR:
      return null;
    default:
      return state;
  }
};

const labeledContentStatus = (state = 'loaded', action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.LABELED_CONTENT + t.GET + t.LOADING:
      return 'loading';
    case t.AI_TRAINING + t.LABELED_CONTENT + t.GET + t.SUCCESS:
      return 'loaded';
    case t.AI_TRAINING + t.LABELED_CONTENT + t.GET + t.ERROR:
      return 'error';
    default:
      return state;
  }
};

const defaultLabeledContentIds = [];
const labeledContentIds = (state = defaultLabeledContentIds, action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.LABELED_CONTENT + t.GET + t.SUCCESS:
      return R.pluck('content_id')(action.payload.data);
    case t.AI_TRAINING + t.LABELED_CONTENT + t.PAGINATION + t.SUCCESS:
      return R.compose(
        R.concat(state),
        R.pluck('content_id')
      )(action.payload.data);
    case t.AI_TRAINING + t.CLEAR:
    case t.AI_TRAINING + t.CONTENT + t.GET + t.SUCCESS:
      return defaultContentIds;
    default:
      return state;
  }
};

const defaultLabeledContentById = {};
const labeledContentById = (state = defaultLabeledContentById, action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.LABELED_CONTENT + t.GET + t.SUCCESS:
      return R.indexBy(R.prop('content_id'))(action.payload.data);
    case t.AI_TRAINING + t.LABELED_CONTENT + t.PAGINATION + t.SUCCESS:
      return R.compose(
        R.merge(state),
        R.indexBy(R.prop('content_id'))
      )(action.payload.data);
    case t.AI_TRAINING + t.LABELED_CONTENT + t.CREATE + t.SUCCESS:
    case t.AI_TRAINING + t.LABELED_CONTENT + t.UPDATE + t.SUCCESS:
      return R.merge(state)({
        [action.payload.data.content_id]: action.payload.data,
      });
    case t.AI_TRAINING + t.CONTENT + t.GET + t.SUCCESS:
      return R.indexBy(R.prop('content_id'))(
        action.payload.data.labeled_content
      );
    case t.AI_TRAINING + t.CONTENT + t.PAGINATION + t.SUCCESS:
      return R.compose(
        R.merge(state),
        R.indexBy(R.prop('content_id'))
      )(action.payload.data.labeled_content);
    case t.AI_TRAINING + t.CLEAR:
      return defaultLabeledContentById;
    default:
      return state;
  }
};

const labeledContentCount = (state = null, action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.LABELED_CONTENT + t.GET + t.SUCCESS:
    case t.AI_TRAINING + t.LABELED_CONTENT + t.PAGINATION + t.SUCCESS:
      return action.payload.total_count;
    case t.AI_TRAINING + t.CLEAR:
    case t.AI_TRAINING + t.CONTENT + t.GET + t.SUCCESS:
      return null;
    default:
      return state;
  }
};

const showFilters = (state = false, action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.FILTER + t.TOGGLE:
      return !state;
    case t.AI_TRAINING + t.CLEAR:
      return false;
    default:
      return state;
  }
};

const defaultFilters = {
  sellers: ['__all__'],
  stages: ['__all__'],
  topics: ['__all__'],
  time: ['__all__'],
  ignore_domains: [],
};
const filters = (state = defaultFilters, action) => {
  switch (action.type) {
    case t.AI_TRAINING + t.FILTER + t.CHANGE:
      return R.compose(
        R.mapObjIndexed((filter, filterName) =>
          R.when(
            R.isEmpty, // if empty, use the default value
            R.always(R.prop(filterName, defaultFilters))
          )(filter)
        ),
        R.merge(state)
      )(action.payload);
    case t.AI_TRAINING + t.FILTER + t.REMOVE:
      return R.evolve({
        [action.payload.key]: R.compose(
          R.when(
            R.isEmpty, // if empty, use the default value
            R.always(R.prop(action.payload.key, defaultFilters))
          ),
          R.without([action.payload.value])
        ),
      })(state);
    case t.AI_TRAINING + t.FILTER + t.CLEAR:
    case t.AI_TRAINING + t.CLEAR:
      return defaultFilters;
    default:
      return state;
  }
};

export default combineReducers({
  versions,
  versionsCount,
  versionsStatus,
  currentVersion,
  versionErrors,
  status,
  contentStatus,
  contentIds,
  contentById,
  contentTotalCount,
  contentCurrentCount,
  labeledContentStatus,
  labeledContentIds,
  labeledContentById,
  labeledContentCount,
  showFilters,
  filters,
});
