import Highcharts from 'highcharts';
import * as R from 'ramda';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { formatAmount } from 'common/helpers';
import {
  IColumn,
  IDataCellProps,
  IRow,
  SortOrder,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { CustomCellConfig } from 'components/UI/common/TypedTable/renderers/CustomCell';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { getCellValue } from 'components/UI/common/TypedTable/renderers/custom/common';
import SeriesLabel from 'components/dashboard/ForecastDashboard/TrackingDashboard/SeriesLabel';
import {
  Series,
  StatItem,
  StatMetricValue,
  Stats,
} from 'components/dashboard/ForecastDashboard/TrackingDashboard/types';

export interface IMyRow extends IRow {
  __color__: string;
  __icon__: BoostUpIcons;
}

export const MAIN_COLUMN_WIDTH = 200;
export const PERIOD_COLUMN_WIDTH = 130;

export const formatRowValue = (
  companyCurrencyCode: string,
  display_name: StatItem['display_name'],
  value?: StatMetricValue
) => {
  if (R.isNil(value)) {
    return '-';
  }

  switch (display_name) {
    case 'Pipeline Gap':
      return `${value.toFixed()}%`;
    case 'Pipeline Coverage':
      return `${value.toFixed(1)}x`;
    default:
      return formatAmount(companyCurrencyCode, value);
  }
};

type DashStyleToIconsMap = Partial<
  Record<Highcharts.DashStyleValue, BoostUpIcons>
>;
const dashStyleToIconsMap: DashStyleToIconsMap = {
  Dash: BoostUpIcons.ChartLabelLineDash,
  Dot: BoostUpIcons.ChartLabelLineDot,
  Solid: BoostUpIcons.ChartLabelLineSolid,
};

export const getLabelIcon = (series: Series) =>
  series.type === 'line'
    ? dashStyleToIconsMap[series.dashStyle] || BoostUpIcons.ChartLabelLineSolid
    : BoostUpIcons.ChartLabelDot;

const RowLabel: React.FC<IDataCellProps> = ({ column, row }) => {
  const text = getCellValue({ column, row });
  const typedRow = row as IMyRow;

  const label = (
    <SeriesLabel color={typedRow.__color__} icon={typedRow.__icon__}>
      {text}
    </SeriesLabel>
  );

  return (
    <TooltipWrapper tooltip={label} showOnTruncatedOnly>
      {label}
    </TooltipWrapper>
  );
};

export const getColumns = (stat?: Stats) => {
  const columns: IColumn[] = [
    {
      id: 'metric',
      label: '',
      field: 'display_name',
      type: ColumnTypes.CUSTOM,
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      width: MAIN_COLUMN_WIDTH,
      config: {
        renderer: RowLabel,
      } as CustomCellConfig,
    },
  ];

  if (stat?.periods.length) {
    columns.push(
      ...stat.periods.map<IColumn>((period) => ({
        id: period,
        label: period,
        field: `metrics.${period}`,
        type: ColumnTypes.MONEY,
        align: 'center' as IColumn['align'],
        sortable: false,
        sort_order: SortOrder.ASCENDING,
        config: {},
      }))
    );
  }

  return columns;
};

export const setTableFluid = (
  [firstColumn, ...rest]: IColumn[],
  fluid: boolean
) => [
  firstColumn,
  ...rest.map((column) => ({
    ...column,
    width: fluid ? undefined : PERIOD_COLUMN_WIDTH,
  })),
];

export const formatNewForecastField = (field: string) =>
  field.toLowerCase().split(' ').join('_').trim();

export const formatNewForecastOptions = (data: StatItem[]) => {
  let newApiData: StatItem[] = data;

  const newForecastOptions = newApiData.map((item) => {
    const forecasts = Object.entries(item.forecast)
      .map(([keyforecastGroupKey, forecastGroupValue]) =>
        Object.entries(forecastGroupValue).map(
          ([forecastOptionKey, forecastOptionValue]) => ({
            [`${formatNewForecastField(
              forecastOptionKey
            )}`]: forecastOptionValue,
          })
        )
      )
      .flat()
      .reduce((acc, cur) => {
        const key = Object.keys(cur)[0];
        acc[key] = cur[key];
        return acc;
      }, {});

    return {
      ...item,
      forecasts,
    };
  });

  return newForecastOptions;
};

export const getRows = (
  companyCurrencyCode: string,
  stat: Stats | undefined,
  checkedSeries: Series[]
): IMyRow[] =>
  checkedSeries.map<IMyRow>((series, index) => {
    const item = stat?.data.find((item2) => item2.name === series.field);

    const metrics = item
      ? stat?.periods.reduce(
          (acc, period, index) => ({
            ...acc,
            [period]: formatRowValue(
              companyCurrencyCode,
              item.display_name,
              item.values[index]
            ),
          }),
          {}
        )
      : {};

    const row: IMyRow = {
      id: `${series.field}-${index}`,
      __color__: series.color,
      __icon__: getLabelIcon(series),
      display_name: item?.display_name,
      name: item?.name,
      metrics: metrics,
    };

    return row;
  }) ?? [];
