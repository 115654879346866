import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import BuDropdown, { BuDropdownItemContainer } from 'components/UI/BuDropdown';
import PanelStages from 'components/UI/FilterPanels/PanelStages';
import { WhiteBackground } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/OppStageFilter/OppStageFilter.style';
import {
  ALL_ACTIVE_OPTION,
  ALL_OPTION,
} from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/constants';
import {
  BIMetricColumn,
  BIMetricsFilter,
  PicklistOptions,
} from 'components/dashboard/Metrics/metrics.types';
import { fetchApi } from 'utils/network';

interface Props {
  templateFilter?: BIMetricsFilter;
  widgetFilterLabel: string;
  widgetFilterName: string;
  widgetFilterType: string;

  updateFilters: (
    emptyFilters: boolean,
    operator: string,
    value: string | string[] | number
  ) => void;
}

export const OppStageFilter: React.FC<Props> = ({
  templateFilter,
  widgetFilterLabel,
  widgetFilterName,
  widgetFilterType,
  updateFilters,
}) => {
  const [stages, setStages] = useState<Filters.Checkbox[]>([]);
  const [selectedStages, setSelectedStages] = useState<Filters.Checkbox[]>([]);

  useEffect(() => {
    const abortController = new AbortController();

    fetchApi<BIMetricColumn, PicklistOptions[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/company_settings/get_column_values`,
      queryMethod: 'post',
      queryParams: {
        label: widgetFilterLabel,
        name: widgetFilterName,
        type: widgetFilterType,
      },
      setData: (result: PicklistOptions[]) => {
        // Filter to remove duplicates which are causing issues
        const cleanRst = result
          .filter((v, i, a) => a.findIndex((v2) => v2.value === v.value) === i)
          .filter((o) => o.value !== '__all_active__') // Filter to remove __all_active__ option
          .map((o) => ({
            label: o.display_name,
            value: o.value,
            checked: templateFilter?.value ? (templateFilter?.value as string[]).includes(o.value) : false,
          }));
        setStages(cleanRst);
        setSelectedStages(cleanRst.filter((stage) => stage.checked));
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load picklist values: ${error}`);
      },
      signal: abortController.signal,
    });

    return () => abortController.abort();
  }, []);

  const handlePicklistValuesChange = (values: string[]): void => {
    if (values.includes(ALL_OPTION.value)) {
      updateFilters(true, 'in', [ALL_OPTION.value]);
      return;
    }
    updateFilters(!values.length, 'in', values);
  };

  const onChange = (data: Filters.PersistValue[], withReset: any) => {
    const newStagesState = stages.map((stage) => {
      const updated = data.find((newState) => newState.id === stage.value);

      if (updated) {
        stage.checked = updated.checked;
      }
      // is not easy have retro compatibility...
      // this is need to clear the all option when the user adds/removes an option
      if (withReset !== undefined && !withReset && stage.value === '__all__') {
        stage.checked = false;
      }
      return stage;
    });

    setStages(newStagesState);
    setSelectedStages(newStagesState.filter((stage) => stage.checked));
  };
  const onClose = useCallback(() => {
    handlePicklistValuesChange(selectedStages.map((stage) => stage.value));
  }, [selectedStages]);

  const label = useMemo(() => {
    if (selectedStages.length && selectedStages[0].label !== 'All') {
      return `${selectedStages[0].label}${
        selectedStages.length > 2 ? ` (+${selectedStages.length - 1})` : ``
      }`;
    } else {
      return `All`;
    }
  }, [selectedStages]);

  return (
    <BuDropdown
      key="select-ma"
      secondary
      inlineLabel={widgetFilterLabel}
      label={label}
      onClose={onClose}
      testingLabel="opportunity-stage"
      className={WhiteBackground}
    >
      {(hide) => (
        <BuDropdownItemContainer>
          <PanelStages
            config={{
              checkedAll: false,
              title: 'Deal Stages',
              type: 'checkbox',
              searchable: true,
              withShowAll: true,
              checkedTotal: 0,
              values: stages,
            }}
            onChange={onChange}
            onClose={onClose}
          ></PanelStages>
        </BuDropdownItemContainer>
      )}
    </BuDropdown>
  );
};
