import { MetricCreateUnitsField } from './MetricCreateUnitsField';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as metricActions from 'actions/revbi/metrics';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuFormulaTextField from 'components/UI/BuFormulaTextField';
import BuIcon from 'components/UI/BuIcon';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { inputsContainer } from 'components/dashboard/Metrics/Create/MetricDefinition/MetricDefinitionInputs/styles';
import * as s from 'components/dashboard/Metrics/Create/MetricDefinition/MetricDefinitionInputs/styles';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import {
  ExclamationIcon,
  MetricCreateSubTitle,
} from 'components/dashboard/Metrics/metrics.styles';
import {
  BIMetricUnion,
  BIMetricFormula,
  UnitValue,
} from 'components/dashboard/Metrics/metrics.types';
import * as metricSelectors from 'selectors/revbi/metrics';

interface Props {
  metric: BIMetricFormula;
}

export const FormulaMetricInputs: React.FC<Props> = ({ metric }) => {
  const dispatch = useDispatch();

  const simpleMetricsSimplified = useSelector(
    metricSelectors.getAllSimpleMetricsSimplified
  );
  const simpleMetricsTSSimplified = useSelector(
    metricSelectors.getAllSimpleHistoricalMetricsSimplified
  );
  const metricsWithTSStatus = useSelector(
    metricSelectors.getAllMetricsWithTSStatus
  );

  const updateMetric = (metric: BIMetricUnion): void => {
    dispatch(metricActions.changeActiveMetric(metric));
  };

  const handleApplyFormula = (formula: string): void => {
    updateMetric({ ...metric, synthetic_metric: formula });
  };

  const handleChangeUnits = (unitValue: UnitValue): void => {
    const newMetric: BIMetricFormula = {
      ...(metric as BIMetricFormula),
      properties: {
        ...metric.properties,
        metricUnit: unitValue,
      },
    };
    updateMetric(newMetric);
  };

  return (
    <>
      <div className={inputsContainer}>
        <MetricCreateSubTitle>
          <span>Formula</span>
          <TooltipWrapper
            tooltip={
              <div className={s.tooltipSize}>
                <p>
                  Formula metrics are composed of other metrics using a formula
                  with math operators and regular numbers. Using aggregation
                  functions such as sum, average etc straight in the formula
                  field is not supported.
                </p>
                <p>
                  Below is an example of a valid formula:
                  <br />
                  (Total amount of opps won / Total amount of opps) * 100
                </p>
              </div>
            }
            position="right center"
          >
            <ExclamationIcon>
              <BuIcon
                name={BoostUpIcons.BadgeInfoOutline}
                color="var(--bu-gray-700)"
              />
            </ExclamationIcon>
          </TooltipWrapper>
        </MetricCreateSubTitle>
        <BuFormulaTextField
          metrics={
            metric.analysis_type === AnalysisType.HISTORICAL
              ? simpleMetricsTSSimplified
              : simpleMetricsSimplified
          }
          metricsStatus={metricsWithTSStatus}
          formulaToBeEdited={metric.synthetic_metric}
          onFormulaFinishEditing={handleApplyFormula}
        />
      </div>
      <div className={inputsContainer}>
        <MetricCreateSubTitle>Units</MetricCreateSubTitle>
        <MetricCreateUnitsField
          initialValue={metric.properties?.metricUnit}
          onChange={handleChangeUnits}
        />
      </div>
    </>
  );
};
