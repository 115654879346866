import { BIMetricColumn } from 'components/dashboard/Metrics/metrics.types';

const GROUP_BY_COLUMN_TYPES = new Set(['picklist', 'text']);

export const groupColumns = (
  columns: BIMetricColumn[] = [],
  dateTypeOptions: BIMetricColumn[]
) => {
  return columns.reduce(
    (pre, column) => {
      const index = dateTypeOptions.findIndex(
        (option) => option.name === column.name
      );

      if (index != -1) {
        pre.push({
          name: `${column.name}_MoM`,
          label: `${column.label}-MoM`,
          type: 'timePeriod',
          applies_to_all_metrics: false,
        });
        pre.push({
          name: `${column.name}_WoW`,
          label: `${column.label}-WoW`,
          type: 'timePeriod',
          applies_to_all_metrics: false,
        });
        pre.push({
          name: `${column.name}_QoQ`,
          label: `${column.label}-QoQ`,
          type: 'timePeriod',
          applies_to_all_metrics: false,
        });
      }

      if (
        GROUP_BY_COLUMN_TYPES.has(column.type) &&
        pre.findIndex((el) => el.name === column.name) === -1
      ) {
        pre.push({
          name: column.name,
          label: column.label,
          type: column.type,
          applies_to_all_metrics: !!column.applies_to_all_metrics
            ? column.applies_to_all_metrics
            : false,
        });
      }

      return pre;
    },
    [
      {
        name: 'none',
        label: 'None',
        type: 'text',
        applies_to_all_metrics: true,
      },
    ]
  );
};
