import axios from 'axios';
import { delay, put, takeEvery, takeLatest, call } from 'redux-saga/effects';

import { actions } from 'actions';
import * as t from 'actions/actionTypes';
import {
  fetchForecastDataFailure,
  fetchForecastDataLoading,
  fetchForecastDataSuccess,
  updateTotalsSuccess,
  forecastSubmissionsCreateErrorAction,
  forecastSubmissionsCreateSuccessAction,
} from 'actions/forecastActions';

function* clearGenerator(action) {
  yield delay(7 * 1000);

  yield put(
    actions.ui.manageForecast.clear({
      settingsId: action.payload.submissionSettingId,
    })
  );
}

export function* fetchForecastDataSaga({ filters }) {
  try {
    yield put(fetchForecastDataLoading());
    const response = yield call(
      axios.post,
      `${process.env.REACT_APP_BACKEND_URL}/api/data/forecast/v2/summary`,
      filters
    );

    yield put(fetchForecastDataSuccess(response.data.data));
    yield put(updateTotalsSuccess());
  } catch (error) {
    yield put(fetchForecastDataFailure(error));
  }
}

export function* manageForecastSaga() {
  yield takeLatest([t.FORECAST + t.GET], fetchForecastDataSaga);

  yield takeEvery(
    [
      forecastSubmissionsCreateErrorAction.type,
      forecastSubmissionsCreateSuccessAction.type,
    ],
    clearGenerator
  );
}
