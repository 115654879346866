import CustomPeriodTab from './TabsForFilter/CustomPeriodTab';
import CustomRangeTab from './TabsForFilter/CustomRangeTab';
import FiscalYearTab from './TabsForFilter/FiscalYearTab';
import PredefinedTab from './TabsForFilter/PredefinedTab';
import { RelativeTab } from './TabsForFilter/RelativeTab';
import { BetweenDate, FiltersConfig } from './TabsForFilter/types';
import classNames from 'classnames';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuPopup from 'components/UI/BuPopup';
import * as s from 'components/dashboard/Metrics/common/Calendar/TimeFilter/styles';

const TABS_FOR_LIVE = ['Predefined', 'Fiscal Year', 'Custom', 'Range'] as const;
const TABS_FOR_HISTORICAL = ['Relative', 'Predefined', 'Range'] as const;

const getInfoMessage = (tab: string) => {
  if ('Relative' === tab)
    return 'The options presented are not representing a specific date in time, but a period of time that is relative to each date snapshot.';
  if ('Predefined' === tab)
    return "The options presented are defined by today's date and are the same for each date snapshot.";
  if ('Range' === tab)
    return 'The same range selected will be applied to each date snapshot';
};

type TimeFilterContentProps = {
  config: FiltersConfig;
  betweenDate: BetweenDate;
  localTab: string;
  isHistorical: boolean;
  value: string[] | string;
  onChange: (value: string) => void;
  onChangeLocalTab: React.Dispatch<React.SetStateAction<string>>;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  setBetweenDate: React.Dispatch<React.SetStateAction<BetweenDate>>;
};

export const TimeFilter: React.FC<TimeFilterContentProps> = ({
  config,
  betweenDate,
  localTab,
  isHistorical = false,
  value,
  onChange,
  onChangeLocalTab,
  onClose,
  setBetweenDate,
}) => (
  <>
    {(config?.customStart || config?.customEnd) && (
      <div className={s.time_filter_dropdown_tabs}>
        {isHistorical
          ? TABS_FOR_HISTORICAL.map((tab) => (
              <div
                key={tab}
                onClick={() => onChangeLocalTab(tab)}
                className={classNames(s.time_filter_dropdown_tab, {
                  selected: tab === localTab,
                  borderRight:
                    localTab === 'Predefined' ||
                    localTab === 'Relative' ||
                    localTab === 'Custom',
                  borderLeft:
                    localTab === 'Range' ||
                    localTab === 'Relative' ||
                    localTab === 'Custom',
                })}
              >
                {
                  <>
                    <span style={{ paddingRight: '8px' }}>{tab}</span>
                    <BuPopup
                      trigger={(ref) => (
                        <div ref={ref}>
                          {
                            <BuIcon
                              name={BoostUpIcons.BadgeInfoOutline}
                              color={'var(--bu-primary-500)'}
                            />
                          }
                        </div>
                      )}
                    >
                      <div className={s.info_message_container}>
                        {getInfoMessage(tab)}
                      </div>
                    </BuPopup>
                  </>
                }
              </div>
            ))
          : TABS_FOR_LIVE.map((tab) => (
              <div
                key={tab}
                onClick={() => onChangeLocalTab(tab)}
                className={classNames(s.time_filter_dropdown_tab, {
                  selected: tab === localTab,
                  borderRight:
                    localTab === 'Predefined' ||
                    localTab === 'Relative' ||
                    localTab === 'Custom',
                  borderLeft:
                    localTab === 'Range' ||
                    localTab === 'Relative' ||
                    localTab === 'Custom',
                })}
              >
                {tab}
              </div>
            ))}
      </div>
    )}

    {localTab === 'Relative' && (
      <RelativeTab
        config={config}
        value={value}
        onChange={onChange}
        onClose={onClose}
      />
    )}

    {localTab === 'Predefined' && (
      <PredefinedTab
        config={config}
        value={value}
        onChange={onChange}
        onClose={onClose}
      />
    )}

    {localTab === 'Fiscal Year' && (
      <FiscalYearTab
        config={config}
        value={value}
        onChange={onChange}
        onClose={onClose}
      />
    )}

    {localTab === 'Custom' && (
      <CustomPeriodTab onChange={onChange} value={value as string} />
    )}

    {localTab === 'Range' && (
      <CustomRangeTab
        config={config}
        onChange={onChange}
        betweenDate={betweenDate}
        setBetweenDate={setBetweenDate}
      />
    )}
  </>
);
