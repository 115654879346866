import BuIcon from '../BuIcon';
import classNames from 'classnames';
import { css } from 'emotion';
import React from 'react';

import * as styles from 'components/UI/BuGroupButton/styles';
import { IProps } from 'components/UI/BuGroupButton/types';
import Tooltip from 'components/UI/common/Tooltip';

const COMPONENT_TESTING_NAME = 'GroupButton';

const customWrapper = css`
  &.ui.inverted.popup {
    padding: 5px 8px;
    font-size: 12px;
  }
`;

const BuGroupButton: React.FC<IProps> = ({
  options,
  selectedOption,
  className,
  onSelect,
  disabled,
  testingLabel = '',
}) => {
  const componentDataTestingKey = testingLabel
    ? `${testingLabel}-${COMPONENT_TESTING_NAME}`
    : COMPONENT_TESTING_NAME;
  return (
    <div
      data-testing={componentDataTestingKey}
      className={classNames(styles.optionsContainer, className)}
    >
      {options.map((option, i) => (
        <div
          role="presentation"
          data-testing={`${componentDataTestingKey}-Option-${
            option.text?.replaceAll(' ', '_') ?? i
          }`}
          onClick={() => !disabled && onSelect(option.id)}
          key={option.id}
          className={classNames('option-group', styles.itemContainer, {
            [styles.selectedOption]: selectedOption === option.id,
            disabled: option.disabled || disabled,
            'not-icon-button': !option.iconName,
          })}
        >
          <Tooltip
            className={customWrapper}
            tooltip={option.text}
            position="top center"
            hoverable
          >
            <div className="option-group-item">
              {option.iconName ? (
                <BuIcon name={option.iconName} />
              ) : (
                option.text
              )}
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default BuGroupButton;
