import * as t from 'actions/actionTypes';
import { postAction, deleteAction } from 'actions/baseActions';
import * as genericSagas from 'sagas/generic';

const transcriptActionType = t.transcriptTypes;

export const speakerType = {
  CUSTOMER: 'customer',
  COMPANY: 'company',
  SILENCE: 'silence',
};

export const getTranscriptionById = (email, token, id) => ({
  type: t.TRANSCRIPT + t.GET,
  url: `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call_insights/${id}/`,
  saga: genericSagas.get,
  success: (payload) => ({
    type: t.TRANSCRIPT + t.GET + t.SUCCESS,
    payload,
  }),
  error: (e) => ({
    type: t.TRANSCRIPT + t.GET + t.ERROR,
    error: e,
  }),
  loading: () => ({
    type: t.TRANSCRIPT + t.GET + t.LOADING,
  }),
});

export const createTranscriptAction = {
  updateState: (key, value) => ({
    type: transcriptActionType.UPDATE_STATE,
    key,
    value,
  }),
  resetActiveTopic: () => ({
    type: transcriptActionType.RESET_ACTIVE_TOPIC,
  }),
  playTopic: (times, name) => ({
    type: transcriptActionType.PLAY_TOPIC,
    times,
    name,
  }),
  doSearch: () => ({
    type: transcriptActionType.DO_SEARCH,
  }),
  resetSearch: () => ({
    type: transcriptActionType.RESET_SEARCH,
  }),
  resetState: () => ({
    type: transcriptActionType.RESET_STATE,
  }),
  resetSelection: () => ({
    type: transcriptActionType.RESET_SELECTION,
  }),
  removeTag: (text) => ({
    type: transcriptActionType.REMOVE_TAG,
    text,
  }),
  toggleAddingTag: () => ({
    type: transcriptActionType.TOGGLE_ADDING_TAG,
  }),
  cancelAddingTag: () => ({
    type: transcriptActionType.CANCEL_ADDING_TAG,
  }),
  addTag: (tag) => ({
    type: transcriptActionType.ADD_TAG,
    tag,
  }),
  addTagError: (apiError) => ({
    type: transcriptActionType.ADD_TAG_ERROR,
    addTagErrorResponse: apiError, // I don't use apiError to avoid conflicting with the existing error managemnet
  }),
  resetTagError: () => ({
    type: transcriptActionType.RESET_TAG_ERROR,
  }),
  processCallDetails: (response) =>
    createTranscriptAction.updateState('callDetail', response.data),
  processCallSummary: (response) =>
    createTranscriptAction.updateState('callSummary', response.data),
  processApiSuccess: (response) =>
    createTranscriptAction.updateState('apiSuccess', response.data),
  processApiError: (response) =>
    createTranscriptAction.updateState('apiError', response),
  playAudio: () => createTranscriptAction.updateState('isPlaying', true),
  pauseAudio: () => createTranscriptAction.updateState('isPlaying', false),
  setSearchText: (searchText) =>
    createTranscriptAction.updateState('searchText', searchText),
  setSelectedGroup: (selectedGroup) =>
    createTranscriptAction.updateState('selectedGroup', selectedGroup),
  setSelectedGroupType: (selectedGroupType) =>
    createTranscriptAction.updateState('selectedGroupType', selectedGroupType),
  setSelectedSnippet: (snippet, index) =>
    createTranscriptAction.updateState('selectedSnippet', {
      snippet,
      index,
    }),
  setFilteredSnippets: (filteredSnippets) =>
    createTranscriptAction.updateState('filteredSnippets', filteredSnippets),
  setSearchIndex: (value) =>
    createTranscriptAction.updateState('searchIndex', value),
  processRemoveTagSuccess: (text) => createTranscriptAction.removeTag(text),
  processAddTagSuccess: (response) =>
    createTranscriptAction.addTag(response.data),
  processAddTagFailure: (response) =>
    createTranscriptAction.addTagError(response),
};

export const doRemoveTag = (email, api_access_token, callId, text) =>
  deleteAction(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call/${callId}/tags/${text}`,
    {
      email,
      api_access_token,
    },
    () => createTranscriptAction.processRemoveTagSuccess(text),
    createTranscriptAction.processApiError
  );

export const doAddTag = (email, api_access_token, callId, text) =>
  postAction(
    `${process.env.REACT_APP_BACKEND_URL}/api/data/calls/call/${callId}/tags`,
    {
      email,
      api_access_token,
      text,
    },
    createTranscriptAction.processAddTagSuccess,
    createTranscriptAction.processAddTagFailure
  );
