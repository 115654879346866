import styled from '@emotion/styled';
import moment from 'moment';
import { isNil } from 'ramda';
import React, { useMemo } from 'react';

import { CROUserSubmission } from 'actions/croOverrideActions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import {
  CustomComponentFunction,
  getCellValue,
} from 'components/UI/common/TypedTable/renderers/custom/common';
import { RowSubmission } from 'components/dashboard/CROOverride/types';
import Tooltip from 'components/dashboard/Forecast/Dashboard/SubmitForecast/Tooltip';

export const DeltaIconWrapper = styled.div`
  align-self: center;
  text-align: center;
  line-height: 1;
  font-size: 0.8em;
  height: 12px;
  width: 12px;
  overflow: hidden;

  &.up {
    background-color: var(--bu-green-200);
    color: var(--bu-green-500);
  }

  &.down {
    background-color: var(--bu-red-200);
    color: var(--bu-red-500);
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 5px;

  .bu-icon.bu-icon-multi_currency {
    color: var(--bu-orange-500);
  }
`;

export const Sub = styled.div`
  font-size: 0.8em;
  color: var(--bu-gray-700);
`;

const formatDate = (date: string) => moment(date).format("MMM DD 'YY, h:mm a");

type ForecastCROCellConfig = {
  companyCurrency: string;
  businessType: string;
  forecastName: string;
  period: string;
  currencyFormatter(value: number): string;
};
const forecastCROCell: CustomComponentFunction<ForecastCROCellConfig> = ({
  companyCurrency,
  currencyFormatter,
  period,
  businessType,
  forecastName,
}) => (props) => {
  const { row, column } = props;

  const { user_id, currency } = row as RowSubmission;
  const submission = getCellValue(props) as CROUserSubmission;
  const {
    delta,
    last_submission_amount: amount,
    last_submission_deals_included: dealsIncluded,
    last_submission_deals_total: dealsTotal,
    last_submission_deals_amount: dealsAmount,
    last_submission_timestamp: lastSubmitted,
  } = submission ?? {};

  const isCurrencyDiffThenUser = Boolean(
    currency && currency !== companyCurrency
  );

  if (!lastSubmitted) {
    return <Cell>-</Cell>;
  }

  const tooltip = useMemo(
    () => (
      <Tooltip
        title={column.label}
        userId={user_id}
        forecastName={forecastName}
        businessTypeName={businessType}
        companyCurrency={companyCurrency}
        closeDateInterval={period}
        isCurrencyDiffThenUser={isCurrencyDiffThenUser}
      />
    ),
    [
      column.label,
      user_id,
      forecastName,
      businessType,
      companyCurrency,
      period,
      isCurrencyDiffThenUser,
    ]
  );

  return useMemo(
    () => (
      <TooltipWrapper tooltip={tooltip} hoverable>
        <Cell>
          <Row>Last: {formatDate(lastSubmitted)}</Row>
          <Row>
            {isCurrencyDiffThenUser && (
              <BuIcon name={BoostUpIcons.MultiCurrency} />
            )}
            {delta && (
              <DeltaIconWrapper className={delta}>
                <BuIcon
                  name={
                    delta === 'up'
                      ? BoostUpIcons.ChangeUp
                      : BoostUpIcons.ChangeDown
                  }
                />
              </DeltaIconWrapper>
            )}
            {currencyFormatter(amount ?? 0)}
          </Row>

          {!isNil(dealsAmount) && (
            <Sub>
              {dealsIncluded} of {dealsTotal} ({currencyFormatter(dealsAmount)})
            </Sub>
          )}
        </Cell>
      </TooltipWrapper>
    ),
    [
      lastSubmitted,
      delta,
      amount,
      dealsAmount,
      tooltip,
      dealsIncluded,
      dealsTotal,
    ]
  );
};

export default forecastCROCell;
