import { getLocation } from 'connected-react-router';

import { PersistedTableItem } from 'components/UI/DealsFlatTableTS/Table/types';
import { IReduxState } from 'reducers/types';
import { getModalProps } from 'selectors';

const immutableEmptyPersistObject = {};

export const getTablePrevState = (
  state: IReduxState,
  type: PersistedTableItem['type'],
  name?: PersistedTableItem['name']
): PersistedTableItem | {} =>
  state.table.find((el) => el.type === type && el.name === name) ||
  immutableEmptyPersistObject;

export const getAppState = (state: IReduxState) => state.appState;

export const getPersistName = (state: IReduxState, isModal?: boolean) => {
  const { pathname } = getLocation(state as any);

  if (isModal) {
    const modalScheme = pathname.split('~').slice(-1)[0];
    const { apiUrl } = getModalProps(state, modalScheme);
    const subPath = apiUrl ? `_${apiUrl}` : '';

    return `${pathname}${subPath}`;
  }

  return pathname;
};

export const getSizeColumns = (
  state: IReduxState,
  isModal?: boolean
): { width: string; id: string }[] => {
  const name = getPersistName(state, isModal);

  const appState = state.appState[name];
  return appState && appState.sizeColumns ? appState.sizeColumns : [];
};

export interface ColumnToggleState {
  id: string;
  label: string;
  show: boolean;
}

export const getShowOrHideColumns = (
  state: IReduxState,
  isModal?: boolean
): ColumnToggleState[] => {
  const name = getPersistName(state, isModal);
  const appState = state.appState[name];
  return appState && appState.showOrHideColumns
    ? appState.showOrHideColumns
    : [];
};

export const getSequenceColumns = (state: IReduxState, isModal?: boolean) => {
  const name = getPersistName(state, isModal);
  const appState = state.appState[name];

  return appState && appState.sequenceColumns ? appState.sequenceColumns : [];
};
