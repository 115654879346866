import { css } from 'emotion';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'actions';
import { getBusinessTypesConfig } from 'actions/settingsActions';
import { forecastTabs, BusinessTypeTab } from 'common/constants';
import BuGroupButton from 'components/UI/BuGroupButton';
import { AnalyticsTracker } from 'components/common/analyticsUtils';
import { IReduxState } from 'reducers/types';
import {
  getBusinessTypes,
  getSelectedBusinessTypeForPanel,
  isBusinessTypesConfigReady,
} from 'selectors';

const buttonGroupContainer = css`
  margin: 10px 0 10px;
`;

type Props = {
  tab: BusinessTypeTab;
  onSelect?: (value: string) => void;
  isOverallEnabled?: boolean;
};

const OVERALL_BUSINESS_TYPE = 'Overall';

const BusinessTypesPanel: React.FC<Props> = ({
  tab,
  onSelect,
  isOverallEnabled,
}) => {
  const [isSended, setIsSended] = useState(false);

  const dispatch = useDispatch();

  const { businessTypes, selectedBusinessType } = useSelector(
    (state: IReduxState) => ({
      businessTypes: getBusinessTypes(state, isOverallEnabled),
      selectedBusinessType: getSelectedBusinessTypeForPanel(state, tab),
    })
  );

  const hasBusinessTypesConfigLoaded = useSelector(isBusinessTypesConfigReady);

  const defaultBusinessType = businessTypes[0];

  const setBusinessType = (value: string) => {
    dispatch(
      forecastTabs.includes(tab)
        ? actions.ui.manageForecast.setActiveBusinessType(value)
        : actions.ui.filters.setBusinessType({
            businessType: value === OVERALL_BUSINESS_TYPE ? '' : value,
            tab,
          })
    );
  };

  // This hook is required for compatibility with some older selectors,
  // such as `isForecastSubmissionAvailable`.
  // In the future, we will need deeper refactoring.
  useEffect(() => {
    if (!selectedBusinessType && defaultBusinessType && !isSended) {
      setBusinessType(defaultBusinessType);
      setIsSended(true);
    }
  }, [selectedBusinessType, defaultBusinessType, isSended]);

  useEffect(() => {
    if (!hasBusinessTypesConfigLoaded) {
      dispatch(getBusinessTypesConfig());
    }
  }, [hasBusinessTypesConfigLoaded]);

  const onChange = (value: string) => {
    setBusinessType(value);

    AnalyticsTracker.event(
      { tab },
      {
        category: 'Business Types Panel',
        action: 'Change Business Type',
        label: value,
      }
    );

    if (onSelect) {
      onSelect(value);
    }
  };

  if (!businessTypes.length) {
    return null;
  }

  const isActiveBussinesTypeAvailable =
    selectedBusinessType && businessTypes.includes(selectedBusinessType);

  const selectedOption = isActiveBussinesTypeAvailable
    ? (selectedBusinessType as string)
    : defaultBusinessType;

  return (
    <BuGroupButton
      className={buttonGroupContainer}
      options={businessTypes.map((text: any) => ({ id: text, text }))}
      selectedOption={selectedOption}
      onSelect={onChange}
      testingLabel="BusinessTypes"
    />
  );
};

export default BusinessTypesPanel;
