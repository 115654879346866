import { StatMetrics } from '../TrackingDashboard/types';
import { getSeriesColor } from './constants';
import * as Highcharts from 'highcharts';

import { ForecastPaceQuarterlyData } from 'actions/analyticsActions';
import { formatAmount } from 'common/helpers';
import { multiplierFormatter } from 'components/UI/TableConfig/column-helper';
import * as styles from 'components/dashboard/ForecastDashboard/Widget3/styles';

const xAXIS_EDGE_OFFSET = 0.5;

const addNoDataLabel = (chart: Highcharts.Chart, xAxisIndex: number) => {
  const xLeftBound = chart.xAxis[0].toPixels(
    xAxisIndex - xAXIS_EDGE_OFFSET,
    false
  );
  const xRightBound = chart.xAxis[0].toPixels(
    xAxisIndex + xAXIS_EDGE_OFFSET,
    false
  );

  chart.renderer
    .label('No data available for this quarter', 0)
    .attr('text-anchor', 'middle')
    .css({
      color: '#9b9b9b',
      fontSize: '14px',
      fontFamily: 'var(--bu-font-medium)',
    })
    .align({ align: 'center', verticalAlign: 'middle' }, false, {
      x: xLeftBound,
      y: 0,
      width: xRightBound - xLeftBound,
      height: chart.chartHeight,
    })
    .add();
};

const pointToBreakLine = (
  x: number,
  y: number | null,
  quarter: ForecastPaceQuarterlyData
): (Highcharts.PointOptionsObject | null)[] => [
  { x: x - xAXIS_EDGE_OFFSET, y, custom: quarter },
  { x: x - xAXIS_EDGE_OFFSET / 2, y, custom: quarter },
  { x: x, y },
  { x: x + xAXIS_EDGE_OFFSET / 2, y, custom: quarter },
  { x: x + xAXIS_EDGE_OFFSET, y, custom: quarter },
  null,
];

const BAR_GROUP = {
  OPP: 'left',
  WIN: 'middle',
  LOST: 'right',
  COVERAGE: 'far_right',
};

const isSeriesColumnOptions = (
  series: Highcharts.SeriesOptions
): series is Highcharts.SeriesColumnOptions => series.type === 'column';

export const STACK_LABELS_STYLES = {
  y: -10,
  backgroundColor: 'var(--bu-color-constant-pipeline-coverage)',
  borderRadius: 11,
  borderWidth: 0,
  padding: 3,
  style: {
    color: 'var(--bu-white)',
    fontSize: '12px',
    fontWeight: 'normal',
    textOutline: 'none',
  },
};

type iSeriesOptions =
  | Highcharts.SeriesColumnOptions
  | Highcharts.SeriesLineOptions;

const getSeries = (quarters: ForecastPaceQuarterlyData[]): iSeriesOptions[] =>
  quarters.map((q: ForecastPaceQuarterlyData, i) => {
    const customOptions = q.forecast_category
      ? ({
          type: 'column',
          xAxis: 0,
          yAxis: 0,
          pointWidth: 20,
          stack: BAR_GROUP.OPP,
        } as Highcharts.SeriesColumnOptions)
      : ({
          type: 'line',
          step: 'center',
          marker: false,
          dashStyle: 'ShortDot',
        } as Highcharts.SeriesLineOptions);

    switch (true) {
      case q.name === 'target':
        customOptions.xAxis = 1;
        customOptions.data = q.values
          .map((v, x) => pointToBreakLine(x, v, q))
          .flat();
        break;
      case q.name === 'pipeline_coverage':
        customOptions.stack = BAR_GROUP.COVERAGE;
        customOptions.xAxis = 0;
        customOptions.yAxis = 1;
        break;
      case q.name === 'pipeline_coverage_target':
        customOptions.xAxis = 1;
        customOptions.yAxis = 1;
        customOptions.data = q.values
          .map((v, x) => pointToBreakLine(x, v, q))
          .flat();
        break;
      case q.name === ('lost' as StatMetrics):
        customOptions.type = 'column';
        customOptions.stack = BAR_GROUP.LOST;
        break;
      case q.name === 'booked':
        customOptions.type = 'column';
        customOptions.stack = BAR_GROUP.WIN;
        break;
    }

    return {
      ...customOptions,
      data: customOptions.data || q.values,
      color: getSeriesColor(q.name, i),
      visible: true,
      name: q.display_name,
      pointWidth: 20,
    };
  });

export const getPlotOptions = (
  quarters: ForecastPaceQuarterlyData[],
  currencyCode: string,
  periods: string[]
): Highcharts.Options => ({
  chart: {
    type: 'column',
    height: 380,
    marginLeft: 220,
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
    className: styles.chartLinesStyles,
    events: {
      render() {
        quarters.forEach((quarter, i) => {
          if (quarter.name === null) {
            addNoDataLabel(this, i);
          }
        });
      },
    },
  },
  title: {
    text: '',
  },
  xAxis: [
    {
      type: 'category',
      categories: periods,
      minPadding: 0,
      maxPadding: 0,
      labels: {
        enabled: true,
      },
      visible: true,
      gridLineWidth: 1,
    },
    {
      type: 'linear',
      categories: periods,
      minPadding: 0,
      maxPadding: 0,
      min: 0,
      max: quarters.length - 1,
      visible: false,
    },
  ],
  yAxis: [
    {
      allowDecimals: false,
      min: 0,
      title: {
        text: '',
      },
      labels: {
        enabled: true,
        formatter() {
          return this.isFirst || this.isLast
            ? ''
            : formatAmount(currencyCode, this.value as number);
        },
      },
      stackLabels: {
        enabled: true,
        formatter() {
          return formatAmount(currencyCode, this.total);
        },
        ...STACK_LABELS_STYLES,
      } as Highcharts.YAxisStackLabelsOptions,
    },
    {
      opposite: true,
      min: 0,
      title: { text: '' },
      labels: {
        enabled: true,
        formatter() {
          return this.isFirst || this.isLast
            ? ''
            : String(multiplierFormatter(this.value));
        },
      },
      stackLabels: {
        enabled: true,
        formatter() {
          return multiplierFormatter(this.total);
        },
        ...STACK_LABELS_STYLES,
      } as Highcharts.YAxisStackLabelsOptions,
    },
  ],
  legend: {
    enabled: false,
  },
  tooltip: {
    enabled: true,
    formatter: function () {
      const pointUserOptions = this.point.series.userOptions;
      const xSeries = this.point.options.custom?.period || this.x;
      const formatter = this.series.name.includes('Coverage')
        ? (value?: number) => (value ? multiplierFormatter(value) : value)
        : (value?: number) => formatAmount(currencyCode, value || 0);

      const tooltipLines = [
        `<b>${xSeries}</b>`,
        `${this.series.name}: ${formatter(this.y as number)}`,
      ];

      if (this.point.options.custom?.hideTooltip === true) {
        return false;
      }

      if (
        isSeriesColumnOptions(pointUserOptions) &&
        pointUserOptions.stack === BAR_GROUP.OPP
      ) {
        tooltipLines.push(`Total: ${formatter(this.point.total)}`);
      }

      return tooltipLines.join('<br />');
    },
  },
  plotOptions: {
    series: {
      states: {
        inactive: {
          opacity: 0.75,
        },
      },
    },
    column: {
      stacking: 'normal',
    },
    line: {
      lineWidth: 3,
    },
  },
  series: getSeries(quarters),
});
