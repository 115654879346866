import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import * as s from './styles';

class ReactDialog extends Component {
  state = {
    isOpen: this.props.showModal
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ isOpen: nextProps.showModal });
  }

  static propTypes = {
    showModal: PropTypes.bool,
    footer: PropTypes.func,
    handleModalClose: PropTypes.func,
    dialogClass: PropTypes.string,
    headerTitle: PropTypes.string
  };

  static defaultProps = {
    dialogClass: ''
  };

  handleCloseModal = () => {
    this.setState({ isOpen: false });
    this.props.handleCloseModal();
  };

  render() {
    const { isOpen } = this.state;
    return (
      <ReactModal
        style={{
          overlay: {
            display: 'block',
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: 100
          },
          content: { display: 'block' }
        }}
        className="modal fade in"
        overlayClassName="modal-open"
        isOpen={isOpen}
        contentLabel="Minimal Modal Example"
        onRequestClose={this.handleCloseModal}
        ariaHideApp={false}
      >
        <div
          className="modal fade in"
          id="myModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          style={{ display: 'block' }}
        >
          <div className={s.callTranscript_modal}>
            {React.cloneElement(this.props.children, {
              handleCloseModal: this.handleCloseModal
            })}
          </div>
        </div>
        <div className="modal-backdrop fade in" />
      </ReactModal>
    );
  }
}

export default ReactDialog;
