import { css } from 'emotion';

export const editableDiv = css`
  display: block;
  width: 100%;
  height: 80px;
  border: 1px solid var(--bu-gray-500);
  border-radius: 4px;
  padding: 10px;
  outline: 0;
  background-color: var(--bu-text-white);
  overflow: auto scroll;
  :focus {
    border: 1px solid var(--bu-primary-500);
  }
`;

export const metricElement = css`
  padding: 1.5px 8px;
  border-radius: 12px;
  background-color: var(--bu-indigo-300);
  color: var(--bu-indigo-700);
  z-index: -10;
  outline: 0;
  margin-bottom: 5px;
  font-family: var(--bu-font-medium);
`;

export const metricColorIndigo = css`
  color: var(--bu-indigo-700);
  background-color: var(--bu-indigo-300);
`;
export const metricColorYellow = css`
  color: var(--bu-yellow-700);
  background-color: var(--bu-yellow-300);
`;
export const metricColorLime = css`
  color: var(--bu-lime-700);
  background-color: var(--bu-lime-300);
`;
export const metricColorPurple = css`
  color: var(--bu-purple-700);
  background-color: var(--bu-purple-300);
`;
export const metricColorBlue = css`
  color: var(--bu-primary-700);
  background-color: var(--bu-primary-300);
`;
export const metricColorOrange = css`
  color: var(--bu-orange-700);
  background-color: var(--bu-orange-300);
`;

export const editableDivError = css`
  border-color: var(--bu-red-500) !important;
  background-color: var(--bu-red-200) !important; ;
`;

export const errorMessage = css`
  color: var(--bu-red-500);
  font-size: 12px;
`;

// fixing caret invisibility bug
export const specialSybmol = css`
  z-index: -10;
  padding: 0 3px;
  margin-bottom: 5px;
`;

export const highlightedTerm = css`
  display: inline-block;
  padding: 0 4px;
`;
