import classnames from 'classnames';
import { sortBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Grid } from 'semantic-ui-react';

import * as styles from 'components/UI/ProspectEngagement/EngagementChart/styles';
import { StageHistory } from 'components/UI/ProspectEngagement/types';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';

interface IProps {
  min: number;
  max: number;
  stages: StageHistory[];
}

interface StagesLine {
  stage: string;
  numberDay: number;
  width: number;
  delta: number;
  sequence: number;
}

const StageHistoryChart: React.FC<IProps> = ({ min, max, stages }) => {
  const startDate = moment(new Date(min));
  const endDate = moment(new Date(max));
  const duration = moment.duration(endDate.diff(startDate));
  const totalDays = duration.asDays();
  const sortedStageHistory = sortBy(stages, ['created_at']).reverse();

  const oneDayWidth: number = 100 / totalDays;

  const stagesLine: StagesLine[] = [];

  let indexSave: number;

  for (let i = 0; i < totalDays; i++) {
    stagesLine.push({
      delta: 0,
      numberDay: i,
      width: oneDayWidth,
      stage: 'No Stage',
      sequence: 0,
    });
  }

  const arrStageStart = stagesLine.map((el) => {
    const findStage = sortedStageHistory.find((i) => {
      const startStage = moment(i.created_at);
      const durationStage = moment.duration(startStage.diff(startDate));
      const durationStageDays = Math.floor(durationStage.asDays());
      return el.numberDay >= durationStageDays;
    });

    if (!findStage) {
      return el;
    }

    return {
      ...el,
      stage: findStage.stage,
      sequence: findStage.sequence,
      delta: findStage.delta,
    };
  });

  const formatedStageLine: StagesLine[] = arrStageStart
    .map((el, idx) => {
      if (el.stage !== 'No Stage') {
        indexSave = idx;
      }

      if (indexSave) {
        return {
          ...arrStageStart[indexSave],
          numberDay: el.numberDay,
        };
      }

      return el;
    })
    .reduce<StagesLine[]>((acc, current) => {
      const key = current.stage;

      const lastElementIndex = acc.length - 1;

      if (lastElementIndex !== -1 && key === acc[lastElementIndex].stage) {
        acc[lastElementIndex].width += current.width;
      } else {
        acc.push(current);
      }

      return acc;
    }, []);

  return (
    <Grid.Row className={styles.row}>
      <Grid.Column width={4} />
      <Grid.Column id="engagement-chart-graph-stage-history" width={12}>
        <div
          className={styles.line}
          style={{ width: `${oneDayWidth * totalDays}%` }}
        >
          {formatedStageLine.map((stage) => (
            <TooltipWrapper
              position="top center"
              tooltip={
                <div className={styles.stage_tooltip}>Stage: {stage.stage}</div>
              }
            >
              <div
                key={stage.numberDay}
                className={classnames(styles.stage_box, {
                  gray: stage.delta === 0,
                  red: stage.delta < 0,
                  green: stage.delta > 0,
                })}
                style={{
                  width: `${stage.width}%`,
                }}
              >
                {stage.delta < 0 && <div className={styles.arrow_stage_left} />}
                <span>{stage.stage}</span>
                {stage.delta > 0 && (
                  <div className={styles.arrow_stage_right} />
                )}
              </div>
            </TooltipWrapper>
          ))}
        </div>
      </Grid.Column>
    </Grid.Row>
  );
};

export default StageHistoryChart;
