import { reducerWithInitialState } from 'typescript-fsa-reducers';

import * as metricsActions from 'actions/revbi/metrics';
import {
  SIMPLE_METRIC_NEWBORN_MOCK,
  FORMULA_METRIC_NEWBORN_MOCK,
} from 'components/dashboard/Metrics/Create/MetricCreate/contants';
import * as utils from 'components/dashboard/Metrics/Create/MetricCreate/utils';
import { getDropdownFriendlyName } from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIMetricSimple,
  BIMetricFormula,
  BIMetricSimpleNewborn,
  BIMetricFormulaNewborn,
  MetricType,
  BIColumnListItem,
  BIWidgetPreview,
  ComponentMode,
} from 'components/dashboard/Metrics/metrics.types';

export type RevBIMetricsReduxStore = {
  create: {
    simpleMetricNewborn: BIMetricSimpleNewborn;
    formulaMetricNewborn: BIMetricFormulaNewborn;
    simpleMetric: BIMetricSimple | null;
    isSimpleMetricTouched: boolean;
    formulaMetric: BIMetricFormula | null;
    isFormulaMetricTouched: boolean;
    activeTab: MetricType;
    componentMode: ComponentMode;
    saveStatus: string;
    updateStatus: string;
    getStatus: string;
  };
  metricsList: {
    list: (BIMetricSimple | BIMetricFormula)[];
    status: string;
  };
  metricsTSList: {
    list: (BIMetricSimple | BIMetricFormula)[];
    status: string;
  };
  createWidgetMetricList: (BIMetricSimple | BIMetricFormula)[];
  objectList: {
    list: string[];
    status: string;
  };
  objectListHistory: {
    list: string[];
    status: string;
  };
  columnFieldsFilter: {
    list: BIColumnListItem[];
    status: string;
  };
  columnFields: {
    columns: { [index: string]: BIColumnListItem[] };
    status: string;
  };
  widget: {
    data: BIWidgetPreview;
    canPreview: boolean;
    canSave: boolean;
    isValidInput: boolean;
  };
};

const initialState: RevBIMetricsReduxStore = {
  create: {
    simpleMetricNewborn: SIMPLE_METRIC_NEWBORN_MOCK,
    formulaMetricNewborn: FORMULA_METRIC_NEWBORN_MOCK,
    simpleMetric: null,
    isSimpleMetricTouched: false,
    formulaMetric: null,
    isFormulaMetricTouched: false,
    activeTab: MetricType.Simple,
    componentMode: ComponentMode.CREATE,
    saveStatus: 'notAsked',
    updateStatus: 'notAsked',
    getStatus: 'notAsked',
  },
  createWidgetMetricList: [],
  metricsList: {
    status: 'notAsked',
    list: [],
  },
  metricsTSList: {
    status: 'notAsked',
    list: [],
  },
  objectList: {
    list: [],
    status: 'notAsked',
  },
  objectListHistory: {
    list: [],
    status: 'notAsked',
  },
  columnFieldsFilter: {
    list: [],
    status: 'notAsked',
  },
  columnFields: {
    columns: {},
    status: 'notAsked',
  },
  widget: {
    data: {
      name: '',
      chart_type: 'column',
      analysis_type: 'live',
      point_in_time: '',
      metric_list: [],
      template_filters: [],
      widget_filters: [],
      order_by: ['descending'],
      order_by_column: {
        name: '',
        label: '',
        type: '',
      },
      group_by: [],
      limit: 10,
    },
    canPreview: false,
    canSave: false,
    isValidInput: false,
  },
};

export const metricsReducer = reducerWithInitialState<RevBIMetricsReduxStore>(
  initialState
)
  .case(metricsActions.fetchAllMetricsSuccess, (state, payload) => ({
    ...state,
    metricsList: {
      status: 'success',
      list: payload,
    },
  }))
  .case(metricsActions.fetchAllMetricsLoading, (state) => ({
    ...state,
    metricsList: {
      ...state.metricsList,
      status: 'loading',
    },
  }))
  .case(metricsActions.fetchAllTSMetricsSuccess, (state, payload) => ({
    ...state,
    metricsTSList: {
      status: 'success',
      list: payload,
    },
  }))
  .case(metricsActions.fetchAllTSMetricsLoading, (state) => ({
    ...state,
    metricsTSList: {
      ...state.metricsList,
      status: 'loading',
    },
  }))
  .case(metricsActions.fetchMetricByIdSuccess, (state, payload) => {
    let isUpdated = false;
    const newList =
      state.metricsList.list.length === 0
        ? state.metricsList.list.concat(payload.data)
        : state.metricsList.list.reduce(
            (acc: (BIMetricSimple | BIMetricFormula)[], metric, index) => {
              if (metric._id === payload.id) {
                acc.push(payload.data);
                isUpdated = true;
              } else {
                if (index === state.metricsList.list.length && !isUpdated) {
                  acc.push(metric);
                  acc.push(payload.data);
                } else {
                  acc.push(metric);
                }
              }
              return acc;
            },
            []
          );
    let newState: RevBIMetricsReduxStore = {
      ...state,
      create: {
        ...state.create,
      },
      metricsList: {
        ...state.metricsList,
        list: newList,
      },
    };
    if (payload.isEditing) {
      const metricType = utils.getMetricType(payload.data);
      newState = {
        ...newState,
        create: {
          ...newState.create,
          componentMode: ComponentMode.EDIT,
          getStatus: 'success',
          activeTab: metricType,
        },
      };
      if (metricType === MetricType.Simple) {
        return {
          ...newState,
          create: {
            ...newState.create,
            simpleMetric: payload.data as BIMetricSimple,
          },
        };
      } else {
        return {
          ...newState,
          create: {
            ...newState.create,
            formulaMetric: payload.data as BIMetricFormula,
          },
        };
      }
    }
    return newState;
  })
  .case(metricsActions.fetchMetricByIdLoading, (state, payload) => {
    if (payload.isEditing) {
      return {
        ...state,
        create: {
          ...state.create,
          getStatus: 'loading',
        },
      };
    }
    return state;
  })
  .case(metricsActions.updateMetricByIdLoading, (state) => ({
    ...state,
    create: {
      ...state.create,
      updateStatus: 'loading',
    },
  }))
  .case(metricsActions.updateMetricByIdSuccess, (state, payload) => {
    const newList = state.metricsList.list.map((metric) => {
      if (metric._id === payload.data._id) {
        return payload.data;
      }
      return metric;
    });
    const newCreateWidgetMetricList = state.createWidgetMetricList.reduce(
      (list, metric) => {
        if (metric._id === payload.data._id) {
          return list.concat(payload.data);
        }
        return list.concat(metric);
      },
      [] as (BIMetricSimple | BIMetricFormula)[]
    );

    return {
      ...state,
      metricsList: {
        ...state.metricsList,
        list: newList,
      },
      createWidgetMetricList: newCreateWidgetMetricList,
    };
  })
  .case(metricsActions.createMetricSuccess, (state, payload) => {
    const newState = {
      ...state,
      create: {
        ...initialState.create,
        saveStatus: 'success',
      },
      metricsList: {
        ...state.metricsList,
        list: state.metricsList.list.concat(payload.metric),
      },
    };
    if (payload.addToWidgetCreationMetricsList) {
      newState.createWidgetMetricList = state.createWidgetMetricList.concat(
        payload.metric
      );
    }
    return newState;
  })
  .case(metricsActions.createMetricLoading, (state) => ({
    ...state,
    create: {
      ...state.create,
      saveStatus: 'loading',
    },
  }))
  .case(metricsActions.changeActiveMetric, (state, payload) => {
    const activeTab = state.create.activeTab;
    const componentMode = state.create.componentMode;

    if (
      activeTab === MetricType.Simple &&
      componentMode === ComponentMode.CREATE
    ) {
      return {
        ...state,
        create: {
          ...state.create,
          simpleMetricNewborn: payload as BIMetricSimpleNewborn,
        },
      };
    }
    if (
      activeTab === MetricType.Simple &&
      componentMode === ComponentMode.EDIT
    ) {
      return {
        ...state,
        create: {
          ...state.create,
          simpleMetric: payload as BIMetricSimple,
          isSimpleMetricTouched: true,
        },
      };
    }
    if (
      activeTab === MetricType.Formula &&
      componentMode === ComponentMode.CREATE
    ) {
      return {
        ...state,
        create: {
          ...state.create,
          formulaMetricNewborn: payload as BIMetricFormulaNewborn,
        },
      };
    }
    if (
      activeTab === MetricType.Formula &&
      componentMode === ComponentMode.EDIT
    ) {
      return {
        ...state,
        create: {
          ...state.create,
          formulaMetric: payload as BIMetricFormula,
          isFormulaMetricTouched: true,
        },
      };
    }
    return state;
  })
  .case(metricsActions.fetchObjectListSuccess, (state, payload) => ({
    ...state,
    objectList: {
      list: payload,
      status: 'success',
    },
  }))
  .case(metricsActions.fetchObjectLoading, (state) => ({
    ...state,
    objectList: {
      ...state.objectList,
      status: 'loading',
    },
  }))
  .case(metricsActions.fetchTimeSeriesObjectListSuccess, (state, payload) => ({
    ...state,
    objectListHistory: {
      list: payload,
      status: 'success',
    },
  }))
  .case(metricsActions.fetchTimeSeriesObjectLoading, (state) => ({
    ...state,
    objectListHistory: {
      ...state.objectList,
      status: 'loading',
    },
  }))
  .case(metricsActions.switchTab, (state, payload) => {
    if (state.create.componentMode === ComponentMode.CREATE) {
      const newState = {
        ...state,
        create: {
          ...state.create,
          activeTab: payload,
        },
      };
      // That part needs for freeze Analysis Type.
      if (state.create.activeTab === MetricType.Simple) {
        newState.create.formulaMetricNewborn.analysis_type =
          state.create.simpleMetricNewborn.analysis_type;

        // Copying Name
        newState.create.formulaMetricNewborn.name =
          state.create.simpleMetricNewborn.name;
      }
      if (state.create.activeTab === MetricType.Formula) {
        newState.create.simpleMetricNewborn.name =
          state.create.formulaMetricNewborn.name;
      }
      return newState;
    }
    if (state.create.componentMode === ComponentMode.EDIT) {
      if (state.create.activeTab === MetricType.Formula) {
        return {
          ...state,
          create: {
            ...state.create,
            simpleMetric: utils.reduxStoreMetricCloner(
              state.create.simpleMetric,
              state.create.formulaMetric as BIMetricFormula
            ),
            activeTab: payload,
            widget: initialState.widget,
          },
        };
      }
      if (state.create.activeTab === MetricType.Simple) {
        return {
          ...state,
          create: {
            ...state.create,
            formulaMetric: utils.reduxStoreMetricCloner(
              state.create.formulaMetric,
              state.create.simpleMetric as BIMetricSimple
            ),
            activeTab: payload,
          },
        };
      }
    }
    return state;
  })
  .case(metricsActions.fetchColumnFieldsFilterSuccess, (state, payload) => ({
    ...state,
    columnFieldsFilter: {
      list: payload.map((el) => ({
        ...el,
        label: getDropdownFriendlyName(el),
      })),
      status: 'success',
    },
  }))
  .case(metricsActions.fetchColumnFieldsFilterLoading, (state) => ({
    ...state,
    columnFieldsFilter: {
      ...state.columnFieldsFilter,
      status: 'loading',
    },
  }))
  .case(
    metricsActions.fetchColumnFieldsFilterTimeSeriesSuccess,
    (state, payload) => ({
      ...state,
      columnFieldsFilter: {
        list: payload.map((el) => ({
          ...el,
          label: getDropdownFriendlyName(el),
        })),
        status: 'success',
      },
    })
  )
  .case(metricsActions.fetchColumnFieldsFilterTimeSeriesLoading, (state) => ({
    ...state,
    columnFieldsFilter: {
      ...state.columnFieldsFilter,
      status: 'loading',
    },
  }))
  .case(metricsActions.fetchColumnFieldsSuccess, (state, payload) => ({
    ...state,
    columnFields: {
      columns: {
        ...state.columnFields.columns,
        [payload.object]: payload.columns,
      },
      status: 'success',
    },
  }))
  .case(metricsActions.fetchColumnFieldsLoading, (state) => ({
    ...state,
    columnFields: {
      ...state.columnFields,
      status: 'loading',
    },
  }))
  .case(metricsActions.cleanUpMetricCreationState, (state) => {
    return {
      ...state,
      createWidgetMetricList: [...initialState.createWidgetMetricList],
      create: { ...initialState.create },
    };
  })
  .case(
    metricsActions.addMetricToWidgetCreationMetricsList,
    (state, payload) => ({
      ...state,
      createWidgetMetricList: state.createWidgetMetricList.concat(payload),
    })
  )
  .case(
    metricsActions.removeMetricFromWidgetCreationMetricsList,
    (state, id) => ({
      ...state,
      createWidgetMetricList: state.createWidgetMetricList.filter(
        (el) => el._id !== id
      ),
    })
  )
  .case(metricsActions.changeCreateMetricComponentMode, (state, payload) => ({
    ...state,
    create: {
      ...state.create,
      componentMode: payload,
    },
  }))
  .case(metricsActions.changePreviewWidget, (state, payload) => ({
    ...state,
    widget: {
      data: payload,
      canPreview: false,
      canSave: false,
      isValidInput: false,
    },
  }))
  .case(metricsActions.removeMetric, (state, payload) => ({
    ...state,
    metricsList: {
      ...state.metricsList,
      list: state.metricsList.list.filter(
        (metric) => !payload?.ids?.includes(metric?._id)
      ),
    },
    metricsTSList: {
      ...state.metricsTSList,
      list: state.metricsTSList.list.filter(
        (metric) => !payload?.ids?.includes(metric?._id)
      ),
    },
  }));
