import { css } from 'emotion';

export const stickyTop = css`
  position: sticky;
  top: 52px;
  background: var(--bu-white);
  z-index: 989;
`;

export const widgetsContainer = css`
  padding-top: 20px;

  > * {
    margin-bottom: 20px;
  }
`;
