import styled from '@emotion/styled';
import classNames from 'classnames';
import { css } from 'emotion';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as metricActions from 'actions/revbi/metrics';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import BuRadio from 'components/UI/BuRadio';
import BuSelect from 'components/UI/BuSelect';
import { Conditions } from 'components/dashboard/Metrics/Create/Conditions/Conditions';
import {
  DEFAULT_ACCOUNTS_ORDER_BY,
  DEFAULT_OPPORTUNITIES_ORDER_BY,
  SUPPORTED_REPORT_VIEW_OBJECTS,
} from 'components/dashboard/Metrics/Create/constants';
import { ACCOUNT, OPPORTUNITY } from 'components/dashboard/Metrics/constants';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIBasicColumn,
  BIMetricUnion,
  BIWidget,
  IOption,
} from 'components/dashboard/Metrics/metrics.types';
import { getObjectList } from 'selectors/revbi/metrics';

const MessageSpan = styled.span((props: { isValid?: boolean }) => ({
  marginLeft: '8px',
  fontSize: '12px',
  color: props.isValid ? 'var(--bu-gray-700)' : 'var(--bu-red-600)',
}));

const InputContainer = styled.div({
  marginBottom: '24px',
  marginRight: '4px',
});

const RadiosContainer = styled.div({
  marginTop: '8px',
});

interface Props {
  widget: BIWidget;
  metric: BIMetricUnion;
  columnList: BIBasicColumn[];
  setMetric: (metric: BIMetricUnion) => void;
  setWidget: (widget: BIWidget) => void;
}

enum Direction {
  Descending = -1,
  Ascending = 1,
}

export const ReportDefinition: React.FC<Props> = ({
  widget,
  metric,
  columnList,
  setMetric,
  setWidget,
}) => {
  const dispatch = useDispatch();

  const [numberOfRecords, setNumberOfRecords] = useState<number>(
    widget.limit ?? 10
  );
  const [isValidNumberOfRecords, setValidNumberOfRecords] =
    useState<boolean>(true);
  const [orderBy, setOrderBy] = useState<string>(
    () => widget?.order_by_column?.name || ''
  );
  const [direction, setDirection] = useState<Direction>(() =>
    widget?.order_by?.[0] === 'ascending'
      ? Direction.Ascending
      : Direction.Descending
  );

  const objectList = useSelector(getObjectList);

  useEffect(() => {
    setOrderBy(() => widget?.order_by_column?.name || '');
    setDirection(() =>
      widget?.order_by?.[0] === 'ascending'
        ? Direction.Ascending
        : Direction.Descending
    );
  }, [widget]);

  useEffect(() => {
    if (objectList.length === 0) {
      dispatch(metricActions.fetchObjectList());
    }
  }, [objectList]);

  useEffect(() => {
    if (metric?.object) {
      dispatch(metricActions.fetchColumnFieldsFilter([metric.object]));
    }
  }, [metric?.object]);

  const handleNumberOfRecordsChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      const numOfRecords = e.currentTarget.valueAsNumber;
      setNumberOfRecords(numOfRecords);

      if (!numOfRecords || numOfRecords < 1 || numOfRecords > 100) {
        setValidNumberOfRecords(false);
      } else {
        setValidNumberOfRecords(true);
      }
      setWidget({
        ...widget,
        limit: numOfRecords,
      });
    }, 400),
    []
  );

  const handleObjectChange = useCallback(
    (values: string[]) => {
      setMetric({ ...metric, object: values[0], filters: [] });
      setWidget({
        ...widget,
        order_by_column:
          values[0] === OPPORTUNITY
            ? DEFAULT_OPPORTUNITIES_ORDER_BY
            : DEFAULT_ACCOUNTS_ORDER_BY,
      });
    },
    [widget, metric]
  );

  const handleOrderBy = (columnName: string) => {
    setOrderBy(columnName);
    const column = columnList.find((c: BIBasicColumn) => c.name === columnName);
    if (column) {
      setWidget({
        ...widget,
        order_by_column: column,
      });
    }
  };

  const handleOrderByDirection = (direction: Direction) => {
    setDirection(direction as Direction);
    setWidget({
      ...widget,
      order_by: [
        direction === Direction.Ascending ? 'ascending' : 'descending',
      ],
    });
  };

  const objectSelectorOptions = useMemo(
    () =>
      objectList
        .filter((object: string) =>
          SUPPORTED_REPORT_VIEW_OBJECTS.includes(object)
        )
        .map((object: string) => ({
          text: object[0].toUpperCase() + object.substring(1),
          value: object,
        })),
    [objectList]
  );

  const columnsSelectorOptions = useMemo(
    () =>
      columnList
        .map((column: BIBasicColumn) => ({
          text: column.label,
          value: column.name,
        }))
        // To remove duplicates which are causing issues
        .filter((v, i, a) => a.findIndex((v2) => v2.value === v.value) === i)
        .sort((a: IOption, b: IOption) =>
          a.text.localeCompare(b.text, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        ),

    [columnList]
  );

  const getOrderByValue = useMemo(() => {
    if (orderBy) return [orderBy];

    if (metric?.object === OPPORTUNITY) return ['opportunity.last_touched'];

    if (metric?.object === ACCOUNT) return ['account.open_opportunities'];

    return [''];
  }, [orderBy, metric.object]);

  return (
    <>
      <InputContainer>
        <MetricCreateSubTitle>Object</MetricCreateSubTitle>
        <BuSelect
          options={objectSelectorOptions}
          onChange={handleObjectChange}
          secondary
          defaults={[metric.object]}
          fullWidth
          placeholder="Select a table"
          testingLabel="object"
        />
      </InputContainer>
      <InputContainer>
        <Conditions metric={metric} />
      </InputContainer>
      <InputContainer>
        <MetricCreateSubTitle>Number of records</MetricCreateSubTitle>
        <BuInput
          isAlignLeftText={false}
          type="number"
          value={numberOfRecords}
          testingLabel="number-of-records"
          onChange={(e) => handleNumberOfRecordsChange(e)}
          className={classNames(
            css`
              width: 100%;
              margin-bottom: 4px;
              input {
                text-align: left !important;
              }
            `,
            !isValidNumberOfRecords &&
              css`
                border-color: var(--bu-red-400) !important;
                input {
                  background-color: var(--bu-red-100);
                  color: var(--bu-red-600);
                  box-shadow: none !important;
                }
              `
          )}
        />

        {isValidNumberOfRecords ? (
          <>
            <BuIcon
              name={BoostUpIcons.BadgeInfoSolid}
              className={css`
                font-size: 18px;
              `}
              color="var(--bu-primary-500)"
            />
            <MessageSpan isValid>
              Choose up to 100 records to show in your list
            </MessageSpan>
          </>
        ) : (
          <>
            <BuIcon
              name={BoostUpIcons.BadgeWarningSolid}
              className={css`
                font-size: 18px;
              `}
              color="var(--bu-red-500)"
            />
            <MessageSpan isValid={false}>
              Please choose a value that is less or equal to 100
            </MessageSpan>
          </>
        )}
      </InputContainer>
      <InputContainer>
        <MetricCreateSubTitle>Order by</MetricCreateSubTitle>
        <BuSelect
          secondary
          fullWidth
          searchable
          isLargePlaceholder
          options={columnsSelectorOptions}
          onChange={(values: string[]) => handleOrderBy(values[0])}
          defaults={getOrderByValue}
          placeholder="Select a option"
          testingLabel="order-by"
        />
        <RadiosContainer>
          <BuRadio
            onChange={(_, { value }) => {
              setDirection(value as Direction);
              handleOrderByDirection(value as Direction);
            }}
            value={Direction.Descending}
            checked={direction === Direction.Descending}
            label="Descending"
            testingLabel="Descending"
          />
          <BuRadio
            onChange={(_, { value }) => {
              setDirection(value as Direction);
              handleOrderByDirection(value as Direction);
            }}
            value={Direction.Ascending}
            checked={direction !== Direction.Descending}
            label="Ascending"
            testingLabel="Ascending"
          />
        </RadiosContainer>
      </InputContainer>
    </>
  );
};
