import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CommonList } from 'components/dashboard/Metrics/List/Common/CommonList';
import { SelectedItem } from 'components/dashboard/Metrics/List/types';
import {
  parseWidget,
  parseWidgetList,
} from 'components/dashboard/Metrics/metrics.helpers';
import { BIWidget } from 'components/dashboard/Metrics/metrics.types';
import { fetchApi, QueryStatus } from 'utils/network';

export const WidgetsList: React.FC = () => {
  const history = useHistory();

  const [widgetList, setWidgetList] = useState<BIWidget[]>([]);

  const [status, setStatus] = useState<QueryStatus>('notAsked');
  const [cloneStatus, setCloneStatus] = useState<QueryStatus>('notAsked');

  const [selectedElement, setSelectedElement] = useState<SelectedItem>();

  const handleDeleteConfirm = () => {
    fetchApi<void, any>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets/${selectedElement?.id}`,
      queryMethod: 'delete',
      setData: () => {
        toast.success(`Widget ${selectedElement?.name} has been deleted`);
        setWidgetList((prev: any) =>
          prev.filter(
            (widget: any) => (widget as any)?._id !== selectedElement?.id
          )
        );
      },
      setError: (error: string | null) => {
        console.error(
          `Failed to delete widget "${selectedElement?.name}": ${error}`
        );
        toast.error(`Failed to delete widget "${selectedElement?.name}"`);
      },
      setStatus: () => {},
    });
  };

  const handleClone = (id: string, name: string) => {
    if (cloneStatus !== 'loading') {
      fetchApi<void, BIWidget>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets/${id}/clone`,
        queryMethod: 'get',
        setData: (result) => {
          const newWidget = parseWidget(result);
          setWidgetList((prev: any) => [newWidget, ...prev]);
          history.push(`/revbi/widgets/edit/${newWidget.id}`);
          toast.success(`Widget "${name}" has been cloned`);
        },
        setError: (error: string | null) => {
          toast.error(`Failed to clone widget "${name}": ${error}`);
        },
        setStatus: setCloneStatus,
      });
    }
  };

  useEffect(() => {
    fetchApi<void, BIWidget[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets`,
      queryMethod: 'get',
      setData: (result) => {
        setWidgetList(parseWidgetList(result ?? []));
      },
      setError: (error: string | null) => {
        toast.error(`Fetching data failed: ${error}`);
      },
      setStatus,
    });
  }, []);

  return (
    <CommonList
      list={widgetList}
      status={status}
      selectedElement={selectedElement}
      onDeleteConfirm={handleDeleteConfirm}
      onClone={handleClone}
      setSelectedElement={setSelectedElement}
    />
  );
};
