import * as t from 'actions/actionTypes';

export const onRefreshForecast = () => ({
  type: t.FORECAST + t.REFRESH,
});

export const updateTotals = () => ({ type: t.FORECAST + t.REFRESH + t.START });

export const updateTotalsSuccess = () => ({
  type: t.FORECAST + t.REFRESH + t.SUCCESS,
});

export const selectItem = (itemName) => ({
  type: t.FORECAST + t.SET + t.ACTIVE,
  itemName,
});

export const toggleForecastOppSplitView = () => ({
  type: t.FORECAST + t.TOGGLE + t.OPP_SPLIT_VIEW,
});

export const setForecastOppSplitView = (value) => ({
  type: t.FORECAST + t.SET + t.OPP_SPLIT_VIEW,
  value,
});

export const fetchForecastData = (filters) => ({
  type: t.FORECAST + t.GET,
  filters,
});

export const fetchForecastDataSuccess = (payload) => ({
  type: t.FORECAST + t.GET + t.SUCCESS,
  payload,
});

export const fetchForecastDataLoading = () => ({
  type: t.FORECAST + t.GET + t.LOADING,
});

export const fetchForecastDataFailure = (e) => ({
  type: t.FORECAST + t.GET + t.ERROR,
  error: e,
});

export const clearHistory = () => ({
  type: t.FORECAST + t.MANAGE + t.USER + t.INCLUDED_DEALS + t.CLEAR,
});

export const setForecastProgressionFilter = (field, value) => ({
  type: t.FORECAST + t.PROGRESSION + t.FILTER + t.CHANGE,
  field,
  value,
});

export const setForecastViewFilter = (value) => ({
  type: t.FORECAST + t.VIEW + t.FILTER + t.CHANGE,
  value,
});

export const setForecastDashboardFilter = (field, value) => ({
  type: t.FORECAST + t.DASHBOARD + t.FILTER + t.CHANGE,
  field,
  value,
});

export const submitForecast = () => ({
  type: t.FORECAST + t.SUBMIT,
});

export const clearSubmitForecastFlag = () => ({
  type: t.FORECAST + t.SUBMIT + t.CLEAR,
});
