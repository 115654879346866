import { css } from 'emotion';

import { fontDefault, fontSubtitle } from 'assets/css/common';

const BORDER_STYLE = '1px solid rgba(34, 36, 38, 0.15)';

export const breadcrumb = css`
  &.ui.breadcrumb {
    margin-right: 40px;
    padding: 2px 0 0;

    .active.section {
      color: var(--bu-gray-900);
      font-weight: normal;
      font-family: var(--bu-font-regular);
    }

    a {
      color: var(--bu-primary-500);
    }
  }
`;

export const container = css`
  height: 100%;
  border: ${BORDER_STYLE};
  border-radius: 4px;
`;

export const headerContainer = css`
  border-bottom: ${BORDER_STYLE};
  display: flex;
  min-height: 70px;
  align-items: center;
`;

export const headerRow = css`
  color: #1a1a1a;
  font-size: 14px;
  font-family: var(--bu-font-medium);
  letter-spacing: 0;
  line-height: 21px;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding: 10px 0 18px 20px;
  .subheader {
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    margin-left: 10px;
  }
`;

export const titleContainer = css`
  display: flex;
  flex-direction: column;
`;

export const headerRow_modal = css`
  flex: 1;
  display: flex;
  align-items: center;

  .subheader {
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    margin-left: 10px;
  }
`;

export const dropdownContainer = css`
  font-size: 14px;
  margin-left: 16px;

  label {
    margin-right: 5px;
  }

  .ui.dropdown {
    height: 30px;
    min-height: 30px;
    min-width: 140px;
    padding-top: 6px;
    > .dropdown.icon {
      padding-top: 7px;
    }
  }
`;

export const smallDropdown = css`
  border-radius: 4px !important;

  &.ui.dropdown {
    height: 30px;
    min-height: 30px;
    min-width: 140px;
    padding-top: 6px;
    position: relative;
    margin-left: 10px;

    > .dropdown.icon {
      padding-top: 7px;
    }

    > .visible.menu {
      top: 36px;
      border-radius: 4px;
      border: 1px solid var(--bu-gray-400);
      border-top-width: inherit !important;

      > .item {
        &:hover {
          background: rgba(48, 176, 209, 0.08);
        }
        &.active selected {
          background: rgba(48, 176, 209, 0.08);
        }
      }
    }
  }
`;

export const filters = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
  padding-right: 10px;
`;

export const clickableCell = css`
  color: var(--bu-primary-500);
  cursor: pointer;
  ${fontSubtitle};
`;

export const nonClickableCell = css`
  cursor: auto;
`;

export const backgroundLevel =
  (color1: string, color2: string) => (ratio: number) => {
    const toHex = (number: number) => {
      const str = Math.min(Math.max(number, 0), 0xff).toString(16);
      return str.length === 1 ? `0${str}` : str;
    };

    const fromHex = (str: string) => parseInt(str, 16);

    const r = Math.ceil(
      fromHex(color2.substring(1, 3)) * ratio +
        fromHex(color1.substring(1, 3)) * (1 - ratio)
    );
    const g = Math.ceil(
      fromHex(color2.substring(3, 5)) * ratio +
        fromHex(color1.substring(3, 5)) * (1 - ratio)
    );
    const b = Math.ceil(
      fromHex(color2.substring(5, 7)) * ratio +
        fromHex(color1.substring(5, 7)) * (1 - ratio)
    );

    return css`
      label: ${~~(ratio * 100)};
      background-color: #${toHex(r) + toHex(g) + toHex(b)} !important;
    `;
  };

export const widgetContainer = css`
  overflow: auto;

  > div:first-child {
    overflow: visible !important;
  }
`;

export const dropdrown = css`
  height: fit-content;
  display: inline-block;
  border: 1px solid var(--bu-gray-500);
  padding: 5px 8px;
  border-radius: 3px;
  cursor: pointer;
  background: var(--bu-white);
  position: relative;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: rgba(49, 92, 121, 0.12);
  }

  &.active {
    border-color: var(--bu-primary-500);
  }
`;

export const dropdrown_label = css`
  ${fontSubtitle};
  margin-right: 5px;
`;

export const dropdrown_value = css`
  ${fontDefault};
`;

export const dropdrown_options = css`
  &.open {
    display: block;
  }

  display: none;
  position: absolute;
  list-style: none;
  padding: 0;
  margin: 0;
  right: -2px;
  top: 36px;
  z-index: 1000;
  background: #fff;
  border-radius: 4px;
  width: max-content;
  max-height: 204px;
  overflow: auto;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
`;

export const dropdrown_options_item = css`
  padding: 7px 15px;

  &.active,
  &:hover {
    background: rgba(48, 176, 209, 0.08);
  }
`;

export const triangle = css`
  border-style: solid;
  height: 0;
  width: 0;
  border-width: 4px;
  border-color: var(--bu-gray-900);
  display: inline-block;
  margin: 0 2px 0 6px;

  &.bu-up {
    border-top-width: 0;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    transform: translateY(-50%);
    border-color: var(--bu-primary-500);
  }

  &.bu-down {
    border-bottom-width: 0;
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    transform: translateY(-50%);
  }
`;

export const headerRow_title = css`
  color: var(--bu-gray-900);
  font-size: 16px;
  font-family: var(--bu-font-medium);
  letter-spacing: 0;
  line-height: 21px;
`;

export const modal_header = css`
  border-bottom: 1px solid var(--bu-gray-500);
  padding: 14px 21px;
  display: flex;
`;

export const styles_dropdown = css`
  padding: 7px !important;
  border: 1px solid #9b9b9b !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  color: var(--bu-gray-900);
  margin-left: 10px;
  .menu {
    z-index: 1000 !important;
  }
`;
