import { Location } from 'history';
import React, { useState, useCallback } from 'react';
import { useHistory, Prompt } from 'react-router-dom';

type IProps = {
  children: (
    showPrompt: boolean,
    proceedLeaving: () => void,
    block: () => void
  ) => React.ReactNode;
  block: boolean;
  ignoredPath?: string;
};

const OnLeave: React.FC<IProps> = ({ children, block, ignoredPath }) => {
  const history = useHistory();
  const [location, setLocation] = useState<Location | null>(null);

  const handleBlockedNavigation = useCallback(
    (nextLocation: Location): boolean => {
      if (block && location === null) {
        if (ignoredPath && nextLocation.pathname.includes(ignoredPath)) {
          return true;
        }
        setLocation(nextLocation);
        return false;
      }
      return true;
    },
    [block, location, setLocation]
  );

  const proceedLeaving = useCallback(() => {
    if (location) {
      history.push(location);
    }
  }, [location, history]);

  return (
    <>
      <Prompt message={handleBlockedNavigation} />
      {children(!!location, proceedLeaving, () => setLocation(null))}
    </>
  );
};

export default OnLeave;
