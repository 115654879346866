import queryString from 'query-string';

import {
  DEFAULT_QUERY_METHOD,
  POSITIVE_HTTP_STATUSES,
} from 'utils/network/constants';
import { getBody } from 'utils/network/getBody';
import { mapHttpStatusToMessage } from 'utils/network/maps';
import { IFetchApiWithoutCb } from 'utils/network/types';

export const fetchApiWithoutCb = async <TReqParams, TResponse>({
  queryMethod = DEFAULT_QUERY_METHOD,
  queryParams,
  signal,
  url,
}: IFetchApiWithoutCb<TReqParams, TResponse>): Promise<{
  error?: string;
  result?: TResponse;
  headers?: Headers;
}> => {
  let error;
  let result;
  let headers;

  try {
    const response = await fetch(
      url +
        (queryMethod === 'get' && queryParams
          ? `?${queryString.stringify(queryParams ?? {})}`
          : ''),
      {
        body: getBody<TReqParams>(queryMethod, queryParams),
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: queryMethod,
        signal,
      }
    );

    headers = response.headers;
    const contentType = headers.get('content-type');
    const httpStatus = response.status;

    if (POSITIVE_HTTP_STATUSES.includes(httpStatus)) {
      if (httpStatus === 204) {
        result = null;
      } else {
        if (contentType && contentType.includes('application/json')) {
          result = await response.json();
        } else {
          result = response;
        }
      }
    } else {
      let apiErrorMessage;

      if (contentType && contentType.includes('application/json')) {
        const { error } = await response.json();

        if (error && error.message) {
          apiErrorMessage = JSON.stringify(error.message);
        }
      }

      error =
        apiErrorMessage ||
        mapHttpStatusToMessage[response.status] ||
        'network error';

      if (httpStatus === 401 && !window.location.pathname.includes('/login')) {
        window.location.href =
          window.location.pathname !== '/'
            ? `/login?next=${window.location.pathname}${window.location.search}`
            : '/login';
      }
    }
  } catch (err) {
    if (err.name !== 'AbortError') {
      error = err.message;
    }
  }
  return { error, result, headers };
};
