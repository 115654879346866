import { css } from 'emotion';

import { fontDefault, checkedIcon } from 'assets/css/common';

export const resetMargins = css`
  .ui.form &.inline.fields {
    margin: 0;
    flex-wrap: wrap;
    justify-content: center;

    &.field {
      padding-right: 20px;
    }
  }

  .checkbox {
    label {
      padding-left: 1.4em !important;

      &:before {
        width: 12px !important;
        height: 12px !important;
        top: 3px !important;
        border: 1px solid var(--bu-gray-700) !important;
      }

      &:after {
        width: 12px !important;
        height: 12px !important;
        font-size: 8px !important;
      }
    }

    input:checked ~ label:before {
      background-color: var(--bu-primary-500) !important;
      border-color: var(--bu-primary-500) !important;
    }

    input:checked ~ label:after {
      content: '' !important;
      top: 3px;
      left: 1px;
      width: 10px !important;
      height: 10px !important;

      ${checkedIcon}
    }
  }
`;

export const fieldSpacing = css`
  .ui.form &.inline.fields .field {
    padding-right: 20px;
  }
`;

export const dot = css`
  display: flex;
  align-items: center;

  &:before {
    content: '';
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 6px;
    margin-right: 6px;
  }
  &.commit:before {
    background-color: var(--bu-color-constant-commit);
  }
  &.best_case:before {
    background-color: var(--bu-color-constant-best-case);
  }
  &.pipeline:before {
    background-color: var(--bu-color-constant-pipeline);
  }
  &.target:before {
    background-color: var(--bu-color-constant-forecast-target);
  }
  &.booked:before {
    background-color: var(--bu-color-constant-booked);
  }
  &.lost:before {
    background-color: var(--bu-color-constant-lost);
  }
  &.pipeline_coverage_target:before {
    background-color: var(--bu-color-constant-pipeline-coverage-target);
  }
  &.pipeline_coverage:before {
    background-color: var(--bu-color-constant-pipeline-coverage);
  }
`;

export const widgetContainer = css`
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const widgetActions = css`
  display: flex;
  justify-content: center;
  margin-top: 12px;
  margin-bottom: 2px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--bu-gray-300);
`;

export const alignRight = css`
  text-align: right;
  flex: 1;
`;

export const widgetTable = css`
  display: flex;
  background-color: var(--bu-white);

  .grid-column {
    flex: 1;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 35px 35px 35px 35px 35px 35px 35px;
    gap: 0;

    border-right: 1px solid var(--bu-gray-300);

    > .grid-cell {
      ${fontDefault};
    }

    > .grid-cell {
      border-bottom: 1px solid var(--bu-gray-300);

      display: flex;
      justify-content: left;
      align-items: center;
      padding: 0 20px;
      font-size: 13px;
      height: 35px;

      &.grid-header {
        justify-content: center;
        border-top: 1px solid var(--bu-gray-300);
        background-color: #f9fafb;
        font-family: var(--bu-font-semi-bold);
        font-size: 14px;
        position: relative;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &.widget {
    background-color: var(--bu-gray-200);

    .grid-column {
      grid-template-rows: 30px;
      > .grid-cell {
        background-color: var(--bu-gray-200);
        height: 30px;
    }
  }
`;

export const mainColumn = css`
  &.grid-column {
    width: 220px;
    flex: none;
  }
`;

export const currentQuarterLabel = css`
  position: absolute;
  left: 25px;

  &.ui.label {
    margin-right: 10px;
    &.blue {
      background-color: var(--bu-primary-500) !important;
    }
  }
`;

export const marginTop = css`
  margin-top: 20px;
`;

export const customCheckbox = css`
  > .checkbox {
    label {
      padding-left: 1.4em !important;

      &:before {
        top: 2px !important;
        width: 12px !important;
        height: 12px !important;
      }

      &:after {
        font-size: 8px !important;
        width: 12px !important;
        height: 12px !important;
      }
    }
  }
`;

export const chartContainer = css`
  .highcharts-container {
    width: 100% !important;

    svg {
      width: 100%;
    }
  }
`;

export const container = css`
  height: 100%;
  border: 1px solid var(--bu-gray-300);
  border-radius: 2px;
`;

export const headerContainer = css`
  border-bottom: 1px solid var(--bu-gray-300);
  padding: 14px 30px;

  display: flex;
`;

export const headerRow = css`
  color: #4a4a4a;
  letter-spacing: 0;
  line-height: 21px;

  display: flex;
  align-items: center;

  .subheader {
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;

    margin-left: 10px;
  }
`;

export const chartLinesStyles = css`
  & {
    .highcharts-plot-line,
    .highcharts-line-series > .highcharts-graph {
      stroke-linecap: round;

      &[stroke-dasharray='3,3'] {
        stroke-dasharray: 5, 8;
      }
    }
  }
`;

export const chartContainerWidget = css`
  .highcharts-container {
    width: 100% !important;
    svg {
      width: 100%;
      .highcharts-background {
        fill: var(--bu-gray-200);
      }
      .highcharts-plot-background {
        fill: var(--bu-white);
      }
    }
  }
`;

export const modal_checkboxes = css`
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid var(--bu-gray-500);
  padding: 9px 21px;
`;

export const modal_container = css`
  border: 1px solid var(--bu-gray-500);
  border-radius: 2px;
`;

export const modal_title = css`
  color: var(--bu-gray-900);
  font-size: 16px;
  font-family: var(--bu-font-medium);
  padding: 9px 21px;
  border-bottom: 1px solid var(--bu-gray-500);
`;

export const modal_widget_container = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &.widget {
    margin-bottom: 0px;
  }
`;

export const quarter_label = css`
  margin-right: 5px !important;
`;
