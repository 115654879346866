import { isValidFilter } from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIDropdownOptions,
  BIMetricColumn,
  BIMetricsQueryFilter,
} from 'components/dashboard/Metrics/metrics.types';

export const areFiltersValid = (filters: BIMetricsQueryFilter[]): boolean =>
  (filters ?? []).reduce(
    (pre: boolean, filter) =>
      pre && isValidFilter(filter?.and_condition?.[0]?.or_condition?.[0]),
    true
  );

export const columnsToDropdownOptions = (
  columns: BIMetricColumn[]
): BIDropdownOptions[] =>
  columns.map((column) => ({ label: column.label, value: column.name }));
