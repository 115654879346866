import styled from '@emotion/styled';
import React from 'react';

const Wrapper = styled.span`
  display: flex;
  gap: 10px;
  text-transform: uppercase;
  align-items: center;
`;
Wrapper.defaultProps = {
  className: 'bu-font-subtitle',
};

const MyIncludes = styled.span`
  background-color: var(--bu-primary-300);
  color: var(--bu-gray-900);
  padding: 8px 10px;
  border-radius: var(--bu-control-border-radius);
`;

const TeamIncluded = styled.span`
  color: var(--bu-gray-700);
  font-size: 0.85em;
`;

type Props = {
  allIds: number;
  teamIds: number;
  myIds: number;
  teamAmount: string;
  myAmount: string;
  showTeamIncludes: boolean;
};

const CustomTableTitle: React.FC<Props> = ({
  allIds,
  myIds,
  myAmount,
  teamIds,
  teamAmount,
  showTeamIncludes,
}) => (
  <Wrapper>
    <MyIncludes>
      My Includes {myIds} of {allIds} ({myAmount})
    </MyIncludes>
    {showTeamIncludes && (
      <TeamIncluded>
        Team Included {teamIds} of {allIds} ({teamAmount})
      </TeamIncluded>
    )}
  </Wrapper>
);

export default CustomTableTitle;
